<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Contratista</a></li>
        <li class="breadcrumb-item active">Consultas licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}}</h1>
    <!-- end page-header -->
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-12 col-md-8">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" *ngIf="idEtapa === 4">
                <!-- begin panel-body -->
                <div class="p3-rem bg-white rounded">
                    <div class="row mb-4">
                        <div class="col-12">
                            <h4>Agregar Consulta</h4>
                        </div>
                    </div>
                    <form [formGroup]="consultaForm" (ngSubmit)="onGuardarConsulta()">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Tipo Consulta :</label>
                            <div class="col-md-9">
                                <select class="form-control" formControlName="tipo_consulta">	
                                    <option *ngFor="let item of tipo_consultas" value="{{item.CORRELATIVO}}" >{{ item.DESCRIPCION}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Consulta :</label>
                            <div class="col-md-9">
                                <textarea class="form-control" rows="2" formControlName="consulta"></textarea>
                            </div>
                        </div>
                        <footer class="text-right">
                            <button class="btn btn-primary" type="submit">Enviar</button>
                        </footer>
                    </form>
                </div>
                <!-- end panel-body -->
            </div>
        </div>
    </div>
    <!-- NEW -->
    <div *ngIf="consultas?.length > 0" class="card preguntas-container">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="pr-0 pr-sm-0 pr-md-4 border-right no-border-sm">
                    <div class="row">
                        <div *ngIf="idEtapa == 4" class="col">
                            <h3>Mis Consultas</h3>
                        </div>
                    </div>
                    <div *ngIf="idEtapa > 5" class="row mb-2">
                        <div class="col-5 text-left">
                            <button [ngClass]="{'text-primary': tabSelected == 1}" class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;" (click)="tabSelected = 1">Consultas, Respuestas</button>
                            <button [ngClass]="{'text-primary': tabSelected == 2}" class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;" (click)="tabSelected = 2">Aclaraciones</button>
                        </div>
                        <div class="col-4 text-left"></div>
                        <div class="col-3 text-right"></div>
                    </div>
                    <br><br>
                    <div *ngIf="tabSelected === 1">
                        <h2>Consultas, Respuestas:</h2>
                        <br>
                        <div class="p-20 m-b-20 card" *ngFor="let pregunta of consultas; let i = index ; let lastRow = last">
                            <div class="pregunta mb-20">
                                <div class="row">
                                    <div class="text-break col-6 text-muted">
                                        
                                    </div>
                                    <div class="col-6 text-right">
                                        <span class=" text-muted">
                                            {{ pregunta.FECHA_CREACION }}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h5 class="mb-1 text-regular">CONSULTA {{pregunta.DESCRIPCION}}</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-break" >
                                        <p class="text-muted">
                                            {{pregunta.CONSULTA}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="respuesta ml-4 mb-4" *ngIf="pregunta.RESPUESTA != null">
                                <div class="row">
                                    <div class="text-break col-12">
                                        <div class="d-flex-inline align-center margin-rem mt-2 mb-2 ml-0">
                                            <div class="round-name">
                                                EM
                                            </div>
                                            <div class="nombre">
                                                <p class="mb-0">EMSA</p>
                                                <!-- <span class="text-muted">{{item.RESPUESTA_FECHA}}</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col">
                                        <p class="">
                                            {{pregunta.RESPUESTA}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tabSelected === 2">
                        <h2>Aclaraciones:</h2>
                        <br>
                        <!-- respuestas ya realizadas -->
                        <div *ngIf="aclaracionesLicitacion.length > 0">
                            <div class="pr-0 pr-sm-0 pr-md-4 border-right no-border-sm" *ngFor="let item of aclaracionesLicitacion; let i = index">
                                <div class="p-20 m-b-20 card">
                                    <div class="pregunta mb-20">
                                        <div class="row">
                                            <div class="text-break col-6 text-muted">
                                            </div>
                                            <div class="col-6 text-right">
                                                <span class=" text-muted">
                                                    {{item.fecha | date: 'dd/MM/yyyy'}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <h5 class="mb-1 text-uppercase">{{item.descripcion}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="respuesta ml-4 mb-4">
                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div>
                                                <div class="card-body">
                                                    <p>{{item.texto_aclaracion}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="ml-2" style="position: sticky;top: 6rem;">
                    <div class="row">
                        <div class="col">
                            <h3>Archivos consolidados de respuestas:</h3>
                        </div>
                    </div>
                    <div *ngIf="archivos?.length > 0" class="row mt-4">
                        <div class="col-12 archivos-list">
                            <app-view-etapa-files-table [archivos]="archivos" [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="consultas?.length <= 0 && idEtapa == 5">
        <div class="row">
            <div class="col-12 text-center mt-5 mb-5">
                <h4 class="text-muted">Las respuestas a las consultas realizadas estarán disponibles en la etapa siguiente de la licitación.</h4>
            </div>
        </div>
    </ng-container>
    <!-- end #content -->
</div>  