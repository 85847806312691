<ng-container *ngIf="nombreArchivo">
  <!-- <div class="btn-container"> -->
  <a class="btn rounded-icon mr-2" (click)="onDescargar()" title="Descargar">
    <i class="fas fa-file-download"></i>
  </a>
  <a class="btn rounded-icon mr-2" (click)="onVisualizar(modalArchivo)" title="Visualizar">
    <i class="fas fa-eye"></i>
  </a>
  <!-- </div> -->
</ng-container>

<ng-template #modalArchivo>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Vista Previa</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="isImage">
      <img [src]="urlVisualizador | safe" class="img-fluid" />
    </ng-container>

    <ng-container *ngIf="!isImage">
      <iframe [src]="urlVisualizador | safe" frameborder="0" allowfullscreen
        style="position:absolute;top:0;left:0;width:100%;height:85vh;"></iframe>
    </ng-container>
  </div>
</ng-template>