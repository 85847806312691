import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FileService } from 'src/app/shared/services/file.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { Location } from '@angular/common';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-create-consulta-licitacion',
  templateUrl: './create-consulta-licitacion.component.html',
  styleUrls: ['./create-consulta-licitacion.component.css']
})
export class CreateConsultaLicitacionComponent implements OnInit {

  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  idParticipante: number;
  consultas: [];
  consultaForm : FormGroup;
  tipo_consultas: any[];
  archivo:string;
  nombreEtapa:string;
  archivos:[]=[];
  aclaracionesLicitacion: any[] = [];
  // tabs
  tabSelected: number = 1;

  constructor(
    private route: ActivatedRoute, 
    private etapaService: EtapasService, 
    private licitacionService: LicitacionService, 
    private notifyService : NotificationService,
    private fileService: FileService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.consultaForm = new FormGroup({
      'consulta' : new FormControl(null, [Validators.required]),
      'tipo_consulta' : new FormControl(0, [Validators.required])
    });
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = +params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        
        this.etapaService.getArchivoLicEtapa(this.idLicitacion,this.idEtapa).subscribe(
          resp=>{
            if(JSON.parse(resp)[0]){
              this.archivo = JSON.parse(resp)[0].NOMBRE_ARCHIVO;
            }
          },
          error=>console.log(error)
        );

        this.licitacionService.getDatosPart().subscribe(
          resp=>{  
            this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;

            this.licitacionService.getEtapaActualLicitacion(this.idLicitacion).subscribe(
              resp=>{
                const etapa: any[] = JSON.parse(resp);
                console.log(etapa[0].etapa_correlativo);

                if (etapa.length > 0){ 
                  this.idEtapa = etapa[0].etapa_correlativo;
                }
                
                if(this.idParticipante && this.idEtapa === 4){
                  this.getConsultas(this.idLicitacion,this.idParticipante);
                }
                else if(this.idEtapa > 5) {
                  this.getConsultas(this.idLicitacion, 0);
                }
              },
              error=>console.log(error)
            );
          },
          error=>console.log(error)
        );

        this.licitacionService.getTipoConsultas('TICOTEC').subscribe(
          resp=>{
            this.tipo_consultas = JSON.parse(resp);

            if (this.tipo_consultas.length > 0){
              this.consultaForm = new FormGroup({
                'consulta' : new FormControl(null, [Validators.required]),
                'tipo_consulta' : new FormControl(this.tipo_consultas[0].CORRELATIVO, [Validators.required])
              });
            }
          },
          error=>console.log(error)
        );

        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
          this.getArchivoLicEtapaFull();
        }
        this.getAclaracionesLicitacion();
    });
  }

  getAclaracionesLicitacion(){
    this.licitacionService.getAclaracionesLicitacion(this.idLicitacion).subscribe(resp=>{
      this.aclaracionesLicitacion = JSON.parse(resp);
    },  err =>{
      this.notifyService.showError(err.error.message, 'Obtener tipos de aclaracion.');
    });
  }

  getArchivoLicEtapaFull(){
    this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,5).subscribe(resp=>{
      this.archivos = JSON.parse(resp);
    });
  }

  private getConsultas(idLic: number, idPart: number){
    this.licitacionService.getConsultasPart(idLic,idPart).subscribe(resp=>{
      this.consultas = JSON.parse(resp);
    });
  }

  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }
  onGuardarConsulta(){

    if(this.consultaForm.invalid){
      this.notifyService.showWarning('Debe ingresar una consulta', 'Crear consulta');
      return;
    }

    // modificar la validacion para que quite todos los espacios en blanco, (ver el que esta en las respuesta a las consultas) 
    if (this.consultaForm.value.consulta.trim() === '') {
      this.notifyService.showWarning('Debe ingresar una consulta', 'Crear consulta');
      return;
    }

    const postData : {
      in_lic_correlativo: number; 
      in_etli_correlativo: number; 
      in_part_correlativo: number;  
      in_usuario: string; 
      in_domi_correlativo_tico: number; 
      in_consulta: string
    } = {
      in_lic_correlativo: this.idLicitacion, 
      in_etli_correlativo: this.idEtapaLic, 
      in_part_correlativo: this.idParticipante, 
      in_usuario:  '',
      in_domi_correlativo_tico: this.consultaForm.value.tipo_consulta ,
      in_consulta: this.consultaForm.value.consulta
    };
    
    this.licitacionService.postConsultaLic(postData).subscribe(resp=>{
      this.notifyService.showSuccess(JSON.parse(resp), 'Crear consulta');

      this.consultaForm = new FormGroup({
        'consulta' : new FormControl(null, [Validators.required]),
        'tipo_consulta' : new FormControl(this.tipo_consultas[0].CORRELATIVO, [Validators.required])
      });

      this.getConsultas(this.idLicitacion,this.idParticipante);  
    }, err =>{
      this.notifyService.showError(err.error.message, 'Crear consulta');
    });
  }

  back(): void {
    this.location.back();
  }

}
