<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" routerLink="/contratista"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Ver archivos licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <!-- <div class="panel-heading">
                    <h4 class="panel-title">Ver archivos licitación</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div> -->
                <div class="bg-white p3-rem rounded">
                    <div class="row mb-4">
                        <div class="col-12">
                            <h4>Ver archivos licitación</h4>
                        </div>
                    </div>
                    <table id="data-table-default" class="table table-striped table-td-valign-middle mb-0">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Nombre archivo</th>
                                <th class="text-nowrap">Descripción</th>
                                <th class="text-nowrap">Etapa</th>
                                <th class="text-nowrap">Fecha creación</th>
                                <th class="text-nowrap">Subido por</th>
                                <th class="text-nowrap"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of archivosLicitacion">
                                <td class="text-break">
                                    <i class="fas fa-file text-primary mr-2"></i>
                                    <span>
                                        {{item.DESCRIPCION}} 
                                    </span>
                                </td>
                                <td class="text-break">
                                    <span>
                                        {{item.DESCRIPCION_ARCHIVO}} 
                                    </span>
                                </td>
                                <td  class="text-break">
                                    <span>
                                        {{item.ETAPA_DESCRIPCION}}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{item.FECHA_ARCHIVO}}
                                    </span>
                                </td>
                                <td class="text-break">
                                    <span>
                                        {{item.USUARIO}}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end #content -->