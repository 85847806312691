<!-- begin #content -->
<div id="content" class="content" *ngIf="!isChildren">
    <!-- begin row -->
    <div class="row">
        <div class="col-6">
            <!-- <ol class="breadcrumb float-xl-right">
                <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
                <li class="breadcrumb-item active">Cargar itemizado</li>
            </ol> -->
            <!-- end breadcrumb -->
            <!-- begin page-header -->
            <h1 class="page-header">Licitación #{{idLicitacion}}</h1>
        </div>
        <div class="col-6 text-right">
            <button *ngIf="idOrigen == 0" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4"
                routerLink="/licitaciones">
                <i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                    style="color: white;"></i> Volver
            </button>
            <button *ngIf="idOrigen == 1" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4"
                routerLink="/edit-licitacion/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">
                <i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                    style="color: white;"></i> Volver
            </button>
            <ng-container *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                <button *ngIf="!actaInicio" class="btn btn-primary mr-4" (click)="save()">Guardar</button>
            </ng-container>
        </div>
    </div>
</div>
<div [ngClass]="{'content': !isChildren}">
    <div class="bg-white" [ngClass]="{'p-20': !isChildren}">
        <ng-container *ngIf="actaInicio">
            <div class="row mb-3">
                <div class="col-12">
                    <h2>Cargar itemizado</h2>
                </div>
                <div class="col-md-4 col-6">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" name="adjunto" id="adjunto"
                            (change)="incomingfile($event)" accept=".xlsx">
                        <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                    </div>
                </div>
                <div class="col-md-2 col-6">
                    <button *ngIf="file" class="btn tag text-orange" (click)="cargarExcel()">
                        Previsualizar
                    </button>
                </div>
            </div>
        </ng-container>
        <table class="table" *ngIf="data?.length > 0">
            <thead class="thead-darkk">
                <th>POS</th>
                <th>SPOS</th>
                <th>ITEM</th>
                <th>UNIDAD</th>
                <th>CANTIDAD</th>
                <th>PRECIO UNITARIO</th>
                <th>TOTAL</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of data" [ngStyle]="{'background-color': item.POS ? '#E9ECEF' : 'white' }">
                    <td>{{ item.POS }}</td>
                    <td>{{ item.SPOS }}</td>
                    <td>{{ item.ITEM }}</td>
                    <td>{{ item?.UNIDAD}}</td>
                    <td class="text-right">
                        {{ item?.CANTIDAD | number:'1.2'}}</td>
                    <td class="text-right">
                        {{ item?.PRECIO | number:'1.2'}}</td>
                    <td class="text-right">
                        {{ item?.CANTIDAD * item?.PRECIO | number:'1.2' }}</td>
                </tr>
            </tbody>
            <tfoot>
                <th>Total</th>
                <th colspan="6" class="text-right">
                    {{sumTotal() | number:'1.2'}}</th>
            </tfoot>
        </table>
        <br>
        <hr>
        <br>
        <div class="row mb-3" *ngIf="objItemizado">
            <div class="col-12 mb-3">
                <h2>Itemizado actual</h2>
            </div>
            <table class="table">
                <thead class="thead-darkk">
                    <th>POS</th>
                    <th>SPOS</th>
                    <th>ITEM</th>
                    <th>UNIDAD</th>
                    <th>CANTIDAD</th>
                    <th>PRECIO UNITARIO</th>
                    <th>TOTAL</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of objItemizado">
                        <tr style="background-color: #E9ECEF">
                            <td>{{ item.pos }}</td>
                            <td>{{ item.descripcion }}</td>
                            <td>{{ item.ITEM }}</td>
                            <td>{{ item?.UNIDAD}}</td>
                            <td class="text-right">
                                {{ item?.CANTIDAD | number:'1.2'}}</td>
                            <td class="text-right">
                                {{ item?.PRECIO | number:'1.2'}}</td>
                            <td class="text-right">
                                {{ item?.CANTIDAD * item?.PRECIO | number:'1.2' }}</td>
                        </tr>
                        <tr *ngFor="let det of item.itemizadoDetalleLs">
                            <td></td>
                            <td>{{ det.pos }}</td>
                            <td>{{ det.item }}</td>
                            <td>{{ det?.unidad}}</td>
                            <td class="text-right">
                                {{ det?.cantidad | number:'1.2'}}</td>
                            <td class="text-right">
                                {{ det?.precio | number:'1.2'}}</td>
                            <td class="text-right">
                                {{ det?.cantidad * det?.precio | number:'1.2' }}</td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <th>Total</th>
                    <th colspan="6" class="text-right">
                        {{sumTotal2() | number:'1.2'}}</th>
                </tfoot>
            </table>
        </div>
    </div>
    <ng-container *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
        <div *ngIf="actaInicio" class="row">
            <div class="col-12 text-right">
                <button class="btn btn-primary" (click)="save()">Guardar</button>
            </div>
        </div>
    </ng-container>
</div>