import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { FileService } from "src/app/shared/services/file.service";
import { FormGroup, FormControl } from "@angular/forms";
import { ItemizadoService } from "src/app/shared/services/itemizado.service";
declare var require: any;
const FileSaver = require("file-saver");

export interface HeaderLicitiacion {
  SOLPE_CORRELATIVO: string;
  CONT_NOMBRE: string;
  CONT_NUMERO: string;
  DESCRIPCION: string;
  FECHA_CREACION: string;
  MONTO_SOLPE: number;
  MONEDA_SOLPE: number;
  DESCRIPCION_MONEDA_SOLPE: string;
  ETAPA_CORRELATIVO: number;
  CORRELATIVO_ETAPA: number;
  ETAPA_DESC: string;
  LICITACION_CORRELATIVO: number;
  FECHA_PUBLICACION: Date;
  CONSULTAS_APROBADAS: number;
  RESP_PROPCESO: number;
  CORRELATIVO_SOLPE: number;
  VALIDACION_LCT: number;
  OBSERVACION_LCT: null | string;
  MONTO_SOLPE_USD: number;
  ORDEN: number;
  VALIDACION_JAC: number;
  OBSERVACION_JAC: null | string;
  TIENE_ACLARACIONES_TEC: number;
  TIENE_ACLARACIONES_ECO: number;
  TIENE_NEGOCIACION: number;
}

@Component({
  selector: "app-view-aclaracion-economica",
  templateUrl: "./view-aclaracion-economica.component.html",
  styleUrls: ["./view-aclaracion-economica.component.css"],
  providers: [LicitacionService, EtapasService, FileService, ItemizadoService],
})
export class ViewAclaracionEconomicaComponent implements OnInit {
  idLicitacion: number;
  idParticipante: number;
  idEtapa: number;
  idEtapaLic: number;
  nombreEtapa: string;
  participantes: [];
  tipo_consultas: [];
  archivos64: Blob;
  filename: string;
  type: string;
  isDisable: boolean = true;
  aclaracionesEco: [];
  aclaracionEcoForm: FormGroup;
  idAclaracion: number;
  idArchivo: number;
  idUserSelected: number;
  nombreParticipanteStr: string;
  objLicitacion: HeaderLicitiacion;
  rolesParticipanteComision: any[];
  observacionAvanceEtapa: string;

  @ViewChild("inputFile") inputFile: ElementRef;
  idAclaracionSelected: number;
  partCorrelativoSelected: number;

  constructor(
    private route: ActivatedRoute,
    private etapaService: EtapasService,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private fileService: FileService,
    private objItemizadoService: ItemizadoService
  ) {
    this.idUserSelected = 0;
    this.nombreParticipanteStr = "";
    this.rolesParticipanteComision = [];
    this.observacionAvanceEtapa = "";
  }

  ngOnInit(): void {
    this.aclaracionEcoForm = new FormGroup({
      participante: new FormControl(0),
      tipo_aclaracion: new FormControl(0),
      desc_aclaracion: new FormControl(null),
      nombreArchivo: new FormControl(null),
    });

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];

      if (this.idEtapa) {
        this.etapaService.getEtapaId(this.idEtapa).subscribe((resp) => {
          if (resp) {
            this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
          }
        });
      }

      this.licitacionService
        .getParticipantesLic(this.idLicitacion, this.idEtapaLic)
        .subscribe(
          (resp) => {
            this.participantes = JSON.parse(resp);
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Obtener Participantes"
            );
          }
        );

      this.licitacionService.getTipoConsultas("TICOEC").subscribe((resp) => {
        this.tipo_consultas = JSON.parse(resp);
      });

      this.getHeaderLicitacion();
      this.getAclaraciones(this.idLicitacion, this.idEtapa);
      this.getSelectRolesParticipanteComision();
    });
  }

  detectFiles(event: any) {
    if (event.target.validity.valid) {
      this.isDisable = false;
    }
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.filename = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
          this.archivos64 = bs64;
        }
      };
    }
  }

  private getAclaraciones(idLic: number, idEtapa: number) {
    this.aclaracionEcoForm = new FormGroup({
      participante: new FormControl(0),
      tipo_aclaracion: new FormControl(0),
      desc_aclaracion: new FormControl(null),
      nombreArchivo: new FormControl(null),
    });
    this.licitacionService
      .getAclaracionLic(idLic, idEtapa)
      .subscribe((resp) => {
        this.aclaracionesEco = JSON.parse(resp);
      });
  }

  onDescargar(fileName: string) {
    this.fileService.downloadFile(fileName).subscribe(
      (resp) => {
        FileSaver.saveAs(resp, fileName);
        this.notifyService.showSuccess("Descargando ...", "Descargar archivos");
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Descargar archivos");
      }
    );
  }

  onGuardarAclaracion() {
    if (this.idLicitacion) {
      const postData: {
        in_lic_correlativo: number;
        in_etli_correlativo: number;
        in_part_correlativo: number;
        in_usuario: string;
        in_domi_correlativo_tico: number;
        in_consulta: string;
      } = {
        in_lic_correlativo: this.idLicitacion,
        in_etli_correlativo: this.idEtapaLic,
        in_part_correlativo: this.aclaracionEcoForm.value.participante,
        in_usuario: "",
        in_domi_correlativo_tico: this.aclaracionEcoForm.value.tipo_aclaracion,
        in_consulta: this.aclaracionEcoForm.value.desc_aclaracion,
      };

      this.licitacionService.postAclaracion(postData).subscribe(
        (resp) => {
          this.idAclaracion = JSON.parse(resp)[0].ACLA_CORRELATIVO;
          this.notifyService.showSuccess(
            "Aclaración enviada correctamente.",
            "Aclaración tecnica"
          );
          if (this.archivos64) {
            const postData: {
              in_det_etapa_lic: number;
              files: Blob;
              fileName: string;
              type: string;
              in_usuario: string;
              in_part_correlativo?: number;
              publico: number;
              descripcion_input_archivo: string;
              idLicitacion: number;
              idEtapaLicitacion: number;
              correlativoGrupoArchivo: number;
            } = {
              in_det_etapa_lic: this.idEtapaLic,
              files: this.archivos64,
              fileName: this.filename,
              type: this.type,
              in_usuario: "",
              in_part_correlativo: this.aclaracionEcoForm.value.participante,
              publico: 1,
              descripcion_input_archivo:
                this.aclaracionEcoForm.value.nombreArchivo == null ||
                this.aclaracionEcoForm.value.nombreArchivo == ""
                  ? this.filename
                  : this.aclaracionEcoForm.value.nombreArchivo,
              idLicitacion: this.idLicitacion,
              idEtapaLicitacion: this.idEtapa,
              correlativoGrupoArchivo: 0,
            };
            this.licitacionService.postArchivosLicitacion(postData).subscribe(
              (resp) => {
                this.notifyService.showSuccess(
                  "Archivo subido correctamente",
                  "Subir archivos licitacion"
                );
                // usar proceso relacion de archivo con el id de la aclaracion.
                this.idArchivo = JSON.parse(resp)[0].SEQ_ARCHDETETLIC;
                if (this.idAclaracion && this.idArchivo) {
                  const putData: {
                    in_acdetlic_acl_correlativo: number;
                    in_archetlic_acl_correlativo: number;
                    in_archetlic_resp_correlativo: number;
                    in_usuario: string;
                    in_accion: string;
                    idLicitacion: number;
                    oferenteNombre: string;
                  } = {
                    in_acdetlic_acl_correlativo: this.idAclaracion,
                    in_archetlic_acl_correlativo: this.idArchivo,
                    in_archetlic_resp_correlativo: null,
                    in_usuario: "",
                    in_accion: "ACLARACION",
                    idLicitacion: this.idLicitacion,
                    oferenteNombre: "",
                  };
                  this.licitacionService
                    .putAclaracionArchivo(putData)
                    .subscribe(
                      (resp) => {
                        this.getAclaraciones(this.idLicitacion, this.idEtapa);
                      },
                      (err) => {
                        this.notifyService.showError(
                          err.error.message,
                          "Archivo Aclaración tecnica"
                        );
                      }
                    );
                }
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Subir archivos licitacion"
                );
              }
            );

            this.archivos64 = null;
            this.filename = "";
            this.type = "";
            this.inputFile.nativeElement.value = "";
          }
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Aclaración tecnica");
        }
      );
    }
  }

  selectUserPatarticipante(idUser: number, nombreParticipante: string) {
    this.idUserSelected = idUser;
    this.nombreParticipanteStr = nombreParticipante;
  }

  permitirCorreccionPresupuesto() {
    const postData = {
      idUser: this.idUserSelected,
      idLicitacion: this.idLicitacion,
    };

    this.objItemizadoService
      .putUpdatePermitirCorreccionPresupuesto(postData)
      .subscribe(
        (resp) => {
          this.licitacionService
            .getParticipantesLic(this.idLicitacion, this.idEtapaLic)
            .subscribe(
              (resp) => {
                this.participantes = JSON.parse(resp);
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Obtener Participantes"
                );
              }
            );
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Permitir corrección presupuesto"
          );
        }
      );
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
          console.log(this.rolesParticipanteComision);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  getHeaderLicitacion() {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        this.objLicitacion = JSON.parse(resp)[0] as HeaderLicitiacion;
        this.idEtapa = this.objLicitacion.ETAPA_CORRELATIVO;
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cabecera licitación"
        );
      }
    );
  }

  aceptarAclaracionesEco() {
    const postData = {
      validacion: 1,
      idLicitacion: this.idLicitacion,
    };

    this.licitacionService.postUpdateTieneAclaracionesEco(postData).subscribe(
      (resp) => {
        this.licitacionService
          .getHeaderLicitacionId(this.idLicitacion)
          .subscribe(
            (resp) => {
              this.objLicitacion = JSON.parse(resp)[0] as HeaderLicitiacion;
              this.idEtapa = this.objLicitacion.ETAPA_CORRELATIVO;

              this.notifyService.showSuccess(
                "Aclaraciones aceptadas correctamente",
                "Aclaraciones tecnica"
              );
            },
            (err) => {
              this.notifyService.showError(
                err.error.message,
                "Obtener cabecera licitación"
              );
            }
          );
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Aclaraciones tecnica");
      }
    );
  }

  async onAvanzaEtapa() {
    if (this.observacionAvanceEtapa.trim() === "") {
      this.notifyService.showWarning(
        "Para avanzar de etapa, primero debe ingresar al menos una observación",
        "Avanzar etapa"
      );
      return;
    } else {
      const postData = {
        in_lic_correlativo: this.idLicitacion,
        in_etapa_correlativo: this.idEtapa,
        in_observaciones: this.observacionAvanceEtapa,
      };

      this.etapaService.postAvanzaEtapaLic(postData).subscribe(
        (resp) => {
          this.licitacionService
            .getHeaderLicitacionId(this.idLicitacion)
            .subscribe(
              (resp) => {
                this.objLicitacion = JSON.parse(resp)[0] as HeaderLicitiacion;
                this.idEtapa = this.objLicitacion.ETAPA_CORRELATIVO;
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Obtener cabecera licitación"
                );
              }
            );

          this.notifyService.showSuccess(
            "La licitacion avanzó de etapa correctamente",
            "Avanzar etapa"
          );
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Avanzar etapa");
        }
      );
    }
  }

  onAclaracionSelectedToVisible(
    idAclaracionDetalle: number,
    partCorrelativo: number
  ) {
    this.idAclaracionSelected = idAclaracionDetalle;
    this.partCorrelativoSelected = partCorrelativo;
  }

  aclaracionVisibleOferente() {
    this.licitacionService
      .postUpdateAclaracionVisible({
        idAclaracionDetalle: this.idAclaracionSelected,
        idLicitacion: this.idLicitacion,
        partCorrelativo: this.partCorrelativoSelected,
      })
      .subscribe(
        (resp) => {
          this.getHeaderLicitacion();
          this.getAclaraciones(this.idLicitacion, this.idEtapa);
          this.notifyService.showSuccess(
            "Aclaración enviada correctamente",
            "Aclaraciones tecnicas"
          );
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Aclaraciones tecnicas"
          );
        }
      );
  }
}
