<!-- begin #content -->
<div id="content" class="content">
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Editar licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{id}}</h1>
    <!-- end page-header -->
    <!-- begin timeline -->
    <ul class="timeline">
        <li *ngFor="let item of etapasLicitacion">
            <!-- begin timeline-time -->
            <div class="timeline-time">
                <span class="date">{{item.ETAPA_FECHA_CREACION }}</span>
                <span class="time">{{item.ETAPA_HORA_CREACION }}</span>
            </div>
            <!-- end timeline-time -->
            <!-- begin timeline-icon -->
            <div class="timeline-icon">
                <a href="javascript:;">&nbsp;</a>
            </div>
            <!-- end timeline-icon -->
            <!-- begin timeline-body -->
            <div class="timeline-body">
                <div class="timeline-header">
                    <span class="username">
                        <a *ngIf="item.ETAPA_CORRELATIVO > 0 && item.ETAPA_ACTUAL==='S'" routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">{{item.ETAPA_DESCRIPCION}}</a> 
                        <a *ngIf="item.ETAPA_CORRELATIVO > 0 && item.ETAPA_ACTUAL==='N'" >{{item.ETAPA_DESCRIPCION}}</a> 
                        <a *ngIf="item.ETAPA_CORRELATIVO === 0" >{{item.ETAPA_DESCRIPCION}}</a> 
                    </span>
                </div>
                <div class="timeline-content">
                    <p>
                        Etapa fue creada el {{item.ETAPA_FECHA_CREACION}} y tiene como fecha de inicio {{item.ETAPA_FECHA_INICIO}} hasta {{item.ETAPA_FECHA_TERMINO}}
                    </p>
                </div>
                <!-- <div class="timeline-likes" >
                    <div class="stats-right">
                        <a routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}" class="btn btn-primary f-s-12 rounded-corner" type="button">Ir</a>
                    </div>
                </div> -->
                <div class="timeline-likes" *ngIf="item.ETAPA_ACTUAL==='S'">
                    <div class="stats-right">
                        <a routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}" class="btn btn-primary f-s-12 rounded-corner" type="button">Ir</a>
                    </div>
                </div>
            </div>
            <!-- end timeline-body -->
        </li>
    </ul>
    <!-- end timeline -->
</div>
<!-- end #content -->