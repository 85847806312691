import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LicitacionService } from '../shared/services/licitacion.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-mantenedor-doc-requeridos-oferente',
  templateUrl: './mantenedor-doc-requeridos-oferente.component.html',
  styleUrls: ['./mantenedor-doc-requeridos-oferente.component.css']
})
export class MantenedorDocRequeridosOferenteComponent implements OnInit {
  docRequeridoSeleccionadoDescripcion: string;
  documentosRequeridosOferente: any[];
  documentosRequeridosOferenteFiltered: any[];
  formCreateDocRequeridoOferente: FormGroup;
  formUpdateDocRequeridoOferente: FormGroup;
  documentoRequeridoCorrelativoSelected: number;
  tiposDocumentos: any[];

  @ViewChild('closeModalCrearDocRequeridoOferente') closeModalCrearDocRequeridoOferente: ElementRef;
  @ViewChild('closeModalEliminarDocRequeridoOferente') closeModalEliminarDocRequeridoOferente: ElementRef;
  @ViewChild('closeModalUpdateDocRequeridoOferente') closeModalUpdateDocRequeridoOferente: ElementRef;

  constructor(
    private licitacionService: LicitacionService, 
    private notifyService: NotificationService
  ) {
    this.docRequeridoSeleccionadoDescripcion = '';
    this.documentosRequeridosOferente = [];
    this.documentosRequeridosOferenteFiltered = [];
    this.documentoRequeridoCorrelativoSelected = 0;
    this.tiposDocumentos = [];

    this.formCreateDocRequeridoOferente = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      tipo: new FormControl('1', Validators.required)
    });

    this.formUpdateDocRequeridoOferente = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      tipo: new FormControl('1', Validators.required),
      vigente: new FormControl('0', Validators.required)
    });
  }

  ngOnInit(): void {
    this.getDocumentosRequeridosOferente();
    this.getSelectTiposDocAdministrativo();
  }

  editarDocRequeridoOferente(correlativo: number){
    this.documentoRequeridoCorrelativoSelected = correlativo;
    const docRequeridoFilter = this.documentosRequeridosOferente.filter(doc => doc.correlativo === correlativo);
    
    this.docRequeridoSeleccionadoDescripcion = docRequeridoFilter[0].descripcion;

    this.formUpdateDocRequeridoOferente = new FormGroup({
      descripcion: new FormControl(docRequeridoFilter[0].descripcion, Validators.required),
      tipo: new FormControl(docRequeridoFilter[0].tipo_doc_correlativo, Validators.required),
      vigente: new FormControl(docRequeridoFilter[0].vigente, Validators.required)
    });
  }

  eliminarDocRequeridoOferente(correlativo: number){
    this.documentoRequeridoCorrelativoSelected = correlativo;
    const docRequeridoFilter = this.documentosRequeridosOferente.filter(doc => doc.correlativo === correlativo); 

    this.docRequeridoSeleccionadoDescripcion = docRequeridoFilter[0].descripcion;
  }

  onCrearDocRequeridoOferente(){
    if (this.formCreateDocRequeridoOferente.invalid) {
      this.notifyService.showWarning('Formulario inválido', 'Debe llenar todos los campos para continuar');
      return;
    }

    const postDataCrearDocRequeridoOferente = {
      descripcionDocReq: this.formCreateDocRequeridoOferente.value.descripcion,
      tipoDoc: this.formCreateDocRequeridoOferente.value.tipo
    };

    this.licitacionService.postCreateDocumentoRequeridoOferente(postDataCrearDocRequeridoOferente).subscribe(
      resp => {
        this.getDocumentosRequeridosOferente();
        this.resetFormCreateDocRequeridoOferente();
        this.notifyService.showSuccess('Se ha creado el documento requerido', 'Documento requerido creado');
        this.closeModalCrearDocRequeridoOferente.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al crear el documento requerido');
      }
    );
  }

  onUpdateDocRequeridoOferente(){
    if (this.formUpdateDocRequeridoOferente.invalid) {
      this.notifyService.showWarning('Formulario inválido', 'Debe llenar todos los campos para continuar');
      return;
    }

    const postDataUpdateDocRequeridoOferente = {
      correlativo: this.documentoRequeridoCorrelativoSelected,
      descripcionDocReq: this.formUpdateDocRequeridoOferente.value.descripcion,
      tipoDoc: this.formUpdateDocRequeridoOferente.value.tipo,
      vigente: this.formUpdateDocRequeridoOferente.value.vigente
    };

    this.licitacionService.postUpdateDocumentoRequeridoOferente(postDataUpdateDocRequeridoOferente).subscribe(
      resp => {
        this.getDocumentosRequeridosOferente();
        this.resetFormUpdateDocRequeridoOferente();
        this.notifyService.showSuccess('Se ha actualizado el documento requerido', 'Documento requerido actualizado');
        this.closeModalUpdateDocRequeridoOferente.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al actualizar el documento requerido');
      }
    );
  }

  onEliminarDocRequeridoOferente(){
    const postDataDeleteDocRequeridoOferente = {
      correlativo: this.documentoRequeridoCorrelativoSelected
    }

    this.licitacionService.postDeleteDocumentoRequeridoOferente(postDataDeleteDocRequeridoOferente).subscribe(
      resp => {
        this.getDocumentosRequeridosOferente();
        this.notifyService.showSuccess('Se ha actualizado el documento requerido', 'Documento requerido actualizado');
        this.closeModalEliminarDocRequeridoOferente.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al actualizar el documento requerido');
      }
    );
  }

  getDocumentosRequeridosOferente(){
    this.licitacionService.getSelectDocumentoRequeridoOferente().subscribe(
      resp=>{
        const documentosRequeridosOferente: any[] = JSON.parse(resp);

        if (documentosRequeridosOferente.length > 0) {
          this.documentosRequeridosOferente = documentosRequeridosOferente;
          this.documentosRequeridosOferenteFiltered = documentosRequeridosOferente;
        }else{
          this.documentosRequeridosOferente = [];
          this.documentosRequeridosOferenteFiltered = [];
        }
      },
      error => {
        this.documentosRequeridosOferente = [];
        this.documentosRequeridosOferenteFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener los documentos Administrativos');
      }
    );
  }

  resetFormUpdateDocRequeridoOferente(){
    this.formUpdateDocRequeridoOferente = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      tipo: new FormControl('1', Validators.required),
      vigente: new FormControl('0', Validators.required)
    });
  }

  resetFormCreateDocRequeridoOferente(){
    this.formCreateDocRequeridoOferente = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      tipo: new FormControl('1', Validators.required)
    });
  }

  applyFilterDocRequeridoOferente(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.documentosRequeridosOferenteFiltered = this.documentosRequeridosOferente.filter(data => {
        return (
          (
            data?.correlativo.toString().toLowerCase().includes(filterValue) || 
            data?.descripcion.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.documentosRequeridosOferenteFiltered = this.documentosRequeridosOferente;
    }
  }

  getSelectTiposDocAdministrativo(){
    this.licitacionService.getSelectTiposDocAdministrativo().subscribe(
      resp=>{
        const tiposDocAdministrativos: any[] = JSON.parse(resp);

        if (tiposDocAdministrativos.length > 0) {
          this.tiposDocumentos = tiposDocAdministrativos;
        }else{
          this.tiposDocumentos = [];
        }
      },
      error => {
        this.tiposDocumentos = [];
        this.notifyService.showError(error.error.message, 'Error al obtener los documentos Administrativos');
      }
    );
  }

}
