<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
        routerLink="/contratista"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
            style="color: white;"></i> Volver</button>
    <!-- begin row -->
    <div class="row">
        <h4 style="margin-left: 10px;" class="panel-title">Licitación #{{idLicitacion}} <small>en
                {{nombreEtapa}}</small></h4>
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="bg-white rounded p3-rem" data-sortable-id="form-stuff-1">
                <!-- begin panel-body -->
                <div class="row">
                    <div class="col-12">
                        <h3 class="">{{licitacionData?.CONT_NOMBRE}}</h3>
                        <!-- <p>{{licitacionData?.CONT_NUMERO}}</p> -->
                    </div>
                    <!-- <div class="col-12">
                        
                    </div> -->
                    <div class="col-12">
                        <span class="text-muted">
                            {{licitacionData?.DESCRIPCION}}
                        </span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="mr-3 ml-3">
                        <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Licitación:
                            </span> {{idLicitacion}}</span>
                        <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Moneda:
                            </span> {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                        <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Fecha: </span>
                            {{licitacionData?.FECHA_CREACION}}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="mr-3 ml-3">
                        <span class="d-block d-md-inline align-center mt-3">
                            <span class="text-muted">Estado: </span>
                            <span *ngIf="licitacionData?.LICITACION_FINALIZADA != 1"
                                class="tab tab-green">{{nombreEtapa}}</span>
                            <span *ngIf="licitacionData?.LICITACION_FINALIZADA == 1"
                                class="tab tab-red">Adjudicada</span>
                        </span>
                    </div>
                </div>
                <div class="row border-top mt-4">
                    <div class="col-12"></div>
                </div>
                <div class="">
                    <div class="row mb-4 mt-4">
                        <span class="col-md-12 text-muted">Avance de tiempo:</span>
                        <div class="col-md-12" style="margin-top: 10px;">
                            <div class="progress rounded-corner progress-striped active">
                                <div *ngIf="porcentaje ===0" class="progress-bar bg-gray" style="width: 100%">
                                    0%
                                </div>
                                <div *ngIf="porcentaje > 0" class="progress-bar bg-orange"
                                    [ngStyle]="{'width': porcentaje+'%' }">
                                    {{porcentaje}}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-nav d-flex">
                        <div class="tablinks" [ngClass]="{'active': tabSelected == 0}" (click)="tabSelected = 0">
                            <span>
                                Cronograma
                            </span>
                        </div>
                        <div class="tablinks" [ngClass]="{'active': tabSelected == 1}" (click)="tabSelected = 1">
                            <span>
                                Archivos
                            </span>
                        </div>
                    </div>
                    <ng-container *ngIf="tabSelected === 0">
                        <div class="row">
                            <!-- <h5 class="col-md-2">Cronograma</h5> -->
                            <div class="col-md-12" style="margin-top: 10px;">
                                <table id="data-table-default" class="table table-striped table-td-valign-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-nowrap">Actividad</th>
                                            <!-- <th width="5%" class="text-nowrap">Nro. Horas</th> -->
                                            <th class="text-nowrap">Fecha Inicio</th>
                                            <th class="text-nowrap">Fecha Fin</th>
                                            <th class="text-nowrap">Obligatoria</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd gradeX" *ngFor="let item of cronograma">
                                            <td *ngIf="item.INTERNA === 0"
                                                [ngStyle]="{'background-color':item.ETAPA_ACTUAL === 'S' ? '#fefe88' : 'transparent'}">
                                                <i *ngIf="item.ETAPA_ACTUAL === 'S'"
                                                    class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-right"
                                                    style="color: black;"></i>
                                                <span *ngIf="item.ETAPA_ACTUAL === 'N' || item.ETAPA_ACTUAL === null">
                                                    {{ item.ETAPA_DESCRIPCION }}
                                                </span>
                                                <span *ngIf="item.ETAPA_ACTUAL === 'S'">
                                                    <a *ngIf="item.urlEtapaCronograma !== ''"
                                                        routerLink="{{ item.urlEtapaCronograma }}"> {{
                                                        item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ?
                                                            '(Etapa actual)': ''}}</strong></a>
                                                    <span *ngIf="item.urlEtapaCronograma === ''">{{
                                                        item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ?
                                                            '(Etapa actual)': ''}}</strong></span>
                                                </span>
                                            </td>
                                            <!-- <td width="5%" [ngStyle]="{'background-color':item.ETAPA_ACTUAL === 'S' ? '#fefe88' : 'transparent' }">
                                                <span>
                                                    {{item.CANT_HORAS}}
                                                </span>
                                            </td> -->
                                            <td *ngIf="item.INTERNA === 0"
                                                [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                                                <span>
                                                    {{item.FECHA_INICIO_ETAPA | date: 'dd/MM/yyyy'}}
                                                </span>
                                            </td>
                                            <td *ngIf="item.INTERNA === 0"
                                                [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                                                <span>
                                                    {{item.FECHA_TERMINO_ETAPA | date: 'dd/MM/yyyy'}}
                                                </span>
                                            </td>
                                            <td *ngIf="item.INTERNA === 0"
                                                [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                                                <span class="tab tab-yellow" *ngIf="item.ETAPA_OBLIGATORIA === 1">
                                                    Obligatoria
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tabSelected === 1">
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <table id="data-table-default" class="table table-striped table-td-valign-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-nowrap">Nombre archivo</th>
                                            <th class="text-nowrap">Descripción</th>
                                            <th class="text-nowrap">Etapa</th>
                                            <th class="text-nowrap">Fecha creación</th>
                                            <th class="text-nowrap">Subido por</th>
                                            <th class="text-nowrap"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd gradeX" *ngFor="let item of archivosLicitacion">
                                            <td class="text-break">
                                                <i class="fas fa-file text-primary mr-2"></i>
                                                <span>
                                                    {{item.DESCRIPCION}}
                                                </span>
                                            </td>
                                            <td class="text-break">
                                                <span>
                                                    {{item.DESCRIPCION_ARCHIVO}}
                                                </span>
                                            </td>
                                            <td class="text-break">
                                                <span>
                                                    {{item.ETAPA_DESCRIPCION}}
                                                </span>
                                            </td>
                                            <td>
                                                <span>
                                                    {{item.FECHA_ARCHIVO}}
                                                </span>
                                            </td>
                                            <td class="text-break">
                                                <span>
                                                    {{item.USUARIO}}
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                <app-view-file-buttons
                                                    [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>