import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IParticipante } from '../shared/services/licitacion.types';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { element } from 'protractor';

interface DestinatariosCorreo{
  correlativo: number;
  destinatario: string;
  correo: string;
}

@Component({
  selector: 'app-envio-correo-licitaciones',
  templateUrl: './envio-correo-licitaciones.component.html',
  styleUrls: ['./envio-correo-licitaciones.component.css']
})
export class EnvioCorreoLicitacionesComponent implements OnInit {

  @Input() idLicitacion: number;
  @Input() idEtapaLic: number;
  @Input() idEtapa: number;
  // participantes de la licitacion
  participantes: any[] = [];
  participantelicitacionSeleccionado: number = 0;
  // participantes de la comision evaluadora
  comisiones: any[] = [];
  participanteComisionSeleccionado: number = 0;
  // llenarla tabla que se va a mostrar una vez se selecione un participante
  destinatariosCorreo: DestinatariosCorreo[] = [];
  // variables de correo
  asuntoCorreo: string = '';
  cuerpoCorreo: string = '';
  adjunto: string = '';
  correosForm : FormGroup;
  // carga de archivos en el correo
  archivos64 : Blob;
  fileName: string;
  type: string;
  archivo: string;
  // usuario 
  userId: string;
  // logica
  enviando: boolean = false;
  
  @ViewChild('inputFile')
  inputFile: ElementRef;

  constructor(
    private licitacionService : LicitacionService,
    private notifyService : NotificationService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.correosForm = new FormGroup({
      'asuntoCorreo': new FormControl(''),
      'cuerpoCorreo': new FormControl(''),
      'destinatarioComision': new FormControl(0),
      'destinatarioParticipante': new FormControl(0),
      'nombreArchivo': new FormControl(''),
      'archivo': new FormControl(null)
    });

    this.correosForm = this.formBuilder.group({
      asuntoCorreo: ['', Validators.required],
      cuerpoCorreo: ['', Validators.required],
      destinatarioComision: [0, Validators.required],
      nombreArchivo: [''],
      destinatarioParticipante: [0, Validators.required],
    });

    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    
    this.getParticipantesComLicitacion();
    this.getParticipantesLicitacion();
  }

  getParticipantesComLicitacion(){
    this.licitacionService.getParticipantesComLicitacion(this.idLicitacion).subscribe(resp =>{
      this.comisiones = JSON.parse(resp);
      //log(this.comisiones);
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener Participantes Comisión Licitación');
    });
  }

  getParticipantesLicitacion(){
    this.licitacionService.getParticipantesLic(this.idLicitacion, this.idEtapaLic).subscribe(resp =>{
      this.participantes = JSON.parse(resp);
      //console.log(this.participantes);
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener Participantes Oferentes Licitación');
    });
  }

  onChangeDestinatarioComision(index: number){
    let comprobarExiste = this.destinatariosCorreo.filter(element => element.correlativo === this.comisiones[index].PART_CORRELATIVO);
    
    if (comprobarExiste.length <= 0) {
      this.destinatariosCorreo.push(
        {
          correlativo: this.comisiones[index].PART_CORRELATIVO,
          destinatario: this.comisiones[index].PART_NOMBRE,
          correo: this.comisiones[index].EMAIL,
        }
      );
    }

    this.correosForm.value.destinatarioComision = 0;
  }

  onChangeDestinatarioOferentes(index: number){
    let comprobarExiste = this.destinatariosCorreo.filter(element => element.correlativo === this.participantes[index].PART_CORRELATIVO);
    
    if (comprobarExiste.length <= 0) {
      this.destinatariosCorreo.push(
        {
          correlativo: this.participantes[index].PART_CORRELATIVO,
          destinatario: this.participantes[index].RAZON_SOCIAL,
          correo: this.participantes[index].CORREO_ELECTRONICO,
        }
      );
    }

    this.correosForm.value.destinatarioParticipante = 0;
  }

  async onEnviarCorreo(){
    await this.onSubirArchivo();
  }

  onEliminarDestinatario(index: number){
    this.destinatariosCorreo.splice(index, 1);
  }

  detectFiles(event: any){
    const reader = new FileReader(); 
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);       
      reader.onload = () => {
        bs64 = reader.result;        
        if (bs64 !== null){                      
              this.archivos64 = bs64; 
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ; 
        }
      };
    }
  }

  async onSubirArchivo(){
    const postData: { 
      in_det_etapa_lic: number;
      files: Blob; 
      fileName: string; 
      type : string; 
      in_usuario: string; 
      in_domi_correlativo_tiar: number; 
      publico: number; 
      descripcion_input_archivo: string;
      idLicitacion: number;
      idEtapaLicitacion: number;
      correlativoGrupoArchivo: number
    } = { 
      in_det_etapa_lic: this.idEtapaLic, 
      files : this.archivos64, 
      fileName: this.fileName, 
      type: this.type, 
      in_usuario: this.userId, 
      in_domi_correlativo_tiar: 35,
      publico: 1, 
      descripcion_input_archivo: this.correosForm.value.nombreArchivo == null || this.correosForm.value.nombreArchivo == '' ? this.fileName : this.correosForm.value.nombreArchivo,
      idLicitacion: this.idLicitacion,
      idEtapaLicitacion: this.idEtapa,
      correlativoGrupoArchivo: 0
    };
    
    if (this.destinatariosCorreo.length > 0){
      if (postData.files){
        await this.licitacionService.postArchivosLicitacion(postData).subscribe(resp => {
          this.adjunto = JSON.parse(resp)[1].nombre_archivo;
          this.destinatariosCorreo.map((element: DestinatariosCorreo) => {
            this.licitacionService.putEnviarCorreoLic(
              {
                pin_lic_correlativo: this.idLicitacion,
                asunto_correo: this.correosForm.value.asuntoCorreo,
                texto_correo: this.correosForm.value.cuerpoCorreo,
                destinatario_correo: element.correo,
                nombre_destinatario: element.destinatario,
                adjunto: this.adjunto
              }
            ).subscribe(
              resp =>{
                this.correosForm.reset();
                this.correosForm = this.formBuilder.group({
                  asuntoCorreo: ['', Validators.required],
                  cuerpoCorreo: ['', Validators.required],
                  destinatarioComision: [0, Validators.required],
                  nombreArchivo: [''],
                  destinatarioParticipante: [0, Validators.required],
                  archivo: [null],
                });
                this.destinatariosCorreo = [];
                this.notifyService.showSuccess('Correo enviado', 'Correo');
              },
              err =>{
                this.notifyService.showError(err.error.message, 'Obtener Participantes Oferentes Licitación');
              }
            );
          });
  
          this.notifyService.showSuccess('Archivo enviado correctamente.', 'Archivo enviado correctamente');
  
          this.archivos64 = null; 
          this.fileName = '';
          this.type = '';
          this.inputFile.nativeElement.value = "";
        }, err =>{
          this.notifyService.showError(err.error.message, 'Actualizar etapa');
        });
      }else{
        this.destinatariosCorreo.map((element: DestinatariosCorreo) => {
          this.licitacionService.putEnviarCorreoLic(
            {
              pin_lic_correlativo: this.idLicitacion,
              asunto_correo: this.correosForm.value.asuntoCorreo,
              texto_correo: this.correosForm.value.cuerpoCorreo,
              destinatario_correo: element.correo,
              nombre_destinatario: element.destinatario,
              adjunto: ''
            }
          ).subscribe(
            resp =>{
              this.correosForm.reset();
              this.correosForm = this.formBuilder.group({
                asuntoCorreo: ['', Validators.required],
                cuerpoCorreo: ['', Validators.required],
                destinatarioComision: [0, Validators.required],
                nombreArchivo: [''],
                destinatarioParticipante: [0, Validators.required],
                archivo: [null],
              });
              this.destinatariosCorreo = [];
              this.notifyService.showSuccess('Correo enviado', 'Correo');
            },
            err =>{
              this.notifyService.showError(err.error.message, 'Obtener Participantes Oferentes Licitación');
            }
          );
        });
      }
    }else{
      this.notifyService.showWarning('Debe seleccionar al menos un destinatario', 'Correo');
    }
  }
}
