import { Component, OnInit, TemplateRef, Input } from "@angular/core";
import { NotificationService } from "src/app/shared/services/notification.service";
import { FileService } from "src/app/shared/services/file.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
declare var require: any;
const FileSaver = require("file-saver");
@Component({
  selector: "app-view-file-buttons",
  templateUrl: "./view-file-buttons.component.html",
  styleUrls: ["./view-file-buttons.component.css"],
})
export class ViewFileButtonsComponent implements OnInit {
  urlVisualizador: string;
  modalRef: BsModalRef;
  @Input() nombreArchivo: string;
  isImage: boolean;

  constructor(
    private notifyService: NotificationService,
    private fileService: FileService,
    private modalService: BsModalService
  ) {
    this.isImage = false;
  }

  ngOnInit(): void {}

  onDescargar() {
    this.fileService.downloadFile(this.nombreArchivo).subscribe(
      (resp) => {
        FileSaver.saveAs(resp, this.nombreArchivo);
        this.notifyService.showSuccess("Descargando ...", "Descargar archivos");
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Descargar archivos");
      }
    );
  }

  onVisualizar(template: TemplateRef<any>) {
    const urlDocumento = this.fileService.getUrlFile(this.nombreArchivo);

    if (urlDocumento.includes("xlsx")) {
      this.urlVisualizador = `https://view.officeapps.live.com/op/embed.aspx?src=${urlDocumento}`;
      this.isImage = false;
    } else if (urlDocumento.includes("jpg") || urlDocumento.includes("png")) {
      this.urlVisualizador = urlDocumento;
      this.isImage = true;
    } else {
      this.urlVisualizador = `https://docs.google.com/gview?url=${urlDocumento}&embedded=true#:0.page.20`;
      this.isImage = false;
    }

    // this.urlVisualizador = !urlDocumento.includes('xlsx')
    //   ? `https://docs.google.com/gview?url=${urlDocumento}&embedded=true#:0.page.20`
    //   : `https://view.officeapps.live.com/op/embed.aspx?src=${urlDocumento}`;

    this.modalRef = this.modalService.show(template, { class: "modal-xl" });
  }
}
