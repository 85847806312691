import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

export class IItemizado {
  correlativo: number;
  descripcion: string;
  licitacion_correlativo: number;
  pos: number;
  itemizadoDetalleLs: IItemizadoDetalle[];
}
export class IItemizadoDetalle {
  correlativo: number;
  item: string;
  unidad: string;
  pos: number;
  cantidad: number;
  precio: number;
  presupuesto: number;
  itemizado_correlativo: number;
  precio_unitario: number;
}
@Injectable({
  providedIn: "root",
})
export class ItemizadoService {
  private header;
  private urlService: string = environment.apiUrl;
  constructor(private http: HttpClient) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }

  postItemizado(postData: IItemizado[]) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "itemizado/postItemizado",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getItemizado(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "itemizado/getItemizado/" + idLicitacion,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getItemizadoParticipante(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "itemizado/getItemizadoParticipante/" + idLicitacion,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getItemizadoResultado(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "itemizado/getItemizadoResultado/" + idLicitacion,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postRegistraPrespuesto(postData: IItemizadoDetalle) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "itemizado/postRegistraPrespuesto",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postEnviaPrespuesto(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    const postData = {
      idLicitacion,
    };
    return this.http.post(
      this.urlService + "itemizado/postEnviaPrespuesto",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putUpdatePermitirCorreccionPresupuesto(postData: {
    idUser: number;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}itemizado/putUpdatePermitirCorreccionPresupuesto`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postInsertPresupuestoOferenteLicitacion(postData: {
    montoTotalPresupuesto: number;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}itemizado/postInsertPresupuestoOferenteLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getPresupuestosOferentesLicitacion(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}itemizado/getPresupuestosOferentesLicitacion/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnPresupuestosOferentesLicitacion = [];
          for (const item in resp["returnPresupuestosOferentesLicitacion"]) {
            returnPresupuestosOferentesLicitacion.push({
              ...resp["returnPresupuestosOferentesLicitacion"][item],
            });
          }
          return JSON.stringify(returnPresupuestosOferentesLicitacion);
        })
      );
  }

  getSelectPresupuestoOferenteLicitacion(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}itemizado/getSelectPresupuestoOferenteLicitacion/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnPresupuestoOferenteLicitacion = [];
          for (const item in resp["returnPresupuestoOferenteLicitacion"]) {
            returnPresupuestoOferenteLicitacion.push({
              ...resp["returnPresupuestoOferenteLicitacion"][item],
            });
          }
          return JSON.stringify(returnPresupuestoOferenteLicitacion);
        })
      );
  }
}
