<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i
            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i>
        Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <!-- <button style="margin-right: 10px;" type="button" class="btn btn-primary" (click)="printPdf()"><i
                class="fas fa-lg fa-fw m-r-10 fa-print" style="color: white;"></i> Imprimir</button> -->
        <li class="breadcrumb-item"><a href="javascript:;">Cronograma</a></li>
        <li class="breadcrumb-item active">Consultas licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}}</h1>
    <!-- end page-header -->
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Consultas</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <!-- begin panel-body -->
                <div class="panel-body">
                    <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Num pregunta</th>
                                <th class="text-nowrap">Participante</th>
                                <th class="text-nowrap">Tipo consulta</th>
                                <th class="text-nowrap">Consulta</th>
                                <th class="text-nowrap">Respuesta</th>
                                <th class="text-nowrap">Fecha creación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of consultas">
                                <td class="text-break">{{getNumber(item.CORRELATIVO)}}</td>
                                <td class="text-break">{{item.RUT_PARTICIPANTE}} {{item.PART_NOMBRE}}</td>
                                <td class="text-break" width="5%">{{item.DESCRIPCION}}</td>
                                <td class="text-break">{{item.CONSULTA}}</td>
                                <td class="text-break">
                                    {{item.RESPUESTA}}
                                </td>
                                <td width="15%">{{item.FECHA_CREACION}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col col-md-12" *ngIf="archivo">
                        <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                            routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i
                                _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                style="color: white;"></i> Volver al cronograma</button>
                        <button class="btn btn-warning" type="button" (click)="onDescargar(archivo)"
                            style="margin-right: 10px;">Descargar consolidado de respuestas</button>
                    </div>
                </div>
                <!-- end panel-body -->
            </div>
        </div>
    </div>
    <!-- end #content -->