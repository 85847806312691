import { Component, Input, OnInit, AfterContentChecked } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: [LicitacionService, ReactiveFormsModule]
})
export class ChatComponent implements OnInit, AfterContentChecked {
  @Input() idLicitacion: number;
  messages: any[];
  userId: number;
  inputMenssage: FormControl;

  constructor(private objLicitacionesService: LicitacionService, objGlobalData: GlobalDataService, private notifyService : NotificationService,) { 
    const testData = [
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 15, usuario: 'Claudio Aliaga',fecha_creacion: "20240113T15:28:59",mensaje:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula in nibh volutpat, mollis ultricies dolor ullamcorper. Ut vitae magna ac libero varius porta."},
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 10, usuario: 'Marta Perez',fecha_creacion: "20240113T15:28:59",mensaje:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula in nibh volutpat, mollis ultricies dolor ullamcorper. Ut vitae magna ac libero varius porta."},
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 10, usuario: 'Marta Perez',fecha_creacion: "20240113T15:28:59",mensaje:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula in nibh volutpat, mollis ultricies dolor ullamcorper. Ut vitae magna ac libero varius porta."},
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 10, usuario: 'Marta Perez',fecha_creacion: "20240113T15:28:59",mensaje:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula in nibh volutpat, mollis ultricies dolor ullamcorper. Ut vitae magna ac libero varius porta."},
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 15, usuario: 'Claudio Aliaga',fecha_creacion: "20240113T15:28:59",mensaje:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 11, usuario: 'Danny Letelier',fecha_creacion: "20240113T15:28:59",mensaje:"Maecenas auctor ligula in nibh volutpat, mollis ultricies dolor ullamcorper. Ut vitae magna ac libero varius porta."},
      {correlativo: 1, lic_correlavio:0, usuario_correlativo: 9, usuario: 'Carla Lisperguer Carrasco',fecha_creacion: "20240113T15:28:59",mensaje:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula in nibh volutpat, mollis ultricies dolor ullamcorper. Ut vitae magna ac libero varius porta."},
    ]
    this.messages = testData;
    this.inputMenssage = new FormControl('');
    //this.idCargoActual = JSON.parse(localStorage.getItem('userData')).rolId;
    this.userId = +JSON.parse(localStorage?.getItem('userData')).userId;    
  }
  ngAfterContentChecked(): void {
    
  }

  ngOnInit(): void {
    this.getMessages();
  }

  getMessages()
  {
    this.objLicitacionesService.getLicitacionChat(this.idLicitacion).subscribe(
      resp =>{
        //const mensajes: any[] = JSON.parse(resp?.toString());

        let response: any = resp;

        if(response.data?.length > 0)
        {
          this.messages = response.data;
        }else{
          this.messages = []
        }
      },
      error => {
        this.notifyService.showError('Error', 'Ocurrio un error al comprobar las notificaciones enviadas.');
        console.log(error);
      },
      () => {
        // nada
      }
    );
  }

  sendMessage()
  {
    if(this.inputMenssage.value == '')
    {
      this.notifyService.showError('Error', 'Debe escribir un mensaje para enviar.');
      return
    }
    this.objLicitacionesService.postLicitacionChat({in_licitacion: this.idLicitacion, in_mensaje: this.inputMenssage.value}).subscribe(
      resp =>{
        //const mensajes: any[] = JSON.parse(resp?.toString());
        this.inputMenssage = new FormControl('');
        this.getMessages()
      },
      error => {
        this.notifyService.showError('Error', 'Ocurrio un error al comprobar las notificaciones enviadas.');
        console.log(error);
      },
      () => {
        // nada
      }
    );
  }


}
