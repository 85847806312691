import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import {
  CriteriosEvaluacionService,
  IGrupo,
} from "src/app/shared/services/criterios-evaluacion.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import * as moment from "src/assets/plugins/moment/moment";
import { PDF } from "src/app/shared/functions/pdf";
import { formatCurrency } from "src/app/shared/functions/common";
import { Location } from "@angular/common";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { ParticipantesService } from "src/app/shared/services/participantes.service";

@Component({
  selector: "app-resultados",
  templateUrl: "./resultados.component.html",
  styleUrls: ["./resultados.component.css"],
  providers: [CriteriosEvaluacionService],
})
export class ResultadosComponent implements OnInit {
  idLicitacion: number;
  objLicitacion: any;
  objGrupos: IGrupo[];
  obgParticipantes: any[];
  objResultados: any[];
  objPart: any[];
  numCols: number = 0;
  objParticipante: any[] = [];

  objEvaluadores: any[];
  objEvaluadoresResultados: any[];
  objComisionTecnica: any[];
  fechaHoy: string = moment().format("DD-MM-YYYY HH:mm:ss");
  downloadingPdf: boolean = false;

  idEtapa: number = 0;
  idEtapaLic: number = 0;
  oferentesAprobadorList: number[] = [];

  archivosLicitacion: any[];
  onVerArchivos: boolean;
  desempenoEvaluadores: any[];
  rolesParticipanteComision: any[];

  @ViewChild("closeModalGanadoresEvalTecnica")
  closeModalGanadoresEvalTecnica: ElementRef;
  observacionAvanceEtapa: string;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private criteriosServices: CriteriosEvaluacionService,
    private location: Location,
    private etapaService: EtapasService,
    private participantesServices: ParticipantesService
  ) {
    this.archivosLicitacion = [];
    this.onVerArchivos = false;
    this.desempenoEvaluadores = [];
    this.rolesParticipanteComision = [];
    this.observacionAvanceEtapa = "";
    this.isLoading = false;

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      // this.verificarLic(this.idLicitacion);
      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
          this.idEtapa = this.objLicitacion.ETAPA_CORRELATIVO;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );

      this.licitacionService.getPartLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.obgParticipantes = JSON.parse(resp);
          this.numCols = this.obgParticipantes.length;
          /* this.licitacionForm = new FormGroup({
            'solpe' : new FormControl(JSON.parse(resp)[0].SOLPE_CORRELATIVO),
            'nro_contrato': new FormControl(JSON.parse(resp)[0].CONT_NUMERO),
            'nombre_contrato' : new FormControl(JSON.parse(resp)[0].CONT_NOMBRE),
            'files': new FormArray ([])
          }); */
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );

      this.criteriosServices.getResultados(this.idLicitacion).subscribe(
        (resp) => {
          let response: any = resp;
          if (response.data?.length > 0) {
            this.objResultados = response.data;
            this.objPart = response.data;
            /* this.setPartList(); */
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );

      this.getComisionTecnicaLicitacion();
      this.getArchivoLicitacion(this.idLicitacion);
      this.getSelectRolesParticipanteComision();
    });
  }

  setPartList() {
    /* var list2 = this.objPart;
    for(let i in list2) {
      delete list2[i].correlativo;
      delete list2[i].descripcion;
      delete list2[i].grupo;
      delete list2[i].peso;
    }
    console.log(list2);
    console.log(this.objResultados); */
  }
  ngOnInit(): void {
    this.licitacionService.getDatosPart().subscribe((resp) => {
      //this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
      this.getGrupos();
      /* if (this.idParticipante){
        this.getArchivoPartcipante();
      } */
    });
  }

  validateUsuarioEncargado() {
    if (
      this.objLicitacion.RESP_PROPCESO ==
      JSON.parse(localStorage.getItem("userData")).userId
    ) {
      return true;
    } else {
      return false;
    }
  }

  roundCalificacionPorcentual(value: number): number {
    return Math.round(value);
  }

  validarExistenParticipantesAprobadores() {
    const participantesAprobados: any[] = this.obgParticipantes.filter(
      (participante) => this.calTotal(participante.razon_social) >= 80
    );
    return participantesAprobados.length > 0;
  }

  back(): void {
    this.location.back();
  }

  getGrupos() {
    this.criteriosServices.getGrupos(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;
        if (response.data?.length > 0) {
          this.objGrupos = response.data;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }
  calCapitulo(idGrupo, Participante) {
    let sum = 0;

    let sumArr = this.objResultados?.filter((x) => x.grupo === idGrupo);

    for (let i = 0; i < sumArr?.length; i++) {
      for (const [key, value] of Object.entries(sumArr[i])) {
        if (key == Participante) {
          sum += +value * (sumArr[i].peso / 100);
          //sum += (+value);
        }
      }
      //sum += sumArr[i][Participante]
    }

    return sum;
  }
  calTotal(Participante) {
    let sum = 0;

    //let sumArr = this.objResultados.filter(x => x.grupo === idGrupo);

    for (let i = 0; i < this.objResultados?.length; i++) {
      for (const [key, value] of Object.entries(this.objResultados[i])) {
        if (key == Participante) {
          sum +=
            +value *
            (this.objResultados[i].peso / 100) *
            (this.getPesoGrupo(this.objResultados[i].grupo) / 100);
        }
      }
      //sum += sumArr[i][Participante]
    }

    return sum;
  }
  calSubCapitulo(idSubGrupo, Participante) {
    let sum = 0;

    let sumArr = this.objResultados?.filter(
      (x) => x.correlativo === idSubGrupo
    );

    for (let i = 0; i < sumArr?.length; i++) {
      for (const [key, value] of Object.entries(sumArr[i])) {
        if (key == Participante) {
          //sum += (+value)*(sumArr[i].peso / 100);
          sum += +value;
        }
      }
      //sum += sumArr[i][Participante]
    }

    return sum;
  }
  getPesoGrupo(idGrupo) {
    let Arr = this.objGrupos?.filter((x) => x.correlativo === idGrupo);

    if (Arr?.length > 0) {
      return Arr[0].peso;
    } else {
      return 0;
    }
  }

  getResultadosParticiapante(idParticipante: number, idGrupo: number) {
    this.criteriosServices
      .getResultadosEvaluadores(this.idLicitacion, idParticipante, idGrupo)
      .subscribe(
        (resp) => {
          let response: any = resp;
          this.objEvaluadoresResultados = response.data;
          /* if(response.data?.length > 0)
      {
        this.objEvaluadoresResultados = response.data;
      } */
          this.objParticipante = this.obgParticipantes.filter(
            (participante) => {
              return participante.CORRELATIVO == idParticipante;
            }
          );
          console.log(this.objParticipante);
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
  }
  getKeys() {
    return this.objEvaluadoresResultados &&
      this.objEvaluadoresResultados.length > 0
      ? Object.keys(this.objEvaluadoresResultados[0])
      : [];
  }
  calTotalEvaluadores(Evaluador) {
    let sum = 0;

    //let sumArr = this.objResultados.filter(x => x.grupo === idGrupo);

    for (let i = 0; i < this.objEvaluadoresResultados?.length; i++) {
      for (const [key, value] of Object.entries(
        this.objEvaluadoresResultados[i]
      )) {
        if (key == Evaluador) {
          //sum += (((+value)*(this.objEvaluadoresResultados[i].peso / 100))*(this.getPesoGrupo(this.objEvaluadoresResultados[i].grupo)/100));
          sum += +value * (this.objEvaluadoresResultados[i].peso / 100);
        }
      }
      //sum += sumArr[i][Participante]
    }

    return sum;
  }

  getComisionTecnicaLicitacion() {
    this.licitacionService
      .getComisionTecnicaLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.objComisionTecnica = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener Comisión Técnica Licitación"
          );
        }
      );
  }

  async onDescargarPDF() {
    this.downloadingPdf = true;
    const doc = new PDF({ format: "A2", unit: "mm" }, [
      "",
      "Evaluaciones Emitidas",
    ]);

    doc.addLogo();
    doc.addTitle(`Licitacion ${this.objLicitacion.CONT_NOMBRE}`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 100;

    doc.identText(
      "Descripción",
      this.objLicitacion.DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Licitación",
      this.idLicitacion.toString(),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Solpe",
      this.objLicitacion?.SOLPE_CORRELATIVO,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Estado",
      this.objLicitacion?.ETAPA_DESC,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha Creación Licitación",
      this.objLicitacion?.FECHA_CREACION
        ? moment(this.objLicitacion?.FECHA_CREACION, "DD-MM-YYYY").format(
            "DD/MM/YYYY"
          )
        : "",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha Emisión Documento",
      this.objLicitacion?.FECHA_CREACION
        ? moment(new Date()).format("DD/MM/YYYY")
        : "",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    // doc.identText(
    //   "Moneda",
    //   this.objLicitacion?.DESCRIPCION_MONEDA_SOLPE || "Peso Chileno",
    //   iLengthTitleFirst,
    //   iLengthValueFirst
    // );

    // doc.identText(
    //   "Monto",
    //   formatCurrency(this.objLicitacion?.MONTO_SOLPE || ""),
    //   iLengthTitleFirst,
    //   iLengthValueFirst
    // );

    doc.increasePageY(10);

    doc.addTitle("Resultado Evaluación Técnica");

    doc.addTableHtml("#table_resultado");

    // //SEGUNDA PAGINA

    doc.iSection = 2;
    doc.addNewPage();

    doc.addTableHtml("#table_detalle");
    doc.increasePageY(10);

    doc.text(
      "Los abajo firmantes certifican los resultados de la comisión encargada:",
      doc.iMargin,
      doc.nPageYAxi
    );
    doc.increasePageY(20);

    this.objComisionTecnica.forEach((persona) => {
      doc.addSign(persona.PER_NOMBRE);
      doc.increasePageY(15);
    });

    doc.text("Observaciones:", doc.iMargin, doc.nPageYAxi);
    doc.increasePageY(5);

    doc.addFooter();
    doc.save(`resultado_eval_tecnica_${this.idLicitacion}.pdf`);
    this.downloadingPdf = false;
  }

  addListAprobados(partiCorrelativo: number) {
    let existeOferenteAprobado = this.oferentesAprobadorList.filter(
      (x) => x == partiCorrelativo
    );

    if (existeOferenteAprobado.length == 0) {
      this.oferentesAprobadorList.push(partiCorrelativo);
    }
  }

  comprobarListAprobados(partiCorrelativo: number) {
    let existeOferenteAprobado = this.oferentesAprobadorList.filter(
      (x) => x == partiCorrelativo
    );
    return existeOferenteAprobado.length > 0;
  }

  removeListAprobados(partiCorrelativo: number) {
    let existeOferenteAprobado = this.oferentesAprobadorList.filter(
      (x) => x == partiCorrelativo
    );
    if (existeOferenteAprobado.length > 0) {
      this.oferentesAprobadorList = this.oferentesAprobadorList.filter(
        (x) => x != partiCorrelativo
      );
    }
  }

  async onUpdateParticipante(partiCorrelativo: number) {
    const postData: {
      in_licitacion: number;
      in_etapa: number;
      in_part_correlativo: number;
      in_usuario: string;
      in_activo: string;
      in_motivo_descalificacion: string;
      in_motivo_reuncia_correlativo: number;
    } = {
      in_licitacion: this.idLicitacion,
      in_etapa: this.idEtapaLic,
      in_part_correlativo: partiCorrelativo,
      in_usuario: "",
      in_activo: "N",
      in_motivo_descalificacion: "",
      in_motivo_reuncia_correlativo: 0,
    };
    this.licitacionService.postPostulanteLic(postData).subscribe(
      (resp) => {
        this.notifyService.showSuccess(
          "Participantes aprobados",
          "Participante"
        );
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Participante");
      }
    );
  }

  async onAvanzaEtapa() {
    if (this.observacionAvanceEtapa.trim() === "") {
      this.isLoading = false;
      this.notifyService.showWarning(
        "Para avanzar de etapa, primero debe ingresar al menos una observación",
        "Avanzar etapa"
      );
      return;
    } else {
      const postData = {
        in_lic_correlativo: this.idLicitacion,
        in_etapa_correlativo: this.idEtapa,
        in_observaciones: this.observacionAvanceEtapa,
      };

      this.etapaService.postAvanzaEtapaLic(postData).subscribe(
        (resp) => {
          this.isLoading = false;
          this.notifyService.showSuccess(
            "La licitacion avanzó de etapa correctamente",
            "Avanzar etapa"
          );
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Avanzar etapa");
        }
      );
    }
  }

  confirmarParticipantesAprobados() {
    this.isLoading = true;

    if (this.observacionAvanceEtapa.trim() === "") {
      this.isLoading = false;
      this.notifyService.showWarning(
        "Para avanzar de etapa, primero debe ingresar al menos una observación",
        "Avanzar etapa"
      );
      return;
    }

    for (let index = 0; index < this.obgParticipantes.length; index++) {
      const element = this.obgParticipantes[index];

      if (this.comprobarListAprobados(element.CORRELATIVO) === true) {
        this.updateAprobacionEvalTecOferente(element.CORRELATIVO).then(() => {
          this.oferentesAprobadorList = [];
        });
      }
    }

    this.onAvanzaEtapa().then(() => {
      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
          this.idEtapa = this.objLicitacion.ETAPA_CORRELATIVO;

          this.licitacionService
            .getPartLicitacionId(this.idLicitacion)
            .subscribe(
              (resp) => {
                this.obgParticipantes = JSON.parse(resp);
                this.numCols = this.obgParticipantes.length;

                this.criteriosServices
                  .getResultados(this.idLicitacion)
                  .subscribe(
                    (resp) => {
                      let response: any = resp;
                      if (response.data?.length > 0) {
                        this.objResultados = response.data;
                        this.objPart = response.data;
                        this.isLoading = false;

                        this.getComisionTecnicaLicitacion();

                        this.closeModalGanadoresEvalTecnica.nativeElement.click();
                        this.back();
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Obtener cabecera licitación"
                );
              }
            );
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
    });
  }

  addListAprobadosAutomatico() {
    for (let index = 0; index < this.obgParticipantes.length; index++) {
      const participantes = this.obgParticipantes[index];
      let isGruposAproabados: boolean = true;

      for (let index = 0; index < this.objGrupos.length; index++) {
        const grupo = this.objGrupos[index];

        if (
          this.calCapitulo(grupo.correlativo, participantes.razon_social) < 80
        ) {
          isGruposAproabados = false;
          break;
        }
      }

      if (isGruposAproabados) {
        this.addListAprobados(participantes.CORRELATIVO);
      }
    }

    console.log(this.oferentesAprobadorList);
  }

  validateParticipantesAprobados(razonSocial: string): boolean {
    let isGruposAproabados: boolean = true;

    for (let index = 0; index < this.objGrupos?.length; index++) {
      const grupo = this.objGrupos[index];

      if (this.calCapitulo(grupo.correlativo, razonSocial) < 80) {
        isGruposAproabados = false;
        break;
      }
    }

    return isGruposAproabados;
  }

  private getArchivoLicitacion(idLicitacion: number) {
    if (idLicitacion) {
      this.licitacionService.getArchivosLicitacion(this.idLicitacion).subscribe(
        (resp) => {
          this.archivosLicitacion = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener archivos licitación"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Falta el id de la licitación",
        "Obtener archivos licitación"
      );
    }
  }

  evaluacionesCompletadas() {
    this.criteriosServices
      .getSelectEvaluacionesCompletadas(this.idLicitacion)
      .subscribe(
        (resp) => {
          console.log(JSON.parse(resp));
          this.desempenoEvaluadores = JSON.parse(resp);
        },
        (err) => {
          this.desempenoEvaluadores = [];
          this.notifyService.showError(
            err.error.message,
            "Evaluaciones completadas"
          );
        }
      );
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  async updateAprobacionEvalTecOferente(partiCorrelativo: number) {
    const postData = {
      part_correlativo: partiCorrelativo,
      lic_correlativo: this.idLicitacion,
    };

    this.participantesServices
      .postUdpateAprobacionEvaluacionTec(postData)
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Participantes aprobados",
            "Participante"
          );
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Permitir corrección presupuesto"
          );
        }
      );
  }

  validateEtapaVerBotonArchivos(): boolean {
    if (this.objLicitacion.ETAPA_CORRELATIVO == 10) {
      return false;
    } else if (this.objLicitacion.ETAPA_CORRELATIVO == 18) {
      return false;
    } else if (this.objLicitacion.ETAPA_CORRELATIVO == 19) {
      return false;
    } else if (this.objLicitacion.ETAPA_CORRELATIVO == 20) {
      return false;
    } else {
      return true;
    }
  }
}
