<!-- begin #content -->
<div id="content" class="content">
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Subir archivo etapa licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <ng-container *ngIf="idEtapa != 6 && idEtapa != 10">
        <div class="row">
            <!-- begin col-6 -->
            <div class="col-12 col-md-6">
                <!-- begin panel -->
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="bg-white p3-rem rounded">
                        <div class="row mb-4">
                            <div class="col-12">
                                <h4>Subir archivo a {{nombreEtapa}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label class="col-form-label col-3">Descripción Archivo <span
                                            class="text-danger">*</span></label>
                                    <div class="col-9">
                                        <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                            [(ngModel)]="nombreArchivo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row m-b-10 align-items-center">
                            <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                                    type="file" (change)="detectFiles($event)" />
                            </div>
                        </div>
                        <app-view-etapa-files-table *ngIf="archivos.length > 0" [archivos]="archivos"
                            [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                        <div class="row">
                            <div class="col-xl-12 text-right">
                                <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                    (click)="back()"><i _ngcontent-ftv-c76=""
                                        class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                        style="color: white;"></i> Volver</button>
                                <button type="button" class="btn btn-primary" (click)="onSubirArchivo()">Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="idEtapa == 6">
        <ng-container *ngIf="stepSelected === 0">
            <div class="bg-white p3-rem rounded">
                <div class="row">
                    <div class="col-12">
                        <h4 class="text-muted">Por favor, cargue los Documentos Administrativos requeridos.</h4>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        <div>
                            <form [formGroup]="formDocRequeridoOferente" (ngSubmit)="onAddDocRequeridoOferente()">
                                <div class="row form-group">
                                    <label for="docSelected" class="col-md-3 col-form-label">Seleccionar
                                        documento</label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="docSelected" #docSelected>
                                            <ng-container *ngFor="let doc of docRequeridos">
                                                <ng-container *ngIf="doc.archivos.length <= 0">
                                                    <option value="{{doc.correlativo_doc_requerido}}">
                                                        {{doc.descripcion}}</option>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row m-b-10 align-items-center">
                                    <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-9">
                                        <input #inputFile style="border-color: transparent;" class="form-control"
                                            name="archivo" type="file" (change)="detectFiles($event)" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button type="submit" class="btn btn-primary"
                                            [disabled]="formDocRequeridoOferente.invalid">Agregar</button>
                                    </div>
                                </div>
                            </form>
                            <br>
                            <br>
                        </div>
                    </div>
                    <div class="col-6">
                        <!-- desarrollar la parte de eliminar los archivos esto debe de actualizar el registro en base de datos y ademas elimiar el archivo seleccionado con el nombre de archivo que venga en el get -->
                        <div class="docRequeridoContainer" *ngFor="let doc of docRequeridos">
                            <div class="row">
                                <div class="col-8">
                                    <span class="text-muted">
                                        <ng-container *ngIf="doc.archivos.length > 0">
                                            <i class="fa fa-check text-success mr-2"></i>
                                        </ng-container>
                                        <ng-container *ngIf="doc.archivos.length <= 0">
                                            <i class="far fa-file-alt text-primary mr-2"></i>
                                        </ng-container>
                                        {{doc.descripcion}}
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- (click)="deleteDocRequeridoOferente(doc.correlativo)" -->
                                    <ng-container *ngIf="doc.archivos.length > 0">
                                        <div title="Eliminar Documento" class="btn rounded-icon mr-2"
                                            (click)="deleteDocRequeridoByOferente(doc.archivos[0].correlativo)">
                                            <i class="fa fa-trash-alt"></i>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                    (click)="back()"><i _ngcontent-ftv-c76=""
                                        class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                        style="color: white;"></i> Volver</button>
                                <button type="button" class="btn btn-primary" (click)="stepSelected = 1;"
                                    [disabled]="!docRequeridosValido">Siguiente</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="stepSelected === 1">
            <div class="row">
                <!-- begin col-6 -->
                <div class="col-12">
                    <!-- begin panel -->
                    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                        <div class="bg-white p3-rem rounded">
                            <div class="row mb-4">
                                <div class="col-12">
                                    <h4>Por favor, suba la Oferta Técnica descargada previamente en formato PDF</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label class="col-form-label col-3">Descripción Archivo <span
                                                class="text-danger">*</span></label>
                                        <div class="col-9">
                                            <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                                [(ngModel)]="nombreArchivo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row m-b-10 align-items-center">
                                <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                        class="text-danger">*</span></label>
                                <div class="col-md-9">
                                    <input #inputFile style="border-color: transparent;" class="form-control"
                                        name="archivo" type="file" (change)="detectFiles($event)" />
                                </div>
                            </div>
                            <app-view-etapa-files-table *ngIf="archivos.length > 0" [archivos]="archivos"
                                [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                            <div class="row">
                                <div class="col-xl-12 text-right">
                                    <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                        (click)="stepSelected = 0;"><i _ngcontent-ftv-c76=""
                                            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                            style="color: white;"></i> Volver</button>
                                    <button type="button" class="btn btn-primary"
                                        (click)="onSubirArchivo()">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="idEtapa == 10">
        <ng-container *ngIf="stepSelected === 0">
            <div class="bg-white p3-rem rounded">
                <div class="row">
                    <div class="col-12">
                        <h4 class="text-muted">Por favor, cargue los Documentos Administrativos requeridos.</h4>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-6">
                        <div>
                            <form [formGroup]="formDocRequeridoOferente" (ngSubmit)="onAddDocRequeridoOferente()">
                                <div class="row form-group">
                                    <label for="docSelected" class="col-md-3 col-form-label">Seleccionar
                                        documento</label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="docSelected" #docSelected>
                                            <ng-container *ngFor="let doc of docRequeridos">
                                                <ng-container *ngIf="doc.archivos.length <= 0">
                                                    <option value="{{doc.correlativo_doc_requerido}}">
                                                        {{doc.descripcion}}</option>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row m-b-10 align-items-center">
                                    <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                            class="text-danger">*</span></label>
                                    <div class="col-md-9">
                                        <input #inputFile style="border-color: transparent;" class="form-control"
                                            name="archivo" type="file" (change)="detectFiles($event)" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button type="submit" class="btn btn-primary"
                                            [disabled]="formDocRequeridoOferente.invalid">Agregar</button>
                                    </div>
                                </div>
                            </form>
                            <br>
                            <br>
                        </div>
                    </div>
                    <div class="col-6">
                        <!-- desarrollar la parte de eliminar los archivos esto debe de actualizar el registro en base de datos y ademas elimiar el archivo seleccionado con el nombre de archivo que venga en el get -->
                        <div class="docRequeridoContainer" *ngFor="let doc of docRequeridos">
                            <div class="row">
                                <div class="col-8">
                                    <span class="text-muted">
                                        <ng-container *ngIf="doc.archivos.length > 0">
                                            <i class="fa fa-check text-success mr-2"></i>
                                        </ng-container>
                                        <ng-container *ngIf="doc.archivos.length <= 0">
                                            <i class="far fa-file-alt text-primary mr-2"></i>
                                        </ng-container>
                                        {{doc.descripcion}}
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- (click)="deleteDocRequeridoOferente(doc.correlativo)" -->
                                    <ng-container *ngIf="doc.archivos.length > 0">
                                        <div title="Eliminar Documento" class="btn rounded-icon mr-2"
                                            (click)="deleteDocRequeridoByOferente(doc.archivos[0].correlativo)">
                                            <i class="fa fa-trash-alt"></i>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                    (click)="back()"><i _ngcontent-ftv-c76=""
                                        class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                        style="color: white;"></i> Volver</button>
                                <button type="button" class="btn btn-primary" (click)="stepSelected = 1;"
                                    [disabled]="!docRequeridosValido">Siguiente</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="stepSelected === 1">
            <div class="row">
                <!-- begin col-6 -->
                <div class="col-12">
                    <!-- begin panel -->
                    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                        <div class="bg-white p3-rem rounded">
                            <div class="row mb-4">
                                <div class="col-12">
                                    <h4>Subir archivo a {{nombreEtapa}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label class="col-form-label col-3">Descripción Archivo <span
                                                class="text-danger">*</span></label>
                                        <div class="col-9">
                                            <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                                [(ngModel)]="nombreArchivo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row m-b-10 align-items-center">
                                <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                        class="text-danger">*</span></label>
                                <div class="col-md-9">
                                    <input #inputFile style="border-color: transparent;" class="form-control"
                                        name="archivo" type="file" (change)="detectFiles($event)" />
                                </div>
                            </div>
                            <app-view-etapa-files-table *ngIf="archivos.length > 0" [archivos]="archivos"
                                [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                            <div class="row">
                                <div class="col-xl-12 text-right">
                                    <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                        (click)="stepSelected = 0;"><i _ngcontent-ftv-c76=""
                                            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                            style="color: white;"></i> Volver</button>
                                    <button type="button" class="btn btn-primary"
                                        (click)="onSubirArchivo()">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<!-- end #content -->