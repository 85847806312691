<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem" *ngIf="stepEstandarEvalTecSeleccionado === 0">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Mantenedor Estandares de Evaluación Técnica</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-crearEstandarEvaluacion" data-toggle="modal" class="btn btn-primary" (click)="resetFormCreateEstandarEvalTec()">Agregar Estandar</a>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterEstandaresEvalTecnica($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Descripción</th>
                                    <th>Vigente</th>
                                    <th>Fecha Creación</th>
                                    <th class="text-left">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of estandaresEvaluacionTecnicaMantenedorFiltered">
                                        <ng-container>
                                            <td>
                                                {{ item.descripcion_estandar }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente === 1">
                                                    Vigente
                                                </span>
                                                <span class="tab tab-red" *ngIf="item.vigente === 0">
                                                    Inactivo
                                                </span>
                                            </td>
                                            <td>
                                                {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                            </td>
                                            <td class="text-right">
                                                <div class="row text-right">
                                                    <a 
                                                        href="#modal-updateEstandarEvaluacion"
                                                        title="Editar Motivo" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="editarEstandarEvalTec(item.correlativo)"
                                                        data-toggle="modal"
                                                    >
                                                        <i class="fa fa-edit"></i>
                                                    </a>
                                                    <a 
                                                        href="#modal-eliminarEstandarEvaluacion"
                                                        title="Eliminar Motivo" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="eliminarEstandarEvalTec(item.correlativo)"
                                                        data-toggle="modal"
                                                    >
                                                        <i class="fa fa-trash-alt"></i>
                                                    </a>
                                                    <a 
                                                        title="Ver grupos de estandar" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="verGruposEstandar(item.correlativo)"
                                                    >
                                                        <i class="fa fa-eye"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- GRUPOS -->
                <div class="panel-body rounded p3-rem" *ngIf="stepEstandarEvalTecSeleccionado === 1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <button type="button" class="btn btn-primary" (click)="stepEstandarEvalTecSeleccionado = 0;"><i class="fa fa-arrow-left"></i> Volver</button>
                                </div>
                                <div class="col-md-6 text-right">
                                    <span *ngIf="grupoValidado === false" style="font-weight: bold;" class="tab tab-yellow">Grupo no válido</span>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Mantenedor Estandares de Evaluación Técnica / <span style="font-weight: bold;">{{ estadarSeleccionado }}</span></h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-crearEstandarGrupo" data-toggle="modal" class="btn btn-primary" (click)="resetFormCreateGrupoEvalTec()">Agregar Grupo</a>
                                </div>
                            </div>
                            <hr>
                            <div *ngIf="estandarGrupoEvalTec.length > 0" class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterEstandarGrupoEvalTec($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <ng-container *ngIf="estandarGrupoEvalTec.length > 0">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Grupo</th>
                                        <th>Ponderador</th>
                                        <th>Vigente</th>
                                        <th>Fecha Creación</th>
                                        <th class="text-left">Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of estandarGrupoEvalTecFiltered">
                                            <ng-container>
                                                <td>
                                                    {{ item.descripcion }}
                                                </td>
                                                <td>
                                                    {{ item.peso }}
                                                </td>
                                                <td>
                                                    <span class="tab tab-green" *ngIf="item.vigente === 1">
                                                        Vigente
                                                    </span>
                                                    <span class="tab tab-red" *ngIf="item.vigente === 0">
                                                        Inactivo
                                                    </span>
                                                </td>
                                                <td>
                                                    {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                                </td>
                                                <td class="text-right">
                                                    <div class="row text-right">
                                                        <a 
                                                            href="#modal-updateEstandarGrupo"
                                                            title="Editar Motivo" 
                                                            class="btn rounded-icon mr-2"
                                                            (click)="editarGrupoEvalTec(item.correlativo)"
                                                            data-toggle="modal"
                                                        >
                                                            <i class="fa fa-edit"></i>
                                                        </a>
                                                        <a 
                                                            href="#modal-eliminarEstandarGrupo"
                                                            title="Eliminar Motivo" 
                                                            class="btn rounded-icon mr-2"
                                                            (click)="eliminarGrupoEvalTec(item.correlativo)"
                                                            data-toggle="modal"
                                                        >
                                                            <i class="fa fa-trash-alt"></i>
                                                        </a>
                                                        <a 
                                                            title="Ver subgrupos" 
                                                            class="btn rounded-icon mr-2"
                                                            (click)="verSubgruposEstandar(item.correlativo)"
                                                        >
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-container *ngIf="estandarGrupoEvalTec.length <= 0">
                                <p class="text-center text-muted">No existen grupos para este estandar.</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- SUBGRUPOS -->
                <div class="panel-body rounded p3-rem" *ngIf="stepEstandarEvalTecSeleccionado === 2">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <button type="button" class="btn btn-primary" (click)="stepEstandarEvalTecSeleccionado = 1;"><i class="fa fa-arrow-left"></i> Volver</button>
                                </div>
                                <div class="col-md-6 text-right">
                                    <span *ngIf="subgrupoValidado === false" style="font-weight: bold;" class="tab tab-yellow">Subgrupo no válido</span>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Mantenedor Estandares de Evaluación Técnica / <span style="font-weight: bold;">{{ estadarSeleccionado }}</span> /  <span style="font-weight: bold;">{{ grupoSubgrupoSeleccionado }}</span></h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-crearEstandarSubgrupo" data-toggle="modal" class="btn btn-primary" (click)="resetFormCreateSubgrupoEvalTec()">Agregar Subgrupo</a>
                                </div>
                            </div>
                            <hr>
                            <div *ngIf="estandarSubgrupoEvalTec.length > 0" class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterEstandarSubGrupoEvalTec($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <ng-container *ngIf="estandarSubgrupoEvalTec.length > 0">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Subgrupo</th>
                                        <th>Ponderador</th>
                                        <th>Ayuda</th>
                                        <th>Limite de respuestas</th>
                                        <th>Contenido oferta técnica</th>
                                        <th class="text-left">Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of estandarSubgrupoEvalTecFiltered">
                                            <ng-container>
                                                <td>
                                                    {{ item.descripcion }}
                                                </td>
                                                <td>
                                                    {{ item.peso }}
                                                </td>
                                                <td>
                                                    {{ filterAyudaEvalTecSeleccionada(item.idayuda) }}
                                                </td>
                                                <td>
                                                    {{ item.limiterespuestasoferente }}
                                                </td>
                                                <td>
                                                    {{ item.observacion }}
                                                </td>
                                                <td class="text-right">
                                                    <div class="row text-right">
                                                        <a 
                                                            href="#modal-updateEstandarSubgrupo"
                                                            title="Editar Motivo" 
                                                            class="btn rounded-icon mr-2"
                                                            (click)="editarSubgrupoEvalTec(item.correlativo)"
                                                            data-toggle="modal"
                                                        >
                                                            <i class="fa fa-edit"></i>
                                                        </a>
                                                        <a 
                                                            href="#modal-eliminarEstandarSubgrupo"
                                                            title="Eliminar Motivo" 
                                                            class="btn rounded-icon mr-2"
                                                            (click)="eliminarSubgrupoEvalTec(item.correlativo)"
                                                            data-toggle="modal"
                                                        >
                                                            <i class="fa fa-trash-alt"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-container *ngIf="estandarSubgrupoEvalTec.length <= 0">
                                <p class="text-center text-muted">No existen subgrupos para este grupo.</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modales para estadares de evaluación tecnica -->
<!-- Modal eliminar estandar -->
<div class="modal fade" id="modal-eliminarEstandarEvaluacion" tabindex="-1" role="dialog" aria-labelledby="modal-eliminarEstandarEvaluacion" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-eliminarEstandarEvaluacion">Eliminar Estandar de Evaluación Técnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalEliminarEstandarEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea eliminar el siguiente estandar?</p> 
          <span class="font-weight-bold">{{ estadarSeleccionado }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onDeleteEstandarEvalTec()">Eliminar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal agregar estandar -->
<div class="modal fade" id="modal-crearEstandarEvaluacion" tabindex="-1" role="dialog" aria-labelledby="modal-crearEstandarEvaluacion" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-crearEstandarEvaluacion">Crear estandar de evaluación tecnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalCrearEstandarEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formCreateEstandarEvalTec" (ngSubmit)="onCreateEstandarEvalTec()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="descripcionEstandar" #descripcionEstandar />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modal editar estandar -->
<div class="modal fade" id="modal-updateEstandarEvaluacion" tabindex="-1" role="dialog" aria-labelledby="modal-updateEstandarEvaluacion" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-updateEstandarEvaluacion">Editar Estandar de evaluación tecnica: <span style="font-weight: bold;">{{ estadarSeleccionado }}</span></h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalUpdateEstandarEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formUpdateEstandarEvalTec" (ngSubmit)="onUpdateEstandarEvalTec()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="descripcionEstandar" #descripcionEstandar />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Vigente</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="vigente" #vigente>	
                            <option value="1">Si</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modales para estadares de evaluación tecnica fin -->

<!-- Modales para grupos de evaluación tecnica -->
<!-- Modal eliminar grupo -->
<div class="modal fade" id="modal-eliminarEstandarGrupo" tabindex="-1" role="dialog" aria-labelledby="modal-eliminarEstandarGrupo" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-eliminarEstandarGrupo">Eliminar Grupo de Evaluación Técnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalEliminarGrupoEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea eliminar el siguiente Grupo?</p> 
          <span class="font-weight-bold">{{ grupoSeleccionado }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onDeleteGrupoEvalTec()">Eliminar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal agregar grupo -->
<div class="modal fade" id="modal-crearEstandarGrupo" tabindex="-1" role="dialog" aria-labelledby="modal-crearEstandarGrupo" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-crearEstandarGrupo">Crear Grupo de evaluación tecnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalCrearGrupoEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formCreateEstandarGrupoEvalTec" (ngSubmit)="onCreateGrupoEvalTec()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="descripcion" #descripcion />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Ponderador:</label>
                    <div class="col-md-9">
                        <input type="number" class="form-control" formControlName="peso" #peso />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modal editar grupo -->
<div class="modal fade" id="modal-updateEstandarGrupo" tabindex="-1" role="dialog" aria-labelledby="modal-updateEstandarGrupo" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-updateEstandarGrupo">Editar Grupo de evaluación tecnica: <span style="font-weight: bold;">{{ grupoSeleccionado }}</span></h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalUpdateGrupoEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formUpdateEstandarGrupoEvalTec" (ngSubmit)="onUpdateGrupoEvalTec()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="descripcion" #descripcion />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Ponderador:</label>
                    <div class="col-md-9">
                        <input type="number" class="form-control" formControlName="peso" #peso />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Vigente</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="vigente" #vigente>	
                            <option value="1">Si</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modales para grupos de evaluación tecnica fin -->

<!-- Modales para subgrupos de evaluación tecnica -->
<!-- Modal eliminar subgrupo -->
<div class="modal fade" id="modal-eliminarEstandarSubgrupo" tabindex="-1" role="dialog" aria-labelledby="modal-eliminarEstandarSubgrupo" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-eliminarEstandarSubgrupo">Eliminar Subgrupo de Evaluación Técnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalEliminarSubgrupoEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea eliminar el siguiente Subgrupo?</p> 
          <span class="font-weight-bold">{{ subgrupoSeleccionado }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onDeleteSubgrupoEvalTec()">Eliminar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal agregar subgrupo -->
<div class="modal fade" id="modal-crearEstandarSubgrupo" tabindex="-1" role="dialog" aria-labelledby="modal-crearEstandarGrupo" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-crearEstandarGrupo">Crear Subgrupo de evaluación tecnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalCrearSubgrupoEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formCreateSubgrupoEvalTec" (ngSubmit)="onCreateSubgrupoEvalTec()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="subgrupoDescripcion" #subgrupoDescripcion />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Ponderador:</label>
                    <div class="col-md-9">
                        <input type="number" class="form-control" formControlName="subgrupoPeso" #subgrupoPeso />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Tipo:</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="subgrupoTipo" #subgrupoTipo>
                            <option value="1">Archivo</option>
                            <option value="2">Cargos</option>
                            <option value="3">Personal</option>
                            <option value="4">Equipos</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Ayuda relacionada:</label>
                    <div class="col-md-7">
                        <select class="form-control" formControlName="subgrupoIdAyuda" #subgrupoIdAyuda (change)="onChangeAyuda($event)">
                            <ng-container *ngFor="let ayuda of ayudasEvalTecnica">
                                <option value="{{ayuda.correlativo}}">{{ayuda.descripcion}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-md-2 text-right">
                        <button *ngIf="accionesAyudaEvalTecnica == 0" type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 1;"><i class="fa fa-plus"></i> Crear Ayuda</button>
                        <button *ngIf="accionesAyudaEvalTecnica != 0" type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 0;"><i class="fa fa-times"></i> Cancelar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- visible la lista de los criterios de las ayudas -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 0">
                            <ng-container *ngIf="criteriosEvalTecnica.length > 0">
                                <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                    <div class="col-12">
                                        <h4>Criterios de ayuda</h4>
                                    </div>
                                    <hr>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <!-- filtros para cargos -->
                                                <ng-container *ngIf="cargosFilter.length > 0">
                                                    <div>
                                                        <button class="btn tag text-primary m-r-10 m-b-10" (click)="selectCargo('', $event)">Todos</button>
                                                        <ng-container *ngFor="let cargo of cargosFilter">
                                                            <button class="btn tag text-primary m-r-10 m-b-10" (click)="selectCargo(cargo, $event)">{{cargo}}</button>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-12 col-md-4 text-right">
                                                <input 
                                                    matinput="" 
                                                    (keyup)="applyFilterAyudasEvalTecnica($event)"
                                                    placeholder="Buscar ..." 
                                                    class="form-control" 
                                                    style="margin-bottom: 10px; padding-right: 2rem !important;"
                                                >
                                                <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                            </div>
                                            <div class="col-12 col-md-2 text-right">
                                                <button type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 2; resetFormCreateAyudaEvalTecnicaCriterio()"><i class="fa fa-plus"></i> Agregar Criterio</button>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-hover">
                                        <thead class="thead-darkk">
                                            <th>Descripción</th>
                                            <th>Cargo</th>
                                            <th>Perfil requerido</th>
                                            <th>Nota</th>
                                            <th class="text-left">Acciones</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let criterio of criteriosEvalTecnicaFiltered">
                                                <ng-container>
                                                    <td>
                                                        {{ criterio.descripcion }}
                                                    </td>
                                                    <td>
                                                        {{ criterio.cargo }}
                                                    </td>
                                                    <td>
                                                        {{ criterio.perfil_requerido }}
                                                    </td>
                                                    <td>
                                                        {{ criterio.nota }}
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="row text-right">
                                                            <a 
                                                                (click)="editarAyudaEvalTecnicaCriterio(criterio.correlativo, $event)"
                                                                title="Editar criterio" 
                                                                class="btn rounded-icon mr-2"
                                                            >
                                                                <i class="fa fa-edit"></i>
                                                            </a>
                                                            <a 
                                                                (click)="eliminarAyudaEvalTecnicaCriterio(criterio.correlativo, $event)"
                                                                title="Eliminar criterio" 
                                                                class="btn rounded-icon mr-2"
                                                            >
                                                                <i class="fa fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="criteriosEvalTecnica.length <= 0 && formCreateSubgrupoEvalTec.value.subgrupoIdAyuda != null">
                                <div class="col-12 mt-4 text-center">
                                    <p class="text-muted">No existen criterios para esta ayuda. ¿Desea crear un nuevo criterio?</p>
                                    <button type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 2; resetFormCreateAyudaEvalTecnicaCriterio()"><i class="fa fa-plus"></i> Agregar Criterio</button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- visible el formulario para crear una nueva ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 1">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Agregar ayuda de evaluación tecnica</h4>
                                </div>
                                <hr>
                                <form [formGroup]="formCreateAyudaEvalTec" (ngSubmit)="onCrearAyudaEvalTecnica($event)" novalidate>
                                    <div class="row form-group">
                                        <label for="descripcion" class="col-md-3 col-form-label">Descripción</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Crear</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <!-- visible el formulario para crear un nuevo criterio de ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 2">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Agregar criterio de ayuda</h4>
                                </div>
                                <hr>
                                <form [formGroup]="formCreateAyudaCriterio" (ngSubmit)="onCrearAyudaEvalTecnicaCriterio($event)" novalidate>
                                    <div class="row form-group">
                                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="cargo" class="col-md-3 col-form-label">Cargo</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" formControlName="cargo" #cargo>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="perfil_requerido" class="col-md-3 col-form-label">Perfil Requerido</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="perfil_requerido" #perfil_requerido></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="nota" class="col-md-3 col-form-label">Nota</label>
                                        <div class="col-md-9">
                                            <select class="form-control" formControlName="nota" #nota>	
                                                <option *ngFor="let item of notasEvaluacionTecnica" value="{{item.correlativo}}" >{{ item.nota }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Agregar</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <!-- visible el formulario para editar un criterio de ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 3">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Editar criterio de ayuda - {{ criterioAyudaSeleccionadoStr }}</h4>
                                </div>
                                <hr>
                                <form [formGroup]="formUpdateAyudaCriterio" (ngSubmit)="onEditarAyudaEvalTecnicaCriterio($event)" novalidate>
                                    <div class="row form-group">
                                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="cargo" class="col-md-3 col-form-label">Cargo</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" formControlName="cargo" #cargo>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="perfil_requerido" class="col-md-3 col-form-label">Perfil Requerido</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="perfil_requerido" #perfil_requerido></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="nota" class="col-md-3 col-form-label">Nota</label>
                                        <div class="col-md-9">
                                            <select class="form-control" formControlName="nota" #nota>	
                                                <option *ngFor="let item of notasEvaluacionTecnica" value="{{item.correlativo}}" >{{ item.nota }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <!-- visible el formulario para eliminar un criterio de ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 4">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Eliminar criterio de ayuda - {{ criterioAyudaSeleccionadoStr }}</h4>
                                </div>
                                <hr>
                                <div class="col-12 text-right">
                                    <button type="button" class="btn btn-primary text-right" (click)="onEliminarAyudaEvalTecnicaCriterio($event)"> <i class="fa fa-trash"></i> Eliminar</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Limite de respuesta:</label>
                    <div class="col-md-9">
                        <input type="number" class="form-control" formControlName="subgrupoLimiteRespuesta" #subgrupoLimiteRespuesta />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Contenido oferta técnica:</label>
                    <div class="col-md-9">
                        <textarea class="form-control" formControlName="subgrupoObservacion" #subgrupoObservacion></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="resetAyudasEvalTecnica()">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modal editar subgrupo -->
<div class="modal fade" id="modal-updateEstandarSubgrupo" tabindex="-1" role="dialog" aria-labelledby="modal-updateEstandarGrupo" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-updateEstandarGrupo">Editar Subgrupo de evaluación tecnica: <span style="font-weight: bold;">{{ subgrupoSeleccionado }}</span></h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalUpdateSubgrupoEvalTec aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formUpdateSubgrupoEvalTec" (ngSubmit)="onUpdateSubgrupoEvalTec()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="subgrupoDescripcion" #subgrupoDescripcion />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Ponderador:</label>
                    <div class="col-md-9">
                        <input type="number" class="form-control" formControlName="subgrupoPeso" #subgrupoPeso />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Tipo:</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="subgrupoTipo" #subgrupoTipo>
                            <option value="1">Archivo</option>
                            <option value="2">Cargos</option>
                            <option value="3">Personal</option>
                            <option value="4">Equipos</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Ayuda relacionada:</label>
                    <div class="col-md-7">
                        <select class="form-control" formControlName="subgrupoIdAyuda" #subgrupoIdAyuda (change)="onChangeAyuda($event)">
                            <ng-container *ngFor="let ayuda of ayudasEvalTecnica">
                                <option value="{{ayuda.correlativo}}">{{ayuda.descripcion}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-md-2 text-right">
                        <button *ngIf="accionesAyudaEvalTecnica == 0" type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 1;"><i class="fa fa-plus"></i> Crear Ayuda</button>
                        <button *ngIf="accionesAyudaEvalTecnica != 0" type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 0;"><i class="fa fa-times"></i> Cancelar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- visible la lista de los criterios de las ayudas -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 0">
                            <ng-container *ngIf="criteriosEvalTecnica.length > 0">
                                <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                    <div class="col-12">
                                        <h4>Criterios de ayuda</h4>
                                    </div>
                                    <hr>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <!-- filtros para cargos -->
                                                <ng-container *ngIf="cargosFilter.length > 0">
                                                    <div>
                                                        <button class="btn tag text-primary m-r-10 m-b-10" (click)="selectCargo('', $event)">Todos</button>
                                                        <ng-container *ngFor="let cargo of cargosFilter">
                                                            <button class="btn tag text-primary m-r-10 m-b-10" (click)="selectCargo(cargo, $event)">{{cargo}}</button>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-12 col-md-4 text-right">
                                                <input 
                                                    matinput="" 
                                                    (keyup)="applyFilterAyudasEvalTecnica($event)"
                                                    placeholder="Buscar ..." 
                                                    class="form-control" 
                                                    style="margin-bottom: 10px; padding-right: 2rem !important;"
                                                >
                                                <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                            </div>
                                            <div class="col-12 col-md-2 text-right">
                                                <button type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 2; resetFormCreateAyudaEvalTecnicaCriterio()"><i class="fa fa-plus"></i> Agregar Criterio</button>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-hover">
                                        <thead class="thead-darkk">
                                            <th>Descripción</th>
                                            <th>Cargo</th>
                                            <th>Perfil requerido</th>
                                            <th>Nota</th>
                                            <th class="text-left">Acciones</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let criterio of criteriosEvalTecnicaFiltered">
                                                <ng-container>
                                                    <td>
                                                        {{ criterio.descripcion }}
                                                    </td>
                                                    <td>
                                                        {{ criterio.cargo }}
                                                    </td>
                                                    <td>
                                                        {{ criterio.perfil_requerido }}
                                                    </td>
                                                    <td>
                                                        {{ criterio.nota }}
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="row text-right">
                                                            <a 
                                                                (click)="editarAyudaEvalTecnicaCriterio(criterio.correlativo, $event)"
                                                                title="Editar criterio" 
                                                                class="btn rounded-icon mr-2"
                                                            >
                                                                <i class="fa fa-edit"></i>
                                                            </a>
                                                            <a 
                                                                (click)="eliminarAyudaEvalTecnicaCriterio(criterio.correlativo, $event)"
                                                                title="Eliminar criterio" 
                                                                class="btn rounded-icon mr-2"
                                                            >
                                                                <i class="fa fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="criteriosEvalTecnica.length <= 0 && formCreateSubgrupoEvalTec.value.subgrupoIdAyuda != null">
                                <div class="col-12 mt-4 text-center">
                                    <p class="text-muted">No existen criterios para esta ayuda. ¿Desea crear un nuevo criterio?</p>
                                    <button type="button" class="btn btn-primary" (click)="accionesAyudaEvalTecnica = 2; resetFormCreateAyudaEvalTecnicaCriterio()"><i class="fa fa-plus"></i> Agregar Criterio</button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- visible el formulario para crear una nueva ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 1">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Agregar ayuda de evaluación tecnica</h4>
                                </div>
                                <hr>
                                <form [formGroup]="formCreateAyudaEvalTec" (ngSubmit)="onCrearAyudaEvalTecnica($event)" novalidate>
                                    <div class="row form-group">
                                        <label for="descripcion" class="col-md-3 col-form-label">Descripción</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Crear</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <!-- visible el formulario para crear un nuevo criterio de ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 2">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Agregar criterio de ayuda</h4>
                                </div>
                                <hr>
                                <form [formGroup]="formCreateAyudaCriterio" (ngSubmit)="onCrearAyudaEvalTecnicaCriterio($event)" novalidate>
                                    <div class="row form-group">
                                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="cargo" class="col-md-3 col-form-label">Cargo</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" formControlName="cargo" #cargo>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="perfil_requerido" class="col-md-3 col-form-label">Perfil Requerido</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="perfil_requerido" #perfil_requerido></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="nota" class="col-md-3 col-form-label">Nota</label>
                                        <div class="col-md-9">
                                            <select class="form-control" formControlName="nota" #nota>	
                                                <option *ngFor="let item of notasEvaluacionTecnica" value="{{item.correlativo}}" >{{ item.nota }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Agregar</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <!-- visible el formulario para editar un criterio de ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 3">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Editar criterio de ayuda - {{ criterioAyudaSeleccionadoStr }}</h4>
                                </div>
                                <hr>
                                <form [formGroup]="formUpdateAyudaCriterio" (ngSubmit)="onEditarAyudaEvalTecnicaCriterio($event)" novalidate>
                                    <div class="row form-group">
                                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="cargo" class="col-md-3 col-form-label">Cargo</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" formControlName="cargo" #cargo>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="perfil_requerido" class="col-md-3 col-form-label">Perfil Requerido</label>
                                        <div class="col-md-9">
                                            <textarea rows="8" class="form-control" formControlName="perfil_requerido" #perfil_requerido></textarea>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="nota" class="col-md-3 col-form-label">Nota</label>
                                        <div class="col-md-9">
                                            <select class="form-control" formControlName="nota" #nota>	
                                                <option *ngFor="let item of notasEvaluacionTecnica" value="{{item.correlativo}}" >{{ item.nota }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <!-- visible el formulario para eliminar un criterio de ayuda -->
                        <ng-container *ngIf="accionesAyudaEvalTecnica == 4">
                            <div style="padding: 20px; border-radius: 5px; background-color: ghostwhite;" class="col-12">
                                <div class="col-12">
                                    <h4>Eliminar criterio de ayuda - {{ criterioAyudaSeleccionadoStr }}</h4>
                                </div>
                                <hr>
                                <div class="col-12 text-right">
                                    <button type="button" class="btn btn-primary text-right" (click)="onEliminarAyudaEvalTecnicaCriterio($event)"> <i class="fa fa-trash"></i> Eliminar</button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <br>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Limite de respuesta:</label>
                    <div class="col-md-9">
                        <input type="number" class="form-control" formControlName="subgrupoLimiteRespuesta" #subgrupoLimiteRespuesta />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Contenido oferta técnica:</label>
                    <div class="col-md-9">
                        <textarea class="form-control" formControlName="subgrupoObservacion" #subgrupoObservacion></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modales para grupos de evaluación tecnica fin -->