import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';

interface excelDataProps {
  title?: string;
  headers: string[];
  data: (string | number)[][];
  filename?: string;
}

interface excelMultipleDataProps {
  filename?: string;
  data: excelDataProps[]
}

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
  workbook: Workbook;

  onInit(){
    this.workbook = new Workbook();
  }

  exportExcel(excelData: excelDataProps) {
    this.onInit();
    this.createSheet(excelData);
    this.generateExcel(excelData.filename);
  }

  exportExcelWithMultiplesSheets(excelProps: excelMultipleDataProps) {
    this.onInit();
    const data = excelProps.data;
    data.forEach((d) => this.createSheet(d));
    this.generateExcel(excelProps.filename);
  }

  createSheet(excelData: excelDataProps) {
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    //Create a workbook with a worksheet
    const worksheet = this.workbook.addWorksheet(title || 'Consultas');
    //Adding Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      worksheet.addRow(d);
    });

    worksheet.addRow([]);

    this.autoFitColumn(worksheet);

    headerRow.eachCell((_, i) => {
      worksheet.getColumn(i).numFmt = '#,##0.00;-#,##0.00';
    })
  }

  generateExcel(filename: string) {
    //Generate & Save Excel File
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename + '.xlsx');
    })
  }

  autoFitColumn(worksheet: Worksheet) {
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        var columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength ) {
          maxLength = columnLength + 5;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });
  }
}
