<!-- begin #content -->
<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <div class="col-6 text-left">
            <button style="margin-right: 10px;" type="button" class="btn btn-primary mr-4" (click)="back()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                    style="color: white;"></i> Volver</button>
        </div>
        <div class="col-6 text-right">
            <button *ngIf="isEnviarCorreccionPresupuesto === true" type="button" class="btn btn-primary mr-4"
                data-toggle="modal" data-target="#modalEnviarCorreccionPresupuesto">
                Enviar correcciones del presupuesto
            </button>
            <button *ngIf="objLicitacion" type="button" class="btn btn-primary " [disabled]="downloadingPdf"
                (click)="onDescargarPDF()">
                <i class="fas fa-lg fa-fw m-r-10 fa-file-pdf"></i>
                Descargar Oferta Económica
            </button>
        </div>
    </div>
</div>
<div id="content" class="content">
    <ng-container *ngIf="archivos.length > 0">
        <div class="row">
            <div class="col-12">
                <div class="bg-white p3-rem">
                    <h4 class="text-muted"><i class="fa fa-check-circle text-success mr-2"></i>
                        Oferta Económica completada.</h4>
                </div>
            </div>
        </div>
        <br>
    </ng-container>
    <div class="row mb-4">
        <div class="col-12">
            <div class="bg-white p3-rem">
                <div class="row mt-0">
                    <div class="col-12">
                        <h3 class="">{{objLicitacion?.CONT_NOMBRE}}</h3>
                        <!-- <p>{{licitacionData?.CONT_NUMERO}}</p> -->
                    </div>
                    <!-- <div class="col-12">
                        
                    </div> -->
                    <div class="col-12">
                        <span class="text-muted">
                            {{objLicitacion?.DESCRIPCION}}
                        </span>
                    </div>
                </div>
                <div class="row mt-3 mb-4">
                    <div class="col-12">
                        <div class="">
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                    class="text-muted">Licitación: </span> {{idLicitacion}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Moneda:
                                </span> {{objLicitacion?.DESCRIPCION_MONEDA_SOLPE}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12">
                        <app-view-etapa-files-table *ngIf="archivos?.length > 0" 
                            [archivos]="archivos" 
                            [nombreEtapa]="'Recepción de Oferta Económica'"
                            [type]="1"
                            ></app-view-etapa-files-table>
                        <p *ngIf="archivos?.length <= 0" class="text-center text-muted text-bolder">No hay presupuesto cargado.</p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- hacer una tabla para colocar los datos ingresados por el usuario, ver si es posible armar un  solo objeto que se recorra y se arme la tabla mas facil con el item y subitem -->
    <table class="table table-striped d-none" id="table_presupuesto">
        <thead class="thead-darkk">
            <tr>
                <th>Descripción</th>
                <th>Unidad</th>
                <th class="text-right">Cantidad</th>
                <th class="text-right">Precio</th>
                <th class="text-right">Total</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of objItemizado">
                <tr>
                    <td>
                        <h5>
                            {{item.pos}} {{item.descripcion}}
                        </h5>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <ng-container *ngFor="let detalle of item.itemizadoDetalleLs">
                    <tr>
                        <td>
                            <p>
                                {{detalle.pos}} {{ detalle.item }}
                            </p>
                        </td>
                        <td>
                            <p>
                                {{ detalle.unidad }}
                            </p>
                        </td>
                        <td>
                            <p class="mb-0 text-right">
                                {{ detalle.cantidad | number:'1.2'}}
                            </p>
                        </td>
                        <td>
                            <p class="mb-0 text-right">
                                {{ detalle.precio | number:'1.2'}}
                            </p>
                        </td>
                        <td>
                            <p class="mb-0 text-right">
                                {{ setMontoDosDecimales(detalle.precio) * detalle.cantidad | number:'1.2' }}
                            </p>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr class="bg-white">
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{calcularTotal() | number: '1.2'}}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="isPermisoEditarPresupuesto" class="bg-white p3-rem">
        <div class="row pt-2 pb-5">
            <div class="col-12">
                <h3>
                    Registro de Oferta Económica
                </h3>
            </div>
            <!-- Una vez registrado todo el presupuesto y adjuntados
                    los documentos requeridos deberá enviar la información mediante el botón de la parte
                    inferior. -->
            <div class="col-12">
                <span class="text-muted">A continuación deberá registras los precios unitarios correspondientes de cada
                    ítem, estos se guardaran de forma automática. También puede guardar el avanza realizado de forma
                    manual haciendo click en <strong>Guardar presupuesto</strong>.</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8 ">
                <div class="pr-md-5 border-right no-border-sm">
                    <div *ngIf="objItemizado" class="">
                        <div class="d-table">
                            <ng-container *ngFor="let item of objItemizado; let i = index; let first = first"
                                class=" bg-white border">
                                <div class="d-row ">
                                    <div class="d-cell">
                                        <h5 class="mb-0">
                                            {{item.pos}} {{item.descripcion}}
                                        </h5>
                                    </div>
                                    <div class="d-cell"></div>
                                    <div class="d-cell"></div>
                                    <div class="d-cell"></div>
                                </div>

                                <ng-container *ngFor="let detalle of item.itemizadoDetalleLs; 
                                let x = index; let last = last" class="">
                                    <div class="d-row" [ngClass]="{'last': last}">
                                        <div class="d-cell">
                                            <p class="ml-4 mb-0">
                                                {{detalle.pos}} {{ detalle.item }}
                                            </p>
                                        </div>
                                        <div class="d-cell">
                                            <p class="mb-0">
                                                {{ detalle.cantidad | number:'1.2'}} {{ detalle.unidad }}
                                            </p>
                                        </div>
                                        <div class="d-cell">
                                            <div class="form-group row mb-0">
                                                <div class="col-md-12">
                                                    <input class="form-control" type="number"
                                                        [value]="setMontoDosDecimales(detalle.precio)"
                                                        (focusout)="registrarMonto($event, i, x)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-cell text-right">
                                            <p class="mb-0 mr-4">
                                                {{ setMontoDosDecimales(detalle.precio) * detalle.cantidad |
                                                number:'1.2' }}
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="p-20 pt-0">
                    <div class="row">
                        <div class="col-12">
                            <h3>Resumen</h3>
                        </div>
                    </div>
                </div>
                <div class="p-20 pt-0" style="position: sticky; top: 6rem; max-height: 70vh; 
                    overflow-x: auto; overflow-y: overlay;">
                    <ng-container *ngFor="let item of objItemizado; let i = index; let first = first"
                        class=" bg-white border">
                        <div class="row mb-4">
                            <div class="col-12 col-md-8">
                                <p>{{item.pos}} {{item.descripcion}}</p>
                            </div>
                            <div class="col-12 col-md-4 text-right">
                                <p class="mb-0">{{calcularCapitulo(i) | number: '1.2'}}</p>
                                <span>
                                    ({{(calcularCapitulo(i)*100)/calcularTotal()| number: '1.0-0'}}%)
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="p-20" style="position: sticky; top: 80vh">
                    <div class="row mt-4">
                        <div class="col-12 col-md-8 bold">
                            <span>Total</span>
                        </div>
                        <div class="col-12 col-md-4 text-right bold">
                            <span>{{calcularTotal() | number: '1.2'}}</span>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <button class="btn tag mb-1 text-bolder text-primary col-12" (click)="enviarPresupuesto()">
                            Guardar presupuesto
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div style="border: 1px solid #dee2e6;" class="row bg-white p3-rem">
        <!-- <div class="col-6">
            <h5>Debe subir los siguientes archivos:</h5>
            <h5 class="text-muted">
                Presupuesto, Excel del Formulario ECO y PDFs con todos los ECO firmados
            </h5>
        </div>
        <div class="col-6 text-right">
            <button class="btn tag mb-1 text-bolder text-primary pl-4 pr-4"
            href="#modalFile" data-toggle="modal" (click)="uploadFileModal = true">
                Subir
            </button>
        </div> -->
        <div class="col-12">
            <!-- termianr la condicion aqui con una funcion que valide si el participante tiene activo el poder modificar el presuipuesto -->
            <ng-container *ngIf="presupuestoEnviadoStr !== ''">
                <h5 class="text-muted text-center">{{presupuestoEnviadoStr}}</h5>
            </ng-container>
            <ng-container *ngIf="isPermisoEditarPresupuesto">
                <ng-container *ngIf="stepSelected === 0">
                    <div class="bg-white p3-rem rounded">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="text-muted">Por favor, cargue los ECOs correspondientes de respaldo.</h3>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row">
                            <div class="col-6">
                                <div>
                                    <form [formGroup]="formDocRequeridoOferente"
                                        (ngSubmit)="onAddDocRequeridoOferente()">
                                        <div class="row form-group">
                                            <label for="docSelected" class="col-md-3 col-form-label">Seleccionar
                                                documento</label>
                                            <div class="col-md-9">
                                                <select class="form-control" formControlName="docSelected" #docSelected>
                                                    <ng-container *ngFor="let doc of docRequeridos">
                                                        <ng-container *ngIf="doc.archivos.length <= 0">
                                                            <option value="{{doc.correlativo_doc_requerido}}">
                                                                {{doc.descripcion}}</option>
                                                        </ng-container>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row m-b-10 align-items-center">
                                            <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                                    class="text-danger">*</span></label>
                                            <div class="col-md-9">
                                                <input #inputFile style="border-color: transparent;"
                                                    class="form-control" name="archivo" type="file"
                                                    (change)="detectFiles($event)" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <button type="submit" class="btn btn-primary"
                                                    [disabled]="formDocRequeridoOferente.invalid">Agregar</button>
                                            </div>
                                        </div>
                                    </form>
                                    <br>
                                    <br>
                                </div>
                            </div>
                            <div class="col-6">
                                <!-- desarrollar la parte de eliminar los archivos esto debe de actualizar el registro en base de datos y ademas elimiar el archivo seleccionado con el nombre de archivo que venga en el get -->
                                <div class="docRequeridoContainer" *ngFor="let doc of docRequeridos">
                                    <div class="row">
                                        <div class="col-8">
                                            <span class="text-muted">
                                                <ng-container *ngIf="doc.archivos.length > 0">
                                                    <i class="fa fa-check text-success mr-2"></i>
                                                </ng-container>
                                                <ng-container *ngIf="doc.archivos.length <= 0">
                                                    <i class="far fa-file-alt text-primary mr-2"></i>
                                                </ng-container>
                                                {{doc.descripcion}}
                                            </span>
                                        </div>
                                        <div class="col-4 text-right">
                                            <!-- (click)="deleteDocRequeridoOferente(doc.correlativo)" -->
                                            <ng-container *ngIf="doc.archivos.length > 0">
                                                <div title="Eliminar Documento" class="btn rounded-icon mr-2"
                                                    (click)="deleteDocRequeridoByOferente(doc.archivos[0].correlativo)">
                                                    <i class="fa fa-trash-alt"></i>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                            (click)="back()"><i _ngcontent-ftv-c76=""
                                                class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                                style="color: white;"></i> Volver</button>
                                        <button type="button" class="btn btn-primary" (click)="stepSelected = 1;"
                                            [disabled]="!docRequeridosValido">Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="stepSelected === 1">
                    <div class="row">
                        <div class="col-6">
                            <h4 class="text-muted">Ingrese el monto total de su oferta económica:</h4>
                            <br>
                            <input class="form-control" type="number" [(ngModel)]="montoTotalPresupuesto" />
                        </div>
                        <br>
                        <div class="col-12 text-right">
                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                (click)="stepSelected = 0;"><i _ngcontent-ftv-c76=""
                                    class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i>
                                Volver</button>
                            <button class="btn btn-primary" (click)="validateMontoIngresado()">Siguiente</button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="stepSelected === 2">
                    <div class="row">
                        <!-- begin col-6 -->
                        <div class="col-12">
                            <!-- begin panel -->
                            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                                <div class="bg-white p3-rem rounded">
                                    <div class="row mb-4">
                                        <div class="col-12">
                                            <ng-container *ngIf="archivos.length <= 0">
                                                <h4>Cargar Oferta Económica</h4>
                                                <p class="text-muted">
                                                    <!-- Después de completar el
                                                    <strong>Registro de Oferta Económica</strong>, descargue el
                                                    archivo
                                                    proporcionado por el sistema haciendo clic en la opción
                                                    <strong>Descargar Oferta Económica</strong> y envíelo
                                                    para completar el proceso. -->

                                                    Después de completar el <strong>formulario económico</strong>
                                                    descargar el archivo proporcionado por el sistema haciendo clic en
                                                    la opción <strong>Descargar Oferta Económica</strong>, firmar
                                                    documento por el Representante Legal y envíelo para completar el
                                                    proceso
                                                </p>
                                            </ng-container>
                                            <ng-container *ngIf="archivos.length > 0">
                                                <h4 class="text-muted"><i
                                                        class="fa fa-check-circle text-success mr-2"></i>
                                                    Oferta Económica completada.</h4>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group row">
                                                <label class="col-form-label col-3">Descripción Archivo
                                                    <span class="text-danger">*</span></label>
                                                <div class="col-9">
                                                    <input class="form-control" type="text" placeholder=""
                                                        id="nombreArchivo" [(ngModel)]="nombreArchivo">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row m-b-10 align-items-center">
                                        <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-9">
                                            <input #inputFile style="border-color: transparent;" class="form-control"
                                                name="archivo" type="file" (change)="detectFiles($event)" />
                                        </div>
                                    </div>
                                    <app-view-etapa-files-table *ngIf="archivos.length > 0" [archivos]="archivos"
                                        [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                                    <div class="row">
                                        <div class="col-xl-12 text-right">
                                            <button style="margin-right: 10px;" type="button" class="btn btn-primary"
                                                (click)="stepSelected = 1;"><i _ngcontent-ftv-c76=""
                                                    class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                                                    style="color: white;"></i> Volver</button>
                                            <button type="button" class="btn btn-primary"
                                                (click)="onSubirArchivo()">Enviar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal" id="modalFile" *ngIf="uploadFileModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-form-label col-2">Descripción Archivo <span
                                    class="text-danger">*</span></label>
                            <div class="col-10">
                                <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                    [(ngModel)]="nombreArchivo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-b-10 align-items-center">
                    <label class="col-md-3 col-form-label">Seleccionar archivo <span
                            class="text-danger">*</span></label>
                    <div class="col-md-9">
                        <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                            type="file" (change)="detectFiles($event)" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 text-right">
                        <!-- <button style="margin-right: 10px;" type="button" class="btn btn-primary" routerLink="/contratista"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button> -->
                        <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="onSubirArchivo()">Subir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal confimacion para enviar correccion presupuesto -->
<div class="modal fade" id="modalEnviarCorreccionPresupuesto" tabindex="-1" role="dialog"
    aria-labelledby="modalEnviarCorreccionPresupuesto" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEnviarCorreccionPresupuestoLabel">Enviar correcciones del presupuesto
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Una vez enviado el presupuesto no se podrá modificar.</p>
                <p>¿Esta seguro de enviar la corrección?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="enviarEdicionPresupuesto()">Enviar corrección</button>
            </div>
        </div>
    </div>
</div>