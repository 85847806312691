import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'src/assets/plugins/moment/moment';
import { ExportExcelService } from '../services/export-excel.service';

@Component({
  selector: 'app-view-excel-buttons',
  templateUrl: './view-excel-buttons.component.html',
  styleUrls: ['./view-excel-buttons.component.css']
})
export class ViewExcelButtonsComponent implements OnInit {
  @Input() title: string;
  @Input() filename: string;
  @Input() headers: any[];
  @Input() data: any;
  @Input() variant: string = 'primary';
  constructor(private excelService : ExportExcelService) { }

  ngOnInit(): void {}

  onExportar = () => {
    const title = this.title;
    const filename = this.filename;
    const headers = this.headers;
    const data = this.data;

    const aHeaders = headers.filter((aData) => aData.text !== '')
    const aDataExcel = []

    data.forEach((aRow: any) => {
      const aDataTmp: any = []
      aHeaders.forEach((aHeader) => {
        let value = aRow[aHeader.value]
        if(value){
          if (typeof value === 'boolean') value = value ? 'Si' : 'No'
          if(aHeader?.type === 'date') value = moment(value).format('DD/MM/YYYY');
        }
        aDataTmp.push(value)
      })
      aDataExcel.push(aDataTmp)
    })

    const aDate = new Date()
    const sDate = aDate.getFullYear() + '' + (aDate.getMonth() + 1) + '' + aDate.getDate()
    const sTime = aDate.getHours() + '' + aDate.getMinutes() + '' + aDate.getSeconds()

    const excelData = {
      headers: aHeaders.map((aData) => aData.text),
      data: aDataExcel,
      title: title,
      filename: `${filename}_${sDate}${sTime}`
    }

    return this.excelService.exportExcel(excelData);
  }
}