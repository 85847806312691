import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import {
  IItemizado,
  IItemizadoDetalle,
  ItemizadoService,
} from "src/app/shared/services/itemizado.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-ver-itemizado-licitacion",
  templateUrl: "./ver-itemizado-licitacion.component.html",
  styleUrls: ["./ver-itemizado-licitacion.component.css"],
  providers: [LicitacionService, ItemizadoService, EtapasService],
})
export class VerItemizadoLicitacionComponent implements OnInit {
  idLicitacion: number;
  objItemizado: IItemizado[];
  objLicitacion: any;
  indexItem: number = 0;
  indexDetalle: number = 0;
  archivos: [];
  idParticipante: number;
  updateDet: IItemizadoDetalle;
  idEtapaLic: number;
  etapaOfertaEco: any;

  archivos64: Blob;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivo: string;
  @ViewChild("inputFile")
  inputFile: ElementRef;
  nombreArchivo: string;
  uploadFileModal: boolean = false;

  idEtapa: number;
  idOrigen: number;

  constructor(
    private objItemizadoService: ItemizadoService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private etapaService: EtapasService,
    private location: Location
  ) {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.idOrigen = params["idOrigen"];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
          this.getItemizado();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
      this.licitacionService.getDatosPart().subscribe((resp) => {
        this.idParticipante = JSON.parse(resp)[0]?.PART_CORRELATIVO;
        if (this.idParticipante) {
          this.getCronograma();
        }
      });
    });
  }

  getCronograma() {
    if (this.idLicitacion) {
      this.licitacionService
        .getCronogramaLicView(this.idLicitacion)
        .subscribe((resp) => {
          if (resp) {
            this.etapaOfertaEco = JSON.parse(resp);
            this.etapaOfertaEco = this.etapaOfertaEco?.filter(
              (item) => item.ETAPA_CORRELATIVO === 10
            ); //10 estapa de recepcion de oferta economica
            this.idEtapaLic = this.etapaOfertaEco[0]?.ETLIC_CORRELATIVO;
            //this.getArchivoPartcipante();
            this.getArchivoLicEtapaFull();
          }
        });
    }
  }

  ngOnInit(): void {}

  getItemizado() {
    this.objItemizadoService
      .getItemizadoParticipante(this.idLicitacion)
      .subscribe(
        (resp) => {
          let response: any = resp;

          if (response.data?.length > 0) {
            this.objItemizado = response.data;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
  }
  registrarMonto(event: any, indexItem, indexDetalle) {
    this.indexItem = indexItem;
    this.indexDetalle = indexDetalle;
    if (
      event.target.value == "" ||
      event.target.value == null ||
      event.target.value == undefined
    ) {
      return;
    }
    if (
      event.target.value ==
      this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle]
        .precio
    ) {
      return;
    }

    this.updateMonto(event.target.value);
  }

  updateMonto(calificacion) {
    this.updateDet =
      this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle];
    this.updateDet.precio = +calificacion;
    //this.objCritero.

    this.objItemizadoService.postRegistraPrespuesto(this.updateDet).subscribe(
      (resp) => {
        let response: any = resp;
        this.notifyService.showSuccess("", "Datos guardados");
        if (response.data) {
          //this.objCritero = response.data;
          this.objItemizado[this.indexItem].itemizadoDetalleLs[
            this.indexDetalle
          ] = this.updateDet;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }
  calcularTotal() {
    let toReturn = 0;
    //this.objItemizadoLs
    for (let y = 0; y < this.objItemizado?.length; y++) {
      for (
        let i = 0;
        i < this.objItemizado[y]?.itemizadoDetalleLs?.length;
        i++
      ) {
        toReturn =
          toReturn +
          this.objItemizado[y]?.itemizadoDetalleLs[i]?.cantidad *
            this.objItemizado[y]?.itemizadoDetalleLs[i]?.precio_unitario;
      }
    }
    return toReturn;
  }
  calcularCapitulo(index: number) {
    let toReturn = 0;
    for (
      let i = 0;
      i < this.objItemizado[index]?.itemizadoDetalleLs?.length;
      i++
    ) {
      toReturn =
        toReturn +
        this.objItemizado[index]?.itemizadoDetalleLs[i]?.cantidad *
          this.objItemizado[index]?.itemizadoDetalleLs[i]?.precio_unitario;
    }
    return toReturn;
  }
  getArchivoLicEtapaFull() {
    this.etapaService
      .getArchivoLicEtapaFull(this.idLicitacion, 10)
      .subscribe((resp) => {
        this.archivos = JSON.parse(resp);
      });
  }

  detectFiles(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64;
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
        }
      };
    }
  }

  onSubirArchivo() {
    const postData: {
      in_det_etapa_lic: number;
      files: Blob;
      fileName: string;
      type: string;
      in_usuario: string;
      in_part_correlativo: number;
      in_domi_correlativo_tiar: number;
      publico: number;
      descripcion_input_archivo: string;
      idLicitacion: number;
      idEtapaLicitacion: number;
      correlativoGrupoArchivo: number;
    } = {
      in_det_etapa_lic: this.idEtapaLic,
      files: this.archivos64,
      fileName: this.fileName,
      type: this.type,
      in_usuario: "",
      in_part_correlativo: this.idParticipante,
      in_domi_correlativo_tiar: 27,
      publico: 1,
      descripcion_input_archivo:
        this.nombreArchivo == null || this.nombreArchivo == ""
          ? this.fileName
          : this.nombreArchivo,
      idLicitacion: this.idLicitacion,
      idEtapaLicitacion: 10,
      correlativoGrupoArchivo: 0,
    };
    if (postData.files) {
      this.licitacionService.postArchivosLicitacion(postData).subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Archivo enviado correctamente.",
            "Archivo enviado correctamente"
          );
          //this.getArchivoPartcipante();
          //this.getArchivoLicEtapaFull();
          //this.getArchivoPartcipante();
          this.getArchivoLicEtapaFull();
          this.uploadFileModal = false;
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Actualizar etapa");
          this.uploadFileModal = true;
        }
      );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Actualizar etapa"
      );
    }

    this.archivos64 = null;
    this.fileName = "";
    this.type = "";
    this.inputFile.nativeElement.value = "";
  }

  getArchivoPartcipante() {
    const etapa = this.idEtapaLic;
    const parti = this.idParticipante;
    this.licitacionService
      .getArchivosPartLic(etapa, parti, 27)
      .subscribe((resp) => {
        if (JSON.parse(resp)[0]) {
          this.archivos = JSON.parse(resp);
        }
      });
  }

  enviarPresupuesto() {
    //this.updateDet = this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle];
    //this.updateDet.precio = +calificacion;
    //this.objCritero.

    this.objItemizadoService.postEnviaPrespuesto(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;
        this.notifyService.showSuccess("", "Datos guardados");
        if (response.data) {
          //this.objCritero = response.data;
          //this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle] = this.updateDet;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  back(): void {
    this.location.back();
  }
}
