import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import {
  IGrupo,
  CriteriosEvaluacionService,
} from "src/app/shared/services/criterios-evaluacion.service";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Location } from "@angular/common";

@Component({
  selector: "create-criterios",
  templateUrl: "./create-criterios.component.html",
  styleUrls: ["./create-criterios.component.css"],
  providers: [CriteriosEvaluacionService],
})
export class CreateCriteriosComponent implements OnInit {
  modalGrupo: boolean = false;
  modalSubgrupo: boolean = false;
  modalCriterio: boolean = false;
  grupoSelected: IGrupo;
  grupoIndex: number;

  isUpdateGrupo: boolean = false;
  isUpdateSubgrupo: boolean = false;
  isUpdateCriterio: boolean = false;

  indexGrupo: number = 0;
  indexSubgrupo: number = 0;
  indexCriterio: number = 0;

  subgrupoIndex: number;

  idLicitacion: number;
  objLicitacion: any;
  objGrupos: IGrupo[];
  formGroup: FormGroup;
  formSubgroup: FormGroup;
  formCriterio: FormGroup;
  // variables para la validacion de si los formularios esatn ya la 100%
  gruposPesoTotal: number;
  pesoPermitidoGrupo: number;
  pesoPermitidoSubGrupo: number;
  objPesosGruposYSubgrupos: any[] = [];
  idOrigen: number;
  idEtapa: number;
  idEtapaLic: number;
  isChildren: boolean;
  @Input() idLicitacionParent: number = 0;
  @Input() actaInicio: boolean = false;
  @Output() data: EventEmitter<any[]> = new EventEmitter<any[]>();
  // variablas para la seleccion de ayuda para la evalucion tecnica
  @Input() ayudaEvalTecnicaLs: any[] = [];
  @Output() ayudaEvalTecnicaLsData: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();
  estandaresEvalTecnica: any[];
  formEstandares: FormGroup;
  ayudasEditables: any[];
  ayudasEditablesSelected: any[] = [];
  ayudaEditableSubgrupoSelected: number;
  idAyudaToUpdate: number;
  correlativoSubgrupoSelected: number;
  tipoFormAyudaRelacionadaSubgrupo: number;
  idAyudaRelacionadaSubgrupo: number;
  formUpdateAyudaRelacionadaSubgrupo: FormGroup;
  formCreateAyudaRelacionadaSubgrupo: FormGroup;
  ayudaRelacionadaSubgrupoDescripcion: string;
  cargosFilter: string[];
  ayudasEditablesSelectedFilteredByCargos: any[];
  formGuardarPlantillaEstandar: FormGroup;

  @ViewChild("closeModalCreatePlantillaEstandar")
  closeModalCreatePlantillaEstandar: ElementRef;
  rolesParticipanteComision: any[];

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private criteriosServices: CriteriosEvaluacionService,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.rolesParticipanteComision = [];

    this.formGroup = new FormGroup({
      descripcion: new FormControl(null),
      correlativo: new FormControl(null),
      licitacion_correlativo: new FormControl(null),
      peso: new FormControl(null),
    });

    this.formSubgroup = new FormGroup({
      descripcion: new FormControl(null),
      observacion: new FormControl(null),
      grupo_correlativo: new FormControl(null),
      subgrupo_abierto: new FormControl(null),
      peso: new FormControl(null),
      tipo: new FormControl(null),
      idAyuda: new FormControl(null),
      limiteRespuestasOferente: new FormControl(null),
    });

    this.formCriterio = new FormGroup({
      correlativo: new FormControl(null),
      descripcion: new FormControl(null),
      observacion: new FormControl(null),
      grupo_correlativo: new FormControl(null),
      subgrupo_correlativo: new FormControl(null),
    });

    this.formEstandares = new FormGroup({
      estandarEvalTecSelect: new FormControl(null, [Validators.required]),
    });

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = !this.isChildren
        ? params["idLicitacion"]
        : this.idLicitacionParent;
      this.idOrigen = params["idOrigen"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      /* this.verificarLic(this.idLicitacion); */
      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
          this.getSelectRolesParticipanteComision();
          /* this.licitacionForm = new FormGroup({
            'solpe' : new FormControl(JSON.parse(resp)[0].SOLPE_CORRELATIVO),
            'nro_contrato': new FormControl(JSON.parse(resp)[0].CONT_NUMERO),
            'nombre_contrato' : new FormControl(JSON.parse(resp)[0].CONT_NOMBRE),
            'files': new FormArray ([])
          }); */
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
    });

    this.formGroup = this.formBuilder.group({
      descripcion: ["", Validators.required],
      peso: ["", Validators.required],
    });

    this.formSubgroup = this.formBuilder.group({
      descripcion: ["", Validators.required],
      observacion: ["", Validators.required],
      peso: ["", Validators.required],
      tipo: ["1", Validators.required],
      idAyuda: ["1", Validators.required],
      limiteRespuestasOferente: [1, Validators.required],
    });

    this.formCriterio = this.formBuilder.group({
      descripcion: ["", Validators.required],
      observacion: ["", Validators.required],
    });

    this.formUpdateAyudaRelacionadaSubgrupo = this.formBuilder.group({
      descripcionAyuda: [null, Validators.required],
      notaAyuda: [0, Validators.required],
      cargoAyuda: [null],
      ponderadorAyuda: [null],
      perfilAyuda: [null],
    });

    this.formCreateAyudaRelacionadaSubgrupo = this.formBuilder.group({
      descripcionAyuda: [null, Validators.required],
      notaAyuda: [0, Validators.required],
      cargoAyuda: [null],
      ponderadorAyuda: [null],
      perfilAyuda: [null],
    });

    this.formGuardarPlantillaEstandar = new FormGroup({
      descripcionEstandar: new FormControl(null, [Validators.required]),
    });

    this.ayudaEvalTecnicaLs = [];
    this.estandaresEvalTecnica = [];
    this.ayudasEditables = [];
    this.ayudasEditablesSelected = [];
    this.ayudaEditableSubgrupoSelected = 0;
    this.idAyudaToUpdate = 0;
    this.correlativoSubgrupoSelected = 0;
    this.tipoFormAyudaRelacionadaSubgrupo = 0;
    this.idAyudaRelacionadaSubgrupo = 0;
    this.ayudaRelacionadaSubgrupoDescripcion = "";
    this.cargosFilter = [];
  }

  ngOnInit(): void {
    /* correlativo: number;
    descripcion: string;
    observacion: string;
    grupo_correlativo: number;
    peso: number;
    abierto: boolean;

    correlativo: number;
    descripcion: string;
    observacion: string;
    grupo_correlativo: number;
    subgrupo_correlativo: number;
    calificacion: number;
    documento: boolean;
    */
    this.objGrupos = [];
    this.getGrupos();
    this.isChildren = this.idLicitacionParent !== 0;
    this.getAyudaEvalCriterios();
  }

  back(): void {
    this.location.back();
  }

  addGrupo() {
    this.calcularPorcentajesFormularios();
    let pesoPermitido = 100 - this.gruposPesoTotal;

    if (this.formGroup.get("peso").value <= 0) {
      this.notifyService.showWarning(
        "El porcentaje ingresado no puede ser manor o igual a 0",
        "Advertencia"
      );
    } else {
      if (this.gruposPesoTotal >= 100) {
        this.notifyService.showWarning(
          "Los grupos alcanzan un total en paso de 100%, no es posible agregar mas capitulos",
          "Advertencia"
        );
      } else {
        //this.objGrupos.push({})
        var descr = this.formGroup.get("descripcion").value;
        //var obj = this.formGroup.get("observacion").value;
        var peso = this.formGroup.get("peso").value;

        // validacion en caso de que el peso que venga supere el 100% total del formulario
        let pesoTotalAgregado =
          this.gruposPesoTotal + this.formGroup.get("peso").value;

        if (pesoTotalAgregado > 100) {
          this.notifyService.showWarning(
            "No puede ingresar un peso mayor a " + pesoPermitido,
            "Advertencia"
          );
        } else {
          if (peso <= 100) {
            if (this.formGroup.valid) {
              this.objGrupos.push({
                correlativo: 0,
                descripcion: descr,
                peso: peso,
                licitacion_correlativo: this.idLicitacion,
                subgrupoLs: [],
              });
              this.modalGrupo = false;
            }

            this.formGroup = this.formBuilder.group({
              descripcion: ["", Validators.required],
              peso: ["", Validators.required],
            });
          } else {
            this.notifyService.showWarning(
              "El porcentaje no puede ser mayor a 100",
              "Advertencia"
            );
          }
        }
      }

      this.calcularPorcentajesFormularios();
      pesoPermitido = 100 - this.gruposPesoTotal;
      this.pesoPermitidoGrupo = pesoPermitido;
    }
  }

  addSubGrupo() {
    //this.objGrupos.push({})
    this.calcularPorcentajesFormularios();
    var descr = this.formSubgroup.get("descripcion").value;
    var obj = this.formSubgroup.get("observacion").value;
    var peso = this.formSubgroup.get("peso").value;
    var varTipo = this.formSubgroup.get("tipo").value;
    let idAyuda: number = this.formSubgroup.get("idAyuda").value;
    let limiteRespuestasOferente: number = this.formSubgroup.get(
      "limiteRespuestasOferente"
    ).value;

    if (peso <= 0) {
      this.notifyService.showWarning(
        "El peso ingresado no puede ser menor o igual a 0",
        "Advertencia"
      );
    } else {
      if (peso <= 100) {
        if (this.objGrupos[this.grupoIndex].subgrupoLs == undefined) {
          this.objGrupos[this.grupoIndex].subgrupoLs = [];
        }

        let correlativoGrupo = this.objGrupos[this.grupoIndex].correlativo;
        let objGrupoFilter = this.objPesosGruposYSubgrupos.filter(
          (item) => item.correlativo === correlativoGrupo
        );
        let pesoTotalSubCapitulos =
          objGrupoFilter[0].subGrupoPesoTotal +
          this.formSubgroup.get("peso").value;

        if (objGrupoFilter[0].subGrupoPesoTotal < 100) {
          // validacion en caso de que la cantidad ingresada mas el porcentaje que ya existe en el formulario sobrepase el 100%
          if (pesoTotalSubCapitulos <= 100) {
            if (this.formSubgroup.valid) {
              this.objGrupos[this.grupoIndex].subgrupoLs.push({
                correlativo: 0,
                descripcion: descr,
                observacion: obj,
                grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,
                peso: peso,
                subgrupo_abierto: true,
                tipo: varTipo,
                idAyuda,
                limiteRespuestasOferente,
                criteriosLs: [],
              });
              this.modalSubgrupo = false;
            }

            this.formSubgroup = this.formBuilder.group({
              descripcion: ["", Validators.required],
              observacion: ["", Validators.required],
              peso: ["", Validators.required],
              tipo: ["1", Validators.required],
              idAyuda: ["1", Validators.required],
              limiteRespuestasOferente: [1, Validators.required],
            });

            this.save();
          } else {
            this.notifyService.showWarning(
              "El peso ingreso supera la cantidad permitida.",
              "Advertencia"
            );
          }
        } else {
          this.notifyService.showWarning(
            "El porcentaje total ingresado no puede ser mayor de 100%",
            "Advertencia"
          );
        }
      } else {
        this.notifyService.showWarning(
          "El peso ingresado no puede ser mayor a 100",
          "Advertencia"
        );
      }
    }
    this.calcularPorcentajesFormularios();
  }

  addCriterio() {
    //obsData: ISubGrupo, indexGrupo, indexSubGrupo
    //this.objGrupos.push({})
    var descr = this.formCriterio.get("descripcion").value;
    var obj = this.formCriterio.get("observacion").value;
    if (
      this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex]
        .criteriosLs == undefined
    ) {
      this.objGrupos[this.grupoIndex].subgrupoLs[
        this.subgrupoIndex
      ].criteriosLs = [];
    }
    //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
    this.modalCriterio = false;

    this.formCriterio = this.formBuilder.group({
      descripcion: ["", Validators.required],
      observacion: ["", Validators.required],
    });
  }

  cleanGrupoForm() {
    this.formGroup.controls["descripcion"].setValue("");
    //this.formGroup.controls['correlativo'].setValue('');
    //this.formGroup.controls['licitacion_correlativo'].setValue('');
    this.formGroup.controls["peso"].setValue("");

    this.formGroup = this.formBuilder.group({
      descripcion: ["", Validators.required],
      peso: ["", Validators.required],
    });
  }

  cleanSubGrupoForm() {
    this.formSubgroup.controls["descripcion"].setValue("");
    //this.formSubgroup.controls['grupo_correlativo'].setValue('');
    this.formSubgroup.controls["observacion"].setValue("");
    //this.formSubgroup.controls['subgrupo_abierto'].setValue('');
    this.formSubgroup.controls["peso"].setValue("");
    this.formSubgroup.controls["tipo"].setValue(1);
    this.formSubgroup.controls["idAyuda"].setValue("");
    this.formSubgroup.controls["limiteRespuestasOferente"].setValue(1);

    this.formSubgroup = this.formBuilder.group({
      descripcion: ["", Validators.required],
      observacion: ["", Validators.required],
      peso: ["", Validators.required],
      tipo: ["1", Validators.required],
      idAyuda: ["1", Validators.required],
      limiteRespuestasOferente: [1, Validators.required],
    });
  }

  cleanCriterioForm() {
    this.formCriterio.controls["descripcion"].setValue("");
    this.formCriterio.controls["observacion"].setValue("");

    this.formCriterio = this.formBuilder.group({
      descripcion: ["", Validators.required],
      observacion: ["", Validators.required],
    });
  }

  removeGroup(index, correlativo) {
    if (correlativo > 0) {
      this.criteriosServices.postDeleteGrupos(this.objGrupos[index]).subscribe(
        (resp) => {
          let response: any = resp;

          this.licitacionService
            .postDeleteAyudaEvaltecnicaEditableByGrupo({
              objeto_grupo: this.objGrupos[index],
            })
            .subscribe((resp) => {
              this.objGrupos.splice(index, 1);
              this.getAyudaEvalTecnicaEditable();
              this.calcularPorcentajesFormularios();
            });
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else {
      this.objGrupos.splice(index, 1);
      this.calcularPorcentajesFormularios();
    }
  }

  removeSubGroup(index, indexSub, correlativo) {
    if (correlativo > 0) {
      this.criteriosServices
        .postDeleteSubGrupos(this.objGrupos[index].subgrupoLs[indexSub])
        .subscribe(
          (resp) => {
            let response: any = resp;

            this.licitacionService
              .postDeleteAyudaEvaltecnicaEditable({
                correlativo_subgrupo: correlativo,
              })
              .subscribe(
                (resp) => {
                  this.objGrupos[index].subgrupoLs.splice(indexSub, 1);
                  this.getAyudaEvalTecnicaEditable();
                  this.calcularPorcentajesFormularios();
                },
                (error) => {
                  console.log(error);
                }
              );
          },
          (error) => {
            console.log(error);
          },
          () => {}
        );
    } else {
      this.objGrupos[index].subgrupoLs.splice(indexSub, 1);
      this.calcularPorcentajesFormularios();
    }
  }

  removeCriterio(index, indexSub, indexCrit, correlativo) {
    if (correlativo > 0) {
      //alert("delete con carga de datos");
      this.criteriosServices
        .postDeleteCriterio(
          this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCrit]
        )
        .subscribe(
          (resp) => {
            let response: any = resp;
            this.objGrupos[index].subgrupoLs[indexSub].criteriosLs.splice(
              indexCrit,
              1
            );
            this.calcularPorcentajesFormularios();
          },
          (error) => {
            console.log(error);
          },
          () => {}
        );
    } else {
      this.objGrupos[index].subgrupoLs[indexSub].criteriosLs.splice(
        indexCrit,
        1
      );
      this.calcularPorcentajesFormularios();
    }
  }

  LoadUpdateGroupForm(index, correlativo) {
    this.modalGrupo = true;
    this.isUpdateGrupo = true;
    this.indexGrupo = index;
    if (correlativo > 0) {
      //alert("delete con carga de datos");
    } else {
      //this.objGrupos.splice(index,1);
    }
    this.formGroup.controls["descripcion"].setValue(
      this.objGrupos[index].descripcion
    );
    //this.formGroup.controls['correlativo'].setValue(this.objGrupos[index].correlativo);
    //this.formGroup.controls['correlativo_licitacion'].setValue(this.objGrupos[index]);
    this.formGroup.controls["peso"].setValue(this.objGrupos[index].peso);
  }

  LoadUpdateSubGroupForm(index, indexSub, correlativo) {
    this.modalSubgrupo = true;
    this.isUpdateSubgrupo = true;
    this.indexGrupo = index;
    this.indexSubgrupo = indexSub;
    if (correlativo > 0) {
      //alert("delete con carga de datos");
    } else {
      //this.objGrupos.splice(index,1);
    }
    this.formSubgroup.controls["descripcion"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].descripcion
    );
    this.formSubgroup.controls["observacion"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].observacion
    );
    //this.formSubgroup.controls['subgrupo_abierto'].setValue(this.objGrupos[index].subgrupoLs[indexSub].subgrupo_abierto);
    this.formSubgroup.controls["peso"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].peso
    );
    this.formSubgroup.controls["tipo"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].tipo
    );
    this.formSubgroup.controls["idAyuda"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].idAyuda
    );
    this.formSubgroup.controls["limiteRespuestasOferente"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].limiteRespuestasOferente
    );

    this.idAyudaToUpdate = this.objGrupos[index].subgrupoLs[indexSub].idAyuda;
    this.correlativoSubgrupoSelected = correlativo;
  }
  LoadUpdateCriterioForm(index, indexSub, indexCriterio, correlativo) {
    this.modalCriterio = true;
    this.isUpdateCriterio = true;
    this.indexGrupo = index;
    this.indexSubgrupo = indexSub;
    this.indexCriterio = indexCriterio;
    if (correlativo > 0) {
      //alert("delete con carga de datos");
    } else {
      //this.objGrupos.splice(index,1);
    }
    this.formCriterio.controls["descripcion"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
        .descripcion
    );
    //this.formGroup.controls['correlativo'].setValue(this.objGrupos[index].correlativo);
    //this.formGroup.controls['correlativo_licitacion'].setValue(this.objGrupos[index]);
    this.formCriterio.controls["observacion"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
        .observacion
    );
  }

  updateGrupo() {
    /* var descr = this.formCriterio.get("descripcion").value;
    var obj = this.formCriterio.get("observacion").value; */
    let pesoPermitido = 0;
    this.calcularPorcentajesFormularios();

    if (this.formGroup.get("peso").value <= 0) {
      this.notifyService.showWarning(
        "El porcentaje ingresado no puede ser manor o igual a 0",
        "Advertencia"
      );
    } else {
      if (this.formGroup.get("peso").value <= 100) {
        // validacicion de si el porcentaje no sobre pasas el peso total permitido
        let correlativoGrupo = this.objGrupos[this.indexGrupo].correlativo;
        let objGrupoFilter = this.objGrupos.filter(
          (item) => item.correlativo == correlativoGrupo
        );
        let calcularPesoPermitido =
          this.gruposPesoTotal -
          objGrupoFilter[0].peso +
          this.formGroup.get("peso").value;
        //let pesoPermitidoGrupo = 100 - this.gruposPesoTotal;

        if (calcularPesoPermitido <= 100) {
          this.objGrupos[this.indexGrupo].descripcion =
            this.formGroup.get("descripcion").value;
          this.objGrupos[this.indexGrupo].peso =
            this.formGroup.get("peso").value;
        } else {
          this.notifyService.showWarning(
            "El peso ingresado excede la cantidad permitida",
            "Advertencia"
          );
        }
      } else {
        this.notifyService.showWarning(
          "El porcentaje no puede ser mayor a 100",
          "Advertencia"
        );
      }

      this.calcularPorcentajesFormularios();
      pesoPermitido = 100 - this.gruposPesoTotal;
      this.pesoPermitidoGrupo = pesoPermitido;
    }
  }

  updateSubGrupo() {
    /* var descr = this.formCriterio.get("descripcion").value;
    var obj = this.formCriterio.get("observacion").value; */
    /* var descr = this.formSubgroup.get("descripcion").value;
    var obj = this.formSubgroup.get("observacion").value;
    var peso = this.formSubgroup.get("peso").value; */
    this.calcularPorcentajesFormularios();

    if (this.formSubgroup.get("peso").value <= 0) {
      this.notifyService.showWarning(
        "El porcentaje no puede ser menor o igual a 0",
        "Advertencia"
      );
    } else {
      if (this.formSubgroup.get("peso").value <= 100) {
        // validacion por si el peso ingresado sobrepasa el 100%
        let correlativoGrupo = this.objGrupos[this.indexGrupo].correlativo;
        let objGrupoFilter = this.objPesosGruposYSubgrupos.filter(
          (item) => item.correlativo === correlativoGrupo
        );
        let pesoTotalSubCapitulos =
          objGrupoFilter[0].subGrupoPesoTotal -
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].peso +
          this.formSubgroup.get("peso").value;

        if (pesoTotalSubCapitulos <= 100) {
          this.objGrupos[this.indexGrupo].subgrupoLs[
            this.indexSubgrupo
          ].descripcion = this.formSubgroup.get("descripcion").value;
          this.objGrupos[this.indexGrupo].subgrupoLs[
            this.indexSubgrupo
          ].observacion = this.formSubgroup.get("observacion").value;
          //this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].subgrupo_abierto = this.formSubgroup.get("subgrupo_abierto").value;
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].peso =
            this.formSubgroup.get("peso").value;
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].tipo =
            this.formSubgroup.get("tipo").value;
          this.objGrupos[this.indexGrupo].subgrupoLs[
            this.indexSubgrupo
          ].idAyuda = this.formSubgroup.get("idAyuda").value;
          this.objGrupos[this.indexGrupo].subgrupoLs[
            this.indexSubgrupo
          ].limiteRespuestasOferente = this.formSubgroup.get(
            "limiteRespuestasOferente"
          ).value;

          if (this.idAyudaToUpdate == this.formSubgroup.get("idAyuda").value) {
            console.log("iguales");
          } else {
            this.licitacionService
              .postUpdateAyudaEditableBase({
                in_subgrupo_correlativo: this.correlativoSubgrupoSelected,
                in_idayuda_correlativo: this.formSubgroup.get("idAyuda").value,
                in_licitacion_correlativo: this.idLicitacion,
              })
              .subscribe((resp) => {
                this.getAyudaEvalTecnicaEditable();
              });
          }
        } else {
          this.notifyService.showWarning(
            "El peso ingresado supera la capacidad permitida.",
            "Advertencia"
          );
        }
      } else {
        this.notifyService.showWarning(
          "El porcentaje no puede ser mayor a 100",
          "Advertencia"
        );
      }
    }
    this.calcularPorcentajesFormularios();
  }
  updateCriterios() {
    /* var descr = this.formCriterio.get("descripcion").value;
    var obj = this.formCriterio.get("observacion").value; */
    this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs[
      this.indexCriterio
    ].descripcion = this.formCriterio.get("descripcion").value;
    this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs[
      this.indexCriterio
    ].observacion = this.formCriterio.get("observacion").value;
  }

  saveInicial() {
    this.calcularPorcentajesFormularios();

    if (this.objPesosGruposYSubgrupos.length > 0) {
      // valdiar los porcentajes de los capitullos
      if (this.gruposPesoTotal == 100) {
        // verificar porcentaje de subcapitulos
        let isSubcapitulosValidos: boolean = false;
        for (
          let index = 0;
          index < this.objPesosGruposYSubgrupos.length;
          index++
        ) {
          const element = this.objPesosGruposYSubgrupos[index];

          if (element.subGrupoPesoTotal != 100) {
            isSubcapitulosValidos = false;
            break;
          } else {
            isSubcapitulosValidos = true;
          }
        }

        if (isSubcapitulosValidos) {
          this.criteriosServices.postGrupo(this.objGrupos).subscribe(
            (resp) => {
              let response: any = resp;
              this.notifyService.showSuccess("", "Datos guardados");
              if (response.data?.length > 0) {
                this.objGrupos = response.data;
                // aqui cosalamente se va a obtener el estadar de las ayudas de evaluacion tecnica editables
                this.licitacionService
                  .postSaveAyudaEvalTecnicaEditable({
                    correlativo: this.idLicitacion,
                  })
                  .subscribe((resp) => {
                    const response: any = JSON.parse(resp);
                    this.ayudasEditables = response;
                    console.log(response);
                  });
                //this.data.emit(this.objGrupos);
              }
            },
            (error) => {
              console.log(error);
            },
            () => {}
          );
        } else {
          this.notifyService.showWarning(
            "Por favor verifique que los subcapitulos sumen un 100%",
            "Advertencia"
          );
        }
      } else {
        this.notifyService.showWarning(
          "Por favor verifique que los capitulos sumen un 100%",
          "Advertencia"
        );
      }
    } else {
      this.notifyService.showWarning(
        "Por favor verifique que el formulario este completo",
        "Advertencia"
      );
    }
  }

  save() {
    this.calcularPorcentajesFormularios();

    if (this.objPesosGruposYSubgrupos.length > 0) {
      // valdiar los porcentajes de los capitullos
      if (this.gruposPesoTotal == 100) {
        // verificar porcentaje de subcapitulos
        let isSubcapitulosValidos: boolean = false;
        for (
          let index = 0;
          index < this.objPesosGruposYSubgrupos.length;
          index++
        ) {
          const element = this.objPesosGruposYSubgrupos[index];

          if (element.subGrupoPesoTotal != 100) {
            isSubcapitulosValidos = false;
            break;
          } else {
            isSubcapitulosValidos = true;
          }
        }

        if (isSubcapitulosValidos) {
          this.criteriosServices.postGrupo(this.objGrupos).subscribe(
            (resp) => {
              let response: any = resp;
              this.notifyService.showSuccess("", "Datos guardados");
              if (response.data?.length > 0) {
                this.objGrupos = response.data;

                this.licitacionService
                  .postComprobarExiteAyudaEvaltecnicaEditable({
                    objeto_grupos: this.objGrupos,
                  })
                  .subscribe((resp) => {
                    this.data.emit(this.objGrupos);
                    this.getAyudaEvalTecnicaEditable();
                  });
              }
            },
            (error) => {
              console.log(error);
            },
            () => {}
          );
        } else {
          this.notifyService.showWarning(
            "Por favor verifique que los subcapitulos sumen un 100%",
            "Advertencia"
          );
        }
      } else {
        this.notifyService.showWarning(
          "Por favor verifique que los capitulos sumen un 100%",
          "Advertencia"
        );
      }
    } else {
      this.notifyService.showWarning(
        "Por favor verifique que el formulario este completo",
        "Advertencia"
      );
    }
  }

  getGrupos() {
    this.criteriosServices.getGrupos(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;

        if (response.data?.length > 0) {
          this.objGrupos = response.data;
          this.getAyudaEvalTecnicaEditable();
        } else {
          this.setDefault();
        }

        this.calcularPorcentajesFormularios();
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  calcularPorcentajesFormularios() {
    let gruposPesoTotal = 0;
    let subGruposPesoTotal = 0;
    let objPesoXGrupos = [];
    let objPesoXSubGrupos = [];

    for (let index = 0; index < this.objGrupos.length; index++) {
      const element = this.objGrupos[index];
      gruposPesoTotal = gruposPesoTotal + this.objGrupos[index].peso;

      for (let i = 0; i < this.objGrupos[index].subgrupoLs.length; i++) {
        const subGrupoelement = this.objGrupos[index].subgrupoLs[i];
        subGruposPesoTotal = subGruposPesoTotal + subGrupoelement.peso;
        objPesoXSubGrupos.push({
          correlativo: subGrupoelement.correlativo,
          peso: subGrupoelement.peso,
        });
      }

      objPesoXGrupos.push({
        correlativo: element.correlativo,
        pesoXGrupo: this.objGrupos[index].peso,
        subGrupos: objPesoXSubGrupos,
        subGrupoPesoTotal: subGruposPesoTotal,
      });

      subGruposPesoTotal = 0;
      objPesoXSubGrupos = [];
    }

    //console.log(objPesoXGrupos);
    //console.log(gruposPesoTotal);

    this.gruposPesoTotal = gruposPesoTotal;
    this.pesoPermitidoGrupo = 100 - gruposPesoTotal;
    this.objPesosGruposYSubgrupos = objPesoXGrupos;

    // console.log(this.objPesosGruposYSubgrupos);
    // console.log(this.gruposPesoTotal);
  }

  getAyudaEvalCriterios() {
    this.criteriosServices.getAyudaEvalTecnicaCriterios().subscribe((resp) => {
      let response: any = resp;
      this.ayudaEvalTecnicaLs = response.ayudaEvalTecCriterios;
      // this.ayudaEvalTecnicaLsData.emit(this.ayudaEvalTecnicaLs);
    });
  }

  setDefault() {
    this.getEstandaresEvalTecnica();

    // this.objGrupos.push({
    //   correlativo: 0,
    //   descripcion: "A. Experiencia del personal",
    //   peso: 20,
    //   licitacion_correlativo: this.idLicitacion,
    //   subgrupoLs: [
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "A.1 Estructura organizacional propuesta para el servicio",
    //       observacion:
    //         "Indicando turnos propuestos, separando las etapas de movilización, ejecución y desmovilización.",
    //       grupo_correlativo: 0,
    //       peso: 30,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 1,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion: "A.2 Cargos",
    //       observacion: "TEC 10, listado de CARGOS",
    //       grupo_correlativo: 0,
    //       peso: 20,
    //       subgrupo_abierto: true,
    //       tipo: 2,
    //       idAyuda: 2,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion: "A.3 Curriculum Vitae del personal clave",
    //       observacion:
    //         "Administrador de Contrato, Jefe de Faena, EPR, Supervisor",
    //       grupo_correlativo: 0,
    //       peso: 40,
    //       subgrupo_abierto: true,
    //       tipo: 3,
    //       idAyuda: 3,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion: "A.4 Respaldo de Solicitud de Jornada excepcional",
    //       observacion: "Siempre y cuando aplica",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 4,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //   ],
    // });
    // this.objGrupos.push({
    //   correlativo: 0,
    //   descripcion: "B. Equipos",
    //   peso: 20,
    //   licitacion_correlativo: this.idLicitacion,
    //   subgrupoLs: [
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "B.1 Nómina de equipos de movimiento de tierra, equipos de apoyo, y vehículos propuestos",
    //       observacion:
    //         "especificando: estado, antigüedad, marca, modelo, kilometraje.",
    //       grupo_correlativo: 0,
    //       peso: 50,
    //       subgrupo_abierto: true,
    //       tipo: 4,
    //       idAyuda: 5,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "B.2 Ficha Técnica de equipos de movimiento de tierra y apoyo",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 50,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 6,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //   ],
    // });
    // this.objGrupos.push({
    //   correlativo: 0,
    //   descripcion: "C. Metodología y Procedimientos",
    //   peso: 20,
    //   licitacion_correlativo: this.idLicitacion,
    //   subgrupoLs: [
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.1 Metodología y/o Procedimiento de Traslado de Equipos.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 10,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.2 Metodología y/o Procedimiento de Trabajo para las Actividades de Movimiento de Tierra.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 11,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.3 Metodología propuesta para los Cambios de Turno (jornada diaria y completa), considerando medidas COVID de acuerdo a protocolos EMSA y tomas de test de antígeno antes de iniciar el traslado a proyecto.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 14,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.4 Procedimiento de Conducción y uso de Vehículos y Equipos.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 15,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion: "C.5 Procedimiento de Emergencias.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 16,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.6 Plan de Mantenimiento de Equipos de Movimiento de Tierra y de Apoyo.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 17,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.7 Cronograma general para la ejecución del servicio y otros cronogramas aperturados con más detalle para etapas de movilización, movilización entre proyectos, ejecución y desmovilización.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 15,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 18,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.8 Detalle de servicios y equipos contratados a terceros.",
    //       observacion:
    //         "Detallar los servicios que necesita subcontratar, especificando el tipo de servicio, el personal (dotación) requerida, si este no fuera continuo, las etapas del proyecto donde participará (TEC-11).",
    //       grupo_correlativo: 0,
    //       peso: 10,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 19,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "C.9 Metodología detallada de todos los trabajos a ejecutar por el personal de su empresa, con los respectivos procedimientos e instructivos de cada una de las tareas que realizará el personal en las áreas de trabajo",
    //       observacion:
    //         "Sean estas rutinarias como también aquellas que sean necesarias y que puedan surgir de eventos emergentes propios de la operación de equipos de Movimiento de Tierra (Incluir instalación y retiro de martillos pica-rocas en los equipos).",
    //       grupo_correlativo: 0,
    //       peso: 15,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 20,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //   ],
    // });

    // this.objGrupos.push({
    //   correlativo: 0,
    //   descripcion: "D. Seguridad y Salud Ocupacional",
    //   peso: 20,
    //   licitacion_correlativo: this.idLicitacion,
    //   subgrupoLs: [
    //     {
    //       correlativo: 0,
    //       descripcion: "D.1 Seguridad y Salud Ocupacional",
    //       observacion:
    //         "Para evaluar los aspectos de Seguridad y Salud Ocupacional de cada propuesta, se utilizará la Matriz de Calificación de Seguridad y Salud en el Trabajo.",
    //       grupo_correlativo: 0,
    //       peso: 100,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 21,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //   ],
    // });

    // this.objGrupos.push({
    //   correlativo: 0,
    //   descripcion: "E. Sustentabilidad",
    //   peso: 20,
    //   licitacion_correlativo: this.idLicitacion,
    //   subgrupoLs: [
    //     {
    //       correlativo: 0,
    //       descripcion:
    //         "E.1 Matriz de identificación de aspectos ambientales y controles operacionales.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 40,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 22,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //     {
    //       correlativo: 0,
    //       descripcion: "E.2 Plan de Gestión Ambiental.",
    //       observacion: "",
    //       grupo_correlativo: 0,
    //       peso: 60,
    //       subgrupo_abierto: true,
    //       tipo: 1,
    //       idAyuda: 23,
    //       limiteRespuestasOferente: 1,
    //       criteriosLs: [],
    //     },
    //   ],
    // });
  }

  getEstandaresEvalTecnica() {
    this.licitacionService.getEstandaresEvalTecnica().subscribe((resp) => {
      this.estandaresEvalTecnica = JSON.parse(resp);
      this.formEstandares = new FormGroup({
        estandarEvalTecSelect: new FormControl(
          this.estandaresEvalTecnica[0].correlativo,
          [Validators.required]
        ),
      });
    });
  }

  onGetEstandarEvalTecnica() {
    this.objGrupos = [];

    if (this.formEstandares.invalid) {
      this.notifyService.showWarning(
        "Debe seleccinar un estandar de evaluación",
        "Advertencia"
      );
      return;
    }

    this.licitacionService
      .postBuildEstandarEvalTecnica({
        idLicitacion: this.idLicitacion,
        correlativoEstandar: this.formEstandares.value.estandarEvalTecSelect,
      })
      .subscribe((resp) => {
        this.objGrupos = JSON.parse(resp);
        //console.log(this.objGrupos);

        if (this.objGrupos.length > 0) {
          this.saveInicial();
        } else {
          this.notifyService.showWarning(
            "No hay un estadar definido para esta opción.",
            "Advertencia"
          );
        }
      });
  }

  deleteEstandarCriterioSeleccionado() {
    this.licitacionService
      .postDeleteEstandarCriterioSeleccionado({
        in_licitacion_correlativo: this.idLicitacion,
      })
      .subscribe((resp) => {
        this.notifyService.showSuccess(
          "Estandar eliminado correctamente",
          "Estandar"
        );
        this.objGrupos = [];
        this.getGrupos();
      });
  }

  getAyudaEvalTecnicaEditable() {
    this.licitacionService
      .getAyudaEvalTecnicaEditable(this.idLicitacion)
      .subscribe((resp) => {
        const response: any = JSON.parse(resp);
        this.ayudasEditables = response;
        console.log(response);
      });
  }

  filterAyudaSeleccionada(correlativo: number) {
    this.cargosFilter = [];
    this.ayudasEditablesSelected = this.ayudasEditables.filter(
      (data) => data.correlativo_subgrupo === correlativo
    );
    this.ayudaEditableSubgrupoSelected = correlativo;

    for (let index = 0; index < this.ayudasEditablesSelected.length; index++) {
      const element = this.ayudasEditablesSelected[index];

      if (element?.cargo_ayuda != "" && element?.cargo_ayuda != null) {
        this.cargosFilter.push(element?.cargo_ayuda);
      }
    }

    this.cargosFilter = Array.from(new Set(this.cargosFilter));

    console.log(this.cargosFilter);
  }

  editarAyudaRelacionadaSubgrupo(correlativo: number) {
    this.tipoFormAyudaRelacionadaSubgrupo = 1;
    this.idAyudaRelacionadaSubgrupo = correlativo;

    const ayudaSeleccionada = this.ayudasEditablesSelected.filter(
      (data) => data.correlativo === correlativo
    );

    this.formUpdateAyudaRelacionadaSubgrupo = this.formBuilder.group({
      descripcionAyuda: [
        ayudaSeleccionada[0].descripcion_ayuda,
        Validators.required,
      ],
      notaAyuda: [ayudaSeleccionada[0].nota_ayuda, Validators.required],
      cargoAyuda: [ayudaSeleccionada[0].cargo_ayuda],
      ponderadorAyuda: [ayudaSeleccionada[0].ponderador_ayuda],
      perfilAyuda: [ayudaSeleccionada[0].perfil_requerido_ayuda],
    });
  }

  eliminarAyudaRelacionadaSubgrupo(correlativo: number) {
    this.tipoFormAyudaRelacionadaSubgrupo = 2;
    this.idAyudaRelacionadaSubgrupo = correlativo;

    const ayudaSeleccionada = this.ayudasEditablesSelected.filter(
      (data) => data.correlativo === correlativo
    );
    this.ayudaRelacionadaSubgrupoDescripcion =
      ayudaSeleccionada[0].descripcion_ayuda;
  }

  resetUpdateFormAyudaRelacionadaSubgrupo() {
    this.formUpdateAyudaRelacionadaSubgrupo = this.formBuilder.group({
      descripcionAyuda: [null, Validators.required],
      notaAyuda: [0, Validators.required],
      cargoAyuda: [null],
      ponderadorAyuda: [null],
      perfilAyuda: [null],
    });
  }

  updateAyudaRelacionadaSubgrupo() {
    if (this.formUpdateAyudaRelacionadaSubgrupo.invalid) {
      this.notifyService.showWarning(
        "Debe rellenar todos los campos",
        "Advertencia"
      );
      return;
    }

    const postData = {
      correlativo: this.idAyudaRelacionadaSubgrupo,
      ayudaDescripcion:
        this.formUpdateAyudaRelacionadaSubgrupo.value.descripcionAyuda,
      ayudaNota: this.formUpdateAyudaRelacionadaSubgrupo.value.notaAyuda,
      ayudaCargo: this.formUpdateAyudaRelacionadaSubgrupo.value.cargoAyuda,
      ayudaPonderador:
        this.formUpdateAyudaRelacionadaSubgrupo.value.ponderadorAyuda,
      ayudaPerfil: this.formUpdateAyudaRelacionadaSubgrupo.value.perfilAyuda,
    };

    this.licitacionService
      .postUpdateAyudaEditableSubgrupo(postData)
      .subscribe((resp) => {
        this.notifyService.showSuccess("Ayuda editada correctamente", "Ayuda");
        this.licitacionService
          .getAyudaEvalTecnicaEditable(this.idLicitacion)
          .subscribe((resp) => {
            const response: any = JSON.parse(resp);
            this.ayudasEditables = response;
            const subgrupoCorrelativo = this.ayudasEditablesSelected.filter(
              (data) => data.correlativo === this.idAyudaRelacionadaSubgrupo
            );
            this.ayudasEditablesSelected = this.ayudasEditables.filter(
              (data) =>
                data.correlativo_subgrupo ===
                subgrupoCorrelativo[0].correlativo_subgrupo
            );

            for (
              let index = 0;
              index < this.ayudasEditablesSelected.length;
              index++
            ) {
              const element = this.ayudasEditablesSelected[index];

              if (element?.cargo_ayuda != "" && element?.cargo_ayuda != null) {
                this.cargosFilter.push(element?.cargo_ayuda);
              }
            }
            this.cargosFilter = Array.from(new Set(this.cargosFilter));
          });
      });
  }

  deleteAyudaRelacionadaSubgrupo() {
    const postData = {
      correlativo: this.idAyudaRelacionadaSubgrupo,
    };

    this.licitacionService
      .postDeleteAyudaEditableSubgrupo(postData)
      .subscribe((resp) => {
        this.licitacionService
          .getAyudaEvalTecnicaEditable(this.idLicitacion)
          .subscribe((resp) => {
            const response: any = JSON.parse(resp);
            this.ayudasEditables = response;
            const subgrupoCorrelativo = this.ayudasEditablesSelected.filter(
              (data) => data.correlativo === this.idAyudaRelacionadaSubgrupo
            );
            this.ayudasEditablesSelected = this.ayudasEditables.filter(
              (data) =>
                data.correlativo_subgrupo ===
                subgrupoCorrelativo[0].correlativo_subgrupo
            );

            for (
              let index = 0;
              index < this.ayudasEditablesSelected.length;
              index++
            ) {
              const element = this.ayudasEditablesSelected[index];

              if (element?.cargo_ayuda != "" && element?.cargo_ayuda != null) {
                this.cargosFilter.push(element?.cargo_ayuda);
              }
            }
            this.cargosFilter = Array.from(new Set(this.cargosFilter));

            this.notifyService.showSuccess(
              "Ayuda eliminada correctamente",
              "Ayuda"
            );
            this.tipoFormAyudaRelacionadaSubgrupo = 0;
          });
      });
  }

  resetCreateFormAyudaRelacionadaSubgrupo() {
    this.formCreateAyudaRelacionadaSubgrupo = this.formBuilder.group({
      descripcionAyuda: [null, Validators.required],
      notaAyuda: [0, Validators.required],
      cargoAyuda: [null],
      ponderadorAyuda: [null],
      perfilAyuda: [null],
    });
  }

  createAyudaRelacionadaSubgrupo() {
    if (this.formCreateAyudaRelacionadaSubgrupo.invalid) {
      this.notifyService.showWarning(
        "Debe rellenar todos los campos",
        "Advertencia"
      );
      return;
    }

    const postData = {
      correlativoSubgrupo: this.ayudaEditableSubgrupoSelected,
      idAyuda: 0,
      descripcion:
        this.formCreateAyudaRelacionadaSubgrupo.value.descripcionAyuda,
      nota: this.formCreateAyudaRelacionadaSubgrupo.value.notaAyuda,
      idLicitacion: this.idLicitacion,
      cargo: this.formCreateAyudaRelacionadaSubgrupo.value.cargoAyuda,
      ponderador: this.formCreateAyudaRelacionadaSubgrupo.value.ponderadorAyuda,
      perfil: this.formCreateAyudaRelacionadaSubgrupo.value.perfilAyuda,
    };

    this.licitacionService
      .postCreateAyudaEditableSubgrupo(postData)
      .subscribe((resp) => {
        this.licitacionService
          .getAyudaEvalTecnicaEditable(this.idLicitacion)
          .subscribe((resp) => {
            const response: any = JSON.parse(resp);
            this.ayudasEditables = response;
            this.ayudasEditablesSelected = this.ayudasEditables.filter(
              (data) =>
                data.correlativo_subgrupo === this.ayudaEditableSubgrupoSelected
            );
            for (
              let index = 0;
              index < this.ayudasEditablesSelected.length;
              index++
            ) {
              const element = this.ayudasEditablesSelected[index];

              if (element?.cargo_ayuda != "" && element?.cargo_ayuda != null) {
                this.cargosFilter.push(element?.cargo_ayuda);
              }
            }

            this.cargosFilter = Array.from(new Set(this.cargosFilter));
            // this.ayudasEditablesSelected = this.ayudasEditablesSelected.sort((a, b) => a.correlativo - b.correlativo);
            this.notifyService.showSuccess(
              "Ayuda creada correctamente",
              "Ayuda"
            );
            this.resetCreateFormAyudaRelacionadaSubgrupo();
            this.tipoFormAyudaRelacionadaSubgrupo = 0;
          });
      });
  }

  selectCargo(cargo: string) {
    if (cargo != "") {
      this.ayudasEditablesSelected = this.ayudasEditables.filter((data) => {
        return (
          data?.cargo_ayuda
            ?.toString()
            .toLowerCase()
            .includes(cargo.toLowerCase()) &&
          data?.correlativo_subgrupo === this.ayudaEditableSubgrupoSelected
        );
      });
    } else {
      this.ayudasEditablesSelected = this.ayudasEditables.filter((data) => {
        return (
          data?.correlativo_subgrupo === this.ayudaEditableSubgrupoSelected
        );
      });
    }
  }

  onCrearPlantillaEstandar() {
    if (this.formGuardarPlantillaEstandar.invalid) {
      this.notifyService.showWarning(
        "Debe rellenar todos los campos",
        "Advertencia"
      );
      return;
    }

    this.licitacionService.getEstandaresEvalTecnica().subscribe(
      (resp) => {
        const estadaresExistentes = JSON.parse(resp);
        const estandarFilter = estadaresExistentes.filter(
          (data) =>
            data.descripcion_estandar ===
            this.formGuardarPlantillaEstandar.value.descripcionEstandar
        );

        if (estandarFilter.length > 0) {
          this.notifyService.showWarning(
            "El estandar ya existe",
            "Advertencia"
          );
          return;
        }

        const postData = {
          idLicitacion: this.idLicitacion,
          descripcionEstandar:
            this.formGuardarPlantillaEstandar.value.descripcionEstandar,
        };

        this.licitacionService
          .postCreatePlantillaEstandarEvaltec(postData)
          .subscribe(
            (resp) => {
              this.notifyService.showSuccess(
                "Estandar creado correctamente",
                "Estandar"
              );
              this.getEstandaresEvalTecnica();
              this.closeModalCreatePlantillaEstandar.nativeElement.click();
            },
            (err) => {
              this.notifyService.showError(
                err.error.message,
                "Error al crear estandar"
              );
            }
          );
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Error al obtener estandares existentes"
        );
      }
    );
  }

  resetCrearPlantillaEstandar() {
    this.formGuardarPlantillaEstandar = new FormGroup({
      descripcionEstandar: new FormControl(null, [Validators.required]),
    });
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }
}
