<!-- begin #content -->
<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <div class="col-6 text-left">
            <button style="margin-right: 10px;" type="button" class="btn btn-primary" (click)="back()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                    style="color: white;"></i> Volver</button>
        </div>
        <div class="col-6 text-right">
            <div>
                <ng-container *ngIf="objLicitacion?.ETAPA_CORRELATIVO == 6 && isRespuestasCompletadas() !== false">
                    <button style="margin-right: 10px;" type="button" class="btn btn-primary" (click)="imprimir()"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-print" style="color: white;"></i>
                        Imprimir</button>
                    <button style="margin-right: 10px;" type="button"
                        class="btn btn-primary"
                        routerLink="/upload-etapa-archivo/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-upload" style="color: white;"></i>
                        Subir
                        Doc. Administrativos</button>
                </ng-container>
            </div>
        </div>
    </div>
    <br>
    <ng-container *ngIf="objLicitacion?.ETAPA_CORRELATIVO == 6">
        <ng-container *ngIf="isRespuestasCompletadas() === false">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-danger" role="alert">
                        Falta completar información o antecedentes de la Oferta Técnica. Favor, complete toda la
                        información
                        antes de enviar la Oferta Técnica
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isRespuestasCompletadas() !== false">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-success" role="alert">
                        <i class="fas fa-check-circle"></i> Ha completado la información de la Oferta Técnica. Verifique
                        la
                        información, descargue la Oferta Técnica y luego complete los documentos administrativos.
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <div class="main-group" *ngFor="let grupo of objGrupos; let i = index">
        <div class="group">
            <div class="mb-0">
                <!-- <span class="q-group mr-2">{{grupo.peso}}</span> -->
                <span class="text-white">
                    {{grupo.descripcion}}
                </span>
            </div>
        </div>
        <div>
            <div class="subgroup" *ngFor="let subgrupo of grupo.subgrupoLs; let y = index"
                style="padding-left: 3rem; padding-right: 3rem">
                <div class="subgroup_container">
                    <!-- {{y + 1}}.-  ({{subgrupo.peso}}%) -->
                    <span class="mb-0 text-subgroup">
                        <p class="bold">{{subgrupo.descripcion}} </p>
                        <p class="text-muted mb-0">{{subgrupo.observacion}}</p>
                    </span>
                    <ng-container>
                        <div class="subgroup_options">
                            <span *ngIf="subgrupo.criteriosLs?.length < subgrupo.limiteRespuestasOferente"
                                style="font-weight: bold;" class="mr-2 text-danger">
                                Respuestas:
                                <span *ngIf="subgrupo.criteriosLs?.length <= 0">0</span>
                                <span *ngIf="subgrupo.criteriosLs?.length > 0">{{subgrupo.criteriosLs?.length}}</span>
                                / {{subgrupo.limiteRespuestasOferente}}
                            </span>
                            <span *ngIf="subgrupo.criteriosLs?.length == subgrupo.limiteRespuestasOferente"
                                style="font-weight: bold;" class="mr-2 text-success">
                                Completado:
                                <span *ngIf="subgrupo.criteriosLs?.length <= 0">0</span>
                                <span *ngIf="subgrupo.criteriosLs?.length > 0">{{subgrupo.criteriosLs?.length}}</span>
                                / {{subgrupo.limiteRespuestasOferente}}
                            </span>
                            <a *ngIf="subgrupo.criteriosLs?.length < subgrupo.limiteRespuestasOferente"
                                class="btn tag text-primary" href="#modalCriterio" data-toggle="modal"
                                (click)="indexGrupo = i; indexSubgrupo = y; modalCriterio = true;isUpdateCriterio = false;cleanCriterioForm()">Agregar</a>
                        </div>
                    </ng-container>
                    <!-- <a class="btn tag" (click)="removeSubGroup(i,y,subgrupo.correlativo)">remove</a> -->
                </div>
                <div class="d-table" *ngIf="subgrupo.criteriosLs?.length > 0">
                    <div class="t-head">
                        <span class="d-cell">Descripción</span>
                        <span *ngIf="subgrupo.tipo == 2" class="d-cell">Cantidad</span>
                        <span *ngIf="subgrupo.tipo == 2" class="d-cell">Turno</span>
                        <span *ngIf="subgrupo.tipo == 3" class="d-cell">Nombres/Apellidos</span>
                        <span *ngIf="subgrupo.tipo == 3" class="d-cell">Perfil</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">Marca</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">Modelo</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">Año</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">Propiedad</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">ECF</span>
                        <span class="d-cell">Observación</span>
                        <span class="d-cell">Nombre archivo</span>
                        <span class="d-cell"></span>
                    </div>
                    <div class="criterio" *ngFor="let criterio of subgrupo.criteriosLs; let x = index">
                        <!-- <div class="criterio_container"> -->

                        <!-- <span *ngIf="subgrupo.tipo == 1" class="text-criterio "> -->
                        <span class="d-cell">{{criterio.descripcion}}</span>
                        <!-- <span class="d-cell text-muted">{{criterio.observacion}}</span> -->
                        <!-- </span> -->

                        <!-- <span *ngIf="subgrupo.tipo == 2" class="text-criterio "> -->
                        <!-- <span class="d-cell">{{criterio.descripcion}}</span> -->
                        <span *ngIf="subgrupo.tipo == 2" class="d-cell">{{criterio.cantidad}}</span>
                        <span *ngIf="subgrupo.tipo == 2" class="d-cell">
                            <div *ngIf="criterio.turno == 1">
                                Diurno
                            </div>
                            <div *ngIf="criterio.turno == 2">
                                Nocturno
                            </div>
                        </span>
                        <!-- <span class="d-cell text-muted">{{criterio.observacion}}</span> -->
                        <!-- </span> -->

                        <!-- <span *ngIf="subgrupo.tipo == 3" class="text-criterio "> -->
                        <!-- <span class="d-cell">{{criterio.descripcion}}</span> -->
                        <span *ngIf="subgrupo.tipo == 3" class="d-cell">{{criterio.nombre_apellido}}</span>
                        <span *ngIf="subgrupo.tipo == 3" class="d-cell">{{criterio.perfil}}</span>
                        <!-- <span class="d-cell text-muted ">{{criterio.observacion}}</span> -->
                        <!-- </span> -->

                        <!-- <span *ngIf="subgrupo.tipo == 4" class="text-criterio"> -->
                        <!-- <span class="d-cell">{{criterio.descripcion}}</span> -->
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">{{criterio.marca}}</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">{{criterio.modelo}}</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">{{criterio.ano}}</span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">
                            <div *ngIf="criterio.tipo_propiedad == 1">Propio</div>
                            <div *ngIf="criterio.tipo_propiedad == 2">Arrendado</div>
                        </span>
                        <span *ngIf="subgrupo.tipo == 4" class="d-cell">
                            <span *ngIf="criterio.cumple_ECF == true">Si</span>
                            <span *ngIf="criterio.cumple_ECF == false">No</span>
                        </span>
                        <span class="text-muted d-cell">{{criterio.observacion}}</span>

                        <!-- <div *ngIf="criterio.descripcion_archivo != ''" style="display: inline; margin-right: 20px;">
                                    <span *ngIf="criterio.descripcion_archivo != ''">{{criterio.descripcion_archivo}}</span>
                                </div> -->
                        <span *ngIf="criterio.descripcion_archivo != ''"
                            class="text-muted d-cell">{{criterio.descripcion_archivo}}</span>
                        <!-- </span> -->
                        <div class="criterio_options d-cell">
                            <ng-container *ngIf="objLicitacion?.ETAPA_CORRELATIVO == 6">
                                <app-view-file-buttons [nombreArchivo]="criterio.documento"></app-view-file-buttons>
                                <a class="btn rounded-icon f-right mr-2"
                                    (click)="removeCriterio(i,y,x,criterio.correlativo)" title="Borrar">
                                    <i class="fas fa-trash"></i>
                                </a>
                                <a class="btn rounded-icon f-right mr-2" href="#modalCriterio" data-toggle="modal"
                                    (click)="LoadUpdateCriterioForm(i,y,x,criterio.correlativo);" title="Modificar">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </ng-container>
                        </div>

                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- end row -->
    <div>

    </div>
</div>
<div class="modal" id="modalCriterio" *ngIf="modalCriterio">
    <div class="modal-dialog">
        <div class="modal-content p-4">
            <div class="modal-body">
                <!-- <div>
                    {{this.objGrupos[indexGrupo].subgrupoLs[indexSubgrupo].tipo}} ({{indexGrupo}} {{indexSubgrupo}})
                </div> -->
                <form [formGroup]="formCriterio">
                    <div *ngIf="this.objGrupos[indexGrupo].subgrupoLs[indexSubgrupo].tipo == 1">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Descripción <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="descripcion"
                                    formControlName="descripcion">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Observación</label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <textarea rows="3" class="form-control" type="text" placeholder="" id="observacion"
                                    formControlName="observacion"></textarea>
                            </div>
                        </div>
                        <div class="row m-b-15">
                            <label class="col-form-label col-md-12">Subir documento <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input (change)="onFileChange($event)" class="" type="file" placeholder="" id="file"
                                    formControlName="file">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.objGrupos[indexGrupo].subgrupoLs[indexSubgrupo].tipo == 2">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Descripción <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="descripcion"
                                    formControlName="descripcion">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Cantidad <span class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="number" placeholder="" id="cantidad"
                                    formControlName="cantidad">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Turno <span class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <select class="default-select2 form-control" id="solpe" name="solpe" id="turno"
                                    formControlName="turno">
                                    <option value="0">Seleccione ...</option>
                                    <option value="1">Diurno</option>
                                    <option value="2">Nocturno</option>
                                </select>
                                <!-- <input class="form-control" type="text" placeholder="" > -->
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Observación</label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <textarea rows="3" class="form-control" type="text" placeholder="" id="observacion"
                                    formControlName="observacion"></textarea>
                            </div>
                        </div>
                        <div class="row m-b-15">
                            <label class="col-form-label col-md-12">Subir documento <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input (change)="onFileChange($event)" class="" type="file" placeholder="" id="file"
                                    formControlName="file">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.objGrupos[indexGrupo].subgrupoLs[indexSubgrupo].tipo == 3">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Descripción <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="descripcion"
                                    formControlName="descripcion">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Nombre y apellido <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="nombre_apellido"
                                    formControlName="nombre_apellido">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Perfil del proponente <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="perfil"
                                    formControlName="perfil">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Observación</label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <textarea rows="3" class="form-control" type="text" placeholder="" id="observacion"
                                    formControlName="observacion"></textarea>
                            </div>
                        </div>
                        <div class="row m-b-15">
                            <label class="col-form-label col-md-12">Subir documento <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input (change)="onFileChange($event)" class="" type="file" placeholder="" id="file"
                                    formControlName="file">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.objGrupos[indexGrupo].subgrupoLs[indexSubgrupo].tipo == 4">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Descripción <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="descripcion"
                                    formControlName="descripcion">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Marca <span class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="marca"
                                    formControlName="marca">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Modelo <span class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="text" placeholder="" id="modelo"
                                    formControlName="modelo">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Año <span class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input class="form-control" type="number" placeholder="" id="ano" formControlName="ano">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Propio / Arrendado <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <select class="default-select2 form-control" id="propiedad" formControlName="propiedad">
                                    <option value="0">Seleccione ...</option>
                                    <option value="1">Propio</option>
                                    <option value="2">Arrendado</option>
                                </select>
                                <!-- <input class="form-control" type="text" placeholder="" > -->
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <!-- <label class="col-form-label col-md-12">Cumple ECF <span class="text-danger">*</span></label>
                            <div class="col-md-12"> -->
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                            <!--  <input class="form-control" type="text" placeholder="" >
                            </div> -->
                            <div class="col-md-12 text-right">
                                <input class="form-check-input" type="checkbox" value="true" id="cumpleecf"
                                    formControlName="cumpleecf">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Cumple ECF
                                </label>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-12">Observación</label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <textarea rows="3" class="form-control" type="text" placeholder="" id="observacion"
                                    formControlName="observacion"></textarea>
                            </div>
                        </div>
                        <div class="row m-b-15">
                            <label class="col-form-label col-md-12">Subir documento <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-12">
                                <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                    <option value="0">Seleccione ...</option>
                                </select>-->
                                <input (change)="onFileChange($event)" class="" type="file" placeholder="" id="file"
                                    formControlName="file">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <button *ngIf="!isUpdateCriterio" class="btn btn-primary" (click)="postCriterio()"
                                data-dismiss="modal">Agregar</button>
                            <button *ngIf="isUpdateCriterio" class="btn btn-primary" (click)="updateCriterios()"
                                data-dismiss="modal">Modificar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>