<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i
            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i>
        Volver</button>
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3>{{licitacionData?.CONT_NOMBRE}}</h3>
                                </div>
                                <div class="col-12">
                                    <span class="text-muted">
                                        {{licitacionData?.DESCRIPCION}}
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="mr-3 ml-3">
                                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                            class="text-muted">Licitación: </span> {{idLicitacion}}</span>
                                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                            class="text-muted">Moneda: </span>
                                        {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                            class="text-muted">Fecha: </span> {{licitacionData?.FECHA_CREACION}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="oferenteDataEstatus?.ADJUDICADO === 1">
            <div class="col-12">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="panel-body rounded p3-rem">
                        <ng-container *ngIf="isCartaAdjudicacion">
                            <div class="row">
                                <div class="col-12">
                                    <p *ngIf="isCartaEnviada == false" class="text-muted"><strong>IMPORTANTE: Por favor
                                            descargue la carta de adjudicación
                                            que se le
                                            ha enviado y responda la solicitud con la carta firmada.</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <h4 class="text-muted">Carta de adjudicación</h4>
                                </div>
                                <div class="col-6 text-right">
                                    <button *ngIf="isCartaEnviada == false" class="btn btn-primary" data-toggle="modal"
                                        data-target="#cargtaAdjudicarModal"><i class="fas fa-plus mr-2"></i> Responder
                                        carta</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-hover">
                                        <thead class="thead-darkk">
                                            <th>Carta Recibida</th>
                                            <th></th>
                                            <th>Fecha Recepción</th>
                                            <th>Carta Enviada</th>
                                            <th></th>
                                            <th>Fecha Envio</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <ng-container>
                                                    <td>
                                                        {{ cartasAdjudicacion?.descripcion_archivo }}
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="row text-right">
                                                            <app-view-file-buttons
                                                                [nombreArchivo]="cartasAdjudicacion?.nombre_archivo"></app-view-file-buttons>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{ cartasAdjudicacion?.fecha_creacion | date: 'dd/MM/yyyy'}}
                                                    </td>
                                                    <td>
                                                        {{ cartasAdjudicacion?.descripcion_archivo_respuesta_oferente }}
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="row text-right">
                                                            <app-view-file-buttons
                                                                [nombreArchivo]="cartasAdjudicacion?.nombre_archivo_respuesta_oferente"></app-view-file-buttons>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{ cartasAdjudicacion?.fecha_recepcion_respuesta | date:
                                                        'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isCartaAdjudicacion === false">
                            <h4 class="text-muted text-center">El resultado de la licitación aún está en evaluación, se le
                                notificara cuando este disponible.</h4>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="oferenteDataEstatus?.ADJUDICADO === 0">
            <div class="col-12">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="panel-body rounded p3-rem">
                        <ng-container *ngIf="isCartaAgradecimiento">
                            <div class="row">
                                <div class="col-6">
                                    <h4 class="text-muted">Carta de Agradecimiento</h4>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-hover">
                                        <thead class="thead-darkk">
                                            <th>Carta Recibida</th>
                                            <th></th>
                                            <th>Fecha Recepción</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <ng-container
                                                    *ngIf="cartasAgradecimientoOferente?.nombre_archivo != null">
                                                    <td>
                                                        {{ cartasAgradecimientoOferente?.descripcion_archivo }}
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="row text-right">
                                                            <app-view-file-buttons
                                                                [nombreArchivo]="cartasAgradecimientoOferente?.nombre_archivo"></app-view-file-buttons>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{ cartasAgradecimientoOferente?.fecha_creacion | date:
                                                        'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isCartaAgradecimiento === false">
                            <h4 class="text-muted text-center">El proceso de adjudicación no ha sido completado, se le
                                notificara cuando este disponible.</h4>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<!-- Modal cargar carta de adjudicacion -->
<div class="modal fade" id="cargtaAdjudicarModal" tabindex="-1" role="dialog"
    aria-labelledby="cargtaAdjudicarModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cargtaAdjudicarModalLabel">Adjudicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModalAdjudicarCarta>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-muted">Por favor ingrese la carta de adjudicación firmada.</p>
                <form [formGroup]="formDocCartaAdjudicacion" (ngSubmit)="enviarCartaAdjudicacion()">
                    <div class="row form-group">
                        <label for="docSelected" class="col-md-3 col-form-label">Descripción <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="descripcionCartaAdjudicacion">
                        </div>
                    </div>
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                                type="file" (change)="detectFiles($event)" />
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary"
                                [disabled]="formDocCartaAdjudicacion.invalid">Responder Carta</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>