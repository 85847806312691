<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Mantenedor Motivos de Renuncia a Licitación</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-crearMotivoRenuncia" data-toggle="modal" class="btn btn-primary"
                                        (click)="resetFormCreateMotivoRenuncia()">Agregar Motivo</a>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" (keyup)="applyFilterMotivoRenuncia($event)"
                                        placeholder="Buscar ..." class="form-control"
                                        style="margin-bottom: 10px; padding-right: 2rem !important;">
                                    <i matsuffix="" class="fas fa-search"
                                        style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Descripción</th>
                                    <th>Tipo</th>
                                    <th>Vigente</th>
                                    <th>Fecha Creación</th>
                                    <th class="text-left">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of motivoRenunciaFiltered">
                                        <ng-container>
                                            <td>
                                                {{ item.descripcion }}
                                            </td>
                                            <td>
                                                {{ item.tipo }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente === 1">
                                                    Vigente
                                                </span>
                                                <span class="tab tab-red" *ngIf="item.vigente === 0">
                                                    Inactivo
                                                </span>
                                            </td>
                                            <td>
                                                {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                            </td>
                                            <td class="text-right">
                                                <div class="row text-right">
                                                    <a href="#modal-updateMotivoRenuncia" title="Editar Motivo"
                                                        class="btn rounded-icon mr-2"
                                                        (click)="editarMotivoRenuncia(item.correlativo)"
                                                        data-toggle="modal">
                                                        <i class="fa fa-edit"></i>
                                                    </a>
                                                    <a href="#modal-eliminarMotivoRenuncia" title="Eliminar Motivo"
                                                        class="btn rounded-icon mr-2"
                                                        (click)="eliminarMotivoRenuncia(item.correlativo)"
                                                        data-toggle="modal">
                                                        <i class="fa fa-trash-alt"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal eliminar ayuda criterio -->
<div class="modal fade" id="modal-eliminarMotivoRenuncia" tabindex="-1" role="dialog"
    aria-labelledby="modal-eliminarMotivoRenuncia" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-eliminarMotivoRenuncia">Eliminar Motivo de Renuncia</h5>
                <button type="button" class="close" data-dismiss="modal" #closeModalEliminarMotivoRenuncia
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Desea eliminar el siguiente motivo?</p>
                <span class="font-weight-bold">{{ motivoSeleccionadoDescripcion }}</span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary"
                    (click)="onEliminarMotivoRenunciaCriterio()">Eliminar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal agregar ayuda criterio -->
<div class="modal fade" id="modal-crearMotivoRenuncia" tabindex="-1" role="dialog"
    aria-labelledby="modal-crearMotivoRenuncia" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-crearMotivoRenuncia">Crear Motivo Renuncia Licitación</h5>
                <button type="button" class="close" data-dismiss="modal" #closeModalCrearMotivoRenuncia
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formCreateMotivoRenuncia" (ngSubmit)="onCrearMotivoRenuncia()" novalidate>
                    <div class="row form-group">
                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="descripcion" #descripcion />
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Tipo:</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="tipo" #tipo>
                                <option value="EMSA">EMSA</option>
                                <option value="OFERENTE">OFERENTE</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal editar ayuda criterio -->
<div class="modal fade" id="modal-updateMotivoRenuncia" tabindex="-1" role="dialog"
    aria-labelledby="modal-updateMotivoRenuncia" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modal-updateMotivoRenuncia">Editar Motivo Renuncia</h5>
                <button type="button" class="close" data-dismiss="modal" #closeModalUpdateMotivoRenuncia
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formUpdateMotivoRenuncia" (ngSubmit)="onUpdateMotivoRenuncia()" novalidate>
                    <div class="row form-group">
                        <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="descripcion" #descripcion />
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">Tipo:</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="tipo" #tipo>
                                <option value="EMSA">EMSA</option>
                                <option value="OFERENTE">OFERENTE</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="descripcion" class="col-md-3 col-form-label">Vigente</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="vigente" #vigente>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>