import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FileService } from 'src/app/shared/services/file.service';
import { FormGroup, FormControl } from '@angular/forms';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-view-acla-eco-readonly',
  templateUrl: './view-acla-eco-readonly.component.html',
  styleUrls: ['./view-acla-eco-readonly.component.css']
})
export class ViewAclaEcoReadonlyComponent implements OnInit {
  idLicitacion:number;
  idEtapa:number;
  idEtapaLic:number;
  nombreEtapa:string;
  filename: string;
  aclaracionesEco:[];

  constructor(private route: ActivatedRoute,private etapaService: EtapasService, private licitacionService: LicitacionService, private notifyService : NotificationService, private fileService: FileService) { }

  ngOnInit(): void {

    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }
        this.getAclaraciones(this.idLicitacion, this.idEtapa);
    });
  }

  private getAclaraciones(idLic: number, idEtapa: number){
    this.licitacionService.getAclaracionLic(idLic, idEtapa).subscribe(resp=>{
      this.aclaracionesEco = JSON.parse(resp);
    });
  }

  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }


}
