<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Mantenedor Documentos Administrativos y Formularios ECO - TEC</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-crearDocRequeridoOferente" data-toggle="modal" class="btn btn-primary" (click)="resetFormCreateDocRequeridoOferente()">Agregar Tipo Documentos</a>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterDocRequeridoOferente($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Descripción</th>
                                    <th>Tipo</th>
                                    <th>Vigente</th>
                                    <th>Fecha Creación</th>
                                    <th class="text-left">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of documentosRequeridosOferenteFiltered">
                                        <ng-container>
                                            <td>
                                                {{ item.descripcion }}
                                            </td>
                                            <td>
                                                {{ item.tipo_doc }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente === 1">
                                                    Vigente
                                                </span>
                                                <span class="tab tab-red" *ngIf="item.vigente === 0">
                                                    Inactivo
                                                </span>
                                            </td>
                                            <td>
                                                {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                            </td>
                                            <td class="text-right">
                                                <div class="row text-right">
                                                    <a 
                                                        href="#modal-updateDocRequeridoOferente"
                                                        title="Editar Motivo" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="editarDocRequeridoOferente(item.correlativo)"
                                                        data-toggle="modal"
                                                    >
                                                        <i class="fa fa-edit"></i>
                                                    </a>
                                                    <a 
                                                        href="#modal-eliminarDocRequeridoOferente"
                                                        title="Eliminar Motivo" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="eliminarDocRequeridoOferente(item.correlativo)"
                                                        data-toggle="modal"
                                                    >
                                                        <i class="fa fa-trash-alt"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal eliminar documento requerido -->
<div class="modal fade" id="modal-eliminarDocRequeridoOferente" tabindex="-1" role="dialog" aria-labelledby="modal-eliminarDocRequeridoOferente" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-eliminarDocRequeridoOferente">Eliminar Documento Administrativo</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalEliminarDocRequeridoOferente aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea eliminar el siguiente Documento Administrativo?</p> 
          <span class="font-weight-bold">{{ docRequeridoSeleccionadoDescripcion }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onEliminarDocRequeridoOferente()">Eliminar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal agregar ayuda criterio -->
<div class="modal fade" id="modal-crearDocRequeridoOferente" tabindex="-1" role="dialog" aria-labelledby="modal-crearDocRequeridoOferente" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-crearDocRequeridoOferente">Crear Documento Requerido Oferente</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalCrearDocRequeridoOferente aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formCreateDocRequeridoOferente" (ngSubmit)="onCrearDocRequeridoOferente()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="descripcion" #descripcion />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="tipo" class="col-md-3 col-form-label">Tipo:</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="tipo" #tipo>
                            <ng-container *ngFor="let item of tiposDocumentos">
                                <option value="{{item.correlativo}}">{{ item.descripcion }}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modal editar ayuda criterio -->
<div class="modal fade" id="modal-updateDocRequeridoOferente" tabindex="-1" role="dialog" aria-labelledby="modal-updateDocRequeridoOferente" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-updateDocRequeridoOferente">Editar Doc. Administrativo: {{ docRequeridoSeleccionadoDescripcion }}</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalUpdateDocRequeridoOferente aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formUpdateDocRequeridoOferente" (ngSubmit)="onUpdateDocRequeridoOferente()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="descripcion" #descripcion />
                    </div>
                </div>
                <div class="row form-group">
                    <label for="tipo" class="col-md-3 col-form-label">Tipo:</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="tipo" #tipo>
                            <ng-container *ngFor="let item of tiposDocumentos">
                                <option value="{{item.correlativo}}">{{ item.descripcion }}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Vigente</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="vigente" #vigente>	
                            <option value="1">Si</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>