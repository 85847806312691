import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { FileService } from "src/app/shared/services/file.service";
import { Location } from "@angular/common";
declare var require: any;
const FileSaver = require("file-saver");
@Component({
  selector: "app-view-respuesta-economica",
  templateUrl: "./view-respuesta-economica.component.html",
  styleUrls: ["./view-respuesta-economica.component.css"],
})
export class ViewRespuestaEconomicaComponent implements OnInit {
  idLicitacion: number;
  idParticipante: number;
  idEtapa: number;
  idEtapaLic: number;
  nombreEtapa: string;
  aclaracionEco: [];
  archivos64: any[] = [];
  showBtn: boolean = false;

  @ViewChild("inputFile")
  inputFile: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private etapaService: EtapasService,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private fileService: FileService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.licitacionService.getDatosPart().subscribe((resp) => {
        this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
        if (this.idParticipante) {
          this.getAclaraciones(
            this.idLicitacion,
            this.idParticipante,
            this.idEtapa
          );
        }
      });
      if (this.idEtapa) {
        this.etapaService.getEtapaId(this.idEtapa).subscribe((resp) => {
          if (resp) {
            this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
          }
        });
      }
    });
  }

  detectFiles(event: any, idF: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64.map((item, i) => {
            if (item.correlativo == idF) {
              this.archivos64[i].fileName = event.target.files[0].name;
              this.archivos64[i].type = event.target.files[0].name.slice(
                ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
              );
              this.archivos64[i].path = bs64;
            }
          });
        }
      };
    }
  }

  private getAclaraciones(idLic: number, idPart: number, idEtapa: number) {
    this.licitacionService.getAclaracionPart(idLic, idPart, idEtapa).subscribe(
      (resp) => {
        if (JSON.parse(resp)) {
          this.showBtn = JSON.parse(resp).length === 0 ? false : true;
          this.aclaracionEco = JSON.parse(resp);
          this.aclaracionEco.forEach((element) => {
            let correlativo = JSON.parse(JSON.stringify(element)).CORRELATIVO;
            this.archivos64.push({ correlativo: correlativo, path: null });
          });
        }
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Aclaración economica");
      }
    );
  }
  onDescargar(fileName: string) {
    this.fileService.downloadFile(fileName).subscribe(
      (resp) => {
        FileSaver.saveAs(resp, fileName);
        this.notifyService.showSuccess("Descargando ...", "Descargar archivos");
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Descargar archivos");
      }
    );
  }
  onGuardarRespuesta() {
    let idArchivo: number;
    if (this.archivos64) {
      this.archivos64.forEach((element) => {
        let elem = JSON.parse(JSON.stringify(element));
        if (elem.fileName) {
          const postData: {
            in_det_etapa_lic: number;
            files: Blob;
            fileName: string;
            type: string;
            in_usuario: string;
            in_part_correlativo?: number;
            in_domi_correlativo_tiar?: number;
            publico: number;
            descripcion_input_archivo: string;
            idLicitacion: number;
            idEtapaLicitacion: number;
            correlativoGrupoArchivo: number;
          } = {
            in_det_etapa_lic: this.idEtapaLic,
            files: elem.path,
            fileName: elem.fileName,
            type: elem.type,
            in_usuario: "",
            in_part_correlativo: this.idParticipante,
            in_domi_correlativo_tiar: 27,
            publico: 1,
            descripcion_input_archivo:
              "Archivo de Respuesta de Aclaración Económica",
            idLicitacion: this.idLicitacion,
            idEtapaLicitacion: this.idEtapa,
            correlativoGrupoArchivo: 0,
          };
          this.licitacionService
            .postArchivosLicitacion(postData)
            .subscribe((resp) => {
              idArchivo = JSON.parse(resp)[0].SEQ_ARCHDETETLIC;
              const putData: {
                in_acdetlic_acl_correlativo: number;
                in_archetlic_acl_correlativo: number;
                in_archetlic_resp_correlativo: number;
                in_usuario: string;
                in_accion: string;
                idLicitacion: number;
                oferenteNombre: string;
              } = {
                in_acdetlic_acl_correlativo: elem.correlativo,
                in_archetlic_acl_correlativo: null,
                in_archetlic_resp_correlativo: idArchivo,
                in_usuario: "",
                in_accion: "RESPUESTA",
                idLicitacion: this.idLicitacion,
                oferenteNombre: JSON.parse(localStorage.getItem("userData")).userName
              };
              this.licitacionService.putAclaracionArchivo(putData).subscribe(
                (resp) => {
                  this.notifyService.showSuccess(
                    "Respuesta enviada correctamente",
                    "Envio de respuesta aclaracion"
                  );
                  this.getAclaraciones(
                    this.idLicitacion,
                    this.idParticipante,
                    this.idEtapa
                  );
                },
                (err) => {
                  this.notifyService.showError(
                    err.error.message,
                    "Archivo Aclaración economica"
                  );
                }
              );
            });
        }
      });

      this.archivos64 = null;
      this.inputFile.nativeElement.value = "";
    }
  }

  back(): void {
    this.location.back();
  }
}
