<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i
            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i>
        Volver</button>
    <ng-container *ngIf="!validateRoleParticipanteComision(37)">
        <div class="row">
            <div class="col-xl-12">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="panel-body rounded p3-rem">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h4 class="text-muted text-center">Vista única del Responsable del proceso.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </ng-container>
    <ng-container *ngIf="validateRoleParticipanteComision(37)">
        <div class="row">
            <div class="col-xl-12">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="panel-body rounded p3-rem">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h3 class="">{{licitacionData?.CONT_NOMBRE}}</h3>
                            </div>
                            <div class="col-12">
                                <span class="text-muted">
                                    {{licitacionData?.DESCRIPCION}}
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="mr-3 ml-3">
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                        class="text-muted">Licitación: </span> <strong>{{idLicitacion}}</strong></span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Solpe:
                                    </span> <strong>{{licitacionData?.SOLPE_CORRELATIVO}}</strong></span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Monto:
                                    </span> {{licitacionData?.MONTO_SOLPE | number: '1.2'}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                        class="text-muted">Moneda:
                                    </span> {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                        class="text-muted">Contrato:
                                    </span> {{licitacionData?.CONT_NUMERO}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Fecha:
                                    </span> {{licitacionData?.FECHA_CREACION}}</span>
                            </div>
                        </div>
                        <br>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
            <div class="panel-body rounded p3-rem">
                <div class="row">
                    <div class="col-6">
                        <h4 class="text-muted">Documentos de adjudicación MAF</h4>
                    </div>
                    <div class="col-6 text-right">
                        <button *ngIf="licitacionData?.LICITACION_FINALIZADA != 1" class="btn btn-primary"
                            data-toggle="modal" data-target="#documentoAdjudicarMafModal"><i
                                class="fas fa-plus mr-2"></i> Cargar Documento
                        </button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-hover">
                            <thead class="thead-darkk">
                                <th>Descripción</th>
                                <th>Usuario</th>
                                <th>Fecha Creación</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of documentosAdjudicacionMaf">
                                    <ng-container>
                                        <td>
                                            {{ item.descripcion_archivo }}
                                        </td>
                                        <td>
                                            {{ item.nombre_usuario_creacion }}
                                        </td>
                                        <td>
                                            {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td class="text-right">
                                            <div class="row text-right">
                                                <app-view-file-buttons
                                                    [nombreArchivo]="item.nombre_archivo"></app-view-file-buttons>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="documentosAdjudicacionMaf.length > 0">
            <ng-container *ngIf="isOferenteAdjudicado === false">
                <div class="row">
                    <ng-container *ngFor="let parti of objParticipantes">
                        <div *ngIf="parti.vigente == 'S'" class="col-12 col-md-6 mb-4">
                            <div class="p-30 bg-white rounded">
                                <div class="row border-bottom pt-0 pb-2">
                                    <div class="col-6">
                                        <h4 class="text-muted">Recomendación de Adjudicación</h4>
                                    </div>
                                    <div class="col-6 text-right">
                                        <ng-container *ngIf="parti.vigente == 'S'">
                                            <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                                <ng-container *ngIf="parti.APROBO_EVAL_ECO == 1">
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="adjudicarParticipante(parti.PART_CORRELATIVO, parti.RAZON_SOCIAL)"
                                                        data-toggle="modal" data-target="#adjudicarModal">
                                                        Recomendar Oferente
                                                    </button>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="m-0">Evaluación Técnica:</p>
                                    </div>
                                    <div class="col-6 text-right">
                                        <div *ngIf="parti.APROBO_EVAL_TEC == 1">
                                            <span class="tab tab-green mr-2">Aprobada</span>
                                            <a routerLink="/resultados/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"
                                                class="btn rounded-icon" title="Ver Evaluación Técnica">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </div>
                                        <span *ngIf="parti.APROBO_EVAL_TEC == 0" class="tab tab-red">No
                                            aprobada</span>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="m-0">Evaluación Económica:</p>
                                    </div>
                                    <div class="col-6 text-right">
                                        <div *ngIf="parti.APROBO_EVAL_ECO == 1">
                                            <span class="tab tab-green mr-2">Aprobada</span>
                                            <a routerLink="/resultados-economicos/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"
                                                class="btn rounded-icon" title="Ver Evaluación Económica">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </div>
                                        <span *ngIf="parti.APROBO_EVAL_ECO == 0" class="tab tab-red">No
                                            aprobada</span>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="m-0">Adjudicación:</p>
                                    </div>
                                    <div class="col-6 text-right">
                                        <span *ngIf="parti.ADJUDICADO == 1" class="tab tab-green">Adjudicado</span>
                                        <span *ngIf="parti.ADJUDICADO == 0" class="tab tab-red">No
                                            Adjudicado</span>
                                    </div>
                                </div>
                                <hr>
                                <div class="row pb-0">
                                    <h4 class="col-12 mb-0">
                                        {{parti.RAZON_SOCIAL}}
                                    </h4>
                                    <span class="col-12 text-muted">
                                        RUT: {{parti.RUT}}
                                    </span>
                                </div>
                                <div class="row  mb-4 mt-4 ">
                                    <div class="col-12">
                                        <div class="rounded border p-10">
                                            <div class="row">
                                                <div class="col-6">
                                                    <p class="text-muted mb-0">Encargado</p>
                                                    <span>{{parti.ENCARGADO}}</span>
                                                </div>
                                                <div class="col-6">
                                                    <p class="text-muted mb-0">Correo</p>
                                                    <span>{{parti.CORREO_ELECTRONICO}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="licitacionData?.ORDEN >= 13">
                                    <div class="row">
                                        <div class="col-12">
                                            <h5>Documentos / presupuesto</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <app-view-etapa-files-table *ngIf="archivos && archivos.length > 0"
                                                [archivos]="filesPart(parti.PART_CORRELATIVO)"
                                                [nombreEtapa]="'Recepción de Oferta Económica'"></app-view-etapa-files-table>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="isOferenteAdjudicado === true">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="panel-body rounded p3-rem">
                        <div class="row">
                            <div class="col-6">
                                <h4 class="text-muted">Cartas de adjudicación</h4>
                            </div>
                            <div class="col-6 text-right">
                                <button *ngIf="licitacionData?.LICITACION_FINALIZADA != 1" class="btn btn-primary"
                                    data-toggle="modal" data-target="#cartaAdjudicarModal"><i
                                        class="fas fa-plus mr-2"></i> Carta
                                    adjudicación
                                </button>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Oferente</th>
                                        <th>Carta Enviada</th>
                                        <th>Usuario</th>
                                        <th></th>
                                        <th>Fecha Creación</th>
                                        <th>Carta Recibida</th>
                                        <th></th>
                                        <th>Fecha Recepción</th>
                                        <th>Solpe</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of cartasAdjudicacion">
                                            <ng-container>
                                                <td class="bold">
                                                    {{ item.razon_social }}
                                                </td>
                                                <td>
                                                    {{ item.descripcion_archivo }}
                                                </td>
                                                <td>
                                                    {{ item.nombre_usuario_creacion }}
                                                </td>
                                                <td class="text-right">
                                                    <div class="row text-right">
                                                        <app-view-file-buttons
                                                            [nombreArchivo]="item?.nombre_archivo"></app-view-file-buttons>
                                                        <div *ngIf="item?.nombre_archivo_respuesta_oferente == null && licitacionData?.LICITACION_FINALIZADA != 1"
                                                            title="Enviar Carta de Adjudicación"
                                                            class="btn rounded-icon mr-2"
                                                            (click)="onCartaAdjudicacion(item.correlativo, item.razon_social)"
                                                            data-toggle="modal"
                                                            data-target="#updateEstadoCartaAdjudicarModal">
                                                            <i class="fa fa-paper-plane"></i>
                                                        </div>
                                                        <!-- (click)="onUpdateEstadoCartaAdjudicacion(item.correlativo, item.razon_social)"
                                                            data-toggle="modal"
                                                            data-target="#enviarCartaAdjudicacionSolpeModal" -->
                                                        <div *ngIf="item?.nombre_archivo_respuesta_oferente == null && licitacionData?.LICITACION_FINALIZADA != 1"
                                                            title="Eliminar carta de adjudicación"
                                                            class="btn rounded-icon mr-2"
                                                            (click)="onCartaAdjudicacion(item.correlativo, item.razon_social)"
                                                            data-toggle="modal"
                                                            data-target="#deleteCartaAdjudicarModal">
                                                            <i class="fa fa-trash-alt"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                                </td>
                                                <td>
                                                    {{ item?.descripcion_archivo_respuesta_oferente }}
                                                </td>
                                                <td class="text-right">
                                                    <div class="row text-right">
                                                        <app-view-file-buttons
                                                            [nombreArchivo]="item?.nombre_archivo_respuesta_oferente"></app-view-file-buttons>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{ item?.fecha_recepcion_respuesta | date: 'dd/MM/yyyy'}}
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="item?.asociada_solpe === 0">
                                                        <button class="btn btn-primary"
                                                            *ngIf="item?.nombre_archivo_respuesta_oferente"
                                                            (click)="cartaAdjudicacionSolpeContrato(item?.nombre_archivo_respuesta_oferente, item?.descripcion_archivo_respuesta_oferente, item?.correlativo)"
                                                            data-toggle="modal"
                                                            data-target="#enviarCartaAdjudicacionSolpeModal">
                                                            Enviar a la SOLPE
                                                        </button>
                                                    </ng-container>
                                                    <ng-container *ngIf="item?.asociada_solpe === 1">
                                                        <span class="tab tab-green">
                                                            Relacionada
                                                        </span>
                                                    </ng-container>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container
                *ngIf="isOferenteAdjudicado === true && cartasAdjudicacion[0]?.nombre_archivo_respuesta_oferente != null">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <div class="panel-body rounded p3-rem">
                        <div class="row">
                            <div class="col-6">
                                <h4 class="text-muted">Cartas de Agradecimiento</h4>
                            </div>
                            <div class="col-6 text-right">
                                <!-- data-toggle="modal"
                                    data-target="#cartaAgradecimientoModal" -->
                                <button
                                    *ngIf="licitacionData?.LICITACION_FINALIZADA != 1 && cartasAgradecimiento.length <= 0"
                                    class="btn btn-primary" (click)="generarCartasAgradecimiento()"><i
                                        class="fas fa-plus mr-2"></i> Cargar
                                    oferentes
                                </button>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Oferente</th>
                                        <th>Carta Enviada</th>
                                        <th>Usuario</th>
                                        <th>Fecha Creación</th>
                                        <th>Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of cartasAgradecimiento">
                                            <ng-container>
                                                <td class="bold">
                                                    {{ item.razon_social }}
                                                </td>
                                                <td>
                                                    {{ item.descripcion_archivo }}
                                                </td>
                                                <td>
                                                    {{ item.nombre_usuario_creacion }}
                                                </td>
                                                <td>
                                                    {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="licitacionData?.LICITACION_FINALIZADA != 1">
                                                        <button *ngIf="item.nombre_archivo === null"
                                                            class="btn btn-primary"
                                                            (click)="handleCartaAgradecimientoOferente(item.correlativo, item.correlativo_oferente)"
                                                            data-toggle="modal" data-target="#cartaAgradecimientoModal">
                                                            Agregar
                                                        </button>
                                                    </ng-container>
                                                    <div class="row text-right">
                                                        <app-view-file-buttons
                                                            [nombreArchivo]="item?.nombre_archivo"></app-view-file-buttons>
                                                            <!-- terminar esta seccion con el modal y demas -->
                                                        <div *ngIf="item?.nombre_archivo != null && item?.visible == 0 && licitacionData?.LICITACION_FINALIZADA != 1"
                                                            title="Enviar carta de Agradecimiento"
                                                            class="btn rounded-icon mr-2"
                                                            (click)="onCartaAgradecimiento(item.correlativo, item.correlativo_oferente, item.razon_social)"
                                                            data-toggle="modal"
                                                            data-target="#updateEstadoCartaAgradecimientoModal">
                                                            <i class="fa fa-paper-plane"></i>
                                                        </div>
                                                        <div *ngIf="item?.nombre_archivo != null && licitacionData?.LICITACION_FINALIZADA != 1"
                                                            title="Eliminar carta de Agradecimiento"
                                                            class="btn rounded-icon mr-2"
                                                            (click)="onCartaAgradecimiento(item.correlativo, item.correlativo_oferente, item.razon_social)"
                                                            data-toggle="modal"
                                                            data-target="#deleteCartaAgradecimientoModal">
                                                            <i class="fa fa-trash-alt"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<!-- Modal adjujdicar oferente -->
<div class="modal fade" id="adjudicarModal" tabindex="-1" role="dialog" aria-labelledby="adjudicarModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="adjudicarModalLabel">Adjudicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModalAdjudicar>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Una vez sea adjudicado el oferente no podrá ser modificado.</p>
                <p>¿Desea adjudicar al oferente <strong>{{participanteNombre}}</strong>?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="onAdjudicar()">Adjudcar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal cargar carta de adjudicacion -->
<div class="modal fade" id="cartaAdjudicarModal" tabindex="-1" role="dialog" aria-labelledby="cartaAdjudicarModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cartaAdjudicarModalLabel">Adjudicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModalAdjudicarCarta>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Por favor ingrese la carta de adjudicación para ser enviada al oferente</p>
                <form [formGroup]="formDocCartaAdjudicacion" (ngSubmit)="enviarCartaAdjudicacion()">
                    <div class="row form-group">
                        <label for="docSelected" class="col-md-3 col-form-label">Descripción <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="descripcionCartaAdjudicacion">
                        </div>
                    </div>
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                                type="file" (change)="detectFiles($event)" />
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary"
                                [disabled]="formDocCartaAdjudicacion.invalid">Subir Carta</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal cargar cartas de agradecimientos -->
<div class="modal fade" id="cartaAgradecimientoModal" tabindex="-1" role="dialog"
    aria-labelledby="cartaAgradecimientoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cartaAgradecimientoModalLabel">Agradecimientos</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalAgradecimentoCarta>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Por favor ingrese la carta de agradecimiento para ser enviada al oferente</p>
                <form [formGroup]="formDocCartaAgradecimiento" (ngSubmit)="enviarCartasAgradecimiento()">
                    <div class="row form-group">
                        <label for="docSelected" class="col-md-3 col-form-label">Descripción <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="descripcionCartaAgradecimiento">
                        </div>
                    </div>
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                                type="file" (change)="detectFiles($event)" />
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary"
                                [disabled]="formDocCartaAgradecimiento.invalid">Enviar Carta</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal cargar dicumentos de adjudicacion MAF -->
<div class="modal fade" id="documentoAdjudicarMafModal" tabindex="-1" role="dialog"
    aria-labelledby="documentoAdjudicarMafModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="documentoAdjudicarMafModalLabel">Adjudicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalAdjudicarDocumento>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Por favor ingrese documento de adjudicación según la validación MAF</p>
                <form [formGroup]="formDocAdjudicacionMaf" (ngSubmit)="cargarDocumentosAdjudicacionMaf()">
                    <div class="row form-group">
                        <label for="docSelected" class="col-md-3 col-form-label">Descripción <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="descripcionDocAdjudicacionMaf">
                        </div>
                    </div>
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-md-3 col-form-label">Seleccionar archivo <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                                type="file" (change)="detectFiles($event)" />
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary"
                                [disabled]="formDocAdjudicacionMaf.invalid">Cargar documento</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal enviar carta adjudicacion a la solpe -->
<div class="modal fade" id="enviarCartaAdjudicacionSolpeModal" tabindex="-1" role="dialog"
    aria-labelledby="documentoAdjudicarMafModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="enviarCartaAdjudicacionSolpeModalLabel">Enviar carta de adjudicación a la
                    SOLPE</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalEnviarCartaASolpe>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-muted">IMPORTANTE: Al enviar el documento a la SOLPE se <strong>finalizara</strong> y
                    <strong>cerrara</strong> el proceso de licitación.
                </p>
                <p>¿Desea enviar la carta de adjudicación firmada por el oferente a la SOLPE:
                    <strong>{{licitacionData?.SOLPE_CORRELATIVO}}</strong>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="postSaveCartaAdjudicacionSolpeContrato()"
                    data-dismiss="modal">Enviar
                    a la SOLPE</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal enviar carta de adjudicacion al oferente -->
<div class="modal fade" id="updateEstadoCartaAdjudicarModal" tabindex="-1" role="dialog"
    aria-labelledby="updateEstadoCartaAdjudicarModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="updateEstadoCartaAdjudicarModalLabel">Adjudicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalCartaAdjudicacion>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea enviar esta carta de adjudicación al oferente
                    <strong>{{participanteNombre}}</strong>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="updateEstadoCartaAdjudicacion()"
                    data-dismiss="modal">Enviar carta</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal eliminar carta de adjudicacion -->
<div class="modal fade" id="deleteCartaAdjudicarModal" tabindex="-1" role="dialog"
    aria-labelledby="deleteCartaAdjudicarModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteCartaAdjudicarModalLabel">Adjudicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalDeleteCartaAdjudicacion>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea eliminar esta carta de adjudicación para el oferente
                    <strong>{{participanteNombre}}</strong>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="deleteCartaAdjudicacion()"
                    data-dismiss="modal">Eliminar carta</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal enviar carta de agradecimiento al oferente -->
<div class="modal fade" id="updateEstadoCartaAgradecimientoModal" tabindex="-1" role="dialog"
    aria-labelledby="updateEstadoCartaAgradecimientoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="updateEstadoCartaAgradecimientoModalLabel">Agradecimiento</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalCartaAgradecimiento>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea enviar esta carta de agradecimiento al oferente
                    <strong>{{participanteNombre}}</strong>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="updateEstadoCartaAgradecimiento()"
                    data-dismiss="modal">Enviar carta</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal eliminar carta de agradecimiento -->
<div class="modal fade" id="deleteCartaAgradecimientoModal" tabindex="-1" role="dialog"
    aria-labelledby="deleteCartaAgradecimientoModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteCartaAgradecimientoModalLabel">Agradecimiento</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalDeleteCartaAgradecimiento>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea eliminar esta carta de agradecimiento para el oferente
                    <strong>{{participanteNombre}}</strong>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="deleteCartaAgradecimiento()"
                    data-dismiss="modal">Eliminar carta</button>
            </div>
        </div>
    </div>
</div>