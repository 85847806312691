import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as internal from "events";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export class IGrupo {
  correlativo: number;
  descripcion: string;
  licitacion_correlativo: number;
  peso: number;
  subgrupoLs: ISubGrupo[];
}
export class ISubGrupo {
  correlativo: number;
  descripcion: string;
  observacion: string;
  grupo_correlativo: number;
  peso: number;
  subgrupo_abierto: boolean;
  tipo: number;
  idAyuda: number;
  limiteRespuestasOferente: number;
  criteriosLs: ICriterios[];
}
export class ICriterios {
  correlativo: number;
  descripcion: string;
  observacion: string;
  grupo_correlativo: number;
  subgrupo_correlativo: number;
  calificacion: number;
  documento: string;
  correlativo_participante: number;

  cantidad: number;
  turno: number;

  nombre_apellido: string;
  perfil: string;

  marca: string;
  modelo: string;
  ano: number;
  tipo_propiedad: number;
  cumple_ECF: boolean;
  descripcion_archivo: string;
}
@Injectable({
  providedIn: "root",
})
export class CriteriosEvaluacionService {
  private header;
  private urlService: string = environment.apiUrl;
  constructor(private http: HttpClient) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }

  postGrupo(postData: IGrupo[]) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(this.urlService + "evaluacion/postGrupos", postData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  postSubgrupo(postData: ISubGrupo) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postSubgrupo",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postCriterio(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCriterio",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postCriterioForm(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCriterio",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getGrupos(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "evaluacion/getGrupos/" + idLicitacion,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getResultados(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "evaluacion/getResultados/" + idLicitacion,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getResultadosEvaluadores(
    idLicitacion: number,
    idParticipante: number,
    id_grupo: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService +
        "evaluacion/getResultadosEvaluadores/" +
        idLicitacion +
        "/" +
        idParticipante +
        "/" +
        id_grupo,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getGruposParticipante(idLicitacion: number, idParticipant: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService +
        "evaluacion/getGruposParticipante/" +
        idLicitacion +
        "/" +
        idParticipant,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getGruposParticipanteEvaluador(idLicitacion: number, idParticipant: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService +
        "evaluacion/getGruposParticipanteEvaluador/" +
        idLicitacion +
        "/" +
        idParticipant,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteGrupos(postData: IGrupo) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteGrupos",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postDeleteSubGrupos(postData: ISubGrupo) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteSubGrupos",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteCriterio(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteCriterio",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  /* postCriterio(postData : ICriterios){ 
    return this.http.post(this.urlService + 'evaluacion/postCriterio', postData, { 
      headers: {
          Authorization: "Bearer " + token,
        }
    });
  } */

  postCriterioCargo(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCriterioCargo",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postDeleteCriterioCargo(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteCriterio",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postCriterioPersonal(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCriterioPersonal",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteCriterioPersonal(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteCriterio",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postCriterioEquipo(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCriterioEquipo",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postDeleteCriterioEquipo(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteCriterio",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postCalificar(postData: ICriterios) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCalificar",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getAyudaEvalTecnica(idAyudaEvalTecnica: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "evaluacion/getAyudaEvalTecnica/" + idAyudaEvalTecnica,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getAyudaEvalTecnicaCriterios() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "evaluacion/getAyudaEvalTecnicaCriterios",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectEvaluacionCompletadaEvaluador(
    idOferente: number,
    idLicitacion: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}evaluacion/getSelectEvaluacionCompletadaEvaluador/${idLicitacion}/${idOferente}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          return resp["evaluacionCompletada"];
        })
      );
  }

  postCreateValidacionEvaluacionTecEvaluador(postData: {
    in_participante: number;
    in_licitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postCreateValidacionEvaluacionTecEvaluador",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteValidacionEvaluacionTecEvaluador(postData: {
    in_participante: number;
    in_licitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "evaluacion/postDeleteValidacionEvaluacionTecEvaluador",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectEvaluacionesCompletadas(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}evaluacion/getSelectEvaluacionesCompletadas/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnEvaluacionesCompletadas = [];
          for (const item in resp["returnEvaluacionesCompletadas"]) {
            returnEvaluacionesCompletadas.push({
              ...resp["returnEvaluacionesCompletadas"][item],
            });
          }
          return JSON.stringify(returnEvaluacionesCompletadas);
        })
      );
  }

  getSelectPresentacionOfertaTecnica(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}evaluacion/getSelectPresentacionOfertaTecnica/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnOfertasPresentadas = [];
          for (const item in resp["returnOfertasPresentadas"]) {
            returnOfertasPresentadas.push({
              ...resp["returnOfertasPresentadas"][item],
            });
          }
          return JSON.stringify(returnOfertasPresentadas);
        })
      );
  }
}
