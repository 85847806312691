import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Params } from '@angular/router';
import { EtapasService } from '../services/etapas.licitacion.service';
import { LicitacionService } from '../services/licitacion.service';
import { ExportExcelService } from '../services/export-excel.service';

@Component({
  selector: 'app-view-consulta-licitacion-readonly',
  templateUrl: './view-consulta-licitacion-readonly.component.html',
  styleUrls: ['./view-consulta-licitacion-readonly.component.css']
})
export class ViewConsultaLicitacionReadonlyComponent implements OnInit {
  idLicitacion:number;
  idEtapa:number;
  idEtapaLic:number;
  consultas_licitacion :[];
  consultas_participantes :[];
  modalRef: BsModalRef;
  dataForExcel = [];
  nombreEtapa: string;
  cant_pregunta: number = 0;
  constructor(private route: ActivatedRoute, private etapaService: EtapasService, private licitacionService: LicitacionService,private modalService: BsModalService, private excelService : ExportExcelService) { }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        this.licitacionService.getConsultasLic(this.idEtapaLic).subscribe(resp =>{
          this.consultas_licitacion = JSON.parse(resp);    
          if(this.consultas_licitacion.length){
            this.cant_pregunta = this.consultas_licitacion.length;
          }      
        });
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }
    });
  }

  onVerConsultas(idParticipante: number, template: TemplateRef<any>){
    this.licitacionService.getConsultasPartLic(this.idEtapaLic, idParticipante).subscribe(resp =>{
      this.consultas_participantes =  JSON.parse(resp);
      this.modalRef = this.modalService.show(template); 
    });
  }

  onExport(){
    this.dataForExcel = [];
    this.licitacionService.getConsultasLicXls(this.idEtapaLic).subscribe(resp=>{
      const dataXLS = JSON.parse(resp);
      if (dataXLS){
        dataXLS.forEach((row: any) => {
          this.dataForExcel.push(Object.values(row))
        })
        let reportData = {
          title: 'Consultas licitación #' + this.idLicitacion,
          data: this.dataForExcel,
          headers: Object.keys(dataXLS[0])
        }
        this.excelService.exportExcel(reportData);
      }
    });
  }

}
