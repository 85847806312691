<div class="content" style="padding-bottom: 0;">
    <div class="d-flex justify-content-between">
        <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
            routerLink="/licitaciones"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                style="color: white;"></i> Volver</button>
    </div>
</div>

<div id="printComponent">
    <!-- begin #content -->
    <div id="content" class="content">
        <!-- begin row -->
        <div class="row">
            <!-- begin col-6 -->
            <div class="col-xl-12">
                <!-- begin panel -->
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <!-- begin panel-body -->
                    <div class="bg-white rounded p3-rem">
                        <div class="row">
                            <div class="col-12 col-md-12 text-right">
                                <button *ngIf="licitacionData" type="button" class="btn tag" style="margin-bottom: 3px;"
                                    [disabled]="downloadingPdf" (click)="onDescargarPDF()">
                                    <i class="fas fa-lg fa-fw m-r-10 fa-file-pdf"></i>
                                    Descargar
                                </button>
                                <div *ngIf="licitacionData?.LICITACION_FINALIZADA != 1"
                                    class="dropdown d-flex-inline  ml-2">
                                    <button class="btn tag dropdown-toggle" type="button" id="dropdownMenu2"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Opciones
                                        <span class="caret ml-1"></span>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <!-- <li *ngIf="idEtapa > 5"><a style="cursor: pointer;"
                                                (click)="printPdf()">Descargar consultas</a></li> -->
                                        <li><a href="javascript:;" routerLink="/acta-inicial/{{idLicitacion}}">Acta de
                                                inicio</a>
                                        </li>
                                        <ng-container *ngIf="validateRoleParticipanteComision(37)">
                                            <!-- <li><a href="#modalParticipantes" data-target="#modalParticipantes"
                                                    data-toggle="modal">Editar participantes</a></li> -->
                                            <li><a href="javascript:;"
                                                    routerLink="/administrar-oferentes/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Editar
                                                    participantes</a></li>
                                            <!-- <li><a style="cursor: pointer;" data-toggle="modal"
                                                    data-target="#modalEditarComision">Editar comisión</a></li> -->
                                            <li><a href="javascript:;"
                                                    routerLink="/administrar-comision/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Editar
                                                    comisión</a></li>
                                            <li><a href="javascript:;"
                                                    routerLink="/edit-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Editar
                                                    cronograma</a></li>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="idRol == 15 && this.objLicitacion?.ETAPA_CORRELATIVO == 18">
                                            <li><a href="javascript:;"
                                                    routerLink="/administrar-oferentes/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Editar
                                                    participantes</a></li>
                                        </ng-container>
                                        <li><a href="javascript:;"
                                                routerLink="/ver-itemizado/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}/0">Presupuesto</a>
                                        </li>
                                        <li><a href="javascript:;"
                                                routerLink="/administrar-doc-req-oferente-lic/{{idLicitacion}}">Doc.
                                                Administrativos</a></li>
                                        <li><a href="javascript:;"
                                                routerLink="/observaciones-etapas/{{idLicitacion}}">Observaciones</a>
                                        </li>
                                        <li role="separator" class="divider"></li>
                                        <li><a href="javascript:;"
                                                routerLink="/edit-etapa-licitacion/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Agregar
                                                archivo</a></li>
                                        <li><a href="#modalEnviarCorre" data-target="#modalEnviarCorre"
                                                data-toggle="modal">Enviar Correo</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <h3 class="">{{licitacionData?.CONT_NOMBRE}}</h3>
                                <!-- <p>{{licitacionData?.CONT_NUMERO}}</p> -->
                            </div>
                            <!-- <div class="col-12">

                        </div> -->
                            <div class="col-12">
                                <span class="text-muted">
                                    {{licitacionData?.DESCRIPCION}}
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="mr-3 ml-3">
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                        class="text-muted">Licitación: </span> <strong>{{idLicitacion}}</strong></span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Solpe:
                                    </span> {{licitacionData?.SOLPE_CORRELATIVO}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Monto:
                                    </span> {{licitacionData?.MONTO_SOLPE | number: '1.2'}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                        class="text-muted">Moneda: </span>
                                    {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span
                                        class="text-muted">Contrato: </span> {{licitacionData?.CONT_NUMERO}}</span>
                                <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Fecha:
                                    </span> {{licitacionData?.FECHA_CREACION}}</span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="mr-3 ml-3">
                                <span class="d-block d-md-inline align-center mt-3">
                                    <span class="text-muted">Estado: </span>
                                    <span *ngIf="licitacionData?.LICITACION_FINALIZADA != 1"
                                        class="tab tab-green">{{nombreEtapa}}</span>
                                    <span *ngIf="licitacionData?.LICITACION_FINALIZADA == 1"
                                        class="tab tab-red">Adjudicada</span>
                                </span>
                            </div>
                        </div>
                        <div class="row border-top mt-4">
                            <div class="col-12"></div>
                        </div>
                        <div class="row mt-4 mb-4 ">
                            <div class="col-12">
                                <p class="mb-2 text-muted">Participantes:</p>
                                <span *ngFor="let participante of participantes, let isLast = last">
                                    <span *ngIf="participante.VIGENTE == 'S'" class="tag2">
                                        {{participante.RAZON_SOCIAL}}
                                    </span>
                                    <ng-container *ngIf="participante.VIGENTE == 'N'">
                                        <span style="cursor: pointer;" *ngIf="participante.SOLICITUD_RENUNCIA == 1"
                                            class="tab tab-red mr-2" title="Solicitud de renuncia a licitación"
                                            data-toggle="modal" data-target="#modalSolicitudesRenunciaLicitacion"
                                            (click)="onSolicitudRenunciaLicitacion(participante.PART_CORRELATIVO)">
                                            {{participante.RAZON_SOCIAL}} <i
                                                *ngIf="participante.CONFIRMACION_RENUNCIA == 0"
                                                class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        </span>
                                        <span *ngIf="participante.SOLICITUD_RENUNCIA == 0" class="tab tab-red mr-2">
                                            {{participante.RAZON_SOCIAL}}
                                        </span>
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                        <div *ngFor="let comite of comision | keyvalue" class="row mt-4 mb-4">
                            <div class="col-12">
                                <p class="text-muted mb-0">{{comite.key}}:</p>
                            </div>
                            <div *ngFor="let evaluador of comite.value"
                                class="d-flex-inline align-center margin-rem mt-2">
                                <div class="round-name">
                                    {{evaluador.PART_NOMBRE.slice(0, 2)}}
                                </div>
                                <div class="nombre">
                                    <p class="mb-0">{{evaluador.PART_NOMBRE}}</p>
                                    <span class="text-muted">{{evaluador.EMAIL}}</span>
                                    <!-- <span class="text-muted">{{evaluador.COMISION}}</span> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="row mt-4 mb-4">
                        <div class="col-12">
                            <p class="mb-2 text-muted">Comite:</p>
                            <div *ngFor="let evaluador of comision" class="d-flex-inline align-center margin-rem">
                                <div class="round-name">
                                    {{evaluador.PART_NOMBRE.slice(0, 2)}}
                                </div>
                                <div class="nombre">
                                    <p class="mb-0">{{evaluador.PART_NOMBRE}}</p>
                                    <span class="text-muted">{{evaluador.COMISION}}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                        <div class="row mb-4">
                            <span class="col-md-12 text-muted">Avance de tiempo:</span>
                            <div class="col-md-12" style="margin-top: 10px;">
                                <div class="progress rounded-corner progress-striped active">
                                    <div *ngIf="porcentaje === 0" class="progress-bar bg-gray" style="width: 100%">
                                        0%
                                    </div>
                                    <div *ngIf="porcentaje > 0" class="progress-bar bg-orange"
                                        [ngStyle]="{'width': porcentaje+'%' }">
                                        {{porcentaje}}%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mb-4">
                        <h5 class="col-md-2">Fechas clave</h5>
                        <div class="col-md-10" style="margin-top: 10px;">
                            <span>
                                <span class="text-muted">Inicio:</span> {{fecha_desde | date: 'dd/MM/yyyy'}}<span class="text-muted"> - Termino:</span> {{fecha_hasta | date: 'dd/MM/yyyy'}}
                            </span>
                        </div>
                    </div> -->
                        <div class="tab-nav d-flex">
                            <div class="tablinks" [ngClass]="{'active': tab == 1}" (click)="tab = 1">
                                <span>
                                    Cronograma
                                </span>
                            </div>
                            <div class="tablinks" [ngClass]="{'active': tab == 2}" (click)="tab = 2">
                                <span>
                                    Archivos
                                </span>
                            </div>
                            <div class="tablinks" [ngClass]="{'active': tab == 3}" (click)="tab = 3">
                                <span>
                                    Correos
                                </span>
                            </div>
                        </div>
                        <div [ngClass]="{'d-none': tab !== 1}">
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <h4>Cronograma</h4>
                                </div>
                                <div class="col-6 text-right">
                                    <ng-container *ngIf="versionesHistorial.length > 0">
                                        <button *ngIf="isFilterHistorial == false" class="btn btn-primary"
                                            (click)="isFilterHistorial = !isFilterHistorial"><i class="fa fa-clock"
                                                aria-hidden="true"></i> Hitorial</button>
                                    </ng-container>
                                </div>
                            </div>
                            <ng-container *ngIf="isFilterHistorial">
                                <br>
                                <div class="row">
                                    <div class="col-6"></div>
                                    <div class="col-6 text-right">
                                        <form [formGroup]="formHistorial" (ngSubmit)="onGetVersionHistorial()">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="actualHistorial"
                                                            placeholder="Versión base (Acta de inicio)" #actualHistorial
                                                            readonly>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <select class="form-control" formControlName="versionHistorial"
                                                            #versionHistorial>
                                                            <option *ngFor="let item of versionesHistorial"
                                                                value="{{item.version}}">V.{{item.version}}</option>
                                                            <option value="-1">Versión Actual</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 text-right">
                                                    <button class="btn tag mr-2"
                                                        (click)="isFilterHistorial = !isFilterHistorial">Cancelar</button>
                                                    <button type="submit" class="btn btn-primary"
                                                        [disabled]="formHistorial.invalid" data-toggle="modal"
                                                        data-target="#modalHistorialCronograma">Comparar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-container>
                            <form>
                                <div class="form-group row mb-4">
                                    <!-- <h5 class="col-md-2">Cronograma</h5> -->
                                    <div class="col-md-12" style="margin-top: 10px;">
                                        <table id="table_cronograma"
                                            class="table table-striped table-td-valign-middle mb-4">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th class="text-nowrap">
                                                        Actividad
                                                    </th>
                                                    <!-- <th width="5%" class="text-nowrap">Nro. Horas</th> -->
                                                    <th class="text-nowrap">
                                                        Fecha Inicio
                                                    </th>
                                                    <th class="text-nowrap">
                                                        Fecha Fin
                                                    </th>
                                                    <th class="text-nowrap">
                                                        Obligatoria
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="odd gradeX" *ngFor="let item of cronograma">
                                                    <td
                                                        [ngStyle]="{'background-color':item.ETAPA_ACTUAL === 'S' ? '#fefe88' : 'transparent'}">
                                                        <i *ngIf="item.ETAPA_ACTUAL === 'S'"
                                                            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-right"
                                                            style="color: black;"></i>
                                                        <!-- <ng-container *ngIf="item.ETAPA_CORRELATIVO != 9">
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL === 'S'" routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.ETAPA_CORRELATIVO == 9">
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL === 'S'" routerLink="/resultados/{{item.LIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_CORRELATIVO == 9 && item.ETAPA_ACTUAL !== 'S'">
                                                        <a routerLink="/resultados/{{item.LIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.ETAPA_CORRELATIVO != 9 && item.ETAPA_ACTUAL !== 'S'">
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL !== 'S'" routerLink="/{{item.PAG_SOLO_LECTURA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                    </ng-container>
                                                    <a *ngIf="!item.ETLIC_CORRELATIVO">
                                                        <span>
                                                            {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)': ''}}</strong>
                                                        </span>
                                                    </a> -->

                                                        <!-- menu -->
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL === 'S' && item.ETAPA_CORRELATIVO != 17"
                                                            routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL
                                                                    === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL === 'S' && item.ETAPA_CORRELATIVO == 17"
                                                            routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL
                                                                    === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL !== 'S'  && item.ETAPA_CORRELATIVO != 17"
                                                            routerLink="/{{item.PAG_SOLO_LECTURA}}/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL
                                                                    === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                        <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL !== 'S'  && item.ETAPA_CORRELATIVO == 17"
                                                            routerLink="/{{item.PAGINA}}/{{item.LIC_CORRELATIVO}}">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL
                                                                    === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                        <a *ngIf="!item.ETLIC_CORRELATIVO">
                                                            <span>
                                                                {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL
                                                                    === 'S' ? '(Etapa actual)': ''}}</strong>
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <!-- <td width="5%" [ngStyle]="{'background-color':item.ETAPA_ACTUAL === 'S' ? '#fefe88' : 'transparent' }">{{item.CANT_HORAS}}</td> -->
                                                    <td
                                                        [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                                                        <span>
                                                            <span
                                                                [class]="pdfClassElement">{{getDayNameByDate(item.FECHA_INICIO_ETAPA)}}</span>
                                                            {{item.FECHA_INICIO_ETAPA | date: 'dd/MM/yyyy'}}
                                                        </span>
                                                    </td>
                                                    <td
                                                        [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                                                        <span>
                                                            <span
                                                                [class]="pdfClassElement">{{getDayNameByDate(item.FECHA_TERMINO_ETAPA)}}</span>
                                                            {{item.FECHA_TERMINO_ETAPA | date: 'dd/MM/yyyy'}}
                                                        </span>
                                                    </td>
                                                    <td
                                                        [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                                                        <span class="tab tab-yellow"
                                                            *ngIf="item.ETAPA_OBLIGATORIA === 1">
                                                            Obligatoria
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-right">
                                            <app-view-excel-buttons [title]="'Cronograma'" [filename]="'cronograma'"
                                                [headers]="excelHeaders" [data]="cronograma" [variant]="'tag'"
                                                style="margin-bottom: 10px;">
                                            </app-view-excel-buttons>
                                            <ng-container *ngIf="this.objLicitacion?.ETAPA_CORRELATIVO != 0">
                                                <ng-container
                                                    *ngIf="validateRoleParticipanteComision(37) && this.objLicitacion?.ETAPA_CORRELATIVO != 16">
                                                    <ng-container *ngIf="idCargoActual == 12">
                                                        <button style="margin-left: 10px;"
                                                            *ngIf="oferentesDescalificadosData.length <= 0"
                                                            type="button" class="btn btn-primary"
                                                            data-target="#modalAvanzarEtapa" data-toggle="modal">Avanzar
                                                            etapa</button>

                                                        <button style="margin-left: 10px;"
                                                            *ngIf="oferentesDescalificadosData.length > 0" type="button"
                                                            class="btn btn-primary"
                                                            data-target="#modalAvanzarEtapaOfentesDescalificados"
                                                            data-toggle="modal">Avanzar etapa</button>
                                                    </ng-container>
                                                    <!-- <button style="margin-left: 10px;" *ngIf="idLicitacion && 16>correlativoEtapa" type="button" class="btn btn-primary" (click)="onValidarPreguntas()">Validar preguntas</button> -->
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div [ngClass]="{'d-none': tab !== 2}">
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <h4>Archivos</h4>
                                </div>
                                <div class="col-6 text-right"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                    <ng-container *ngIf="etapasArchivosFiltros.length > 0">
                                        <div>
                                            <button class="btn tag text-primary m-r-10 m-b-10"
                                                (click)="selectEtapaArchivo('', $event)">Todos</button>
                                            <ng-container *ngFor="let etapa of etapasArchivosFiltros">
                                                <button class="btn tag text-primary m-r-10 m-b-10"
                                                    (click)="selectEtapaArchivo(etapa, $event)">{{etapa}}</button>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" (keyup)="applyFilterArchivos($event)" placeholder="Buscar ..."
                                        class="form-control"
                                        style="margin-bottom: 10px; padding-right: 2rem !important;">
                                    <i matsuffix="" class="fas fa-search"
                                        style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <table style="margin-top: 10px;" id="table_archivos"
                                class="table table-striped table-td-valign-middle">
                                <thead class="thead-dark">
                                    <tr>
                                        <th class="text-nowrap">Nombre archivo</th>
                                        <th class="text-nowrap">Descripción</th>
                                        <th class="text-nowrap">Etapa</th>
                                        <th class="text-nowrap">Fecha creación</th>
                                        <th class="text-nowrap">Subido</th>
                                        <th class="text-nowrap"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd gradeX" *ngFor="let item of archivosLicitacionFilter">
                                        <td class="text-break">
                                            <i class="fas fa-file text-primary mr-2"></i>
                                            <span>
                                                {{item.DESCRIPCION}}
                                            </span>
                                        </td>
                                        <td class="text-break">
                                            <span>
                                                {{item.DESCRIPCION_ARCHIVO}}
                                            </span>
                                        </td>
                                        <td class="text-break">
                                            <span class="text-muted">
                                                {{item.ETAPA_DESCRIPCION}}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="text-muted">
                                                {{item.FECHA_ARCHIVO }}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="text-muted">
                                                {{item.USUARIO}}
                                            </span>
                                            <!-- <ng-template [ngIf]="item.TIPO_ARCHIVO"> - </ng-template>
                                        {{item.NOMBRE_ARCHIVO.split('/').pop()}} -->
                                        </td>
                                        <td class="text-center">
                                            <app-view-file-buttons
                                                [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div [ngClass]="{'d-none': tab !== 3}">
                            <!-- Agregar aqui la tabla con los correos -->
                            <app-correos-licitacion [idLicitacion]="idLicitacion"></app-correos-licitacion>
                        </div>
                    </div>
                    <!-- end panel-body -->
                </div>
                <!-- end panel -->
            </div>
            <!-- end col-6 -->
        </div>
        <!-- end row -->
        <chat [idLicitacion]="idLicitacion"></chat>
    </div>
    <!-- modal comision evaluadora -->
    <!-- <div class="modal modal-message" id="modalComision">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="width: 80%;">
                <h4 class="modal-title">Administrador Contratista</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body" style="width: 80%;">
                <div class="row">
                    <div class="col-md-12">
                        <app-table-participantes-com [idLicitacion]="idLicitacion" (data)="updateComision($event)"></app-table-participantes-com>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <a href="javascript:;" class="btn btn-primary btn-block" data-dismiss="modal">
                            Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

    <!-- <div class="modal fade bd-example-modal-lg" id="modalComision" tabindex="-1" role="dialog" aria-labelledby="modalComision" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalComision">Administrar Participantes de la Licitación (Oferentes)</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <app-table-participantes-com [idLicitacion]="idLicitacion" (data)="updateComision($event)"></app-table-participantes-com>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
</div> -->
    <!-- modal participantes -->
    <!-- <div class="modal modal-message" id="modalParticipantes">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="width: 80%;">
                <h4 class="modal-title">Administrador Contratista</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body" style="width: 80%;">
                <div class="row">
                    <div class="col-md-12">
                        <app-table-participantes [idLicitacion]="idLicitacion" [idEtapaLic]="idEtapaLic" (data)="setParticipantes($event)"></app-table-participantes>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <a href="javascript:;" class="btn btn-primary btn-block" data-dismiss="modal">
                            Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

    <div class="modal fade bd-example-modal-lg" id="modalParticipantes" tabindex="-1" role="dialog"
        aria-labelledby="modalParticipantes" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalParticipantes">Administrar Participantes de la Licitación
                        (Oferentes)</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <app-table-participantes-com [idLicitacion]="idLicitacion" (data)="updateComision($event)"></app-table-participantes-com> -->
                            <app-table-participantes [idLicitacion]="idLicitacion" [idEtapaLic]="idEtapaLic"
                                (data)="setParticipantes($event)"></app-table-participantes>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal para el envio de correos electronicos desde licitaciones -->
    <!-- <div class="modal modal-message" id="modalEnviarCorre">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="width: 80%;">
                <h4 class="modal-title">Enviar correo</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body" style="width: 80%;">
                <div class="row">
                    <div class="col-md-12">
                        <app-envio-correo-licitaciones
                            [idLicitacion]="idLicitacion"
                            [idEtapaLic]="idEtapaLic"
                            [idEtapa]="idEtapa"
                        ></app-envio-correo-licitaciones>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <a href="javascript:;" class="btn btn-primary btn-block" data-dismiss="modal">
                            Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

    <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="modalEnviarCorre" tabindex="-1" role="dialog"
        aria-labelledby="modalEnviarCorre" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalEnviarCorre">Enviar correo</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- componente para el envio de correos -->
                            <app-envio-correo-licitaciones [idLicitacion]="idLicitacion" [idEtapaLic]="idEtapaLic"
                                [idEtapa]="idEtapa"></app-envio-correo-licitaciones>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal para el envio de correos electronicos desde licitaciones -->
    <div class="modal fade" id="modalAvanzarEtapa" tabindex="-1" role="dialog" aria-labelledby="modalAvanzarEtapa"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalAvanzarEtapa">Avanzar Etapa</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar" #closeModal>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Una vez se avance la etapa no será posible volver a la etapa anterior.</p>
                    <p>¿Está seguro que desea avanzar la etapa?</p>
                    <br>
                    <p>Ingrese sus observaciones:</p>
                    <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" (click)="onValidarPreguntas()" class="btn btn-primary"
                        [disabled]="isLoading">Avanzar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal para avazar etapa pero dejando fuera a los opferentes que no cumplieron con la etapa obligatoria -->
    <div class="modal fade" id="modalAvanzarEtapaOfentesDescalificados" tabindex="-1" role="dialog"
        aria-labelledby="modalAvanzarEtapaOfentesDescalificados" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalAvanzarEtapaOfentesDescalificados">Avanzar Etapa</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar"
                        #closeModalOfentesDescalificados>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Los oferentes que no completaron la etapa obligatoria no continuarán en la licitación.</p>
                    <ng-container *ngFor="let oferente of oferentesDescalificadosData">
                        <div *ngIf="oferente.participoEnEtapaActual == true" class="row">
                            <div class="col-6">
                                <p class="text-success" style="font-weight: bold; cursor: pointer;"
                                    routerLink="{{urlEtapaActual}}"
                                    (click)="closeModalAvanzarEtapaOfentesDescalificados()">
                                    {{oferente.razon_social}} (Completado)
                                </p>
                            </div>
                            <div class="col-6">
                                <i class="fa fa-check-circle text-success mr-2"></i>
                            </div>
                        </div>
                        <div *ngIf="oferente.participoEnEtapaActual == false" class="row">
                            <div class="col-6">
                                <p class="text-danger" style="font-weight: bold; cursor: pointer;"
                                    routerLink="{{urlEtapaActual}}"
                                    (click)="closeModalAvanzarEtapaOfentesDescalificados()">
                                    {{oferente.razon_social}} (No completado)
                                </p>
                            </div>
                            <div class="col-6">
                                <i class="fa fa-times text-danger mr-2"></i>
                            </div>
                        </div>
                    </ng-container>
                    <br>
                    <p>Una vez se avance la etapa no será posible volver a la etapa anterior.</p>
                    <p>¿Está seguro que desea avanzar la etapa?</p>
                    <p>Ingrese sus observaciones:</p>
                    <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" (click)="onValidarPreguntas(); isLoading = true;" class="btn btn-primary"
                        [disabled]="isLoading">Avanzar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal para visualizar solicitudes de renuncia a licitacion-->
    <div class="modal fade" id="modalSolicitudesRenunciaLicitacion" tabindex="-1" role="dialog"
        aria-labelledby="modalSolicitudesRenunciaLicitacion" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalSolicitudesRenunciaLicitacion">Solicitud de renuncia a licitación
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- <ng-container *ngFor="let renuncia of renunciaLicitacionParticipante">
            <p>{{renuncia.motivo_renuncia}}</p>
          </ng-container> -->
                    <table class="table table-hover">
                        <thead class="thead-darkk">
                            <th>Motivo de renuncia</th>
                            <th>Descripción</th>
                            <th class="text-right">Confirmación</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let renuncia of renunciaLicitacionParticipante">
                                <ng-container>
                                    <td>
                                        {{ renuncia.descripcion }}
                                    </td>
                                    <td>
                                        {{ renuncia.motivo_renuncia }}
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="renuncia.confirmacion_renuncia_licitacion == 0"
                                            class="btn tag text-orange"
                                            (click)="confirmarRenunciaLicitacionParticipante(renuncia.part_correlativo)">Aceptar</button>
                                        <p *ngIf="renuncia.confirmacion_renuncia_licitacion == 1" class="text-success">
                                            Confirmado</p>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal comision -->
    <div class="modal fade" id="modalEditarComision" tabindex="-1" role="dialog" aria-labelledby="modalEditarComision"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalEditarComision">Editar comisión de licitación</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-table-participantes-com [idLicitacion]="idLicitacion"
                        (data)="updateComision($event)"></app-table-participantes-com>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="modalHistorialCronograma" tabindex="-1" role="dialog"
        aria-labelledby="modalHistorialCronograma" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalHistorialCronograma">Historial de cronograma</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="cronogramaVersionSeleccionado.length > 0">
                        <div class="row">
                            <div class="col-12">
                                <span class="mr-3 text-muted" style="font-weight: bold;"><i
                                        class="fa fa-circle text-warning mr-2" aria-hidden="true"></i> Etapa
                                    Modificada</span>
                                <span class="mr-3 text-muted" style="font-weight: bold;"><i
                                        class="fa fa-circle text-danger mr-2" aria-hidden="true"></i> Etapa
                                    Eliminada</span>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6">
                                <p class="text-muted" style="font-weight: bold;">Versión Base (Acta de inicio):</p>
                                <table id="table_cronograma" class="table table-striped table-td-valign-middle mb-4">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th class="text-nowrap">
                                                Actividad
                                            </th>
                                            <th class="text-nowrap">
                                                Fecha Inicio
                                            </th>
                                            <th class="text-nowrap">
                                                Fecha Fin
                                            </th>
                                            <th class="text-nowrap">
                                                Obligatoria
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd gradeX" *ngFor="let item of cronogramaVersionOriginal">
                                            <ng-container *ngIf="item.etapa_correlativo !== 0">
                                                <td [ngClass]="{
                                                'bg-danger text-white': item.activo == 'N'
                                            }" style="height: 60px;">
                                                    <span>
                                                        {{ item.descripcion }}
                                                    </span>
                                                </td>
                                                <td [ngClass]="{
                                                'bg-danger text-white': item.activo == 'N'
                                            }">
                                                    <span *ngIf="item.fecha_inicio_etapa !== null">
                                                        {{item.fecha_inicio_etapa | date: 'dd/MM/yyyy'}}
                                                    </span>
                                                </td>
                                                <td [ngClass]="{
                                                'bg-danger text-white': item.activo == 'N'
                                            }">
                                                    <span *ngIf="item.fecha_termino_etapa !== null">
                                                        {{item.fecha_termino_etapa | date: 'dd/MM/yyyy'}}
                                                    </span>
                                                </td>
                                                <td [ngClass]="{
                                                'bg-danger text-white': item.activo == 'N'
                                            }">
                                                    <span class="tab tab-yellow" *ngIf="item.obligatorio === 1">
                                                        Obligatoria
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6">
                                <p class="text-muted" style="font-weight: bold;">Versión seleccionada:</p>
                                <table id="table_cronograma" class="table table-striped table-td-valign-middle mb-4">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th class="text-nowrap">
                                                Actividad
                                            </th>
                                            <th class="text-nowrap">
                                                Fecha Inicio
                                            </th>
                                            <th class="text-nowrap">
                                                Fecha Fin
                                            </th>
                                            <th class="text-nowrap">
                                                Obligatoria
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd gradeX" *ngFor="let item of cronogramaVersionSeleccionado">
                                            <ng-container *ngIf="item.etapa_correlativo !== 0">
                                                <td [ngClass]="{
                                                'bg-warning': item.existe === true && item.diferente === true,
                                                'bg-danger text-white': item.existe === false || item.activo == 'N'
                                            }" style="height: 60px;">
                                                    <span>
                                                        {{ item.descripcion }}
                                                    </span>
                                                </td>
                                                <td [ngClass]="{
                                                'bg-warning': item.existe === true && item.diferente === true,
                                                'bg-danger text-white': item.existe === false || item.activo == 'N'
                                            }">
                                                    <span *ngIf="item.fecha_inicio_etapa !== null">
                                                        {{item.fecha_inicio_etapa | date: 'dd/MM/yyyy'}}
                                                    </span>
                                                </td>
                                                <td [ngClass]="{
                                                'bg-warning': item.existe === true && item.diferente === true,
                                                'bg-danger text-white': item.existe === false || item.activo == 'N'
                                            }">
                                                    <span *ngIf="item.fecha_termino_etapa !== null">
                                                        {{item.fecha_termino_etapa | date: 'dd/MM/yyyy'}}
                                                    </span>
                                                </td>
                                                <td [ngClass]="{
                                                'bg-warning': item.existe === true && item.diferente === true,
                                                'bg-danger text-white': item.existe === false || item.activo == 'N'
                                            }">
                                                    <span class="tab tab-yellow" *ngIf="item.obligatorio === 1">
                                                        Obligatoria
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="cronogramaVersionSeleccionado.length <= 0">
                        <p class="text-center">No hay cronograma para mostrar</p>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </div>
            </div>
        </div>
    </div>