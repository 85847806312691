import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService } from 'src/app/shared/services/solpe.service';

export class IOption
{
  value: number;
  text: string;
}

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent {

  @Input() options: IOption[] = [];
  @Input() formControlSelect: FormControl;
  @Output() optionSelected = new EventEmitter<string>();
  idLicitacion: number;

  //options: string[] = [];
  filteredOptions: IOption[] = [];
  searchTerm: string = '';
  selectedOption: string = '';
  pickedOption: IOption;
  solpeName: string;

  openDropdown: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private notifyService : NotificationService,
    private licitacionService : LicitacionService,
    private solpeService: SolpeService
  ){
    this.filteredOptions = this.options;
    this.openDropdown = false;
    this.solpeName = '';

    this.route.params.subscribe((params: Params)=>{
      if (params['idLicitacion'] != undefined) {
        this.idLicitacion = params['idLicitacion'];
      
        this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(resp=>{
          const solpeCorrelativo: number = JSON.parse(resp)[0].CORRELATIVO_SOLPE.toString();
          this.solpeService.getSolpeByCorrelativo(solpeCorrelativo).subscribe(
            resp => {
              let response: any = resp;
              if(response.data?.length > 0){
                this.solpeName = response.data[0].text;
              }
            }, 
            error => {
              console.log(error);
            }
          );
        }, err =>{
          this.notifyService.showError(err.error.message, 'Obtener cabecera licitación');
        });
      }
    });
  }

  filterOptions() {
    this.filteredOptions = this.options.filter(
      option => {
        return option.text.toLowerCase().includes(this.searchTerm.toLowerCase())
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filteredOptions = this.options.filter(
      option => {
        return option.text.toLowerCase().includes(this.searchTerm.toLowerCase())
      }
    );
  }

  onOptionSelected() {
    // Handle the selected option here
    this.optionSelected.emit(this.selectedOption);
    //console.log(this.selectedOption);
  }

  setOpction(option:IOption){
    this.pickedOption = option
    //this.formControlSelect = new FormControl(option.value)
    this.selectedOption = option.value.toString()
    this.openDropdown = false
    this.onOptionSelected()
  }
}