import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CriteriosEvaluacionService, ICriterios, IGrupo } from 'src/app/shared/services/criterios-evaluacion.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-calificar',
  templateUrl: './calificar.component.html',
  styleUrls: ['./calificar.component.css'],
  providers: [CriteriosEvaluacionService, LicitacionService]
})
export class CalificarComponent implements OnInit {

  grupoSelected: IGrupo;
  grupoIndex: number;

  indexGrupo: number = 0;
  indexSubgrupo: number = 0;
  indexCriterio: number = 0;
  idParticipante: number = 0;

  subgrupoIndex: number;

  idLicitacion: number;
  objLicitacion: any;
  objGrupos: IGrupo[];

  objCritero: ICriterios;

  obgParticipantes: any[];

  @Input() ayudaEvalTecnicaLs: any[];
  @Output() getAyudaEvalTecnica: EventEmitter<any[]> = new EventEmitter<any[]>();

  grupoDescripcion: string;
  subGrupoDescripcion: string;

  ayudaEvalTecnicaLsFiltered: any[];
  cargosFilter: string[];
  aclaraciones: any[];
  aclaracionesFiltered: any[];
  oferenteSeleccionadoStr: string;
  evaluacionCompleta: boolean;
  idOferente: number;
  ofertasTecnicasPresentadasPorOfertentes: any[];
  
  constructor(
    private route: ActivatedRoute, 
    private licitacionService: LicitacionService, 
    private notifyService : NotificationService,
    private criteriosServices: CriteriosEvaluacionService, 
    private location: Location
  ) {
      this.ayudaEvalTecnicaLs = [];
      this.ayudaEvalTecnicaLsFiltered = [];
      this.grupoDescripcion = '';
      this.subGrupoDescripcion = '';
      this.cargosFilter = [];
      this.aclaraciones = [];
      this.aclaracionesFiltered = [];
      this.oferenteSeleccionadoStr = '';
      this.obgParticipantes = [];
      this.evaluacionCompleta = false;
      this.idOferente = 0;
      this.ofertasTecnicasPresentadasPorOfertentes = [];

      this.route.params.subscribe(
        (params: Params)=>{
          this.idLicitacion = params['idLicitacion'];

          this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(resp=>{
            this.objLicitacion = JSON.parse(resp)[0];
            this.licitacionService.getAclaracionLic(this.idLicitacion,8).subscribe(resp=>{
              this.aclaraciones = JSON.parse(resp);
            });
          }, err =>{
            this.notifyService.showError(err.error.message, 'Obtener cabecera licitación');
          });
          
          this.licitacionService.getPartLicitacionId(this.idLicitacion).subscribe(resp=>{
            this.obgParticipantes = JSON.parse(resp);
          }, err =>{
            this.notifyService.showError(err.error.message, 'Obtener cabecera licitación');
          });
      });
  }

  ngOnInit(): void {
    this.getSelectPresentacionOfertaTecnica();
  }

  back(): void {
    this.location.back();
  }

  selectCargo(cargo: string){
    if(cargo != '')
    {
      this.ayudaEvalTecnicaLsFiltered = this.ayudaEvalTecnicaLs.filter(data => {
        return (
          (
            data?.cargo.toString().toLowerCase().includes(cargo.toLowerCase())
          )
        );
      });
    }
    else
    {
      this.ayudaEvalTecnicaLsFiltered = this.ayudaEvalTecnicaLs;
    }
  }

  ayudaevalTecnicaData(
    idAyudaEvalTecnica: number, 
    index, 
    indexSub, 
    indexCriterio, 
    grupoDescripcion: string, 
    subGrupoDescripcion: string,
    subgrupoCorrelativo: number
  ) {

    this.grupoDescripcion = grupoDescripcion;
    this.subGrupoDescripcion = subGrupoDescripcion;
    this.cargosFilter = [];
    this.indexGrupo = index;
    this.indexSubgrupo = indexSub;
    this.indexCriterio = indexCriterio;

    if (subgrupoCorrelativo == null || subgrupoCorrelativo == undefined) {
      subgrupoCorrelativo = 0;
    }

    this.criteriosServices.getAyudaEvalTecnica(subgrupoCorrelativo).subscribe(resp => {
      const response: any = resp;
      const ayudaEvaluacionTecnica: any[] = response.ayudaEvalTec;

      if (ayudaEvaluacionTecnica.length > 0) {
        for (let index = 0; index < ayudaEvaluacionTecnica.length; index++) {
          const element = ayudaEvaluacionTecnica[index];
          
          if (element?.cargo != '' && element?.cargo != null) {
            this.cargosFilter.push(element?.cargo);
          }
        }

        this.cargosFilter = Array.from(new Set(this.cargosFilter));
        this.ayudaEvalTecnicaLs = ayudaEvaluacionTecnica;
        this.ayudaEvalTecnicaLsFiltered = ayudaEvaluacionTecnica;
        this.getAyudaEvalTecnica.emit();
      }else{
        this.ayudaEvalTecnicaLs = [];
        this.ayudaEvalTecnicaLsFiltered = [];
        this.cargosFilter = [];
        this.getAyudaEvalTecnica.emit();
      }
    },
    error => {console.log(error)},
    () => {
      // nada
    });
  }

  GetGrupos(idParticipante: number, participanteSeleccionadoStr: string)
  {
    this.idParticipante = idParticipante;
    this.criteriosServices.getGruposParticipanteEvaluador(this.idLicitacion,this.idParticipante).subscribe(resp => {
      let response: any = resp;

      if(response.data?.length > 0)
      {
        this.objGrupos = response.data;
      }
      
      this.oferenteSeleccionadoStr = participanteSeleccionadoStr;
      this.aclaracionesFiltered = this.aclaraciones.filter(data => data.PART_CORRELATIVO == idParticipante);
      this.getSelectEvaluacionCompletadaEvaluador(idParticipante);
    }, 
    error => {console.log(error)},
    () => {
      
    });
  }
  ponerNota(event: any, index, indexSub, indexCriterio)
  {
    this.indexGrupo = index;
    this.indexSubgrupo = indexSub;
    this.indexCriterio = indexCriterio;
    if(event.target.value == '' || event.target.value == null || event.target.value == undefined)
    {
      return;
    }
    if(event.target.value == this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs[this.indexCriterio].calificacion)
    {
      return;
    }

    this.updateCriterios(event.target.value);
  }

  ponerNotaSelect(nota: number){

    if(nota == null || nota == undefined)
    {
      return;
    }
    if(nota == this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs[this.indexCriterio].calificacion)
    {
      return;
    }

    this.updateCriterios(nota);
  }

  updateCriterios(calificacion)
  {

    this.objCritero = this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs[this.indexCriterio];
    this.objCritero.calificacion = +calificacion;
    //this.objCritero.
    
    this.criteriosServices.postCalificar(this.objCritero).subscribe(resp => {
      let response: any = resp;
      this.notifyService.showSuccess('', 'Datos guardados');
      if(response.data)
      {
        //this.objCritero = response.data;
        this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs[this.indexCriterio] = this.objCritero;
      }
    }, 
    error => {console.log(error)},
    () => {
      
    });
    
  }

  applyFilterAyudaEvalTecnica(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.ayudaEvalTecnicaLsFiltered = this.ayudaEvalTecnicaLs.filter(data => {
        return (
          (
            data?.cargo.toString().toLowerCase().includes(filterValue) || 
            data?.descripcion.toString().toLowerCase().includes(filterValue) ||
            data?.grupo.toString().toLowerCase().includes(filterValue) ||
            data?.item.toString().toLowerCase().includes(filterValue) ||
            data?.nota.toString().toLowerCase().includes(filterValue) ||
            data?.perfil_requerido.toString().toLowerCase().includes(filterValue) ||
            data?.ponderador.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.ayudaEvalTecnicaLsFiltered = this.ayudaEvalTecnicaLs;
    }
  }

  getSelectEvaluacionCompletadaEvaluador(idOferente: number) {
    this.criteriosServices.getSelectEvaluacionCompletadaEvaluador(idOferente, this.idLicitacion).subscribe(
      resp => {
        const response: boolean = resp;
        this.evaluacionCompleta = response;
        console.log(response);
      }, 
      error => {
        this.notifyService.showWarning(error.message, 'Evaluacion');
      },
    );
  }

  createValidacionEvalTecEvaluador(){

    const postData = {
      in_participante: this.idParticipante,
      in_licitacion: this.idLicitacion
    };

    this.criteriosServices.postCreateValidacionEvaluacionTecEvaluador(postData).subscribe(
      resp => {
        this.criteriosServices.getSelectEvaluacionCompletadaEvaluador(this.idParticipante, this.idLicitacion).subscribe(
          resp => {
            const response: boolean = resp;
            this.evaluacionCompleta = response;
          }, 
          error => {
            this.notifyService.showWarning(error.message, 'Evaluacion');
          },
        );
        this.notifyService.showSuccess('Evaludacion completada', 'Evaluacion');
      }, 
      error => {
        this.notifyService.showError(error.message, 'Evaluacion');
      },
    )
  }

  deleteValidacionEvalTecEvaluador(){

    const postData = {
      in_participante: this.idParticipante,
      in_licitacion: this.idLicitacion
    };

    this.criteriosServices.postDeleteValidacionEvaluacionTecEvaluador(postData).subscribe(
      resp => {
        this.getSelectEvaluacionCompletadaEvaluador(this.idParticipante);
        this.notifyService.showWarning('Evaludacion pendiente por confirmar', 'Evaluacion');
      }, 
      error => {
        this.notifyService.showError(error.message, 'Evaluacion');
      },
    )
  }

  getSelectPresentacionOfertaTecnica(){
    this.criteriosServices.getSelectPresentacionOfertaTecnica(this.idLicitacion).subscribe(
      resp => {
        const oferentesOfertasPresentadas: any[] = JSON.parse(resp);

        if (oferentesOfertasPresentadas.length > 0) {
          this.ofertasTecnicasPresentadasPorOfertentes = oferentesOfertasPresentadas;
          console.log(this.ofertasTecnicasPresentadasPorOfertentes);
        } else {
          this.ofertasTecnicasPresentadasPorOfertentes = [];
        }
      },
      error => {
        this.ofertasTecnicasPresentadasPorOfertentes = [];
        this.notifyService.showError(error.message, 'Evaluacion');
      },
    )
  }

  validatePresentacionOfertaTecnica(idOferente: number): boolean {
    return this.ofertasTecnicasPresentadasPorOfertentes.some(
      (item: { part_correlativo: number }) =>
        item.part_correlativo === idOferente
    );
  }
}
