import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FileService {
  private header;
  private urlService: string = environment.apiUrl;
  constructor(private http: HttpClient) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }
  private urlFileFolder: string = environment.apiUrl + environment.fileFolder;

  getUrlFile(fileName: string) {
    return this.urlFileFolder + fileName;
  }

  downloadFile(fileName: string): Observable<Blob> {
    return this.http.get(this.getUrlFile(fileName), { responseType: "blob" });
  }

  deleteFile(postData: { file_name: string; file_correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/deleteFileLicitacion",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
}
