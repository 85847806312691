<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row">
                        <div class="col-md-12" *ngIf="ayudaEvalTecnicaSelected == false">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Mantenedor de Ayuda Eval. Técnica</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-crearAyudaEvalTecnica" data-toggle="modal" class="btn btn-primary" (click)="resetFormCreateAyudaEval()">Agregar Ayuda</a>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterAyudaEvalTecnica($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Descripción</th>
                                    <th>Vigente</th>
                                    <th>Fecha Creación</th>
                                    <th class="text-center">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of ayudaEvalTecnicaFiltered">
                                        <ng-container>
                                            <td>
                                                {{ item.descripcion }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente === 'S'">
                                                    Vigente
                                                </span>
                                                <span class="tab tab-red" *ngIf="item.vigente === 'N'">
                                                    Inactivo
                                                </span>
                                            </td>
                                            <td>
                                                {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                            </td>
                                            <td class="text-center">
                                                <div class="row text-right">
                                                    <a 
                                                        href="#modal-updateAyudaEvalTecnica"
                                                        title="Editar Ayuda" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="editarAyudaEvalTecnica(item.correlativo)"
                                                        data-toggle="modal"
                                                    >
                                                        <i class="fa fa-edit"></i>
                                                    </a>
                                                    <div 
                                                        title="Ver Criterios de Ayuda" 
                                                        class="btn rounded-icon mr-2"
                                                        (click)="getAyudaEvalTecnicaCriterios(item.correlativo); ayudaEvalTecnicaSelected = true"
                                                    >
                                                        <i class="fa fa-eye"></i>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12" *ngIf="ayudaEvalTecnicaSelected">
                            <!-- *ngIf="ayudasEvalTecnicaCriterios.length > 0" -->
                            <ng-container>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5>{{ ayudaSeleccionadaDescripcion }}</h5>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <button class="btn tag text-bolder text-muted text-primary mr-2" (click)="ayudaEvalTecnicaSelected = false">
                                            <i class="fa fa-arrow-alt-circle-left"></i> Volver
                                        </button>
                                        <a href="#modal-crearAyudaEvalTecnicaCriterio" data-toggle="modal" class="btn btn-primary" (click)="resetFormCreateAyudaEvalTecnicaCriterio()">Agregar</a>
                                    </div>
                                </div>
                                <br>
                            </ng-container>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterAyudaEvalTecnicaCriterios($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <div class="row">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Descripción</th>
                                        <th>Cargo</th>
                                        <th>Grupo</th>
                                        <th>Item</th>
                                        <th>Perfil Requerido</th>
                                        <th>Ponderador</th>
                                        <th>Nota</th>
                                        <th class="text-right">Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of ayudasEvalTecnicaCriteriosFiltered">
                                            <td>{{item.correlativo}} - {{ item.descripcion }}</td>
                                            <td>{{ item.cargo }}</td>
                                            <td>{{ item.grupo }}</td>
                                            <td>{{ item.item }}</td>
                                            <td>{{ item.perfil_requerido }}</td>
                                            <td>{{ item.ponderador }}</td>
                                            <td class="text-center">{{ item.nota }}</td>
                                            <td>
                                                <div class="row text-right">
                                                    <a 
                                                        href="#modal-editarAyudaCriterio" 
                                                        data-toggle="modal" 
                                                        (click)="editarAyudaEvalTecnicaCriterio(item.correlativo)" 
                                                        class="btn rounded-icon f-right mr-2"
                                                    >
                                                        <i class="fa fa-edit"></i>
                                                    </a>
                                                    <a 
                                                    href="#modal-eliminarAyudaCriterio" 
                                                    data-toggle="modal" 
                                                    (click)="eliminarAyudaEvalTecnicaCriterio(item.correlativo)" 
                                                    class="btn rounded-icon f-right mr-2" 
                                                >
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal eliminar ayuda criterio -->
<div class="modal fade" id="modal-eliminarAyudaCriterio" tabindex="-1" role="dialog" aria-labelledby="modal-eliminarAyudaCriterio" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-eliminarAyudaCriterio">Eliminar Ayuda Criterio</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalEliminarAyudaCriterio aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Desea eliminar este criterio para la ayuda?</p> 
          <span class="font-weight-bold">{{ ayudaSeleccionadaDescripcion }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onEliminarAyudaEvalTecnicaCriterio()">Eliminar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal editar ayuda criterio -->
<div class="modal fade" id="modal-editarAyudaCriterio" tabindex="-1" role="dialog" aria-labelledby="modal-editarAyudaCriterio" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-editarAyudaCriterio">Editar Ayuda Criterio</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalEditarAyudaCriterio aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formUpdateAyudaCriterio" (ngSubmit)="onEditarAyudaEvalTecnicaCriterio()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                    <div class="col-md-9">
                        <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" formControlName="grupo" #grupo>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="item" class="col-md-3 col-form-label">Item</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="item" #item>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="cargo" class="col-md-3 col-form-label">Cargo</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="cargo" #cargo>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="ponderador" class="col-md-3 col-form-label">Ponderador</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="ponderador" #ponderador>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="perfil_requerido" class="col-md-3 col-form-label">Perfil Requerido</label>
                    <div class="col-md-9">
                        <textarea rows="8" class="form-control" formControlName="perfil_requerido" #perfil_requerido></textarea>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="nota" class="col-md-3 col-form-label">Nota</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="nota" #nota>	
                            <option *ngFor="let item of notasEvaluacionTecnica" value="{{item.correlativo}}" >{{ item.nota }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modal agregar ayuda criterio -->
<div class="modal fade" id="modal-crearAyudaEvalTecnicaCriterio" tabindex="-1" role="dialog" aria-labelledby="modal-crearAyudaEvalTecnicaCriterio" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-crearAyudaEvalTecnicaCriterio">Crear Ayuda Criterio</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalCrearAyudaCriterio aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formCreateAyudaCriterio" (ngSubmit)="onCrearAyudaEvalTecnicaCriterio()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                    <div class="col-md-9">
                        <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" formControlName="grupo" #grupo>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="item" class="col-md-3 col-form-label">Item</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="item" #item>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="cargo" class="col-md-3 col-form-label">Cargo</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="cargo" #cargo>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="ponderador" class="col-md-3 col-form-label">Ponderador</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" formControlName="ponderador" #ponderador>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="perfil_requerido" class="col-md-3 col-form-label">Perfil Requerido</label>
                    <div class="col-md-9">
                        <textarea rows="8" class="form-control" formControlName="perfil_requerido" #perfil_requerido></textarea>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="nota" class="col-md-3 col-form-label">Nota</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="nota" #nota>	
                            <option *ngFor="let item of notasEvaluacionTecnica" value="{{item.correlativo}}" >{{ item.nota }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="submit" class="btn btn-primary">Guardar</button>
        </div> -->
      </div>
    </div>
</div>
<!-- Modal agregar ayuda criterio -->
<div class="modal fade" id="modal-crearAyudaEvalTecnica" tabindex="-1" role="dialog" aria-labelledby="modal-crearAyudaEvalTecnica" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-crearAyudaEvalTecnica">Crear Ayuda Evaluación Técnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalCrearAyudaEvalTecnica aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formCreateAyudaEval" (ngSubmit)="onCrearAyudaEvalTecnica()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripción</label>
                    <div class="col-md-9">
                        <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
<!-- Modal editar ayuda criterio -->
<div class="modal fade" id="modal-updateAyudaEvalTecnica" tabindex="-1" role="dialog" aria-labelledby="modal-updateAyudaEvalTecnica" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-updateAyudaEvalTecnica">Editar Ayuda Evaluación Técnica</h5>
          <button type="button" class="close" data-dismiss="modal" #closeModalUpdateAyudaEvalTecnica aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="formUpdateAyudaEval" (ngSubmit)="onUpdateAyudaEvalTecnica()" novalidate>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Descripcion</label>
                    <div class="col-md-9">
                        <textarea rows="8" class="form-control" formControlName="descripcion" #descripcion></textarea>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="descripcion" class="col-md-3 col-form-label">Vigente</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="vigente" #vigente>	
                            <option value="S">Si</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="submit" class="btn btn-primary">Guardar</button>
                </div>
            </form>
        </div>
      </div>
    </div>
</div>