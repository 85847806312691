import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import * as moment from "src/assets/plugins/moment/moment";

@Component({
  selector: "app-view-cronograma-contratista",
  templateUrl: "./view-cronograma-contratista.component.html",
  styleUrls: ["./view-cronograma-contratista.component.css"],
})
export class ViewCronogramaContratistaComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  nombreEtapa: string;
  cronograma: any[];
  porcentaje: number = 0;
  fecha_desde: string = "";
  fecha_hasta: string = "";
  licitacionData: any;
  tabSelected: number;
  archivosLicitacion: any[];
  userId: string = "";

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private etapaService: EtapasService,
    private notifyService: NotificationService
  ) {
    this.cronograma = [];
    this.tabSelected = 0;
    this.archivosLicitacion = [];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.userId = JSON.parse(localStorage.getItem("userData")).userId;

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const licitacion: any = JSON.parse(resp)[0];
          this.licitacionData = licitacion;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );

      this.licitacionService.getPorcentajeAvance(this.idLicitacion).subscribe(
        (resp) => {
          if (JSON.parse(resp)[0].PORCENTAJE_AVANCE) {
            this.porcentaje = JSON.parse(resp)[0].PORCENTAJE_AVANCE;
            this.fecha_desde = JSON.parse(resp)[0].FECHA_DESDE;
            this.fecha_hasta = JSON.parse(resp)[0].FECHA_HASTA;

            let fechaDesde = moment(this.fecha_desde);
            let fechaHasta = moment(this.fecha_hasta);

            // a = diferencia en dias entre fecha de inicio y fecha de fin
            // b = 100% de dias
            // c = diferencia entre la fecha de inicio y la fecha actual
            // formula x = a * b / c

            let a = fechaHasta.diff(fechaDesde, "days");
            let b = 100; //(fechaHasta.diff(fechaDesde, 'days') * 100);
            const hoy = moment(new Date());
            let c = hoy.diff(fechaDesde, "days");

            let porcentajeActual = (c * b) / a;

            this.porcentaje = Math.round(porcentajeActual);

            if (porcentajeActual > 100) {
              this.porcentaje = 100;
            }
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener porcentaje de avance"
          );
        }
      );

      this.licitacionService.getCronogramaLicView(this.idLicitacion).subscribe(
        (resp) => {
          const cronogramaContratista: any[] = JSON.parse(resp);
          let cronograma = [];

          if (cronogramaContratista.length > 0) {
            for (let index = 0; index < cronogramaContratista.length; index++) {
              const element = cronogramaContratista[index];
              let urlEtapaCronograma = "";

              switch (element.ETAPA_CORRELATIVO) {
                case 1:
                  urlEtapaCronograma = `/upload-etapa-archivo/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 2:
                  urlEtapaCronograma = `/upload-etapa-archivo/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 3:
                  urlEtapaCronograma = `/upload-etapa-archivo/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 11:
                  urlEtapaCronograma = `/view-respuesta-economica/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 8:
                  urlEtapaCronograma = `/view-respuesta-aclaracion/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 4:
                  urlEtapaCronograma = `/create-consulta-licitacion/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 5:
                  urlEtapaCronograma = `/create-consulta-licitacion/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 6:
                  urlEtapaCronograma = `/evaluacion/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 13:
                  urlEtapaCronograma = `/view-respuesta-negociacion/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 7:
                  urlEtapaCronograma = `/upload-etapa-archivo/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 9:
                  urlEtapaCronograma = `/upload-etapa-archivo/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 12:
                  urlEtapaCronograma = `/upload-etapa-archivo/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}/`;
                  break;
                case 10:
                  urlEtapaCronograma = `/cargar-presupuesto/${element.LIC_CORRELATIVO}`;
                  break;
                case 16:
                  urlEtapaCronograma = `/adjudicacion-contratista/${element.LIC_CORRELATIVO}/${element.ETAPA_CORRELATIVO}/${element.ETLIC_CORRELATIVO}`;
                  break;
                default:
                  break;
              }

              cronograma.push({
                ...element,
                urlEtapaCronograma,
              });
            }

            this.cronograma = cronograma.sort((a, b) => a.ORDEN - b.ORDEN);
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cronograma licitación"
          );
        }
      );

      if (this.idEtapa) {
        this.etapaService.getEtapaId(this.idEtapa).subscribe(
          (resp) => {
            if (JSON.parse(resp)[0]) {
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
            }
          },
          (err) => {
            this.notifyService.showError(err.error.message, "Obtener etapa");
          }
        );
      }

      this.getArchivoLicitacion(this.idLicitacion);
    });
  }

  private getArchivoLicitacion(idLicitacion: number) {
    if (idLicitacion) {
      this.licitacionService
        .getArchivosLicitacionOferente(this.idLicitacion, this.userId)
        .subscribe(
          (resp) => {
            this.archivosLicitacion = JSON.parse(resp);

            if (this.idEtapa == 5) {
              this.archivosLicitacion = this.archivosLicitacion.filter(
                (x) => x.ETAPA_CORRELATIVO != 5
              );
            }

            this.notifyService.showSuccess(
              "Archivos obtenidos correctamente",
              "Obtener archivos licitación"
            );
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Obtener archivos licitación"
            );
          }
        );
    } else {
      this.notifyService.showError(
        "Falta el id de la licitación",
        "Obtener archivos licitación"
      );
    }
  }
}
