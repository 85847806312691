<!-- begin #content -->
<div id="content" class="content">
    <button *ngIf="idOrigen == 1" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4"
        routerLink="/edit-licitacion/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76=""
            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <button *ngIf="idOrigen == 0" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4"
        (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
            style="color: white;"></i> Volver</button>
    <!-- begin row -->
    <br>
    <!-- <h1 class="page-header">Licitación #{{idLicitacion}}</h1> -->
</div>
<div id="content" class="content">
    <h1 class="page-header">Itemizado / Presupuesto para la licitación <strong>#{{idLicitacion}}</strong></h1>
    <div class="bg-white p3-rem">
        <div *ngIf="objItemizado" class="row">
            <div class="col-12 col-md-8 ">
                <div class="pr-md-5 border-right no-border-sm">
                    <div *ngIf="objItemizado" class="">
                        <div class="d-table">
                            <ng-container *ngFor="let item of objItemizado; let i = index; let first = first"
                                class=" bg-white border">
                                <div class="d-row ">
                                    <div class="d-cell">
                                        <h5 class="mb-0">
                                            {{item.pos}} {{item.descripcion}}
                                        </h5>
                                    </div>
                                    <div class="d-cell">
                                        <span class="font-weight-bold">Cantidad</span>
                                    </div>
                                    <div class="d-cell">
                                        <span class="font-weight-bold">P. Unitario</span>
                                    </div>
                                    <div class="d-cell">
                                        <span class="font-weight-bold">Total</span>
                                    </div>
                                    <div class="d-cell"></div>
                                </div>

                                <ng-container *ngFor="let detalle of item.itemizadoDetalleLs; 
                                let x = index; let last = last" class="">
                                    <div class="d-row" [ngClass]="{'last': last}">
                                        <div class="d-cell">
                                            <p class="ml-4 mb-0">
                                                {{detalle.pos}} {{ detalle.item }}
                                            </p>
                                        </div>
                                        <div class="d-cell">
                                            <p class="mb-0">
                                                {{ detalle.cantidad | number:'1.2'}} {{ detalle.unidad }}
                                            </p>
                                        </div>
                                        <!-- <div class="d-cell">
                                            <div class="form-group row mb-0">
                                                <div class="col-md-12">
                                                    
                                                    <input class="form-control" type="text" placeholder="" id="" [value]="detalle.precio"
                                                    (focusout)="registrarMonto($event, i, x)">
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="d-cell text-right">
                                            <p class="mb-0 mr-4">
                                                {{ detalle.precio_unitario | number:'1.2' }}
                                            </p>
                                        </div>
                                        <div class="d-cell text-right">
                                            <p class="mb-0 mr-4">
                                                {{ detalle.precio_unitario * detalle.cantidad | number:'1.2' }}
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="p-20 pt-0">
                    <div class="row">
                        <div class="col-12">
                            <h3>Resumen</h3>
                        </div>
                    </div>
                </div>
                <div class="p-20 pt-0" style="position: sticky; top: 6rem; max-height: 70vh; 
                    overflow-x: auto; overflow-y: overlay;">
                    <ng-container *ngFor="let item of objItemizado; let i = index; let first = first"
                        class=" bg-white border">
                        <div class="row mb-4">
                            <div class="col-12 col-md-8">
                                <p>{{item.pos}} {{item.descripcion}}</p>
                            </div>
                            <div class="col-12 col-md-4 text-right">
                                <p class="mb-0">{{calcularCapitulo(i) | number: '1.2'}}</p>
                                <!-- <span>
                                    ({{(calcularCapitulo(i)*100)/calcularTotal()| number: '1.0-0'}}%)
                                </span> -->
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="p-20" style="position: sticky; top: 80vh">
                    <div class="row mt-4">
                        <div class="col-12 col-md-8 bold">
                            <span>Total</span>
                        </div>
                        <div class="col-12 col-md-4 text-right bold">
                            <span>{{calcularTotal() | number: '1.2'}}</span>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <!-- <button class="btn tag mb-1 text-bolder text-primary col-12"
                        (click)="enviarPresupuesto()"
                        > 
                            Enviar presupuesto 
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!objItemizado">
            <h2 class="text-center">No se ha cargado el presupuesto para esta licitación.</h2>
        </div>
    </div>
</div>



<div class="modal" id="modalFile" *ngIf="uploadFileModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-form-label col-2">Descripción Archivo <span
                                    class="text-danger">*</span></label>
                            <div class="col-10">
                                <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                    [(ngModel)]="nombreArchivo">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-b-10 align-items-center">
                    <label class="col-md-3 col-form-label">Seleccionar archivo <span
                            class="text-danger">*</span></label>
                    <div class="col-md-9">
                        <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                            type="file" (change)="detectFiles($event)" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 text-right">
                        <!-- <button style="margin-right: 10px;" type="button" class="btn btn-primary" routerLink="/contratista"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button> -->
                        <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="onSubirArchivo()">Subir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>