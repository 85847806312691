import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-aclaraciones-licitacion',
  templateUrl: './aclaraciones-licitacion.component.html',
  styleUrls: ['./aclaraciones-licitacion.component.css']
})
export class AclaracionesLicitacionComponent implements OnInit {
  @Input() idLicitacion: number;
  @Input() idEtapa: number;
  @Input() idEtapaLic: number;
  @Input() aclaracionesLicitacion: any[];
  @Output("getAclaracionesLicitacion") getAclaracionesLicitacion: EventEmitter<any> = new EventEmitter();
  tiposAclaraciones: any[] = [];
  showFile : boolean = false;
  archivos64 : Blob;
  filename: string;
  type: string;
  isDisable:boolean = true;
  nombreEtapa:string;
  archivos:[]=[];
  aclaracionesForm : FormGroup;
  userId: string;
  @ViewChild('inputFile')
  inputFile: ElementRef;
  nombreArchivo: string;

  constructor(
    private licitacionService: LicitacionService,
    private notifyService : NotificationService,
    private etapaService: EtapasService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.aclaracionesForm = this.formBuilder.group({
      tipoAclaracion: [0, Validators.required],
      textoAclaracion: ['', Validators.required],
    });

    this.userId = JSON.parse(localStorage.getItem('userData')).userId;

    if (this.idEtapa){
      this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
        if (resp){
          this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
        }
      });
      this.getArchivoLicEtapaFull();
    }

    this.getTiposAclaraciones();
  }

  getTiposAclaraciones(){
    this.licitacionService.getTiposAclaraciones().subscribe(resp=>{
      this.tiposAclaraciones= JSON.parse(resp);
    },  err =>{
      this.notifyService.showError(err.error.message, 'Obtener aclaraciones.');
    });
  }

  getArchivoLicEtapaFull(){
    this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,this.idEtapa).subscribe(resp=>{
      this.archivos = JSON.parse(resp);
    });
  }

  detectFiles(event: any) {
    if (event.target.validity.valid){
      this.isDisable = false;
    }
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.filename = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
              this.archivos64 = bs64;
        }
      };
    }
  }

  onGuardarArchivo(){
    if (this.archivos64){
        const postData: { 
          in_det_etapa_lic: number;
          files: Blob; 
          fileName: string; 
          type : string; 
          in_usuario: string; 
          publico: number; 
          descripcion_input_archivo: string;
          idLicitacion: number;
          idEtapaLicitacion: number;
          correlativoGrupoArchivo: number;
        } = { 
          in_det_etapa_lic: this.idEtapaLic , 
          files : this.archivos64, 
          fileName: this.filename, 
          type: this.type, 
          in_usuario:'', 
          publico: 1, 
          descripcion_input_archivo: this.nombreArchivo == null || this.nombreArchivo == '' ? this.filename : this.nombreArchivo, 
          idLicitacion: this.idLicitacion,
          idEtapaLicitacion: this.idEtapa,
          correlativoGrupoArchivo: 0
        };
        this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
          this.notifyService.showSuccess('Archivo subido correctamente', 'Subir archivos licitacion');
          this.nombreArchivo = '';
          this.getArchivoLicEtapaFull();
        }, err =>{
          this.notifyService.showError(err.error.message, 'Subir archivos licitacion');
        });
    }

    this.archivos64 = null; 
    this.filename = '';
    this.type = '';
    this.inputFile.nativeElement.value = "";
  }

  onGurardarAclaracionLicitacion(){
    const putData = {
      pin_id_licitacion: this.idLicitacion,
      vn_id_tipo_aclaracion: this.aclaracionesForm.value.tipoAclaracion,
      vn_texto_aclaracion: this.aclaracionesForm.value.textoAclaracion,
      vn_id_usuario_creacion: this.userId,
    };

    this.licitacionService.putGuardarAclaracionLicitacion(
      putData
    ).subscribe(resp =>{
      this.aclaracionesForm.reset();
      this.aclaracionesForm = this.formBuilder.group({
        tipoAclaracion: [0, Validators.required],
        textoAclaracion: ['', Validators.required],
      });
      this.getAclaracionesLicitacion.emit();
      this.notifyService.showSuccess('aclaración registrada', 'Aclaraciones');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener Participantes Oferentes Licitación');
    });
  }
}
