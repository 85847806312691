import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
// import { IOption } from "../general/custom-select/custom-select.component";
import {
  CriteriosEvaluacionService,
  IGrupo,
} from "../shared/services/criterios-evaluacion.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NotificationService } from "../shared/services/notification.service";
import { LicitacionService } from "../shared/services/licitacion.service";
import { EtapasService } from "../shared/services/etapas.licitacion.service";
import { FileService } from "../shared/services/file.service";
import { ItemizadoService } from "../shared/services/itemizado.service";
import { SolpeService } from "../shared/services/solpe.service";
import * as moment from "src/assets/plugins/moment/moment";
import { formatCurrency, getDayNameByDate } from "../shared/functions/common";
import { PDF } from "../shared/functions/pdf";

@Component({
  selector: "app-acta-inicial",
  templateUrl: "./acta-inicial.component.html",
  styleUrls: ["./acta-inicial.component.css"],
})
export class ActaInicialComponent implements OnInit {
  idLicitacion: number;
  monedas: any[];
  licitacionForm: FormGroup;
  dataLicitacion = null;
  aSolpes: any[];
  // aSolpesSelect: IOption[];
  solpeSeleccionada: number = 0;
  currentStep: number = 1;

  // validacion de datos de la licitacion
  islicitacionValida: boolean = false;
  // componentes licitacion validos
  isDatosBasicosValidos: boolean = false;
  isCronogramaValido: boolean = false;
  isParticipantesValido: boolean = false;
  isComisionValidoTecnica: boolean = false;
  isComisionValidoEconomica: boolean = false;
  isComisionValidoResponsable: boolean = false;
  isComisionValidoCoordinador: boolean = false;
  isComisionValidLiderComisionTecnica: boolean = false;
  isCriteriosValido: boolean = false;
  isitemizadoValido: boolean = false;
  isDocumentosValido: boolean = false;
  isActaValida: boolean = false;
  isMatrizRiesgoValida: boolean = false;
  isDocumentosValidoOferente: boolean = false;
  isValidacionLct: boolean = false;
  // variables para la validacion de si los formularios esatn ya la 100%
  objGrupos: IGrupo[];
  gruposPesoTotal: number;
  pesoPermitidoGrupo: number;
  pesoPermitidoSubGrupo: number;
  objPesosGruposYSubgrupos: any[];

  datosLicitacion: {};
  montoItemizado = 0;
  gruposArchivos: any[];
  @Output() gruposArchivosData: EventEmitter<any[]> = new EventEmitter<any[]>();
  isNotificaicioneEnviada: boolean;
  docRequeridosOferenteActa: any[];
  docRequeridosOferenteToSave: any[];
  formDocRequeridosOferenteActa: FormGroup;
  rolesParticipanteComision: any[];
  observacionLct: string;
  validacionLct: number;
  isSolicitarFechaPublicacion: boolean;
  montoSolpeOriginal: number;
  monedaSolpeOriginal: string;
  // variables de pdf
  downloadingPdf: boolean = false;
  pdfClassElement = "d-none";
  archivosLicitacion: any[] = [];
  partComisionFirmante: any[];
  cronograma: any[];
  getDayNameByDate = getDayNameByDate;

  @ViewChild("closeEliminarLicitacion") closeEliminarLicitacion: ElementRef;
  @ViewChild("closeValidarLicitacion") closeValidarLicitacion: ElementRef;

  existePresupuesto: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifyService: NotificationService,
    private licitacionService: LicitacionService,
    private etapaService: EtapasService,
    private fileService: FileService,
    private criteriosServices: CriteriosEvaluacionService,
    private objItemizadoService: ItemizadoService,
    private solpeService: SolpeService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];

      this.currentStep = 1;
      this.montoItemizado = 0;
      this.gruposArchivos = [];
      this.rolesParticipanteComision = [];
      this.observacionLct = "";
      this.validacionLct = 0;
      this.isSolicitarFechaPublicacion = false;
      this.montoSolpeOriginal = 0;
      this.monedaSolpeOriginal = "";
      this.partComisionFirmante = [];
      this.cronograma = [];
      this.objGrupos = [];
      this.isNotificaicioneEnviada = false;
      // this.aSolpesSelect = [];
      this.docRequeridosOferenteActa = [];
      this.docRequeridosOferenteToSave = [];
      this.monedas = [];
      this.aSolpes = [];
      this.objPesosGruposYSubgrupos = [];
      this.existePresupuesto = false;

      this.licitacionForm = new FormGroup({
        solpe: new FormControl(null),
        nro_contrato: new FormControl(null),
        nombre_contrato: new FormControl(null),
        descripcion: new FormControl(null),
        moneda_solpe: new FormControl(0),
        monto_solpe: new FormControl(null),
        fecha_publicacion: new FormControl(null),
      });

      this.formDocRequeridosOferenteActa = new FormGroup({
        docRequeridoOferenteCorrelativo: new FormControl(
          null,
          Validators.required
        ),
      });
    });
  }

  ngOnInit(): void {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        this.dataLicitacion = JSON.parse(resp)[0];

        const fechaEstimadaPublicacion = moment(
          new Date().setDate(new Date().getDate() + 7)
        ).format("YYYY-MM-DD");

        this.licitacionForm = new FormGroup({
          solpe: new FormControl(JSON.parse(resp)[0]?.SOLPE_CORRELATIVO),
          nro_contrato: new FormControl({
            value: JSON.parse(resp)[0]?.CONT_NUMERO,
            disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
          }),
          nombre_contrato: new FormControl({
            value: JSON.parse(resp)[0]?.CONT_NOMBRE,
            disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
          }),
          descripcion: new FormControl({
            value: JSON.parse(resp)[0]?.DESCRIPCION,
            disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
          }),
          moneda_solpe: new FormControl(JSON.parse(resp)[0]?.MONEDA_SOLPE),
          monto_solpe: new FormControl({
            value: JSON.parse(resp)[0]?.MONTO_SOLPE,
            disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
          }),
          fecha_publicacion: new FormControl(
            JSON.parse(resp)[0]?.FECHA_PUBLICACION !== null
              ? moment(JSON.parse(resp)[0]?.FECHA_PUBLICACION).format(
                  "YYYY-MM-DD"
                )
              : fechaEstimadaPublicacion
          ),
        });

        setTimeout(() => {
          if (this.dataLicitacion?.ETAPA_CORRELATIVO != 0) {
            this.licitacionForm.get("nombre_contrato").disable();
            this.licitacionForm.get("descripcion").disable();
            this.licitacionForm.get("monto_solpe").disable();
            this.licitacionForm.get("nro_contrato").disable();
            this.licitacionForm.get("fecha_publicacion").disable();
            this.licitacionForm.updateValueAndValidity();
          }
        }, 500);

        const solpeCorrelativo: number = JSON.parse(resp)[0]?.CORRELATIVO_SOLPE;
        this.solpeService.getSolpeByCorrelativo(solpeCorrelativo).subscribe(
          (resp) => {
            let response: any = resp;

            if (response.data?.length > 0) {
              this.montoSolpeOriginal = response.data[0].monto;
              this.monedaSolpeOriginal = response.data[0]?.MONEDA_SIMBOLO;
            }
          },
          (error) => {
            console.log(error);
          }
        );

        this.getMonedas();
        this.getGruposArchivos();
        this.getGrupos();
        this.getNotificacionesEnviadasComision();
        this.getDocumentosRequeridosOferente();
        this.getSelectRolesParticipanteComision();
        this.getDocRequeridosOferenteSaved();
        // traer el cronograma de la licitacion PDF
        this.cronogramaPdf();
        // traer los archivos de la licitacion PDF
        this.getArchivoLicitacionPdf(this.idLicitacion);
        // se valida la licitacion
        this.onValidarLicitacion();

        // traer a los participante de la comision que pueden firmar PDF
        this.licitacionService
          .getParticipantesComLicitacion(this.idLicitacion)
          .subscribe(
            (resp) => {
              if (JSON.parse(resp)) {
                this.partComisionFirmante = JSON.parse(resp).filter(
                  (x) =>
                    x.TIPO_PARTICIPANTE === 37 || x.TIPO_PARTICIPANTE === 39
                );
              }
            },
            (err) => {
              this.notifyService.showError(
                err.error.message,
                "Obtener comision"
              );
            }
          );
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cabecera licitación"
        );
      }
    );
  }

  deleteDocRequeridoOferente(correlativo: number) {
    this.licitacionService
      .postDeleteDocRequeridoOferenteActa({ correlativo: correlativo })
      .subscribe((resp) => {
        this.licitacionService
          .getSelectDocRequeridosOferenteSaved(this.idLicitacion)
          .subscribe((resp) => {
            this.notifyService.showWarning(
              "El documento requerido ha sido eliminado.",
              "Correcto"
            );
            this.docRequeridosOferenteToSave = JSON.parse(resp);

            if (this.docRequeridosOferenteToSave.length > 0) {
              this.isDocumentosValidoOferente = true;
            } else {
              this.isDocumentosValidoOferente = false;
            }

            this.onValidarLicitacion();
          });
      });
  }

  getDocRequeridosOferenteSaved() {
    this.licitacionService
      .getSelectDocRequeridosOferenteSaved(this.idLicitacion)
      .subscribe((resp) => {
        this.docRequeridosOferenteToSave = JSON.parse(resp);

        if (this.docRequeridosOferenteToSave.length > 0) {
          this.isDocumentosValidoOferente = true;
        } else {
          this.isDocumentosValidoOferente = false;
        }

        this.onValidarLicitacion();
      });
  }

  onAddDocRequeridosOferenteActa() {
    if (this.formDocRequeridosOferenteActa.invalid) {
      this.notifyService.showWarning(
        "Por favor, llene todos los campos obligatorios.",
        "Advertencia"
      );
      return;
    }

    const validateDocRequeridoOferenteExiste =
      this.docRequeridosOferenteToSave.filter(
        (doc) =>
          doc.correlativo_doc_requerido ==
          this.formDocRequeridosOferenteActa.value
            .docRequeridoOferenteCorrelativo
      );

    if (validateDocRequeridoOferenteExiste.length > 0) {
      this.notifyService.showWarning(
        "El documento requerido ya fue agregado",
        "Advertencia"
      );
      return;
    }

    const dataToSave = {
      correlativo_doc_requerido:
        this.formDocRequeridosOferenteActa.value
          .docRequeridoOferenteCorrelativo,
      idLicitacion: this.idLicitacion,
    };

    // insert directo aqui para evitar conflictos
    this.licitacionService
      .postCreateDocRequeridoOferenteActa(dataToSave)
      .subscribe((resp) => {
        this.licitacionService
          .getSelectDocRequeridosOferenteSaved(this.idLicitacion)
          .subscribe((resp) => {
            this.docRequeridosOferenteToSave = JSON.parse(resp);

            if (this.docRequeridosOferenteToSave.length > 0) {
              this.isDocumentosValidoOferente = true;
            } else {
              this.isDocumentosValidoOferente = false;
            }

            this.onValidarLicitacion();
          });
      });
  }

  resetFormDocRequeridosOferenteActa() {
    this.formDocRequeridosOferenteActa = new FormGroup({
      docRequeridoOferenteCorrelativo: new FormControl(
        null,
        Validators.required
      ),
    });
  }

  calcularPorcentajesFormularios() {
    let gruposPesoTotal = 0;
    let subGruposPesoTotal = 0;
    let objPesoXGrupos = [];
    let objPesoXSubGrupos = [];

    for (let index = 0; index < this.objGrupos.length; index++) {
      const element = this.objGrupos[index];
      gruposPesoTotal = gruposPesoTotal + this.objGrupos[index].peso;

      for (let i = 0; i < this.objGrupos[index].subgrupoLs.length; i++) {
        const subGrupoelement = this.objGrupos[index].subgrupoLs[i];
        subGruposPesoTotal = subGruposPesoTotal + subGrupoelement.peso;
        objPesoXSubGrupos.push({
          correlativo: subGrupoelement.correlativo,
          peso: subGrupoelement.peso,
        });
      }

      objPesoXGrupos.push({
        correlativo: element.correlativo,
        pesoXGrupo: this.objGrupos[index].peso,
        subGrupos: objPesoXSubGrupos,
        subGrupoPesoTotal: subGruposPesoTotal,
      });

      subGruposPesoTotal = 0;
      objPesoXSubGrupos = [];
    }

    //console.log(objPesoXGrupos);
    //console.log(gruposPesoTotal);

    this.gruposPesoTotal = gruposPesoTotal;
    this.pesoPermitidoGrupo = 100 - gruposPesoTotal;
    this.objPesosGruposYSubgrupos = objPesoXGrupos;
  }

  getGrupos() {
    this.criteriosServices.getGrupos(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;

        if (response.data?.length > 0) {
          this.objGrupos = response.data;
        }

        this.calcularPorcentajesFormularios();
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  validarExisteActaInicio(archivos: any[]): boolean {
    const existeActaInicio = archivos.filter(
      (grupo) => grupo.GRUPO_ARCHIVO == 1
    );

    if (existeActaInicio.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  validarExisteMatrizRiesgo(archivos: any[]): boolean {
    const existeMatriz = archivos.filter((grupo) => grupo.GRUPO_ARCHIVO == 7);

    if (existeMatriz.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  removeDuplicates(originalArray: any[], prop: string) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  validaArchivos(archivos: any[]): boolean {
    let valid: boolean = false;
    const existenArchivosObligatorios = this.gruposArchivos.filter(
      (grupo) => grupo.obligatorio == 1
    );
    let archivosObligatorios: any[] = [];

    if (existenArchivosObligatorios.length > 0) {
      for (let index = 0; index < existenArchivosObligatorios.length; index++) {
        const element = existenArchivosObligatorios[index];

        for (let index = 0; index < archivos.length; index++) {
          const archivo = archivos[index];

          if (archivo.GRUPO_ARCHIVO == element.correlativo) {
            archivosObligatorios.push(archivo);
          }
        }
      }

      archivosObligatorios = this.removeDuplicates(
        archivosObligatorios,
        "GRUPO_ARCHIVO"
      );

      if (archivosObligatorios.length == existenArchivosObligatorios.length) {
        valid = true;
      } else {
        valid = false;
      }
    } else {
      valid = true;
    }

    return valid;
  }

  getGruposArchivos() {
    this.licitacionService.getGruposArchivos().subscribe((resp) => {
      const data: any[] = JSON.parse(resp);

      if (data.length > 0) {
        this.gruposArchivos = data;
        this.gruposArchivosData.emit(this.gruposArchivos);
      }
    });
  }

  getMonedas() {
    this.licitacionService.getMonedasSolpeLicitacion().subscribe(
      (resp) => {
        this.monedas = JSON.parse(resp);
        //console.log(this.monedas);
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener Monedas para la licitacion"
        );
      }
    );
  }

  // onOptionSelected(event) {
  //   this.solpeSeleccionada = +event;

  //   const data: any = this.aSolpesSelect.find((solpe: any) => {
  //     return solpe.value == event;
  //   });

  //   this.licitacionForm = new FormGroup({
  //     solpe: new FormControl(data.codigo_solpe),
  //     nro_contrato: new FormControl(data.orden_de_compra),
  //     nombre_contrato: new FormControl(data.nombre_solpe),
  //     descripcion: new FormControl(data.descripcion),
  //     moneda_solpe: new FormControl(data.CODIGO_MONEDA),
  //     monto_solpe: new FormControl(data.monto),
  //     fecha_publicacion: new FormControl(null),
  //   });

  //   this.montoSolpeOriginal = data.monto;
  //   this.monedaSolpeOriginal = data.MONEDA_SIMBOLO;
  // }

  onUpdateLicitacion() {
    if (this.licitacionForm.value.monto_solpe > this.montoSolpeOriginal) {
      this.notifyService.showWarning(
        "El monto de la licitación no puede ser mayor al monto original de la SOLPE",
        "Monto de la solpe"
      );
      return;
    }

    if (this.idLicitacion) {
      const putData: {
        in_licitacion: number;
        in_solpe: number;
        in_cont_correlativo: number;
        in_cont_nombre: string;
        in_usuario: string;
        in_descripcion: string;
        in_moneda_solpe: number;
        in_monto_solpe: number;
        in_fecha_publicacion: string;
      } = {
        in_licitacion: 0,
        in_solpe: 0,
        in_cont_correlativo: 0,
        in_cont_nombre: "",
        in_usuario: "",
        in_descripcion: "",
        in_moneda_solpe: 0,
        in_monto_solpe: 0,
        in_fecha_publicacion: null,
      };

      putData.in_licitacion = this.idLicitacion;
      putData.in_solpe = this.licitacionForm.value.solpe;
      //putData.corr_solpe = this.solpeSeleccionada
      putData.in_cont_correlativo = this.licitacionForm.value.nro_contrato;
      putData.in_cont_nombre = this.licitacionForm.value.nombre_contrato;
      putData.in_descripcion = this.licitacionForm.value.descripcion;
      putData.in_moneda_solpe = this.licitacionForm.value.moneda_solpe;
      putData.in_monto_solpe = this.licitacionForm.value.monto_solpe;
      putData.in_fecha_publicacion =
        this.licitacionForm.value.fecha_publicacion;

      //fecha_publicacion

      if (
        putData.in_licitacion !== 0 &&
        putData.in_solpe &&
        putData.in_cont_correlativo &&
        putData.in_cont_nombre &&
        putData.in_descripcion &&
        putData.in_monto_solpe !== 0 &&
        putData.in_fecha_publicacion !== "Invalid date"
      ) {
        this.licitacionService.putLicitacion(putData).subscribe(
          (resp) => {
            if (resp) {
            }

            //validar datos generales
            //this.dataLicitacion.putData.in_monto_solpe
            this.dataLicitacion.MONTO_SOLPE = putData.in_monto_solpe;

            if (
              this.idLicitacion !== 0 &&
              this.licitacionForm.value.solpe !== "" &&
              this.licitacionForm.value.nro_contrato !== "" &&
              this.licitacionForm.value.nombre_contrato !== "" &&
              this.licitacionForm.value.descripcion !== "" &&
              this.licitacionForm.value.moneda_solpe != 0 &&
              this.licitacionForm.value.monto_solpe !== 0 &&
              this.licitacionForm.value.fecha_publicacion !== "Invalid date"
            ) {
              this.isDatosBasicosValidos = true;
            } else {
              this.isDatosBasicosValidos = false;
            }

            this.licitacionService
              .getHeaderLicitacionId(this.idLicitacion)
              .subscribe(
                (resp) => {
                  this.dataLicitacion = JSON.parse(resp)[0];

                  this.licitacionForm = new FormGroup({
                    solpe: new FormControl(
                      JSON.parse(resp)[0].SOLPE_CORRELATIVO
                    ),
                    nro_contrato: new FormControl({
                      value: JSON.parse(resp)[0]?.CONT_NUMERO,
                      disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
                    }),
                    nombre_contrato: new FormControl({
                      value: JSON.parse(resp)[0]?.CONT_NOMBRE,
                      disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
                    }),
                    descripcion: new FormControl({
                      value: JSON.parse(resp)[0]?.DESCRIPCION,
                      disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
                    }),
                    moneda_solpe: new FormControl(
                      JSON.parse(resp)[0]?.MONEDA_SOLPE
                    ),
                    monto_solpe: new FormControl({
                      value: JSON.parse(resp)[0]?.MONTO_SOLPE,
                      disabled: this.dataLicitacion?.ETAPA_CORRELATIVO != 0,
                    }),
                    fecha_publicacion: new FormControl(
                      JSON.parse(resp)[0]?.FECHA_PUBLICACION !== null
                        ? moment(JSON.parse(resp)[0]?.FECHA_PUBLICACION).format(
                            "YYYY-MM-DD"
                          )
                        : null
                    ),
                  });

                  if (this.dataLicitacion?.ETAPA_CORRELATIVO != 0) {
                    this.licitacionForm.get("nombre_contrato").disable();
                    this.licitacionForm.get("descripcion").disable();
                    this.licitacionForm.get("monto_solpe").disable();
                    this.licitacionForm.get("nro_contrato").disable();
                    this.licitacionForm.get("fecha_publicacion").disable();
                    this.licitacionForm.updateValueAndValidity();
                  }

                  this.onValidarLicitacion();

                  this.currentStep++;
                  this.notifyService.showSuccess(
                    "Licitación actualizada.",
                    "Actualizar licitación"
                  );
                },
                (err) => {
                  this.notifyService.showError(
                    err.error.message,
                    "Obtener cabecera licitación"
                  );
                }
              );
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Actualizar licitación"
            );
          }
        );
      } else {
        this.notifyService.showError(
          "Favor llene los campos obligatorios.",
          "Actualizar licitación"
        );
      }
    }
  }

  updateCriterios(criterios) {
    if (criterios?.length > 0) {
      this.objGrupos = criterios;
      this.calcularPorcentajesFormularios();

      if (this.objPesosGruposYSubgrupos.length > 0) {
        // valdiar los porcentajes de los capitullos
        if (this.gruposPesoTotal == 100) {
          // verificar porcentaje de subcapitulos
          let isSubcapitulosValidos: boolean = false;
          for (
            let index = 0;
            index < this.objPesosGruposYSubgrupos.length;
            index++
          ) {
            const element = this.objPesosGruposYSubgrupos[index];

            if (element.subGrupoPesoTotal != 100) {
              isSubcapitulosValidos = false;
              break;
            } else {
              isSubcapitulosValidos = true;
            }
          }

          if (isSubcapitulosValidos) {
            this.isCriteriosValido = true;
            this.currentStep++;
          } else {
            this.isCriteriosValido = false;
          }
        } else {
          this.isCriteriosValido = false;
        }
      } else {
        this.isCriteriosValido = false;
      }

      // this.isCriteriosValido = true;
      // this.currentStep++;
    } else {
      this.isCriteriosValido = false;
    }
  }
  updateItemizado(event: any[]) {
    console.log(event);

    if (event?.length > 1) {
      //this.onValidarPresupuesto()
      let totalitemizado: number = 0;
      //this.objItemizadoLs
      for (let y = 0; y < event?.length; y++) {
        for (let i = 0; i < event[y]?.itemizadoDetalleLs?.length; i++) {
          let precio =
            event[y]?.itemizadoDetalleLs[i]?.precio > 0
              ? event[y]?.itemizadoDetalleLs[i]?.precio
              : event[y]?.itemizadoDetalleLs[i]?.precio_unitario;
          // se verifica contra esta key por solo existe si hay un update
          if (
            event[y]?.itemizadoDetalleLs[i]?.hasOwnProperty("precio_unitario")
          ) {
            //this.currentStep = 7;
          }
          totalitemizado =
            totalitemizado + event[y]?.itemizadoDetalleLs[i]?.cantidad * precio;
        }
      }

      this.montoItemizado = totalitemizado;
      if (event?.length > 0) {
        this.isitemizadoValido = true;
      } else {
        this.isitemizadoValido = false;
      }

      //
    }
  }
  onEnviarInvitacion() {
    const putData: {
      in_licitacion: number;
      in_etapa: number;
      in_usuario: string;
    } = {
      in_licitacion: this.idLicitacion,
      in_etapa: this.dataLicitacion.CORRELATIVO_ETAPA,
      in_usuario: "",
    };
    this.licitacionService.putEnviarInvitacionLic(putData).subscribe((resp) => {
      this.notifyService.showSuccess(JSON.parse(resp), "Envio invitación");
      this.router.navigate(["/licitaciones"]);
    });
  }

  onValidarLicitacion() {
    // validacion de formulario
    if (
      this.idLicitacion !== 0 &&
      this.licitacionForm.value.solpe !== "" &&
      this.licitacionForm.value.nro_contrato !== "" &&
      this.licitacionForm.value.nombre_contrato !== "" &&
      this.licitacionForm.value.descripcion !== "" &&
      this.licitacionForm.value.moneda_solpe != 0 &&
      this.licitacionForm.value.monto_solpe !== 0 &&
      this.licitacionForm.value.fecha_publicacion !== "Invalid date"
    ) {
      this.isDatosBasicosValidos = true;
    } else {
      this.isDatosBasicosValidos = false;
    }

    this.licitacionForm.valid;

    //this.montoSolpe = this.licitacionForm.value.monto_solpe ? this.licitacionForm.value.monto_solpe : 0;

    // validacion cronograma
    this.licitacionService
      .getCronogramaLicitacionId(this.idLicitacion)
      .subscribe(
        (resp) => {
          const cronograma = JSON.parse(resp);
          this.cronogramaPdf();

          for (let index = 0; index < cronograma.length; index++) {
            const element = cronograma[index];
            if (element.ACTIVO == "S") {
              if (element.FECHA_INICIO_ETAPA !== null) {
                this.isCronogramaValido = true;
              } else {
                this.isCronogramaValido = false;
                break;
              }
            }
          }

          // validacion de participantes oferentes
          this.licitacionService
            .getParticipantesLic(
              this.idLicitacion,
              this.dataLicitacion.CORRELATIVO_ETAPA
            )
            .subscribe((resp) => {
              const participantes = JSON.parse(resp);

              if (participantes.length > 0) {
                if (participantes.length >= 3) {
                  this.isParticipantesValido = true;
                } else {
                  this.isParticipantesValido = false;
                }
              } else {
                this.isParticipantesValido = false;
              }

              // validacion de participantes de la comision evaluadora
              this.licitacionService
                .getParticipantesComLicitacion(this.idLicitacion)
                .subscribe(
                  (resp) => {
                    const participantesCom = JSON.parse(resp);
                    this.isComisionValidoTecnica = false;
                    this.isComisionValidoEconomica = false;
                    this.isComisionValidoCoordinador = false;
                    this.isComisionValidoResponsable = false;
                    this.isComisionValidLiderComisionTecnica = false;

                    for (
                      let index = 0;
                      index < participantesCom.length;
                      index++
                    ) {
                      const element = participantesCom[index];
                      if (
                        element.TIPO_PARTICIPANTE == 7 &&
                        element.PUEDE_PARTICIPAR == 1
                      ) {
                        this.isComisionValidoTecnica = true;
                      }

                      if (
                        element.TIPO_PARTICIPANTE == 8 &&
                        element.PUEDE_PARTICIPAR == 1
                      ) {
                        this.isComisionValidoEconomica = true;
                      }

                      if (
                        element.TIPO_PARTICIPANTE == 36 &&
                        element.PUEDE_PARTICIPAR == 1
                      ) {
                        this.isComisionValidoCoordinador = true;
                      }

                      if (
                        element.TIPO_PARTICIPANTE == 37 &&
                        element.PUEDE_PARTICIPAR == 1
                      ) {
                        this.isComisionValidoResponsable = true;
                      }

                      if (
                        element.TIPO_PARTICIPANTE == 39 &&
                        element.PUEDE_PARTICIPAR == 1
                      ) {
                        this.isComisionValidLiderComisionTecnica = true;
                      }
                    }

                    // validacion de los criterios
                    this.criteriosServices
                      .getGrupos(this.idLicitacion)
                      .subscribe(
                        (resp) => {
                          let response: any = resp;

                          if (response.data?.length > 0) {
                            this.objGrupos = response.data;
                            this.calcularPorcentajesFormularios();

                            if (this.objPesosGruposYSubgrupos.length > 0) {
                              // valdiar los porcentajes de los capitullos
                              if (this.gruposPesoTotal == 100) {
                                // verificar porcentaje de subcapitulos
                                let isSubcapitulosValidos: boolean = false;
                                for (
                                  let index = 0;
                                  index < this.objPesosGruposYSubgrupos.length;
                                  index++
                                ) {
                                  const element =
                                    this.objPesosGruposYSubgrupos[index];

                                  if (element.subGrupoPesoTotal != 100) {
                                    isSubcapitulosValidos = false;
                                    break;
                                  } else {
                                    isSubcapitulosValidos = true;
                                  }
                                }

                                if (isSubcapitulosValidos) {
                                  this.isCriteriosValido = true;
                                } else {
                                  this.isCriteriosValido = false;
                                }
                              } else {
                                this.isCriteriosValido = false;
                              }
                            } else {
                              this.isCriteriosValido = false;
                            }
                          } else {
                            this.isCriteriosValido = false;
                          }

                          // validacion de itemizado
                          this.objItemizadoService
                            .getItemizadoParticipante(this.idLicitacion)
                            .subscribe(
                              (resp) => {
                                let response: any = resp;

                                let totalitemizado: number = 0;
                                //this.objItemizadoLs
                                for (
                                  let y = 0;
                                  y < response.data?.length;
                                  y++
                                ) {
                                  for (
                                    let i = 0;
                                    i <
                                    response.data[y]?.itemizadoDetalleLs
                                      ?.length;
                                    i++
                                  ) {
                                    totalitemizado =
                                      totalitemizado +
                                      response.data[y]?.itemizadoDetalleLs[i]
                                        ?.cantidad *
                                        response.data[y]?.itemizadoDetalleLs[i]
                                          ?.precio_unitario;
                                  }
                                }

                                this.montoItemizado = totalitemizado;
                                //this.diferenciaMontos = (this.montoItemizado - this.montoSolpe);

                                if (response.data?.length > 0) {
                                  this.isitemizadoValido = true;
                                } else {
                                  this.isitemizadoValido = false;
                                }

                                // validacion de archivos
                                this.etapaService
                                  .getArchivoLicEtapaFull(this.idLicitacion, 0)
                                  .subscribe((resp) => {
                                    let archivosAdicionales: any[] =
                                      JSON.parse(resp);
                                    //let archivosValidos: boolean = false;

                                    if (archivosAdicionales.length > 0) {
                                      // for (let index = 0; index < archivosAdicionales.length; index++) {
                                      //   const element = archivosAdicionales[index];

                                      //   if (this.validaArchivos(element)) {
                                      //     archivosValidos = true;
                                      //   }else{
                                      //     archivosValidos = false;
                                      //   }
                                      // }

                                      //archivosValidos = this.validaArchivos(archivosAdicionales);
                                      this.isDocumentosValido =
                                        this.validaArchivos(
                                          archivosAdicionales
                                        );
                                      this.isActaValida =
                                        this.validarExisteActaInicio(
                                          archivosAdicionales
                                        );
                                      this.isMatrizRiesgoValida =
                                        this.validarExisteMatrizRiesgo(
                                          archivosAdicionales
                                        );
                                    } else {
                                      this.isDocumentosValido = false;
                                      this.isActaValida = false;
                                      this.isMatrizRiesgoValida = false;
                                    }

                                    if (
                                      this.docRequeridosOferenteToSave.length >
                                      0
                                    ) {
                                      this.isDocumentosValidoOferente = true;
                                    } else {
                                      this.isDocumentosValidoOferente = false;
                                    }

                                    const contArchivosBase =
                                      archivosAdicionales.length;
                                    //this.onValidateLicitacionCompletada();

                                    // validacion por parte del lider de la comision tecnica
                                    this.licitacionService
                                      .getHeaderLicitacionId(this.idLicitacion)
                                      .subscribe(
                                        (resp) => {
                                          const headerLicitacion: any[] =
                                            JSON.parse(resp);
                                          this.isValidacionLct =
                                            headerLicitacion[0]
                                              .VALIDACION_LCT === 0
                                              ? false
                                              : true;
                                          this.observacionLct =
                                            headerLicitacion[0]
                                              .OBSERVACION_LCT !== null
                                              ? headerLicitacion[0]
                                                  .OBSERVACION_LCT
                                              : "";

                                          if (
                                            this.isDatosBasicosValidos ===
                                              true &&
                                            this.isCronogramaValido === true &&
                                            this.isParticipantesValido ===
                                              true &&
                                            this.isComisionValidoTecnica ===
                                              true &&
                                            this.isComisionValidoEconomica ===
                                              true &&
                                            this.isComisionValidoCoordinador ===
                                              true &&
                                            this.isComisionValidoResponsable ===
                                              true &&
                                            this
                                              .isComisionValidLiderComisionTecnica &&
                                            this.isCriteriosValido === true &&
                                            this.isitemizadoValido &&
                                            this.isDocumentosValido &&
                                            this.isDocumentosValidoOferente &&
                                            this.isMatrizRiesgoValida ===
                                              true &&
                                            this.isValidacionLct === true
                                          ) {
                                            this.islicitacionValida = true;
                                          } else {
                                            this.islicitacionValida = false;
                                          }
                                        },
                                        (err) => {
                                          this.notifyService.showError(
                                            err.error.message,
                                            "Obtener cabecera licitación"
                                          );
                                        }
                                      );
                                  });
                              },
                              (error) => {
                                console.log(error);
                              },
                              () => {}
                            );
                        },
                        (error) => {
                          console.log(error);
                        },
                        () => {}
                      );
                  },
                  (err) => {
                    this.notifyService.showError(
                      err.error.message,
                      "Obtener Participantes Comisión Licitación"
                    );
                  }
                );
            });
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cronograma licitación"
          );
        }
      );
  }
  onValidarOferente() {
    // validacion de participantes oferentes
    this.licitacionService
      .getParticipantesLic(
        this.idLicitacion,
        this.dataLicitacion.CORRELATIVO_ETAPA
      )
      .subscribe((resp) => {
        const participantes = JSON.parse(resp);

        if (participantes.length > 0) {
          if (participantes.length >= 3) {
            this.isParticipantesValido = true;
          } else {
            this.isParticipantesValido = false;
          }
        } else {
          this.isParticipantesValido = false;
        }
      });
  }
  onValidarComision() {
    // validacion de participantes de la comision evaluadora
    this.licitacionService
      .getParticipantesComLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          const participantesCom = JSON.parse(resp);
          this.isComisionValidoTecnica = false;
          this.isComisionValidoEconomica = false;
          this.isComisionValidoCoordinador = false;
          this.isComisionValidoResponsable = false;
          this.isComisionValidLiderComisionTecnica = false;

          for (let index = 0; index < participantesCom.length; index++) {
            const element = participantesCom[index];
            if (
              element.TIPO_PARTICIPANTE == 7 &&
              element.PUEDE_PARTICIPAR == 1
            ) {
              this.isComisionValidoTecnica = true;
            }

            if (
              element.TIPO_PARTICIPANTE == 8 &&
              element.PUEDE_PARTICIPAR == 1
            ) {
              this.isComisionValidoEconomica = true;
            }

            if (
              element.TIPO_PARTICIPANTE == 36 &&
              element.PUEDE_PARTICIPAR == 1
            ) {
              this.isComisionValidoCoordinador = true;
            }

            if (
              element.TIPO_PARTICIPANTE == 37 &&
              element.PUEDE_PARTICIPAR == 1
            ) {
              this.isComisionValidoResponsable = true;
            }

            if (
              element.TIPO_PARTICIPANTE == 39 &&
              element.PUEDE_PARTICIPAR == 1
            ) {
              this.isComisionValidLiderComisionTecnica = true;
            }
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener Participantes Comisión Licitación"
          );
        }
      );
  }
  onValidarCronograma() {
    this.licitacionService
      .getCronogramaLicitacionId(this.idLicitacion)
      .subscribe(
        (resp) => {
          const cronograma = JSON.parse(resp);
          this.cronogramaPdf();

          for (let index = 0; index < cronograma.length; index++) {
            const element = cronograma[index];
            if (element.ACTIVO == "S") {
              if (element.FECHA_INICIO_ETAPA !== null) {
                this.isCronogramaValido = true;
              } else {
                this.isCronogramaValido = false;
                break;
              }
            }
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cronograma licitación"
          );
        }
      );
  }
  onValidarEvaTecnica() {
    // validacion de los criterios
    this.criteriosServices.getGrupos(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;

        if (response.data?.length > 0) {
          this.objGrupos = response.data;
          this.calcularPorcentajesFormularios();

          if (this.objPesosGruposYSubgrupos.length > 0) {
            // valdiar los porcentajes de los capitullos
            if (this.gruposPesoTotal == 100) {
              // verificar porcentaje de subcapitulos
              let isSubcapitulosValidos: boolean = false;
              for (
                let index = 0;
                index < this.objPesosGruposYSubgrupos.length;
                index++
              ) {
                const element = this.objPesosGruposYSubgrupos[index];

                if (element.subGrupoPesoTotal != 100) {
                  isSubcapitulosValidos = false;
                  break;
                } else {
                  isSubcapitulosValidos = true;
                }
              }

              if (isSubcapitulosValidos) {
                this.isCriteriosValido = true;
              } else {
                this.isCriteriosValido = false;
              }
            } else {
              this.isCriteriosValido = false;
            }
          } else {
            this.isCriteriosValido = false;
          }
        } else {
          this.isCriteriosValido = false;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  onValidarPresupuesto() {
    // validacion de itemizado
    this.objItemizadoService
      .getItemizadoParticipante(this.idLicitacion)
      .subscribe(
        (resp) => {
          let response: any = resp;

          let totalitemizado: number = 0;
          //this.objItemizadoLs
          for (let y = 0; y < response.data?.length; y++) {
            for (
              let i = 0;
              i < response.data[y]?.itemizadoDetalleLs?.length;
              i++
            ) {
              totalitemizado =
                totalitemizado +
                response.data[y]?.itemizadoDetalleLs[i]?.cantidad *
                  response.data[y]?.itemizadoDetalleLs[i]?.precio_unitario;
            }
          }

          this.montoItemizado = totalitemizado;
          //this.diferenciaMontos = (this.montoItemizado - this.montoSolpe);

          if (response.data?.length > 0) {
            this.isitemizadoValido = true;
          } else {
            this.isitemizadoValido = false;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
  }
  onValidarDocumentos() {
    // validacion de archivos
    this.etapaService
      .getArchivoLicEtapaFull(this.idLicitacion, 0)
      .subscribe((resp) => {
        let archivosAdicionales: any[] = JSON.parse(resp);
        //let archivosValidos: boolean = false;

        if (archivosAdicionales.length > 0) {
          // for (let index = 0; index < archivosAdicionales.length; index++) {
          //   const element = archivosAdicionales[index];

          //   if (this.validaArchivos(element)) {
          //     archivosValidos = true;
          //   }else{
          //     archivosValidos = false;
          //   }
          // }

          this.isDocumentosValido = this.validaArchivos(archivosAdicionales);
          this.isActaValida = this.validarExisteActaInicio(archivosAdicionales);
          this.isMatrizRiesgoValida =
            this.validarExisteMatrizRiesgo(archivosAdicionales);
        } else {
          this.isDocumentosValido = false;
          this.isActaValida = false;
          this.isMatrizRiesgoValida = false;
        }

        const contArchivosBase = archivosAdicionales.length;
        //this.onValidateLicitacionCompletada();
        /*       if (this.isDatosBasicosValidos === true && this.isCronogramaValido === true && this.isParticipantesValido === true && this.isComisionValidoTecnica === true && this.isComisionValidoEconomica === true && this.isComisionValidoCoordinador === true && this.isComisionValidoResponsable === true && this.isCriteriosValido === true && this.isitemizadoValido && this.isDocumentosValido) {
        this.islicitacionValida = true;
      } else {
        this.islicitacionValida = false;
      } */
      });
  }

  onUpdateCronograma(data: any[]) {
    //const cronograma = JSON.parse(resp);

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.ACTIVO == "S") {
        if (element.FECHA_INICIO_ETAPA !== null) {
          this.isCronogramaValido = true;
        } else {
          this.isCronogramaValido = false;
          break;
        }
      }
    }

    if (
      this.isDatosBasicosValidos === true &&
      this.isCronogramaValido === true &&
      this.isParticipantesValido === true &&
      this.isComisionValidoTecnica === true &&
      this.isComisionValidoEconomica === true &&
      this.isComisionValidoCoordinador === true &&
      this.isComisionValidoResponsable === true &&
      this.isComisionValidLiderComisionTecnica &&
      this.isCriteriosValido === true &&
      this.isitemizadoValido &&
      this.isDocumentosValido &&
      this.isDocumentosValidoOferente &&
      this.isMatrizRiesgoValida === true &&
      this.isValidacionLct === true
    ) {
      this.islicitacionValida = true;
    } else {
      this.islicitacionValida = false;
    }

    this.cronogramaPdf();
  }
  onUpdateArchivos(data: any[]) {
    //let archivosAdicionales: any[] = JSON.parse(resp);
    if (data.length > 0) {
      //let archivosValidos: boolean = false;

      // for (let index = 0; index < data.length; index++) {
      //   const element = data[index];

      //   if (this.validaArchivos(element)) {
      //     archivosValidos = true;
      //   }else{
      //     archivosValidos = false;
      //   }
      // }

      this.isDocumentosValido = this.validaArchivos(data);
      this.isActaValida = this.validarExisteActaInicio(data);
      this.isMatrizRiesgoValida = this.validarExisteMatrizRiesgo(data);
    } else {
      this.isDocumentosValido = false;
      this.isActaValida = false;
      this.isMatrizRiesgoValida = false;
    }

    if (
      this.isDatosBasicosValidos === true &&
      this.isCronogramaValido === true &&
      this.isParticipantesValido === true &&
      this.isComisionValidoTecnica === true &&
      this.isComisionValidoEconomica === true &&
      this.isComisionValidoCoordinador === true &&
      this.isComisionValidoResponsable === true &&
      this.isComisionValidLiderComisionTecnica &&
      this.isCriteriosValido === true &&
      this.isitemizadoValido &&
      this.isDocumentosValido &&
      this.isDocumentosValidoOferente &&
      this.isMatrizRiesgoValida === true &&
      this.isValidacionLct === true
    ) {
      this.islicitacionValida = true;
    } else {
      this.islicitacionValida = false;
    }

    this.getArchivoLicitacionPdf(this.idLicitacion);
  }
  onUpdateOferentes(data: any[]) {
    if (data.length > 0) {
      if (data.length >= 3) {
        this.isParticipantesValido = true;
      } else {
        this.isParticipantesValido = false;
      }
    } else {
      this.isParticipantesValido = false;
    }

    if (
      this.isDatosBasicosValidos === true &&
      this.isCronogramaValido === true &&
      this.isParticipantesValido === true &&
      this.isComisionValidoTecnica === true &&
      this.isComisionValidoEconomica === true &&
      this.isComisionValidoCoordinador === true &&
      this.isComisionValidoResponsable === true &&
      this.isComisionValidLiderComisionTecnica &&
      this.isCriteriosValido === true &&
      this.isitemizadoValido &&
      this.isDocumentosValido &&
      this.isDocumentosValidoOferente &&
      this.isMatrizRiesgoValida === true &&
      this.isValidacionLct === true
    ) {
      this.islicitacionValida = true;
    } else {
      this.islicitacionValida = false;
    }
  }
  onUpdateParticipante(data: any[]) {
    //PUEDE_PARTICIPAR
    //const participantesCom = JSON.parse(resp);
    this.isComisionValidoTecnica = false;
    this.isComisionValidoEconomica = false;
    this.isComisionValidoCoordinador = false;
    this.isComisionValidoResponsable = false;
    this.isComisionValidLiderComisionTecnica = false;

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.TIPO_PARTICIPANTE == 7 && element.PUEDE_PARTICIPAR == 1) {
        this.isComisionValidoTecnica = true;
      }

      if (element.TIPO_PARTICIPANTE == 8 && element.PUEDE_PARTICIPAR == 1) {
        this.isComisionValidoEconomica = true;
      }

      if (element.TIPO_PARTICIPANTE == 36 && element.PUEDE_PARTICIPAR == 1) {
        this.isComisionValidoCoordinador = true;
      }

      if (element.TIPO_PARTICIPANTE == 37 && element.PUEDE_PARTICIPAR == 1) {
        this.isComisionValidoResponsable = true;
      }

      if (element.TIPO_PARTICIPANTE == 39 && element.PUEDE_PARTICIPAR == 1) {
        this.isComisionValidLiderComisionTecnica = true;
      }
    }

    if (
      this.isDatosBasicosValidos === true &&
      this.isCronogramaValido === true &&
      this.isParticipantesValido === true &&
      this.isComisionValidoTecnica === true &&
      this.isComisionValidoEconomica === true &&
      this.isComisionValidoCoordinador === true &&
      this.isComisionValidoResponsable === true &&
      this.isComisionValidLiderComisionTecnica &&
      this.isCriteriosValido === true &&
      this.isitemizadoValido &&
      this.isDocumentosValido &&
      this.isDocumentosValidoOferente &&
      this.isMatrizRiesgoValida === true &&
      this.isValidacionLct === true
    ) {
      this.islicitacionValida = true;
    } else {
      this.islicitacionValida = false;
    }

    this.licitacionService
      .getParticipantesComLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          if (JSON.parse(resp)) {
            this.partComisionFirmante = JSON.parse(resp).filter(
              (x) => x.TIPO_PARTICIPANTE === 37 || x.TIPO_PARTICIPANTE === 39
            );
          }
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Obtener comision");
        }
      );

    this.getNotificacionesEnviadasComision();
  }

  sendNotificacionComision() {
    this.licitacionService
      .postEnviaNotificacionComisionEvaluadora({
        licitacion_correlativo: this.idLicitacion,
      })
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Notificación enviada",
            "Se le ha enviado la notificacion a la comisión evaluadora"
          );
          this.getNotificacionesEnviadasComision();
        },
        (error) => {
          this.notifyService.showError(
            "Error al enviar",
            "Ocurrio un error al enviar notificación"
          );
          console.log(error);
        },
        () => {
          // nada
        }
      );
  }

  getNotificacionesEnviadasComision() {
    this.licitacionService
      .getNotificacionesComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          const notificaciones: any[] = JSON.parse(resp);

          if (notificaciones.length > 0) {
            this.isNotificaicioneEnviada = false;
          } else {
            this.isNotificaicioneEnviada = true;
          }
        },
        (error) => {
          this.notifyService.showError(
            "Error",
            "Ocurrio un error al comprobar las notificaciones enviadas."
          );
          console.log(error);
        },
        () => {
          // nada
        }
      );
  }

  getDocumentosRequeridosOferente() {
    this.licitacionService
      .getSelectDocumentoRequeridoOferenteActa()
      .subscribe((resp) => {
        const documentosRequeridosOferente: any[] = JSON.parse(resp);
        this.docRequeridosOferenteActa = documentosRequeridosOferente;
      });
  }

  deleteLicitacion() {
    this.licitacionService
      .postDeleteLicitacion({ idLicitacion: this.idLicitacion })
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Se elimino la licitación correctamente.",
            "Eliminar licitación"
          );
          this.closeEliminarLicitacion.nativeElement.click();
          this.router.navigate(["/licitaciones"]);
        },
        (error) => {
          this.notifyService.showError(
            error.error.message,
            "Error al eliminar licitación"
          );
        }
      );
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  validarLicitacionLct() {
    // agregar solo la variable de observacion para el caso de rechazo o desacuerdo con la los datos de la licitacion
    if (this.validacionLct === 0) {
      if (this.observacionLct === "") {
        this.notifyService.showError(
          "Por favor, ingrese la observación",
          "Validar licitación"
        );
        return;
      }
    }

    const postData = {
      validacion: this.validacionLct,
      observacion: this.observacionLct,
      idLicitacion: this.idLicitacion,
    };

    this.licitacionService.postUpdateValidacionLct(postData).subscribe(
      (resp) => {
        this.notifyService.showSuccess(
          "Se valido la licitación correctamente.",
          "Validar licitación"
        );
        this.onValidarLicitacion();
        this.closeValidarLicitacion.nativeElement.click();
      },
      (error) => {
        this.notifyService.showError(
          error.error.message,
          "Error al validar licitación"
        );
      }
    );
  }

  getGenerateNotificacionRevisionLctByResponsable() {
    this.licitacionService
      .getGenerateNotificacionRevisionLctByResponsable(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Notificación enviada al lider de comisión tecnica.",
            "Validar licitación"
          );
          //this.onValidarLicitacion();
        },
        (error) => {
          this.notifyService.showError(
            "Error",
            "Ocurrio un error al comprobar las notificaciones enviadas."
          );
        }
      );
  }

  async onDescargarPDF() {
    this.downloadingPdf = true;
    this.pdfClassElement = "d-block";
    const doc = new PDF({ format: "A2", unit: "mm" }, [
      "",
      "Cronograma",
      "Archivos",
      "Matriz de Categorización",
      "Aprobaciones firmadas",
    ]);

    doc.addLogo();
    doc.addTitle(`Licitacion ${this.dataLicitacion.CONT_NOMBRE}`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 100;

    doc.identText(
      "Descripción",
      this.dataLicitacion.DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Licitación",
      this.idLicitacion.toString(),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Solpe",
      this.dataLicitacion?.SOLPE_CORRELATIVO,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Estado",
      this.dataLicitacion?.ETAPA_DESC,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha",
      this.dataLicitacion?.FECHA_CREACION
        ? moment(this.dataLicitacion?.FECHA_CREACION, "DD-MM-YYYY").format(
            "DD/MM/YYYY"
          )
        : "",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Moneda",
      this.dataLicitacion?.DESCRIPCION_MONEDA_SOLPE || "Peso Chileno",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Monto",
      formatCurrency(this.dataLicitacion?.MONTO_SOLPE || ""),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.increasePageY(10);

    doc.addTitle("Participantes");

    doc.addTableHtml("#table_participantes");

    doc.increasePageY(10);

    doc.addTitle("Comisiones");

    doc.addTableHtml("#table_comisiones");

    doc.increasePageY(10);

    // //SEGUNDA PAGINA

    doc.iSection = 2;
    doc.addNewPage();

    doc.addTableHtml("#table_cronograma");

    // //TERCERA PAGINA

    doc.iSection = 3;
    doc.addNewPage();

    doc.addTableHtml("#table_archivos");

    // //CUARTA PAGINA

    // doc.iSection = 4;
    // doc.addNewPage();

    // doc.addTableHtml('#table_correos');

    // //QUINTA PAGINA

    doc.iSection = 4;
    doc.addNewPage();

    const archivosMatrizRiesgo: any[] = this.archivosLicitacion.filter(
      (x) => x.grupo_archivo === 7
    );
    const archivosMatrizRiesgoOrdenados: any[] = archivosMatrizRiesgo.sort(
      (a, b) => a.arch_correlativo - b.arch_correlativo
    );

    if (archivosMatrizRiesgo.length > 0) {
      const urlMatriz: string = this.fileService.getUrlFile(
        archivosMatrizRiesgoOrdenados[archivosMatrizRiesgo.length - 1]
          .NOMBRE_ARCHIVO
      );
      doc.addImage(urlMatriz, 27, 50, 280, 240);
    }

    // //SEXTA PAGINA

    doc.iSection = 5;
    doc.addNewPage();

    doc.increasePageY(10);

    doc.text(
      "Los abajo firmantes aprueban el acta de inicio para esta licitación:",
      doc.iMargin,
      doc.nPageYAxi
    );
    doc.increasePageY(20);

    for (let index = 0; index < this.partComisionFirmante.length; index++) {
      const element = this.partComisionFirmante[index];
      doc.addSign(`${element.COMISION} (${element.PART_NOMBRE})`);
      doc.increasePageY(20);
    }

    doc.addFooter();
    doc.save(`licitacion_${this.idLicitacion}.pdf`);
    this.downloadingPdf = false;
    this.pdfClassElement = "d-none";
  }

  private getArchivoLicitacionPdf(idLicitacion: number) {
    if (idLicitacion) {
      this.licitacionService.getArchivosLicitacion(this.idLicitacion).subscribe(
        (resp) => {
          this.archivosLicitacion = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener archivos licitación"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Falta el id de la licitación",
        "Obtener archivos licitación"
      );
    }
  }

  cronogramaPdf() {
    this.licitacionService.getCronogramaLicView(this.idLicitacion).subscribe(
      (resp) => {
        this.cronograma = JSON.parse(resp).sort((a, b) => a.ORDEN - b.ORDEN);
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cronograma licitación"
        );
      }
    );
  }

  // onhChangeMontoSolpe(){
  //   if(this.licitacionForm.value.monto_solpe > 0){
  //     this.isSolicitarFechaPublicacion = true;
  //   }else{
  //     this.isSolicitarFechaPublicacion = false;
  //   }
  // }
}
