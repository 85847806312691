import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EtapasService } from '../services/etapas.licitacion.service';
import { LicitacionService } from '../services/licitacion.service';
import { NotificationService } from '../services/notification.service';
import { FileService } from '../services/file.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-view-nego-readonly',
  templateUrl: './view-nego-readonly.component.html',
  styleUrls: ['./view-nego-readonly.component.css']
})
export class ViewNegoReadonlyComponent implements OnInit {
  idLicitacion:number;
  idParticipante:number;
  idEtapa:number;
  idEtapaLic:number;
  nombreEtapa:string;
  participantes:[];
  tipo_consultas:[];
  isDisable:boolean = true;
  archivos64 : Blob;
  filename: string;
  type: string;
  negociacion:[];
  idNegociacion: number;
  idArchivo: number;
  constructor(private route: ActivatedRoute,private etapaService: EtapasService, private licitacionService: LicitacionService, private notifyService : NotificationService, private fileService: FileService) { }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }
        this.getNegociaciones(this.idLicitacion, this.idEtapa);
    });
  }

  private getNegociaciones(idLic: number, idEtapa: number){
    this.licitacionService.getNegociacionesLic(idLic, idEtapa).subscribe(resp=>{
      this.negociacion = JSON.parse(resp);
    });
  }

  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }

}
