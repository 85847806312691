import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-print-button',
  templateUrl: './view-print-button.component.html',
  styleUrls: ['./view-print-button.component.css']
})

export class ViewPrintButtonComponent {

  @Input() printComponentId: string = 'printComponent';
  @Input() cssFile: string | string[];
  @Input() title: string;
  @Input() variant: string = 'primary';
  @Input() breakInside: string = 'avoid';

  createCssImports(){
    if(typeof this.cssFile === 'string') return this.createCssImport(this.cssFile);
    return this.cssFile.map((cssFile) => this.createCssImport(cssFile)).join('');
  }

  createCssImport(cssFile: string){
    return `<link href="${cssFile}" rel="stylesheet" />`;
  }

  onImprimir(){
    const printComponent = document.getElementById(this.printComponentId).cloneNode(true) as HTMLElement;
    const inputs = printComponent.querySelectorAll('input,select,textarea') as NodeListOf<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>;
    for (let i = 0; i < inputs.length; i++) {
      if(['INPUT', 'SELECT'].includes(inputs[i].nodeName)) inputs[i].setAttribute('value',inputs[i].value);
      if(inputs[i].nodeName === 'TEXTAREA') inputs[i].innerHTML = inputs[i].value;
    }
    const WindowPrt = window.open('');
    WindowPrt.document.open();
    WindowPrt.document.write(`
      <html>
        <head>
          <title>${this.title}</title>
          <link href="/assets/css/default/app.min.css" rel="stylesheet" />
          <link href="/assets/css/styles.css" rel="stylesheet" />
          ${this.createCssImports()}
          <style>
            .content {
              margin-left: 0 !important;
              padding: 0 !important;
            }
            .table-main {
              padding: 0 !important;
            }
            body {
              background-color: white !important;
            }
            .panel-body {
              padding: 0 !important;
            }
            div {
              break-inside: ${this.breakInside};
            }
          </style>
        </head>
        <body>${printComponent.outerHTML}</body>
      </html>
    `);
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(function() {
      WindowPrt.print();
      setTimeout(function() {
        WindowPrt.close();
      }, 10);
    }, 50);
  }
}