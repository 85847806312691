import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

export class ICurrencies {
  id: number;
  description: string;
  ISOCode: string;
  Simbol: string;
  Decimal: number;
  ExchangeRate: number;
  ExchageRate: number;
  Principal: boolean;
  TimoCode: number;
  decimalPipe: string;
}

export class IActions
{
  accion_correlativo: number;
  nombre: string;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  public rolObservable: BehaviorSubject<string> = new BehaviorSubject(JSON.parse(localStorage.getItem('userData')) === null ? '' : JSON.parse(localStorage.getItem('userData')).rolId);
  public Actions: IActions[];

  set setRol(inData: string) {
    this.rolObservable.next(inData);
  }

  get getRol(): string {
    return this.rolObservable.value;
  }

  validationRol(): Observable<string> {
    return this.rolObservable.asObservable();
  }
  existeAccion(accion: number) {
    //return (this.Actions.includes(accion));

    if(this.Actions.some(a => a.accion_correlativo === accion))
    {
      return true
    }
    else
    {
      return false
    }
  }
  /* rolSubscription(): Subscription
  {
    return this.validationRol().subscribe((rest)=>{

    })
  } */

  constructor(private http: HttpClient) { 
    this.Actions = [];

    if(JSON.parse(localStorage.getItem('userActions')))
    {
      this.Actions = JSON.parse(localStorage.getItem('userActions')) as IActions[];
    }
    //this.rolSubcription
    /* let sub = this.validationRol.subscribe((value) => {

    }) */
  }


}
