<a class="floating-icon" href="#modalChat" 
(click)="getMessages()"
data-target="#modalChat" data-toggle="modal">
    <i class="fas fa-comment-alt"></i>
</a>
<div class="modal fade bd-example-modal-lg" id="modalChat" tabindex="-1" role="dialog" aria-labelledby="modalChat" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalComision">Licitacion {{idLicitacion}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p3-rem pt-2">
            <div class="row" *ngIf="messages?.length == 0">
                <div class="col-12">
                    <p class="text-muted text-center">Sin mensajes</p>
                </div>
            </div>
            <div class="row" >
                <ng-container *ngFor="let message of messages; let i = index">
                    <div class="col-12">
                        <div *ngIf="message.usuario_correlativo !== messages[i - 1]?.usuario_correlativo" class="d-flex-inline align-center margin-rem mt-4 ml-0">
                            <div class="round-name">
                                {{message.usuario.slice(0, 2)}}
                            </div>
                            <div class="nombre">
                                <p class="mb-0">{{message.usuario}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" >
                        <div [ngClass]="message.usuario_correlativo == userId ? 'mis-mensajes' : 'mensajes' ">
                            <p class="mb-0">{{message.mensaje}}</p>
                            <span class="text-muted">{{message.fecha_creacion | date:'HH:mm dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row mt-2">
                <div class="col-12 d-flex">
                    <input class="form-control" type="text" placeholder="escriba su mensaje aqui..." [formControl]="inputMenssage">
                    <button class="btn btn-primary ml-2" (click)="sendMessage()">Enviar</button>
                </div>       
            </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        </div> -->
      </div>
    </div>
</div>