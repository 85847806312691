<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn btn-primary" routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Consultas de la licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-12 col-md-6">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Consultas de la licitación</h4>
                </div>
                <div class="panel-body">
                    <table id="data-table-default" class="table table-striped table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Participante</th>
                                <th class="text-nowrap">Nro. Consultas</th>
                                <th class="text-nowrap">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of consultas_licitacion">
                                <td class="text-break">{{item.PART_DESCRIP}}</td>
                                <td>{{item.CANT_CONS}}</td>
                                <td>
                                    <button type="button" class="btn btn-primary" (click)="onVerConsultas(item.PART_CORRELATIVO, template)">Ver</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="panel-footer text-right">
                    <button *ngIf="cant_pregunta > 0" class="btn btn-primary" (click)="onExport()">Descargar Preguntas</button>
                </div>
            </div>
        </div>
    </div>
    <chat [idLicitacion]="idLicitacion"></chat>
    <ng-template #template>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Consultas postulante </h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                <thead>
                    <tr>
                        <th class="text-nowrap">Tipo consulta</th>
                        <th class="text-wrap">Consulta</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd gradeX" *ngFor="let item of consultas_participantes">
                        <td>{{item.DESC_TIPO}}</td>
                        <td class="text-break">{{item.DESC_CONSULTA}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modalRef.hide()">Cerrar</button>
        </div>
    </ng-template>
</div>
<!-- end #content -->