import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { IOnAgregarParticipanteCom } from "./licitacion.types";
import { NgModel } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class LicitacionService {
  private header;
  private urlService: string = environment.apiUrl;
  constructor(private http: HttpClient) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }

  postLicitacion(postData: { in_usuario: string }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(this.urlService + "licitacion/postLicitacion", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let licitacion = [];
          for (const item in resp["licitacion"]) {
            licitacion.push({ ...resp["licitacion"][item] });
          }
          return JSON.stringify(licitacion);
        })
      );
  }

  postArchivosLicitacion(postData: {
    in_det_etapa_lic: number;
    files: Blob;
    fileName: string;
    type: string;
    in_usuario: string;
    in_part_correlativo?: number;
    in_domi_correlativo_tiar?: number;
    publico: number;
    descripcion_input_archivo: string;
    idLicitacion: number;
    idEtapaLicitacion: number;
    correlativoGrupoArchivo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;

    return this.http
      .post(this.urlService + "licitacion/postArchivosLicitacion", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let archivos = [];
          for (const item in resp["archivos"]) {
            archivos.push({ ...resp["archivos"][item] });
            archivos.push({ nombre_archivo: resp["nombre_archivo"] });
          }
          return JSON.stringify(archivos);
        })
      );
  }

  postCalcularFechaEtapaLic(postData: {
    in_cant_horas: number;
    in_fecha: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(
        this.urlService + "licitacion/postCalcularFechaEtapaLic",
        postData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let fecha = [];
          for (const item in resp["fecha"]) {
            fecha.push({ ...resp["fecha"][item] });
          }
          return JSON.stringify(fecha);
        })
      );
  }

  postPostulanteLic(postData: {
    in_licitacion: number;
    in_etapa: number;
    in_part_correlativo: number;
    in_usuario: string;
    in_activo: string;
    in_motivo_descalificacion: string;
    in_motivo_reuncia_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .post(this.urlService + "licitacion/postPostulanteLic", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  postEtapaLicitacionId(postData: {
    in_licitacion: number;
    in_usuario: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .post(this.urlService + "licitacion/postEtapaLicitacionId", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let etapas_licitacion = [];
          for (const item in resp["etapas_licitacion"]) {
            etapas_licitacion.push({ ...resp["etapas_licitacion"][item] });
          }
          return JSON.stringify(etapas_licitacion);
        })
      );
  }
  postConsultaLic(postData: {
    in_lic_correlativo: number;
    in_etli_correlativo: number;
    in_part_correlativo: number;
    in_usuario: string;
    in_domi_correlativo_tico: number;
    in_consulta: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .post(this.urlService + "licitacion/postConsultaLic", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }
  postAclaracion(postData: {
    in_lic_correlativo: number;
    in_etli_correlativo: number;
    in_part_correlativo: number;
    in_usuario: string;
    in_domi_correlativo_tico: number;
    in_consulta: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(this.urlService + "licitacion/postAclaracion", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let aclaracion = [];
          for (const item in resp["aclaracion"]) {
            aclaracion.push({ ...resp["aclaracion"][item] });
          }
          return JSON.stringify(aclaracion);
        })
      );
  }
  postNegociacion(postData: {
    in_lic_correlativo: number;
    in_etli_correlativo: number;
    in_part_correlativo: number;
    in_usuario: string;
    in_domi_correlativo_tico: number;
    in_consulta: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(this.urlService + "licitacion/postNegociacion", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let negociacion = [];
          for (const item in resp["negociacion"]) {
            negociacion.push({ ...resp["negociacion"][item] });
          }
          return JSON.stringify(negociacion);
        })
      );
  }

  putLicitacion(putData: {
    in_licitacion: number;
    in_solpe: number;
    in_cont_correlativo: number;
    in_cont_nombre: string;
    in_usuario: string;
    in_descripcion: string;
    in_moneda_solpe: number;
    in_monto_solpe: number;
    in_fecha_publicacion: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putLicitacion", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  putAclaracionArchivo(putData: {
    in_acdetlic_acl_correlativo: number;
    in_archetlic_acl_correlativo: number;
    in_archetlic_resp_correlativo: number;
    in_usuario: string;
    in_accion: string;
    idLicitacion: number;
    oferenteNombre: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putAclaracionArchivo", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }
  putNegociacionArchivo(putData: {
    in_acdetlic_neg_correlativo: number;
    in_archetlic_neg_correlativo: number;
    in_archetlic_resp_correlativo: number;
    in_usuario: string;
    in_accion: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putNegociacionArchivo", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  putCronogramaLic(putData: {
    in_licitacion: number;
    in_etapa_correl: number;
    in_usuario: string;
    in_activo: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putCronogramaLic", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  putEtapaLicitacion(putData: { in_licitacion: number; in_usuario: string }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putEtapaLicitacion", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }
  putEnviarInvitacionLic(putData: {
    in_licitacion: number;
    in_etapa: number;
    in_usuario: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putEnviarInvitacionLic", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  putFechaCronogramaLic(putData: {
    in_licitacion: number;
    in_etapa_correlativo: number;
    in_fecha_inicio: string;
    in_fecha_termino: string;
    in_hora: string;
    iv_usuario_creacion: number;
    in_version: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.iv_usuario_creacion = JSON.parse(
      localStorage.getItem("userData")
    ).userId;
    return this.http
      .put(this.urlService + "licitacion/putFechaCronogramaLic", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  putPartEtapaLic(putData: {
    in_licitacion: number;
    in_part_correlativo: number;
    in_etapa: number;
    in_usuario: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putPartEtapaLic", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  putVigenciaPartLic(putData: {
    in_licitacion: number;
    in_etapa: number;
    in_usuario: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putVigenciaPartLic", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }
  putRespuestaConsultaLicitacion(putData: {
    in_condetlic_correlativo: number;
    in_respuesta: string;
    in_usuario: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(
        this.urlService + "licitacion/putRespuestaConsultaLicitacion",
        putData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  getDocumentosBase() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getDocumentosBase", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let documentos = [];
          for (const item in resp["documentos"]) {
            documentos.push({ ...resp["documentos"][item] });
          }
          return JSON.stringify(documentos);
        })
      );
  }

  getParticipantes() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getParticipantes", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let participantes = [];
          for (const item in resp["participantes"]) {
            participantes.push({ ...resp["participantes"][item] });
          }
          return JSON.stringify(participantes);
        })
      );
  }

  getLicitaciones(iv_excluye_creacion: any) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService + "licitacion/getLicitaciones/" + iv_excluye_creacion,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let licitaciones = [];
          for (const item in resp["licitaciones"]) {
            licitaciones.push({ ...resp["licitaciones"][item] });
          }
          return JSON.stringify(licitaciones);
        })
      );
  }

  getLicitacionesGestor() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getLicitacionesGestor", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let licitaciones = [];
          for (const item in resp["licitaciones"]) {
            licitaciones.push({ ...resp["licitaciones"][item] });
          }
          return JSON.stringify(licitaciones);
        })
      );
  }

  getLicitacionesTipo(in_tipo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getLicitacionesTipo/" + in_tipo, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let licitaciones = [];
          for (const item in resp["licitaciones"]) {
            licitaciones.push({ ...resp["licitaciones"][item] });
          }
          return JSON.stringify(licitaciones);
        })
      );
  }
  getLicitacionesParticipante(in_part_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getLicitacionesParticipante/" +
          in_part_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let licitaciones = [];
          for (const item in resp["licitaciones"]) {
            licitaciones.push({ ...resp["licitaciones"][item] });
          }
          return JSON.stringify(licitaciones);
        })
      );
  }

  getValidaLic(id: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getValidaLic/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let lic_valida = [];
          for (const item in resp["lic_valida"]) {
            lic_valida.push({ ...resp["lic_valida"][item] });
          }
          return JSON.stringify(lic_valida);
        })
      );
  }

  getParticipantesLic(in_lic_correlativo: number, in_etli_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getParticipantesLic/" +
          in_lic_correlativo +
          "/" +
          in_etli_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let participantes = [];
          for (const item in resp["participantes"]) {
            participantes.push({ ...resp["participantes"][item] });
          }
          return JSON.stringify(participantes);
        })
      );
  }
  getParticipantesLicitacionID(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getParticipantesLicitacionID/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let participantes = [];
          for (const item in resp["participantes"]) {
            participantes.push({ ...resp["participantes"][item] });
          }
          return JSON.stringify(participantes);
        })
      );
  }
  getDatosPart() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    const in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .get(this.urlService + "licitacion/getDatosPart/" + in_usuario, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let datos_participante = [];
          for (const item in resp["datos_participante"]) {
            datos_participante.push({ ...resp["datos_participante"][item] });
          }
          return JSON.stringify(datos_participante);
        })
      );
  }

  getConsultasLic(in_etli_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService + "licitacion/getConsultasLic/" + in_etli_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let consultas_lic = [];
          for (const item in resp["consultas_lic"]) {
            consultas_lic.push({ ...resp["consultas_lic"][item] });
          }
          return JSON.stringify(consultas_lic);
        })
      );
  }
  getConsultasLicXls(in_etli_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getConsultasLicXls/" +
          in_etli_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let consultas_lic = [];
          for (const item in resp["consultas_lic"]) {
            consultas_lic.push({ ...resp["consultas_lic"][item] });
          }
          return JSON.stringify(consultas_lic);
        })
      );
  }
  getListConsultasLic(in_lic_correlativo: number, in_etapa: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getListConsultasLic/" +
          in_lic_correlativo +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let consultas_lic = [];
          for (const item in resp["consultas_lic"]) {
            consultas_lic.push({ ...resp["consultas_lic"][item] });
          }
          return JSON.stringify(consultas_lic);
        })
      );
  }
  getConsultasPartLic(
    in_etli_correlativo: number,
    in_part_correlativo: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getConsultasPartLic/" +
          in_etli_correlativo +
          "/" +
          in_part_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let consultas_participante = [];
          for (const item in resp["consultas_participante"]) {
            consultas_participante.push({
              ...resp["consultas_participante"][item],
            });
          }
          return JSON.stringify(consultas_participante);
        })
      );
  }
  getConsultasPart(in_lic_correlativo: number, in_part_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getConsultasPart/" +
          in_lic_correlativo +
          "/" +
          in_part_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let consultas_participante = [];
          for (const item in resp["consultas_participante"]) {
            consultas_participante.push({
              ...resp["consultas_participante"][item],
            });
          }
          return JSON.stringify(consultas_participante);
        })
      );
  }
  getConsultasLicFull(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getConsultasLicFull/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let consultas = [];
          for (const item in resp["consultas"]) {
            consultas.push({ ...resp["consultas"][item] });
          }
          return JSON.stringify(consultas);
        })
      );
  }
  getAclaracionPart(
    in_lic_correlativo: number,
    in_part_correlativo: number,
    in_etapa: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getAclaracionPart/" +
          in_lic_correlativo +
          "/" +
          in_part_correlativo +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let aclaracion_participante = [];
          for (const item in resp["aclaracion_participante"]) {
            aclaracion_participante.push({
              ...resp["aclaracion_participante"][item],
            });
          }
          return JSON.stringify(aclaracion_participante);
        })
      );
  }
  getArchivosPartLic(
    in_etli_correlativo: number,
    in_part_correlativo: number,
    in_domi_correlativo_tiar: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getArchivosPartLic/" +
          in_etli_correlativo +
          "/" +
          in_part_correlativo +
          "/" +
          in_domi_correlativo_tiar,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivo_part_lic = [];
          for (const item in resp["archivo_part_lic"]) {
            archivo_part_lic.push({ ...resp["archivo_part_lic"][item] });
          }
          return JSON.stringify(archivo_part_lic);
        })
      );
  }
  getNegocacionPart(
    in_lic_correlativo: number,
    in_part_correlativo: number,
    in_etapa: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getNegocacionPart/" +
          in_lic_correlativo +
          "/" +
          in_part_correlativo +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let negociacion_participante = [];
          for (const item in resp["negociacion_participante"]) {
            negociacion_participante.push({
              ...resp["negociacion_participante"][item],
            });
          }
          return JSON.stringify(negociacion_participante);
        })
      );
  }
  getAclaracionLic(in_lic_correlativo: number, in_etapa: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getAclaracionLic/" +
          in_lic_correlativo +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let aclaracion_licitacion = [];
          for (const item in resp["aclaracion_licitacion"]) {
            aclaracion_licitacion.push({
              ...resp["aclaracion_licitacion"][item],
            });
          }
          return JSON.stringify(aclaracion_licitacion);
        })
      );
  }
  getNegociacionesLic(in_lic_correlativo: number, in_etapa: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getNegociacionesLic/" +
          in_lic_correlativo +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let negocacion_licitacion = [];
          for (const item in resp["negocacion_licitacion"]) {
            negocacion_licitacion.push({
              ...resp["negocacion_licitacion"][item],
            });
          }
          return JSON.stringify(negocacion_licitacion);
        })
      );
  }
  getHeaderLicitacionId(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getHeaderLicitacionId/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let header_licitiacion = [];
          for (const item in resp["header_licitiacion"]) {
            header_licitiacion.push({ ...resp["header_licitiacion"][item] });
          }
          return JSON.stringify(header_licitiacion);
        })
      );
  }
  getCronogramaLicitacionId(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getCronogramaLicitacionId/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cronograma_licitiacion = [];
          for (const item in resp["cronograma_licitiacion"]) {
            cronograma_licitiacion.push({
              ...resp["cronograma_licitiacion"][item],
            });
          }
          return JSON.stringify(cronograma_licitiacion);
        })
      );
  }
  getDocumentosLicitacionId(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getDocumentosLicitacionId/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let documentos_licitiacion = [];
          for (const item in resp["documentos_licitiacion"]) {
            documentos_licitiacion.push({
              ...resp["documentos_licitiacion"][item],
            });
          }
          return JSON.stringify(documentos_licitiacion);
        })
      );
  }
  getPartLicitacionId(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getPartLicitacionId/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let participante_licitiacion = [];
          for (const item in resp["participante_licitiacion"]) {
            participante_licitiacion.push({
              ...resp["participante_licitiacion"][item],
            });
          }
          return JSON.stringify(participante_licitiacion);
        })
      );
  }
  getCronogramaLicUpdate(in_licitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    const in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .get(
        this.urlService +
          "licitacion/getCronogramaLicUpdate/" +
          in_licitacion +
          "/" +
          in_usuario,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cronograma = [];
          for (const item in resp["cronograma"]) {
            cronograma.push({ ...resp["cronograma"][item] });
          }
          return JSON.stringify(cronograma);
        })
      );
  }
  getCronogramaLicView(in_licitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService + "licitacion/getCronogramaLicView/" + in_licitacion,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cronograma = [];
          for (const item in resp["cronograma"]) {
            cronograma.push({ ...resp["cronograma"][item] });
          }
          return JSON.stringify(cronograma);
        })
      );
  }

  getTipoConsultas(in_tipo: string) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getTipoConsultas/" + in_tipo, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let tipo_consultas = [];
          for (const item in resp["tipo_consultas"]) {
            tipo_consultas.push({ ...resp["tipo_consultas"][item] });
          }
          return JSON.stringify(tipo_consultas);
        })
      );
  }
  getPorcentajeAvance(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getPorcentajeAvance/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let avance = [];
          for (const item in resp["avance"]) {
            avance.push({ ...resp["avance"][item] });
          }
          return JSON.stringify(avance);
        })
      );
  }
  getArchivosLicitacion(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getArchivosLicitacion/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivos = [];
          for (const item in resp["archivos"]) {
            archivos.push({ ...resp["archivos"][item] });
          }
          return JSON.stringify(archivos);
        })
      );
  }
  getDashBoard() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getDashBoard/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let dashboard = [];
          for (const item in resp["dashboard"]) {
            dashboard.push({ ...resp["dashboard"][item] });
          }
          return JSON.stringify(dashboard);
        })
      );
  }
  getFormatearFecha(iv_fecha: any) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getFormatearFecha/" + iv_fecha, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let formato = [];
          for (const item in resp["formato"]) {
            formato.push({ ...resp["formato"][item] });
          }
          return JSON.stringify(formato);
        })
      );
  }

  getParticipantesCom() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getParticipantesCom", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let participantesCom = [];
          for (const item in resp["participantesCom"]) {
            participantesCom.push({ ...resp["participantesCom"][item] });
          }
          return JSON.stringify(participantesCom);
        })
      );
  }

  putParticipanteCom(putData: IOnAgregarParticipanteCom) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http
      .put(this.urlService + "licitacion/putParticipanteCom", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  getParticipantesComLicitacion(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getParticipantesComLicitacion/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let comisiones = [];
          for (const item in resp["comisiones"]) {
            comisiones.push({ ...resp["comisiones"][item] });
          }
          return JSON.stringify(comisiones);
        })
      );
  }

  eliminarParticipanteCom(in_part_com_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .delete(
        this.urlService +
          "licitacion/eliminarParticipanteCom/" +
          in_part_com_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  getComisionTecnicaLicitacion(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getComisionTecnicaLicitacion/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let comisionTecnica = [];
          for (const item in resp["comisionTecnica"]) {
            comisionTecnica.push({ ...resp["comisionTecnica"][item] });
          }
          return JSON.stringify(comisionTecnica);
        })
      );
  }

  getCorreosLicitacion(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getCorreosLicitacionById/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let correosLicitacion = [];
          for (const item in resp["correos_licitiacion"]) {
            correosLicitacion.push({ ...resp["correos_licitiacion"][item] });
          }
          return JSON.stringify(correosLicitacion);
        })
      );
  }

  putEnviarCorreoLic(putData: {
    pin_lic_correlativo: number;
    asunto_correo: string;
    texto_correo: string;
    destinatario_correo: string;
    nombre_destinatario: string;
    adjunto: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    //putData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .put(
        this.urlService + "licitacion/putEnviarCorreoLicitaciones",
        putData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  getTiposAclaraciones() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getTiposAclaraciones", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let tiposAclaraciones = [];
          for (const item in resp["tipos_aclaraciones"]) {
            tiposAclaraciones.push({ ...resp["tipos_aclaraciones"][item] });
          }
          return JSON.stringify(tiposAclaraciones);
        })
      );
  }

  getAclaracionesLicitacion(in_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getAclaracionesLicitacion/" +
          in_lic_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let aclaracionesLicitacion = [];
          for (const item in resp["aclaraciones"]) {
            aclaracionesLicitacion.push({ ...resp["aclaraciones"][item] });
          }
          return JSON.stringify(aclaracionesLicitacion);
        })
      );
  }

  putGuardarAclaracionLicitacion(putData: {
    pin_id_licitacion: number;
    vn_id_tipo_aclaracion: number;
    vn_texto_aclaracion: string;
    vn_id_usuario_creacion: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    //putData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .put(this.urlService + "licitacion/putAclaracionLicitacion", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let message = {};
          message = resp["message"];
          return JSON.stringify(message);
        })
      );
  }

  getArchivosLicitacionOferente(in_lic_correlativo: number, idUsuario: string) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "licitacion/getArchivosLicitacionOferente/" +
          in_lic_correlativo +
          "/" +
          idUsuario,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivos = [];
          for (const item in resp["archivos"]) {
            archivos.push({ ...resp["archivos"][item] });
          }
          return JSON.stringify(archivos);
        })
      );
  }

  getRolesComisionLicitacion() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getRolesComision", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let rolesComision = [];
          for (const item in resp["rolesComision"]) {
            rolesComision.push({ ...resp["rolesComision"][item] });
          }
          return JSON.stringify(rolesComision);
        })
      );
  }

  getMonedasSolpeLicitacion() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getMonedas", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let monedas = [];
          for (const item in resp["monedas"]) {
            monedas.push({ ...resp["monedas"][item] });
          }
          return JSON.stringify(monedas);
        })
      );
  }

  postSetGanador(postData: { id_participante: number; id_licitacion: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(this.urlService + "licitacion/postSetGanador", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let licitacion = [];
          for (const item in resp["licitacion"]) {
            licitacion.push({ ...resp["licitacion"][item] });
          }
          return JSON.stringify(licitacion);
        })
      );
  }

  postRegistrarLicitacion(postData: { idSolpe: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/postRegistrarLicitacion",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getLicitacionesEvaluadorList() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getLicitacionesEvaluadorList", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let licitaciones = [];
          for (const item in resp["licitaciones"]) {
            licitaciones.push({ ...resp["licitaciones"][item] });
          }
          return JSON.stringify(licitaciones);
        })
      );
  }

  getGruposArchivos() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "licitacion/getGruposArchivos", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let getGruposArchivos = [];
          for (const item in resp["gruposArchivos"]) {
            getGruposArchivos.push({ ...resp["gruposArchivos"][item] });
          }
          return JSON.stringify(getGruposArchivos);
        })
      );
  }

  postEnviaNotificacionComisionEvaluadora(postData: {
    licitacion_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/postEnviaNotificacionComisionEvaluadora",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getNotificacionesComision(licitacion_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          `licitacion/getNotificacionesComision/${licitacion_correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let notificacionesComision = [];
          for (const item in resp["notificacionesComision"]) {
            notificacionesComision.push({
              ...resp["notificacionesComision"][item],
            });
          }
          return JSON.stringify(notificacionesComision);
        })
      );
  }

  putDeclaracionDocumentoCheck(postData: {
    correlativo_participante_comision: number;
    check_documento: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.put(
      this.urlService + "licitacion/putDeclaracionDocumentoCheck",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getAyudaEvalTecnica() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + `licitacion/getAyudaEvalTecnica`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let ayudaEvalTecnica = [];
          for (const item in resp["ayudaEvalTecnica"]) {
            ayudaEvalTecnica.push({ ...resp["ayudaEvalTecnica"][item] });
          }
          return JSON.stringify(ayudaEvalTecnica);
        })
      );
  }

  getAyudaEvalTecnicaCriterios(in_id_ayuda: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          `licitacion/getAyudaEvalTecnicaCriterios/${in_id_ayuda}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let ayudaEvalTecnicaCriterios = [];
          for (const item in resp["ayudaEvalTecnicaCriterios"]) {
            ayudaEvalTecnicaCriterios.push({
              ...resp["ayudaEvalTecnicaCriterios"][item],
            });
          }
          return JSON.stringify(ayudaEvalTecnicaCriterios);
        })
      );
  }

  getAyudaEvalTecnicaCriteriosMantenedor(in_id_ayuda: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          `licitacion/getAyudaEvalTecnicaCriteriosMantenedor/${in_id_ayuda}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let ayudaEvalTecnicaCriterios = [];
          for (const item in resp["ayudaEvalTecnicaCriterios"]) {
            ayudaEvalTecnicaCriterios.push({
              ...resp["ayudaEvalTecnicaCriterios"][item],
            });
          }
          return JSON.stringify(ayudaEvalTecnicaCriterios);
        })
      );
  }

  getNotasEvalTecnica() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + `licitacion/getNotasEvalTecnica`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let notasEvalTecnica = [];
          for (const item in resp["notasAyudaEvaltecnica"]) {
            notasEvalTecnica.push({ ...resp["notasAyudaEvaltecnica"][item] });
          }
          return JSON.stringify(notasEvalTecnica);
        })
      );
  }

  deleteAyudaEvalTecnicaCriterios(postData: { in_correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/deleteAyudaEvalTecnicaCriterios",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  createAyudaEvalTecnicaCriterios(postData: {
    in_idAyuda: number;
    in_descripcion: string;
    correlativo_nota: string;
    in_grupo: string;
    in_item: string;
    in_cargo: string;
    in_ponderador: string;
    in_perfil_requerido: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/createAyudaEvalTecnicaCriterios",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putAyudaEvalTecnicaCriterios(postData: {
    in_correlativo: number;
    in_idAyuda: number;
    in_descripcion: string;
    correlativo_nota: string;
    in_grupo: string;
    in_item: string;
    in_cargo: string;
    in_ponderador: string;
    in_perfil_requerido: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/putAyudaEvalTecnicaCriterios",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postCreateAyudaEvalTecnica(postData: { in_descripcion: string }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/postCreateAyudaEvalTecnica",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putUpdateAyudaEvalTecnica(postData: {
    in_correlativo: number;
    in_descripcion: string;
    in_vigente: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/putUpdateAyudaEvalTecnica",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putUpdateValidateConsultasRespuestas(postData: {
    pin_lic_correlativo: number;
    in_validacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/putUpdateValidateConsultasRespuestas",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putFechaCronogramaLicOriginal(putData: {
    in_licitacion: number;
    in_etapa_correlativo: number;
    in_fecha_inicio: string;
    in_fecha_termino: string;
    in_hora: string;
    iv_usuario_creacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.iv_usuario_creacion = JSON.parse(
      localStorage.getItem("userData")
    ).userId;
    return this.http.post(
      this.urlService + "licitacion/putFechaCronogramaLicOriginal",
      putData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  getLicitacionChat(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      this.urlService + "licitacion/getLicitacionChat/" + idLicitacion,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postLicitacionChat(postData: { in_licitacion: number; in_mensaje: string }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/postLicitacionChat",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
  postRespuestaPrincipal(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/postRespuestaPrincipal",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putCronogramaLicOriginal(putData: {
    in_licitacion: number;
    in_etapa_correl: number;
    in_usuario: string;
    in_activo: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    putData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;
    return this.http.put(
      this.urlService + "licitacion/putCronogramaLicOriginal",
      putData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putEtapaObligatoriaCronograma(putData: {
    in_licitacion: number;
    pin_correlativo: number;
    in_obligatorio: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.put(
      this.urlService + "licitacion/putEtapaObligatoriaCronograma",
      putData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putEtapaObligatoriaCronogramaOriginal(putData: {
    in_licitacion: number;
    pin_correlativo: number;
    in_obligatorio: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.put(
      this.urlService + "licitacion/putEtapaObligatoriaCronogramaOriginal",
      putData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getUltimaverisonCronograma(in_correlativo_licitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getUltimaverisonCronograma/${in_correlativo_licitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let versionCronograma = [];
          for (const item in resp["versionCronograma"]) {
            versionCronograma.push({ ...resp["versionCronograma"][item] });
          }
          return JSON.stringify(versionCronograma);
        })
      );
  }

  postRegistraHistoricoCronogramaLicitacion(postData: {
    in_correlativo_licitacion: number;
    in_usuario_correlativo: number;
    in_etapa_cambio_historico: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService + "licitacion/postRegistraHistoricoCronogramaLicitacion",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  putActualizaVersionCronograma(putData: {
    pin_lic_correlativo: number;
    in_version: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.put(
      this.urlService + "licitacion/putActualizaVersionCronograma",
      putData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getVerisonesCronograma(pin_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getVerisonesCronograma/${pin_lic_correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let versionesCronograma = [];
          for (const item in resp["versionesCronograma"]) {
            versionesCronograma.push({ ...resp["versionesCronograma"][item] });
          }
          return JSON.stringify(versionesCronograma);
        })
      );
  }

  getVerisonCronograma(pin_lic_correlativo: number, in_version: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getVerisonCronograma/${pin_lic_correlativo}/${in_version}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let versionCronograma = [];
          for (const item in resp["versionCronograma"]) {
            versionCronograma.push({ ...resp["versionCronograma"][item] });
          }
          return JSON.stringify(versionCronograma);
        })
      );
  }

  getOferentesDescalificados(pin_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getOferentesDescalificados/${pin_lic_correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let oferentesDescalificados = [];
          for (const item in resp["oferentesDescalificados"]) {
            oferentesDescalificados.push({
              ...resp["oferentesDescalificados"][item],
            });
          }
          return JSON.stringify(oferentesDescalificados);
        })
      );
  }

  getEtapaActualLicitacion(pin_lic_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getEtapaActualLicitacion/${pin_lic_correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let etapaActualLicitacion = [];
          for (const item in resp["etapaActualLicitacion"]) {
            etapaActualLicitacion.push({
              ...resp["etapaActualLicitacion"][item],
            });
          }
          return JSON.stringify(etapaActualLicitacion);
        })
      );
  }

  postEnviaNotificacionDescalificacion(postData: {
    pin_lic_correlativo: number;
    in_part_correlativo: number;
    in_adjunto: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postEnviaNotificacionDescalificacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  updateSolicitudRenunciaLicitacion(postData: {
    motivo_renuncia_licitacion: string;
    pin_lic_correlativo: number;
    pin_part_correlativo: number;
    motivo_renuncia_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/updateSolicitudRenunciaLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  updateConfirmarRenunciaLicitacion(postData: {
    pin_lic_correlativo: number;
    in_part_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/updateConfirmarRenunciaLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSolicitudRenunciaLicitacion(
    pin_lic_correlativo: number,
    in_part_correlativo: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSolicitudRenunciaLicitacion/${pin_lic_correlativo}/${in_part_correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let solicitudRenunciaLicitacion = [];
          for (const item in resp["solicitudRenuncia"]) {
            solicitudRenunciaLicitacion.push({
              ...resp["solicitudRenuncia"][item],
            });
          }
          return JSON.stringify(solicitudRenunciaLicitacion);
        })
      );
  }

  postEnviaNotificacionRenunciaLicitacion(postData: {
    pin_lic_correlativo: number;
    in_part_correlativo: number;
    in_adjunto: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postEnviaNotificacionRenunciaLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postEnviaNotificacionDescalificacionIncumplimiento(postData: {
    pin_lic_correlativo: number;
    in_part_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postEnviaNotificacionDescalificacionIncumplimiento`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postInsertMotivoRenunciaLicitacion(postData: {
    motivo_renuncia: string;
    tipo_motivo_renuncia: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postInsertMotivoRenunciaLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateMotivoRenunciaLicitacion(postData: {
    motivo_renuncia: string;
    tipo_motivo_renuncia: string;
    vigente: number;
    correlativo_renuncia: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateMotivoRenunciaLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeteleMotivoRenunciaLicitacion(postData: {
    correlativo_renuncia: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeteleMotivoRenunciaLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getMotivosRenunciaLicitacion() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getMotivosRenunciaLicitacion`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let motivosRenuncia = [];
          for (const item in resp["motivosRenuncia"]) {
            motivosRenuncia.push({ ...resp["motivosRenuncia"][item] });
          }
          return JSON.stringify(motivosRenuncia);
        })
      );
  }

  getEstandaresEvalTecnica() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getEstandaresEvalTecnica`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let estandaresEvalTecnica = [];
          for (const item in resp["estandaresEvalTecnica"]) {
            estandaresEvalTecnica.push({
              ...resp["estandaresEvalTecnica"][item],
            });
          }
          return JSON.stringify(estandaresEvalTecnica);
        })
      );
  }

  postBuildEstandarEvalTecnica(postData: {
    idLicitacion: number;
    correlativoEstandar: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(
        `${this.urlService}licitacion/postBuildEstandarEvalTecnica`,
        postData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let estandarEvalTecnica = [];
          for (const item in resp["estandarEvalTecnica"]) {
            estandarEvalTecnica.push({ ...resp["estandarEvalTecnica"][item] });
          }
          return JSON.stringify(estandarEvalTecnica);
        })
      );
  }

  postDeleteEstandarCriterioSeleccionado(postData: {
    in_licitacion_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteEstandarCriterioSeleccionado`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getEstandaresEvalTecnicaMantenedor() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getEstandaresEvalTecnicaMantenedor`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let estandaresEvalTecnicaMantenedor = [];
          for (const item in resp["estandaresEvalTecnicaMantenedor"]) {
            estandaresEvalTecnicaMantenedor.push({
              ...resp["estandaresEvalTecnicaMantenedor"][item],
            });
          }
          return JSON.stringify(estandaresEvalTecnicaMantenedor);
        })
      );
  }

  postInsertEstandarEvalTecnica(postData: { descripcion_estandar: string }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postInsertEstandarEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateEstandarEvalTecnica(postData: {
    estandarDescricion: string;
    vigente: number;
    correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateEstandarEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteEstandarEvalTecnica(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteEstandarEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  // GRUPOS

  getSelectEstandaresEvalTecnicaMantenedorGrupoMantenedor(correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectEstandaresEvalTecnicaMantenedorGrupoMantenedor/${correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let estandarGrupoEvalTecnicaMantenedor = [];
          for (const item in resp["estandarGrupoEvalTecnicaMantenedor"]) {
            estandarGrupoEvalTecnicaMantenedor.push({
              ...resp["estandarGrupoEvalTecnicaMantenedor"][item],
            });
          }
          return JSON.stringify(estandarGrupoEvalTecnicaMantenedor);
        })
      );
  }

  postInsertGrupoEvalTecnica(postData: {
    correlativo_estandar_eval_tec: number;
    descripcion: string;
    peso: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postInsertGrupoEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateGrupoEvalTecnica(postData: {
    grupoDescricion: string;
    peso: number;
    vigente: number;
    correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateGrupoEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteGrupoEvalTecnica(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteGrupoEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  // SUBGRUPOS

  getSelectEstandaresEvalTecnicaMantenedorSubgrupoMantenedor(
    correlativo: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectEstandaresEvalTecnicaMantenedorSubgrupoMantenedor/${correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let estandarSubgrupoEvalTecnicaMantenedor = [];
          for (const item in resp["estandarSubgrupoEvalTecnicaMantenedor"]) {
            estandarSubgrupoEvalTecnicaMantenedor.push({
              ...resp["estandarSubgrupoEvalTecnicaMantenedor"][item],
            });
          }
          return JSON.stringify(estandarSubgrupoEvalTecnicaMantenedor);
        })
      );
  }

  postInsertSubgrupoEvalTecnica(postData: {
    grupoCorrelativo: number;
    subgrupoDescripcion: string;
    subgrupoPeso: number;
    subgrupoAbierto: number;
    subgrupoTipo: number;
    subgrupoIdAyuda: number;
    subgrupoLimiteRespuesta: number;
    subgrupoObservacion: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postInsertSubgrupoEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateSubgrupoEvalTecnica(postData: {
    subgrupoCorrelativo: number;
    subgrupoDescripcion: string;
    subgrupoPeso: number;
    subgrupoAbierto: number;
    subgrupoTipo: number;
    subgrupoIdAyuda: number;
    subgrupoLimiteRespuesta: number;
    subgrupoObservacion: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateSubgrupoEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteSubgrupoEvalTecnica(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteSubgrupoEvalTecnica`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postSaveAyudaEvalTecnicaEditable(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(
        `${this.urlService}licitacion/postSaveAyudaEvalTecnicaEditable`,
        postData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnSaveAyudaEvalTecnicaEditable = [];
          for (const item in resp["returnSaveAyudaEvalTecnicaEditable"]) {
            returnSaveAyudaEvalTecnicaEditable.push({
              ...resp["returnSaveAyudaEvalTecnicaEditable"][item],
            });
          }
          return JSON.stringify(returnSaveAyudaEvalTecnicaEditable);
        })
      );
  }

  getAyudaEvalTecnicaEditable(correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getAyudaEvalTecnicaEditable/${correlativo}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnAyudaEvalTecnicaEditable = [];
          for (const item in resp["returnAyudaEvalTecnicaEditable"]) {
            returnAyudaEvalTecnicaEditable.push({
              ...resp["returnAyudaEvalTecnicaEditable"][item],
            });
          }
          return JSON.stringify(returnAyudaEvalTecnicaEditable);
        })
      );
  }

  postUpdateAyudaEditableBase(postData: {
    in_subgrupo_correlativo: number;
    in_idayuda_correlativo: number;
    in_licitacion_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateAyudaEditableBase`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteAyudaEvaltecnicaEditable(postData: {
    correlativo_subgrupo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteAyudaEvaltecnicaEditable`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postComprobarExiteAyudaEvaltecnicaEditable(postData: {
    objeto_grupos: any[];
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postComprobarExiteAyudaEvaltecnicaEditable`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteAyudaEvaltecnicaEditableByGrupo(postData: { objeto_grupo: any }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteAyudaEvaltecnicaEditableByGrupo`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateAyudaEditableSubgrupo(postData: {
    correlativo: number;
    ayudaDescripcion: string;
    ayudaNota: number;
    ayudaCargo: string;
    ayudaPonderador: string;
    ayudaPerfil: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateAyudaEditableSubgrupo`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteAyudaEditableSubgrupo(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteAyudaEditableSubgrupo`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postCreateAyudaEditableSubgrupo(postData: {
    correlativoSubgrupo: number;
    idAyuda: number;
    descripcion: string;
    nota: number;
    idLicitacion: number;
    cargo: string;
    ponderador: string;
    perfil: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreateAyudaEditableSubgrupo`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postCreatePlantillaEstandarEvaltec(postData: {
    idLicitacion: number;
    descripcionEstandar: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreatePlantillaEstandarEvaltec`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postCreateDocumentoRequeridoOferente(postData: {
    descripcionDocReq: string;
    tipoDoc: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreateDocumentoRequeridoOferente`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateDocumentoRequeridoOferente(postData: {
    correlativo: number;
    descripcionDocReq: string;
    tipoDoc: number;
    vigente: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateDocumentoRequeridoOferente`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteDocumentoRequeridoOferente(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteDocumentoRequeridoOferente`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectDocumentoRequeridoOferente() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectDocumentoRequeridoOferente`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let returnDocumentosRequeridosOferente = [];
          for (const item in resp["returnDocumentosRequeridosOferente"]) {
            returnDocumentosRequeridosOferente.push({
              ...resp["returnDocumentosRequeridosOferente"][item],
            });
          }
          return JSON.stringify(returnDocumentosRequeridosOferente);
        })
      );
  }

  getSelectDocumentoRequeridoOferenteActa() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectDocumentoRequeridoOferenteActa`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnDocumentosRequeridosOferenteActa = [];
          for (const item in resp["returnDocumentosRequeridosOferenteActa"]) {
            returnDocumentosRequeridosOferenteActa.push({
              ...resp["returnDocumentosRequeridosOferenteActa"][item],
            });
          }
          return JSON.stringify(returnDocumentosRequeridosOferenteActa);
        })
      );
  }

  getSelectDocRequeridosOferenteSaved(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectDocRequeridosOferenteSaved/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnDocRequeridosOferenteSaved = [];
          for (const item in resp["returnDocRequeridosOferenteSaved"]) {
            returnDocRequeridosOferenteSaved.push({
              ...resp["returnDocRequeridosOferenteSaved"][item],
            });
          }
          return JSON.stringify(returnDocRequeridosOferenteSaved);
        })
      );
  }

  postCreateDocRequeridoOferenteActa(postData: {
    correlativo_doc_requerido: string;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreateDocRequeridoOferenteActa`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteDocRequeridoOferenteActa(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteDocRequeridoOferenteActa`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  geSelectDocRequeridosByOferente(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/geSelectDocRequeridosByOferente/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnDocRequeridosByOferente = [];
          for (const item in resp["returnDocRequeridosByOferente"]) {
            returnDocRequeridosByOferente.push({
              ...resp["returnDocRequeridosByOferente"][item],
            });
          }
          return JSON.stringify(returnDocRequeridosByOferente);
        })
      );
  }

  postCreateDocumentoRequeridoByOferente(postData: {
    correlativo_doc_requerido: number;
    files: Blob;
    fileName: string;
    type: string;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreateDocumentoRequeridoByOferente`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteDocRequeridoByOferente(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteDocRequeridoByOferente`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectComisionByLicitacion() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectComisionByLicitacion`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let returnComisionByLicitacion = [];
          for (const item in resp["returnComisionByLicitacion"]) {
            returnComisionByLicitacion.push({
              ...resp["returnComisionByLicitacion"][item],
            });
          }
          return JSON.stringify(returnComisionByLicitacion);
        })
      );
  }

  postDeleteLicitacion(postData: { idLicitacion: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectNotificacionesByUsuario() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectNotificacionesByUsuario`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let returnNotificacionesByUsuario = [];
          for (const item in resp["returnNotificacionesByUsuario"]) {
            returnNotificacionesByUsuario.push({
              ...resp["returnNotificacionesByUsuario"][item],
            });
          }
          return JSON.stringify(returnNotificacionesByUsuario);
        })
      );
  }

  getSelectTiposDocAdministrativo() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectTiposDocAdministrativo`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let returnTiposDocAdministrativo = [];
          for (const item in resp["returnTiposDocAdministrativo"]) {
            returnTiposDocAdministrativo.push({
              ...resp["returnTiposDocAdministrativo"][item],
            });
          }
          return JSON.stringify(returnTiposDocAdministrativo);
        })
      );
  }

  getSelectRolesParticipanteComision(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectRolesParticipanteComision/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnRolesParticipanteComision = [];
          for (const item in resp["returnRolesParticipanteComision"]) {
            returnRolesParticipanteComision.push({
              ...resp["returnRolesParticipanteComision"][item],
            });
          }
          return JSON.stringify(returnRolesParticipanteComision);
        })
      );
  }

  getSelectParticipanteLic() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectParticipanteLic`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let returnParticipanteLic = [];
          for (const item in resp["returnParticipanteLic"]) {
            returnParticipanteLic.push({
              ...resp["returnParticipanteLic"][item],
            });
          }
          return JSON.stringify(returnParticipanteLic);
        })
      );
  }

  getSelectPermitirEditarPresupuesto(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectPermitirEditarPresupuesto/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let returnPermisoEdicionPart = [];
          for (const item in resp["returnPermisoEdicionPart"]) {
            returnPermisoEdicionPart.push({
              ...resp["returnPermisoEdicionPart"][item],
            });
          }
          return JSON.stringify(returnPermisoEdicionPart);
        })
      );
  }

  postUpdatePermisoEditarPresupuesto(postData: { idLicitacion: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdatePermisoEditarPresupuesto`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateValidacionLct(postData: {
    validacion: number;
    observacion: string;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateValidacionLct`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getGenerateNotificacionRevisionLctByResponsable(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      `${this.urlService}licitacion/getGenerateNotificacionRevisionLctByResponsable/${idLicitacion}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateValidacionJac(postData: {
    validacion: number;
    observacion: string;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateValidacionaJac`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateTieneAclaracionesTec(postData: {
    validacion: number;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateTieneAclaracionesTec`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateTieneAclaracionesEco(postData: {
    validacion: number;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateTieneAclaracionesEco`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateTieneNegociacion(postData: {
    validacion: number;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateTieneNegociacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectCartasAdjudicacionLicitacion(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectCartasAdjudicacionLicitacion/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cartaAdjudicacion = [];
          for (const item in resp["cartaAdjudicacion"]) {
            cartaAdjudicacion.push({
              ...resp["cartaAdjudicacion"][item],
            });
          }
          return JSON.stringify(cartaAdjudicacion);
        })
      );
  }

  postCreateDocCartaAdjudicacion(postData: {
    fileName: string;
    fileDescripcion: string;
    type: string;
    files: Blob;
    idLicitacion: number;
    idParticipante: number;
    idSolpe: number;
    nombreOferente: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreateDocCartaAdjudicacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdataDocCartaAdjudicacionFirmada(postData: {
    fileName: string;
    fileDescripcion: string;
    type: string;
    files: Blob;
    correlativoCarta: number;
    idLicitacion: number;
    nombreOferente: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdataDocCartaAdjudicacionFirmada`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectCartasAgradecimiento(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectCartasAgradecimiento/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cartaAgradecimiento = [];
          for (const item in resp["cartaAgradecimiento"]) {
            cartaAgradecimiento.push({
              ...resp["cartaAgradecimiento"][item],
            });
          }
          return JSON.stringify(cartaAgradecimiento);
        })
      );
  }

  postGenerateCartasAgradecimiento(postData: { idLicitacion: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postGenerateCartasAgradecimiento`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectCartasAgradecimientoOferente(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectCartasAgradecimientoOferente/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cartsaAgradecimiento = [];
          for (const item in resp["cartsaAgradecimiento"]) {
            cartsaAgradecimiento.push({
              ...resp["cartsaAgradecimiento"][item],
            });
          }
          return JSON.stringify(cartsaAgradecimiento);
        })
      );
  }

  postSaveCartaAdjudicacionSolpeContrato(postData: {
    fileName: string;
    fileDescripcion: string;
    type: string;
    files: Blob;
    solpeCorrelativo: string;
    tipoDocSolpe: string;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postSaveCartaAdjudicacionSolpeContrato`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postCreateDocAdjudicacionMaf(postData: {
    fileName: string;
    fileDescripcion: string;
    type: string;
    files: Blob;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postCreateDocAdjudicacionMaf`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectDocAdjudicacionMaf(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectDocAdjudicacionMaf/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let docAdjudicacionMaf = [];
          for (const item in resp["docAdjudicacionMaf"]) {
            docAdjudicacionMaf.push({
              ...resp["docAdjudicacionMaf"][item],
            });
          }
          return JSON.stringify(docAdjudicacionMaf);
        })
      );
  }

  getSelectAclaracionesLicitaciones() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectAclaracionesLicitaciones`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let aclaracionesLicitacion = [];
          for (const item in resp["aclaracionesLicitacion"]) {
            aclaracionesLicitacion.push({
              ...resp["aclaracionesLicitacion"][item],
            });
          }
          return JSON.stringify(aclaracionesLicitacion);
        })
      );
  }

  postUpdateAclaracionVisible(postData: {
    idAclaracionDetalle: number;
    idLicitacion: number;
    partCorrelativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateAclaracionVisible`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateValidacionRespuestasAConsultasLct(postData: {
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      this.urlService +
        "licitacion/postUpdateValidacionRespuestasAConsultasLct",
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateValidacionRiesgoControl(postData: {
    validacion: number;
    observacion: string;
    idLicitacion: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateValidacionRiesgoControl`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateCartasAgradecimiento(postData: {
    correlativo: number;
    fileDescripcion: string;
    fileName: string;
    type: string;
    files: Blob;
    idLicitacion: number;
    correlativoOferente: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateCartasAgradecimiento`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateEstadoCartaAdjudicacion(postData: {
    correlativoCarta: number;
    idLicitacion: number;
    nombreOferente: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateEstadoCartaAdjudicacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteCartaAdjudicacion(postData: { correlativoCarta: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteCartaAdjudicacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectCartasAdjudicacionOferente(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        `${this.urlService}licitacion/getSelectCartasAdjudicacionOferente/${idLicitacion}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let cartaAdjudicacion = [];
          for (const item in resp["cartaAdjudicacion"]) {
            cartaAdjudicacion.push({
              ...resp["cartaAdjudicacion"][item],
            });
          }
          return JSON.stringify(cartaAdjudicacion);
        })
      );
  }

  postUpdateEstadoCartaAgradecimiento(postData: {
    correlativoCartaAgradecimiento: number;
    idLicitacion: number;
    correlativoOferente: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateEstadoCartaAgradecimiento`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postDeleteCartaAgradecimiento(postData: {
    correlativoCartaAgradecimiento: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}licitacion/postDeleteCartaAgradecimiento`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
}
