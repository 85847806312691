<ng-container>
  <div class="row mb-3 mt-5">
    <div class="col">
      <h4 class="d-flex justify-content-between">
        Comisiones
        <ng-container *ngIf="etapaActualLicitacion == 0">
          <ng-container *ngIf="validateRoleParticipanteComision(37)">
            <a class="btn tag text-orange" href="#modal-participante-com" data-toggle="modal">Agregar</a>
          </ng-container>
        </ng-container>
      </h4>
    </div>
  </div>
  <div class="form-group row m-b-15">
    <label class="col-form-label col-md-2"></label>
    <div class="col-md-12">
      <table id="table_comisiones" class="table table-striped table-td-valign-middle">
        <thead class="thead-darkk">
          <tr>
            <th class="text-nowrap">Participante</th>
            <th class="text-nowrap">Comisión</th>
            <!-- <th class="text-nowrap" style="vertical-align: top; text-align: center;">Capitulos</th> -->
            <th class="text-nowrap">Participación</th>
            <th class="text-center">Documento Válido</th>
            <th class="text-nowrap"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="comisiones && comisiones?.length == 0">
            <td colspan="4">
              <h4 class="text-center text-muted p-5">
                Sin comisión asignada
              </h4>
            </td>
          </tr>
          <tr class="odd gradeX" *ngFor="let item of comisiones">
            <td>{{ item.PART_NOMBRE }}</td>
            <td>{{ item.COMISION }}</td>
            <!-- <td>{{ item.CAPITULOS.split('').join(' , ') }}</td> -->
            <td>
              <ng-container *ngIf="item?.TIPO_PARTICIPANTE != 42">
                <ng-container *ngIf="item.PUEDE_PARTICIPAR == 0">
                  <span class="tab tab-yellow">
                    Esperando Resp
                  </span>
                </ng-container>
                <ng-container *ngIf="item.PUEDE_PARTICIPAR == 1">
                  <span class="tab tab-green">
                    Habilitado
                  </span>
                </ng-container>
                <ng-container *ngIf="item.PUEDE_PARTICIPAR == 2">
                  <span class="tab tab-red">
                    Inhabilitado
                  </span>
                </ng-container>
              </ng-container>
            </td>
            <td class="text-center">
              <ng-container *ngIf="item?.TIPO_PARTICIPANTE != 42">
                <ng-container *ngIf="item.DECLARACION_DOCUMENTO != null">
                  <ng-container *ngIf="item.DOCUMENTO_CHECK == 0">
                    <p class="text-muted">El documento aún no ha sido validado.</p>
                    <button class="btn btn-primary btn-sm"
                      (click)="onUpdateCheckDocumento(item.PART_CORRELATIVO, 1)">Validar</button>
                  </ng-container>
                  <ng-container *ngIf="item.DOCUMENTO_CHECK == 1">
                    <p class="text-muted h5">Válido <i class="fa fa-check-circle text-green mr-2"></i></p>
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
            <td class="text-right">
              <ng-container *ngIf="item?.TIPO_PARTICIPANTE != 42">
                <app-view-file-buttons [nombreArchivo]="item.DECLARACION_DOCUMENTO"></app-view-file-buttons>
              </ng-container>
              <ng-container *ngIf="etapaActualLicitacion == 0">
                <ng-container *ngIf="validateRoleParticipanteComision(37)">
                  <a class="btn rounded-icon" href="#modal-eliminar-participante-com" data-toggle="modal"
                    (click)="removeParticipanteCom(item.PART_CORRELATIVO)" title="Eliminar">
                    <i class="fas fa-trash"></i>
                  </a>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<!-- Modal de participante de comision -->
<div class="modal fade" id="modal-participante-com">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Agregar Participante Comisión</h4>
        <button type="button" id="close-modal-participante-com" class="close" data-dismiss="modal"
          aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formParticipanteCom" (ngSubmit)="onAgregarParticipanteCom()" novalidate>
          <div class="row form-group">
            <label for="per_correlativo" class="col-md-3 col-form-label">Participante</label>
            <div class="col-md-9">
              <select class="form-control" id="per_correlativo" name="per_correlativo"
                formControlName="per_correlativo">
                <option value="0">Seleccione...</option>
                <option *ngFor="let item of participantesCom" value="{{item.PER_CORRELATIVO}}">{{item.PER_NOMBRE}}
                </option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="domi_correlativo_tipar" class="col-md-3 col-form-label">Comisión</label>
            <div class="col-md-9">
              <select class="form-control" id="domi_correlativo_tipar" name="domi_correlativo_tipar"
                formControlName="domi_correlativo_tipar">
                <option value="0">Seleccione ...</option>
                <option *ngFor="let item of rolesComisionList" value="{{item.correlativo}}">{{item.descripcion}}
                </option>
                <!-- <option value="7">Comisión Técnica</option>
                <option value="8">Comisión Económica</option> -->
              </select>
            </div>
          </div>
          <!-- <div class="row form-group" *ngIf="formParticipanteCom.value.domi_correlativo_tipar ==='7'">
            <label for="cap" class="col-md-3 col-form-label">Capitulos</label>
            <div class="col-md-9">
              <div class="checkbox checkbox-css">
                <div class="mb-2" *ngFor="let item of capitulos; index as indexOfelement">
                  <input #cap type="checkbox" id="cap{{item.CORRELATIVO}}"
                    (change)="onUpdateCapitulo(indexOfelement, cap.checked)" />
                  <label style="margin-right: 5px;" for="cap{{item.CORRELATIVO}}">{{ item.DESCRIPCION }}</label>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12 text-right">
              <button class="btn btn-primary mr-2" type="submit">Guardar</button>
              <a href="javascript:;" class="btn tag text-orange " data-dismiss="modal">Cerrar</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal de eliminar participante comision -->
<div class="modal fade" id="modal-eliminar-participante-com">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Eliminar Participante Comisión</h4>
        <button type="button" id="close-modal-eliminar-participante-com" class="close" data-dismiss="modal"
          aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <h5 class="text-center">¿Deseas eliminar al participante seleccionado?</h5>
        <br><br>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-2"><a href="javascript:;" class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a>
          </div>
          <div class="col-md-4 text-right">
            <button type="button" class="btn btn-primary btn-block"
              (click)="onEliminarParticipanteCom()">Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>