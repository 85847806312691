<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h3 class="">{{licitacionData?.CONT_NOMBRE}}</h3>
                        </div>
                        <div class="col-12">
                            <span class="text-muted">
                                {{licitacionData?.DESCRIPCION}}
                            </span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="mr-3 ml-3">
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Licitación: </span> <strong>{{idLicitacion}}</strong></span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Solpe: </span> {{licitacionData?.SOLPE_CORRELATIVO}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Monto: </span> {{licitacionData?.MONTO_SOLPE | number: '1.2'}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Moneda: </span> {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Contrato: </span> {{licitacionData?.CONT_NUMERO}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Fecha: </span> {{licitacionData?.FECHA_CREACION}}</span>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <form [formGroup]="formDocRequeridosOferenteActa" (ngSubmit)="onAddDocRequeridosOferenteActa()">
                                <div class="row form-group">
                                    <label for="docRequeridoOferente" class="col-md-3 col-form-label">Doc. Requerido:</label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="docRequeridoOferenteCorrelativo" #docRequeridoOferenteCorrelativo>
                                            <ng-container *ngFor="let doc of docRequeridosOferenteActa">
                                                <option value="{{doc.correlativo}}">{{doc.descripcion}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button type="submit" class="btn btn-primary" [disabled]="formDocRequeridosOferenteActa.invalid">Agregar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6">
                            <ng-container *ngIf="docRequeridosOferenteToSave.length > 0">
                                <div class="docRequeridoContainer" *ngFor="let doc of docRequeridosOferenteToSave">
                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="text-muted">{{ doc.tipo_doc_descripcion }}</h5>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="doc.tipo_doc_correlativo === 2" class="row">
                                        <div class="col-12">
                                            <h5 class="text-muted">{{ doc.tipo_doc_descripcion }}</h5>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-8">
                                            <span class="text-muted"><i class="far fa-file-alt text-primary mr-2"></i> {{doc.descripcion}}</span>
                                        </div>
                                        <div class="col-4 text-right">
                                            <div 
                                                title="Eliminar Documento" 
                                                class="btn rounded-icon mr-2"
                                                (click)="deleteDocRequeridoOferente(doc.correlativo)"
                                            >
                                                <i class="fa fa-trash-alt"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="docRequeridosOferenteToSave.length <= 0">
                                <p class="text-muted text-center">No hay documentos requeridos seleccionados.</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>