import { Component, OnInit } from '@angular/core';
import { LicitacionService } from '../shared/services/licitacion.service';

@Component({
  selector: 'app-cronograma',
  templateUrl: './cronograma.component.html',
  styleUrls: ['./cronograma.component.css']
})
export class CronogramaComponent implements OnInit {
  licitaciones: [];
  constructor(private licitacionService: LicitacionService) { }

  ngOnInit(): void {
    this.licitacionService.getLicitaciones('S').subscribe(resp=>{
      this.licitaciones = JSON.parse(resp);
    });
  }

}
