import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';

@Component({
  selector: 'app-view-licitacion',
  templateUrl: './view-licitacion.component.html',
  styleUrls: ['./view-licitacion.component.css']
})
export class ViewLicitacionComponent implements OnInit {
  id:number;
  etapasLicitacion :[] = [];
  constructor(private route: ActivatedRoute, private router: Router, private licitacionService : LicitacionService) { }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.id = params['id'];
        const postData : {in_licitacion: number;in_usuario: string} ={ in_licitacion: this.id, in_usuario:''};
        this.licitacionService.postEtapaLicitacionId(postData).subscribe(resp=>{
          this.etapasLicitacion = JSON.parse(resp);
        });

    });
  }

}
