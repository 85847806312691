import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Params } from "@angular/router";
import { LicitacionService } from "../shared/services/licitacion.service";
import { NotificationService } from "../shared/services/notification.service";
import { ParticipantesService } from "../shared/services/participantes.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";

interface HeaderLicitacion {
  SOLPE_CORRELATIVO: string;
  CONT_NOMBRE: string;
  CONT_NUMERO: string;
  DESCRIPCION: string;
  FECHA_CREACION: string;
  MONTO_SOLPE: number;
  MONEDA_SOLPE: number;
  DESCRIPCION_MONEDA_SOLPE: string;
  ETAPA_CORRELATIVO: number;
  CORRELATIVO_ETAPA: number;
  ETAPA_DESC: string;
  LICITACION_CORRELATIVO: number;
  FECHA_PUBLICACION: null | string;
  CONSULTAS_APROBADAS: number;
  RESP_PROPCESO: number;
  CORRELATIVO_SOLPE: number;
  VALIDACION_LCT: number;
  OBSERVACION_LCT: string;
  MONTO_SOLPE_USD: number;
  ORDEN: number;
  VALIDACION_JAC: number;
  OBSERVACION_JAC: string;
  TIENE_ACLARACIONES_TEC: number;
  TIENE_ACLARACIONES_ECO: number;
  TIENE_NEGOCIACION: number;
  LICITACION_FINALIZADA: number;
}

export interface Participangtes {
  APROBO_EVAL_TEC: number;
  APROBO_EVAL_ECO: number;
  ADJUDICADO: number;
}

@Component({
  selector: "app-adjudicacion-contratista",
  templateUrl: "./adjudicacion-contratista.component.html",
  styleUrls: ["./adjudicacion-contratista.component.css"],
})
export class AdjudicacionContratistaComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  userId: string;
  licitacionData: HeaderLicitacion;
  oferenteDataEstatus: Participangtes;
  cartasAdjudicacion: any;
  cartaCorrelativo: number;
  formDocCartaAdjudicacion: FormGroup;
  isCartaEnviada: boolean;
  isCartaAdjudicacion: boolean;
  isCartaAgradecimiento: boolean;
  cartasAgradecimientoOferente: any;
  // cargar archivos para la carta de adjudicacion
  archivos64: Blob;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivo: string;
  @ViewChild("inputFile")
  inputFile: ElementRef;
  uploadFileModal: boolean = false;

  @ViewChild("closeModalAdjudicarCarta") closeModalAdjudicarCarta: ElementRef;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private participanteService: ParticipantesService
  ) {
    this.formDocCartaAdjudicacion = new FormGroup({
      descripcionCartaAdjudicacion: new FormControl(null, Validators.required),
    });

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.userId = JSON.parse(localStorage.getItem("userData")).userId;
      this.cartaCorrelativo = 0;
      this.isCartaEnviada = false;
      this.isCartaAdjudicacion = false;
      this.isCartaAgradecimiento = false;

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const licitacion: HeaderLicitacion = JSON.parse(
            resp
          )[0] as HeaderLicitacion;
          this.licitacionData = licitacion;

          console.log(this.licitacionData);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );
    });
  }

  ngOnInit(): void {
    this.getInformacionParticipantes();
    this.getCartaAdjudicacion();
    this.getSelectCartasAgradecimientoOferente();
  }

  back(): void {
    this.location.back();
  }

  getInformacionParticipantes() {
    this.participanteService
      .getSelectParticipanteByCorrelativo(this.idLicitacion)
      .subscribe((resp) => {
        const participantes: Participangtes = JSON.parse(
          resp
        )[0] as Participangtes;
        this.oferenteDataEstatus = participantes;
      });
  }

  getCartaAdjudicacion() {
    this.licitacionService
      .getSelectCartasAdjudicacionOferente(this.idLicitacion)
      .subscribe(
        (resp) => {
          const cartasAdjudicacion: any[] = JSON.parse(resp);

          if (cartasAdjudicacion.length > 0) {
            const cartasAdjudicacion: any[] = JSON.parse(resp);
            this.isCartaAdjudicacion = true;
            this.cartasAdjudicacion = cartasAdjudicacion[0];

            this.cartaCorrelativo = this.cartasAdjudicacion.correlativo;
            this.isCartaEnviada =
              this.cartasAdjudicacion.nombre_archivo_respuesta_oferente != null;
          } else {
            this.isCartaAdjudicacion = false;
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cartas de adjudicacion"
          );
        }
      );
  }

  detectFiles(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64;
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
        }
      };
    }
  }

  enviarCartaAdjudicacion() {
    if (this.formDocCartaAdjudicacion.invalid) {
      this.notifyService.showWarning(
        "Todos los campos son obligatorios",
        "Advertencia"
      );
      return;
    }

    const postData: {
      fileName: string;
      fileDescripcion: string;
      type: string;
      files: Blob;
      correlativoCarta: number;
      idLicitacion: number;
      nombreOferente: string;
    } = {
      fileName: this.fileName,
      fileDescripcion:
        this.formDocCartaAdjudicacion.value.descripcionCartaAdjudicacion,
      type: this.type,
      files: this.archivos64,
      correlativoCarta: this.cartaCorrelativo,
      idLicitacion: this.idLicitacion,
      nombreOferente: JSON.parse(localStorage.getItem("userData")).userName,
    };

    if (postData.files) {
      this.licitacionService
        .postUpdataDocCartaAdjudicacionFirmada(postData)
        .subscribe(
          (resp) => {
            this.notifyService.showSuccess(
              "Carta de adjudicación agregada correctamente",
              "Carta de adjudicación"
            );

            this.getCartaAdjudicacion();

            this.formDocCartaAdjudicacion = new FormGroup({
              descripcionCartaAdjudicacion: new FormControl(
                null,
                Validators.required
              ),
            });

            this.archivos64 = null;
            this.fileName = "";
            this.type = "";
            this.inputFile.nativeElement.value = "";

            this.closeModalAdjudicarCarta.nativeElement.click();
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Carta de adjudicación"
            );
          }
        );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Carta de adjudicación"
      );
    }
  }

  getSelectCartasAgradecimientoOferente() {
    this.licitacionService
      .getSelectCartasAgradecimientoOferente(this.idLicitacion)
      .subscribe(
        (resp) => {
          const cartasAgradecimiento: any[] = JSON.parse(resp);

          if (cartasAgradecimiento.length > 0) {
            const cartasAgradecimient: any[] = JSON.parse(resp);
            this.cartasAgradecimientoOferente = cartasAgradecimient[0];
            this.isCartaAgradecimiento = true;
          } else {
            this.isCartaAgradecimiento = false;
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cartas de adjudicacion"
          );
        }
      );
  }
}
