<!-- begin #content -->
<div id="content" class="content no-print">
    <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-primary" routerLink="/licitaciones"><i _ngcontent-ftv-c76=""
                class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
        <app-view-print-button [printComponentId]="'printPage'" [cssFile]="cssFiles" [breakInside]="'auto'"
            [title]="'Licitacion'"></app-view-print-button>
    </div>
</div>

<!-- begin #content -->
<div id="content" class="content" id="printPage">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <!-- begin panel-heading -->
                <div class="panel-heading no-print">
                    <h4 class="panel-title">Editar licitacion</h4>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <div class="mb-3 print">
                        <img src="/assets/img/logo/logo_em_pdf.svg" style="height: 40px" />
                    </div>
                    <div class="row">
                        <div class="col">
                            <h4>Datos de la licitacion</h4>
                        </div>
                    </div>
                    <form [formGroup]="licitacionForm" novalidate (ngSubmit)="onUpdateLicitacion()">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Número Licitación:</label>
                            <div class="col-md-10">
                                <label class="col-form-label" id="nro_licitacion">{{idLicitacion}}</label>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Fecha Licitación:</label>
                            <div class="col-md-10">
                                <label class="col-form-label"
                                    id="fechaCreacionLicitacion">{{fechaCreacionLicitacion}}</label>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">SOLPE</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" formControlName="solpe">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Monto SOLPE <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" type="number" id="monto_solpe"
                                    formControlName="monto_solpe">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Moneda SOLPE <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <select class="default-select2 form-control no-print" id="moneda_solpe"
                                    formControlName="moneda_solpe" (change)="onUpdateMonedaSolpe()">
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of monedas; let i = index" value="{{item.id}}">
                                        {{item.description}}</option>
                                </select>
                                <input class="form-control print" type="text" [value]="moneda_solpe">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Número de contrato</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="ej: CO-0XX/2018 ..."
                                    id="nro_contrato" formControlName="nro_contrato">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Nombre de contrato</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" id="nombre_contrato"
                                    formControlName="nombre_contrato">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Descripcion de la licitacion <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <textarea class="form-control" id="descripcion"
                                    formControlName="descripcion"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button type="submit" class="btn btn-success"><i class="fas fa-lg fa-fw m-r-10 fa-save"
                                        style="color: white;"></i>Guardar y Continuar</button>
                            </div>
                        </div>
                        <!-- <div class="row mb-3 mt-5">
                            <div class="col">
                                <h4>Cronograma</h4>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2"></label>
                            <div class="col-md-10">
                                <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                                    <thead>
                                        <tr>
                                            <th width="5%"></th>
                                            <th class="text-nowrap" style="vertical-align: top; text-align: center;">Actividad</th>
                                            <th class="text-nowrap" style="vertical-align: top; text-align: center;">
                                                <div class="row">
                                                  <div class="col col-md-6" style=" text-align: left; ">
                                                      Fecha Inicio
                                                  </div> -->
                        <!-- <div class="col col-md-2" style=" text-align: left; ">
                                                    Hora Inicio
                                                  </div> -->
                        <!-- <div class="col col-md-6" style=" text-align: left; ">
                                                    Fecha Termino
                                                  </div> -->
                        <!-- <div class="col col-md-2" style=" text-align: left; ">
                                                    Hora Termino
                                                  </div> -->
                        <!-- </div>
                                            </th> -->
                        <!--<th width="5%" class="text-nowrap" style="vertical-align: top; text-align: center;">Nro. Horas</th>-->
                        <!-- <th class="text-nowrap" style="vertical-align: top; text-align: center;">Fecha Inicio</th>
                                            <th class="text-nowrap" style="vertical-align: top; text-align: center;">Fecha Termino</th> -->
                        <!-- </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd gradeX" *ngFor="let item of cronograma">
                                            <td width="5%">
                                                <input #proceso type="checkbox" id="proceso" name="proceso" (click)="onChangeProceso(item.CORRELATIVO, proceso.checked)" [disabled]="item.OBLIGATORIO ===1 ? true: false" [checked]="item.ACTIVO ==='S' ? true: false">
                                            </td>
                                            <td class="text-break">
                                                <span>
                                                    {{ item.ETAPA_DESC}}
                                                </span>
                                            </td>
                                            <td>
                                                <div class="row">
                                                  <div class="col col-md-6">
                                                    <input class="form-control"
                                                            #fecha_inicio
                                                            type="date"
                                                            id="fecha_inicio"
                                                            name="fecha_inicio"
                                                            (change)="onFechaInicio(item.ETAPA_CORRELATIVO,fecha_inicio.value)"
                                                            [(ngModel)]="fechaInicio[item.ETAPA_CORRELATIVO]"
                                                            [ngModelOptions]="{standalone: true}"> -->
                        <!-- <input type="datetime-local"> -->
                        <!-- </div> -->
                        <!-- <div class="col col-md-2">
                                                    <div class="input-group date">
                                                      <input class="form-control"
                                                            #hrs_inicio
                                                            type="number"
                                                            id="hr_inicio"
                                                            name="hr_inicio"
                                                            (change)="onCalHrInicio(item.ETAPA_CORRELATIVO,hrs_inicio.value)"
                                                            [(ngModel)]="hInicioTemp[item.ETAPA_CORRELATIVO]"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                  </div> -->
                        <!-- <div class="col col-md-6">
                                                      <input class="form-control"
                                                              #fecha_termino
                                                              type="date"
                                                              id="fecha_termino"
                                                              name="fecha_termino"
                                                              (change)="onFechaTermino(item.ETAPA_CORRELATIVO,fecha_termino.value)"
                                                              [(ngModel)]="fechaFin[item.ETAPA_CORRELATIVO]"
                                                              [ngModelOptions]="{standalone: true}">
                                                  </div> -->
                        <!-- <div class="col col-md-2">
                                                    <div class="input-group date">
                                                      <input class="form-control"
                                                            #hrs_termino
                                                            type="number"
                                                            id="hr_termino"
                                                            name="hr_termino"
                                                            (change)="onCalHrTermino(item.ETAPA_CORRELATIVO,hrs_termino.value)"
                                                            [(ngModel)]="hTerminoTemp[item.ETAPA_CORRELATIVO]"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                  </div> -->
                        <!-- </div>
                                            </td> -->
                        <!--<td>
                                                <input class="form-control" disabled type="number" id="horas{{item.CORRELATIVO}}" name="horas{{item.CORRELATIVO}}" [(ngModel)]="horasTemp[item.CORRELATIVO]" [ngModelOptions]="{standalone: true}">
                                            </td>-->
                        <!-- <td>
                                              <input style="background-color: transparent; border-color: transparent;" type="hidden" id="FechaIni{{item.ETAPA_CORRELATIVO}}" name="FechaIni{{item.ETAPA_CORRELATIVO}}" [(ngModel)]="fechaInicio[item.ETAPA_CORRELATIVO]" [ngModelOptions]="{standalone: true}"
                                              disabled>
                                              <div>{{fechaInicio[item.ETAPA_CORRELATIVO] | date: 'dd/MM/yyyy HH:mm'}}</div>
                                            </td>
                                            <td>
                                              <input style="background-color: transparent; border-color: transparent;" type="hidden" id="FechaFin{{item.ETAPA_CORRELATIVO}}" name="FechaFin{{item.ETAPA_CORRELATIVO}}" [(ngModel)]="fechaFin[item.ETAPA_CORRELATIVO]" [ngModelOptions]="{standalone: true}"
                                              disabled>
                                              <div>{{ fechaFin[item.ETAPA_CORRELATIVO] | date: 'dd/MM/yyyy HH:mm'}}</div>
                                            </td> -->
                        <!-- </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                        <app-cronograma-fechas [idLicitacion]="idLicitacion" [idEtapa]="0"></app-cronograma-fechas>

                        <app-table-participantes [idLicitacion]="idLicitacion"
                            [idEtapaLic]="idEtapaLic"></app-table-participantes>

                        <app-table-participantes-com [idLicitacion]="idLicitacion"></app-table-participantes-com>

                        <!-- <div class="row mb-3 mt-5">
                            <div class="col">
                                <h4>Documentos</h4>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2"></label>
                            <div class="col-md-10">
                                <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle" *ngIf="documentos && documentos.length > 0">
                                    <thead>
                                        <tr>
                                            <th class="text-nowrap">Documento</th>
                                            <th class="text-nowrap">Archivo</th>
                                            <th class="text-nowrap"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd gradeX" *ngFor="let item of documentos">
                                            <td class="text-break">{{item.DESCRIPCION}}</td>
                                            <td class="text-break">
                                                <span class="d-inline-block" style="width: 50%;">
                                                    <span>
                                                        {{item.DESCRIPCION_ARCHIVO}}
                                                    </span>
                                                </span>
                                                <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                                            </td>
                                            <td class="text-center">
                                                <input class="form-control" type="hidden" #idF value="{{item.CORRELATIVO}}" />
                                                <input class="" type="file" (change)="detectFiles($event, idF.value)" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                        <div class="row mb-3 mt-5">
                            <div class="col-6">
                                <h4>Criterios de evaluación técnica</h4>
                            </div>
                            <div class="col-6 text-right">
                                <button *ngIf="isCriteriosValido === false" type="button" class="btn tag text-orange"
                                    routerLink="/crear-criterios/{{idLicitacion}}/0/{{idEtapaLic}}/1"><i
                                        _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-15 fa-plus-circle"></i> Cargar
                                    Criterios</button>
                                <button *ngIf="isCriteriosValido === true" type="button" class="btn tag text-orange"
                                    routerLink="/crear-criterios/{{idLicitacion}}/0/{{idEtapaLic}}/1"><i
                                        _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-edit"></i> Modificar
                                    Criterios</button>
                            </div>
                            <create-criterios [idLicitacionParent]="idLicitacion"></create-criterios>
                        </div>
                        <div class="row mb-3 mt-5">
                            <div class="col-6">
                                <h4>Itemizado</h4>
                            </div>
                            <div class="col-6 text-right">
                                <button *ngIf="isitemizadoValido === false" type="button" class="btn tag text-orange"
                                    routerLink="/itemizado/{{idLicitacion}}/0/{{idEtapaLic}}/1"><i _ngcontent-ftv-c76=""
                                        class="fas fa-lg fa-fw m-r-10 fa-plus-circle"></i> Cargar Itemizado</button>
                                <button *ngIf="isitemizadoValido === true" type="button"
                                    class="btn tag text-orange m-r-5"
                                    routerLink="/itemizado/{{idLicitacion}}/0/{{idEtapaLic}}/1"><i _ngcontent-ftv-c76=""
                                        class="fas fa-lg fa-fw m-r-10 fa-edit"></i> Subir Itemizado</button>
                                <button *ngIf="isitemizadoValido === true" type="button" class="btn tag text-orange"
                                    routerLink="/ver-itemizado/{{idLicitacion}}/0/{{idEtapaLic}}/1"><i
                                        _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-eye"></i> Ver
                                    Itemizado</button>
                            </div>

                        </div>
                        <app-resultados-itemizado [idLicitacionParent]="idLicitacion"></app-resultados-itemizado>
                        <app-cargar-archivos-licitacion [idLicitacion]="idLicitacion"
                            [idEtapa]="idEtapaLic"></app-cargar-archivos-licitacion>
                        <!-- <hr>
                        <footer style="align-items: center; text-align: center" *ngIf="showGuardar">
                            <button type="submit" class="btn btn-success">Guardar</button><br>
                            <span class="text-muted mt-2">Nota: cuando los campos requeridos esten completos podra enviar la invitacion</span>
                        </footer> -->
                        <br>
                        <hr>
                        <br>
                        <footer style="align-items: right; text-align: right" *ngIf="showGuardar">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngIf="islicitacionValida === false" class="alert alert-danger text-center"
                                        role="alert">
                                        Licitación NO válida para enviar Invitación.
                                    </div>
                                    <div *ngIf="islicitacionValida === true" class="alert alert-success text-center"
                                        role="alert">
                                        Licitación válida para enviar Invitación.
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6"></div>
                                <div class="col-6">
                                    <a style="color: white;" class="btn btn-primary m-r-10" data-toggle="modal"
                                        data-target="#modalValidarLicitacion" (click)="onValidarLicitacion()"><i
                                            class="fas fa-lg fa-fw m-r-10 fa-check" style="color: white;"></i>Validar
                                        datos de la licitación</a>
                                    <button type="submit" class="btn btn-success"><i
                                            class="fas fa-lg fa-fw m-r-10 fa-save" style="color: white;"></i>Guardar y
                                        Continuar</button>
                                    <button *ngIf="islicitacionValida === true" type="button"
                                        class="btn btn-success m-l-10" (click)="onEnviarInvitacion()">Enviar
                                        invitación</button>
                                </div>
                            </div>
                        </footer>
                    </form>
                    <!-- <div class="row" *ngIf="showInvitacion" style="text-align-last: center;">
                        <div class="col col-md-12">
                            <button type="button" class="btn btn-success" (click)="onEnviarInvitacion()">Enviar invitación</button><br>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div *ngIf="islicitacionValida === false" class="alert alert-danger text-left" role="alert">
                                    Licitación NO valida para enviar Invitación.
                                </div>
                                <div *ngIf="islicitacionValida === true" class="alert alert-success text-left" role="alert">
                                    Licitación Valida para enviar Invitación.
                                </div>
                            </div>
                            <div class="col-6">
                                <a style="color: white;" class="btn btn-primary" data-toggle="modal" data-target="#modalValidarLicitacion" (click)="onValidarLicitacion()"><i class="fas fa-lg fa-fw m-r-10 fa-check" style="color: white;"></i>Validar datos de la licitación</a>
                                &nbsp;
                                <button *ngIf="islicitacionValida === false" type="submit" class="btn btn-success"><i class="fas fa-lg fa-fw m-r-10 fa-save" style="color: white;"></i>Guardar progreso</button>
                                <button *ngIf="islicitacionValida === true" type="button" class="btn btn-success" (click)="onEnviarInvitacion()">Enviar invitación</button><br>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>
<!-- Modal -->
<div class="modal fade" id="modalValidarLicitacion" tabindex="-1" role="dialog"
    aria-labelledby="modalValidarLicitacionCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalValidarLicitacionTitle">Validar datos de la licitación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <p>
                        <i *ngIf="isDatosBasicosValidos === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isDatosBasicosValidos === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Datos básicos de la licitación
                    </p>
                    <p>
                        <i *ngIf="isCronogramaValido === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isCronogramaValido === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Cronograma
                    </p>
                    <p>
                        <i *ngIf="isParticipantesValido === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isParticipantesValido === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Empresas participantes (Oferentes)
                    </p>
                    <p>
                        <i *ngIf="isComisionValidoTecnica === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isComisionValidoTecnica === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Comisión Técnica
                    </p>
                    <p>
                        <i *ngIf="isComisionValidoEconomica === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isComisionValidoEconomica === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Comisión Económica
                    </p>
                    <p>
                        <i *ngIf="isComisionValidoCoordinador === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isComisionValidoCoordinador === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Coordinador de Abastecimiento
                    </p>
                    <p>
                        <i *ngIf="isComisionValidoResponsable === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isComisionValidoResponsable === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Responsable del Proceso
                    </p>
                    <p>
                        <i *ngIf="isCriteriosValido === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isCriteriosValido === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Criterios de evaluación técnica
                    </p>
                    <p>
                        <i *ngIf="isitemizadoValido === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isitemizadoValido === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Itemizado
                    </p>
                    <p>
                        <i *ngIf="isDocumentosValido === false" class="fas fa-lg fa-fw m-r-10 fa-times"
                            style="color: red;"></i>
                        <i *ngIf="isDocumentosValido === true" class="fas fa-lg fa-fw m-r-10 fa-check"
                            style="color: green;"></i>
                        Archivos base ({{contArchivosBase}})
                    </p>
                </div>
                <hr>
                <div class="row">
                    <div style="border-right: solid 1px;" class="col-6">
                        <div class="row">
                            <div class="col-4">
                                <span>SOLPE:</span>
                            </div>
                            <div class="col-8 text-right">
                                <span style="font-weight: bold;">{{montoSolpe | number: '1.2'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <span>Itemizado:</span>
                            </div>
                            <div class="col-8 text-right">
                                <span style="font-weight: bold;">{{montoItemizado | number: '1.2'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <p>Diferencia: <span style="font-weight: bold;">{{diferenciaMontos | number: '1.2'}}</span></p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>