import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { LicitacionService } from "../shared/services/licitacion.service";
import { ActivatedRoute, Params } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-administrar-comision",
  templateUrl: "./administrar-comision.component.html",
  styleUrls: ["./administrar-comision.component.css"],
})
export class AdministrarComisionComponent implements OnInit {
  idLicitacion: number;
  licitacionData: any;
  idEtapa: number;
  idEtapaLic: number;
  comision: any[];
  partComisionFirmante: any[];

  constructor(
    private notifyService: NotificationService,
    private licitacionService: LicitacionService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.comision = [];
      this.partComisionFirmante = [];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const licitacion: any[] = JSON.parse(resp);
          this.licitacionData = licitacion[0];
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );
    });
  }

  ngOnInit(): void {
    this.licitacionService
      .getParticipantesComLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          if (JSON.parse(resp)) {
            this.partComisionFirmante = JSON.parse(resp).filter(
              (x) => x.TIPO_PARTICIPANTE === 37 || x.TIPO_PARTICIPANTE === 39
            );
            this.comision = JSON.parse(resp);
            this.comision = this.groupByKey(this.comision, "COMISION");
          }
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Obtener comision");
        }
      );
  }

  updateComision(arr: []) {
    this.comision = this.groupByKey(arr, "COMISION");
  }

  groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  back(): void {
    this.location.back();
  }
}
