import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FileService } from 'src/app/shared/services/file.service';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-view-respuesta-negociacion',
  templateUrl: './view-respuesta-negociacion.component.html',
  styleUrls: ['./view-respuesta-negociacion.component.css']
})
export class ViewRespuestaNegociacionComponent implements OnInit {
  
  idLicitacion:number;
  idParticipante:number;
  idEtapa:number;
  idEtapaLic:number;
  nombreEtapa: string;
  archivos64 : any[] = [];
  negociaciones:[];
  showBtn: boolean = false;
  datosParticipanteLic: any[];
  isEditarPresupuesto: boolean;

  @ViewChild('inputFile')
  inputFile: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private etapaService: EtapasService, 
    private licitacionService: LicitacionService, 
    private notifyService : NotificationService,
    private fileService: FileService
  ) {
    this.datosParticipanteLic = [];
    this.isEditarPresupuesto = false;
  }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        this.licitacionService.getDatosPart().subscribe(resp=>{      
          this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
          if(this.idParticipante){
            this.getNegociaciones(this.idLicitacion,this.idParticipante, this.idEtapa );
          }
        });

        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }

        this.licitacionService.getEtapaActualLicitacion(this.idLicitacion).subscribe(
          resp=>{
            const etapa: any[] = JSON.parse(resp);
            console.log(etapa[0].etapa_correlativo);

            if (etapa.length > 0){ 
              this.idEtapa = etapa[0].etapa_correlativo;

              if (etapa[0].etapa_correlativo == 13){ 
                this.licitacionService.getSelectParticipanteLic().subscribe(
                  resp=>{
                    this.datosParticipanteLic = JSON.parse(resp);

                    this.isEditarPresupuesto = this.validateCorreccionPresupuesto();
                    console.log(this.isEditarPresupuesto);
                  },
                  err =>{
                    this.notifyService.showError(err.error.message, 'Obtener datos del participante Lic');
                  }
                );
              }
            }
          },
          error => console.log(error)
        );
    });
  }

  private getNegociaciones(idLic: number, idPart: number, idEtapa: number){
    this.licitacionService.getNegocacionPart(idLic,idPart, idEtapa).subscribe(resp=>{
      if (JSON.parse(resp)){
        this.showBtn = JSON.parse(resp).length === 0 ? false:  true;
        this.negociaciones = JSON.parse(resp);
        this.negociaciones.forEach(element => {
          let correlativo = JSON.parse(JSON.stringify(element)).CORRELATIVO;
          this.archivos64.push({ correlativo: correlativo, path:null });        
        });
      }
    }, err =>{
      this.notifyService.showError(err.error.message, 'Negociaciones');
    });
  }
  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }
  detectFiles(event: any, idF: any) {
    const reader = new FileReader(); 
    let bs64 = null;
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);       
      reader.onload = () => {
        bs64 = reader.result;        
        if (bs64 !== null){
          this.archivos64.map((item, i) => {
            if (item.correlativo == idF){  
              this.archivos64[i].fileName = event.target.files[0].name;
              this.archivos64[i].type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;                       
              this.archivos64[i].path = bs64; 
             }
           });
        }
      };
    }
  }



  onGuardarRespuesta(){
    let idArchivo: number;
    if (this.archivos64){
      this.archivos64.forEach(element => {
        let elem = JSON.parse(JSON.stringify(element));
        if (elem.fileName){
          const postData: { in_det_etapa_lic: number;
                            files: Blob; 
                            fileName: string; 
                            type : string; 
                            in_usuario: string; 
                            in_part_correlativo? : number;
                            in_domi_correlativo_tiar?: number;
                            publico: number;
                            descripcion_input_archivo: string;
                            idLicitacion: number;
                            idEtapaLicitacion: number;
                            correlativoGrupoArchivo: number
                          }={
                            in_det_etapa_lic: this.idEtapaLic,
                            files: elem.path, 
                            fileName: elem.fileName, 
                            type : elem.type, 
                            in_usuario: '',
                            in_part_correlativo : this.idParticipante,
                            in_domi_correlativo_tiar: 27,
                            publico: 1,
                            descripcion_input_archivo: 'Archivo de Respuesta de Negociación',
                            idLicitacion: this.idLicitacion,
                            idEtapaLicitacion: this.idEtapa,
                            correlativoGrupoArchivo: 0
                          }
          this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
            idArchivo = JSON.parse(resp)[0].SEQ_ARCHDETETLIC;
            const putData: { in_acdetlic_neg_correlativo: number;in_archetlic_neg_correlativo: number;in_archetlic_resp_correlativo: number; in_usuario:string; in_accion: string } =
                           { in_acdetlic_neg_correlativo: elem.correlativo , in_archetlic_neg_correlativo: null , in_archetlic_resp_correlativo: idArchivo , in_usuario: '', in_accion : 'RESPUESTA' }
            this.licitacionService.putNegociacionArchivo(putData).subscribe(resp =>{
              this.notifyService.showSuccess('Respuesta enviada correctamente', 'Envio de respuesta negociación');
              this.getNegociaciones(this.idLicitacion,this.idParticipante, this.idEtapa );
            }, err =>{
              this.notifyService.showError(err.error.message, 'Archivo Negociación');
            });
          });
        }
      });

      this.archivos64 = null; 
      this.inputFile.nativeElement.value = "";
    }
  }

  validateCorreccionPresupuesto(): boolean {
    return this.datosParticipanteLic.some((item: { lic_correlativo: number; corregir_presupuesto: number }) => item.lic_correlativo == this.idLicitacion && item.corregir_presupuesto === 1);
  }

}
