<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h3 class="">{{licitacionData?.CONT_NOMBRE}}</h3>
                        </div>
                        <div class="col-12">
                            <span class="text-muted">
                                {{licitacionData?.DESCRIPCION}}
                            </span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="mr-3 ml-3">
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Licitación: </span> <strong>{{idLicitacion}}</strong></span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Solpe: </span> {{licitacionData?.SOLPE_CORRELATIVO}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Monto: </span> {{licitacionData?.MONTO_SOLPE | number: '1.2'}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Moneda: </span> {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Contrato: </span> {{licitacionData?.CONT_NUMERO}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Fecha: </span> {{licitacionData?.FECHA_CREACION}}</span>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <h4 class="mt-3 mb-3 text-muted">Observaciones</h4>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn btn-primary" data-toggle="modal" data-target="#agregarObservacionesModal"><i class="fas fa-lg fa-fw m-r-5 fa-plus-circle" style="color: white;"></i> Agregar</button>
                        </div>
                        <div class="col-12">
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Observaciones</th>
                                    <th>Etapa</th>
                                    <th>Usuario</th>
                                    <th>Fecha Creación</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of observacionesEtapasLicitacion">
                                        <ng-container>
                                            <td>
                                                {{ item.observaciones }}
                                            </td>
                                            <td>
                                                {{ item.descripcion }}
                                            </td>
                                            <td>
                                                {{ item.nombre_usuario_creacion }}
                                            </td>
                                            <td>
                                                {{ item.fecha_creacion | date: 'dd/MM/yyyy'}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal agregar observaciones a etapa actual de la licitacion-->
<div class="modal fade" id="agregarObservacionesModal" tabindex="-1" role="dialog" aria-labelledby="agregarObservacionesModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="agregarObservacionesModalLabel">Agregar observaciones a: {{licitacionData.ETAPA_DESC}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModalAddObservaciones>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-muted">Observaciones:</p>
          <textarea class="form-control" [(ngModel)]="observaciones" rows="5"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onAddObservacion()">Agregar</button>
        </div>
      </div>
    </div>
</div>