<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
        routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76=""
            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Aclaraciones tecnicas licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div *ngIf="objLicitacion?.TIENE_ACLARACIONES_TEC === 1" class="row">
        <!-- begin col-6 -->
        <div class="col-12 col-md-6">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Enviar Aclaraciones tecnicas a los Oferentes</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <ng-container
                        *ngIf="!validateRoleParticipanteComision(39) || !validateRoleParticipanteComision(37)">
                        <div class="text-center">
                            <p>Esta acción solo la pueden visualizar los responsables de la licitación.</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="validateRoleParticipanteComision(39) || validateRoleParticipanteComision(37)">
                        <ng-container>
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Oferente</th>
                                    <th>Estado</th>
                                    <th class="text-left">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of participantes">
                                        <ng-container *ngIf="item.VIGENTE == 'S'">
                                            <td>
                                                {{ item.PART_NOMBRE }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green"
                                                    *ngIf="item.VALIDACION_ACLARACION_TEC === 1">
                                                    Permitido
                                                </span>
                                                <span class="tab tab-red" *ngIf="item.VALIDACION_ACLARACION_TEC === 0">
                                                    No permitido
                                                </span>
                                            </td>
                                            <td class="text-right">
                                                <div class="row text-right">
                                                    <!-- validar por parte del responsable del proceso -->
                                                    <a *ngIf="validateRoleParticipanteComision(37) && item.VALIDACION_ACLARACION_TEC === 0"
                                                        title="Permitir enviar aclaraciones"
                                                        class="btn rounded-icon mr-2" data-toggle="modal"
                                                        data-target="#validarAclaracionOferente"
                                                        (click)="oferenteSelected(item.PART_CORRELATIVO, item.PART_NOMBRE)">
                                                        <i class="fa fa-check"></i>
                                                    </a>
                                                    <!-- redactar aclaraciones por parte del lider de la comision tecnica -->
                                                    <a *ngIf="(validateRoleParticipanteComision(39) || validateRoleParticipanteComision(37)) && item.VALIDACION_ACLARACION_TEC === 1"
                                                        title="Redactar aclaraciones" class="btn rounded-icon mr-2"
                                                        data-toggle="modal"
                                                        (click)="isValidateByResponsable = item.VALIDACION_ACLARACION_TEC;">
                                                        <i class="fa fa-paper-plane"></i>
                                                    </a>
                                                    <!-- solicitar aclaraciones por parte del responsable del proceso -->
                                                    <a *ngIf="validateRoleParticipanteComision(39) && item.VALIDACION_ACLARACION_TEC === 0"
                                                        title="solicitar enviar aclaraciones"
                                                        class="btn rounded-icon mr-2" data-toggle="modal"
                                                        (click)="oferenteSelected(item.PART_CORRELATIVO, item.PART_NOMBRE)"
                                                        data-target="#solicitarAclaracionOferente">
                                                        <i class="fa fa-paper-plane"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                        <!-- Redactar aclaraciones en caso de que se le haya consedido permiso lider de la comision tecnica para el ofereten seleccionado -->
                        <ng-container
                            *ngIf="(validateRoleParticipanteComision(39) || validateRoleParticipanteComision(37)) && isValidateByResponsable === 1">
                            <hr>
                            <h3 class="text-muted">Redactar Aclaraciones</h3>
                            <br>
                            <form [formGroup]="aclaracionForm" novalidate (ngSubmit)="onGuardarAclaracion()">
                                <div class="form-group row m-b-15">
                                    <!-- begin col-6 -->
                                    <div class="col-xl-12">
                                        <div class="form-group row m-b-15">
                                            <div class="col col-md-3">
                                                <span>
                                                    Participante
                                                </span>
                                            </div>
                                            <div class="col col-md-9">
                                                <select class="form-control" formControlName="participante">
                                                    <option value="0">Seleccione...</option>
                                                    <ng-container *ngFor="let item of participantes">
                                                        <option
                                                            *ngIf="item.VIGENTE == 'S' && item.VALIDACION_ACLARACION_TEC === 1"
                                                            value="{{item.PART_CORRELATIVO}}">{{
                                                            item.PART_NOMBRE}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row m-b-15">
                                            <div class="col col-md-3">
                                                <span>
                                                    Tipo aclaración
                                                </span>
                                            </div>
                                            <div class="col col-md-9">
                                                <select class="form-control" formControlName="tipo_aclaracion">
                                                    <option value="0">Seleccione...</option>
                                                    <option *ngFor="let item of tipo_consultas"
                                                        value="{{item.CORRELATIVO}}">{{
                                                        item.DESCRIPCION}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row m-b-15">
                                            <div class="col col-md-3">
                                                <span>
                                                    Descripción de aclaración
                                                </span>
                                            </div>
                                            <div class="col col-md-9">
                                                <textarea class="form-control " rows="2"
                                                    formControlName="desc_aclaracion"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row m-b-15">
                                            <div class="col col-md-3">
                                                <span>
                                                    Descripción Archivo
                                                </span>
                                            </div>
                                            <div class="col col-md-9">
                                                <input class="form-control" type="text" placeholder=""
                                                    id="nombreArchivo" formControlName="nombreArchivo">
                                            </div>
                                        </div>
                                        <div class="form-group row m-b-15">
                                            <div class="col col-md-3">
                                                <span>
                                                    Seleccionar archivo
                                                </span>
                                            </div>
                                            <div class="col col-md-9">
                                                <input #inputFile class="form-control"
                                                    style="border-color: transparent;" name="archivo" type="file"
                                                    (change)="detectFiles($event)" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end col-6 -->
                                    <div class="col col-md-12" style="margin-top: 10px; text-align: center;">
                                        <button class="btn btn-primary" type="submit"
                                            [disabled]="isDisable">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Ofertas técnicas enviadas</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <p>Visualice las Ofertas técnicas enviadas para realizar las aclaraciones correspondientes en caso
                        de que existan.</p>
                    <div class="text-center">
                        <a class="btn btn-primary" routerLink="/calificar/{{idLicitacion}}">Ver Ofertas técnicas</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Aclaraciones enviadas</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">
                                    Aclaracion tecnica
                                </th>
                                <th class="text-nowrap">
                                    Tipo
                                </th>
                                <th class="text-nowrap">
                                    Enviada a
                                </th>
                                <th class="text-nowrap">
                                    Fecha creación
                                </th>
                                <th class="text-nowrap">
                                    Archivo
                                </th>
                                <th class="text-nowrap">
                                    Descargar archivos

                                </th>
                                <th class="text-nowrap">
                                    Respuestas
                                </th>
                                <th class="text-nowrap">
                                    Fecha creación
                                </th>
                                <th class="text-nowrap">
                                    Descargar archivos
                                </th>
                                <th class="text-nowrap">
                                    Enviar al oferente
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of aclaraciones">
                                <td class="text-break">
                                    <span>
                                        {{ item.ACLARACION }}
                                    </span>
                                </td>
                                <td class="text-break">
                                    <span>
                                        {{ item.TIPO_ACLARACION }}
                                    </span>
                                </td>
                                <td class="text-break">
                                    <span>
                                        {{ item.PART_NOMBRE }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item.FECHA_CREACION }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item.DESCRIPCION }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_ACL"
                                        [nombreArchivo]="item.NOMBRE_ARCHIVO_ACL"
                                        style="margin-right: 10px;"></app-view-file-buttons>
                                </td>
                                <td>
                                    <span>
                                        {{ item.NOMBRE_ARCHIVO_RESP }}
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        {{ item.FECHA_CREACION_ARCHIVO }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_RESP"
                                        [nombreArchivo]="item.NOMBRE_ARCHIVO_RESP"></app-view-file-buttons>
                                </td>
                                <td class="text-center">
                                    <!-- boton para enviar aclaraciones al oferente -->
                                    <ng-container *ngIf="validateRoleParticipanteComision(37)">
                                        <ng-container *ngIf="item.visible === 0">
                                            <button class="btn btn-primary" data-toggle="modal"
                                                data-target="#EnviarAclaracionAlOferenteModal"
                                                (click)="onAclaracionSelectedToVisible(item.CORRELATIVO, item.PART_CORRELATIVO)">Enviar</button>
                                        </ng-container>
                                        <ng-container *ngIf="item.visible === 1">
                                            <span class="tab tab-green">Enviada</span>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!validateRoleParticipanteComision(37)">
                                        <span class="tab tab-green" *ngIf="item.visible === 1">Enviada</span>
                                        <span class="tab tab-red" *ngIf="item.visible === 0">No aprobada</span>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="objLicitacion?.TIENE_ACLARACIONES_TEC === 0">
        <ng-container *ngIf="objLicitacion.ETAPA_CORRELATIVO == 8">
            <div class="row">
                <div class="col-12 mt-5">
                    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                        <div class="panel-heading">
                            <h4 class="panel-title">Validar aclaraciones técnicas</h4>
                            <div class="panel-heading-btn">
                                <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                                    data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                            </div>
                        </div>
                        <div class="panel-body text-center">
                            <h4 class="text-muted">Se debe validar si la licitación tendrá aclaraciones técnicas por
                                parte del responsable del proceso.</h4>
                            <ng-container *ngIf="validateRoleParticipanteComision(37)">
                                <br>
                                <button class="btn btn-primary mr-2" data-toggle="modal"
                                    data-target="#rechazarAclaracionesTecModal">Rechazar</button>
                                <button class="btn btn-primary" data-toggle="modal"
                                    data-target="#aceptarAclaracionesTecModal">Aceptar y validar</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="objLicitacion.ETAPA_CORRELATIVO != 8">
            <div class="row">
                <div class="col-12 mt-5">
                    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                        <div class="panel-heading">
                            <h4 class="panel-title">Aclaraciones técnicas</h4>
                            <div class="panel-heading-btn">
                                <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                                    data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                            </div>
                        </div>
                        <div class="panel-body text-center">
                            <h4 class="text-muted">No fueron necesarias las aclaraciones técnicas para esta licitación.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <chat [idLicitacion]="idLicitacion"></chat>
</div>
<!-- end #content -->
<!-- Modal validar aclaraciones oferente-->
<div class="modal fade" id="validarAclaracionOferente" tabindex="-1" role="dialog"
    aria-labelledby="validarAclaracionOferenteLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="validarAclaracionOferenteLabel">Permitir enviar aclaraciones al oferente
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Esta seguro que desea permitir que se le soliciten aclaraciones al oferente: <span
                        class="bold">{{oferenteSeleccionadoNombre}}</span>?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="permitirEnvioAclaracionTecnica()">Permitir</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal solicitud de validacion para enviar aclaraciones oferente -->
<div class="modal fade" id="solicitarAclaracionOferente" tabindex="-1" role="dialog"
    aria-labelledby="solicitarAclaracionOferenteLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="solicitarAclaracionOferenteLabel">Solicitar aclaraciones
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Desea solicitar aclaraciones al oferente: <span class="bold">{{oferenteSeleccionadoNombre}}</span>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="generateNotificacionValidacionAclaracionTecnica()">Solicitar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal definir si habra aclaraciones o no-->
<div class="modal fade" id="aceptarAclaracionesTecModal" tabindex="-1" role="dialog"
    aria-labelledby="aceptarAclaracionesTecLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="aceptarAclaracionesTecLabel">Aclaraciones técnicas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Desea aceptar la etapa de aclaraciones técnicas?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="aceptarAclaracionesTec()">Aceptar Etapa</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal definir si habra aclaraciones o no (caso de rechazo)-->
<div class="modal fade" id="rechazarAclaracionesTecModal" tabindex="-1" role="dialog"
    aria-labelledby="rechazarAclaracionesTecLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="rechazarAclaracionesTecLabel">Aclaraciones técnicas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-muted">IMPORTANTE: Una vez rechazada esta estapa se avanzara a la siguiente y no se podra
                    volver atras.</p>
                <p>¿Desea rechazar la etapa de aclaraciones técnicas?</p>
                <p>Ingrese sus observaciones:</p>
                <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="onAvanzaEtapa()">
                    Rechazar y avanzar
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="EnviarAclaracionAlOferenteModal" tabindex="-1" role="dialog"
    aria-labelledby="EnviarAclaracionAlOferenteModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="EnviarAclaracionAlOferenteModalLabel">Enviar aclaración al oferente</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea enviar esta aclaración al oferente?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="aclaracionVisibleOferente()">Enviar</button>
            </div>
        </div>
    </div>
</div>