<!-- begin #content -->
<div id="content" class="content">
    <div class="row">
        <!-- begin col-3 -->
        <!-- <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-blue">
                <div class="stats-icon"><i class="far fa-lg fa-fw m-r-10 fa-file-alt"></i></div>
                <div class="stats-info">
                    <h4>En creación</h4>
                    <p>{{en_creacion}}</p>
                </div>
                <div class="stats-link">
                    <a routerLink="/licitaciones/0">Ver detalle<i class="fa fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div> -->
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <!-- <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-info">
                <div class="stats-icon"><i class="fas fa-cog fa-spin"></i></div>
                <div class="stats-info">
                    <h4>En proceso</h4>
                    <p>{{en_proceso}}</p>
                </div>
                <div class="stats-link">
                    <a routerLink="/licitaciones/1">Ver detalle<i class="fa fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div> -->
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <!-- <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-orange">
                <div class="stats-icon"><i class="fas fa-lg fa-fw m-r-10 fa-archive"></i></div>
                <div class="stats-info">
                    <h4>Adjudicadas</h4>
                    <p>{{adjudicadas}}</p>
                </div>
                <div class="stats-link">
                    <a routerLink="/licitaciones/2">Ver detalle<i class="fa fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div> -->
        <!-- end col-3 -->
    </div>
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <!-- begin panel-heading -->
                <!-- <div class="panel-heading">
                    <h4 class="panel-title">Licitaciones</h4>
                </div> -->
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <div class="row mb-4">
                        <div class="col-6">
                            <h4>Licitaciones</h4>
                        </div>
                        <div class="col-6 text-right">
                            <app-view-excel-buttons [title]="'Licitaciones'" [filename]="'licitaciones'"
                                [headers]="excelHeaders" [data]="licitaciones" [variant]="'tag'"
                                style="margin-right: 10px;">
                            </app-view-excel-buttons>
                            <!-- <a class="btn-primary btn" routerLink="/create-licitacion">
                                Agregar
                            </a> -->

                            <button *ngIf="globalData.existeAccion(2)" (click)="getSolpes()" class="btn-primary btn"
                                href="#modalAgregar" data-toggle="modal">
                                Agregar
                            </button>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-md-8 text-left">
                            <ng-container *ngIf="globalData.getRol == '12'">
                                <button [ngClass]="{'text-primary': idFiltro == 0}" (click)="FilterTable(0)"
                                    class="btn tag mb-1 text-bolder text-muted"
                                    style="margin-right: 5px;">Todas</button>
                                <button [ngClass]="{'text-primary': idFiltro == 1}" (click)="FilterTable(1)"
                                    class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;">En creación
                                    {{en_creacion}}</button>
                                <button [ngClass]="{'text-primary': idFiltro == 2}" (click)="FilterTable(2)"
                                    class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;">En proceso
                                    {{en_proceso}}</button>
                                <button [ngClass]="{'text-primary': idFiltro == 3}" (click)="FilterTable(3)"
                                    class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;">Adjudicadas
                                    {{adjudicadas}}</button>
                            </ng-container>
                        </div>
                        <div class="col-12 col-md-4 text-right">
                            <div class="input-group">
                                <input class="form-control" type="text" [(ngModel)]="filterLicitacion"
                                    name="filterLicitacion" id="filterLicitacion" (keyup)="applyFilterSearch()"
                                    placeholder="Buscar">
                                <span class="input-group-addon"><i class="fas fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <!-- <a routerLink="/create-licitacion">
                        <i class="fa fa-cogs"></i>
                        <span>Crear licitacion</span>
                    </a> -->
                    <div class="row">
                        <div class="col-md-12">
                            <table id="data-table-default" class="table table-striped table-td-valign-middle">
                                <thead class="">
                                    <tr>
                                        <th>#</th>
                                        <th>Nro. Contrato</th>
                                        <th>Nombre</th>
                                        <th class="text-nowrap">Etapa</th>
                                        <th class="text-nowrap">Inicio etapa</th>
                                        <th class="text-nowrap">Fin etapa</th>
                                        <th class="text-nowrap">Oblibatoria</th>
                                        <th class="text-nowrap">Detalle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd gradeX" *ngFor="let item of licitacionesFiltered">
                                        <td>
                                            <label>{{ item.LIC_CORRELATIVO}}</label>
                                        </td>
                                        <td class="text-break">
                                            <label>{{ item.CONTRATO_CORRELATIVO}}</label>
                                        </td>
                                        <td class="text-break">
                                            <label>{{ item.CONTRATO_NOMBRE }}</label>
                                        </td>
                                        <td class="text-break">
                                            <span *ngIf="item.LICITACION_FINALIZADA != 1" class="tab tab-green">{{
                                                item.ETAPA_DESCRIPCION}}</span>
                                            <span *ngIf="item.LICITACION_FINALIZADA == 1"
                                                class="tab tab-red">Adjudicada</span>
                                        </td>
                                        <td>
                                            <label>{{ item.FECHA_INICIO_ETAPA | date: 'dd/MM/yyyy'}}</label>
                                        </td>
                                        <td>
                                            <label>{{ item.FECHA_TERMINO_ETAPA | date: 'dd/MM/yyyy'}}</label>
                                        </td>
                                        <td>
                                            <span class="tab tab-yellow" *ngIf="item.OBLIGATORIA === 1">
                                                Obligatoria
                                            </span>
                                        </td>
                                        <td>
                                            <!-- Nueva botonera -->
                                            <div class="dropdown d-flex-inline ml-2">
                                                <button class="btn tag dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Opciones
                                                    <span class="caret ml-1"></span>
                                                </button>
                                                <ng-container *ngIf="item.LICITACION_FINALIZADA != 1">
                                                    <!-- opciones fuera de las funciones del JAC -->
                                                    <ng-container *ngIf="!validateCargoJac()">
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li><a routerLink="/acta-inicial/{{item.LIC_CORRELATIVO}}">Acta
                                                                    de inicio</a></li>
                                                            <ng-container *ngIf="item.ETAPA_CORRELATIVO === 0">
                                                                <li *ngIf="validateBtnDeclaracionJurada(item.LIC_CORRELATIVO) === 1"
                                                                    href="#modalDeclaracion" data-toggle="modal"
                                                                    (click)="cargarDatosLicitacion(item.LIC_CORRELATIVO); getParticipantes(item.LIC_CORRELATIVO, item.ETLIC_CORRELATIVO); resetModalForm()">
                                                                    <a href="javasritp:;">Declaración de inhabilidad</a>
                                                                </li>
                                                                <li
                                                                    *ngIf="validateBtnDeclaracionJurada(item.LIC_CORRELATIVO) === 2">
                                                                    <a class="text-success">Declaración cargada</a>
                                                                </li>
                                                            </ng-container>
                                                            <li><a *ngIf="item.ETAPA_CORRELATIVO === 0"
                                                                    routerLink="/crear-criterios/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}/0">Metodología
                                                                    Eval. Técnica</a></li>
                                                            <li><a *ngIf="item.ETAPA_CORRELATIVO === 0"
                                                                    routerLink="/itemizado/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}/0">Cargar
                                                                    Itemizado</a></li>
                                                            <li><a *ngIf="item.ETAPA_CORRELATIVO === 0"
                                                                    routerLink="/ver-itemizado/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}/0">Presupuesto</a>
                                                            </li>
                                                            <li><a
                                                                    routerLink="/view-cronograma/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Detalle</a>
                                                            </li>
                                                            <!-- <li *ngIf="item.ETAPA_CORRELATIVO === 0"><a routerLink="/edit-licitacion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Editar</a></li> -->
                                                            <li role="separator" class="divider"></li>
                                                            <li *ngIf="item.ORDEN >= 10 && isComision(item.tecnica)"><a
                                                                    routerLink="/calificar/{{item.LIC_CORRELATIVO}}">Eval.
                                                                    Técnica</a></li>
                                                            <li *ngIf="item.ORDEN >= 10"><a
                                                                    routerLink="/resultados/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Oferta
                                                                    Técnica</a></li>
                                                            <li *ngIf="item.ORDEN >= 11"><a
                                                                    routerLink="/resultados-economicos/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Oferta
                                                                    Económica </a></li>
                                                        </ul>
                                                    </ng-container>
                                                    <!-- opciones dentro de las funciones del JAC -->
                                                    <ng-container *ngIf="validateCargoJac()">
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li *ngIf="item.ORDEN >= 11"><a
                                                                    routerLink="/resultados-economicos/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Oferta
                                                                    Económica </a></li>
                                                            <li><a
                                                                    routerLink="/view-cronograma/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Detalle</a>
                                                            </li>
                                                        </ul>
                                                    </ng-container>
                                                    <!-- opciones dentro de las funciones del DAB -->
                                                    <ng-container *ngIf="validateCargoDab()">
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li *ngIf="item.ORDEN >= 11"><a
                                                                    routerLink="/resultados-economicos/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Oferta
                                                                    Económica </a></li>
                                                        </ul>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="item.LICITACION_FINALIZADA == 1">
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                        <li><a routerLink="/acta-inicial/{{item.LIC_CORRELATIVO}}">Acta
                                                                de inicio</a></li>
                                                        <li><a
                                                                routerLink="/view-cronograma/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Detalle</a>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>


<!-- modal agregar -->
<div class="modal" id="modalAgregar">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-form-label col-2">Solpe <span class="text-danger">*</span></label>
                            <div class="col-10">
                                <!-- <select class="form-control" [formControl]="solpeSeleccionada">	
                                    <option value="0">Seleccione...</option>
                                    <option value="26023">26023</option>										
                                    <option *ngFor="let item of tiposAclaraciones; let i = index" value="{{item.id_tipo_aclaracion}}">{{item.descripcion}}</option>
                                </select> -->
                            </div>
                            <div class="col-12">
                                <custom-select [options]="aSolpesSelect" (optionSelected)="onOptionSelected($event)">
                                </custom-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 text-right">
                        <!-- <button style="margin-right: 10px;" type="button" class="btn btn-primary" routerLink="/contratista"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button> -->
                        <button type="button" class="btn btn-primary" data-dismiss="modal"
                            (click)="onAgregar()">Agregar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- modal agregar -->
<div class="modal" id="modalDeclaracion">
    <div class="modal-dialog">
        <div class="modal-content p3-rem">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h4>{{dataLicitacion?.CONT_NOMBRE}}</h4>
                    </div>
                    <div class="col-12">
                        <p class="text-muted">{{dataLicitacion?.DESCRIPCION}}</p>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-6 col-12" *ngFor="let participante of participantes">
                        <p></p>
                    </div>
                </div> -->

                <div class="row mt-4 mb-4 ">
                    <div class="col-12">
                        <p class="mb-2 text-muted">Participantes:</p>
                        <ng-container *ngIf="participantesCount == 0">
                            <p>No se encontraron participantes.</p>
                        </ng-container>
                        <ng-container *ngIf="participantesCount > 0">
                            <span *ngFor="let participante of participantes, let isLast = last">
                                <span class="tag2">
                                    {{participante?.RAZON_SOCIAL}}
                                </span>
                                <p style="margin-left: 50px !important;" class="text-muted">
                                    <li>{{participante?.PART_NOMBRE}}</li>
                                </p>
                                <br>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-12">
                        <div class="row card p-20">
                            <div class="col-12 mb-4">
                                <h4>Declaración de inhabilidad:</h4>
                            </div>
                            <div class="col-12">
                                <p>
                                    ¿Puede participar en la presente licitación?
                                </p>
                            </div>

                            <div class="switch col-12">

                                <div class="form-check mb-2">
                                    <input value="0" (change)="PuedeParticipar = 1" class="form-check-input"
                                        type="radio" name="default_radio" id="validDefaultRadio" value="">
                                    <label class="form-check-label" for="validDefaultRadio">Si</label>
                                </div>

                                <div class="form-check mb-2">
                                    <input value="1" (change)="PuedeParticipar = 2" class="form-check-input"
                                        type="radio" name="default_radio" id="validDefaultRadio" value="">
                                    <label class="form-check-label" for="validDefaultRadio">No</label>
                                </div>
                            </div>

                            <!-- <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-form-label col-12">Descripción Archivo <span class="text-danger">*</span></label>
                                    <div class="col-12">
                                        <input class="form-control" placeholder="Declaracion" type="text" placeholder="" id="nombreArchivo" [(ngModel)]="nombreArchivo">
                                    </div>
                                </div>   
                            </div> -->
                            <div class="col-12 mt-4 mb-2">
                                <span>IMPORTANTE: En caso de no poder participar en la licitación, por favor adjunte una
                                    declaración explicativa con la razón de no poder participar incluyendo su
                                    firma.</span>
                            </div>
                            <hr>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="text-muted">Descargar formato </p>
                                    </div>
                                    <div class="col-6 content-center" style="justify-content: end;">
                                        <app-view-file-buttons
                                            [nombreArchivo]="'acta_declaracion_jurada/formato_declaracion_jurada.docx'"></app-view-file-buttons>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-4 mb-2">
                                <span>Adjunte la declaración firmada:</span>
                            </div>
                            <div class="col col-md-12 mb-4">
                                <input #inputFile class="" name="archivo" type="file" (change)="detectFiles($event)"
                                    #archivo />
                            </div>
                            <div class="col col-md-12 text-right">
                                <button type="button" class="btn tag mr-2" (click)="onGuardarArchivo()">Subir
                                    declaración</button>
                                <button type="button" class="btn tag" data-dismiss="modal" aria-hidden="true"
                                    #closeModal>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>