import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-edit-etapa-licitacion',
  templateUrl: './edit-etapa-licitacion.component.html',
  styleUrls: ['./edit-etapa-licitacion.component.css']
})
export class EditEtapaLicitacionComponent implements OnInit {

  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  participantes: [];
  archivos64 : Blob ;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivos:[]=[];
  idCargoActual: number = 0;
  archivoPublico: number = 0;
  checkedArchivo: boolean = false;
  @ViewChild('inputFile')
  inputFile: ElementRef;
  nombreArchivo: string;
  participanteSeleccionado: number = 0;

  constructor(private route: ActivatedRoute, private router: Router, private licitacionService: LicitacionService, private etapaService : EtapasService, private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];

        this.licitacionService.getParticipantesLic(this.idLicitacion, this.idEtapaLic).subscribe(resp=>{          
          this.participantes = JSON.parse(resp);
        });

        // if (this.idEtapa){
        //   this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
        //     if (resp){
        //       this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
        //     }
        //   });
        //   this.getArchivoLicEtapaFull();
        // }
        this.getEtapaActualLicitacion();
    });

    this.idCargoActual = JSON.parse(localStorage.getItem('userData')).rolId;
  }

  getArchivoLicEtapaFull(){
    this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,this.idEtapa).subscribe(resp=>{
      this.archivos = JSON.parse(resp);
    });
  }

  detectFiles(event: any){
    const reader = new FileReader(); 
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);       
      reader.onload = () => {
        bs64 = reader.result;        
        if (bs64 !== null){                      
              this.archivos64 = bs64; 
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ; 
        }
      };
    }
  }

  onGuardarEtapa(){
    const postData: { 
      in_det_etapa_lic: number;
      files: Blob; 
      fileName: string; 
      type : string; 
      in_usuario: string;
      publico: number; 
      descripcion_input_archivo: string;
      idLicitacion: number;
      idEtapaLicitacion: number;
      correlativoGrupoArchivo: number
      } = { 
        in_det_etapa_lic: this.idEtapaLic , 
        files : this.archivos64, 
        fileName: this.fileName, 
        type: this.type, 
        in_usuario:'', 
        publico: 1, 
        descripcion_input_archivo: this.nombreArchivo == null || this.nombreArchivo == '' ? this.fileName : this.nombreArchivo,
        idLicitacion: this.idLicitacion,
        idEtapaLicitacion: this.idEtapa,
        correlativoGrupoArchivo: 0
      };

    postData.publico = this.archivoPublico;

    if (postData.files){
      this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Etapa actualizada', 'Actualizar etapa');
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp =>{
            if(JSON.parse(resp)[0].TIPIN_DESC && JSON.parse(resp)[0].TIPIN_DESC==='MANUAL'){              
              const putData : { in_licitacion: number;in_etapa: number; in_usuario : string } = {in_licitacion: this.idLicitacion, in_etapa: this.idEtapa , in_usuario: ''};
              this.licitacionService.putVigenciaPartLic(putData).subscribe(resp=>{
                /*if (resp){
                  const putData: { in_licitacion: number; in_usuario:string; } = { in_licitacion : this.idLicitacion, in_usuario: ''}
                  this.licitacionService.putEtapaLicitacion(putData).subscribe(resp=>{ 
                    this.notifyService.showSuccess(JSON.parse(resp), 'Actualizar Etapa');
                  }, err =>{
                    this.notifyService.showError(err.error.message, 'Actualizar etapa');
                  });
                }*/
              }, err =>{
                this.notifyService.showError(err.error.message, 'Actualizar etapa');
              });
            }
          });
        }
        this.nombreArchivo = '';
        this.getArchivoLicEtapaFull();
      }, err =>{
        this.notifyService.showError(err.error.message, 'Actualizar etapa');
      });
    }else{
      this.notifyService.showError('Debe adjuntar un archivo.', 'Actualizar etapa');
    }

    this.archivos64 = null; 
    this.fileName = '';
    this.type = '';
    this.inputFile.nativeElement.value = "";
  }

  onActualizaParticipantesEtapa(idParticipante: number ,estado:boolean){

    const v_estado: string = estado ===true ? 'S': 'N';
    const putData : { in_licitacion: number;in_part_correlativo: number;in_etapa: number; in_estado: string; in_usuario: string} = { in_licitacion: this.idLicitacion, in_part_correlativo: idParticipante, in_etapa: this.idEtapaLic, in_estado: v_estado, in_usuario:''};
    this.licitacionService.putPartEtapaLic(putData).subscribe(resp=>{
      this.notifyService.showSuccess('Participante actualizado', 'Actualizar Participante');
    });
  }

  onActualizaEstadoArchivo(estado:boolean){

    this.checkedArchivo = !estado;
    
    if (this.checkedArchivo) {
      this.archivoPublico = 1;
    } else {
      this.archivoPublico = 0;
    }
  }

  onUpdateParticipante(idParticipante: number, partCheck: boolean){
    let activo = partCheck === true ? 'S': 'N';
    const postData : { 
      in_licitacion: number;
      in_etapa: number; 
      in_part_correlativo: number; 
      in_usuario: string; 
      in_activo: string;
      in_motivo_descalificacion: string;
      in_motivo_reuncia_correlativo: number;
    } = { 
      in_licitacion: this.idLicitacion, 
      in_etapa: this.idEtapaLic, 
      in_part_correlativo : idParticipante, 
      in_usuario:'', 
      in_activo: activo,
      in_motivo_descalificacion: '',
      in_motivo_reuncia_correlativo: 0
    };
    this.licitacionService.postPostulanteLic(postData).subscribe(resp =>{
      this.notifyService.showSuccess(JSON.parse(resp), 'Participante');
      // se vuelve a cargar todo por si acaso
      this.licitacionService.getParticipantesLic(this.idLicitacion, this.idEtapaLic).subscribe(resp=>{          
        this.participantes = JSON.parse(resp);
      });
      if (this.idEtapa){
        this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
          if (resp){
            this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
          }
        });
        this.getArchivoLicEtapaFull();
      }
    }, err =>{
      this.notifyService.showError(err.error.message, 'Participante');
    });
  }

  eliminarParticipante(){
    this.onUpdateParticipante(this.participanteSeleccionado, false);
  }

  removeParticipanteCom(idParticipante: number){
   this.participanteSeleccionado = idParticipante;
  }

  getEtapaActualLicitacion(){
    this.licitacionService.getEtapaActualLicitacion(this.idLicitacion).subscribe(resp =>{
      const etapaActualLicitacionData: any[] = JSON.parse(resp);
      this.nombreEtapa = etapaActualLicitacionData[0].descripcion;
      this.idEtapa = etapaActualLicitacionData[0].etapa_correlativo;
      this.getArchivoLicEtapaFull();
    });
  }
}
