import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(list: any[], value: string) {
    return value ? list.filter(item => item.correo_correlativo.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.correo_destinatario.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.correo_asunto.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.correo_fecha_creacion.toString().toLowerCase().includes(value.toString().toLowerCase())) : list;
  }
}