import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { subscribeOn } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ParticipantesService } from 'src/app/shared/services/participantes.service';

@Component({
  selector: 'app-participantes',
  templateUrl: './participantes.component.html',
  styleUrls: ['./participantes.component.css']
})
export class ParticipantesComponent implements OnInit {

  //empresas: any[] = [];
  empresas: any[] = [];
  empresasFilter: any[] = [];
  participantesEmpresa: any[] = [];
  participantesEmpresaNotificacion: any[] = [];
  editarEmpresa = false;
  editarParticipante = false;
  empresaSelected = false;
  filtroUsuario = 32;
 

  // empresas
  formEmpresa: FormGroup;
  submitted = false;
  correlativoEmpresaSeleccionada: number = 0;
  empresaSeleccionada = '';

  // participante
  formParticipante: FormGroup;
  submittedParticipante = false;
  corrParticipanteSeleccionado: number;

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeModalParticipante') closeModalParticipante: ElementRef;
  @ViewChild('closeModalEliminar') closeModalEliminar: ElementRef;
  @ViewChild('closeModalEliminarParticipante') closeModalEliminarParticipante: ElementRef;

  isVisiblePassword: boolean;

  constructor(private participanteServices: ParticipantesService, private notifyService: NotificationService) {
    this.isVisiblePassword = true;
  }

  ngOnInit(): void {
    this.getEmpresas();
    this.resetFormEmpresa();
    this.resetFormParticipante();
  }
  private getEmpresas() {
    this.participanteServices.getEmpresas().subscribe(resp => {
      this.empresas = JSON.parse(resp);
      this.empresasFilter = this.empresas;
    });
  }
  resetFormEmpresa() {
    this.formEmpresa = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      rut: new FormControl(null, [ Validators.required ]),
      digito: new FormControl(null, [ Validators.required, Validators.minLength(1), Validators.maxLength(1) ]),
      email: new FormControl(null, Validators.required),
      vigente: new FormControl(null)
    })
    this.submitted = false;
    this.editarEmpresa = false;
  }
  onAgregarEmpresa() {
    this.submitted = true;
    if (this.formEmpresa.invalid) {
      return;
    }    

    if(this.editarEmpresa) {
      const putData: { 
        in_part_correlativo: number; 
        iv_nombre: string;
        iv_apellido_paterno: string; 
        iv_apellido_materno: string; 
        iv_razon_social: string;
        in_rut: number;
        iv_digrut: string; 
        iv_email: string;
        iv_password: string;
        iv_vigente: string;
      } = {
        in_part_correlativo: this.correlativoEmpresaSeleccionada, 
        iv_nombre: '', 
        iv_apellido_paterno: '', 
        iv_apellido_materno: '', 
        iv_razon_social: this.formEmpresa.value.nombre,
        in_rut: this.formEmpresa.value.rut, 
        iv_digrut: this.formEmpresa.value.digito, 
        iv_email: this.formEmpresa.value.email, 
        iv_password: '',
        iv_vigente: this.formEmpresa.value.vigente
      }

      this.participanteServices.putEmpresaOParticipante(putData).subscribe(resp=>{
        this.notifyService.showSuccess('Empresa actualizada con exito.', 'Actualizar Empresa');
      }, err =>{
        this.notifyService.showError('Error al actualizar empresa', 'Actualizar Empresa');
        console.error(putData);
        console.error(err);
      }, () => {
        this.getEmpresas();
      });      
    } else {
      const postData : {
        iv_razon_social: string, 
        in_empr_rut: number, 
        iv_empr_digrut: string, 
        iv_email: string 
      } = {
        iv_razon_social: this.formEmpresa.value.nombre, 
        in_empr_rut: this.formEmpresa.value.rut, 
        iv_empr_digrut: this.formEmpresa.value.digito, 
        iv_email: this.formEmpresa.value.email 
      };

      this.participanteServices.postEmpresa(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Empresa creada con exito.', 'Crear Empresa');
      }, err =>{
        this.notifyService.showError('Error al crear empresa', 'Crear Empresa');
        console.error(postData);
        console.error(err);
      }, () => {
        this.getEmpresas();
      });            
    }

    this.closeModal.nativeElement.click();
    this.resetFormEmpresa();
  }
  onEditarEmpresa(correlativo_empresa) {
    this.editarEmpresa = true;
    this.correlativoEmpresaSeleccionada = correlativo_empresa;


    //const empresaEdtiar = this.empresas

    const data = this.empresas.find((reponsable: any)=> {
      return reponsable.PART_CORRELATIVO == correlativo_empresa
    })
    

    if(data)
    {
      this.formEmpresa.controls.nombre.setValue(data.RAZON_SOCIAL);
      this.formEmpresa.controls.rut.setValue(data.RUT);
      this.formEmpresa.controls.digito.setValue(data.DIGITO_RUT);
      this.formEmpresa.controls.email.setValue(data.EMAIL);
      this.formEmpresa.controls.vigente.setValue(data.vigente);
      this.formEmpresa.updateValueAndValidity();
    }

    /* this.participanteServices.getEmpresa(correlativo_empresa).subscribe(resp => {
      let empresa = JSON.parse(resp)[0];

      this.formEmpresa.controls.nombre.setValue(empresa.PART_NOMBRE);
      this.formEmpresa.controls.rut.setValue(empresa.RUT);
      this.formEmpresa.controls.digito.setValue(empresa.DIGRUT);
      this.formEmpresa.controls.email.setValue(empresa.EMAIL);
      this.formEmpresa.updateValueAndValidity();
    }); */
  }
  eliminarEmpresa(correlativo_empresa: number) {
    this.correlativoEmpresaSeleccionada = correlativo_empresa;
  }
  onEliminarEmpresa() {
    this.participanteServices.delParticipantesEmpresa(this.correlativoEmpresaSeleccionada).subscribe(resp=>{
      this.notifyService.showSuccess('Empresa eliminado con exito.', 'Eliminar Empresa');
    }, err => {
      this.notifyService.showError('Error al eliminar empresa', 'Eliminar Empresa');
      console.error(err);
    }, () => {
      this.getEmpresas();
    });
    this.closeModalEliminar.nativeElement.click();
  }

  /** Participantes */
  desplegarParticipantes(correlativo_empresa: number, nombreEmpresa: string) {
    this.correlativoEmpresaSeleccionada = correlativo_empresa;
    this.empresaSeleccionada = nombreEmpresa;
    this.participanteServices.getParticipantesEmpresa(correlativo_empresa, 32).subscribe(resp => {
      this.participantesEmpresa = JSON.parse(resp);
    })
    this.participanteServices.getParticipantesEmpresa(correlativo_empresa, 33).subscribe(resp => {
      this.participantesEmpresaNotificacion = JSON.parse(resp);
    })
  }
  resetFormParticipante() {
    this.formParticipante = new FormGroup({
      nombres: new FormControl(null, Validators.required),
      apellido_paterno: new FormControl(null, Validators.required),
      apellido_materno: new FormControl(null, Validators.required),
      rut: new FormControl(null, [ Validators.required ]),
      digito: new FormControl(null, [ Validators.required, Validators.minLength(1), Validators.maxLength(1) ]),
      email: new FormControl(null, Validators.required),
      password: new FormControl(''),
      tipo_participante: new FormControl(32, Validators.required),
      vigente: new FormControl(null)
    });
    this.submittedParticipante = false;
    this.editarParticipante = false;
  }
  onAgregarParticipante() {
    this.submittedParticipante = true;
    if (this.formParticipante.invalid) {
      return;
    }

    if(this.editarParticipante) {
      const putData: { 
        in_part_correlativo: number;
        iv_nombre: string;
        iv_apellido_paterno: string; 
        iv_apellido_materno: string; 
        iv_razon_social: string;
        in_rut: number;
        iv_digrut: string; 
        iv_email: string;
        iv_password: string;
        iv_vigente: string;
      } = {
        in_part_correlativo: this.corrParticipanteSeleccionado, 
        iv_nombre: this.formParticipante.value.nombres, 
        iv_apellido_paterno: this.formParticipante.value.apellido_paterno, 
        iv_apellido_materno: this.formParticipante.value.apellido_materno, 
        iv_razon_social: '',
        in_rut: this.formParticipante.value.rut, 
        iv_digrut: this.formParticipante.value.digito, 
        iv_email: this.formParticipante.value.email, 
        iv_password: this.formParticipante.value.password,
        iv_vigente: this.formParticipante.value.vigente
      }

      this.participanteServices.putEmpresaOParticipante(putData).subscribe(resp=>{
        this.notifyService.showSuccess('Participante actualizado con exito.', 'Actualizar Participante');
        //console.log(JSON.parse(resp))
      }, err =>{
        this.notifyService.showError('Error al actualizar participante', 'Actualizar Participante');
        console.error(putData);
        console.error(err);
      }, () => {
        this.desplegarParticipantes(this.correlativoEmpresaSeleccionada, this.empresaSeleccionada);
      });      
    } else {
      const putData: { 
        in_part_correlativo: number, 
        iv_nombre: string, 
        iv_apellido_paterno: string, 
        iv_apellido_materno: string, 
        in_rut: number, 
        iv_digrut: string, 
        iv_email: string, 
        iv_password: string,
        in_domi_correlativo_tipar: number
      } = {
        in_part_correlativo: this.correlativoEmpresaSeleccionada, 
        iv_nombre: this.formParticipante.value.nombres, 
        iv_apellido_paterno: this.formParticipante.value.apellido_paterno, 
        iv_apellido_materno: this.formParticipante.value.apellido_materno, 
        in_rut: this.formParticipante.value.rut, 
        iv_digrut: this.formParticipante.value.digito, 
        iv_email: this.formParticipante.value.email, 
        iv_password: this.formParticipante.value.password,
        in_domi_correlativo_tipar: this.formParticipante.value.tipo_participante
      }

      this.participanteServices.putParticipante(putData).subscribe(resp=>{
        this.notifyService.showSuccess('Participante creado con exito.', 'Crear Participante');
      }, err =>{
        this.notifyService.showError('Error al crear participante', 'Crear Participante');
        console.error(putData);
        console.error(err);
      }, () => {
        this.desplegarParticipantes(this.correlativoEmpresaSeleccionada, this.empresaSeleccionada);
      });
    }
    
    this.closeModalParticipante.nativeElement.click();
    this.resetFormParticipante();
  }  
  onEditarParticipante(corr_participante: number) {
    this.editarParticipante = true;
    this.corrParticipanteSeleccionado = corr_participante;

    const responsables = [...this.participantesEmpresa, ...this.participantesEmpresaNotificacion]

    const data = responsables.find((reponsable: any)=> {
      return reponsable.PART_CORRELATIVO == corr_participante
    })
    

    if(data)
    {
      this.formParticipante.controls.nombres.setValue(data.nombre_solo);
      this.formParticipante.controls.apellido_paterno.setValue(data.paterno);
      this.formParticipante.controls.apellido_materno.setValue(data.materno);
      this.formParticipante.controls.rut.setValue(data.RUT);
      this.formParticipante.controls.digito.setValue(data.DIGRUT);
      this.formParticipante.controls.email.setValue(data.EMAIL);
      this.formParticipante.controls.password.setValue('');
      this.formParticipante.controls.tipo_participante.setValue(data.tipo);
      this.formParticipante.controls.vigente.setValue(data.vigente);

      if (data.tipo == 32) {
        this.isVisiblePassword = true;
      }else{
        this.isVisiblePassword = false;
      }

      this.formParticipante.updateValueAndValidity();
    }
    /* this.participanteServices.getEmpresa(corr_participante).subscribe(resp => {
      let participante = JSON.parse(resp)[0];      

      this.formParticipante.controls.nombres.setValue(participante.NOMBRE);
      this.formParticipante.controls.apellido_paterno.setValue(participante.APATERNO);
      this.formParticipante.controls.apellido_materno.setValue(participante.AMATERNO);
      this.formParticipante.controls.rut.setValue(participante.RUT);
      this.formParticipante.controls.digito.setValue(participante.DIGRUT);
      this.formParticipante.controls.email.setValue(participante.EMAIL);
      this.formParticipante.controls.password.setValue('');
      this.formParticipante.updateValueAndValidity();
    }); */
  }
  eliminarParticipante(corr_participante: number) {
    this.corrParticipanteSeleccionado = corr_participante;
  }
  onEliminarParticipante() {
    this.participanteServices.delParticipantesEmpresa(this.corrParticipanteSeleccionado).subscribe(resp=>{
      this.notifyService.showSuccess('Participante eliminado con exito.', 'Eliminar Participante');
    }, err => {
      this.notifyService.showError('Error al eliminar participante', 'Eliminar Participante');
      console.error(err);
    }, () => {
      this.desplegarParticipantes(this.correlativoEmpresaSeleccionada, this.empresaSeleccionada);
    });
    this.closeModalEliminarParticipante.nativeElement.click();
  }
 /*  applyFilterEmpresas(event: Event)
  {
    const filterValue = (event.target as HTMLInputElement).value;
    this.empresas.filter = filterValue.trim().toLowerCase();
  } */
  applyFilterEmpresas(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    //this.dataUsersFilter = this.dataUsers;
    //this.dataUsersFilter.filter = filterValue.trim().toLowerCase();
    if(filterValue != '')
    {
      this.empresasFilter = this.empresas.filter(data => {
        return (
          (
            data?.RAZON_SOCIAL.toLowerCase().includes(filterValue) || 
            data?.RUT.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.empresasFilter = this.empresas
    }

    
  }
  
  onActivarParticipante(correlativo: number, part_correlativo: number) {
    const postData = {
      correlativo: correlativo,
      part_correlativo: part_correlativo
    }

    this.participanteServices.postUdpateActivarParticipante(postData).subscribe(
      resp=>{
        this.notifyService.showSuccess('Participante activado con exito.', 'Activar Participante');
        this.desplegarParticipantes(this.correlativoEmpresaSeleccionada, this.empresaSeleccionada);
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al activar participante');
      }
    );
  }

  onTipoParticipanteChange(event: any) {
    console.log(event.target.value);

    if (event.target.value == '32') {
      this.isVisiblePassword = true;
    }else{
      this.isVisiblePassword = false;
    }
  }

  get fe() { return this.formEmpresa.controls; }
  get fp() { return this.formParticipante.controls; }
}
