import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router , Params} from '@angular/router';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FileService } from 'src/app/shared/services/file.service';
import { IParticipante } from 'src/app/shared/services/licitacion.types';
import { CriteriosEvaluacionService } from 'src/app/shared/services/criterios-evaluacion.service';
import { ItemizadoService } from 'src/app/shared/services/itemizado.service';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-edit-licitacion',
  templateUrl: './edit-licitacion.component.html',
  styleUrls: ['./edit-licitacion.component.css'],
  providers: [ItemizadoService, CriteriosEvaluacionService, EtapasService, LicitacionService]
})
export class EditLicitacionComponent implements OnInit {
  idLicitacion:number;
  idEtapa:number;
  idEtapaLic:number;
  documentos: [];
  etapas: [];
  licitacionForm :FormGroup;
  fechaInicio:any[] = [];
  fechaFin:any[] = [];
  cantHora:any[] = [];
  fechaCronogramaLic: any[] = [];
  archivos64 : any[] = [];
  cronograma: any[];
  showInvitacion : boolean = false;
  showGuardar : boolean = false;

  horasTemp:any[] =[];
  dTemp:any[] =[];
  dTempVal:any[] =[];
  hTemp:any[] =[];
  //dias: number;
  //hrs : number;

  hInicioTemp:any[] =[]
  fInicioTemp: any[] = []
  hTerminoTemp:any[] =[]
  fTerminoTemp: any[] = []

  totalHoras: number;

  fechaCreacionLicitacion: string = '';
  monedas: any[] = [];

  // validacion de datos de la licitacion
  islicitacionValida: boolean = false;
  // componentes licitacion validos
  isDatosBasicosValidos: boolean = false;
  isCronogramaValido: boolean = false;
  isParticipantesValido: boolean = false;
  isComisionValidoTecnica: boolean = false;
  isComisionValidoEconomica: boolean = false;
  isComisionValidoResponsable: boolean = false;
  isComisionValidoCoordinador: boolean = false;
  isCriteriosValido: boolean = false;
  isitemizadoValido: boolean = false;
  isDocumentosValido: boolean = false;

  participantes: IParticipante[] = [];
  participantesCom: any[];
  contArchivosBase: number;
  moneda_solpe: string;
  cssFiles: string[] = [
    '/assets/css/app/edit-licitacion.css',
    '/assets/css/app/create-criterios.css',
    '/assets/css/app/resultados-itemizado.css'
  ]

  montoSolpe: number = 0;
  montoItemizado: number = 0;
  diferenciaMontos: number = 0;

  constructor(
    private licitacionService : LicitacionService,
    private etapaService : EtapasService,
    private route: ActivatedRoute, private router: Router,
    private notifyService : NotificationService,
    private fileService: FileService,
    private criteriosServices: CriteriosEvaluacionService,
    private objItemizadoService: ItemizadoService
  ) { }

  ngOnInit(): void {

    this.getMonedas();

    this.licitacionForm = new FormGroup({
      'solpe' : new FormControl(null),
      'nro_contrato': new FormControl(null),
      'nombre_contrato' : new FormControl(null),
      'descripcion' : new FormControl(null),
      'moneda_solpe' : new FormControl(0),
      'monto_solpe' : new FormControl(null),
      'files': new FormArray ([])
    });

    this.route.params

    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        this.verificarLic(this.idLicitacion);
        this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(resp=>{
          this.licitacionForm = new FormGroup({
            'solpe' : new FormControl(JSON.parse(resp)[0].SOLPE_CORRELATIVO),
            'nro_contrato': new FormControl(JSON.parse(resp)[0].CONT_NUMERO),
            'nombre_contrato' : new FormControl(JSON.parse(resp)[0].CONT_NOMBRE),
            'descripcion' : new FormControl(JSON.parse(resp)[0].DESCRIPCION),
            'moneda_solpe' : new FormControl(JSON.parse(resp)[0].MONEDA_SOLPE),
            'monto_solpe' : new FormControl(JSON.parse(resp)[0].MONTO_SOLPE),
            'files': new FormArray ([])
          });

          this.fechaCreacionLicitacion = JSON.parse(resp)[0].FECHA_CREACION;
          this.onUpdateMonedaSolpe();
        }, err =>{
          this.notifyService.showError(err.error.message, 'Obtener cabecera licitación');
        });

        this.licitacionService.getCronogramaLicitacionId(this.idLicitacion).subscribe(resp=>{
          this.cronograma = JSON.parse(resp);
          this.cronograma.forEach(element => {
            const el = JSON.parse(JSON.stringify(element));
            if(el.FECHA_INICIO_ETAPA != null)
            {
              this.fechaInicio[el.ETAPA_CORRELATIVO] = new Date(el.FECHA_INICIO_ETAPA).toISOString().split('T')[0];
            }
            else
            {
              this.fechaInicio[el.ETAPA_CORRELATIVO] = el.FECHA_INICIO_ETAPA;
            }
            if(el.FECHA_TERMINO_ETAPA != null)
            {
              this.fechaFin[el.ETAPA_CORRELATIVO] = new Date(el.FECHA_TERMINO_ETAPA).toISOString().split('T')[0] ;
            }
            else
            {
              this.fechaFin[el.ETAPA_CORRELATIVO] = el.FECHA_TERMINO_ETAPA;
            }
          });

          this.onValidarLicitacion();
        }, err =>{
          this.notifyService.showError(err.error.message, 'Obtener cronograma licitación');
        });

        this.getDocumentosLicitacionId();
    });
  }

  onUpdateMonedaSolpe(){
    const select = document.getElementById('moneda_solpe') as HTMLSelectElement;
    const options = select.options;
    let value = '';

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if(option.value == this.licitacionForm.value.moneda_solpe){
        value = option.innerHTML;
      }
    }

    this.moneda_solpe = value;
  }

  onValidateLicitacionCompletada(){
    if (this.isDatosBasicosValidos === true && this.isCronogramaValido === true && this.isParticipantesValido === true && this.isComisionValidoTecnica === true && this.isComisionValidoEconomica === true && this.isComisionValidoCoordinador === true && this.isComisionValidoResponsable === true && this.isCriteriosValido === true && this.isitemizadoValido && this.isDocumentosValido) {
      this.islicitacionValida = true;
    } else {
      this.islicitacionValida = false;
    }
  }

  onValidarLicitacion(){
    // validacion de formulario
    if (this.idLicitacion !== 0 && this.licitacionForm.value.solpe !== '' && this.licitacionForm.value.nro_contrato !== '' && this.licitacionForm.value.nombre_contrato !== '' && this.licitacionForm.value.descripcion !== '' && this.licitacionForm.value.moneda_solpe != 0 && this.licitacionForm.value.monto_solpe !== 0){
      this.isDatosBasicosValidos = true;
    }else{
      this.isDatosBasicosValidos = false;
    }

    this.montoSolpe = this.licitacionForm.value.monto_solpe ? this.licitacionForm.value.monto_solpe : 0;

    // validacion cronograma
    this.licitacionService.getCronogramaLicitacionId(this.idLicitacion).subscribe(resp=>{
      this.cronograma = JSON.parse(resp);

      for (let index = 0; index < this.cronograma.length; index++) {
        const element = this.cronograma[index];
        if (element.ACTIVO == 'S') {
          if (element.FECHA_INICIO_ETAPA !== null) {
            this.isCronogramaValido = true;
          }else{
            this.isCronogramaValido = false;
            break;
          }
        }
      }

      // validacion de participantes oferentes
      this.licitacionService.getParticipantesLic(this.idLicitacion, this.idEtapaLic).subscribe(resp =>{
        this.participantes = JSON.parse(resp);

        if (this.participantes.length > 0) {
          this.isParticipantesValido = true;
        } else {
          this.isParticipantesValido = false;
        }

        // validacion de participantes de la comision evaluadora
        this.licitacionService.getParticipantesComLicitacion(this.idLicitacion).subscribe(resp =>{
          this.participantesCom = JSON.parse(resp);
          this.isComisionValidoTecnica = false;
          this.isComisionValidoEconomica = false;
          this.isComisionValidoCoordinador = false;
          this.isComisionValidoResponsable = false;

          for (let index = 0; index < this.participantesCom.length; index++) {
            const element = this.participantesCom[index];
            if (element.TIPO_PARTICIPANTE == 7) {
              this.isComisionValidoTecnica = true;
            }

            if (element.TIPO_PARTICIPANTE == 8) {
              this.isComisionValidoEconomica = true;
            }

            if (element.TIPO_PARTICIPANTE == 36) {
              this.isComisionValidoCoordinador = true;
            }

            if (element.TIPO_PARTICIPANTE == 37) {
              this.isComisionValidoResponsable = true;
            }
          }

          // if (this.participantesCom.length > 0) {
          //   this.isComisionValido = true;
          // } else {
          //   this.isComisionValido = false;
          // }

          // validacion de los criterios
          this.criteriosServices.getGrupos(this.idLicitacion).subscribe(resp => {
            let response: any = resp;

            if(response.data?.length > 0){
              this.isCriteriosValido = true;
            }else{
              this.isCriteriosValido = false;
            }

            // validacion de itemizado
            this.objItemizadoService.getItemizadoParticipante(this.idLicitacion).subscribe(resp => {
              let response: any = resp;

              let totalitemizado: number = 0;
              //this.objItemizadoLs
              for(let y = 0; y < response.data?.length; y++)
              {
                for(let i = 0; i < response.data[y]?.itemizadoDetalleLs?.length; i++)
                {
                  totalitemizado = totalitemizado + (response.data[y]?.itemizadoDetalleLs[i]?.cantidad * response.data[y]?.itemizadoDetalleLs[i]?.precio_unitario);
                }
              }

              this.montoItemizado = totalitemizado;
              this.diferenciaMontos = (this.montoItemizado - this.montoSolpe);

              if(response.data?.length > 0){
                this.isitemizadoValido = true;
              }else{
                this.isitemizadoValido = false;
              }

              // validacion de archivos
              this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,0).subscribe(resp=>{
                let archivosAdicionales: any[] = JSON.parse(resp);
                if (archivosAdicionales.length > 0) {
                  this.isDocumentosValido = true;
                } else {
                  this.isDocumentosValido = false;
                }

                this.contArchivosBase = archivosAdicionales.length;
                this.onValidateLicitacionCompletada();
              });

            },
            error => {console.log(error)},
            () => {

            });

          },
          error => {console.log(error)},
          () => {

          });

        }, err =>{
          this.notifyService.showError(err.error.message, 'Obtener Participantes Comisión Licitación');
        });

      });
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener cronograma licitación');
    });

  }

  getMonedas(){
    this.licitacionService.getMonedasSolpeLicitacion().subscribe(resp =>{
      this.monedas = JSON.parse(resp);
      console.log(this.monedas);
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener Monedas para la licitacion');
    });
  }

  getDocumentosLicitacionId(){
    this.licitacionService.getDocumentosLicitacionId(this.idLicitacion).subscribe(resp=>{
      this.documentos = JSON.parse(resp);
      this.documentos.forEach(element => {
        let valor = JSON.parse(JSON.stringify(element)).VALOR ;
        let descripcion = JSON.parse(JSON.stringify(element)).DESCRIPCION;
        let correlativo = JSON.parse(JSON.stringify(element)).CORRELATIVO;
        /* const group = new FormGroup({
          'nombreArchivo': new FormControl({value: descripcion,disabled: true}, Validators.required),
          'archivo': new FormControl(null, Validators.required),
          'id_archivo': new FormControl(correlativo, Validators.required),
          'valor': new FormControl(valor, Validators.required)
        }); */
        //(<FormArray>this.licForm.get('files')).push(group);
        this.archivos64.push({ correlativo: correlativo, path:null });
      });
    });
  }

  onDescargar(fileName: string){
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }

  detectFiles(event: any, idF: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
          this.archivos64.map((item, i) => {
            if (item.correlativo == idF){
              this.archivos64[i].fileName = event.target.files[0].name;
              this.archivos64[i].type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
              this.archivos64[i].path = bs64;
             }
           });
        }
      };
    }
  }

  onEnviarInvitacion(){
    const putData: { in_licitacion: number; in_etapa: number, in_usuario:string; } = { in_licitacion : this.idLicitacion, in_etapa: this.idEtapa, in_usuario: ''}
    this.licitacionService.putEnviarInvitacionLic(putData).subscribe(resp=>{
      this.notifyService.showSuccess(JSON.parse(resp), 'Envio invitación');
      this.router.navigate(['/licitaciones']);
    });
  }

  private verificarLic(idLic: number){
    if (idLic){
      this.licitacionService.getValidaLic(idLic).subscribe(resp=>{
        switch (JSON.parse(resp)[0].LIC_VALIDA){
          case 0:
            this.showInvitacion = false;
            this.showGuardar = true;
            this.notifyService.showWarning(JSON.parse(resp)[0].MENSAJE, 'Valida licitación');
          break;
          case 1:
            this.showInvitacion = true;
            this.showGuardar = true;
            this.notifyService.showSuccess(JSON.parse(resp)[0].MENSAJE, 'Valida licitación');
          break;
        }
      }, err =>{
        this.notifyService.showError(err.error.message, 'Valida licitación');
      });
    }
  }
  onUpdateLicitacion(){
    if (this.idLicitacion){
      const putData: {
        in_licitacion: number;
        in_solpe: number;
        in_cont_correlativo: number;
        in_cont_nombre:string;
        in_usuario:string;
        in_descripcion: string;
        in_moneda_solpe: number;
        in_monto_solpe: number;
        in_fecha_publicacion: string;
      } = {
        in_licitacion: 0,
        in_solpe:0,
        in_cont_correlativo:0,
        in_cont_nombre: '',
        in_usuario: '',
        in_descripcion: '',
        in_moneda_solpe: 0,
        in_monto_solpe: 0,
        in_fecha_publicacion: null
      };

      putData.in_licitacion = this.idLicitacion;
      putData.in_solpe = this.licitacionForm.value.solpe;
      putData.in_cont_correlativo = this.licitacionForm.value.nro_contrato;
      putData.in_cont_nombre = this.licitacionForm.value.nombre_contrato;
      putData.in_descripcion = this.licitacionForm.value.descripcion;
      putData.in_moneda_solpe = this.licitacionForm.value.moneda_solpe;
      putData.in_monto_solpe = this.licitacionForm.value.monto_solpe;

      if (putData.in_licitacion !== 0 && putData.in_solpe && putData.in_cont_correlativo  &&  putData.in_cont_nombre && putData.in_descripcion && putData.in_monto_solpe !== 0){
        this.licitacionService.putLicitacion(putData).subscribe(resp=>{
          if (resp){
            this.archivos64.map((item, i) => {
              if (item.path){
                  const postData: {
                    in_det_etapa_lic: number;
                    files: Blob;
                    fileName: string;
                    type : string;
                    in_usuario: string;
                    in_domi_correlativo_tiar: number;
                    publico: number;
                    descripcion_input_archivo: string;
                    idLicitacion: number;
                    idEtapaLicitacion: number;
                    correlativoGrupoArchivo: number
                  } = {
                    in_det_etapa_lic: this.idEtapaLic ,
                    files : item.path,
                    fileName: item.fileName,
                    type: item.type, in_usuario:'',
                    in_domi_correlativo_tiar: item.correlativo,
                    publico: 1,
                    descripcion_input_archivo: item.fileName,
                    idLicitacion: this.idLicitacion,
                    idEtapaLicitacion: this.idEtapa,
                    correlativoGrupoArchivo: 0
                   };
                  this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
                  }, err =>{
                    this.notifyService.showError(err.error.message, 'Subir archivos licitacion');
                  });
              }
            });
          }
          this.notifyService.showSuccess(JSON.parse(resp), 'Actualizar licitación');
          this.archivos64 = [];
          this.getDocumentosLicitacionId();
        }, err =>{
          this.notifyService.showError(err.error.message, 'Actualizar licitación');
        });
      }else{
        this.notifyService.showError('Favor llene los campos obligatorios.', 'Actualizar licitación');
      }
    }
  }
  /*onCalDias(idEtapa: number,dias: number){

    if (dias < 10000 && dias >0){
      //this.dias = (dias*24) === NaN ? 0: (dias*24) ;
      this.dTemp[idEtapa] = (dias*24) === NaN ? 0: (dias*24) ;
      this.dTempVal[idEtapa] = dias === NaN ? 0: dias ;
    }else{
      this.dTemp[idEtapa]= 0;
      this.dTempVal[idEtapa]= 0;
      //this.dias = 0 ;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 9999', 'Calculo de dias');
    }
    this.calculo(idEtapa);
  }
  onCalHrs( idEtapa: number,hrs: number){

    if (hrs < 25 && hrs >0){
      //this.hrs  = hrs === NaN ? 0 : hrs;
      this.hTemp[idEtapa]  = hrs === NaN ? 0 : hrs;
    }else{
      this.hTemp[idEtapa]= 0;
      //this.hrs = 0 ;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }
    this.calculo(idEtapa);
  }*/
  /*private calculo(idEtapa: number){
    this.totalHoras = 0 ;
    const horasDias: number = !this.dTemp[idEtapa] ? 0 : this.dTemp[idEtapa];
    const horasIngr: number = !this.hTemp[idEtapa]  ? 0 : this.hTemp[idEtapa] ;
    this.totalHoras = +horasDias +  +horasIngr;
    this.calFecha(idEtapa, this.totalHoras);
  }*/
 /** Cambio en forma de ingreso fechas del formulario de creacion de licitacion */
//  onFechaInicio( idEtapa: number, fecha: string){
//   this.fInicioTemp[idEtapa] = fecha
//   this.fechaInicio[idEtapa] = fecha

//   this.fechaInicio.forEach((element, i) => {
//     if (i >=  idEtapa + 1 ){
//       this.fechaInicio[i] = '';
//       this.fechaFin[i] ='';
//     }
//   });
//   }
 onCalHrInicio( idEtapa: number,hrs: number){
    if (hrs < 25 && hrs >0){
      this.hInicioTemp[idEtapa]  = Number.isNaN(hrs) ? 0 : hrs;
    }else{
      this.hInicioTemp[idEtapa]= 0;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }
    this.fechaInicio[idEtapa] = this.fInicioTemp[idEtapa]
  }
//  onFechaTermino( idEtapa: number, fecha: string){
//     this.fTerminoTemp[idEtapa] = fecha
//     this.fInicioTemp[idEtapa + 1] = fecha

//     this.fechaInicio[idEtapa + 1] = fecha
//     this.fechaFin[idEtapa] = fecha

//     this.fechaCronogramaLic.push({
//       in_etapa: idEtapa,
//       in_fecha_ini: this.fInicioTemp[idEtapa],
//       in_fecha_fin: this.fTerminoTemp[idEtapa],
//       in_hora: 0
//     })
// }
 onCalHrTermino( idEtapa: number,hrs: number){
    if (hrs < 25 && hrs >0){
      this.hTerminoTemp[idEtapa]  = Number.isNaN(hrs) ? 0 : hrs;
      this.hInicioTemp[idEtapa + 1]  = Number.isNaN(hrs) ? 0 : hrs;
    }else{
      this.hTerminoTemp[idEtapa]= 0;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }

    this.fechaInicio[idEtapa + 1] = this.fTerminoTemp[idEtapa] + ' ' + hrs + ':00'
    this.fechaFin[idEtapa] = this.fTerminoTemp[idEtapa] + ' ' + hrs + ':00'

    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa] + 'T' + ('0'+this.hInicioTemp[idEtapa]).slice(-2) + ':00' ,
      in_fecha_fin: this.fTerminoTemp[idEtapa] + 'T' + ('0'+this.hTerminoTemp[idEtapa]).slice(-2) + ':00',
      in_hora: 0
    })
  }
}
