import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { FileService } from '../shared/services/file.service';

@Component({
  selector: 'app-cargar-archivos-licitacion',
  templateUrl: './cargar-archivos-licitacion.component.html',
  styleUrls: ['./cargar-archivos-licitacion.component.css']
})
export class CargarArchivosLicitacionComponent implements OnInit {

  @Input() idLicitacion: number;
  @Input() idEtapa: number;
  @Input() currentStep: number;
  @Output() data: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() gruposArchivosData: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() actaInicio: boolean = false;
  //@Output() data: EventEmitter<string[]> = new EventEmitter<string[]>();

  userId: string;

  fileName: string;
  type: string;
  archivos: any[] = [];
  archivos64Adicionales : Blob;
  nombreArchivo: string;
  isPublic: number;
  idCargoActual: number;
  gruposArchivos: any[];
  gruposArchivosSinFiltro: any[];
  correlativoGrupoArchivoSeletected: number;

  @ViewChild('inputFileAdicional')
  inputFileAdicional: ElementRef;

  constructor(
    private licitacionService : LicitacionService,
    private etapaService : EtapasService,
    private notifyService : NotificationService,
    private fileService: FileService
  ) {
    this.isPublic = 0;
    this.gruposArchivos = [];
    this.gruposArchivosSinFiltro = [];
    this.correlativoGrupoArchivoSeletected = 0;
  }

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.getGruposArchivos();
    this.getArchivoLicEtapaFull();
    this.idCargoActual = JSON.parse(localStorage.getItem('userData')).rolId;
  }

  onCheckPublico(){
    if (this.isPublic == 1) {
      this.notifyService.showWarning('El archivo es publico, esto sera visible para todos los usuarios.', 'Archivos');
    }
  }

  deleteFileLicitacion(fileCorrelativo: number, fileName: string){
    this.fileService.deleteFile({file_correlativo: fileCorrelativo, file_name: fileName}).subscribe(resp=>{
      this.getGruposArchivos();
      this.getArchivoLicEtapaFull();
      this.notifyService.showSuccess('Se elimino el archivo', 'Archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener Monedas para la licitacion');
    });
  }

  validateCheckArchivoGrupo(correlativoGrupoArchivo: number) {
    let valid: boolean = false;

    for (let index = 0; index < this.archivos.length; index++) {
      const element = this.archivos[index];
      
      if (element.GRUPO_ARCHIVO == correlativoGrupoArchivo) {
        valid =  true;
        break;
      }
    }

    return valid;
  }

  returnGrupoArchivo(grupoCorrelativo: number){
    const gruposArchivos: any[] = this.gruposArchivosSinFiltro.filter((grupo) => grupo.correlativo == grupoCorrelativo);

    if (gruposArchivos.length > 0) {
      return gruposArchivos[0].descripcion;
    }else {
      return '';
    }
  }

  // removeDuplicates(originalArray: any[], prop: string) {
  //   var newArray = [];
  //   var lookupObject  = {};

  //   for(var i in originalArray) {
  //      lookupObject[originalArray[i][prop]] = originalArray[i];
  //   }

  //   for(i in lookupObject) {
  //       newArray.push(lookupObject[i]);
  //   }
  //    return newArray;
  // }

  getArchivoLicEtapaFull(){
    this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,0).subscribe(resp=>{
      const data: any[] = JSON.parse(resp);

      this.archivos = data;
      this.data.emit(this.archivos);
    });
  }

  getGruposArchivos(){
    this.licitacionService.getGruposArchivos().subscribe(resp=>{
      const data: any[] = JSON.parse(resp);
      this.gruposArchivosSinFiltro = data;

      if (data.length > 0) {
        if (this.currentStep === 10) {
          this.gruposArchivos = data.filter((grupo) => grupo.correlativo == 1);
          this.correlativoGrupoArchivoSeletected = this.gruposArchivos[0].correlativo;
          this.gruposArchivosData.emit(this.gruposArchivos);
        } else if(this.currentStep === 9){
          this.gruposArchivos = data.filter((grupo) => grupo.correlativo == 7);
          this.correlativoGrupoArchivoSeletected = this.gruposArchivos[0].correlativo;
          this.gruposArchivosData.emit(this.gruposArchivos);
        } else {
          this.gruposArchivos = data.filter((grupo) => (grupo.correlativo != 1 && grupo.correlativo != 7));
          this.correlativoGrupoArchivoSeletected = this.gruposArchivos[0].correlativo;
          this.gruposArchivosData.emit(this.gruposArchivos);
        }
      }
    });
  }

  onChangeGurpoArchivo(grupo: number) {
    this.correlativoGrupoArchivoSeletected = grupo;
  }

  detectDocumentosAdicionales(event: any){
    const reader = new FileReader(); 
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);       
      reader.onload = () => {
        bs64 = reader.result;        
        if (bs64 !== null){                      
              this.archivos64Adicionales = bs64; 
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ; 
        }
      };
    }
  }

  onGuardarDocumentoAdicional(){
    if (this.currentStep === 9) {
      if (!this.type.includes('jpg') && !this.type.includes('png')) {
        this.notifyService.showWarning('El archivo debe ser JPG o PNG', 'Archivos');
        return;
      }
    }

    const postData: { 
      in_det_etapa_lic: number;
      files: Blob; 
      fileName: string; 
      type : string; 
      in_usuario: string;
      publico: number; 
      descripcion_input_archivo: string;
      idLicitacion: number;
      idEtapaLicitacion: number;
      correlativoGrupoArchivo: number;
    } = { 
        in_det_etapa_lic: this.idEtapa , 
        files : this.archivos64Adicionales, 
        fileName: this.fileName, 
        type: this.type, 
        in_usuario:'', 
        publico: (this.currentStep == 9 || this.currentStep == 10) ? 0 : this.isPublic, 
        descripcion_input_archivo: this.nombreArchivo == null || this.nombreArchivo == '' ? this.fileName : this.nombreArchivo,
        idLicitacion: this.idLicitacion,
        idEtapaLicitacion: this.idEtapa,
        correlativoGrupoArchivo: this.correlativoGrupoArchivoSeletected
    };

    if (postData.files){
      this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Etapa actualizada', 'Actualizar etapa');
        this.getGruposArchivos();
        this.getArchivoLicEtapaFull();
      }, err =>{
        this.notifyService.showError(err.error.message, 'Actualizar etapa');
      });
    }else{
      this.notifyService.showError('Debe adjuntar un archivo.', 'Actualizar etapa');
    }

    this.archivos64Adicionales = null; 
    this.fileName = '';
    this.type = '';
    this.nombreArchivo = '';
    this.inputFileAdicional.nativeElement.value = "";
    this.isPublic = 0;
  }

  urlArchivoSelected(correlativoGrupoArchivo: number) {
    let urlArchivo: string = '';
    const archivosGrupo: any[] = this.archivos.filter((item) => item.GRUPO_ARCHIVO == correlativoGrupoArchivo);

    if (archivosGrupo.length > 0) {
      urlArchivo = archivosGrupo[archivosGrupo.length - 1].NOMBRE_ARCHIVO;
    }

    return urlArchivo;
  }

}
