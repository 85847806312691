<!-- begin #content -->
<div id="content" class="content">
    <!-- routerLink="/cronograma" -->
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin row -->
    <div class="row">
        <h4 style="margin-left: 10px;" class="panel-title">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h4>
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                    <h4 class="panel-title">Editar cronograma licitación</h4>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <app-cronograma-fechas [idLicitacion]="idLicitacion" [idEtapa]="idEtapa"></app-cronograma-fechas>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>
