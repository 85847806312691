<div class="content" *ngIf="idParticipante == 0">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i
            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i>
        Volver</button>
    <div class="row">
        <ng-container *ngFor="let participante of obgParticipantes">
            <div *ngIf="participante.VIGENTE == 'S'" class="col-12 col-md-6 p">
                <div class="empresa mb-3">
                    <h5>{{participante.razon_social}}</h5>
                    <p class="text'text-muted">{{participante.rut | number}}-{{participante.digito_rut}}</p>
                    <p><span class="text-muted">Responsable: </span>{{participante.responsable}}</p>
                    <!-- <p (click)="GetGrupos(participante.CORRELATIVO)">Seleccionar</p> -->
                    <ng-container *ngIf="validatePresentacionOfertaTecnica(participante?.CORRELATIVO)">
                        <a class="btn tag text-primary"
                            (click)="GetGrupos(participante.CORRELATIVO, participante.razon_social)">Ver Oferta
                            Técnica</a>
                    </ng-container>
                    <ng-container *ngIf="!validatePresentacionOfertaTecnica(participante?.CORRELATIVO)">
                        <p class="text-danger bold">No presento oferta técnica</p>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <chat [idLicitacion]="idLicitacion"></chat>
</div>
<!-- begin #content -->
<div id="content" class="content" *ngIf="idParticipante != 0">
    <!-- begin row -->
    <div class="row">
        <div class="col-6 col-md-6">
            <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
                (click)="idParticipante = 0"><i _ngcontent-ftv-c76=""
                    class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
        </div>
        <div class="col-6 col-md-6 text-right">
            <span style="font-size: 18px;" class="text-muted m-r-10">
                <ng-container *ngIf="!evaluacionCompleta">Evaluación en curso a:</ng-container>
                <ng-container *ngIf="evaluacionCompleta">Evaluación completada a:</ng-container>
                <span style="font-weight: bold; font-size: 18px;">
                    {{oferenteSeleccionadoStr}}
                </span>
            </span>
            <ng-container *ngIf="this.objLicitacion.ETAPA_CORRELATIVO === 9">
                <ng-container *ngIf="!evaluacionCompleta">
                    <button type="button" class="btn btn-primary" (click)="createValidacionEvalTecEvaluador()"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-check" style="color: white;"></i>
                        Completar Evaluación</button>
                </ng-container>
                <ng-container *ngIf="evaluacionCompleta">
                    <button type="button" class="btn btn-primary" (click)="deleteValidacionEvalTecEvaluador()"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-times" style="color: white;"></i>
                        Deshacer Evaluación</button>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Aclaraciones enviadas</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <ng-container *ngIf="aclaracionesFiltered.length > 0">
                    <div class="panel-body">
                        <table id="data-table-default"
                            class="table table-striped table-bordered table-td-valign-middle">
                            <thead>
                                <tr>
                                    <th class="text-nowrap">Aclaracion tecnica</th>
                                    <th class="text-nowrap">Enviada a </th>
                                    <th class="text-nowrap">Fecha creación</th>
                                    <th class="text-nowrap">Aclaracion</th>
                                    <th class="text-nowrap">Respuesta</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="odd gradeX" *ngFor="let item of aclaracionesFiltered">
                                    <td class="text-break">{{item.ACLARACION}}</td>
                                    <td class="text-break">{{item.PART_NOMBRE}}</td>
                                    <td>{{ item.FECHA_CREACION}}</td>
                                    <td>
                                        <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_ACL"
                                            [nombreArchivo]="item.NOMBRE_ARCHIVO_ACL"
                                            style="margin-right: 10px;"></app-view-file-buttons>
                                    </td>
                                    <td>
                                        <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_RESP"
                                            [nombreArchivo]="item.NOMBRE_ARCHIVO_RESP"></app-view-file-buttons>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                <ng-container *ngIf="aclaracionesFiltered.length <= 0">
                    <div class="panel-body">
                        <div class="alert alert-warning" role="alert">No existen aclaraciones para este caso de
                            evaluación.</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="main-group" *ngFor="let grupo of objGrupos; let i = index">
                <div class="group">
                    <div class="mb-0">
                        <span class="q-group mr-2">{{grupo.peso}}</span>
                        <span class="text-white">
                            {{grupo.descripcion}}
                        </span>
                    </div>
                </div>
                <div>
                    <div class="subgroup" *ngFor="let subgrupo of grupo.subgrupoLs; let y = index"
                        style="padding-left: 3rem; padding-right: 3rem">
                        <div class="subgroup_container">
                            <!-- {{y + 1}}.-  -->
                            <span class="mb-0 text-subgroup">
                                <p class="bold">{{subgrupo.descripcion}} ({{subgrupo.peso}}%)</p>
                                <p class="text-muted mb-0">{{subgrupo.observacion}}</p>
                            </span>
                            <div class="subgroup_options">
                                <!-- <a class="btn rounded-icon mr-2" href="#modalCriterio" data-toggle="modal" (click)="indexGrupo = i; indexSubgrupo = y; modalCriterio = true;isUpdateCriterio = false;cleanCriterioForm()" title="Agregar criterio">
                                    <i class="fas fa-plus-circle"></i>
                                </a> -->
                            </div>
                            <!-- <a class="btn tag" (click)="removeSubGroup(i,y,subgrupo.correlativo)">remove</a> -->
                        </div>
                        <div class="d-table" *ngIf="subgrupo.criteriosLs?.length > 0">
                            <div class="t-head">
                                <span class="d-cell">Descripcion</span>
                                <span *ngIf="subgrupo.tipo == 2" class="d-cell">Cantidad</span>
                                <span *ngIf="subgrupo.tipo == 2" class="d-cell">Turno</span>
                                <span *ngIf="subgrupo.tipo == 3" class="d-cell">Nombres/Apellidos</span>
                                <span *ngIf="subgrupo.tipo == 3" class="d-cell">Perfil</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">Marca</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">Modelo</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">Ano</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">Propiedad</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">ECF</span>
                                <span class="d-cell">Observacion</span>
                                <span class="d-cell">Archivo</span>
                                <span class="d-cell"></span>
                            </div>
                            <div class="criterio " *ngFor="let criterio of subgrupo.criteriosLs; let x = index">
                                <!-- <div class="criterio_container"> -->

                                <!-- <span *ngIf="subgrupo.tipo == 1" class="text-criterio "> -->
                                <span class="d-cell">{{criterio.descripcion}}</span>
                                <!-- <span class="d-cell text-muted">{{criterio.observacion}}</span> -->
                                <!-- </span> -->

                                <!-- <span *ngIf="subgrupo.tipo == 2" class="text-criterio "> -->
                                <!-- <span class="d-cell">{{criterio.descripcion}}</span> -->
                                <span *ngIf="subgrupo.tipo == 2" class="d-cell">{{criterio.cantidad}}</span>
                                <span *ngIf="subgrupo.tipo == 2" class="d-cell">
                                    <div *ngIf="criterio.turno == 1">
                                        Diurno
                                    </div>
                                    <div *ngIf="criterio.turno == 2">
                                        Nocturno
                                    </div>
                                </span>
                                <!-- <span class="d-cell text-muted">{{criterio.observacion}}</span> -->
                                <!-- </span> -->

                                <!-- <span *ngIf="subgrupo.tipo == 3" class="text-criterio "> -->
                                <!-- <span class="d-cell">{{criterio.descripcion}}</span> -->
                                <span *ngIf="subgrupo.tipo == 3" class="d-cell">{{criterio.nombre_apellido}}</span>
                                <span *ngIf="subgrupo.tipo == 3" class="d-cell">{{criterio.perfil}}</span>
                                <!-- <span class="d-cell text-muted ">{{criterio.observacion}}</span> -->
                                <!-- </span> -->

                                <!-- <span *ngIf="subgrupo.tipo == 4" class="text-criterio"> -->
                                <!-- <span class="d-cell">{{criterio.descripcion}}</span> -->
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">{{criterio.marca}}</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">{{criterio.modelo}}</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">{{criterio.ano}}</span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">
                                    <div *ngIf="criterio.tipo_propiedad == 1">Propio</div>
                                    <div *ngIf="criterio.tipo_propiedad == 2">Arrendado</div>
                                </span>
                                <span *ngIf="subgrupo.tipo == 4" class="d-cell">
                                    <span *ngIf="criterio.cumple_ECF == 1">Si</span>
                                    <span *ngIf="criterio.cumple_ECF == 0">No</span>
                                </span>
                                <span class="text-muted d-cell">{{criterio.observacion}}</span>
                                <span class="d-cell">
                                    <app-view-file-buttons [nombreArchivo]="criterio.documento"></app-view-file-buttons>
                                </span>

                                <!-- </span> -->
                                <div *ngIf="!evaluacionCompleta" class="criterio_options d-cell">
                                    <!-- <input type="text" [value]="criterio.calificacion" (focusout)="ponerNota($event, i, y, x)"> -->

                                    <div class="form-group row m-b-15">
                                        <ng-container *ngIf="this.objLicitacion.ETAPA_CORRELATIVO === 9">
                                            <label class="col-form-label text-muted col-md-12">
                                                <button type="button" class="btn btn-primary" data-toggle="modal"
                                                    data-target="#modalAyudaEvaluacionTecnica"
                                                    (click)="ayudaevalTecnicaData(subgrupo.idAyuda, i, y, x, grupo.descripcion, criterio.descripcion, subgrupo.correlativo)">Ayuda
                                                    Calificación</button>
                                            </label>
                                            <div class="col-md-12">
                                                <input class="form-control" type="number" placeholder="Calificacion"
                                                    type="text" [value]="criterio.calificacion"
                                                    (focusout)="ponerNota($event, i, y, x)">
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <chat [idLicitacion]="idLicitacion"></chat>
    <br>
    <!-- end row -->
    <div *ngIf="this.objLicitacion.ETAPA_CORRELATIVO === 9" class="text-right">
        <ng-container *ngIf="!evaluacionCompleta">
            <button type="button" class="btn btn-primary" (click)="createValidacionEvalTecEvaluador()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-check" style="color: white;"></i> Completar
                Evaluación</button>
        </ng-container>
        <ng-container *ngIf="evaluacionCompleta">
            <button type="button" class="btn btn-primary" (click)="deleteValidacionEvalTecEvaluador()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-times" style="color: white;"></i> Deshacer
                Evaluación</button>
        </ng-container>
    </div>
</div>
<!-- Modal ayuda evaluacion tecnica -->
<div class="modal fade bd-example-modal-lg" id="modalAyudaEvaluacionTecnica" tabindex="-1" role="dialog"
    aria-labelledby="modalAyudaEvaluacionTecnica" aria-hidden="true">
    <div style="max-width: 95vw;" class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Ayuda de evaluación técnica</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h4>{{grupoDescripcion}}</h4>
                <p class="text-muted">{{subGrupoDescripcion}}</p>
                <ng-container *ngIf="ayudaEvalTecnicaLs.length > 0">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <!-- para  filtros en el futuro -->
                            <ng-container *ngIf="cargosFilter.length > 0">
                                <div>
                                    <button class="btn tag text-primary m-r-10 m-b-10"
                                        (click)="selectCargo('')">Todos</button>
                                    <ng-container *ngFor="let cargo of cargosFilter">
                                        <button class="btn tag text-primary m-r-10 m-b-10"
                                            (click)="selectCargo(cargo)">{{cargo}}</button>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-12 col-md-4">
                            <input matinput="" (keyup)="applyFilterAyudaEvalTecnica($event)" placeholder="Buscar ..."
                                class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                            <i matsuffix="" class="fas fa-search"
                                style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                        </div>
                    </div>
                    <br>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Descripcion</th>
                                <th scope="col">cargo</th>
                                <th scope="col">ponderador</th>
                                <th scope="col">perfil requerido</th>
                                <th scope="col">nota</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ayudaEvalTecnicaLsFiltered">
                                <td class="table-ayuda-text">{{ item.descripcion }}</td>
                                <td class="table-ayuda-text">{{ item.cargo }}</td>
                                <td class="table-ayuda-text">{{ item.ponderador }}</td>
                                <td class="table-ayuda-text">{{ item.perfil_requerido }}</td>
                                <td class="text-center font-weight-bold">
                                    <button *ngIf="item.nota == 0" type="button"
                                        class="btn btn-outline-danger table-ayuda-text"
                                        (click)="ponerNotaSelect(item.nota)" data-dismiss="modal">{{ item.nota
                                        }}</button>
                                    <button *ngIf="item.nota > 0 && item.nota <= 40" type="button"
                                        class="btn btn-outline-warning table-ayuda-text"
                                        (click)="ponerNotaSelect(item.nota)" data-dismiss="modal">{{ item.nota
                                        }}</button>
                                    <button *ngIf="item.nota > 40 && item.nota <= 100" type="button"
                                        class="btn btn-outline-success table-ayuda-text"
                                        (click)="ponerNotaSelect(item.nota)" data-dismiss="modal">{{ item.nota
                                        }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container *ngIf="ayudaEvalTecnicaLs.length <= 0">
                    <div class="alert alert-warning" role="alert">No existe ayuda para este caso de evaluación.</div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>