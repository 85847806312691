import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LicitacionService } from "../shared/services/licitacion.service";
import { NotificationService } from "../shared/services/notification.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

interface MotivosRenuncia {
  correlativo: number;
  descripcion: string;
  tipo: string;
  vigente: number;
  fecha_creacion: Date;
}

@Component({
  selector: "app-contratista",
  templateUrl: "./contratista.component.html",
  styleUrls: ["./contratista.component.css"],
})
export class ContratistaComponent implements OnInit {
  licitaciones: [];
  idParticipante: number;
  // variables para el bucador
  filterLicitacion: string;
  // carga de archivos en el correo
  archivos64: Blob;
  fileName: string;
  type: string;
  archivo: string;
  // usuario
  userId: string;
  adjunto: string;
  // formulario de renuncia de particiapante a licitacion
  renunciarLicitacionForm: FormGroup;
  @ViewChild("inputFile") inputFile: ElementRef;
  idLicitacion: number;
  idEtapaLic: number;
  etapaActualLicitacion: number;
  @ViewChild("closeModal") closeModal: ElementRef;
  motivoRenunciaList: MotivosRenuncia[];
  confirmacionEnviada: boolean;
  datosParticipanteLic: any[];
  selectAclaracionesLicitaciones: any[];

  constructor(
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder
  ) {
    this.idLicitacion = 0;
    this.idEtapaLic = 0;
    this.etapaActualLicitacion = 0;
    this.motivoRenunciaList = [];
    this.confirmacionEnviada = false;
    this.datosParticipanteLic = [];
    this.userId = JSON.parse(localStorage.getItem("userData")).userId;
    this.selectAclaracionesLicitaciones = [];
  }

  ngOnInit(): void {
    this.renunciarLicitacionForm = new FormGroup({
      motivoRenuncia: new FormControl(""),
      nombreArchivo: new FormControl(""),
      archivo: new FormControl(null),
      motivoRenunciaCorrelativo: new FormControl(null),
    });

    this.renunciarLicitacionForm = this.formBuilder.group({
      motivoRenuncia: ["", Validators.required],
      nombreArchivo: ["", Validators.required],
      archivo: [null, Validators.required],
      motivoRenunciaCorrelativo: [null, Validators.required],
    });

    this.licitacionService.getDatosPart().subscribe((resp) => {
      this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
      if (this.idParticipante) {
        this.licitacionService
          .getLicitacionesParticipante(this.idParticipante)
          .subscribe(
            (resp) => {
              this.licitaciones = JSON.parse(resp);
            },
            (err) => {
              this.notifyService.showError(
                err.error.message,
                "Obtener licitaciones"
              );
            }
          );
      }
    });

    this.licitacionService.getSelectParticipanteLic().subscribe(
      (resp) => {
        this.datosParticipanteLic = JSON.parse(resp);
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener datos del participante Lic"
        );
      }
    );

    this.getMotivosRenuncia();
    this.getSelectAclaracionesLicitaciones();
  }

  onSolicitarRenunciaLicitacion(
    idLicitacion: number,
    etapaActualLicitacion: number,
    idEtapaLic: number
  ) {
    this.idLicitacion = idLicitacion;
    this.etapaActualLicitacion = etapaActualLicitacion;
    this.idEtapaLic = idEtapaLic;
  }

  renunciarLicitacion() {
    if (this.renunciarLicitacionForm.invalid) {
      this.notifyService.showWarning(
        "Todos los campos son obligatorios",
        "Renunciar licitación"
      );
      return;
    }

    const postDataRenuncia = {
      motivo_renuncia_licitacion:
        this.renunciarLicitacionForm.value.motivoRenuncia,
      pin_lic_correlativo: this.idLicitacion,
      pin_part_correlativo: this.idParticipante,
      motivo_renuncia_correlativo:
        this.renunciarLicitacionForm.value.motivoRenunciaCorrelativo,
    };

    this.licitacionService
      .updateSolicitudRenunciaLicitacion(postDataRenuncia)
      .subscribe(
        (resp) => {
          const postDataFile: {
            in_det_etapa_lic: number;
            files: Blob;
            fileName: string;
            type: string;
            in_usuario: string;
            in_domi_correlativo_tiar: number;
            publico: number;
            descripcion_input_archivo: string;
            idLicitacion: number;
            idEtapaLicitacion: number;
            correlativoGrupoArchivo: number;
          } = {
            in_det_etapa_lic: this.idEtapaLic,
            files: this.archivos64,
            fileName: this.fileName,
            type: this.type,
            in_usuario: this.idParticipante.toString(),
            in_domi_correlativo_tiar: 27,
            publico: 0,
            descripcion_input_archivo:
              this.renunciarLicitacionForm.value.nombreArchivo == null ||
              this.renunciarLicitacionForm.value.nombreArchivo == ""
                ? this.fileName
                : this.renunciarLicitacionForm.value.nombreArchivo,
            idLicitacion: this.idLicitacion,
            idEtapaLicitacion: this.etapaActualLicitacion,
            correlativoGrupoArchivo: 0,
          };

          this.licitacionService.postArchivosLicitacion(postDataFile).subscribe(
            (resp) => {
              this.adjunto = JSON.parse(resp)[1].nombre_archivo;
              // colocar aqui el envio de notificacion a oferente y responsables de la licitacion
              // console.log(this.adjunto);

              const postDataEnvioNotifiacionRenuncia = {
                pin_lic_correlativo: this.idLicitacion,
                in_part_correlativo: this.idParticipante,
                in_adjunto: this.adjunto,
              };

              this.licitacionService
                .postEnviaNotificacionRenunciaLicitacion(
                  postDataEnvioNotifiacionRenuncia
                )
                .subscribe(
                  (resp) => {
                    // volver a cargar las licitaciones
                    this.licitacionService
                      .getLicitacionesParticipante(this.idParticipante)
                      .subscribe(
                        (resp) => {
                          this.licitaciones = JSON.parse(resp);
                          this.cancelarReununciaLicitacion();
                          this.closeModal.nativeElement.click();
                          this.notifyService.showWarning(
                            "Su solicitud de renuncia ha sido enviada y ha quedado fuera del proceso de la licitación.",
                            "Solicitud de renuncia a licitación"
                          );
                        },
                        (err) => {
                          this.notifyService.showError(
                            err.error.message,
                            "Obtener licitaciones"
                          );
                        }
                      );
                  },
                  (err) => {
                    this.notifyService.showError(
                      err.error.message,
                      "Participante"
                    );
                  }
                );
            },
            (err) => {
              this.notifyService.showError(
                err.error.message,
                "Cargar archivo de licitacion"
              );
            }
          );
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Error al solicitar renuncia a Licitación"
          );
        }
      );
  }

  cancelarReununciaLicitacion() {
    this.renunciarLicitacionForm = this.formBuilder.group({
      motivoRenuncia: ["", Validators.required],
      nombreArchivo: ["", Validators.required],
      archivo: [null, Validators.required],
      motivoRenunciaCorrelativo: [null, Validators.required],
    });

    this.archivos64 = null;
    this.fileName = "";
    this.type = "";
    this.inputFile.nativeElement.value = "";

    this.idLicitacion = 0;
    this.idEtapaLic = 0;
    this.etapaActualLicitacion = 0;
  }

  detectFiles(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64;
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
        }
      };
    }
  }

  getMotivosRenuncia() {
    this.licitacionService.getMotivosRenunciaLicitacion().subscribe(
      (resp) => {
        const motivosRenuncia: any[] = JSON.parse(resp);
        const motivosActivos: any[] = motivosRenuncia.filter(
          (element) => element.tipo === "OFERENTE"
        );

        // console.log(motivosActivos);

        if (motivosActivos.length > 0) {
          this.motivoRenunciaList = motivosActivos;
        } else {
          this.motivoRenunciaList = [];
        }
      },
      (error) => {
        this.motivoRenunciaList = [];
        this.notifyService.showError(
          error.error.message,
          "Error al obtener los motivos de renuncia"
        );
      }
    );
  }

  getArchivoLicitacionValidarConfirmacion(idLicitacion: number, etapa: number) {
    if (etapa > 0 && etapa < 4) {
      this.licitacionService
        .getArchivosLicitacionOferente(idLicitacion, this.userId)
        .subscribe(
          (resp) => {
            let archivosLicitacion: any[] = JSON.parse(resp);
            archivosLicitacion = archivosLicitacion.filter(
              (x) => x.ETAPA_CORRELATIVO == etapa
            );

            if (archivosLicitacion.length > 0) {
              this.confirmacionEnviada = true;
            } else {
              this.confirmacionEnviada = false;
            }
          },
          (err) => {
            this.confirmacionEnviada = false;
            this.notifyService.showError(
              err.error.message,
              "Obtener archivos licitación"
            );
          }
        );
    }
  }

  validateCorreccionPresupuesto(idLicitacion: number): boolean {
    return this.datosParticipanteLic.some(
      (item: { lic_correlativo: number; corregir_presupuesto: number }) =>
        item.lic_correlativo === idLicitacion && item.corregir_presupuesto === 1
    );
  }

  getSelectAclaracionesLicitaciones() {
    this.licitacionService.getSelectAclaracionesLicitaciones().subscribe(
      (resp) => {
        const aclaracioneLicitacion: any[] = JSON.parse(resp);

        if (aclaracioneLicitacion.length > 0) {
          this.selectAclaracionesLicitaciones = aclaracioneLicitacion;
          // console.log(aclaracioneLicitacion);
        } else {
          this.selectAclaracionesLicitaciones = [];
        }
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Error en al comprobacion de las aclaraciones"
        );
        this.selectAclaracionesLicitaciones = [];
      }
    );
  }

  validateRespuestasAclaraciones(
    idLicitacion: number,
    etapaCorrelativo: number
  ): boolean {
    return this.selectAclaracionesLicitaciones.some(
      (item: {
        LIC_CORRELATIVO: number;
        ETAPA_CORRELATIVO: number;
        NOMBRE_ARCHIVO_RESP: string;
        PART_CORRELATIVO: number;
      }) =>
        item.LIC_CORRELATIVO === idLicitacion &&
        item.ETAPA_CORRELATIVO === etapaCorrelativo &&
        item.NOMBRE_ARCHIVO_RESP === null &&
        item.PART_CORRELATIVO == JSON.parse(localStorage.getItem("userData")).userId
    );
  }
}
