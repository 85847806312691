import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { LicitacionService } from "../shared/services/licitacion.service";
import { ActivatedRoute, Params } from "@angular/router";
import { EtapasService } from "../shared/services/etapas.licitacion.service";
import { Location } from "@angular/common";
import { ParticipantesService } from "../shared/services/participantes.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FileService } from "../shared/services/file.service";

interface HeaderLicitacion {
  SOLPE_CORRELATIVO: string;
  CONT_NOMBRE: string;
  CONT_NUMERO: string;
  DESCRIPCION: string;
  FECHA_CREACION: string;
  MONTO_SOLPE: number;
  MONEDA_SOLPE: number;
  DESCRIPCION_MONEDA_SOLPE: string;
  ETAPA_CORRELATIVO: number;
  CORRELATIVO_ETAPA: number;
  ETAPA_DESC: string;
  LICITACION_CORRELATIVO: number;
  FECHA_PUBLICACION: null | string;
  CONSULTAS_APROBADAS: number;
  RESP_PROPCESO: number;
  CORRELATIVO_SOLPE: number;
  VALIDACION_LCT: number;
  OBSERVACION_LCT: string;
  MONTO_SOLPE_USD: number;
  ORDEN: number;
  VALIDACION_JAC: number;
  OBSERVACION_JAC: string;
  TIENE_ACLARACIONES_TEC: number;
  TIENE_ACLARACIONES_ECO: number;
  TIENE_NEGOCIACION: number;
  LICITACION_FINALIZADA: number;
}

export interface Participangtes {
  PART_CORRELATIVO: number;
  PART_NOMBRE: string;
  RAZON_SOCIAL: string;
  ENCARGADO: string;
  vigente: string;
  CORREO_ELECTRONICO: string;
  FECHA_PRESUPUESTO: string;
  RUT: string;
  APROBO_EVAL_TEC: number;
  APROBO_EVAL_ECO: number;
  ADJUDICADO: number;
}

@Component({
  selector: "app-adjudicacion",
  templateUrl: "./adjudicacion.component.html",
  styleUrls: ["./adjudicacion.component.css"],
})
export class AdjudicacionComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  licitacionData: HeaderLicitacion;
  objParticipantes: Participangtes[];
  archivos: any[];
  presupuestosOferentesLicitacion: any[];
  oferenteSelected: number;
  participanteNombre: string;
  isOferenteAdjudicado: boolean;
  cartasAdjudicacion: any[];
  rolesParticipanteComision: any[];
  cartasAgradecimiento: any[];
  documentosAdjudicacionMaf: any[];
  fileNameCartaAdjudicacion: string;
  fileDescripcionCartaAdjudicacion: string;
  corretlativoCartaAdjudicacion: number;
  correlativoCartaAgradecimiento: number;
  correlativoOferente: number;

  // cargar archivos para la carta de adjudicacion
  archivos64: Blob;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivo: string;
  @ViewChild("inputFile")
  inputFile: ElementRef;
  uploadFileModal: boolean = false;
  formDocCartaAdjudicacion: FormGroup;
  formDocCartaAgradecimiento: FormGroup;
  formDocAdjudicacionMaf: FormGroup;

  filesToSend: Blob;

  @ViewChild("closeModalAdjudicar") closeModalAdjudicar: ElementRef;
  @ViewChild("closeModalAdjudicarCarta") closeModalAdjudicarCarta: ElementRef;
  @ViewChild("closeModalAgradecimentoCarta")
  closeModalAgradecimentoCarta: ElementRef;
  @ViewChild("closeModalAdjudicarDocumento")
  closeModalAdjudicarDocumento: ElementRef;
  @ViewChild("closeModalEnviarCartaASolpe")
  closeModalEnviarCartaASolpe: ElementRef;
  @ViewChild("closeModalCartaAdjudicacion")
  closeModalCartaAdjudicacion: ElementRef;
  @ViewChild("closeModalDeleteCartaAdjudicacion")
  closeModalDeleteCartaAdjudicacion: ElementRef;
  @ViewChild("closeModalDeleteCartaAgradecimiento")
  closeModalDeleteCartaAgradecimiento: ElementRef;
  @ViewChild("closeModalCartaAgradecimiento")
  closeModalCartaAgradecimiento: ElementRef;

  constructor(
    private notifyService: NotificationService,
    private licitacionService: LicitacionService,
    private location: Location,
    private route: ActivatedRoute,
    private etapaService: EtapasService,
    private participantesServices: ParticipantesService,
    private fileService: FileService
  ) {
    this.objParticipantes = [];
    this.presupuestosOferentesLicitacion = [];
    this.archivos = [];
    this.oferenteSelected = 0;
    this.participanteNombre = "";
    this.isOferenteAdjudicado = false;
    this.cartasAdjudicacion = [];
    this.rolesParticipanteComision = [];
    this.cartasAgradecimiento = [];
    this.documentosAdjudicacionMaf = [];
    this.fileNameCartaAdjudicacion = "";
    this.fileDescripcionCartaAdjudicacion = "";
    this.corretlativoCartaAdjudicacion = 0;
    this.correlativoCartaAgradecimiento = 0;
    this.correlativoOferente = 0;

    this.formDocCartaAdjudicacion = new FormGroup({
      descripcionCartaAdjudicacion: new FormControl(null, Validators.required),
    });

    this.formDocCartaAgradecimiento = new FormGroup({
      descripcionCartaAgradecimiento: new FormControl(
        null,
        Validators.required
      ),
    });

    this.formDocAdjudicacionMaf = new FormGroup({
      descripcionDocAdjudicacionMaf: new FormControl(null, Validators.required),
    });

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const objLicitacionData: HeaderLicitacion = JSON.parse(resp)[0];
          this.licitacionData = objLicitacionData;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );

      this.getSelectRolesParticipanteComision();
    });
  }

  ngOnInit(): void {
    this.getParticipantesLicitacionID();
    this.getArchivoLicEtapaFull();
    this.getCartaAdjudicacion();
    this.getSelectCartasAgradecimiento();
    this.getDocsAdjudicacionMaf();
  }

  back(): void {
    this.location.back();
  }

  getHeaderLicitacionId() {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        const objLicitacionData: HeaderLicitacion = JSON.parse(resp)[0];
        this.licitacionData = objLicitacionData;
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener datos de la licitacion"
        );
      }
    );
  }

  getParticipantesLicitacionID() {
    this.licitacionService
      .getParticipantesLicitacionID(this.idLicitacion)
      .subscribe(
        (resp) => {
          const participantesLic: Participangtes[] = JSON.parse(
            resp
          ) as Participangtes[];
          // se filtra los participantes que aprobaron la evaluacion de tecnica y economica
          this.objParticipantes = participantesLic.filter(
            (participante) => participante.APROBO_EVAL_ECO == 1
          );

          if (this.objParticipantes[0].ADJUDICADO == 1) {
            this.isOferenteAdjudicado = true;
            this.oferenteSelected = this.objParticipantes[0].PART_CORRELATIVO;
            this.participanteNombre = this.objParticipantes[0].RAZON_SOCIAL;
          } else {
            this.isOferenteAdjudicado = false;
            this.oferenteSelected = 0;
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
  }

  getArchivoLicEtapaFull() {
    this.etapaService
      .getArchivoLicEtapaFull(this.idLicitacion, 10)
      .subscribe((resp) => {
        this.archivos = JSON.parse(resp);
      });
  }

  filesPart(partCorrelativo: number): any[] {
    return this.archivos.filter(
      (element) => element.PARTICIPANTE === partCorrelativo
    );
  }

  adjudicarParticipante(partCorrelativo: number, participanteNombre: string) {
    this.oferenteSelected = partCorrelativo;
    this.participanteNombre = participanteNombre;
  }

  onAdjudicar() {
    const postData = {
      part_correlativo: this.oferenteSelected,
      lic_correlativo: this.idLicitacion,
      nombre_oferente: this.participanteNombre,
    };

    this.participantesServices.postUpdateAdjudicacion(postData).subscribe(
      (resp) => {
        this.getParticipantesLicitacionID();
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Adjudicar participante"
        );
      }
    );

    // logica de la adjudicacion
    this.closeModalAdjudicar.nativeElement.click();
  }

  getCartaAdjudicacion() {
    this.licitacionService
      .getSelectCartasAdjudicacionLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          const cartasAdjudicacion: any[] = JSON.parse(resp);

          if (cartasAdjudicacion.length > 0) {
            this.cartasAdjudicacion = JSON.parse(resp);
          } else {
            this.cartasAdjudicacion = [];
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cartas de adjudicacion"
          );
        }
      );
  }

  detectFiles(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64;
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
        }
      };
    }
  }

  enviarCartaAdjudicacion() {
    if (this.formDocCartaAdjudicacion.invalid) {
      this.notifyService.showWarning(
        "Todos los campos son obligatorios",
        "Advertencia"
      );
      return;
    }

    const postData: {
      fileName: string;
      fileDescripcion: string;
      type: string;
      files: Blob;
      idLicitacion: number;
      idParticipante: number;
      idSolpe: number;
      nombreOferente: string;
    } = {
      fileName: this.fileName,
      fileDescripcion:
        this.formDocCartaAdjudicacion.value.descripcionCartaAdjudicacion,
      type: this.type,
      files: this.archivos64,
      idLicitacion: this.idLicitacion,
      idParticipante: this.oferenteSelected,
      idSolpe: this.licitacionData.CORRELATIVO_SOLPE ?? 0,
      nombreOferente: this.participanteNombre,
    };

    if (postData.files) {
      this.licitacionService.postCreateDocCartaAdjudicacion(postData).subscribe(
        (resp) => {
          this.getCartaAdjudicacion();

          this.formDocCartaAdjudicacion = new FormGroup({
            descripcionCartaAdjudicacion: new FormControl(
              null,
              Validators.required
            ),
          });

          this.archivos64 = null;
          this.fileName = "";
          this.type = "";
          this.inputFile.nativeElement.value = "";

          this.notifyService.showSuccess(
            "Carta de adjudicación agregada correctamente",
            "Carta de adjudicación"
          );

          this.closeModalAdjudicarCarta.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Carta de adjudicación"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Carta de adjudicación"
      );
    }
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  getSelectCartasAgradecimiento() {
    this.licitacionService
      .getSelectCartasAgradecimiento(this.idLicitacion)
      .subscribe(
        (resp) => {
          const cartasAgradecimiento: any[] = JSON.parse(resp);

          if (cartasAgradecimiento.length > 0) {
            this.cartasAgradecimiento = JSON.parse(resp);
          } else {
            this.cartasAgradecimiento = [];
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cartas de agradecimiento"
          );
        }
      );
  }

  enviarCartasAgradecimiento() {
    if (this.formDocCartaAgradecimiento.invalid) {
      this.notifyService.showWarning(
        "Todos los campos son obligatorios",
        "Advertencia"
      );
      return;
    }

    const postData: {
      correlativo: number;
      fileDescripcion: string;
      fileName: string;
      type: string;
      files: Blob;
      idLicitacion: number;
      correlativoOferente: number;
    } = {
      correlativo: this.correlativoCartaAgradecimiento,
      fileDescripcion:
        this.formDocCartaAgradecimiento.value.descripcionCartaAgradecimiento,
      fileName: this.fileName,
      type: this.type,
      files: this.archivos64,
      idLicitacion: this.idLicitacion,
      correlativoOferente: this.correlativoOferente,
    };

    if (postData.files) {
      this.licitacionService.postUpdateCartasAgradecimiento(postData).subscribe(
        (resp) => {
          this.getSelectCartasAgradecimiento();

          this.formDocCartaAgradecimiento = new FormGroup({
            descripcionCartaAgradecimiento: new FormControl(
              null,
              Validators.required
            ),
          });

          this.archivos64 = null;
          this.fileName = "";
          this.type = "";
          this.inputFile.nativeElement.value = "";

          this.notifyService.showSuccess(
            "Cartas de Agradecimiento generadas correctamente",
            "Cartas de agradecimiento"
          );

          this.closeModalAgradecimentoCarta.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Cartas de agradecimiento"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Carta de adjudicación"
      );
    }
  }

  cartaAdjudicacionSolpeContrato(
    fileName: string,
    fileDescripcion: string,
    correlativo: number
  ) {
    this.fileNameCartaAdjudicacion = fileName;
    this.fileDescripcionCartaAdjudicacion = fileDescripcion;
    this.corretlativoCartaAdjudicacion = correlativo;
  }

  postSaveCartaAdjudicacionSolpeContrato() {
    this.fileService.downloadFile(this.fileNameCartaAdjudicacion).subscribe(
      (resp) => {
        const reader = new FileReader();
        reader.readAsDataURL(resp);

        reader.onloadend = () => {
          const base64data: any = reader.result;

          const postData = {
            fileName: this.fileNameCartaAdjudicacion,
            fileDescripcion: this.fileDescripcionCartaAdjudicacion,
            type: this.fileNameCartaAdjudicacion.slice(
              ((this.fileNameCartaAdjudicacion.lastIndexOf(".") - 1) >>> 0) + 2
            ),
            files: base64data,
            solpeCorrelativo: this.licitacionData.CORRELATIVO_SOLPE.toString(),
            tipoDocSolpe: "139",
            idLicitacion: this.idLicitacion,
          };

          this.licitacionService
            .postSaveCartaAdjudicacionSolpeContrato(postData)
            .subscribe(
              (resp) => {
                this.fileNameCartaAdjudicacion = "";
                this.fileDescripcionCartaAdjudicacion = "";

                this.getCartaAdjudicacion();
                this.getHeaderLicitacionId();

                this.closeModalEnviarCartaASolpe.nativeElement.click();

                this.notifyService.showSuccess(
                  "Carta de adjudicación enviada correctamente a la SOLPE",
                  "Carta de adjudicación"
                );
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Carta de adjudicación"
                );
              }
            );
        };
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Obtener archivos");
      }
    );
  }

  getDocsAdjudicacionMaf() {
    this.licitacionService
      .getSelectDocAdjudicacionMaf(this.idLicitacion)
      .subscribe(
        (resp) => {
          const docsAdjudicacionMaf: any[] = JSON.parse(resp);

          if (docsAdjudicacionMaf.length > 0) {
            this.documentosAdjudicacionMaf = JSON.parse(resp);
          } else {
            this.documentosAdjudicacionMaf = [];
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener documentos de adjudicacion maf"
          );
        }
      );
  }

  cargarDocumentosAdjudicacionMaf() {
    if (this.formDocAdjudicacionMaf.invalid) {
      this.notifyService.showWarning(
        "Todos los campos son obligatorios",
        "Advertencia"
      );
      return;
    }

    const postData: {
      fileName: string;
      fileDescripcion: string;
      type: string;
      files: Blob;
      idLicitacion: number;
    } = {
      fileName: this.fileName,
      fileDescripcion:
        this.formDocAdjudicacionMaf.value.descripcionDocAdjudicacionMaf,
      type: this.type,
      files: this.archivos64,
      idLicitacion: this.idLicitacion,
    };

    if (postData.files) {
      this.licitacionService.postCreateDocAdjudicacionMaf(postData).subscribe(
        (resp) => {
          this.getDocsAdjudicacionMaf();

          this.formDocAdjudicacionMaf = new FormGroup({
            descripcionDocAdjudicacionMaf: new FormControl(
              null,
              Validators.required
            ),
          });

          this.archivos64 = null;
          this.fileName = "";
          this.type = "";
          this.inputFile.nativeElement.value = "";

          this.notifyService.showSuccess(
            "Documento de adjudicación MAF cargado correctamente",
            "Documento de adjudicación MAF"
          );

          this.closeModalAdjudicarDocumento.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Documento de adjudicación MAF"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Documento de adjudicación MAF"
      );
    }
  }

  handleCartaAgradecimientoOferente(
    correlativoCarta: number,
    correlativoOferente: number
  ) {
    this.correlativoCartaAgradecimiento = correlativoCarta;
    this.correlativoOferente = correlativoOferente;
  }

  // crear el generador de los registos de las cartas de agradecimiento con el servicio "postGenerateCartasAgradecimiento"

  generarCartasAgradecimiento() {
    this.licitacionService
      .postGenerateCartasAgradecimiento({ idLicitacion: this.idLicitacion })
      .subscribe(
        (resp) => {
          this.getSelectCartasAgradecimiento();

          this.notifyService.showSuccess(
            "Oferentes designados correctamente",
            "Cartas de agradecimiento"
          );
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Cartas de agradecimiento"
          );
        }
      );
  }

  onCartaAdjudicacion(
    correlativoCartaAdjudicacion: number,
    nombreOferenteAdjudicado: string
  ) {
    this.corretlativoCartaAdjudicacion = correlativoCartaAdjudicacion;
    this.participanteNombre = nombreOferenteAdjudicado;
  }

  updateEstadoCartaAdjudicacion() {
    this.licitacionService
      .postUpdateEstadoCartaAdjudicacion({
        correlativoCarta: this.corretlativoCartaAdjudicacion,
        idLicitacion: this.idLicitacion,
        nombreOferente: this.participanteNombre,
      })
      .subscribe(
        (resp) => {
          this.getCartaAdjudicacion();

          this.notifyService.showSuccess(
            `Carta de adjudicación enviada al oferente: ${this.participanteNombre}`,
            "Carta de adjudicación"
          );

          this.closeModalCartaAdjudicacion.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Carta de adjudicación"
          );
        }
      );
  }

  deleteCartaAdjudicacion() {
    this.licitacionService
      .postDeleteCartaAdjudicacion({
        correlativoCarta: this.corretlativoCartaAdjudicacion,
      })
      .subscribe(
        (resp) => {
          this.getCartaAdjudicacion();

          this.notifyService.showWarning(
            `Carta de adjudicación eliminada para el oferente: ${this.participanteNombre}`,
            "Carta de adjudicación"
          );

          this.closeModalDeleteCartaAdjudicacion.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Carta de adjudicación"
          );
        }
      );
  }

  onCartaAgradecimiento(
    correlativoCartaAgradecimiento: number,
    correlativoOferente: number,
    nombreOferenteAdjudicado: string
  ) {
    this.correlativoCartaAgradecimiento = correlativoCartaAgradecimiento;
    this.correlativoOferente = correlativoOferente;
    this.participanteNombre = nombreOferenteAdjudicado;
  }

  updateEstadoCartaAgradecimiento() {
    this.licitacionService
      .postUpdateEstadoCartaAgradecimiento({
        correlativoCartaAgradecimiento: this.correlativoCartaAgradecimiento,
        idLicitacion: this.idLicitacion,
        correlativoOferente: this.correlativoOferente,
      })
      .subscribe(
        (resp) => {
          this.getSelectCartasAgradecimiento();

          this.notifyService.showSuccess(
            `Carta de agradecimiento enviada al oferente: ${this.participanteNombre}`,
            "Carta de agradecimiento"
          );

          this.closeModalCartaAgradecimiento.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Carta de agradecimiento"
          );
        }
      );
  }

  deleteCartaAgradecimiento() {
    this.licitacionService
      .postDeleteCartaAgradecimiento({
        correlativoCartaAgradecimiento: this.correlativoCartaAgradecimiento,
      })
      .subscribe(
        (resp) => {
          this.getSelectCartasAgradecimiento();

          this.notifyService.showWarning(
            `Carta de agradecimiento eliminada para el oferente: ${this.participanteNombre}`,
            "Carta de agradecimiento"
          );

          this.closeModalDeleteCartaAgradecimiento.nativeElement.click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Carta de agradecimiento"
          );
        }
      );
  }
}
