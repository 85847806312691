<div class="content" style="padding-bottom: 0;">
    <div class="d-flex justify-content-between">
        <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
            (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                style="color: white;"></i> Volver</button>
        <div>
            <!-- Agregar el click con la funcion que trae el Desempeño de los evaluadores -->
            <button
                *ngIf="(objLicitacion?.ETAPA_CORRELATIVO == 9) && (validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39))"
                style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
                data-toggle="modal" data-target="#desempenoEvaluadoresModal" (click)="evaluacionesCompletadas()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-chart-bar" style="color: white;"></i>
                Desempeño de Evaluadores</button>

            <button
                *ngIf="(objLicitacion?.ETAPA_CORRELATIVO == 9) && (validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39))"
                style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
                data-toggle="modal" data-target="#modalCompletarEvaluacion" (click)="addListAprobadosAutomatico()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-check" style="color: white;"></i> Completar
                Evaluacion</button>
            <button *ngIf="objLicitacion" type="button" class="btn btn-primary" [disabled]="downloadingPdf"
                style="margin-bottom: 15px; margin-right: 10px;" (click)="onDescargarPDF()">
                <i class="fas fa-lg fa-fw m-r-10 fa-file-pdf"></i>
                Descargar
            </button>
            <button *ngIf="validateEtapaVerBotonArchivos()" type="button" class="btn btn-primary"
                (click)="onVerArchivos = !onVerArchivos;" style="margin-bottom: 15px;">
                <ng-container *ngIf="onVerArchivos">
                    <i class="fas fa-lg fa-fw fa-times"></i>
                    Ocultar archivos
                </ng-container>
                <ng-container *ngIf="!onVerArchivos">
                    <i class="fas fa-lg fa-fw fa-file"></i>
                    Ver archivos
                </ng-container>
            </button>
        </div>
    </div>
</div>

<div id="printComponent">

    <div class="content">
        <div class="table-main">
            <div class="row">
                <div class="col-12 col-md-12">
                    <span class="text-header">Licitación #{{idLicitacion}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="text-muted text-header">Solpe: {{objLicitacion?.SOLPE_CORRELATIVO}}</span>
                </div>
                <div class="col-12">
                    <h3 class="mb-0 text-header">{{objLicitacion?.CONT_NOMBRE}}</h3>
                    <p class="text-header">{{objLicitacion?.CONT_NUMERO}}</p>
                </div>
            </div>
            <ng-container *ngIf="onVerArchivos === true">
                <br>
                <hr>
                <h4 class="text-muted">Archivos de la licitación</h4>
                <table style="margin-top: 10px;" id="table_archivos" class="table table-striped table-td-valign-middle">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-nowrap">Nombre archivo</th>
                            <th class="text-nowrap">Descripción</th>
                            <th class="text-nowrap">Etapa</th>
                            <th class="text-nowrap">Fecha creación</th>
                            <th class="text-nowrap">Subido</th>
                            <th class="text-nowrap"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="odd gradeX" *ngFor="let item of archivosLicitacion">
                            <td class="text-break">
                                <i class="fas fa-file text-primary mr-2"></i>
                                <span>
                                    {{item.DESCRIPCION}}
                                </span>
                            </td>
                            <td class="text-break">
                                <span>
                                    {{item.DESCRIPCION_ARCHIVO}}
                                </span>
                            </td>
                            <td class="text-break">
                                <span class="text-muted">
                                    {{item.ETAPA_DESCRIPCION}}
                                </span>
                            </td>
                            <td>
                                <span class="text-muted">
                                    {{item.FECHA_ARCHIVO }}
                                </span>
                            </td>
                            <td>
                                <span class="text-muted">
                                    {{item.USUARIO}}
                                </span>
                                <!-- <ng-template [ngIf]="item.TIPO_ARCHIVO"> - </ng-template>
                            {{item.NOMBRE_ARCHIVO.split('/').pop()}} -->
                            </td>
                            <td class="text-center">
                                <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <table style="width: 100%;" id="table_resultado">
                <thead class="d-head">
                    <tr>
                        <td class="d-cell">
                            <span>
                                Descripcion
                            </span>
                        </td>
                        <ng-container *ngFor="let parti of obgParticipantes">
                            <td class="d-cell" *ngIf="parti.VIGENTE == 'S'">
                                <span>{{parti.razon_social}}</span>
                                <!-- <span *ngIf="parti.VIGENTE == 'N'" [ngClass]="{'text-danger': parti.VIGENTE == 'N'}">{{parti.razon_social}}</span> -->
                            </td>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let grupo of objGrupos">
                        <td class="d-cell">
                            <span>
                                {{grupo.descripcion}} ({{grupo.peso}}%)
                            </span>
                        </td>
                        <ng-container *ngFor="let parti of obgParticipantes">
                            <td class="d-cell" *ngIf="parti.VIGENTE == 'S'">
                                <ng-container *ngIf="calCapitulo(grupo.correlativo, parti.razon_social) < 80">
                                    <span class="pointer text-danger"
                                        (click)="getResultadosParticiapante(parti.CORRELATIVO,grupo.correlativo)"
                                        href="#modal" data-toggle="modal" title="Ver Detalle">
                                        {{calCapitulo(grupo.correlativo, parti.razon_social) | number:'1.1-1'}}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="calCapitulo(grupo.correlativo, parti.razon_social) >= 80">
                                    <span class="pointer text-success"
                                        (click)="getResultadosParticiapante(parti.CORRELATIVO,grupo.correlativo)"
                                        href="#modal" data-toggle="modal" title="Ver Detalle">
                                        {{calCapitulo(grupo.correlativo, parti.razon_social) | number:'1.1-1'}}
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
                <tfoot>
                    <td class="d-cell pt-5 bold">
                        <span>
                            Resultado
                        </span>
                    </td>
                    <ng-container *ngFor="let parti of obgParticipantes">
                        <td class="d-cell pt-5 bold" *ngIf="parti.VIGENTE == 'S'">
                            <ng-container *ngIf="calTotal(parti.razon_social) < 80">
                                <span class="text-danger">
                                    {{calTotal(parti.razon_social) | number:'1.1-1'}}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="calTotal(parti.razon_social) >= 80">
                                <span class="text-success">
                                    {{calTotal(parti.razon_social) | number:'1.1-1'}}
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>
                </tfoot>
            </table>
        </div>
        <chat [idLicitacion]="idLicitacion"></chat>
    </div>
    <div class="content">
        <div class="table-main">
            <table style="width: 100%;" id="table_detalle">
                <thead class="d-head">
                    <tr>
                        <td class="d-cell">
                            <span>
                                Descripcion
                            </span>
                        </td>
                        <ng-container *ngFor="let parti of obgParticipantes">
                            <td class="d-cell" *ngIf="parti.VIGENTE == 'S'">
                                <span>{{parti.razon_social}}</span>
                                <!-- <span *ngIf="parti.VIGENTE == 'N'" [ngClass]="{'text-danger': parti.VIGENTE == 'N'}">{{parti.razon_social}}</span> -->
                            </td>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container class="" *ngFor="let grupo of objGrupos">
                        <tr class="d-row grupo">
                            <td class="d-cell">
                                <span>
                                    {{grupo.descripcion}} ({{grupo.peso}}%)
                                </span>
                            </td>
                            <ng-container *ngFor="let parti of obgParticipantes">
                                <td *ngIf="parti.VIGENTE == 'S'" class="d-cell">
                                </td>
                            </ng-container>
                        </tr>
                        <tr class="subgroup d-row" *ngFor="let subgrupo of grupo.subgrupoLs; let y = index">
                            <td class="d-cell sub-grupo">
                                <span>
                                    {{subgrupo.descripcion}} ({{subgrupo.peso}}%)
                                </span>
                            </td>
                            <ng-container *ngFor="let parti of obgParticipantes">
                                <td *ngIf="parti.VIGENTE == 'S'" class="d-cell">
                                    <div>
                                        <ng-container
                                            *ngIf="calSubCapitulo(subgrupo.correlativo, parti.razon_social) < 80">
                                            <span class="text-danger">
                                                {{calSubCapitulo(subgrupo.correlativo, parti.razon_social) |
                                                number:'1.1-1'}}
                                            </span>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="calSubCapitulo(subgrupo.correlativo, parti.razon_social) >= 80">
                                            <span class="text-success">
                                                {{calSubCapitulo(subgrupo.correlativo, parti.razon_social) |
                                                number:'1.1-1'}}
                                            </span>
                                        </ng-container>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- <div class="modal modal-message"  id="modal" style="margin-left: 15px !important;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{objParticipante[0]?.DESCRIPCION}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="table-main">
                        <div *ngIf="objEvaluadoresResultados?.length == 0">
                            Sin evaluaciones emitidas
                        </div>
                        <div class="d-table" *ngIf="objEvaluadoresResultados?.length > 0">
                            <div class="d-head">
                                <ng-container class="d-cell" *ngFor="let key of getKeys(); let first = first">
                                    <div *ngIf="first" class="d-cell">
                                        <span>
                                            Descripcion
                                        </span>
                                    </div>
                                    <div *ngIf="(key !== 'correlativo' && key !== 'grupo' && key !== 'peso' && key !== 'descripcion')" class="d-cell">
                                        <span>
                                            {{key}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>

                            <ng-container *ngFor="let grupo of objEvaluadoresResultados">
                                <div class="d-row">
                                    <div class="d-cell">
                                        <span>
                                            {{grupo.descripcion}} ({{grupo.peso}}%)
                                        </span>
                                    </div>
                                    <ng-container class="d-cell" *ngFor="let item of grupo | keyvalue">
                                        <div class="d-cell" *ngIf="(item.key !== 'correlativo' && item.key !== 'grupo' && item.key !== 'peso' && item.key !== 'descripcion')">
                                            <span>
                                                {{item.value}}
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="d-row">
                                <ng-container class="d-cell" *ngFor="let key of getKeys(); let first = first">
                                    <div *ngIf="first" class="d-cell">
                                        <span>
                                            Resultado
                                        </span>
                                    </div>
                                    <div *ngIf="(key !== 'correlativo' && key !== 'grupo' && key !== 'peso' && key !== 'descripcion')" class="d-cell">
                                        <span>
                                            {{calTotalEvaluadores(key) | number:'1.0-0'}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- modal completar evaluacion seleccionar ganadores -->
<div class="modal fade" tabindex="-1" id="modalCompletarEvaluacion" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Resultado de Evaluación Técnica</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalGanadoresEvalTecnica>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Al <strong>Confirmar y avanzar</strong> se cerrar la etapa de evaluación técnica y se avanzará a la
                    siguiente.</p>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Oferentes</th>
                            <th scope="col">Resultado</th>
                            <th scope="col">Estado</th>
                            <!-- <th scope="col ">Acciones</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let parti of obgParticipantes">
                            <th>{{parti.DESCRIPCION}}</th>
                            <td class="text-center">{{calTotal(parti.razon_social) | number:'1.0-0'}}</td>
                            <td>
                                <ng-container *ngIf="parti.VIGENTE == 'S'">
                                    <span *ngIf="validateParticipantesAprobados(parti.razon_social) === true"
                                        class="tab tab-green">Aprobado</span>
                                    <span *ngIf="validateParticipantesAprobados(parti.razon_social) === false"
                                        class="tab tab-red">No Aprobado</span>
                                </ng-container>
                                <ng-container *ngIf="parti.VIGENTE == 'N'">
                                    <span class="tab tab-red">Eliminado</span>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="this.oferentesAprobadorList.length > 0">
                    <br>
                    <p>Ingrese sus observaciones:</p>
                    <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
                </ng-container>
            </div>
            <div class="modal-footer">
                <ng-container *ngIf="this.oferentesAprobadorList.length > 0">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="confirmarParticipantesAprobados()">Confirmar y Avanzar</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                </ng-container>
                <ng-container *ngIf="this.oferentesAprobadorList.length <= 0">
                    <p class="text-danger">Debe existir al menos un oferente aprobado para continuar con la licitación.
                    </p>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- modal completar evaluacion seleccionar ganadores -->
<div class="modal fade" tabindex="-1" id="modal" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{objParticipante[0]?.DESCRIPCION}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalGanadoresEvalTecnica>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="objEvaluadoresResultados?.length == 0">
                    Sin evaluaciones emitidas
                </div>
                <div class="d-table" *ngIf="objEvaluadoresResultados?.length > 0">
                    <div class="d-head">
                        <ng-container class="d-cell" *ngFor="let key of getKeys(); let first = first">
                            <div *ngIf="first" class="d-cell">
                                <span>
                                    Descripcion
                                </span>
                            </div>
                            <div *ngIf="(key !== 'correlativo' && key !== 'grupo' && key !== 'peso' && key !== 'descripcion')"
                                class="d-cell">
                                <span>
                                    {{key}}
                                </span>
                            </div>
                        </ng-container>
                    </div>

                    <ng-container *ngFor="let grupo of objEvaluadoresResultados">
                        <div class="d-row">
                            <div class="d-cell">
                                <span>
                                    {{grupo.descripcion}} ({{grupo.peso}}%)
                                </span>
                            </div>
                            <ng-container class="d-cell" *ngFor="let item of grupo | keyvalue">
                                <div class="d-cell"
                                    *ngIf="(item.key !== 'correlativo' && item.key !== 'grupo' && item.key !== 'peso' && item.key !== 'descripcion')">
                                    <span>
                                        {{roundCalificacionPorcentual(item.value)}}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div class="d-row">
                        <ng-container class="d-cell" *ngFor="let key of getKeys(); let first = first">
                            <div *ngIf="first" class="d-cell">
                                <span>
                                    Resultado
                                </span>
                            </div>
                            <div *ngIf="(key !== 'correlativo' && key !== 'grupo' && key !== 'peso' && key !== 'descripcion')"
                                class="d-cell">
                                <span>
                                    {{calTotalEvaluadores(key) | number:'1.0-0'}}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal desempeno de evaluadores-->
<div class="modal fade" id="desempenoEvaluadoresModal" tabindex="-1" role="dialog"
    aria-labelledby="desempenoEvaluadoresModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="desempenoEvaluadoresModalLabel">Desempeño de evaluadores</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="desempenoEvaluadores.length > 0">
                    <table style="margin-top: 10px;" id="table_archivos"
                        class="table table-striped table-td-valign-middle">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-nowrap">Evaluador</th>
                                <th class="text-nowrap">Oferentes evaluados</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of desempenoEvaluadores">
                                <td class="text-break">
                                    <span>
                                        {{item.evaluador}}
                                    </span>
                                </td>
                                <td class="text-break">
                                    <ng-container *ngIf="item.evaluaciones !== null">
                                        <ng-container *ngFor="let evaluacion of item.evaluaciones">
                                            <p class="text-success">{{evaluacion.oferente}} - (Evaluación Completada)
                                            </p>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-container *ngIf="desempenoEvaluadores.length == 0">
                    <p class="text-center text-muted">No se han emitido evaluaciones</p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>