import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../shared/services/notification.service';
import { LicitacionService } from '../shared/services/licitacion.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-administrar-doc-req-oferente-lic',
  templateUrl: './administrar-doc-req-oferente-lic.component.html',
  styleUrls: ['./administrar-doc-req-oferente-lic.component.css']
})
export class AdministrarDocReqOferenteLicComponent implements OnInit {

  docRequeridosOferenteActa: any[];
  docRequeridosOferenteToSave: any[];
  formDocRequeridosOferenteActa: FormGroup;
  idLicitacion: number;
  licitacionData: any;

  constructor(
    private notifyService : NotificationService,
    private licitacionService : LicitacionService,
    private location: Location,
    private route: ActivatedRoute,
  ) {
    this.docRequeridosOferenteActa = [];
    this.docRequeridosOferenteToSave = [];
    
    this.formDocRequeridosOferenteActa = new FormGroup({
      docRequeridoOferenteCorrelativo: new FormControl(null, Validators.required)
    });

    this.route.params.subscribe((params: Params)=>{
      this.idLicitacion = params['idLicitacion'];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(resp =>{
        this.licitacionData = JSON.parse(resp);
        this.licitacionData = this.licitacionData[0];
      }, err =>{
        this.notifyService.showError(err.error.message, 'Obtener datos de la licitacion');
      });
    });
  }

  ngOnInit(): void {
    this.getDocumentosRequeridosOferente();
    this.getDocRequeridosOferenteSaved();
  }

  deleteDocRequeridoOferente(correlativo: number){
    this.licitacionService.postDeleteDocRequeridoOferenteActa({correlativo: correlativo}).subscribe(
      resp=>{
        this.licitacionService.getSelectDocRequeridosOferenteSaved(this.idLicitacion).subscribe(
          resp=>{
            this.notifyService.showWarning("El documento requerido ha sido eliminado.", "Correcto");
            this.docRequeridosOferenteToSave = JSON.parse(resp);
          }
        );
      }
    )
  }

  getDocumentosRequeridosOferente(){
    this.licitacionService.getSelectDocumentoRequeridoOferenteActa().subscribe(
      resp=>{
        const documentosRequeridosOferente: any[] = JSON.parse(resp);
        this.docRequeridosOferenteActa = documentosRequeridosOferente;
      }
    )
  }

  getDocRequeridosOferenteSaved(){
    this.licitacionService.getSelectDocRequeridosOferenteSaved(this.idLicitacion).subscribe(
      resp=>{
        this.docRequeridosOferenteToSave = JSON.parse(resp);
      }
    );
  }

  onAddDocRequeridosOferenteActa(){
    if(this.formDocRequeridosOferenteActa.invalid){
      this.notifyService.showWarning("Por favor, llene todos los campos obligatorios.", "Advertencia");
      return;
    }

    const validateDocRequeridoOferenteExiste = this.docRequeridosOferenteToSave.filter(doc => doc.correlativo_doc_requerido == this.formDocRequeridosOferenteActa.value.docRequeridoOferenteCorrelativo);

    if (validateDocRequeridoOferenteExiste.length > 0) {
      this.notifyService.showWarning('El documento requerido ya fue agregado', 'Advertencia');
      return;
    }

    const dataToSave = {
      correlativo_doc_requerido: this.formDocRequeridosOferenteActa.value.docRequeridoOferenteCorrelativo,
      idLicitacion: this.idLicitacion
    };

    // insert directo aqui para evitar conflictos
    this.licitacionService.postCreateDocRequeridoOferenteActa(dataToSave).subscribe(
      resp=>{
        this.licitacionService.getSelectDocRequeridosOferenteSaved(this.idLicitacion).subscribe(
          resp=>{
            this.docRequeridosOferenteToSave = JSON.parse(resp);
          }
        );
      }
    )
  }

  resetFormDocRequeridosOferenteActa(){
    this.formDocRequeridosOferenteActa = new FormGroup({
      docRequeridoOferenteCorrelativo: new FormControl(null, Validators.required)
    })
  }

  back(): void {
    this.location.back();
  }

}
