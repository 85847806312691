<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn btn-primary"
        routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76=""
            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Respuesta de consultas licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <div class="d-flex justify-content-between">
        <h1 class="page-header">Licitación #{{idLicitacion}} <small>{{nombreEtapa}}</small></h1>
        <div>
            <!-- validacion final del responsable del proceso -->
            <button
                *ngIf="(objLicitacion?.CONSULTAS_APROBADAS === 0 && objLicitacion?.CONSULTAS_APROBADAS_LCT === 1) && validateRoleParticipanteComision(37)"
                style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn tag bg-white"
                data-toggle="modal" data-target="#modalValidarRespuestasConsultas"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check"></i> Validar respuestas</button>

            <!-- Validacion de respuestas por parte del lider de la comision tecnica -->
            <button *ngIf="(objLicitacion?.CONSULTAS_APROBADAS_LCT === 0) && validateRoleParticipanteComision(39)"
                style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn tag bg-white"
                data-toggle="modal" data-target="#validateRespuestasLctModal"><i
                    class="fas fa-lg fa-fw m-r-10 fa-check"></i> Validar respuestas</button>

            <button *ngIf="(objLicitacion?.CONSULTAS_APROBADAS === 1) && validateRoleParticipanteComision(37)"
                style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn tag bg-white"
                data-toggle="modal" data-target="#modalValidarRespuestasConsultas"><i
                    class="fas fa-lg fa-fw m-r-10 fa-times"></i> Deshacer validación</button>

            <button style="margin-right: 10px; margin-bottom: 10px;" type="button" class="btn tag bg-white"
                data-toggle="modal" data-target="#modalValidarRespuestas">Ver sin respuesta</button>
            <button *ngIf="objLicitacion?.CONSULTAS_APROBADAS === 1" style="margin-right: 10px; margin-bottom: 10px;"
                type="button" class="btn tag bg-white" (click)="printPdf()"><i class="fas fa-file-pdf"></i>
                Imprimir</button>
        </div>
    </div>
    <!-- end page-header -->

    <div class="card preguntas-container">
        <!-- <div class="row">
            <div class="col-lg-4">
                <button style="font-size: 18px;" class="btn btn-primary" (click)="tabSelected = 1">Consultas, Respuestas</button>
            </div>
            <div class="col-lg-4">
                <button style="font-size: 18px;" class="btn btn-primary" (click)="tabSelected = 2">Aclaraciones</button>
            </div>
            <div class="col-lg-4"></div>
        </div> -->
        <div class="row mb-2">
            <div class="col-5 text-left">
                <button [ngClass]="{'text-primary': tabSelected == 1}" class="btn tag mb-1 text-bolder text-muted"
                    style="margin-right: 5px;" (click)="tabSelected = 1">Consultas, Respuestas</button>
                <button *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)"
                    [ngClass]="{'text-primary': tabSelected == 2}" class="btn tag mb-1 text-bolder text-muted"
                    style="margin-right: 5px;" (click)="tabSelected = 2">Aclaraciones</button>
            </div>
            <div class="col-4 text-left"></div>
            <div class="col-3 text-right"></div>
        </div>
        <br><br>
        <div *ngIf="tabSelected === 1">
            <h2>Consultas, Respuestas:</h2>
            <br>
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="pr-0 pr-sm-0 pr-md-4 border-right no-border-sm">
                        <!-- <div class="row">
                            <div class="col">
                                <h3>Consultas, Respuestas:</h3>
                            </div>
                        </div> -->
                        <form [formGroup]="formRespuestas">
                            <div formArrayName="respuestas">
                                <div class="p-20 m-b-20 card"
                                    *ngFor="let pregunta of consultas; let i = index ; let lastRow = last">
                                    <div class="pregunta mb-20">
                                        <div class="row">
                                            <div class="col-6">
                                                <p class="font-weight-bolder">
                                                    Consulta # {{pregunta[0].numRow}}
                                                </p>
                                            </div>
                                            <div class="col-6 text-right">
                                                <span class=" text-muted">
                                                    {{ pregunta[0].FECHA_CREACION }}
                                                </span>
                                            </div>
                                        </div>
                                        <!-- <div class="row">
                                            <div class="text-break col-6 text-muted">
                                                <p class="mb-0">
                                                    {{ pregunta[0].PART_NOMBRE }}
                                                </p>
                                                <p>
                                                    {{ pregunta[0].RUT_PARTICIPANTE }}
                                                </p>
                                            </div>
                                            <div class="col-6 text-right">
                                                <span class=" text-muted">
                                                    {{ pregunta[0].FECHA_CREACION }}
                                                </span>
                                            </div>
                                        </div> -->
                                        <div class="row">
                                            <div class="col">
                                                <h5 class="mb-1 text-regular">CONSULTA {{pregunta[0].DESCRIPCION}}</h5>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col text-break">
                                                <p class="text-muted">
                                                    {{pregunta[0].CONSULTA}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="pregunta[0]?.RESPUESTA != null">
                                        <ng-container *ngFor="let item of pregunta; let j = index ; let lastRow = last">
                                            <div class="respuesta ml-4 mb-4"
                                                [ngClass]="{'principal': item.RESPUESTA_PRINCIPAL == 'S'}">
                                                <div class="row">
                                                    <div class="text-break col-12">
                                                        <div
                                                            class="d-flex-inline align-center margin-rem mt-2 mb-2 ml-0">
                                                            <div class="round-name">
                                                                {{item?.RESPUESTA_NOMBRE?.slice(0, 2)}}
                                                            </div>
                                                            <div class="nombre">
                                                                <p class="mb-0">{{ item.RESPUESTA_NOMBRE }} {{
                                                                    item.RESPUESTA_APELLIDOP }} {{
                                                                    item.RESPUESTA_APELLIDOM }}</p>
                                                                <span class="text-muted">{{item.RESPUESTA_FECHA}}</span>
                                                            </div>
                                                        </div>
                                                        <!-- <p class="mb-0">
                                                            {{ item.RESPUESTA_NOMBRE }} {{ item.RESPUESTA_APELLIDOP }} {{ item.RESPUESTA_APELLIDOM }}
                                                        </p>
                                                        <p class="text-muted">
                                                            {{item.RESPUESTA_FECHA}}
                                                        </p> -->
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-12" *ngIf="item.RESPUESTA_PRINCIPAL == 'S'">
                                                        <strong>RESPUESTA</strong>
                                                    </div>
                                                    <div class="col-12">
                                                        <p class="" *ngIf="item.RESPUESTA != null">
                                                            {{item.RESPUESTA}}
                                                        </p>
                                                    </div>
                                                    <div class="col-12 text-right"
                                                        *ngIf="(validateUsuarioEncargado() && item.RESPUESTA_PRINCIPAL == 'N')">
                                                        <button class="btn tag bg-white"
                                                            (click)="setRespuestaPrincipal(item.RESPUESTA_CORRELATIVO)">Designar
                                                            respuesta</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <!--  <div class=" pointer-cursor d-flex align-items-center" (click)="item.isCollapsed=! item.isCollapsed" [attr.aria-expanded]="!item.isCollapsed">
                                        <div class="col-12"></div>
                                    </div> -->
                                    <!-- Area de respuestas para los lideres de la licitacion -->
                                    <ng-container *ngIf="validateUsuarioEncargado()">
                                        <div class="row">
                                            <div class="col">
                                                <div [id]="pregunta[0].CORRELATIVO " formGroupName="{{i}}">
                                                    <input type="hidden" formControlName="correlativoConsulta">
                                                    <div class="card-body ">
                                                        <textarea #resp class="form-control " rows="2"
                                                            (change)="onChangeRespuesta(pregunta[0].CORRELATIVO, resp.value, i)"
                                                            formControlName="respuesta"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- Area de respuestas para los participantes de la comision evaluadora -->
                                    <ng-container *ngIf="!validateUsuarioEncargado() && objLicitacion?.CONSULTAS_APROBADAS_LCT == 0">
                                        <div class="row">
                                            <div class="col">
                                                <div [id]="pregunta[0].CORRELATIVO " formGroupName="{{i}}">
                                                    <input type="hidden" formControlName="correlativoConsulta">
                                                    <div class="card-body ">
                                                        <textarea #resp class="form-control " rows="2"
                                                            (change)="onChangeRespuesta(pregunta[0].CORRELATIVO, resp.value, i)"
                                                            formControlName="respuesta"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="row">
                                        <div class="col text-right">
                                            <div *ngIf="consultas?.length > 0" class="col col-md-12 text-right"
                                                style="margin-top: 10px;">
                                                <!-- usar una funcion para validar si es encargado comision y en esa validacion colocar si estan validads las respuestas por el LCT objLicitacion?.CONSULTAS_APROBADAS_LCT == 0 -->
                                                <!-- despues de validar las respuestas por LCT solamete el lider de la comision tecnica y el responsable del proceso pueden ingresar la respuesta -->
                                                <ng-container *ngIf="validateUsuarioEncargado()">
                                                    <button class="btn btn-primary pl-3 pr-3" type="button"
                                                        (click)="guardarRespuesta(i, pregunta[0].CORRELATIVO)">Responder</button>
                                                </ng-container>
                                                <ng-container *ngIf="!validateUsuarioEncargado() && objLicitacion?.CONSULTAS_APROBADAS_LCT == 0">
                                                    <button class="btn btn-primary pl-3 pr-3" type="button"
                                                        (click)="guardarRespuesta(i, pregunta[0].CORRELATIVO)">Responder</button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <button (click)="printArray()">ver arreay</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="ml-2" style="position: sticky;top: 6rem;">
                        <div class="row">
                            <div class="col">
                                <h3>Subir consolidado</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-md-12">
                                <div class="row card p-20">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-form-label col-12">Descripción Archivo <span
                                                    class="text-danger">*</span></label>
                                            <div class="col-12">
                                                <input class="form-control" type="text" placeholder=""
                                                    id="nombreArchivo" [(ngModel)]="nombreArchivo">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-12 mb-4">
                                        <input #inputFile class="" name="archivo" type="file"
                                            (change)="detectFiles($event)" #archivo />
                                    </div>
                                    <div class="col col-md-12 text-right">
                                        <button type="button" class="btn tag"
                                            (click)="onGuardarArchivo()">Subir</button>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div *ngIf="archivos?.length > 0" class="row mt-4">
                            <div class="col-12 archivos-list">
                                <app-view-etapa-files-table [archivos]="archivos"
                                    [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tabSelected === 2">
            <h2>Aclaraciones:</h2>
            <br>
            <app-aclaraciones-licitacion [idLicitacion]="idLicitacion" [idEtapa]="idEtapa" [idEtapaLic]="idEtapaLic"
                [aclaracionesLicitacion]="aclaracionesLicitacion"
                (getAclaracionesLicitacion)="getAclaracionesLicitacion()">
            </app-aclaraciones-licitacion>
        </div>
    </div>
    <chat [idLicitacion]="idLicitacion"></chat>
</div>
<!-- end #content -->
<!-- Modal -->
<div class="modal fade" id="modalValidarRespuestas" tabindex="-1" role="dialog" aria-labelledby="modalValidarRespuestas"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalValidarRespuestas">Consultas sin respuestas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="consultas?.length > 0">
                    <div *ngFor="let pregunta of consultas">
                        <div *ngIf="pregunta[0].RESPUESTA === null">
                            {{pregunta[0].numRow}} - {{pregunta[0].CONSULTA}}
                        </div>
                    </div>
                    <p class="text-center" *ngIf="validateConsultasCompletas() === true">No hay consultas sin respuestas
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal validar respuetsas a consultas -->
<div class="modal fade" id="modalValidarRespuestasConsultas" tabindex="-1" role="dialog"
    aria-labelledby="modalValidarRespuestasConsultas" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalValidarRespuestasConsultas">Validar respuestas a consultas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeUpdateValidacionRespuestas>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="objLicitacion?.CONSULTAS_APROBADAS === 0">
                    <p style="font-size: 16px;">Esta acción validara las respuestas a las consultas y permitirá avanzar
                        la licitación.</p>
                    <p style="font-size: 16px;">¿Desea validar las respuestas a las consultas?</p>
                </ng-container>
                <ng-container *ngIf="objLicitacion?.CONSULTAS_APROBADAS === 1">
                    <p style="font-size: 16px;">Esta acción deshará la validación a las respuestas de las consultas.</p>
                    <p style="font-size: 16px;">¿Desea validar las respuestas a las consultas?</p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="onValidarRespuestasConsultas()">
                    <ng-container *ngIf="objLicitacion?.CONSULTAS_APROBADAS === 0">Validar</ng-container>
                    <ng-container *ngIf="objLicitacion?.CONSULTAS_APROBADAS === 1">Deshacer</ng-container>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Modal validacion lct respuestas a consultas-->
<div class="modal fade" id="validateRespuestasLctModal" tabindex="-1" role="dialog"
    aria-labelledby="validateRespuestasLctModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="validateRespuestasLctModalLabel">Validar respuestas a consultas</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p style="font-size: 16px;">Esta acción validara las respuestas a las consultas y se le dará aviso al
                    Responsable del proceso para la revisión final de las respuestas.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="validateRespuestasLct()">Validar</button>
            </div>
        </div>
    </div>
</div>