import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import * as moment from "src/assets/plugins/moment/moment";
import { PDF } from "src/app/shared/functions/pdf";
import {
  formatCurrency,
  getDayNameByDate,
} from "src/app/shared/functions/common";
import "src/assets/plugins/moment/min/locales.min.js";
moment.locale("es");
import { jsPDF } from "jspdf";
import { IParticipante } from "src/app/shared/services/licitacion.types";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FileService } from "src/app/shared/services/file.service";

@Component({
  selector: "app-view-cronograma",
  templateUrl: "./view-cronograma.component.html",
  styleUrls: ["./view-cronograma.component.css"],
})
export class ViewCronogramaComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  nombreEtapa: string;
  correlativoEtapa: number;
  cronograma: any[];
  porcentaje: number = 0;
  fecha_desde: string = "";
  fecha_hasta: string = "";
  licitacionData;
  participantes;
  tab: number;
  archivosLicitacion: any[];
  archivosLicitacionFilter: any[];
  etapasArchivosFiltros: string[];
  comision: any[] = [];
  consultas: any[] = [];
  aclaracionesLicitacion: any[];
  objLicitacion: any;
  idCargoActual;
  renunciaLicitacionParticipante: any[];
  cronogramaVersionSeleccionado: any[];
  cronogramaVersionOriginal: any[];
  rolesParticipanteComision: any[];
  partComisionFirmante: any[];

  excelHeaders: any[] = [
    {
      text: "Actividad",
      value: "ETAPA_DESCRIPCION",
    },
    {
      text: "Fecha Inicio",
      value: "FECHA_INICIO_ETAPA",
      type: "date",
    },
    {
      text: "Fecha Fin",
      value: "FECHA_TERMINO_ETAPA",
      type: "date",
    },
  ];
  downloadingPdf: boolean = false;
  pdfClassElement = "d-none";
  getDayNameByDate = getDayNameByDate;
  oferentesDescalificadosData: any[];
  @Output() data: EventEmitter<IParticipante[]> = new EventEmitter<
    IParticipante[]
  >();
  urlEtapaActual: string;
  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("closeModalOfentesDescalificados")
  closeModalOfentesDescalificados: ElementRef;
  formHistorial: FormGroup;
  versionesHistorial: any[];
  isFilterHistorial: boolean;
  ordenEtapaActual: number;
  observacionAvanceEtapa: string;
  idRol: number;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private etapaService: EtapasService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder,
    private fileService: FileService
  ) {
    this.tab = 1;
    this.oferentesDescalificadosData = [];
    this.urlEtapaActual = "";
    this.versionesHistorial = [];
    this.isFilterHistorial = false;
    this.cronogramaVersionSeleccionado = [];
    this.cronogramaVersionOriginal = [];
    this.rolesParticipanteComision = [];
    this.partComisionFirmante = [];
    this.ordenEtapaActual = 0;
    this.observacionAvanceEtapa = "";
    this.idRol = 0;
    this.archivosLicitacion = [];
    this.archivosLicitacionFilter = [];
    this.etapasArchivosFiltros = [];
    this.isLoading = false;

    this.formHistorial = this.formBuilder.group({
      versionHistorial: [null, Validators.required],
      actualHistorial: [null],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.idRol = JSON.parse(localStorage.getItem("userData")).rolId;
      this.renunciaLicitacionParticipante = [];
      this.licitacionService
        .getParticipantesLic(this.idLicitacion, this.idEtapaLic)
        .subscribe((resp) => {
          this.participantes = JSON.parse(resp);
        });

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const dataLidicacion: any[] = JSON.parse(resp);
          this.licitacionData = dataLidicacion[0];
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );

      this.licitacionService.getPorcentajeAvance(this.idLicitacion).subscribe(
        (resp) => {
          if (JSON.parse(resp)[0].PORCENTAJE_AVANCE) {
            this.porcentaje = JSON.parse(resp)[0].PORCENTAJE_AVANCE;
            this.fecha_desde = JSON.parse(resp)[0].FECHA_DESDE;
            this.fecha_hasta = JSON.parse(resp)[0].FECHA_HASTA;

            let fechaDesde = moment(this.fecha_desde);
            let fechaHasta = moment(this.fecha_hasta);

            // a = diferencia en dias entre fecha de inicio y fecha de fin
            // b = 100% de dias
            // c = diferencia entre la fecha de inicio y la fecha actual
            // formula x = a * b / c

            let a = fechaHasta.diff(fechaDesde, "days");
            let b = 100; //(fechaHasta.diff(fechaDesde, 'days') * 100);
            const hoy = moment(new Date());
            let c = hoy.diff(fechaDesde, "days");

            let porcentajeActual = (c * b) / a;

            this.porcentaje = Math.round(porcentajeActual);

            if (porcentajeActual > 100) {
              this.porcentaje = 100;
            }
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener porcentaje de avance"
          );
        }
      );

      this.licitacionService
        .getParticipantesComLicitacion(this.idLicitacion)
        .subscribe(
          (resp) => {
            if (JSON.parse(resp)) {
              this.partComisionFirmante = JSON.parse(resp).filter(
                (x) => x.TIPO_PARTICIPANTE === 37 || x.TIPO_PARTICIPANTE === 39
              );
              this.comision = JSON.parse(resp);
              this.comision = this.groupByKey(this.comision, "COMISION");
            }
          },
          (err) => {
            this.notifyService.showError(err.error.message, "Obtener comision");
          }
        );

      this.licitacionService.getCronogramaLicView(this.idLicitacion).subscribe(
        (resp) => {
          this.cronograma = JSON.parse(resp).sort((a, b) => a.ORDEN - b.ORDEN);

          const etapaActual = JSON.parse(resp).filter(
            (x) => x.ETAPA_ACTUAL === "S"
          );
          this.urlEtapaActual = `/${etapaActual[0]?.PAGINA}/${etapaActual[0]?.LIC_CORRELATIVO}/${etapaActual[0]?.ETAPA_CORRELATIVO}/${etapaActual[0]?.ETLIC_CORRELATIVO}`;

          this.ordenEtapaActual = this.cronograma.filter(
            (x) => x.ETAPA_CORRELATIVO == this.idEtapa
          )[0].ORDEN;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cronograma licitación"
          );
        }
      );
      if (this.idEtapa) {
        this.etapaService.getEtapaId(this.idEtapa).subscribe(
          (resp) => {
            if (JSON.parse(resp)[0]) {
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
              this.correlativoEtapa = JSON.parse(resp)[0].correlativo;
            }
          },
          (err) => {
            this.notifyService.showError(err.error.message, "Obtener etapa");
          }
        );
      }
      this.getArchivoLicitacion(this.idLicitacion);
      this.getConsultas(this.idLicitacion);
      this.getHeaderLicitacion();
      this.getAclaracionesLicitacion();
      this.getOferentesDescalificados();
      this.getEtapaActualLicitacion();
      this.getVersionesHistorial();
      this.getSelectRolesParticipanteComision();
    });
    this.idCargoActual = JSON.parse(localStorage.getItem("userData")).rolId;
  }

  onGetVersionHistorial() {
    this.cronogramaVersionSeleccionado = [];

    this.licitacionService
      .getVerisonCronograma(
        this.idLicitacion,
        this.formHistorial.value.versionHistorial
      )
      .subscribe(
        (resp) => {
          const versionCronograma = JSON.parse(resp);
          let cronogramaComparar: any[] = [];

          for (let index = 0; index < versionCronograma.length; index++) {
            const element: any = versionCronograma[index];
            const cronograma: any[] = this.cronogramaVersionOriginal.filter(
              (x) => x.etapa_correlativo === element.etapa_correlativo
            );

            if (cronograma.length > 0) {
              if (
                element.fecha_inicio_etapa !==
                  cronograma[0].fecha_inicio_etapa ||
                element.fecha_termino_etapa !==
                  cronograma[0].fecha_termino_etapa ||
                element.obligatorio !== cronograma[0].obligatorio
              ) {
                cronogramaComparar.push({
                  ...element,
                  existe: true,
                  diferente: true,
                });
              } else {
                cronogramaComparar.push({
                  ...element,
                  existe: true,
                  diferente: false,
                });
              }
            } else {
              cronogramaComparar.push({
                ...element,
                existe: false,
                diferente: false,
              });
            }
          }

          cronogramaComparar = cronogramaComparar.sort(
            (a, b) => a.orden - b.orden
          );
          this.cronogramaVersionSeleccionado = cronogramaComparar;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener versiones historial"
          );
          this.cronogramaVersionSeleccionado = [];
        }
      );
  }

  getVersionesHistorial() {
    this.licitacionService.getVerisonesCronograma(this.idLicitacion).subscribe(
      (resp) => {
        this.versionesHistorial = JSON.parse(resp);

        if (this.versionesHistorial.length > 0) {
          this.formHistorial = this.formBuilder.group({
            versionHistorial: [-1, Validators.required],
            actualHistorial: [null],
          });
        }

        // cargar la version original de cronograma base (acta de inicio)
        this.licitacionService
          .getVerisonCronograma(this.idLicitacion, 0)
          .subscribe(
            (resp) => {
              this.cronogramaVersionOriginal = JSON.parse(resp).sort(
                (a, b) => a.orden - b.orden
              );
            },
            (err) => {
              this.notifyService.showError(
                err.error.message,
                "Obtener version original del cronograma"
              );
              this.cronogramaVersionOriginal = [];
            }
          );
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener versiones historial"
        );
      }
    );
  }

  resetFormHistorial() {
    this.formHistorial = this.formBuilder.group({
      versionHistorial: [null, Validators.required],
      actualHistorial: [null],
    });
  }

  groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
  onAvanzaEtapa(idLicitacion: number) {
    if (this.objLicitacion.ORDEN == 12) {
      if (this.objLicitacion.VALIDACION_JAC !== 1) {
        this.isLoading = false;

        this.notifyService.showWarning(
          "Para avanzar de etapa, primero debe ser validada por el JAC",
          "Validar licitación"
        );
        return;
      }
    }

    if (this.objLicitacion.ORDEN == 13) {
      if (this.objLicitacion.VALIDACION_RIESGO_CONTROL !== 1) {
        this.isLoading = false;

        this.notifyService.showWarning(
          "Para avanzar de etapa, primero debe ser validada por el Riesgo Control",
          "Validar licitación"
        );
        return;
      }
    }

    if (this.observacionAvanceEtapa.trim() === "") {
      this.isLoading = false;

      this.notifyService.showWarning(
        "Para avanzar de etapa, primero debe ingresar al menos una observación",
        "Avanzar etapa"
      );
      return;
    }

    if (this.oferentesDescalificadosData.length > 0) {
      for (
        let index = 0;
        index < this.oferentesDescalificadosData.length;
        index++
      ) {
        const element = this.oferentesDescalificadosData[index];

        if (element.participoEnEtapaActual === false) {
          this.onUpdateParticipante(element.part_correlativo, false);
        }
      }

      this.licitacionService
        .getParticipantesLic(this.idLicitacion, this.idEtapaLic)
        .subscribe((resp) => {
          this.participantes = JSON.parse(resp);

          if (this.observacionAvanceEtapa.trim() === "") {
            this.isLoading = false;

            this.notifyService.showWarning(
              "Para avanzar de etapa, primero debe ingresar al menos una observación",
              "Avanzar etapa"
            );
            return;
          } else {
            const postData = {
              in_lic_correlativo: idLicitacion,
              in_etapa_correlativo: this.idEtapa,
              in_observaciones: this.observacionAvanceEtapa,
            };

            this.etapaService.postAvanzaEtapaLic(postData).subscribe(
              (resp) => {
                this.getHeaderLicitacion();
                this.getEtapaActualLicitacion();
                this.getOferentesDescalificados();
                this.getLicitaciones();
                this.isLoading = false;
                this.data.emit(this.participantes);
                this.closeModalOfentesDescalificados.nativeElement.click();

                this.notifyService.showSuccess(
                  "La licitacion avanzó de etapa correctamente",
                  "Avanzar etapa"
                );
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Avanzar etapa"
                );
              }
            );
          }
        });
    } else {
      if (this.observacionAvanceEtapa.trim() === "") {
        this.notifyService.showWarning(
          "Para avanzar de etapa, primero debe ingresar al menos una observación",
          "Avanzar etapa"
        );
        return;
      } else {
        const postData = {
          in_lic_correlativo: idLicitacion,
          in_etapa_correlativo: this.idEtapa,
          in_observaciones: this.observacionAvanceEtapa,
        };
        this.etapaService.postAvanzaEtapaLic(postData).subscribe(
          (resp) => {
            this.getHeaderLicitacion();
            this.getEtapaActualLicitacion();
            this.getOferentesDescalificados();
            this.getLicitaciones();
            this.isLoading = false;
            this.data.emit(this.participantes);
            this.closeModal.nativeElement.click();

            this.notifyService.showSuccess(
              "La licitacion avanzó de etapa correctamente",
              "Avanzar etapa"
            );
          },
          (err) => {
            this.notifyService.showError(err.error.message, "Avanzar etapa");
          }
        );
      }
    }
  }

  private getLicitaciones() {
    this.licitacionService.getPorcentajeAvance(this.idLicitacion).subscribe(
      (resp) => {
        if (JSON.parse(resp)[0].PORCENTAJE_AVANCE) {
          this.porcentaje = JSON.parse(resp)[0].PORCENTAJE_AVANCE;
          this.fecha_desde = JSON.parse(resp)[0].FECHA_DESDE;
          this.fecha_hasta = JSON.parse(resp)[0].FECHA_HASTA;

          let fechaDesde = moment(this.fecha_desde);
          let fechaHasta = moment(this.fecha_hasta);

          // a = diferencia en dias entre fecha de inicio y fecha de fin
          // b = 100% de dias
          // c = diferencia entre la fecha de inicio y la fecha actual
          // formula x = a * b / c

          let a = fechaHasta.diff(fechaDesde, "days");
          let b = 100; //(fechaHasta.diff(fechaDesde, 'days') * 100);
          const hoy = moment(new Date());
          let c = hoy.diff(fechaDesde, "days");

          let porcentajeActual = (c * b) / a;

          this.porcentaje = Math.round(porcentajeActual);

          if (porcentajeActual > 100) {
            this.porcentaje = 100;
          }
        }
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener porcentaje de avance"
        );
      }
    );
    this.licitacionService.getCronogramaLicView(this.idLicitacion).subscribe(
      (resp) => {
        this.cronograma = JSON.parse(resp).sort((a, b) => a.ORDEN - b.ORDEN);

        const etapaActual = JSON.parse(resp).filter(
          (x) => x.ETAPA_ACTUAL === "S"
        );

        this.urlEtapaActual = `/${etapaActual[0]?.PAGINA}/${etapaActual[0]?.LIC_CORRELATIVO}/${etapaActual[0]?.ETAPA_CORRELATIVO}/${etapaActual[0]?.ETLIC_CORRELATIVO}`;

        this.ordenEtapaActual = this.cronograma.filter(
          (x) => x.ETAPA_CORRELATIVO == this.idEtapa
        )[0].ORDEN;
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cronograma licitación"
        );
      }
    );
    if (this.idEtapa) {
      this.etapaService.getEtapaId(this.idEtapa).subscribe(
        (resp) => {
          if (JSON.parse(resp)[0]) {
            this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
          }
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Obtener etapa");
        }
      );
    }
  }

  private getArchivoLicitacion(idLicitacion: number) {
    if (idLicitacion) {
      this.licitacionService.getArchivosLicitacion(this.idLicitacion).subscribe(
        (resp) => {
          const archivosLicitacion: any[] = JSON.parse(resp);
          this.etapasArchivosFiltros = [];

          if (archivosLicitacion.length > 0) {
            this.archivosLicitacion = archivosLicitacion;
            this.archivosLicitacionFilter = archivosLicitacion;

            for (let index = 0; index < archivosLicitacion.length; index++) {
              const element = archivosLicitacion[index];
              this.etapasArchivosFiltros.push(element?.ETAPA_DESCRIPCION);
            }

            this.etapasArchivosFiltros = Array.from(new Set(this.etapasArchivosFiltros));
          } else {
            this.archivosLicitacion = [];
            this.archivosLicitacionFilter = [];
            this.etapasArchivosFiltros = [];
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener archivos licitación"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Falta el id de la licitación",
        "Obtener archivos licitación"
      );
    }
  }

  getConsultasFull() {
    this.licitacionService
      .getConsultasLicFull(this.idLicitacion)
      .subscribe((resp) => {
        this.consultas = JSON.parse(resp);
        this.validarPregunta();
      });
  }

  validarPregunta() {
    for (let index = 0; index < this.consultas.length; index++) {
      const element = this.consultas[index];
      if (element.RESPUESTA === null || element.RESPUESTA === "") {
        this.notifyService.showWarning(
          "Hay preguntas pendendientes por contestar.",
          "Consultas, Respuestas"
        );
        this.isLoading = false;
        return false;
      }
    }

    if (this.objLicitacion.CONSULTAS_APROBADAS === 0) {
      this.isLoading = false;
      this.notifyService.showWarning(
        "Las respuestas no han sido validadas por el encargado del proceso.",
        "Consultas, Respuestas"
      );
    } else {
      this.onAvanzaEtapa(this.idLicitacion);
    }
  }

  updateComision(arr: []) {
    this.comision = this.groupByKey(arr, "COMISION");
  }
  setParticipantes(arr: []) {
    this.participantes = arr;
    this.getOferentesDescalificados();
  }

  onValidarPreguntas() {
    this.isLoading = true;

    if (this.idEtapa == 5) {
      this.getConsultasFull();
    } else {
      this.onAvanzaEtapa(this.idLicitacion);
    }
  }

  async onDescargarPDF() {
    this.downloadingPdf = true;
    this.pdfClassElement = "d-block";
    const doc = new PDF({ format: "A2", unit: "mm" }, [
      "",
      "Cronograma",
      "Archivos",
      "Matriz de Categorización",
      "Aprobaciones firmadas",
    ]);

    doc.addLogo();
    doc.addTitle(`Licitacion ${this.licitacionData.CONT_NOMBRE}`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 100;

    doc.identText(
      "Descripción",
      this.licitacionData.DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Licitación",
      this.idLicitacion.toString(),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Solpe",
      this.licitacionData?.SOLPE_CORRELATIVO,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Estado",
      this.licitacionData?.ETAPA_DESC,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha",
      this.licitacionData?.FECHA_CREACION
        ? moment(this.licitacionData?.FECHA_CREACION, "DD-MM-YYYY").format(
            "DD/MM/YYYY"
          )
        : "",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Moneda",
      this.licitacionData?.DESCRIPCION_MONEDA_SOLPE || "Peso Chileno",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Monto",
      formatCurrency(this.licitacionData?.MONTO_SOLPE || ""),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.increasePageY(10);

    doc.addTitle("Participantes");

    doc.addTableHtml("#table_participantes");

    doc.increasePageY(10);

    doc.addTitle("Comisiones");

    doc.addTableHtml("#table_comisiones");

    doc.increasePageY(10);

    // //SEGUNDA PAGINA

    doc.iSection = 2;
    doc.addNewPage();

    doc.addTableHtml("#table_cronograma");

    // //TERCERA PAGINA

    doc.iSection = 3;
    doc.addNewPage();

    doc.addTableHtml("#table_archivos");

    // //CUARTA PAGINA

    // doc.iSection = 4;
    // doc.addNewPage();

    // doc.addTableHtml('#table_correos');

    // //QUINTA PAGINA

    doc.iSection = 4;
    doc.addNewPage();

    const archivosMatrizRiesgo: any[] = this.archivosLicitacion.filter(
      (x) => x.grupo_archivo === 7
    );
    const archivosMatrizRiesgoOrdenados: any[] = archivosMatrizRiesgo.sort(
      (a, b) => a.arch_correlativo - b.arch_correlativo
    );

    if (archivosMatrizRiesgo.length > 0) {
      const urlMatriz: string = this.fileService.getUrlFile(
        archivosMatrizRiesgoOrdenados[archivosMatrizRiesgo.length - 1]
          .NOMBRE_ARCHIVO
      );
      doc.addImage(urlMatriz, 27, 50, 280, 240);
    }

    // //SEXTA PAGINA

    doc.iSection = 5;
    doc.addNewPage();

    doc.increasePageY(10);

    doc.text(
      "Los abajo firmantes aprueban el acta de inicio para esta licitación:",
      doc.iMargin,
      doc.nPageYAxi
    );
    doc.increasePageY(20);

    for (let index = 0; index < this.partComisionFirmante.length; index++) {
      const element = this.partComisionFirmante[index];
      doc.addSign(`${element.COMISION} (${element.PART_NOMBRE})`);
      doc.increasePageY(20);
    }

    doc.addFooter();
    doc.save(`licitacion_${this.idLicitacion}.pdf`);
    this.downloadingPdf = false;
    this.pdfClassElement = "d-none";
  }

  getConsultas(idLic: number) {
    this.licitacionService.getConsultasLicFull(idLic).subscribe((resp) => {
      this.consultas = JSON.parse(resp);
    });
  }

  getAclaracionesLicitacion() {
    this.licitacionService
      .getAclaracionesLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.aclaracionesLicitacion = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener tipos de aclaracion."
          );
        }
      );
  }

  getHeaderLicitacion() {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        this.objLicitacion = JSON.parse(resp)[0];
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cabecera licitación"
        );
      }
    );
  }

  printPdf() {
    const oDocPdf = new jsPDF({ format: "letter", unit: "mm" });
    let nPageYAxi = 10;
    const iMargin = 20;
    const iFontText = 10;
    const iMarginHeader = 35;
    const iMaxPageWidth = 215.9 - iMargin * 2;
    const iLineSeparation = 5;

    const addLogo = () => {
      const logo = new Image();
      logo.src = "/assets/img/logo/logo_em_pdf.png";
      oDocPdf.addImage(logo, "PNG", iMargin, 10, 68, 6.8);
    };

    const addHeader = () => {
      oDocPdf.setFontSize(iFontText);
      oDocPdf.setTextColor(0, 0, 0); // black text
      oDocPdf.setFont("Helvetica", "normal", 700);
      nPageYAxi = 35;

      const consultas_respuestas = new Image();
      consultas_respuestas.src = "/assets/img/misc/consultas_respuestas.png";
      oDocPdf.addImage(
        consultas_respuestas,
        "PNG",
        iMargin,
        nPageYAxi,
        iMaxPageWidth,
        6.8
      );

      nPageYAxi += 20;

      centeredText(`LICITACIÓN ${this.objLicitacion?.CONT_NUMERO}`, nPageYAxi);
      slitText(
        `“${this.objLicitacion?.CONT_NOMBRE}”`,
        iMaxPageWidth,
        6,
        iMargin,
        "center"
      );
      nPageYAxi += 10;
    };

    //slitText se emplea para dividir el texto de un parrafo en multiples lineas, en funcion del ancho enviado en el parametro iMaxWidth
    //el cual es el ancho maximo permitido para el texto.
    //iIncreaseY = es alto que se aumentara por cada linea
    //de ser necesario, el valor del alto de la ultima linea se guarda en la variable nPageYAxi
    //iXAxis = posicion en eje X para inicio del texto.
    const slitText = function (sText, iMaxWidth, iIncreaseY, iXAxis, align) {
      let iIncreaseY2 = 0;
      if (Array.isArray(iIncreaseY)) {
        iIncreaseY2 = iIncreaseY[1];
        iIncreaseY = iIncreaseY[0];
      } else {
        iIncreaseY2 = iIncreaseY;
      }
      const aTextLines = oDocPdf.splitTextToSize(sText, iMaxWidth);
      aTextLines.forEach((sLine, i) => {
        if (i != 0) {
          increasePageY(iIncreaseY);
        } else {
          increasePageY(iIncreaseY2);
        }
        if (!align) {
          oDocPdf.text(iXAxis, nPageYAxi, sLine);
        } else if (align === "center") {
          centeredText(sLine, nPageYAxi); //nPageYAxi posee el valor del ultimo alto ingresado
        } else if (align === "justify") {
          oDocPdf.text(sLine, iXAxis, nPageYAxi, {
            maxWidth: iMaxPageWidth - iMargin,
            align: "justify",
          });
        }
      });
    };

    //funcion se emplea para centrar en texto en funcion del ancho del la hora configurada
    const centeredText = function (sText, y) {
      const textWidth =
        (oDocPdf.getStringUnitWidth(sText) * iFontText) /
        oDocPdf.internal.scaleFactor;
      const textOffset = ((oDocPdf.internal.pageSize.width - textWidth) /
        2) as any;
      oDocPdf.text(textOffset, y, sText);
    };

    //Se emplea para aumentar el contador
    const increasePageY = function (iIncreaseValue) {
      nPageYAxi += iIncreaseValue;
      if (nPageYAxi > oDocPdf.internal.pageSize.height - iMargin) {
        addPage();
        nPageYAxi = iMarginHeader;
      }
    };

    const addPage = () => {
      oDocPdf.addPage("letter", "portrait");
      //al iniciar nueva pagina y agregar el header establesco el alto inicial
      nPageYAxi = iMarginHeader;
      addLogo();
      addHeader();
    };

    const addSubtitle = (sTitle, sValue) => {
      oDocPdf.setTextColor(0, 0, 0); // black text
      identText(sTitle, sValue, 31, 80, 0);
      nPageYAxi += 8;
    };

    const identText = (
      sTitle,
      sValue,
      iMaxWidth,
      iMaxWidthValue = null,
      iAddMargin = 0,
      isQuestion = false,
      align = ""
    ) => {
      if (!iMaxWidthValue) {
        if (iMaxWidth > 50) {
          iMaxWidthValue = 100;
        } else {
          iMaxWidthValue = iMaxWidth;
        }
      } else {
        const iMaxLength = 50;
        sTitle = getMaxText(sTitle, iMaxLength);
      }
      if (isQuestion) oDocPdf.setFont("Helvetica", "normal", 700);
      slitText(sTitle, iMaxWidth, 8, iMargin + iAddMargin, align);
      if (isQuestion) oDocPdf.setFont("Helvetica", "normal", 400);
      slitText(sValue, iMaxWidthValue, [8, 0], iMaxWidth, align);
    };

    const getMaxText = (sText, iMaxLength) => {
      if (sText.length > iMaxLength) {
        sText = sText.substr(0, iMaxLength) + "...";
      }
      return sText;
    };

    // PAGE INIT

    addLogo();
    addHeader();

    addSubtitle("I.", "ACLARACIONES");
    let iMarginText = iMargin;
    const iSeparationText = 11;
    iMarginText += iSeparationText;
    this.aclaracionesLicitacion.map((aclaracion) => {
      let iMarginLeft = iMarginText;
      oDocPdf.setTextColor(0, 0, 0); // black text
      slitText(
        `${aclaracion.descripcion.toUpperCase()}:`,
        iMaxPageWidth,
        iLineSeparation,
        iMarginLeft,
        false
      );
      iMarginLeft += 10;
      oDocPdf.setTextColor(0, 31, 95); //blue job
      slitText(
        `${aclaracion.texto_aclaracion}`,
        iMaxPageWidth - iMargin,
        iLineSeparation,
        iMarginLeft,
        "justify"
      );

      nPageYAxi += 8;
    });

    oDocPdf.setTextColor(0, 0, 0); // black text
    slitText("ANEXOS:", iMaxPageWidth, iLineSeparation, iMarginText, false);

    addPage();

    addSubtitle("II.", "RESPUESTAS A CONSULTAS");

    // this.consultas.map((d: any, i) => {
    //   const pregunta = d[0];
    //   oDocPdf.setTextColor(0, 0, 0); // black text

    //   identText(`${i+1}-`, pregunta.CONSULTA, 35, 80, 11, true, 'justify');
    //   oDocPdf.setTextColor(0, 31, 95); //blue job
    //   oDocPdf.setFont("Helvetica", "normal", 700);
    //   const marginText = 15;
    //   slitText(`R: ${pregunta.RESPUESTA}`, iMaxPageWidth - marginText, iLineSeparation, iMargin + marginText, 'justify');

    //   nPageYAxi += 3;
    // });

    for (let index = 0; index < this.consultas.length; index++) {
      const pregunta: any = this.consultas[index];

      oDocPdf.setTextColor(0, 0, 0); // black text

      identText(
        `${index + 1}-`,
        pregunta.CONSULTA,
        35,
        80,
        11,
        true,
        "justify"
      );
      oDocPdf.setTextColor(0, 31, 95); //blue job
      oDocPdf.setFont("Helvetica", "normal", 700);
      const marginText = 15;
      slitText(
        `R: ${pregunta.RESPUESTA}`,
        iMaxPageWidth - marginText,
        iLineSeparation,
        iMargin + marginText,
        "justify"
      );

      nPageYAxi += 3;
    }

    oDocPdf.save(
      `${this.objLicitacion?.CONT_NUMERO} Consolidado de Consultas.pdf`
    );
  }

  getOferentesDescalificados() {
    this.licitacionService
      .getOferentesDescalificados(this.idLicitacion)
      .subscribe((resp) => {
        const oferentesDescalificados: any[] = JSON.parse(resp);
        this.oferentesDescalificadosData = oferentesDescalificados;
      });
  }

  onUpdateParticipante(idParticipante: number, partCheck: boolean) {
    let activo = partCheck === true ? "S" : "N";
    const postData: {
      in_licitacion: number;
      in_etapa: number;
      in_part_correlativo: number;
      in_usuario: string;
      in_activo: string;
      in_motivo_descalificacion: string;
      in_motivo_reuncia_correlativo: number;
    } = {
      in_licitacion: this.idLicitacion,
      in_etapa: this.idEtapaLic,
      in_part_correlativo: idParticipante,
      in_usuario: "",
      in_activo: activo,
      in_motivo_descalificacion: "",
      in_motivo_reuncia_correlativo: 0,
    };
    this.licitacionService.postPostulanteLic(postData).subscribe(
      (resp) => {
        const postDataNotificacion = {
          pin_lic_correlativo: this.idLicitacion,
          in_part_correlativo: idParticipante,
        };

        this.licitacionService
          .postEnviaNotificacionDescalificacionIncumplimiento(
            postDataNotificacion
          )
          .subscribe(
            (resp) => {
              // nada
            },
            (err) => {
              this.notifyService.showError(err.error.message, "Participante");
            }
          );
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Participante");
      }
    );
  }

  getEtapaActualLicitacion() {
    this.licitacionService
      .getEtapaActualLicitacion(this.idLicitacion)
      .subscribe((resp) => {
        const etapaActualLicitacionData: any[] = JSON.parse(resp);
        this.nombreEtapa = etapaActualLicitacionData[0].descripcion;
        this.idEtapa = etapaActualLicitacionData[0].etapa_correlativo;
      });
  }

  closeModalAvanzarEtapaOfentesDescalificados() {
    this.closeModalOfentesDescalificados.nativeElement.click();
  }

  onSolicitudRenunciaLicitacion(partCorrelativo: number) {
    this.licitacionService
      .getSolicitudRenunciaLicitacion(this.idLicitacion, partCorrelativo)
      .subscribe((resp) => {
        this.renunciaLicitacionParticipante = JSON.parse(resp);
      });
  }

  confirmarRenunciaLicitacionParticipante(partCorrelativo: number) {
    const postDataConfirmarRenunciaLicitacion = {
      pin_lic_correlativo: this.idLicitacion,
      in_part_correlativo: partCorrelativo,
    };

    this.licitacionService
      .updateConfirmarRenunciaLicitacion(postDataConfirmarRenunciaLicitacion)
      .subscribe((resp) => {
        this.licitacionService
          .getSolicitudRenunciaLicitacion(this.idLicitacion, partCorrelativo)
          .subscribe((resp) => {
            this.renunciaLicitacionParticipante = JSON.parse(resp);

            this.licitacionService
              .getParticipantesLic(this.idLicitacion, this.idEtapaLic)
              .subscribe((resp) => {
                this.participantes = JSON.parse(resp);
              });
          });
      });
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  applyFilterArchivos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.archivosLicitacionFilter = this.archivosLicitacion.filter(data => {
        return (
          (
            data?.DESCRIPCION.toString().toLowerCase().includes(filterValue) || 
            data?.DESCRIPCION_ARCHIVO.toString().toLowerCase().includes(filterValue) ||
            data?.ETAPA_DESCRIPCION.toString().toLowerCase().includes(filterValue) ||
            data?.USUARIO.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.archivosLicitacionFilter = this.archivosLicitacion;
    }
  }

  selectEtapaArchivo(etapa: string, event: any){
    event.preventDefault();

    if(etapa != ''){
      this.archivosLicitacionFilter = this.archivosLicitacion.filter(data => {
        return (
          (
            data?.ETAPA_DESCRIPCION.toString().toLowerCase().includes(etapa.toLowerCase())
          )
        );
      });
    }
    else{
      this.archivosLicitacionFilter = this.archivosLicitacion;
    }
  }
}
