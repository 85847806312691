import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ParticipantesService {
  private header;

  constructor(private http: HttpClient) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }
  private urlService: string = environment.apiUrl;

  getEmpresasParticipantes() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "participantes/getEmpresasParticipantes/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let empresas = [];
          1;
          for (const item in resp["empresas"]) {
            empresas.push({ ...resp["empresas"][item] });
          }
          return JSON.stringify(empresas);
        })
      );
  }

  getParticipantesEmpresa(
    in_part_correlativo: number,
    in_domi_correlativo_tipar: number
  ) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "participantes/getParticipantesEmpresa/" +
          in_part_correlativo +
          "/" +
          in_domi_correlativo_tipar,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let participantes_empresa = [];
          1;
          for (const item in resp["participantes_empresa"]) {
            participantes_empresa.push({
              ...resp["participantes_empresa"][item],
            });
          }
          return JSON.stringify(participantes_empresa);
        })
      );
  }

  postEmpresa(postData: {
    iv_razon_social: string;
    in_empr_rut: number;
    iv_empr_digrut: string;
    iv_email: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(this.urlService + "participantes/postEmpresa", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let empresa = [];
          for (const item in resp["empresa"]) {
            empresa.push({ ...resp["empresa"][item] });
          }
          return JSON.stringify(empresa);
        })
      );
  }

  putParticipante(putData: {
    in_part_correlativo: number;
    iv_nombre: string;
    iv_apellido_paterno: string;
    iv_apellido_materno: string;
    in_rut: number;
    iv_digrut: string;
    iv_email: string;
    iv_password: string;
    in_domi_correlativo_tipar: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .put(this.urlService + "participantes/putParticipanteEmpresa", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let participante_empresa = [];
          for (const item in resp["participante_empresa"]) {
            participante_empresa.push({
              ...resp["participante_empresa"][item],
            });
          }
          return JSON.stringify(participante_empresa);
        })
      );
  }
  delParticipantesEmpresa(in_part_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .delete(
        this.urlService +
          "participantes/delParticipantesEmpresa/" +
          in_part_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let participantes_empresa = [];
          1;
          for (const item in resp["participantes_empresa"]) {
            participantes_empresa.push({
              ...resp["participantes_empresa"][item],
            });
          }
          return JSON.stringify(participantes_empresa);
        })
      );
  }
  getEmpresa(in_part_correlativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService + "participantes/getEmpresa/" + in_part_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let empresa = [];
          1;
          for (const item in resp["empresa"]) {
            empresa.push({ ...resp["empresa"][item] });
          }
          return JSON.stringify(empresa);
        })
      );
  }
  putEmpresaOParticipante(putData: {
    in_part_correlativo: number;
    iv_nombre: string;
    iv_apellido_paterno: string;
    iv_apellido_materno: string;
    iv_razon_social: string;
    in_rut: number;
    iv_digrut: string;
    iv_email: string;
    iv_password: string;
    iv_vigente: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .put(this.urlService + "participantes/putEmpresaOParticipante", putData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let participante_empresa = [];
          for (const item in resp["participante_empresa"]) {
            participante_empresa.push({
              ...resp["participante_empresa"][item],
            });
          }
          return JSON.stringify(participante_empresa);
        })
      );
  }

  postArchivosDeclaracionJurada(postData: {
    in_det_etapa_lic: number;
    files: Blob;
    fileName: string;
    type: string;
    in_usuario: string;
    in_part_correlativo?: number;
    in_domi_correlativo_tiar?: number;
    publico: number;
    descripcion_input_archivo: string;
    idLicitacion: number;
    idEtapaLicitacion: number;
    puedeParticipar: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem("userData")).userId;

    return this.http
      .post(
        this.urlService + "participantes/postArchivosDeclaracionJurada",
        postData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivos = [];
          for (const item in resp["archivos"]) {
            archivos.push({ ...resp["archivos"][item] });
            archivos.push({ nombre_archivo: resp["nombre_archivo"] });
          }
          return JSON.stringify(archivos);
        })
      );
  }

  getEmpresas() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "participantes/getEmpresas", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let empresas = [];
          1;
          for (const item in resp["empresas"]) {
            empresas.push({ ...resp["empresas"][item] });
          }
          return JSON.stringify(empresas);
        })
      );
  }

  postUdpateActivarParticipante(postData: {
    correlativo: number;
    part_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}participantes/postUdpateActivarParticipante`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUdpateValidacionAclaracionTec(postData: {
    part_correlativo: number;
    lic_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}participantes/postUdpateValidacionAclaracionTec`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postGenerateNotificacionValidacionAclaracionTec(postData: {
    idLicitacion: number;
    oferenteNombre: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}participantes/postGenerateNotificacionValidacionAclaracionTec`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUdpateAprobacionEvaluacionTec(postData: {
    part_correlativo: number;
    lic_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}participantes/postUdpateAprobacionEvaluacionTec`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUdpateAprobacionEvaluacionEco(postData: {
    part_correlativo: number;
    lic_correlativo: number;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}participantes/postUdpateAprobacionEvaluacionEco`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  postUpdateAdjudicacion(postData: {
    part_correlativo: number;
    lic_correlativo: number;
    nombre_oferente: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.post(
      `${this.urlService}participantes/postUpdateAdjudicacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getSelectParticipanteByCorrelativo(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "participantes/getSelectParticipanteByCorrelativo/" +
          idLicitacion,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let participanteData = [];
          1;
          for (const item in resp["participanteData"]) {
            participanteData.push({ ...resp["participanteData"][item] });
          }
          return JSON.stringify(participanteData);
        })
      );
  }
}
