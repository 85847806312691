import { debugOutputAstAsTypeScript } from "@angular/compiler";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";

import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { IOnAgregarParticipanteCom } from "./../../shared/services/licitacion.types";

@Component({
  selector: "app-table-participantes-com",
  templateUrl: "./table-participantes-com.component.html",
  styleUrls: ["./table-participantes-com.component.css"],
  providers: [LicitacionService],
})
export class TableParticipantesComComponent implements OnInit {
  formParticipanteCom: FormGroup;
  participantesCom: [];
  capitulos: any[] = [];
  comisiones: [];
  participanteComSeleccionado: any = null;
  rolesComisionList: any[] = [];
  @Input() idLicitacion: number;
  @Output() data: EventEmitter<string[]> = new EventEmitter<string[]>();
  rolesParticipanteComision: any[];
  etapaActualLicitacion: number;

  constructor(
    private licitacionService: LicitacionService,
    private notifyService: NotificationService
  ) {
    this.rolesParticipanteComision = [];
    this.etapaActualLicitacion = 0;
  }

  ngOnInit(): void {
    this.formParticipanteCom = new FormGroup({
      per_correlativo: new FormControl(null),
      domi_correlativo_tipar: new FormControl(null),
    });

    this.getRoleComisionLicitacion();
    this.getParticipantesCom();
    if (this.idLicitacion == undefined) {
      this.comisiones = [];
    } else {
      this.getParticipantesComLicitacion();
    }

    this.capitulos = [
      {
        CORRELATIVO: "A",
        DESCRIPCION: "A",
        checked: false,
      },
      {
        CORRELATIVO: "B",
        DESCRIPCION: "B",
        checked: false,
      },
      {
        CORRELATIVO: "C",
        DESCRIPCION: "C",
        checked: false,
      },
      {
        CORRELATIVO: "D",
        DESCRIPCION: "D",
        checked: false,
      },
      {
        CORRELATIVO: "E",
        DESCRIPCION: "E",
        checked: false,
      },
    ];

    this.getSelectRolesParticipanteComision();

    this.licitacionService
      .getEtapaActualLicitacion(this.idLicitacion)
      .subscribe((resp) => {
        const etapaActualLicitacionData: any[] = JSON.parse(resp);
        this.etapaActualLicitacion =
          etapaActualLicitacionData[0].etapa_correlativo;
      });
  }

  getRoleComisionLicitacion() {
    this.licitacionService.getRolesComisionLicitacion().subscribe(
      (resp) => {
        this.rolesComisionList = JSON.parse(resp);
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener Roles de la Comisión"
        );
      }
    );
  }

  getParticipantesCom() {
    this.licitacionService.getParticipantesCom().subscribe(
      (resp) => {
        this.participantesCom = JSON.parse(resp);
        //console.log(this.participantesCom);
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener Participantes Comisión"
        );
      }
    );
  }

  getParticipantesComLicitacion() {
    this.licitacionService
      .getParticipantesComLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.comisiones = JSON.parse(resp);
          this.data.emit(this.comisiones);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener Participantes Comisión Licitación"
          );
        }
      );
  }

  onUpdateCapitulo(index: number, checked: boolean) {
    this.capitulos[index].checked = checked;
  }

  onAgregarParticipanteCom() {
    if (this.idLicitacion) {
      const putData: IOnAgregarParticipanteCom = {
        in_licitacion: 0,
        in_per_correlativo: "",
        in_domi_correlativo_tipar: "",
        in_cap_1: "",
        in_cap_2: "",
        in_cap_3: "",
        in_cap_4: "",
        in_cap_5: "",
      };
      putData.in_licitacion = this.idLicitacion;
      putData.in_per_correlativo =
        this.formParticipanteCom.value.per_correlativo;
      putData.in_domi_correlativo_tipar =
        this.formParticipanteCom.value.domi_correlativo_tipar;
      this.capitulos.forEach((cap, index) => {
        putData["in_cap_" + (index + 1)] = cap.checked ? "S" : "N";
      });
      if (
        putData.in_per_correlativo &&
        putData.in_per_correlativo !== "0" &&
        putData.in_domi_correlativo_tipar &&
        putData.in_domi_correlativo_tipar !== "0" &&
        ((putData.in_domi_correlativo_tipar === "7" &&
          this.capitulos.some((capitulo) => capitulo.checked)) ||
          true)
      ) {
        this.licitacionService.putParticipanteCom(putData).subscribe(
          (resp) => {
            if (resp) {
              this.notifyService.showSuccess(
                JSON.parse(resp),
                "Agregar Participante Comisión"
              );
              this.resetformParticipanteCom();
              this.getParticipantesComLicitacion();
              document.getElementById("close-modal-participante-com").click();
            }
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Agregar Participante Comisión"
            );
          }
        );
      } else {
        this.notifyService.showError(
          "Favor llene los campos obligatorios.",
          "Agregar Participante Comisión"
        );
      }
    }
  }

  resetformParticipanteCom() {
    this.formParticipanteCom.reset();
    this.capitulos = this.capitulos.map((capitulo) => {
      return {
        ...capitulo,
        checked: false,
      };
    });
  }

  removeParticipanteCom(in_part_correlativo: number) {
    this.participanteComSeleccionado = in_part_correlativo;
  }

  onEliminarParticipanteCom() {
    this.licitacionService
      .eliminarParticipanteCom(this.participanteComSeleccionado)
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            JSON.parse(resp),
            "Eliminar Participante Comisión Licitación"
          );
          this.getParticipantesComLicitacion();
          document
            .getElementById("close-modal-eliminar-participante-com")
            .click();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Eliminar Participante Comisión Licitación"
          );
        }
      );
  }

  onUpdateCheckDocumento(partCorrelativo: number, checked: number) {
    this.licitacionService
      .putDeclaracionDocumentoCheck({
        correlativo_participante_comision: partCorrelativo,
        check_documento: checked,
      })
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Documento actualizado",
            "Se ha validado el documento con exito."
          );
          this.getParticipantesComLicitacion();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Error al validar el documento"
          );
        }
      );
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }
}
