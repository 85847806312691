import { ModalParticipantesComponent } from "./../modal-participantes/modal-participantes.component";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { IParticipante } from "src/app/shared/services/licitacion.types";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

interface MotivosRenuncia {
  correlativo: number;
  descripcion: string;
  tipo: string;
  vigente: number;
  fecha_creacion: Date;
}

@Component({
  selector: "app-table-participantes",
  templateUrl: "./table-participantes.component.html",
  styleUrls: ["./table-participantes.component.css"],
})
export class TableParticipantesComponent implements OnInit {
  participantes: IParticipante[] = [];
  participanteSeleccionado: number = 0;
  @Input() idLicitacion: number;
  @Input() idEtapaLic: number;
  @ViewChild("modalParticipante")
  modalParticipante: ModalParticipantesComponent;
  @Output() data: EventEmitter<IParticipante[]> = new EventEmitter<
    IParticipante[]
  >();
  eliminarOfernete: boolean;
  motivoDescalificacion: string;
  participanteSeleccionadoElminar: number;
  etapaActualLicitacion: number;
  // carga de archivos en el correo
  archivos64: Blob;
  fileName: string;
  type: string;
  archivo: string;
  // usuario
  userId: string;
  adjunto: string;
  // formulario de eliminacion de participante
  elminarParticipateForm: FormGroup;
  @ViewChild("inputFile") inputFile: ElementRef;
  motivoRenunciaList: MotivosRenuncia[];
  rolesParticipanteComision: any[];
  idRol: number;

  constructor(
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder
  ) {
    this.eliminarOfernete = false;
    this.motivoDescalificacion = "";
    this.participanteSeleccionadoElminar = 0;
    this.etapaActualLicitacion = 0;
    this.adjunto = "";
    this.motivoRenunciaList = [];
    this.rolesParticipanteComision = [];
    this.idRol = JSON.parse(localStorage.getItem("userData")).rolId;
  }

  ngOnInit(): void {
    this.elminarParticipateForm = new FormGroup({
      motivoDescalificacion: new FormControl(""),
      nombreArchivo: new FormControl(""),
      archivo: new FormControl(null),
      motivoRenunciaCorrelativo: new FormControl(null),
    });

    this.elminarParticipateForm = this.formBuilder.group({
      motivoDescalificacion: ["", Validators.required],
      nombreArchivo: ["", Validators.required],
      archivo: [null, Validators.required],
      motivoRenunciaCorrelativo: [null, Validators.required],
    });

    this.getParticipantes();
    this.licitacionService
      .getEtapaActualLicitacion(this.idLicitacion)
      .subscribe((resp) => {
        const etapaActualLicitacionData: any[] = JSON.parse(resp);
        this.etapaActualLicitacion =
          etapaActualLicitacionData[0].etapa_correlativo;
      });
    this.getMotivosRenuncia();
    this.getSelectRolesParticipanteComision();
  }

  getParticipantes() {
    this.licitacionService.getParticipantes().subscribe((resp) => {
      this.participantes = JSON.parse(resp);
      if (this.participantes) {
        this.licitacionService
          .getParticipantesLic(this.idLicitacion, this.idEtapaLic)
          .subscribe((resp) => {
            //const part_activos = JSON.parse(resp);
            /* part_activos.map(el =>{
            this.participantes.find((element ,i)  => {
              const elem = JSON.parse(JSON.stringify(element));
              if (elem.CORRELATIVO === el.CORRELATIVO){
                this.participantes.splice(i, 1);
                this.participantes.push({ ...element, ACTIVO: true});
              }
            });
          }); */
            this.participantes = JSON.parse(resp);
            this.data.emit(this.participantes);
          });
      }
    });
  }

  onUpdateParticipante(partCheck: boolean) {
    let activo = partCheck === true ? "S" : "N";
    const postData: {
      in_licitacion: number;
      in_etapa: number;
      in_part_correlativo: number;
      in_usuario: string;
      in_activo: string;
      in_motivo_descalificacion: string;
      in_motivo_reuncia_correlativo: number;
    } = {
      in_licitacion: this.idLicitacion,
      in_etapa: this.idEtapaLic,
      in_part_correlativo: this.participanteSeleccionadoElminar,
      in_usuario: "",
      in_activo: activo,
      in_motivo_descalificacion:
        this.elminarParticipateForm.value.motivoDescalificacion,
      in_motivo_reuncia_correlativo:
        this.elminarParticipateForm.value.motivoRenunciaCorrelativo,
    };
    this.licitacionService.postPostulanteLic(postData).subscribe(
      (resp) => {
        if (this.etapaActualLicitacion === 0) {
          this.notifyService.showSuccess(JSON.parse(resp), "Participante");
          this.getParticipantes();
        } else {
          const postDataEnvioNotifiacion = {
            pin_lic_correlativo: this.idLicitacion,
            in_part_correlativo: this.participanteSeleccionadoElminar,
            in_adjunto: this.adjunto,
          };

          this.licitacionService
            .postEnviaNotificacionDescalificacion(postDataEnvioNotifiacion)
            .subscribe(
              (resp) => {
                this.elminarParticipateForm = this.formBuilder.group({
                  motivoDescalificacion: ["", Validators.required],
                  nombreArchivo: ["", Validators.required],
                  archivo: [null, Validators.required],
                  motivoRenunciaCorrelativo: [null, Validators.required],
                });

                this.archivos64 = null;
                this.fileName = "";
                this.type = "";
                this.inputFile.nativeElement.value = "";

                this.eliminarOfernete = false;
                this.notifyService.showWarning(
                  "Participante eliminado",
                  "Participante"
                );
                this.getParticipantes();
              },
              (err) => {
                this.notifyService.showError(err.error.message, "Participante");
              }
            );
        }

        // this.elminarParticipateForm = this.formBuilder.group({
        //   motivoDescalificacion: ['', Validators.required],
        //   nombreArchivo: ['', Validators.required]
        // });

        // this.archivos64 = null;
        // this.fileName = '';
        // this.type = '';
        // this.inputFile.nativeElement.value = "";

        // this.eliminarOfernete = false;
        // this.notifyService.showSuccess(JSON.parse(resp), 'Participante');
        // this.getParticipantes();
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Participante");
      }
    );
  }

  openModalParticipante(in_part_correlativo: number) {
    this.participanteSeleccionado = in_part_correlativo;
    setTimeout(() => {
      this.modalParticipante.abrirModal();
    }, 100);
  }
  eliminarParticipante() {
    if (this.elminarParticipateForm.invalid) {
      this.notifyService.showWarning(
        "Debe completar los campos requeridos para la descalificación del participante.",
        "Participante"
      );
      return;
    } else {
      // primero se hace la carga del archivo
      const postDataFile: {
        in_det_etapa_lic: number;
        files: Blob;
        fileName: string;
        type: string;
        in_usuario: string;
        in_domi_correlativo_tiar: number;
        publico: number;
        descripcion_input_archivo: string;
        idLicitacion: number;
        idEtapaLicitacion: number;
        correlativoGrupoArchivo: number;
      } = {
        in_det_etapa_lic: this.idEtapaLic,
        files: this.archivos64,
        fileName: this.fileName,
        type: this.type,
        in_usuario: "0",
        in_domi_correlativo_tiar: 40,
        publico: 0,
        descripcion_input_archivo:
          this.elminarParticipateForm.value.nombreArchivo == null ||
          this.elminarParticipateForm.value.nombreArchivo == ""
            ? this.fileName
            : this.elminarParticipateForm.value.nombreArchivo,
        idLicitacion: this.idLicitacion,
        idEtapaLicitacion: this.etapaActualLicitacion,
        correlativoGrupoArchivo: 0,
      };

      this.licitacionService.postArchivosLicitacion(postDataFile).subscribe(
        (resp) => {
          this.adjunto = JSON.parse(resp)[1].nombre_archivo;

          this.onUpdateParticipante(false);
          this.data.emit(this.participantes);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener Participantes Oferentes Licitación"
          );
        }
      );
    }
  }

  onEliminarParticipante(idParticipante: number) {
    this.participanteSeleccionadoElminar = idParticipante;

    if (this.etapaActualLicitacion === 0) {
      this.onUpdateParticipante(false);
    } else {
      this.eliminarOfernete = true;
    }
  }

  detectFiles(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64;
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
        }
      };
    }
  }

  cancelarEliminarOferenete() {
    this.elminarParticipateForm = this.formBuilder.group({
      motivoDescalificacion: ["", Validators.required],
      nombreArchivo: ["", Validators.required],
      archivo: [null, Validators.required],
      motivoRenunciaCorrelativo: [null, Validators.required],
    });

    this.archivos64 = null;
    this.fileName = "";
    this.type = "";
    this.inputFile.nativeElement.value = "";

    this.eliminarOfernete = false;
  }

  getMotivosRenuncia() {
    this.licitacionService.getMotivosRenunciaLicitacion().subscribe(
      (resp) => {
        const motivosRenuncia: any[] = JSON.parse(resp);
        const motivosActivos: any[] = motivosRenuncia.filter(
          (element) => element.tipo === "EMSA"
        );

        if (motivosActivos.length > 0) {
          this.motivoRenunciaList = motivosActivos;
        } else {
          this.motivoRenunciaList = [];
        }
      },
      (error) => {
        this.motivoRenunciaList = [];
        this.notifyService.showError(
          error.error.message,
          "Error al obtener los motivos de renuncia"
        );
      }
    );
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }
}
