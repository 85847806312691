<div class="row mb-3 mt-5">
    <div class="col">
        <h4>Documentos y Anexos de la Licitación</h4>
    </div>
</div>
<div class="row">
    <ng-container *ngIf="actaInicio">
        <div class="col-12 col-md-6 card p-20 mr-2">
            <div class="row no-print">
                <ng-container *ngIf="gruposArchivos?.length > 0">
                    <div class="col-12">
                        <div class="form-group row m-b-10 align-items-center">
                            <label class="col-form-label col-md-2">Grupo</label>
                            <div class="col-md-10">
                                <select class="form-control" (change)="onChangeGurpoArchivo($event.target.value)">
                                    <option *ngFor="let grupo of gruposArchivos" value="{{grupo.correlativo}}">
                                        {{grupo.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="gruposArchivos?.length <= 0">
                    <p>No existen grupos.</p>
                </ng-container>
                <div class="col-12">
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-form-label col-md-3 col-12">Descripción</label>
                        <div class="col-md-9 col-12">
                            <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                [(ngModel)]="nombreArchivo">
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row m-b-10">
                        <label class="col-form-label col-md-3 col-12">Subir archivo</label>
                        <div class="col-md-9 col-12">
                            <input #inputFileAdicional style="border-color: transparent;" class="form-control"
                                name="archivo" type="file" (change)="detectDocumentosAdicionales($event)" />
                        </div>
                    </div>
                </div>

                <div class="col-12" *ngIf="(this.currentStep != 9 && this.currentStep != 10)">
                    <div class="row">
                        <label class="col-md-3 col-form-label col-12">Archivo publico<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9 col-12">
                            <div class="checkbox checkbox-css pt-2">
                                <input type="checkbox" value="" id="checkPublico" [(ngModel)]="isPublic"
                                    [checked]="false" [disabled]="idCargoActual == 0" (change)="onCheckPublico()" />
                                <label style="margin-bottom: 10px;" for="checkPublico">Archivo publico (EMSA -
                                    Oferente)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <a style="color: white;" class="btn tag text-orange"
                        (click)="onGuardarDocumentoAdicional()">Agregar</a>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="col-12 col-md-5 card p-20 ml-2">
        <div class="row no-print">
            <p class="text-muted">Documentos Requeridos:</p>
            <ng-container *ngIf="gruposArchivos?.length > 0">
                <div class="col-12" *ngFor="let grupo of gruposArchivos">
                    <div class="row">
                        <div class="col-6 text-left">
                            <p [ngClass]="grupo.obligatorio > 0 ? 'text-red': 'text-muted'"><i
                                    class="far fa-file-alt text-primary mr-2"></i> {{grupo.descripcion}}</p>
                        </div>
                        <div class="col-6 text-right">
                            <ng-container *ngIf="grupo.correlativo == 7">
                                <ng-container *ngIf="validateCheckArchivoGrupo(grupo.correlativo) == true">
                                    <ng-container *ngIf="urlArchivoSelected(grupo.correlativo) 
                                    !== ''">
                                        <app-view-file-buttons
                                            [nombreArchivo]="urlArchivoSelected(grupo.correlativo)"></app-view-file-buttons>
                                    </ng-container>
                                    <span><i class="fa fa-check-circle text-green mr-2"></i></span>
                                </ng-container>
                                <span *ngIf="validateCheckArchivoGrupo(grupo.correlativo) == false"><i
                                        class="fa fa-times text-red mr-2"></i></span>
                            </ng-container>
                            <ng-container *ngIf="grupo.correlativo != 7">
                                <span *ngIf="validateCheckArchivoGrupo(grupo.correlativo) == true"><i
                                        class="fa fa-check-circle text-green mr-2"></i></span>
                                <span *ngIf="validateCheckArchivoGrupo(grupo.correlativo) == false"><i
                                        class="fa fa-times text-red mr-2"></i></span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="gruposArchivos?.length <= 0">
                <p>No hay requeridos para esta licitación.</p>
            </ng-container>
        </div>
    </div>
</div>
<br>
<div class="form-group row m-b-15">
    <!-- <label class="col-form-label col-md-2"></label> -->
    <div class="col-md-12">
        <table class="table table-striped table-td-valign-middle">
            <thead class="thead-darkk">
                <tr>
                    <th class="text-nowrap">Descripción</th>
                    <th class="text-nowrap">Nombre archivo</th>
                    <th class="text-nowrap">Grupo</th>
                    <th class="text-nowrap">Subido por</th>
                    <th class="text-nowrap"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of archivos">
                    <td>
                        <p class="mb-0">{{item.DESCRIPCION_ARCHIVO}}</p>
                        <ng-container *ngIf="idCargoActual > 0 || item.PUBLICO == 1">
                            <span *ngIf="item.PUBLICO == 1" class="tab tab-green mb-2 mt-2">Publico</span>
                        </ng-container>
                    </td>
                    <td>{{item.DESCRIPCION}}</td>
                    <td>{{returnGrupoArchivo(item.GRUPO_ARCHIVO)}}</td>
                    <td>{{item.USUARIO}}</td>
                    <td class="text-center">
                        <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                        <ng-container *ngIf="actaInicio">
                            <a class="btn rounded-icon mr-2"
                                (click)="deleteFileLicitacion(item.CORRELATIVO, item.NOMBRE_ARCHIVO)" title="Eliminar">
                                <i class="fas fa-trash"></i>
                            </a>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="archivos?.length <= 0" style="justify-content: center; display: flex;">
            <p>No se ha seleccionado ningun archivo</p>
        </div>
    </div>
</div>