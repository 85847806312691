<!-- begin #content -->
<div id="content" class="content">
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Editar etapa licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-12 col-md-7">
            <!-- begin panel -->
            <div class="panel panel-inverse rounded" data-sortable-id="form-stuff-1">
                <!-- <div class="panel-heading">
                    <h4 class="panel-title">Editar etapa licitación</h4>
                </div> -->
                <div class="panel-body">
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-12 col-md-3 col-form-label">Participantes <span class="text-danger">*</span></label>
                        <div class="col-12 col-md-9">
                          <!-- <div class="checkbox checkbox-css pt-0" *ngFor="let item of participantes"> -->
                              <!-- <input #participante type="checkbox" value="" id="{{item.PART_CORRELATIVO}}" [checked]="item.ACTIVO ==='S'" (change)="onActualizaParticipantesEtapa(item.PART_CORRELATIVO, participante.checked)" />
                              <label style="margin-bottom: 10px;" for="{{item.PART_CORRELATIVO}}">{{item.PART_NOMBRE}}</label> -->
                              <!-- <input #participante type="checkbox" value="" id="{{item.PART_CORRELATIVO}}" [checked]="item.ACTIVO ==='S'" (change)="eliminarParticipante(item.PART_CORRELATIVO)" /> -->
                              <!-- <input #participante type="checkbox" value="" id="{{item.PART_CORRELATIVO}}" [checked]="item.ACTIVO ==='S'" data-toggle="modal" data-target="#modalEliminarParticipante" />
                              <label style="margin-bottom: 10px;" for="{{item.PART_CORRELATIVO}}">{{item.PART_NOMBRE}}</label> -->
                          <!-- </div> -->
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Participante</th>
                                <th scope="col">Estado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of participantes">
                                <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}" id="{{item.PART_CORRELATIVO}}">{{item.PART_NOMBRE}}</td>
                                <td class="text-center">
                                    <!-- <ng-container *ngIf="item.VIGENTE == 'S'">
                                      <a class="btn rounded-icon" href="#modalEliminarParticipante" data-toggle="modal"
                                      (click)="removeParticipanteCom(item.PART_CORRELATIVO)" title="Eliminar">
                                        <i class="fas fa-trash"></i>
                                      </a>
                                    </ng-container> -->
                                    <ng-container *ngIf="item.VIGENTE == 'N'">
                                      <span [ngClass]="{'text-danger': item.VIGENTE == 'N'}">Eliminado</span>
                                    </ng-container>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                    <div class="row border-top pt-4">
                      <div class="col-12">
                        <div class="form-group row m-b-10 align-items-center">
                          <label class="col-form-label col-md-3">Descripción Archivo <span class="text-danger">*</span></label>
                          <div class="col-md-9">
                              <input class="form-control" type="text" placeholder="" id="nombreArchivo" [(ngModel)]="nombreArchivo">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 ">
                        <div class="form-group row m-b-10 align-items-center">
                          <label class="col-md-3 col-form-label">Subir evidencia adicional <span class="text-danger">*</span></label>
                          <div class="col-md-9">
                              <!-- <input #inputFile style="border-color: transparent;" class="form-control" name="archivo" type="file" 
                              (change)="detectFiles($event)" /> -->
                              <div class="custom-file">
                                <input #inputFile type="file" class="custom-file-input" formControlName="adjunto" name="archivo" id="nombreArchivo" 
                                (change)="detectFiles($event)">
                                <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                              </div>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="idEtapa != 14">
                        <label class="col-md-3 col-form-label">Archivo publico<span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="checkbox checkbox-css pt-0">
                                <input type="checkbox" value="" id="checkPublico" [checked]="checkedArchivo" (change)="onActualizaEstadoArchivo(checkedArchivo)" [disabled]="idCargoActual !== 12"/>
                                <label style="margin-bottom: 10px;" for="checkPublico">Archivo publico (EMSA - Oferente)</label>
                            </div>
                        </div>
                      </ng-container>
                      <div class="col-12 col-md-3"></div>
                      <div class="col-12 col-md-9">
                        <app-view-etapa-files-table [archivos]="archivos" [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                      </div>
                    </div>
                </div>
                <div class="panel-footer text-right">
                    <button style="margin-right: 10px;" type="button" class="btn btn-primary" routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver al cronograma</button>
                    <button class="btn btn-primary" (click)="onGuardarEtapa()"> <i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-save" style="color: white;"></i>Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <chat [idLicitacion]="idLicitacion"></chat>
</div>
<!-- end #content -->
<!-- Modal Eliminar participante -->
<div class="modal fade" id="modalEliminarParticipante" tabindex="-1" role="dialog" aria-labelledby="modalEliminarParticipante" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEliminarParticipante">Eliminar Participante</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>¿Está seguro que desea eliminar el participante?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="eliminarParticipante()" data-dismiss="modal">Guardar cambios</button>
        </div>
      </div>
    </div>
</div>