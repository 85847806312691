import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { LicitacionService } from "../shared/services/licitacion.service";
import { ActivatedRoute, Params } from "@angular/router";
import { EtapasService } from "../shared/services/etapas.licitacion.service";
import { Location } from "@angular/common";

interface HeaderLicitacion {
  SOLPE_CORRELATIVO: string;
  CONT_NOMBRE: string;
  CONT_NUMERO: string;
  DESCRIPCION: string;
  FECHA_CREACION: string;
  MONTO_SOLPE: number;
  MONEDA_SOLPE: number;
  DESCRIPCION_MONEDA_SOLPE: string;
  ETAPA_CORRELATIVO: number;
  CORRELATIVO_ETAPA: number;
  ETAPA_DESC: string;
  LICITACION_CORRELATIVO: number;
  FECHA_PUBLICACION: null | string;
  CONSULTAS_APROBADAS: number;
  RESP_PROPCESO: number;
  CORRELATIVO_SOLPE: number;
  VALIDACION_LCT: number;
  OBSERVACION_LCT: string;
  MONTO_SOLPE_USD: number;
  ORDEN: number;
  VALIDACION_JAC: number;
  OBSERVACION_JAC: string;
  TIENE_ACLARACIONES_TEC: number;
  TIENE_ACLARACIONES_ECO: number;
  TIENE_NEGOCIACION: number;
  LICITACION_FINALIZADA: number;
}

@Component({
  selector: "app-observaciones-etapas-licitacion",
  templateUrl: "./observaciones-etapas-licitacion.component.html",
  styleUrls: ["./observaciones-etapas-licitacion.component.css"],
})
export class ObservacionesEtapasLicitacionComponent implements OnInit {
  idLicitacion: number;
  licitacionData: HeaderLicitacion;
  observacionesEtapasLicitacion: any[];
  observaciones: string;
  @ViewChild("closeModalAddObservaciones")
  closeModalAddObservaciones: ElementRef;

  constructor(
    private notifyService: NotificationService,
    private licitacionService: LicitacionService,
    private location: Location,
    private route: ActivatedRoute,
    private etapasService: EtapasService
  ) {
    this.observacionesEtapasLicitacion = [];
    this.observaciones = "";

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const objLicitacionData: HeaderLicitacion = JSON.parse(resp)[0];
          this.licitacionData = objLicitacionData;
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );
    });
  }

  ngOnInit(): void {
    this.getObservacionesEtapasLicitacion();
  }

  getObservacionesEtapasLicitacion() {
    this.etapasService
      .getSelectObservacioneEtapaLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          const observacionesEtapas: any[] = JSON.parse(resp);
          console.log(observacionesEtapas);

          if (observacionesEtapas.length > 0) {
            this.observacionesEtapasLicitacion = observacionesEtapas;
          } else {
            this.observacionesEtapasLicitacion = [];
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de las etapas de la licitacion"
          );
        }
      );
  }

  back(): void {
    this.location.back();
  }

  onAddObservacion() {
    if (this.observaciones.trim() === "") {
      this.notifyService.showWarning(
        "Por favor, ingrese una observación.",
        "Advertencia"
      );
    } else {
      // ingresar la observacion con la etapa acutal de la licitacion
      const postData = {
        in_lic_correlativo: this.idLicitacion,
        in_etapa_correlativo: this.licitacionData.ETAPA_CORRELATIVO,
        in_observaciones: this.observaciones,
      };

      this.etapasService
        .postiInsertObservacionesLicitacion(postData)
        .subscribe((resp) => {
          this.licitacionService
            .getHeaderLicitacionId(this.idLicitacion)
            .subscribe(
              (resp) => {
                const objLicitacionData: HeaderLicitacion = JSON.parse(resp)[0];
                this.licitacionData = objLicitacionData;

                this.notifyService.showSuccess(
                  "Observación agregada correctamente.",
                  "Observaciones"
                );

                this.getObservacionesEtapasLicitacion();
                this.observaciones = "";
                this.closeModalAddObservaciones.nativeElement.click();
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Obtener datos de la licitacion"
                );
              }
            );
        });
    }
  }
}
