import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FileService } from 'src/app/shared/services/file.service';
import { ActivatedRoute, Params } from '@angular/router';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-view-etapa-archivo',
  templateUrl: './view-etapa-archivo.component.html',
  styleUrls: ['./view-etapa-archivo.component.css']
})
export class ViewEtapaArchivoComponent implements OnInit {
  idLicitacion:number;
  idEtapa:number;
  idEtapaLic:number;
  nombreEtapa:string;
  filename: string;
  etapaArchivo:[];
  participanteSeleccionado: number = 0;

  constructor(private route: ActivatedRoute, private licitacionService: LicitacionService,private etapaService: EtapasService,private notifyService : NotificationService, private fileService: FileService) { }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }
        this.getEtapaArchivo(this.idEtapaLic);
    });
  }

  private getEtapaArchivo(idEtapaLic: number ){
    this.etapaService.getArchivosLicEtapaPart(idEtapaLic).subscribe(resp=>{
      this.etapaArchivo = JSON.parse(resp);
    });
  }

  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }

  onActualizaParticipantesEtapa(idParticipante: number ,estado:boolean){
    const v_estado: string = estado ===true ? 'S': 'N';
    const putData : { in_licitacion: number;in_part_correlativo: number;in_etapa: number; in_estado: string; in_usuario: string} = { in_licitacion: this.idLicitacion, in_part_correlativo: idParticipante, in_etapa: this.idEtapaLic, in_estado: v_estado, in_usuario:''};
    this.licitacionService.putPartEtapaLic(putData).subscribe(resp=>{
      this.notifyService.showSuccess('Participante actualizado', 'Actualizacion de participante');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Actualizacion de participantew');
    });
  }

  onUpdateParticipante(idParticipante: number, partCheck: boolean){
    let activo = partCheck === true ? 'S': 'N';
    const postData : { 
      in_licitacion: number;
      in_etapa: number; 
      in_part_correlativo: number; 
      in_usuario: string; 
      in_activo: string;
      in_motivo_descalificacion: string;
      in_motivo_reuncia_correlativo: number;
    } = { 
      in_licitacion: this.idLicitacion, 
      in_etapa: this.idEtapaLic, 
      in_part_correlativo : idParticipante, 
      in_usuario:'', 
      in_activo: activo,
      in_motivo_descalificacion: '',
      in_motivo_reuncia_correlativo: 0
    };
    this.licitacionService.postPostulanteLic(postData).subscribe(resp =>{
      this.notifyService.showSuccess(JSON.parse(resp), 'Participante');
      this.getEtapaArchivo(this.idEtapaLic);
    }, err =>{
      this.notifyService.showError(err.error.message, 'Participante');
    });
  }

  eliminarParticipante(){
    this.onUpdateParticipante(this.participanteSeleccionado, false);
  }

  removeParticipante(idParticipante: number){
   this.participanteSeleccionado = idParticipante;
  }
}
