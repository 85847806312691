import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LicitacionService } from '../shared/services/licitacion.service';
import { NotificationService } from '../shared/services/notification.service';

export interface NotasEvalTecnica {
  correlativo:    number;
  nota:           number;
  vigente:        string;
  fecha_creacion: Date;
}

export interface AyudaEvalTecnicaCriterio {
  correlativo:      number;
  descripcion:      string;
  cargo:            string;
  perfil_requerido: string;
  nota:             number;
  correlativo_nota: number;
}

@Component({
  selector: 'app-mantenedor-estandares-eval-tec',
  templateUrl: './mantenedor-estandares-eval-tec.component.html',
  styleUrls: ['./mantenedor-estandares-eval-tec.component.css']
})
export class MantenedorEstandaresEvalTecComponent implements OnInit {
  formCreateEstandarEvalTec: FormGroup;
  formUpdateEstandarEvalTec: FormGroup;
  estandaresEvaluacionTecnicaMantenedor: any[];
  estandaresEvaluacionTecnicaMantenedorFiltered: any[];
  estadarSeleccionado: string;
  correlativoEstandarEvalTecSeleccionado: number;
  stepEstandarEvalTecSeleccionado: number;
  // GRUPOS
  grupoEstandarEvalTecSeleccionado: number;
  estandarGrupoEvalTec: any[];
  estandarGrupoEvalTecFiltered: any[];
  formCreateEstandarGrupoEvalTec: FormGroup;
  formUpdateEstandarGrupoEvalTec: FormGroup;
  grupoSeleccionado: string;
  grupoCorrelativoSeleccionado: number;
  // SUBGRUPOS
  subgrupoEstandarEvalTecSeleccionado: number;
  estandarSubgrupoEvalTec: any[];
  estandarSubgrupoEvalTecFiltered: any[];
  formCreateSubgrupoEvalTec: FormGroup;
  formUpdateSubgrupoEvalTec: FormGroup;
  subgrupoSeleccionado: string;
  subgrupoCorrelativoSeleccionado: number;
  grupoSubgrupoSeleccionado: string;
  grupoValidado: boolean;
  subgrupoValidado: boolean;

  // AYUDAS EVALUACION TECNICA
  ayudasEvalTecnica: any[];
  criteriosEvalTecnica: any[];
  criteriosEvalTecnicaFiltered: any[];
  ayudaEvalTecnicaSeleccionada: number;
  accionesAyudaEvalTecnica: number;
  cargosFilter: string[];
  formCreateAyudaEvalTec: FormGroup;
  formCreateAyudaCriterio: FormGroup;
  notasEvaluacionTecnica: NotasEvalTecnica[];
  formUpdateAyudaCriterio: FormGroup;
  criterioAyudaSeleccionado: number;
  criterioAyudaSeleccionadoStr: string;

  @ViewChild('closeModalCrearEstandarEvalTec') closeModalCrearEstandarEvalTec: ElementRef;
  @ViewChild('closeModalEliminarEstandarEvalTec') closeModalEliminarEstandarEvalTec: ElementRef;
  @ViewChild('closeModalUpdateEstandarEvalTec') closeModalUpdateEstandarEvalTec: ElementRef;

  // GRUPOS
  @ViewChild('closeModalCrearGrupoEvalTec') closeModalCrearGrupoEvalTec: ElementRef;
  @ViewChild('closeModalEliminarGrupoEvalTec') closeModalEliminarGrupoEvalTec: ElementRef;
  @ViewChild('closeModalUpdateGrupoEvalTec') closeModalUpdateGrupoEvalTec: ElementRef;

  // SUBGRUPOS
  @ViewChild('closeModalCrearSubgrupoEvalTec') closeModalCrearSubgrupoEvalTec: ElementRef;
  @ViewChild('closeModalEliminarSubgrupoEvalTec') closeModalEliminarSubgrupoEvalTec: ElementRef;
  @ViewChild('closeModalUpdateSubgrupoEvalTec') closeModalUpdateSubgrupoEvalTec: ElementRef;

  constructor(
    private licitacionService: LicitacionService, 
    private notifyService: NotificationService,
  ) { 
    this.estandaresEvaluacionTecnicaMantenedor = [];
    this.estandaresEvaluacionTecnicaMantenedorFiltered = [];
    this.estadarSeleccionado = '';
    this.correlativoEstandarEvalTecSeleccionado = 0;
    this.stepEstandarEvalTecSeleccionado = 0;
    // GRUPOS
    this.estandarGrupoEvalTec = [];
    this.estandarGrupoEvalTecFiltered = [];
    this.grupoEstandarEvalTecSeleccionado = 0;
    this.grupoSeleccionado = '';
    this.grupoCorrelativoSeleccionado = 0;
    // GRUPOS
    this.estandarSubgrupoEvalTec = [];
    this.estandarSubgrupoEvalTecFiltered = [];
    this.subgrupoEstandarEvalTecSeleccionado = 0;
    this.subgrupoSeleccionado = '';
    this.subgrupoCorrelativoSeleccionado = 0;
    this.grupoValidado = false;
    this.subgrupoValidado = false;

    // AYUDAS EVALUACIONES TECNICAS
    this.ayudasEvalTecnica = [];
    this.criteriosEvalTecnica = [];
    this.criteriosEvalTecnicaFiltered = [];
    this.ayudaEvalTecnicaSeleccionada = 0;
    this.accionesAyudaEvalTecnica = 0;
    this.cargosFilter = [];
    this.notasEvaluacionTecnica = [];
    this.criterioAyudaSeleccionado = 0;
    this.criterioAyudaSeleccionadoStr = '';

    this.formCreateEstandarEvalTec = new FormGroup({
      descripcionEstandar: new FormControl('', Validators.required)
    });

    this.formUpdateEstandarEvalTec = new FormGroup({
      descripcionEstandar: new FormControl('', Validators.required),
      vigente: new FormControl(0, Validators.required),
    });

    // GRUPOS
    this.formCreateEstandarGrupoEvalTec = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      peso: new FormControl(null, Validators.required),
    });

    this.formUpdateEstandarGrupoEvalTec = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      peso: new FormControl(null, Validators.required),
      vigente: new FormControl(0, Validators.required),
    });

    // SUBGRUPOS
    this.formCreateSubgrupoEvalTec = new FormGroup({
      subgrupoDescripcion: new FormControl('', Validators.required),
      subgrupoPeso: new FormControl(null, Validators.required),
      subgrupoAbierto: new FormControl(1, Validators.required),
      subgrupoTipo: new FormControl(1, Validators.required),
      subgrupoIdAyuda: new FormControl(null, Validators.required),
      subgrupoLimiteRespuesta: new FormControl(1, Validators.required),
      subgrupoObservacion: new FormControl('', Validators.required),
    });

    this.formUpdateSubgrupoEvalTec = new FormGroup({
      subgrupoDescripcion: new FormControl('', Validators.required),
      subgrupoPeso: new FormControl(null, Validators.required),
      subgrupoAbierto: new FormControl(1, Validators.required),
      subgrupoTipo: new FormControl(1, Validators.required),
      subgrupoIdAyuda: new FormControl(null, Validators.required),
      subgrupoLimiteRespuesta: new FormControl(1, Validators.required),
      subgrupoObservacion: new FormControl('', Validators.required),
    });

    // AYUDAS EVALUACIONES TECNICAS
    this.formCreateAyudaEvalTec = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
    });

    this.formCreateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
      cargo: new FormControl(null),
      perfil_requerido: new FormControl(null),
      nota: new FormControl(null, Validators.required),
    });

    this.formUpdateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
      cargo: new FormControl(null),
      perfil_requerido: new FormControl(null),
      nota: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.getEstandaresEvalTecnicaMantenedor();
    this.getAyudaEvalTecnica();
    this.getNotasEvaluacionTecnica();
  }

  filterAyudaEvalTecSeleccionada(correlativo: number){
    return this.ayudasEvalTecnica.filter(data => data.correlativo === correlativo)[0].descripcion ?? '';
  }

  getAyudaEvalTecnica(){
    this.licitacionService.getAyudaEvalTecnica().subscribe(resp=>{
        const ayudaEvalTecnica: any[] = JSON.parse(resp);

        if (ayudaEvalTecnica.length > 0) {
          this.ayudasEvalTecnica = Array.from(new Set(ayudaEvalTecnica));
        }else{
          this.ayudasEvalTecnica = [];
        }
      },
      error => {
        this.ayudasEvalTecnica = [];
        this.notifyService.showError(error.error.message, 'Error al obtener ayuda de evaluación técnica');
      }
    );
  }

  verSubgruposEstandar(correlativo: number){
    this.stepEstandarEvalTecSeleccionado = 2;
    this.subgrupoEstandarEvalTecSeleccionado = correlativo;
    this.grupoSubgrupoSeleccionado = this.estandarGrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '';

    this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorSubgrupoMantenedor(correlativo).subscribe(
      resp=>{
        const subgruposEstandar: any[] = JSON.parse(resp);
        let sumatoriaSubgrupoPeso: number = 0

        if (subgruposEstandar.length > 0) {
          this.estandarSubgrupoEvalTec = subgruposEstandar;
          this.estandarSubgrupoEvalTecFiltered = subgruposEstandar;

          for (let index = 0; index < subgruposEstandar.length; index++) {
            const element = subgruposEstandar[index];
            
            sumatoriaSubgrupoPeso += element.peso;
          }

          if (sumatoriaSubgrupoPeso == 100) {
            this.subgrupoValidado = true;
          }else{
            this.subgrupoValidado = false;
          }
        }else{
          this.estandarSubgrupoEvalTec = [];
          this.estandarSubgrupoEvalTecFiltered = [];
        }
      },
      error => {
        this.estandarSubgrupoEvalTec = [];
        this.estandarSubgrupoEvalTecFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener los subgrupos de estandares');
      }
    );
  }

  editarSubgrupoEvalTec(correlativo: number){
    this.subgrupoCorrelativoSeleccionado = correlativo;
    this.subgrupoSeleccionado = this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '';

    this.formUpdateSubgrupoEvalTec = new FormGroup({
      subgrupoDescripcion: new FormControl(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '', Validators.required),
      subgrupoPeso: new FormControl(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].peso ?? 0, Validators.required),
      subgrupoAbierto: new FormControl(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].subgrupo_abierto ?? 1, Validators.required),
      subgrupoTipo: new FormControl( this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].tipo ?? 1, Validators.required),
      subgrupoIdAyuda: new FormControl(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].idayuda ?? null, Validators.required),
      subgrupoLimiteRespuesta: new FormControl(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].limiterespuestasoferente ?? 1, Validators.required),
      subgrupoObservacion: new FormControl(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].observacion ?? '', Validators.required),
    });

    this.ayudaEvalTecnicaSeleccionada = this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].idayuda;
    this.cargosFilter = [];
    this.licitacionService.getAyudaEvalTecnicaCriteriosMantenedor(this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].idayuda).subscribe(
      resp=>{
        const criteriosRelacionadosAyuda: any[] = JSON.parse(resp);

        if (criteriosRelacionadosAyuda.length > 0) {
          this.criteriosEvalTecnica = Array.from(new Set(criteriosRelacionadosAyuda));
          this.criteriosEvalTecnicaFiltered = Array.from(new Set(criteriosRelacionadosAyuda));

          for (let index = 0; index < this.criteriosEvalTecnica.length; index++) {
            const element = this.criteriosEvalTecnica[index];
            
            if (element?.cargo != '' && element?.cargo != null) {
              this.cargosFilter.push(element?.cargo);
            }
          }
  
          this.cargosFilter = Array.from(new Set(this.cargosFilter));

        }else{
          this.criteriosEvalTecnica = [];
          this.criteriosEvalTecnicaFiltered = [];
          this.cargosFilter = [];
        }
      },
      error => {
        this.criteriosEvalTecnica = [];
        this.criteriosEvalTecnicaFiltered = [];
        this.cargosFilter = [];
        this.notifyService.showError(error.error.message, 'Error al cargar ayuda');
      }
    );
  }

  eliminarSubgrupoEvalTec(correlativo: number){
    this.subgrupoCorrelativoSeleccionado = correlativo;
    this.subgrupoSeleccionado = this.estandarSubgrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '';
  }

  onDeleteSubgrupoEvalTec(){
    const postData = {
      correlativo: this.subgrupoCorrelativoSeleccionado
    };

    this.licitacionService.postDeleteSubgrupoEvalTecnica(postData).subscribe(
      resp => {
        this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorSubgrupoMantenedor(this.subgrupoEstandarEvalTecSeleccionado).subscribe(
          resp=>{
            const subgruposEstandar: any[] = JSON.parse(resp);
            let sumatoriaSubgrupoPeso: number = 0;
    
            if (subgruposEstandar.length > 0) {
              this.estandarSubgrupoEvalTec = subgruposEstandar;
              this.estandarSubgrupoEvalTecFiltered = subgruposEstandar;

              for (let index = 0; index < subgruposEstandar.length; index++) {
                const element = subgruposEstandar[index];
                
                sumatoriaSubgrupoPeso += element.peso;
              }
    
              if (sumatoriaSubgrupoPeso == 100) {
                this.subgrupoValidado = true;
              }else{
                this.subgrupoValidado = false;
              }
            }else{
              this.estandarSubgrupoEvalTec = [];
              this.estandarSubgrupoEvalTecFiltered = [];
            }

            this.notifyService.showWarning('Subgrupo eliminado correctamente.', 'Correcto');
            this.closeModalEliminarSubgrupoEvalTec.nativeElement.click();
          },
          error => {
            this.estandarSubgrupoEvalTec = [];
            this.estandarSubgrupoEvalTecFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener los subgrupos de estandares');
          }
        );
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al eliminar subgrupo');
      }
    );
  }

  onUpdateSubgrupoEvalTec(){
    if (this.formUpdateSubgrupoEvalTec.invalid) {
      this.notifyService.showWarning("Por favor, complete todos los campos requeridos.", "Advertencia");
      return;
    }

    const postData = {
      subgrupoCorrelativo: this.subgrupoCorrelativoSeleccionado,
      subgrupoDescripcion: this.formUpdateSubgrupoEvalTec.value.subgrupoDescripcion,
      subgrupoPeso: this.formUpdateSubgrupoEvalTec.value.subgrupoPeso,
      subgrupoAbierto: this.formUpdateSubgrupoEvalTec.value.subgrupoAbierto,
      subgrupoTipo: this.formUpdateSubgrupoEvalTec.value.subgrupoTipo,
      subgrupoIdAyuda: this.formUpdateSubgrupoEvalTec.value.subgrupoIdAyuda,
      subgrupoLimiteRespuesta: this.formUpdateSubgrupoEvalTec.value.subgrupoLimiteRespuesta,
      subgrupoObservacion: this.formUpdateSubgrupoEvalTec.value.subgrupoObservacion
    };

    this.licitacionService.postUpdateSubgrupoEvalTecnica(postData).subscribe(
      resp => {
        this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorSubgrupoMantenedor(this.subgrupoEstandarEvalTecSeleccionado).subscribe(
          resp=>{
            const subgruposEstandar: any[] = JSON.parse(resp);
            let sumatoriaSubgrupoPeso: number = 0;
    
            if (subgruposEstandar.length > 0) {
              this.estandarSubgrupoEvalTec = subgruposEstandar;
              this.estandarSubgrupoEvalTecFiltered = subgruposEstandar;

              for (let index = 0; index < subgruposEstandar.length; index++) {
                const element = subgruposEstandar[index];
                
                sumatoriaSubgrupoPeso += element.peso;
              }
    
              if (sumatoriaSubgrupoPeso == 100) {
                this.subgrupoValidado = true;
              }else{
                this.subgrupoValidado = false;
              }
            }else{
              this.estandarSubgrupoEvalTec = [];
              this.estandarSubgrupoEvalTecFiltered = [];
            }
          },
          error => {
            this.estandarSubgrupoEvalTec = [];
            this.estandarSubgrupoEvalTecFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener los subgrupos de estandares');
          }
        );

        this.closeModalUpdateSubgrupoEvalTec.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error');
      }
    );
  }

  onCreateSubgrupoEvalTec(){
    if (this.formCreateSubgrupoEvalTec.invalid) {
      this.notifyService.showWarning("Por favor, complete todos los campos requeridos.", "Advertencia");
      return;
    }

    const postData = {
      grupoCorrelativo: this.subgrupoEstandarEvalTecSeleccionado,
      subgrupoDescripcion: this.formCreateSubgrupoEvalTec.value.subgrupoDescripcion,
      subgrupoPeso: this.formCreateSubgrupoEvalTec.value.subgrupoPeso,
      subgrupoAbierto: this.formCreateSubgrupoEvalTec.value.subgrupoAbierto,
      subgrupoTipo: this.formCreateSubgrupoEvalTec.value.subgrupoTipo,
      subgrupoIdAyuda: this.formCreateSubgrupoEvalTec.value.subgrupoIdAyuda,
      subgrupoLimiteRespuesta: this.formCreateSubgrupoEvalTec.value.subgrupoLimiteRespuesta,
      subgrupoObservacion: this.formCreateSubgrupoEvalTec.value.subgrupoObservacion,
    };

    this.licitacionService.postInsertSubgrupoEvalTecnica(postData).subscribe(
      resp=>{
        this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorSubgrupoMantenedor(this.subgrupoEstandarEvalTecSeleccionado).subscribe(
          resp=>{
            const subgruposEstandar: any[] = JSON.parse(resp);
            let sumatoriaSubgrupoPeso: number = 0;
    
            if (subgruposEstandar.length > 0) {
              this.estandarSubgrupoEvalTec = subgruposEstandar;
              this.estandarSubgrupoEvalTecFiltered = subgruposEstandar;

              for (let index = 0; index < subgruposEstandar.length; index++) {
                const element = subgruposEstandar[index];
                
                sumatoriaSubgrupoPeso += element.peso;
              }
    
              if (sumatoriaSubgrupoPeso == 100) {
                this.subgrupoValidado = true;
              }else{
                this.subgrupoValidado = false;
              }
            }else{
              this.estandarSubgrupoEvalTec = [];
              this.estandarSubgrupoEvalTecFiltered = [];
            }
          },
          error => {
            this.estandarSubgrupoEvalTec = [];
            this.estandarSubgrupoEvalTecFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener los subgrupos de estandares');
          }
        );
        this.closeModalCrearSubgrupoEvalTec.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al insertar subgrupo de evaluacion tecnica');
      }
    );
  }

  resetFormCreateSubgrupoEvalTec(){
    this.formCreateSubgrupoEvalTec = new FormGroup({
      subgrupoDescripcion: new FormControl('', Validators.required),
      subgrupoPeso: new FormControl(null, Validators.required),
      subgrupoAbierto: new FormControl(1, Validators.required),
      subgrupoTipo: new FormControl(1, Validators.required),
      subgrupoIdAyuda: new FormControl(null, Validators.required),
      subgrupoLimiteRespuesta: new FormControl(1, Validators.required),
      subgrupoObservacion: new FormControl('', Validators.required),
    });

    this.resetAyudasEvalTecnica();
  }

  resetFormCreateGrupoEvalTec(){
    this.formCreateEstandarGrupoEvalTec = new FormGroup({
      descripcion: new FormControl('', Validators.required),
      peso: new FormControl(null, Validators.required),
    });
  }

  editarGrupoEvalTec(correlativo: number){
    this.grupoSeleccionado = this.estandarGrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '';
    this.grupoCorrelativoSeleccionado = correlativo;

    this.formUpdateEstandarGrupoEvalTec = new FormGroup({
      descripcion: new FormControl(this.estandarGrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '', Validators.required),
      peso: new FormControl(this.estandarGrupoEvalTec.filter(data => data.correlativo === correlativo)[0].peso ?? 0, Validators.required),
      vigente: new FormControl(this.estandarGrupoEvalTec.filter(data => data.correlativo === correlativo)[0].vigente ?? 0, Validators.required),
    });
  }

  eliminarGrupoEvalTec(correlativo: number){
    this.grupoSeleccionado = this.estandarGrupoEvalTec.filter(data => data.correlativo === correlativo)[0].descripcion ?? '';
    this.grupoCorrelativoSeleccionado = correlativo;
  }

  onCreateGrupoEvalTec(){
    if (this.formCreateEstandarGrupoEvalTec.invalid) {
      this.notifyService.showWarning("Por favor, complete todos los campos requeridos.", "Advertencia");
      return;
    }

    const postData = {
      correlativo_estandar_eval_tec: this.grupoEstandarEvalTecSeleccionado,
      descripcion: this.formCreateEstandarGrupoEvalTec.value.descripcion,
      peso: this.formCreateEstandarGrupoEvalTec.value.peso
    };

    this.licitacionService.postInsertGrupoEvalTecnica(postData).subscribe(
      resp=>{
        this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorGrupoMantenedor(this.grupoEstandarEvalTecSeleccionado).subscribe(
          resp=>{
            const gruposEstandar: any[] = JSON.parse(resp);
            let sumatoriaPesoGrupos: number = 0;
    
            if (gruposEstandar.length > 0) {
              this.estandarGrupoEvalTec = gruposEstandar;
              this.estandarGrupoEvalTecFiltered = gruposEstandar;

              for (let index = 0; index < gruposEstandar.length; index++) {
                const element = gruposEstandar[index];
                sumatoriaPesoGrupos = sumatoriaPesoGrupos + element.peso;
              }
    
              if (sumatoriaPesoGrupos == 100) {
                this.grupoValidado = true;
              }else{
                this.grupoValidado = false;
              }
            }else{
              this.estandarGrupoEvalTec = [];
              this.estandarGrupoEvalTecFiltered = [];
            }
          },
          error => {
            this.estandarGrupoEvalTec = [];
            this.estandarGrupoEvalTecFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener los grupos de estandares');
          }
        );
        this.closeModalCrearGrupoEvalTec.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al insertar estandar evaluacion tecnica');
      }
    );
  }

  onUpdateGrupoEvalTec(){
    if (this.formUpdateEstandarGrupoEvalTec.invalid) {
      this.notifyService.showWarning("Por favor, complete todos los campos requeridos.", "Advertencia");
      return;
    }

    const postData = {
      grupoDescricion: this.formUpdateEstandarGrupoEvalTec.value.descripcion,
      peso: this.formUpdateEstandarGrupoEvalTec.value.peso,
      vigente: this.formUpdateEstandarGrupoEvalTec.value.vigente,
      correlativo: this.grupoCorrelativoSeleccionado
    };

    this.licitacionService.postUpdateGrupoEvalTecnica(postData).subscribe(
      resp=>{
        this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorGrupoMantenedor(this.grupoEstandarEvalTecSeleccionado).subscribe(
          resp=>{
            const gruposEstandar: any[] = JSON.parse(resp);
            let sumatoriaPesoGrupos: number = 0;
    
            if (gruposEstandar.length > 0) {
              this.estandarGrupoEvalTec = gruposEstandar;
              this.estandarGrupoEvalTecFiltered = gruposEstandar;

              for (let index = 0; index < gruposEstandar.length; index++) {
                const element = gruposEstandar[index];
                sumatoriaPesoGrupos = sumatoriaPesoGrupos + element.peso;
              }
    
              if (sumatoriaPesoGrupos == 100) {
                this.grupoValidado = true;
              }else{
                this.grupoValidado = false;
              }
            }else{
              this.estandarGrupoEvalTec = [];
              this.estandarGrupoEvalTecFiltered = [];
            }
          },
          error => {
            this.estandarGrupoEvalTec = [];
            this.estandarGrupoEvalTecFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener los grupos de estandares');
          }
        );
        this.closeModalUpdateGrupoEvalTec.nativeElement.click();
    });
  }

  onDeleteGrupoEvalTec(){
    const postData = {
      correlativo: this.grupoCorrelativoSeleccionado
    };

    this.licitacionService.postDeleteGrupoEvalTecnica(postData).subscribe(
      resp=>{
        this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorGrupoMantenedor(this.grupoEstandarEvalTecSeleccionado).subscribe(
          resp=>{
            const gruposEstandar: any[] = JSON.parse(resp);
            let sumatoriaPesoGrupos: number = 0;
    
            if (gruposEstandar.length > 0) {
              this.estandarGrupoEvalTec = gruposEstandar;
              this.estandarGrupoEvalTecFiltered = gruposEstandar;

              for (let index = 0; index < gruposEstandar.length; index++) {
                const element = gruposEstandar[index];
                sumatoriaPesoGrupos = sumatoriaPesoGrupos + element.peso;
              }
    
              if (sumatoriaPesoGrupos == 100) {
                this.grupoValidado = true;
              }else{
                this.grupoValidado = false;
              }
            }else{
              this.estandarGrupoEvalTec = [];
              this.estandarGrupoEvalTecFiltered = [];
            }
          },
          error => {
            this.estandarGrupoEvalTec = [];
            this.estandarGrupoEvalTecFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener los grupos de estandares');
          }
        );
        this.closeModalEliminarGrupoEvalTec.nativeElement.click();
    });

  }

  getEstandaresEvalTecnicaMantenedor(){
    this.licitacionService.getEstandaresEvalTecnicaMantenedor().subscribe(
      resp=>{
        const estandaresEvalTecnica: any[] = JSON.parse(resp);

        if (estandaresEvalTecnica.length > 0) {
          this.estandaresEvaluacionTecnicaMantenedor = estandaresEvalTecnica;
          this.estandaresEvaluacionTecnicaMantenedorFiltered = estandaresEvalTecnica;
        }else{
          this.estandaresEvaluacionTecnicaMantenedor = [];
          this.estandaresEvaluacionTecnicaMantenedorFiltered = [];
        }
      },
      error => {
        this.estandaresEvaluacionTecnicaMantenedor = [];
        this.estandaresEvaluacionTecnicaMantenedorFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener los motivos de renuncia');
      }
    );
  }

  verGruposEstandar(correlativo: number){
    this.stepEstandarEvalTecSeleccionado = 1;
    this.grupoEstandarEvalTecSeleccionado = correlativo;
    this.estadarSeleccionado = this.estandaresEvaluacionTecnicaMantenedor.filter(data => data.correlativo === correlativo)[0].descripcion_estandar ?? '';

    this.licitacionService.getSelectEstandaresEvalTecnicaMantenedorGrupoMantenedor(correlativo).subscribe(
      resp=>{
        const gruposEstandar: any[] = JSON.parse(resp);
        let sumatoriaPesoGrupos: number = 0;

        if (gruposEstandar.length > 0) {
          this.estandarGrupoEvalTec = gruposEstandar;
          this.estandarGrupoEvalTecFiltered = gruposEstandar;

          for (let index = 0; index < gruposEstandar.length; index++) {
            const element = gruposEstandar[index];
            sumatoriaPesoGrupos = sumatoriaPesoGrupos + element.peso;
          }

          if (sumatoriaPesoGrupos == 100) {
            this.grupoValidado = true;
          }else{
            this.grupoValidado = false;
          }
        }else{
          this.estandarGrupoEvalTec = [];
          this.estandarGrupoEvalTecFiltered = [];
        }
      },
      error => {
        this.estandarGrupoEvalTec = [];
        this.estandarGrupoEvalTecFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener los grupos de estandares');
      }
    );
  }

  resetFormCreateEstandarEvalTec(){
    this.formCreateEstandarEvalTec = new FormGroup({
      descripcionEstandar: new FormControl('', Validators.required)
    });
  }

  editarEstandarEvalTec(correlativo: number){
    this.estadarSeleccionado = this.estandaresEvaluacionTecnicaMantenedor.filter(data => data.correlativo === correlativo)[0].descripcion_estandar ?? '';
    this.correlativoEstandarEvalTecSeleccionado = correlativo;

    this.formUpdateEstandarEvalTec = new FormGroup({
      descripcionEstandar: new FormControl(this.estandaresEvaluacionTecnicaMantenedor.filter(data => data.correlativo === correlativo)[0].descripcion_estandar ?? '', Validators.required),
      vigente: new FormControl(this.estandaresEvaluacionTecnicaMantenedor.filter(data => data.correlativo === correlativo)[0].vigente ?? 0, Validators.required),
    });
  }

  eliminarEstandarEvalTec(correlativo: number){
    this.estadarSeleccionado = this.estandaresEvaluacionTecnicaMantenedor.filter(data => data.correlativo === correlativo)[0].descripcion_estandar ?? '';
    this.correlativoEstandarEvalTecSeleccionado = correlativo;
  }

  onCreateEstandarEvalTec(){

    if(this.formCreateEstandarEvalTec.invalid){
      this.notifyService.showWarning('Todos los campos son obligatorios', 'Advertencia');
      return;
    }

    const postData = {
      descripcion_estandar: this.formCreateEstandarEvalTec.value.descripcionEstandar
    };

    this.licitacionService.postInsertEstandarEvalTecnica(postData).subscribe(
      resp=>{
        this.getEstandaresEvalTecnicaMantenedor();
        this.closeModalCrearEstandarEvalTec.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al insertar estandar evaluacion tecnica');
      }
    );
  }

  onUpdateEstandarEvalTec(){
    if(this.formUpdateEstandarEvalTec.invalid){
      this.notifyService.showWarning('Todos los campos son obligatorios', 'Advertencia');
      return;
    }

    const postData = {
      estandarDescricion: this.formUpdateEstandarEvalTec.value.descripcionEstandar,
      vigente: this.formUpdateEstandarEvalTec.value.vigente,
      correlativo: this.correlativoEstandarEvalTecSeleccionado
    };

    this.licitacionService.postUpdateEstandarEvalTecnica(postData).subscribe(
      resp=>{
        this.getEstandaresEvalTecnicaMantenedor();
        this.closeModalUpdateEstandarEvalTec.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al actualizar estandar evaluacion tecnica');
      }
    );
  }

  onDeleteEstandarEvalTec(){
    const postData = {
      correlativo: this.correlativoEstandarEvalTecSeleccionado
    };

    this.licitacionService.postDeleteEstandarEvalTecnica(postData).subscribe(
      resp=>{
        this.getEstandaresEvalTecnicaMantenedor();
        this.closeModalEliminarEstandarEvalTec.nativeElement.click();
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al eliminar estandar evaluacion tecnica');
      }
    );
  }

  applyFilterEstandarSubGrupoEvalTec(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.estandarSubgrupoEvalTecFiltered = this.estandarSubgrupoEvalTec.filter(data => {
        return (
          (
            data?.correlativo.toString().toLowerCase().includes(filterValue) || 
            data?.descripcion.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.estandarSubgrupoEvalTecFiltered = this.estandarSubgrupoEvalTec;
    }
  }

  applyFilterEstandarGrupoEvalTec(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.estandarGrupoEvalTecFiltered = this.estandarGrupoEvalTec.filter(data => {
        return (
          (
            data?.correlativo.toString().toLowerCase().includes(filterValue) || 
            data?.descripcion.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.estandarGrupoEvalTecFiltered = this.estandarGrupoEvalTec;
    }
  }

  applyFilterEstandaresEvalTecnica(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.estandaresEvaluacionTecnicaMantenedorFiltered = this.estandaresEvaluacionTecnicaMantenedor.filter(data => {
        return (
          (
            data?.correlativo.toString().toLowerCase().includes(filterValue) || 
            data?.descripcion_estandar.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.estandaresEvaluacionTecnicaMantenedorFiltered = this.estandaresEvaluacionTecnicaMantenedor;
    }
  }

  onChangeAyuda(event: any){
    this.ayudaEvalTecnicaSeleccionada = event.target.value;
    this.cargosFilter = [];
    this.licitacionService.getAyudaEvalTecnicaCriteriosMantenedor(event.target.value).subscribe(
      resp=>{
        const criteriosRelacionadosAyuda: any[] = JSON.parse(resp);

        if (criteriosRelacionadosAyuda.length > 0) {
          this.criteriosEvalTecnica = Array.from(new Set(criteriosRelacionadosAyuda));
          this.criteriosEvalTecnicaFiltered = Array.from(new Set(criteriosRelacionadosAyuda));

          for (let index = 0; index < this.criteriosEvalTecnica.length; index++) {
            const element = this.criteriosEvalTecnica[index];
            
            if (element?.cargo != '' && element?.cargo != null) {
              this.cargosFilter.push(element?.cargo);
            }
          }
  
          this.cargosFilter = Array.from(new Set(this.cargosFilter));

        }else{
          this.criteriosEvalTecnica = [];
          this.criteriosEvalTecnicaFiltered = [];
          this.cargosFilter = [];
        }
      },
      error => {
        this.criteriosEvalTecnica = [];
        this.criteriosEvalTecnicaFiltered = [];
        this.cargosFilter = [];
        this.notifyService.showError(error.error.message, 'Error al cargar ayuda');
      }
    );
  }

  applyFilterAyudasEvalTecnica(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.criteriosEvalTecnicaFiltered = this.criteriosEvalTecnica.filter(data => {
        return (
          (
            data?.descripcion.toString().toLowerCase().includes(filterValue) || 
            data?.cargo.toString().toLowerCase().includes(filterValue) ||
            data?.nota.toString().toLowerCase().includes(filterValue) ||
            data?.perfil_requerido.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.criteriosEvalTecnicaFiltered = this.criteriosEvalTecnica;
    }
  }

  selectCargo(cargo: string, event: any){
    event.preventDefault();

    if(cargo != ''){
      this.criteriosEvalTecnicaFiltered = this.criteriosEvalTecnica.filter(data => {
        return (
          (
            data?.cargo.toString().toLowerCase().includes(cargo.toLowerCase())
          )
        );
      });
    }
    else{
      this.criteriosEvalTecnicaFiltered = this.criteriosEvalTecnica;
    }
  }

  resetAyudasEvalTecnica(){
    this.ayudaEvalTecnicaSeleccionada = 0;
    this.criteriosEvalTecnica = [];
    this.criteriosEvalTecnicaFiltered = [];
  }

  onCrearAyudaEvalTecnica(event: any){
    event.preventDefault();

    if (this.formCreateAyudaEvalTec.invalid) {
      this.notifyService.showWarning('Debe completar los campos necesarios.', 'Crear ayuda');
      return;
    }

    const createAyudaEvalTecnica = {
      in_descripcion: this.formCreateAyudaEvalTec.controls.descripcion.value,
    }

    this.licitacionService.postCreateAyudaEvalTecnica(createAyudaEvalTecnica).subscribe(resp=>{
        // cargar de nuevo las ayudas pero selccionando por defecto la que se creo
        this.licitacionService.getAyudaEvalTecnica().subscribe(
          resp=>{
            const ayudaEvalTecnica: any[] = JSON.parse(resp);
    
            if (ayudaEvalTecnica.length > 0) {
              this.ayudasEvalTecnica = Array.from(new Set(ayudaEvalTecnica));
              this.formCreateSubgrupoEvalTec.controls.subgrupoIdAyuda.setValue(this.ayudasEvalTecnica[0].correlativo);
              this.ayudaEvalTecnicaSeleccionada = this.ayudasEvalTecnica[0].correlativo;
              this.criteriosEvalTecnica = [];
              this.criteriosEvalTecnicaFiltered = [];
              this.formCreateAyudaEvalTec.reset();
            }else{
              this.ayudasEvalTecnica = [];
              this.criteriosEvalTecnica = [];
              this.criteriosEvalTecnicaFiltered = [];
            }
          },
          error => {
            this.ayudasEvalTecnica = [];
            this.criteriosEvalTecnica = [];
            this.criteriosEvalTecnicaFiltered = [];
            this.notifyService.showError(error.error.message, 'Error al obtener ayuda de evaluación técnica');
          }
        );

        this.accionesAyudaEvalTecnica = 0;
        this.notifyService.showSuccess('Crear', 'Ayuda Evaluación Técnica creada con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al crear ayuda de evaluación técnica');
      }
    );
  }

  getNotasEvaluacionTecnica(){
    this.licitacionService.getNotasEvalTecnica().subscribe(resp=>{
      const notasEvaluacionTecnica: NotasEvalTecnica[] = JSON.parse(resp);
      console.log(notasEvaluacionTecnica);
      if (notasEvaluacionTecnica.length > 0) {
        this.notasEvaluacionTecnica = notasEvaluacionTecnica;
      }else{
        this.notasEvaluacionTecnica = [];
      }
    });
  }

  resetFormCreateAyudaEvalTecnicaCriterio(){
    this.formCreateAyudaCriterio = new FormGroup({
      descripcion: new FormControl('', [Validators.required]),
      cargo: new FormControl(''),
      perfil_requerido: new FormControl(''),
      nota: new FormControl(this.notasEvaluacionTecnica[0].correlativo),
    });
  }

  onCrearAyudaEvalTecnicaCriterio(event: any){
    event.preventDefault();

    if (this.formCreateAyudaCriterio.invalid) {
      this.notifyService.showWarning('Debe completar al menos una descripción', 'Advertencia');
      return;
    }

    const ayudaEvalTecnicaCriterio = {
      in_idAyuda: this.ayudaEvalTecnicaSeleccionada,
      in_descripcion: this.formCreateAyudaCriterio.controls.descripcion.value,
      correlativo_nota: this.formCreateAyudaCriterio.controls.nota.value,
      in_grupo: '',
      in_item: '',
      in_cargo: this.formCreateAyudaCriterio.controls.cargo.value,
      in_ponderador: '',
      in_perfil_requerido: this.formCreateAyudaCriterio.controls.perfil_requerido.value,
    }

    this.licitacionService.createAyudaEvalTecnicaCriterios(ayudaEvalTecnicaCriterio).subscribe(
      resp => {
        this.cargosFilter = [];
        this.licitacionService.getAyudaEvalTecnicaCriteriosMantenedor(this.ayudaEvalTecnicaSeleccionada).subscribe(
          resp=>{
            const criteriosRelacionadosAyuda: any[] = JSON.parse(resp);

            if (criteriosRelacionadosAyuda.length > 0) {
              this.criteriosEvalTecnica = Array.from(new Set(criteriosRelacionadosAyuda));
              this.criteriosEvalTecnicaFiltered = Array.from(new Set(criteriosRelacionadosAyuda));

              for (let index = 0; index < this.criteriosEvalTecnica.length; index++) {
                const element = this.criteriosEvalTecnica[index];
                
                if (element?.cargo != '' && element?.cargo != null) {
                  this.cargosFilter.push(element?.cargo);
                }
              }
      
              this.cargosFilter = Array.from(new Set(this.cargosFilter));
              this.accionesAyudaEvalTecnica = 0;
            }else{
              this.criteriosEvalTecnica = [];
              this.criteriosEvalTecnicaFiltered = [];
              this.cargosFilter = [];
            }
          },
          error => {
            this.criteriosEvalTecnica = [];
            this.criteriosEvalTecnicaFiltered = [];
            this.cargosFilter = [];
            this.notifyService.showError(error.error.message, 'Error al cargar ayuda');
          }
        );  

        this.notifyService.showSuccess('Crear', 'Criterio de ayuda creado con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al crear criterio de ayuda de evaluación técnica');
      }
    );
  }

  editarAyudaEvalTecnicaCriterio(correlativoAyudaCriterio: number, event: any){
    event.preventDefault();
    
    this.criterioAyudaSeleccionado = correlativoAyudaCriterio;
    const ayudaEvalTecnicaCriterio: AyudaEvalTecnicaCriterio = this.criteriosEvalTecnica.filter(data => data.correlativo === correlativoAyudaCriterio)[0];

    this.criterioAyudaSeleccionadoStr = ayudaEvalTecnicaCriterio?.descripcion;

    this.formUpdateAyudaCriterio.reset();
    this.formUpdateAyudaCriterio.controls.descripcion.setValue(ayudaEvalTecnicaCriterio.descripcion);
    this.formUpdateAyudaCriterio.controls.cargo.setValue(ayudaEvalTecnicaCriterio?.cargo);
    this.formUpdateAyudaCriterio.controls.perfil_requerido.setValue(ayudaEvalTecnicaCriterio?.perfil_requerido);
    this.formUpdateAyudaCriterio.controls.nota.setValue(ayudaEvalTecnicaCriterio?.correlativo_nota);

    this.accionesAyudaEvalTecnica = 3;
  }

  eliminarAyudaEvalTecnicaCriterio(correlativoAyudaCriterio: number, event: any){
    event.preventDefault();

    const ayudaEvalTecnicaCriterio: AyudaEvalTecnicaCriterio = this.criteriosEvalTecnica.filter(data => data.correlativo === correlativoAyudaCriterio)[0];

    this.criterioAyudaSeleccionadoStr = ayudaEvalTecnicaCriterio?.descripcion;

    this.criterioAyudaSeleccionado = correlativoAyudaCriterio;
    this.accionesAyudaEvalTecnica = 4;
  }

  onEliminarAyudaEvalTecnicaCriterio(event: any){
    event.preventDefault();

    this.licitacionService.deleteAyudaEvalTecnicaCriterios({in_correlativo: this.criterioAyudaSeleccionado}).subscribe(resp=>{
        this.cargosFilter = [];
        this.licitacionService.getAyudaEvalTecnicaCriteriosMantenedor(this.ayudaEvalTecnicaSeleccionada).subscribe(
          resp=>{
            const criteriosRelacionadosAyuda: any[] = JSON.parse(resp);

            if (criteriosRelacionadosAyuda.length > 0) {
              this.criteriosEvalTecnica = Array.from(new Set(criteriosRelacionadosAyuda));
              this.criteriosEvalTecnicaFiltered = Array.from(new Set(criteriosRelacionadosAyuda));

              for (let index = 0; index < this.criteriosEvalTecnica.length; index++) {
                const element = this.criteriosEvalTecnica[index];
                
                if (element?.cargo != '' && element?.cargo != null) {
                  this.cargosFilter.push(element?.cargo);
                }
              }
      
              this.cargosFilter = Array.from(new Set(this.cargosFilter));
              this.accionesAyudaEvalTecnica = 0;
            }else{
              this.criteriosEvalTecnica = [];
              this.criteriosEvalTecnicaFiltered = [];
              this.cargosFilter = [];
            }
          },
          error => {
            this.criteriosEvalTecnica = [];
            this.criteriosEvalTecnicaFiltered = [];
            this.cargosFilter = [];
            this.notifyService.showError(error.error.message, 'Error al cargar ayuda');
          }
        ); 
        this.notifyService.showSuccess('Eliminar', 'Criterio de ayuda de evaluación técnica eliminado');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al eliminar criterios de ayuda de evaluación técnica');
      }
    );
  }

  onEditarAyudaEvalTecnicaCriterio(event: any){
    event.preventDefault();

    if (this.formUpdateAyudaCriterio.invalid) {
      this.notifyService.showWarning('Debe completar los campos necesarios, al menos la descripcion y nota.', 'Actualizar ayuda');
      return;
    }

    const updateAyudaEvalTecnicaCriterio = {
      in_correlativo: this.criterioAyudaSeleccionado,
      in_idAyuda: this.ayudaEvalTecnicaSeleccionada,
      in_descripcion: this.formUpdateAyudaCriterio.controls.descripcion.value,
      correlativo_nota: this.formUpdateAyudaCriterio.controls.nota.value,
      in_grupo: '',
      in_item: '',
      in_cargo: this.formUpdateAyudaCriterio.controls.cargo.value,
      in_ponderador: '',
      in_perfil_requerido: this.formUpdateAyudaCriterio.controls.perfil_requerido.value,
    }

    this.licitacionService.putAyudaEvalTecnicaCriterios(updateAyudaEvalTecnicaCriterio).subscribe(resp=>{
        this.licitacionService.getAyudaEvalTecnicaCriteriosMantenedor(this.ayudaEvalTecnicaSeleccionada).subscribe(
          resp=>{
            const criteriosRelacionadosAyuda: any[] = JSON.parse(resp);

            if (criteriosRelacionadosAyuda.length > 0) {
              this.criteriosEvalTecnica = Array.from(new Set(criteriosRelacionadosAyuda));
              this.criteriosEvalTecnicaFiltered = Array.from(new Set(criteriosRelacionadosAyuda));

              for (let index = 0; index < this.criteriosEvalTecnica.length; index++) {
                const element = this.criteriosEvalTecnica[index];
                
                if (element?.cargo != '' && element?.cargo != null) {
                  this.cargosFilter.push(element?.cargo);
                }
              }
      
              this.cargosFilter = Array.from(new Set(this.cargosFilter));
              this.accionesAyudaEvalTecnica = 0;
            }else{
              this.criteriosEvalTecnica = [];
              this.criteriosEvalTecnicaFiltered = [];
              this.cargosFilter = [];
            }
          },
          error => {
            this.criteriosEvalTecnica = [];
            this.criteriosEvalTecnicaFiltered = [];
            this.cargosFilter = [];
            this.notifyService.showError(error.error.message, 'Error al cargar ayuda');
          }
        ); 
        this.notifyService.showSuccess('Actualizar', 'Criterio de ayuda actualizado con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al actualizar criterio de ayuda de evaluación técnica');
      }
    );
  }
}
