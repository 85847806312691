import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-view-etapa-files-table',
  templateUrl: './view-etapa-files-table.component.html',
  styleUrls: ['./view-etapa-files-table.component.css']
})
export class ViewEtapaFilesTableComponent implements OnInit {
  @Input() set archivos(value: any){
    this.archivosFiltrados = value;
    this.filtrarArchivos();
  } get archvios(): any {
    return this.archivosFiltrados;
  }
  @Input() nombreEtapa: string;
  @Input() type: number = 0;
  userData;
  isAuth = false;
  private userSub: Subscription;
  idCargoActual: number = 0;
  archivosFiltrados: any;

  ngOnInit(): void {
    this.idCargoActual = JSON.parse(localStorage.getItem('userData')).rolId;

  }
  constructor(private objAuthService:AuthService)
  {

    this.userSub = this.objAuthService.user.subscribe(
      user =>{
        this.userData = JSON.parse(JSON.stringify(user));
      }
    );
  }

  filtrarArchivos(){
    if(this.userData.rolId !== 0){
      return this.archivosFiltrados;
    }
  }
}
