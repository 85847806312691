<div class="content" style="padding-bottom: 0;" *ngIf="!isChildren">

    <div class="row">
        <div class="col-6">
            <button type="button" class="btn btn-primary mr-4" (click)="back()"><i _ngcontent-ftv-c76=""
                    class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
        </div>
        <div class="col-6 text-right" *ngIf="validateRoleParticipanteComision(37) || validateRol()">
            <!-- <button type="button" class="btn btn-primary mr-4" (click)="back()"><i _ngcontent-ftv-c76=""
                class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button> -->
            <div *ngIf="objLicitacion?.ORDEN >= 14">
                <button *ngIf="objLicitacion" type="button" class="btn btn-primary" [disabled]="downloadingPdf"
                    style="margin-right: 10px" (click)="onDescargarPDF()">
                    <i class="fas fa-lg fa-fw m-r-10 fa-file-pdf"></i>
                    Descargar
                </button>
                <button type="button" class="btn btn-primary" (click)="exportExcelResultados()"
                    style="margin-left: 10px">
                    <i class="fas fa-lg fa-fw fa-file-excel"></i>
                    Exportar a Excel
                </button>
                <button *ngIf="validateEtapaVerBotonArchivos()" type="button" class="btn btn-primary"
                    (click)="onVerArchivos = !onVerArchivos;" style="margin-left: 10px">
                    <ng-container *ngIf="onVerArchivos">
                        <i class="fas fa-lg fa-fw fa-times"></i>
                        Ocultar archivos
                    </ng-container>
                    <ng-container *ngIf="!onVerArchivos">
                        <i class="fas fa-lg fa-fw fa-file"></i>
                        Ver archivos
                    </ng-container>
                </button>
                <button type="button" class="btn btn-primary" style="margin-left: 10px"
                    routerLink="/resultados/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">
                    <i class="fas fa-lg fa-fw m-r-10 fa-chart-bar"></i> Ofertas técnicas
                </button>
            </div>
            <div
                *ngIf="(objLicitacion?.ETAPA_CORRELATIVO == 18 || objLicitacion?.ETAPA_CORRELATIVO == 20) && validateRol()">
                <button type="button" class="btn btn-primary" style="margin-left: 10px"
                    routerLink="/resultados/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">
                    <i class="fas fa-lg fa-fw m-r-10 fa-chart-bar"></i> Ofertas técnicas
                </button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="!validateRoleParticipanteComision(37) || validateRol()">
    <br>
    <h4 class="text-muted text-center">Vista única de los responsables del proceso.</h4>
    <br>
</ng-container>

<div [ngClass]="{'content': !isChildren}" *ngIf="validateRoleParticipanteComision(37) || validateRol()">
    <div [ngClass]="{'bg-white p3-rem': !isChildren}" style="width: 100%">
        <div class="pt-0" *ngIf="!isChildren">
            <div class="row mt-0">
                <div class="col-12">
                    <h3 class="">{{objLicitacion?.CONT_NOMBRE}}</h3>
                    <!-- <p>{{licitacionData?.CONT_NUMERO}}</p> -->
                </div>
                <div class="col-12">
                    <span class="text-muted">
                        {{objLicitacion?.DESCRIPCION}}
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Licitación:
                        </span> {{idLicitacion}}</span>
                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Solpe: </span>
                        {{objLicitacion?.SOLPE_CORRELATIVO}}</span>
                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Contrato: </span>
                        {{objLicitacion?.CONT_NUMERO}}</span>
                    <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Moneda: </span>
                        {{objLicitacion?.DESCRIPCION_MONEDA_SOLPE}}</span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="onVerArchivos === true">
            <br>
            <hr>
            <h4 class="text-muted">Archivos de la licitación</h4>
            <table style="margin-top: 10px;" id="table_archivos" class="table table-striped table-td-valign-middle">
                <thead class="thead-dark">
                    <tr>
                        <th class="text-nowrap">Nombre archivo</th>
                        <th class="text-nowrap">Descripción</th>
                        <th class="text-nowrap">Etapa</th>
                        <th class="text-nowrap">Fecha creación</th>
                        <th class="text-nowrap">Subido</th>
                        <th class="text-nowrap"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd gradeX" *ngFor="let item of archivosLicitacion">
                        <td class="text-break">
                            <i class="fas fa-file text-primary mr-2"></i>
                            <span>
                                {{item.DESCRIPCION}}
                            </span>
                        </td>
                        <td class="text-break">
                            <span>
                                {{item.DESCRIPCION_ARCHIVO}}
                            </span>
                        </td>
                        <td class="text-break">
                            <span class="text-muted">
                                {{item.ETAPA_DESCRIPCION}}
                            </span>
                        </td>
                        <td>
                            <span class="text-muted">
                                {{item.FECHA_ARCHIVO }}
                            </span>
                        </td>
                        <td>
                            <span class="text-muted">
                                {{item.USUARIO}}
                            </span>
                            <!-- <ng-template [ngIf]="item.TIPO_ARCHIVO"> - </ng-template>
                            {{item.NOMBRE_ARCHIVO.split('/').pop()}} -->
                        </td>
                        <td class="text-center">
                            <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <!-- Seccion de aporbacion por el JAC, la etapa solamente se podra avanzar por esta pagina y solo el JAC la debe poder avanzar -->
        <ng-container *ngIf="objLicitacion?.ORDEN >= 12">
            <ng-container *ngIf="validacionJac === 0">
                <br>
                <hr>
                <h4><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle text-danger"></i> Se requiere la validación
                    del JAC para la apertura de las ofertas económicas</h4>
                <br>
                <ng-container *ngIf="obsevacionJac !== ''">
                    <h5>Última observación rechazo:</h5>
                    <p class="text-muted">{{obsevacionJac}}</p>
                </ng-container>
                <br>
                <ng-container *ngIf="idRol == 15">
                    <div class="row">
                        <div class="col-12">
                            <button type="button" class="btn btn-primary mr-4" data-toggle="modal"
                                data-target="#aporbacionJacModal" (click)="valdiacionJac(1)">
                                <i class="fas fa-lg fa-fw m-r-10 fa-check"></i>
                                Validar apertura
                            </button>
                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                data-target="#aporbacionJacModal" (click)="valdiacionJac(0)">
                                <i class="fas fa-lg fa-fw m-r-10 fa-times"></i>
                                Rechazar apertura
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="validacionJac === 1">
                <br>
                <hr>
                <h4><i class="fas fa-lg fa-fw m-r-10 fa-check-circle text-success"></i> Apertura de ofertas económicas
                    validada por el JAC</h4>
            </ng-container>
        </ng-container>
        <!-- Seccion de aporbacion por el Riesgo control, la etapa solamente se podra avanzar por esta pagina y solo Riesgo control la debe poder avanzar -->
        <ng-container *ngIf="objLicitacion?.ORDEN >= 13">
            <ng-container *ngIf="validacionRc === 0">
                <br>
                <hr>
                <h4><i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle text-danger"></i> Se requiere la validación
                    de Riesgo Control para la apertura de las ofertas económicas</h4>
                <br>
                <ng-container *ngIf="obsevacionRc !== ''">
                    <h5>Última observación rechazo:</h5>
                    <p class="text-muted">{{obsevacionRc}}</p>
                </ng-container>
                <br>
                <ng-container *ngIf="idRol == 29">
                    <div class="row">
                        <div class="col-12">
                            <button type="button" class="btn btn-primary mr-4" data-toggle="modal"
                                data-target="#aporbacionRcModal" (click)="valdiacionRc(1)">
                                <i class="fas fa-lg fa-fw m-r-10 fa-check"></i>
                                Validar apertura
                            </button>
                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                data-target="#aporbacionRcModal" (click)="valdiacionRc(0)">
                                <i class="fas fa-lg fa-fw m-r-10 fa-times"></i>
                                Rechazar apertura
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="validacionRc === 1">
                <br>
                <hr>
                <h4><i class="fas fa-lg fa-fw m-r-10 fa-check-circle text-success"></i> Apertura de ofertas económicas
                    validada por Riesgo Control</h4>
            </ng-container>
        </ng-container>
        <div [ngClass]="{'pt-5': !isChildren}" *ngIf="showInfo">
            <table id="table_resultado" class="d-table" *ngIf="objItemizadoLs?.length > 0">
                <thead class="d-head">
                    <tr>
                        <ng-container class="d-cell" *ngFor="let header of headerResumen; let index = index">
                            <td class="d-cell" [ngClass]="{'text-right': index > 0}">
                                <span>
                                    {{header}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container class="d-cell" *ngFor="let parti of objParticipantes">
                            <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                <td *ngIf="parti.vigente == 'S'" class="d-cell text-right">
                                    <span [ngClass]="{'text-danger': parti.vigente == 'N'}">
                                        {{parti.RAZON_SOCIAL}}
                                    </span>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </thead>
                <ng-container *ngFor="let item of objItemizadoLs; let first = first; let i = index; let last = last">
                    <tr class="d-row">
                        <td class="d-cell">
                            <span>
                                {{item.pos}} {{item.descripcion}}
                            </span>
                        </td>
                        <td class="d-cell text-right">
                            <span>
                                {{calcularTotales(i) | number: '1.2'}}
                            </span>
                        </td>
                        <ng-container *ngFor="let parti of objParticipantes">
                            <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                <td class="d-cell text-right" *ngIf="parti.vigente == 'S'"
                                    [ngClass]="calcularTotales(i) <= calcularTotalesPart(i, parti.PART_CORRELATIVO) ? 'red':'green'">
                                    <!-- <span>
                                   {{convertirPorcentaje(((calcularTotalesPart(i, parti.PART_CORRELATIVO)*100)/calcularTotales(i))) | number: '1.0-0'}}%  ({{calcularTotales(i) - calcularTotalesPart(i, parti.PART_CORRELATIVO) | number: '1.0'}})
                                </span> -->
                                    <p class="mb-0"
                                        *ngIf="convertir(calcularTotalesPart(i, parti.PART_CORRELATIVO)) != 0">
                                        {{convertir(calcularTotalesPart(i, parti.PART_CORRELATIVO)) | number: '1.2'}}
                                        <br />
                                    </p>
                                    <p class="mb-0"
                                        *ngIf="convertir(calcularTotalesPart(i, parti.PART_CORRELATIVO)) != 0">
                                        {{(calcularTotales(i) - calcularTotalesPart(i, parti.PART_CORRELATIVO)) * -1 | number:
                                        '1.2'}}
                                        <br />
                                    </p>
                                    <p *ngIf="convertir(calcularTotalesPart(i, parti.PART_CORRELATIVO)) != 0">
                                        <!-- {{
                                        convertirPorcentaje(((calcularTotalesPart(i,
                                        parti.PART_CORRELATIVO)*100)/calcularTotales(i))) | number: '1.0-0'
                                        }} % -->
                                        {{
                                        convertirPorcentaje(((calcularTotalesPart(i,
                                        parti.PART_CORRELATIVO) - calcularTotales(i)) / calcularTotales(i)) * 100) |
                                        number: '1.0-0'
                                        }} %
                                        <i *ngIf="convertirPorcentaje(((calcularTotalesPart(i,
                                        parti.PART_CORRELATIVO) - calcularTotales(i)) / calcularTotales(i)) * 100) > 0"
                                            class="fa fa-arrow-up"></i>
                                        <i *ngIf="convertirPorcentaje(((calcularTotalesPart(i,
                                        parti.PART_CORRELATIVO) - calcularTotales(i)) / calcularTotales(i)) * 100) <= 0"
                                            class="fa fa-arrow-down"></i>
                                    </p>
                                    <span *ngIf="convertir(calcularTotalesPart(i, parti.PART_CORRELATIVO)) == 0">
                                        No enviado
                                    </span>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr *ngIf="last" class="d-row">
                        <td class="d-cell">
                            <span class="bold">
                                Total
                            </span>
                        </td>
                        <td class="d-cell text-right">
                            <span class="bold">
                                {{ this.Total | number: '1.2'}}
                            </span>
                        </td>
                        <ng-container *ngFor="let parti of objParticipantes">
                            <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                <td class="d-cell text-right bold" *ngIf="parti.vigente == 'S'"
                                    [ngClass]="this.Total <= calcularPresupuestoPart(parti.PART_CORRELATIVO) ? 'red':'green'">
                                    <!-- <span>
                                    {{convertirPorcentaje(((calcularPresupuestoPart(parti.PART_CORRELATIVO)*100)/this.Total)) | number: '1.0-0'}}%  ({{convertir(this.Total - calcularPresupuestoPart(parti.PART_CORRELATIVO)) | number: '1.0'}})
                                </span> -->
                                    <p class="mb-0"
                                        *ngIf="convertir(calcularPresupuestoPart(parti.PART_CORRELATIVO)) != 0">
                                        {{convertir(calcularPresupuestoPart(parti.PART_CORRELATIVO)) | number: '1.2'}}
                                        <br />
                                    </p>
                                    <p class="mb-0"
                                        *ngIf="convertir(calcularPresupuestoPart(parti.PART_CORRELATIVO)) != 0">
                                        {{ (convertir(this.Total - calcularPresupuestoPart(parti.PART_CORRELATIVO)) * -1) |
                                        number:
                                        '1.2'}}
                                        <br />
                                    </p>
                                    <p *ngIf="convertir(calcularPresupuestoPart(parti.PART_CORRELATIVO)) != 0">
                                        <!-- {{convertirPorcentaje(((calcularPresupuestoPart(parti.PART_CORRELATIVO)*100)/this.Total))
                                        | number: '1.0-0'}} % -->
                                        {{
                                        convertirPorcentaje(((calcularPresupuestoPart(parti.PART_CORRELATIVO) -
                                        this.Total) / this.Total) * 100)
                                        | number: '1.0-0'
                                        }} %
                                        <i *ngIf="convertirPorcentaje(((calcularPresupuestoPart(parti.PART_CORRELATIVO) -
                                        this.Total) / this.Total) * 100) > 0" class="fa fa-arrow-up"></i>
                                        <i *ngIf="convertirPorcentaje(((calcularPresupuestoPart(parti.PART_CORRELATIVO) -
                                        this.Total) / this.Total) * 100) <= 0" class="fa fa-arrow-down"></i>
                                    </p>
                                    <span *ngIf="convertir(calcularPresupuestoPart(parti.PART_CORRELATIVO)) == 0">
                                        No enviado
                                    </span>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </table>
        </div>
        <div class="pt-5" *ngIf="showInfo">
            <div>
                <div class="">
                    <div *ngIf="objItemizadoLs?.length == 0">
                        Sin evaluaciones emitidas
                    </div>
                    <table id="table_detalle" class="d-table" *ngIf="objItemizadoLs?.length > 0">
                        <thead class="d-head">
                            <tr>
                                <ng-container class="d-cell" *ngFor="let header of headerDetalle; let index = index">
                                    <td class="d-cell" [ngClass]="{'text-right': index > 1}">
                                        <span>
                                            {{header}}
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container class="d-cell" *ngFor="let parti of objParticipantes">
                                    <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                        <td *ngIf="parti.vigente == 'S'" class="d-cell text-right">
                                            <span [ngClass]="{'text-danger': parti.vigente == 'N'}">
                                                {{parti.RAZON_SOCIAL}}
                                            </span>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </thead>
                        <ng-container *ngFor="let item of objItemizadoLs; let first = first">
                            <tr class="d-row bg-white grupo">
                                <td class="d-cell">
                                    <span class="bold">
                                        {{item.pos}} {{item.descripcion}}
                                    </span>
                                </td>
                                <ng-container class="d-cell"
                                    *ngFor="let header of headerDetalle; let first = first; let i = index; let last = last">
                                    <td class="d-cell" *ngIf="!last">
                                        <span>

                                        </span>
                                    </td>
                                </ng-container>
                            </tr>
                            <tr class="d-row bg-white" *ngFor="let detalle of item.itemizadoDetalleLs">
                                <td class="d-cell">
                                    <span class="">
                                        {{detalle.pos}} {{detalle.item}}
                                    </span>
                                </td>
                                <td class="d-cell">
                                    <span>
                                        {{detalle.unidad}}
                                    </span>
                                </td>
                                <td class="d-cell text-right">
                                    <span>
                                        {{detalle.cantidad | number: '1.2'}}
                                    </span>
                                </td>
                                <td class="d-cell text-right">
                                    <!-- <span>
                                        {{detalle.presupuesto * detalle.cantidad | number: '1.2'}}
                                        <span class="unitario">
                                            ({{detalle.presupuesto | number: '1.2'}} c/u)
                                        </span>
                                    </span> -->
                                    <p class="mb-0">
                                        {{detalle.presupuesto * detalle.cantidad | number: '1.2'}}
                                        <br />
                                    </p>
                                    <p>
                                        {{detalle.presupuesto | number: '1.2'}} c/u
                                    </p>
                                </td>
                                <ng-container *ngFor="let parti of objParticipantes">
                                    <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                        <td class="d-cell text-right" *ngIf="parti.vigente == 'S'">
                                            <!-- <span 
                                                *ngIf="detalle[parti.PART_CORRELATIVO] != null"
                                                [ngClass]="detalle.presupuesto <= detalle[parti.PART_CORRELATIVO] ? 'red':'green'"
                                            >
                                                {{ detalle[parti.PART_CORRELATIVO] * detalle.cantidad | number: '1.2'}}
                                                <span class="unitario">
                                                    ({{detalle[parti.PART_CORRELATIVO] | number: '1.2'}} c/u)
                                                </span>
                                            </span> -->
                                            <p *ngIf="detalle[parti.PART_CORRELATIVO] != null"
                                                [ngClass]="detalle.presupuesto <= detalle[parti.PART_CORRELATIVO] ? 'red':'green'"
                                                class="mb-0">
                                                {{ detalle[parti.PART_CORRELATIVO] * detalle.cantidad | number: '1.2'}}
                                                <br />
                                            </p>
                                            <p class="mb-0"
                                                [ngClass]="detalle.presupuesto <= detalle[parti.PART_CORRELATIVO] ? 'red':'green'"
                                                *ngIf="detalle[parti.PART_CORRELATIVO] != null">
                                                {{detalle[parti.PART_CORRELATIVO] | number: '1.2'}} c/u
                                                <br />
                                            </p>
                                            <p [ngClass]="detalle.presupuesto <= detalle[parti.PART_CORRELATIVO] ? 'red':'green'"
                                                *ngIf="detalle[parti.PART_CORRELATIVO] != null">
                                                {{ (((detalle[parti.PART_CORRELATIVO] - detalle.presupuesto) / detalle.presupuesto) * 100) |
                                                number:
                                                '1.0-0'}} %
                                                <i *ngIf="(((detalle[parti.PART_CORRELATIVO] - detalle.presupuesto) / detalle.presupuesto) * 100) > 0"
                                                    class="fa fa-arrow-up"></i>
                                                <i *ngIf="(((detalle[parti.PART_CORRELATIVO] - detalle.presupuesto) / detalle.presupuesto) * 100) <= 0"
                                                    class="fa fa-arrow-down"></i>
                                            </p>
                                            <span *ngIf="detalle[parti.PART_CORRELATIVO] == null">
                                                No enviado
                                            </span>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </ng-container>
                        <!-- <div class="d-head">
                            <ng-container class="d-cell" *ngFor="let key of getKeys(); let first = first">
                                <div *ngIf="first" class="d-cell">
                                    <span>
                                        Descripcion
                                    </span>
                                </div>
                                <div *ngIf="(key !== 'correlativo' && key !== 'grupo' && key !== 'peso' && key !== 'descripcion')" class="d-cell">
                                    <span>
                                        {{key}}
                                    </span>
                                </div>
                            </ng-container>
                        </div> -->

                        <!-- <ng-container *ngFor="let resultados of objEvaluadoresResultados">
                            <div class="d-row">
                                <div class="d-cell">
                                    <span>
                                        {{grupo.descripcion}} ({{grupo.peso}}%)
                                    </span>
                                </div>
                                <ng-container class="d-cell" *ngFor="let item of grupo | keyvalue">
                                    <div class="d-cell" *ngIf="(item.key !== 'correlativo' && item.key !== 'grupo' && item.key !== 'peso' && item.key !== 'descripcion')">
                                        <span>
                                            {{item.value}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container> -->
                        <!-- <div class="d-row">
                            <ng-container class="d-cell" *ngFor="let key of getKeys(); let first = first">
                                <div *ngIf="first" class="d-cell">
                                    <span>
                                        Resultado
                                    </span>
                                </div>
                                <div *ngIf="(key !== 'correlativo' && key !== 'grupo' && key !== 'peso' && key !== 'descripcion')" class="d-cell">
                                    <span>
                                        {{calTotalEvaluadores(key) | number:'1.0-0'}}
                                    </span>
                                </div>
                            </ng-container>
                        </div> -->
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="objLicitacion?.ETAPA_CORRELATIVO == 12 && objetoValidateMontos == null" class="content">
    <div class="bg-white p3-rem">
        <div class="row">
            <div class="col-12">
                <p>
                    <i class="fas fa-lg fa-fw m-r-10 fa-exclamation-triangle text-danger"></i>
                    No se pude determinar
                    quien tiene el menor presupuesto. Por favor, seleccione manualmente al oferente que aprobara la
                    oferta económica.
                </p>
                <br>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Oferente</th>
                            <th class="text-right">Total Oferta Económica</th>
                            <th class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let oferente of oferentesSeleccionManual">
                            <td>{{oferente.razonSocial}}</td>
                            <td class="text-right">{{oferente.total | number: '1.0-0'}}</td>
                            <td class="text-center">
                                <button class="btn btn-primary" href="#modalGanador" data-toggle="modal"
                                    (click)="ParticipanteSelected = findOferenteSeleccionadoManual(oferente.correlativo)">
                                    Aprobar Oferta
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="content" *ngIf="!isChildren && (validateRoleParticipanteComision(37) || validateRol())">
    <div>
        <div class="row">
            <ng-container *ngFor="let parti of objParticipantes">
                <div *ngIf="parti.vigente == 'S'" class="col-12 col-md-4 mb-4">
                    <div class="p-30 bg-white rounded">
                        <div class="row border-bottom pt-0 pb-2">
                            <div class="col-8">
                                <ng-container *ngIf="filesPart(parti.PART_CORRELATIVO).length <= 0">
                                    <div *ngIf="parti.vigente == 'S'" class="rounded-icon icon-orange mr-2">
                                        <i class="far fa-envelope-open"></i>
                                    </div>
                                    <span *ngIf="parti.vigente == 'S'">Registrando información</span>
                                    <span *ngIf="parti.vigente == 'N'" class="text-danger"
                                        style="font-weight: bold;">Oferente Eliminado</span>
                                </ng-container>
                                <ng-container *ngIf="filesPart(parti.PART_CORRELATIVO).length > 0">
                                    <div *ngIf="parti.vigente == 'S'" class="rounded-icon icon-green mr-2">
                                        <i class="far fa-envelope"></i>
                                    </div>
                                    <span *ngIf="parti.vigente == 'S'">
                                        Oferta Económica Enviada
                                    </span>
                                    <span *ngIf="parti.vigente == 'N'" class="text-danger"
                                        style="font-weight: bold;">Oferente Eliminado</span>
                                </ng-container>
                            </div>
                            <div class="col-4">
                                <span>{{parti.FECHA_PRESUPUESTO}}</span>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row">
                            <div class="col-6">
                                <p class="m-0">Evaluación Técnica:</p>
                            </div>
                            <div class="col-6 text-right">
                                <span *ngIf="parti.APROBO_EVAL_TEC == 1" class="tab tab-green">Aprobada</span>
                                <span *ngIf="parti.APROBO_EVAL_TEC == 0" class="tab tab-red">
                                    No aprobada
                                </span>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-6">
                                <p class="m-0">Calificación:</p>
                            </div>
                            <div class="col-6 text-right">
                                <span *ngIf="parti.APROBO_EVAL_TEC == 1" class="tab tab-green">
                                    {{calTotal(parti.RAZON_SOCIAL) | number:'1.0-0'}}
                                </span>
                                <span *ngIf="parti.APROBO_EVAL_TEC == 0" class="tab tab-red">
                                    {{calTotal(parti.RAZON_SOCIAL) | number:'1.0-0'}}
                                </span>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-6">
                                <p class="m-0">Evaluación Económica:</p>
                            </div>
                            <div class="col-6 text-right">
                                <span *ngIf="parti.APROBO_EVAL_ECO == 1" class="tab tab-green">Aprobada</span>
                                <span *ngIf="parti.APROBO_EVAL_ECO == 0" class="tab tab-red">
                                    {{getEvalEconomicaTextStatus()}}
                                </span>
                            </div>
                        </div>
                        <hr>
                        <div class="row pt-4 pb-0">
                            <h4 class="col-12 mb-0">
                                {{parti.RAZON_SOCIAL}}
                            </h4>
                            <span class="col-12 text-muted">
                                RUT: {{parti.RUT}}
                            </span>
                            <span *ngIf="objLicitacion?.ORDEN >= 14 && parti.APROBO_EVAL_TEC == 1"
                                class="col-12 text-muted mt-2 bold">
                                Presupuesto ingresado: {{getPresupuestoIngresadoByOferente(parti.PART_CORRELATIVO) |
                                number:'1.2'}}
                            </span>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <ng-container *ngIf="parti.vigente == 'S'">
                                    <ng-container *ngIf="objLicitacion?.ETAPA_CORRELATIVO == 12">
                                        <ng-container *ngIf="parti.APROBO_EVAL_TEC == 1">
                                            <ng-container *ngIf="objetoValidateMontos != null">
                                                <button
                                                    *ngIf="objetoValidateMontos.correlativo == parti.PART_CORRELATIVO"
                                                    type="button" class="btn btn-primary mt-4" href="#modalGanador"
                                                    data-toggle="modal" (click)="ParticipanteSelected = parti">
                                                    Aprobar Oferta
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row  mb-4 mt-4 ">
                            <div class="col-12">
                                <div class="rounded border p-10">
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="text-muted mb-0">Encargado</p>
                                            <span>{{parti.ENCARGADO}}</span>
                                        </div>
                                        <div class="col-6">
                                            <p class="text-muted mb-0">Correo</p>
                                            <span>{{parti.CORREO_ELECTRONICO}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="objLicitacion?.ORDEN >= 14 && parti.APROBO_EVAL_TEC == 1">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Documentos / presupuesto</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <app-view-etapa-files-table *ngIf="archivos && archivos.length > 0"
                                        [archivos]="filesPart(parti.PART_CORRELATIVO)"
                                        [nombreEtapa]="'Recepción de Oferta Económica'"></app-view-etapa-files-table>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<chat [idLicitacion]="idLicitacion"></chat>

<!-- Modal Eliminar participante -->
<div class="modal fade" id="modalGanador" tabindex="-1" role="dialog" aria-labelledby="modalGanador" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEliminarParticipante">Ganador de la licitación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-muted">Importante: Los otros oferentes serán <strong>descartados</strong>, se avanzara a
                    la siguiente etapa y solo será posible <strong>negociar</strong> y <strong>adjudicar</strong> al
                    ganador.</p>
                <p>¿Está seguro que desea dar como ganador al oferente
                    <strong>{{ParticipanteSelected?.RAZON_SOCIAL}}</strong>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="setGanador()" data-dismiss="modal">Aceptar y
                    avanzar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Aprobacion de JAC-->
<div class="modal fade" id="aporbacionJacModal" tabindex="-1" role="dialog" aria-labelledby="aporbacionJacModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="aporbacionJacModalLabel">Validación JAC</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{modalTexto}}</p>
                <ng-container *ngIf="validacionJacEnviar === 0">
                    <div class="form-group">
                        <label>Observación de rechazo:</label>
                        <textarea [(ngModel)]="obsevacionJac" class="form-control" rows="3"></textarea>
                    </div>
                </ng-container>
                <ng-container *ngIf="validacionJacEnviar === 1">
                    <p>Ingrese sus observaciones:</p>
                    <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="onValidarLicitacion()">Aceptar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal Aprobacion de Riesgo Control-->
<div class="modal fade" id="aporbacionRcModal" tabindex="-1" role="dialog" aria-labelledby="aporbacionRcModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="aporbacionRcModalLabel">Validación Riesgo Control</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{modalTexto}}</p>
                <ng-container *ngIf="validacionRcEnviar === 0">
                    <div class="form-group">
                        <label>Observación de rechazo:</label>
                        <textarea [(ngModel)]="obsevacionRc" class="form-control" rows="3"></textarea>
                    </div>
                </ng-container>
                <ng-container *ngIf="validacionRcEnviar === 1">
                    <p>Ingrese sus observaciones:</p>
                    <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="onValidarLicitacionRc()">Aceptar</button>
            </div>
        </div>
    </div>
</div>