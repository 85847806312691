
import * as moment from "src/assets/plugins/moment/moment";
import { User } from "../models/user.model";


export const groupByKey = (array: any, key: string) => {
  return array
    .reduce((hash: any, obj: any) => {
      if(obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
    }, {})
}

export const getUser = (): User => JSON.parse(localStorage.getItem('userData'));

export const formatCurrency = function(value: number, n: number = 2, x: number = 3, s: string = '.', c: string = ',') {
  if(!value) value = 0;
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  const num = value.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s));
};

export const getDayNameByDate = (date: string) => {
  const dt = moment(date);
  return dt.format('dddd');
}