<!-- begin #content -->
<div id="content" class="" *ngIf="!isChildren && objGrupos.length > 0">
    <!-- begin row -->
    <div class="row mb-1 mt-4 mr-4">
        <div class="col-6 text-left">
            <button style="margin-left: 250px;" type="button" class="btn btn-primary " (click)="back()"><i
                    _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"
                    style="color: white;"></i> Volver</button>
            <!-- <button *ngIf="idOrigen == 1" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4" routerLink="/edit-licitacion/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button> -->
        </div>
        <div class="col-6 text-right">
            <ng-container *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                <ng-container *ngIf="actaInicio">
                    <button class="btn btn-primary mr-4" data-toggle="modal" data-target="#modalCrearPlantillaEstandar"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-5 fa-download" style="color: white;"></i>
                        Guardar
                        plantilla</button>
                    <button class="btn btn-primary mr-4" (click)="deleteEstandarCriterioSeleccionado()"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-5 fa-times" style="color: white;"></i>
                        Seleccionar otro estándar</button>
                    <app-view-print-button class="mr-4"
                        [cssFile]="'/assets/css/app/create-criterios.css'"></app-view-print-button>
                    <button class="btn btn-primary mr-4" (click)="save()"><i _ngcontent-ftv-c76=""
                            class="fas fa-lg fa-fw m-r-10 fa-save" style="color: white;"></i> Guardar</button>
                    <a href="#modalGrupo" data-toggle="modal" class="btn btn-primary"
                        (click)="modalGrupo= true;cleanGrupoForm();isUpdateGrupo = false"><i _ngcontent-ftv-c76=""
                            class="fas fa-lg fa-fw m-r-10 fa-plus-circle" style="color: white;"></i> Agregar Capitulo</a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<div id="content" class="" *ngIf="isChildren && objGrupos.length > 0">
    <div class="row mb-2">
        <div class="col-6 text-left">
            <!-- <button *ngIf="idOrigen == 0" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4" routerLink="/licitaciones"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
            <button *ngIf="idOrigen == 1" style="margin-right: 10px;" type="button" class="btn btn-primary mr-4" routerLink="/edit-licitacion/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button> -->
        </div>
        <div class="col-6 text-right">
            <ng-container *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                <ng-container *ngIf="actaInicio">
                    <button class="btn btn-primary mr-4" data-toggle="modal" data-target="#modalCrearPlantillaEstandar"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-5 fa-download" style="color: white;"></i>
                        Guardar
                        plantilla</button>
                    <button class="btn btn-primary mr-4" (click)="deleteEstandarCriterioSeleccionado()"><i
                            _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-5 fa-times" style="color: white;"></i>
                        Seleccionar otro estándar</button>
                    <a href="#modalGrupo" data-toggle="modal" class="btn btn-primary"
                        (click)="modalGrupo= true;cleanGrupoForm();isUpdateGrupo = false">Agregar Capitulo</a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<!-- begin #content -->
<div id="content" [ngClass]="{'content': !isChildren}" id="printComponent">
    <ng-container *ngIf="objGrupos.length > 0">
        <div class="col-12 mb-3 print" *ngIf="!isChildren">
            <img src="/assets/img/logo/logo_em_pdf.svg" style="height: 40px" />
        </div>
        <div class="main-group" *ngFor="let grupo of objGrupos; let i = index">
            <div class="group">
                <div class="mb-0">
                    <span class="q-group mr-2">{{grupo.peso}}</span>
                    <span>
                        {{grupo.descripcion}}
                    </span>
                    <ng-container *ngIf="actaInicio">
                        <a class="btn rounded-icon f-right mr-2" href="#modalSubgroup" data-toggle="modal"
                            (click)="grupoIndex = i;modalSubgrupo = true;isUpdateSubgrupo = false;cleanSubGrupoForm()"
                            title="Agregar SubCapitulo">
                            <i class="fas fa-plus-circle"></i>
                        </a>
                        <a class="btn rounded-icon f-right mr-2" href="#modalGrupo" data-toggle="modal"
                            (click)="LoadUpdateGroupForm(i,grupo.correlativo)" title="Modificar">
                            <i class="fas fa-pen"></i>
                        </a>
                        <a class="btn rounded-icon f-right mr-2" (click)="removeGroup(i,grupo.correlativo)"
                            title="Eliminar">
                            <i class="fas fa-trash"></i>
                        </a>
                    </ng-container>
                </div>
            </div>
            <div>
                <div class="subgroup" *ngFor="let subgrupo of grupo.subgrupoLs; let y = index"
                    style="padding-left: 3rem;">
                    <div class="subgroup_container">
                        <!-- {{y + 1}}.-  -->
                        <span class="mb-0 text-subgroup">
                            <p>{{subgrupo.descripcion}} ({{subgrupo.peso}}%)</p>
                            <p class="text-muted mb-0">{{subgrupo.observacion}}</p>
                        </span>
                        <div class="subgroup_options">
                            <ng-container *ngIf="actaInicio">
                                <a class="btn rounded-icon mr-2" href="#modalEditarAyudaRelacionada" data-toggle="modal"
                                    (click)="filterAyudaSeleccionada(subgrupo.correlativo)"
                                    title="Editar Ayuda Eval Tec">
                                    <i class="fas fa-info-circle"></i>
                                </a>
                                <a class="btn rounded-icon mr-2" href="#modalSubgroup" data-toggle="modal"
                                    (click)="LoadUpdateSubGroupForm(i,y,subgrupo.correlativo)" title="Modificar">
                                    <i class="fas fa-pen"></i>
                                </a>
                                <a class="btn rounded-icon mr-2" (click)="removeSubGroup(i,y,subgrupo.correlativo)"
                                    title="Borrar">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                    <div class="criterio" *ngFor="let criterio of subgrupo.criteriosLs; let x = index"
                        style="padding-left: 3rem;">
                        <div class="criterio_container">
                            <span class="text-criterio ">
                                <p>{{criterio.descripcion}}</p>
                                <p class="text-muted">{{criterio.observacion}}</p>
                            </span>
                            <div class="criterio_options">
                                <ng-container *ngIf="actaInicio">
                                    <a class="btn rounded-icon f-right mr-2"
                                        (click)="removeCriterio(i,y,x,criterio.correlativo)" title="Borrar">
                                        <i class="fas fa-trash"></i>
                                    </a>
                                    <a class="btn rounded-icon f-right mr-2" href="#modalCriterio" data-toggle="modal"
                                        (click)="LoadUpdateCriterioForm(i,y,x,criterio.correlativo)" title="Modificar">
                                        <i class="fas fa-pen"></i>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="objGrupos.length <= 0">
        <ng-container *ngIf="estandaresEvalTecnica.length > 0">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="formEstandares" (ngSubmit)="onGetEstandarEvalTecnica()">
                        <div class="form-group row">
                            <p class="text-muted">Seleccione un estándar de evaluación técnica para continuar:</p>
                            <select formControlName="estandarEvalTecSelect" class="form-control" #estandarEvalTecSelect>
                                <ng-container *ngFor="let estandar of estandaresEvalTecnica">
                                    <option value="{{estandar.correlativo}}">{{estandar.descripcion_estandar}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group text-right">
                            <button class="btn btn-primary" type="submit">Aplicar</button>
                        </div>
                    </form>
                </div>
            </div>
            <hr>
        </ng-container>
        <ng-container *ngIf="estandaresEvalTecnica.length <= 0">
            <p class="text-center">No hay estandares de evaluación técnica.</p>
        </ng-container>
    </ng-container>
</div>

<div class="row mt-4" *ngIf="actaInicio">
    <div class="col-12 text-right">
        <!-- <app-view-print-button class="mr-4" [cssFile]="'/assets/css/app/create-criterios.css'"></app-view-print-button> -->
        <button class="btn btn-primary" (click)="save()" [disabled]="objGrupos.length == 0">Siguiente</button>
    </div>
</div>

<div class="modal" id="modalGrupo" *ngIf="modalGrupo">
    <div class="modal-dialog">
        <div class="modal-content p-4">
            <div class="modal-body">
                <form [formGroup]="formGroup">
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Descripcion <span class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <input class="form-control" type="text" placeholder="" id="descripcion"
                                formControlName="descripcion">
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Peso <span *ngIf="!isUpdateGrupo">(El peso maximo
                                permitido es: {{pesoPermitidoGrupo}})</span><span class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <input class="form-control" type="number" placeholder="" id="peso" formControlName="peso">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <button *ngIf="!isUpdateGrupo" class="btn btn-primary mr-2" [disabled]="formGroup.invalid"
                                (click)="addGrupo()" data-dismiss="modal">Agregar</button>
                            <button *ngIf="isUpdateGrupo" class="btn btn-primary mr-2" [disabled]="formGroup.invalid"
                                (click)="updateGrupo()" data-dismiss="modal">Actualizar</button>
                            <button class="btn tag text-orange " data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="modalSubgroup" *ngIf="modalSubgrupo">
    <div class="modal-dialog modal-lg">
        <div class="modal-content p-4">
            <div class="modal-body">
                <form [formGroup]="formSubgroup">
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Descripcion <span class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <input class="form-control" type="text" placeholder="" id="descripcion"
                                formControlName="descripcion">
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Observacion <span class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <textarea rows="3" class="form-control" type="text" placeholder="" id="observacion"
                                formControlName="observacion"></textarea>
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Peso <span class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <input class="form-control" type="number" placeholder="" id="peso" formControlName="peso">
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Tipo <span class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <select class="form-select form-control" aria-label="Default select example" id="tipo"
                                formControlName="tipo">
                                <option value="1">Archivo</option>
                                <option value="2">Cargos</option>
                                <option value="3">Personal</option>
                                <option value="4">Equipos</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Limite de respuestas permitidas <span
                                class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <input class="form-control" type="number" placeholder="" id="limiteRespuestasOferente"
                                formControlName="limiteRespuestasOferente">
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12">Ayuda Evaluación <span
                                class="text-danger">*</span></label>
                        <div class="col-md-12">
                            <select class="form-select form-control" aria-label="Default select example" id="idAyuda"
                                formControlName="idAyuda">
                                <option value="{{item.correlativo}}" *ngFor="let item of ayudaEvalTecnicaLs">
                                    {{item.descripcion}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="form-group row m-b-15">
                        <div class="col-md-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="true" id="subgrupo_abierto" formControlName="subgrupo_abierto">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Grupo abierto
                                </label>
                            </div>
                        </div>
                    </div> -->
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <button *ngIf="!isUpdateSubgrupo" class="btn btn-primary mr-2"
                                [disabled]="formSubgroup.invalid" (click)="addSubGrupo()"
                                data-dismiss="modal">Agregar</button>
                            <button *ngIf="isUpdateSubgrupo" class="btn btn-primary mr-2"
                                [disabled]="formSubgroup.invalid" (click)="updateSubGrupo()"
                                data-dismiss="modal">Actualizar</button>
                            <button class="btn tag text-orange" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modalCriterio" *ngIf="modalCriterio">
    <div class="modal-dialog">
        <div class="modal-content p-4">
            <div class="modal-body">
                <form [formGroup]="formCriterio">
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-2">Descripcion <span class="text-danger">*</span></label>
                        <div class="col-md-10">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <input class="form-control" type="text" placeholder="" id="descripcion"
                                formControlName="descripcion" required>
                        </div>
                    </div>
                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-2">Observacion <span class="text-danger">*</span></label>
                        <div class="col-md-10">
                            <!--<select class="default-select2 form-control" id="solpe" name="solpe">
                                <option value="0">Seleccione ...</option>
                            </select>-->
                            <textarea rows="3" class="form-control" type="text" placeholder="" id="observacion"
                                formControlName="observacion"></textarea>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <button *ngIf="!isUpdateCriterio" class="btn btn-primary mr-2" (click)="addCriterio()"
                                [disabled]="formCriterio.invalid" data-dismiss="modal">Agregar</button>
                            <button *ngIf="isUpdateCriterio" class="btn btn-primary mr-2" (click)="updateCriterios()"
                                [disabled]="formCriterio.invalid" data-dismiss="modal">Modificar</button>
                            <button class="btn tag text-orange" data-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="modalEditarAyudaRelacionada" tabindex="-1" role="dialog"
    aria-labelledby="modalEditarAyudaRelacionada" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEditarAyudaRelacionada">Ayuda Relacionada</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <ng-container *ngIf="cargosFilter.length > 0">
                            <div>
                                <button class="btn tag text-primary m-r-10 m-b-10"
                                    (click)="selectCargo('')">Todos</button>
                                <ng-container *ngFor="let cargo of cargosFilter">
                                    <button class="btn tag text-primary m-r-10 m-b-10"
                                        (click)="selectCargo(cargo)">{{cargo}}</button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-6 text-right">
                        <button *ngIf="tipoFormAyudaRelacionadaSubgrupo !== 3" class="btn btn-primary"
                            (click)="tipoFormAyudaRelacionadaSubgrupo = 3">Agregar Ayuda</button>
                    </div>
                </div>
                <br>
                <ng-container *ngIf="tipoFormAyudaRelacionadaSubgrupo === 3">
                    <form [formGroup]="formCreateAyudaRelacionadaSubgrupo"
                        (ngSubmit)="createAyudaRelacionadaSubgrupo()">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Descripción <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="descripcionAyuda"
                                    formControlName="descripcionAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Nota <span class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" type="number" placeholder="" id="notaAyuda"
                                    formControlName="notaAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Cargo</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="cargoAyuda"
                                    formControlName="cargoAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Ponderador</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="ponderadorAyuda"
                                    formControlName="ponderadorAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Perfil Requerido</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="perfilAyuda"
                                    formControlName="perfilAyuda">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary mr-2" type="submit"
                                    [disabled]="formCreateAyudaRelacionadaSubgrupo.invalid">Agregar</button>
                                <button class="btn tag text-orange" data-dismiss="modal"
                                    (click)="resetCreateFormAyudaRelacionadaSubgrupo(); tipoFormAyudaRelacionadaSubgrupo = 0">Cancelar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                </ng-container>
                <ng-container *ngIf="tipoFormAyudaRelacionadaSubgrupo === 3 || tipoFormAyudaRelacionadaSubgrupo === 0">
                    <ng-container *ngIf="ayudasEditablesSelected.length > 0">
                        <table class="table table-hover">
                            <thead class="thead-darkk">
                                <th>Descripción de ayuda</th>
                                <th>Nota</th>
                                <th>Cargo</th>
                                <th>Ponderador</th>
                                <th>Perfil Requerido</th>
                                <th class="text-left">Acciones</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of ayudasEditablesSelected">
                                    <ng-container>
                                        <td>
                                            {{ item.descripcion_ayuda }}
                                        </td>
                                        <td>
                                            {{ item.nota_ayuda }}
                                        </td>
                                        <td>
                                            {{ item.cargo_ayuda }}
                                        </td>
                                        <td>
                                            {{ item.ponderador_ayuda }}
                                        </td>
                                        <td>
                                            {{ item.perfil_requerido_ayuda }}
                                        </td>
                                        <td class="text-right">
                                            <div class="row text-right">
                                                <a title="Editar Ayuda" class="btn rounded-icon mr-2"
                                                    (click)="editarAyudaRelacionadaSubgrupo(item.correlativo)">
                                                    <i class="fa fa-edit"></i>
                                                </a>
                                                <a title="Eliminar Ayuda" class="btn rounded-icon mr-2"
                                                    (click)="eliminarAyudaRelacionadaSubgrupo(item.correlativo)">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-container *ngIf="ayudasEditablesSelected.length <= 0">
                        <p>No existe una ayuda para editar</p>
                    </ng-container>
                </ng-container>
                <br>
                <ng-container *ngIf="tipoFormAyudaRelacionadaSubgrupo === 1">
                    <form [formGroup]="formUpdateAyudaRelacionadaSubgrupo"
                        (ngSubmit)="updateAyudaRelacionadaSubgrupo()">
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Descripción <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="descripcionAyuda"
                                    formControlName="descripcionAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Nota <span class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" type="number" placeholder="" id="notaAyuda"
                                    formControlName="notaAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Cargo</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="cargoAyuda"
                                    formControlName="cargoAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Ponderador</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="ponderadorAyuda"
                                    formControlName="ponderadorAyuda">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Perfil Requerido</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" id="perfilAyuda"
                                    formControlName="perfilAyuda">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary mr-2" type="submit"
                                    [disabled]="formUpdateAyudaRelacionadaSubgrupo.invalid">Modificar</button>
                                <button class="btn tag text-orange" data-dismiss="modal"
                                    (click)="resetUpdateFormAyudaRelacionadaSubgrupo(); tipoFormAyudaRelacionadaSubgrupo = 0">Cancelar</button>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="tipoFormAyudaRelacionadaSubgrupo === 2">
                    <p style="font-size: 16px;">¿Desea eliminar la ayuda señalada?</p>
                    <p style="font-size: 14px; font-weight: bold;">{{ayudaRelacionadaSubgrupoDescripcion}}</p>
                    <div class="row mt-4">
                        <div class="col-12 text-right">
                            <button class="btn btn-primary mr-2"
                                (click)="deleteAyudaRelacionadaSubgrupo()">Eliminar</button>
                            <button class="btn tag text-orange" data-dismiss="modal"
                                (click)="tipoFormAyudaRelacionadaSubgrupo = 0">Cancelar</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Modal crear plantalla nuevo estandar -->
<div class="modal fade" id="modalCrearPlantillaEstandar" tabindex="-1" role="dialog"
    aria-labelledby="modalCrearPlantillaEstandar" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalCrearPlantillaEstandar">Guardar como nuevo estándar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    #closeModalCreatePlantillaEstandar>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <form [formGroup]="formGuardarPlantillaEstandar" (ngSubmit)="onCrearPlantillaEstandar()">
                            <div class="form-group row m-b-15">
                                <label class="col-form-label col-md-2">Descripción <span
                                        class="text-danger">*</span></label>
                                <div class="col-md-10">
                                    <input class="form-control" type="text" placeholder="" id="descripcionEstandar"
                                        formControlName="descripcionEstandar">
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary mr-2" type="submit"
                                        [disabled]="formGuardarPlantillaEstandar.invalid">Guardar</button>
                                    <button class="btn tag text-orange" data-dismiss="modal"
                                        (click)="resetCrearPlantillaEstandar()">Cancelar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>