import { Component, OnInit } from '@angular/core';
import { LicitacionService } from '../shared/services/licitacion.service';

@Component({
  selector: 'app-historico-cambios-cronograma',
  templateUrl: './historico-cambios-cronograma.component.html',
  styleUrls: ['./historico-cambios-cronograma.component.css']
})
export class HistoricoCambiosCronogramaComponent implements OnInit {

  licitaciones: any[];
  versionesCronograma: any[];
  licitacionSeleccionada: boolean;
  idLicitacion: number;
  nombreLicitacion: string;
  versionCronograma: any[];
  versionSeleccionada: number;

  constructor(private licitacionService: LicitacionService) { 
    this.licitaciones = [];
    this.versionesCronograma = [];
    this.licitacionSeleccionada = false;
    this.idLicitacion = 0;
    this.nombreLicitacion = '';
    this.versionCronograma = [];
    this.versionSeleccionada = 0;
  }

  ngOnInit(): void {
    this.licitacionService.getLicitaciones('S').subscribe(resp=>{
      this.licitaciones = JSON.parse(resp);
    });
  }

  selectHistorialCronograma(idLicitacion: number, nombreLicitacion: string){
    this.idLicitacion = idLicitacion;
    this.nombreLicitacion = nombreLicitacion;

    this.licitacionService.getVerisonesCronograma(idLicitacion).subscribe(resp=>{
      console.log(JSON.parse(resp));
      this.versionesCronograma = JSON.parse(resp);
      this.licitacionSeleccionada = true;
    });
  }

  selectVercionCronograma(versionCronograma: number){
    this.versionSeleccionada = versionCronograma;

    this.licitacionService.getVerisonCronograma(this.idLicitacion, versionCronograma).subscribe(resp=>{
      console.log(JSON.parse(resp));
      this.versionCronograma = JSON.parse(resp);
    });
  }
}
