import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LicitacionService } from '../services/licitacion.service';
import { EtapasService } from '../services/etapas.licitacion.service';
import { NotificationService } from '../services/notification.service';
import { FileService } from '../services/file.service';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-view-etapa-readonly',
  templateUrl: './view-etapa-readonly.component.html',
  styleUrls: ['./view-etapa-readonly.component.css']
})
export class ViewEtapaReadonlyComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  participantes: [];
  archivos64 : Blob ;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivos:[]=[];
  constructor(private route: ActivatedRoute, private licitacionService: LicitacionService, 
    private etapaService : EtapasService, 
    private notifyService : NotificationService,
    private fileService: FileService) { }

  ngOnInit(): void {
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,this.idEtapa).subscribe(resp=>{
          this.archivos = JSON.parse(resp);
        });
        this.licitacionService.getParticipantesLic(this.idLicitacion, this.idEtapaLic).subscribe(resp=>{          
          this.participantes = JSON.parse(resp);
        });
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }
    });
  }

  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }

}
