<!-- begin #header -->
<div id="header" class="header navbar-default" *ngIf="isAuth">
    <!-- begin navbar-header -->
    <div class="navbar-header">
        <a class="navbar-brand">
            <!-- <span class="navbar-logo"></span>  -->
            <img class="mr-2" src="../../assets/img/logo/logo.png" alt="">
            <b>Modulo</b> Licitaciones
        </a>
        <button type="button" class="navbar-toggle" data-click="sidebar-toggled">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
    </div>
    <!-- end navbar-header -->
    <!-- begin header-nav -->
    <ul class="navbar-nav navbar-right">
        <li class="dropdown nav-item">
            <a href="javascript:;" class="dropdown-toggle nav-link" data-toggle="dropdown">
                <ng-container *ngIf="notificaciones.length > 0">
                    <i class="fa fa-bell"></i> 
                    <span style="margin-bottom: 12px;" class="badge badge-danger">{{notificaciones.length}}</span>
                </ng-container>
                <ng-container *ngIf="notificaciones.length <= 0">
                    <i class="fa fa-bell"></i>
                </ng-container>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <ng-container *ngIf="notificaciones.length > 0">
                    <ng-container *ngFor="let item of notificaciones">
                        <a href="javascript:;" class="dropdown-item">
                            <div style="border: 1px solid #e0e0e0; padding: 5px;" data-toggle="modal" data-target="#modalNotificaciones">
                                <div style="padding: 3px;">
                                    <p class="m-0">{{item.titulo_notificacion}}</p>
                                    <small class="text-muted">{{item.descripcion_notificacion}}</small>
                                </div>
                            </div>
                        </a>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="notificaciones.length <= 0">
                    <p class="dropdown-item">No hay notificaciones</p>
                </ng-container>
            </div>
        </li>
        <li class="dropdown navbar-user nav-item">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <p class="d-none d-md-inline mb-0">{{userData.userName}}</p> <b class="caret"></b>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <a *ngFor="let item of cargos" style="cursor: pointer; font-weight: normal;" 
                (click)="cambiarCargo(item.ROL_CORRELATIVO, item.GLOSA_ROL)" class="dropdown-item" 
                [ngClass]="{' text-info' : nombreCargoActual == item.GLOSA_ROL}">
                    {{ item.GLOSA_ROL }}
                </a>
                <div class="dropdwn-divider"></div>
                <a style="cursor: pointer; " (click)="onLogout()" class="dropdown-item">Salir</a>
            </div>
        </li>
    </ul>
    <!-- end header-nav -->
</div>
<!-- end #header -->

<!-- begin #sidebar -->
<div id="sidebar" class="sidebar" *ngIf="isAuth">
    <!-- begin sidebar scrollbar -->
    <div data-scrollbar="true" data-height="100%">
        <!-- begin sidebar user -->
        <ul class="nav">
            <li class="nav-profile">
                <a href="javascript:;" data-toggle="nav-profile">
                    <div class="cover with-shadow">
                    </div>
                    <div class="info">
                        <b class="caret pull-right"></b>{{userData.userName}}
                        <small>{{nombreCargoActual}}</small>
                    </div>
                </a>
            </li>
        </ul>
        <!-- end sidebar user -->
        <!-- begin sidebar nav -->
        <ul class="nav">
            <li class="nav-header">Menu</li>
            <!-- <li *ngIf="userData.rolId ===2">
                <a routerLink="/dashboard">
                    <i class="fa fa-th-large"></i>
                    <span>Dashboard</span>
                </a>
            </li> -->
            <!-- <li *ngIf="userData.rolId ===2">
                <a routerLink="/create-licitacion">
                    <i class="fa fa-cogs"></i>
                    <span>Crear licitacion</span>
                </a>
            </li> -->
            <li *ngIf="userData.rolId !== 0">
                <a routerLink="/licitaciones">
                    <i class="fa fa-list"></i>
                    <span>Ver licitaciones</span>
                </a>
            </li>
            <li class="has-sub" *ngIf="userData.rolId !== 0">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-hdd"></i>
                    <span>Mantenedores</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/cronograma">Cronograma</a></li>
                    <li><a routerLink="/historico-cronogramas-licitaciones">Historial de Cronogramas</a></li>
                    <!-- <li><a routerLink="/mantenedores">Avanza etapa</a></li> -->
                    <li><a routerLink="/participantes">Participantes</a></li>
                    <li><a routerLink="/mantenedor-ayuda-eval-tecnica">Ayuda Eval. Técnica</a></li>
                    <li><a routerLink="/mantenedor-motivos-renuncia">Motivos Renuncia</a></li>
                    <li><a routerLink="/mantenedor-estadares-eval-tecnica">Metodologias de Evaluación Tecnica</a></li>
                    <li><a routerLink="/mantenedor-doc-requeridos-oferente">Doc. ADM - ECO - TEC</a></li>
                </ul>
            </li>
            <li class="has-sub" *ngIf="userData.rolId === 0">
                <a href="javascript:;">
                    <b class="caret"></b>
                    <i class="fa fa-users"></i>
                    <span>Contratista</span>
                </a>
                <ul class="sub-menu">
                    <li><a routerLink="/contratista">Mis Licitaciones</a></li>
                </ul>
            </li>
            <!-- begin sidebar minify button -->
            <li><a href="javascript:;" class="sidebar-minify-btn" data-click="sidebar-minify"><i class="fa fa-angle-double-left"></i></a></li>
            <!-- end sidebar minify button -->
        </ul>
        <!-- end sidebar nav -->
    </div>
    <!-- end sidebar scrollbar -->
</div>
<div class="sidebar-bg" *ngIf="isAuth"></div>
<!-- end #sidebar -->
<!-- Modal -->
<div class="modal fade" id="modalNotificaciones" tabindex="-1" role="dialog" aria-labelledby="modalNotificaciones" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNotificaciones">Notificaciones</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="notificaciones.length > 0">
                <div class="row">
                    <div class="col-6">
                        <button class="btn tag text-primary m-r-10 m-b-10" (click)="stepNotifications = 0;">No leidas ({{notificaciones.length}})</button>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary" (click)="marcarTodoLeido()">Marcar todo como leido</button>
                    </div>
                </div>
                <br>
                <ng-container *ngIf="stepNotifications === 0">
                    <ng-container *ngFor="let item of notificaciones">
                        <div style="border: 1px solid #e0e0e0; padding: 5px;">
                            <div style="padding: 3px;">
                                <div class="row">
                                    <div class="col-8">
                                        <p style="font-weight: bold;" class="m-0">{{item.titulo_notificacion}}</p>
                                        <p class="text-muted">{{item.descripcion_notificacion}}</p>
                                        <br>
                                        <p class="text-muted">{{item.fecha_creacion | date: 'dd/MM/yyyy hh:mm a'}}</p>
                                    </div>
                                    <div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center" class="col-4 text-right">
                                        <div 
                                            title="Marcar como leido" 
                                            class="btn rounded-icon mr-2"
                                            (click)="marcarNotificacionesComoLeida(item.correlativo)"
                                        >
                                            <i class="fa fa-eye-slash"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="notificaciones.length <= 0">
                <p class="text-muted text-center">No hay notificaciones</p>
            </ng-container>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
</div>