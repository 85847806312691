<!-- begin #content -->
<div id="content" class="content">
    <div class="row">
        <div class="col-6">
            <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" routerLink="/contratista"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
        </div>
        <div class="col-6 text-right">
            <button *ngIf="(idEtapa == 13 && isEditarPresupuesto)" style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" routerLink="/cargar-presupuesto/{{idLicitacion}}"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-chart-bar" style="color: white;"></i> Corregir Oferta Económica</button>
        </div>
    </div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Respuesta a negociación de licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Respuesta a negociación de licitación</h4>
                </div>
                <div class="panel-body">
                    <div class="row">
                        <!-- begin col-6 -->
                        <div class="col-xl-12">
                            <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                                <thead>
                                    <tr>
                                        <th class="text-nowrap">Negociacion</th>
                                        <th class="text-nowrap">Fecha de creación</th>
                                        <th class="text-nowrap">Archivo</th>
                                        <th class="text-nowrap">Descargar archivo</th>
                                        <th class="text-nowrap">Respuesta</th>
                                        <th class="text-nowrap">Descargar archivo</th>
                                        <th class="text-nowrap">Subir respuesta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd gradeX" *ngFor="let item of negociaciones">
                                        <td class="text-break">{{item.NEGOCIACION}}</td>
                                        <td width="15%">{{item.FECHA_CREACION}}</td>
                                        <td width="15%">{{item.DESCRIPCION}}</td>
                                        <td width="10%">
                                            <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_NEG" [nombreArchivo]="item.NOMBRE_ARCHIVO_NEG" style="margin-right: 10px;"></app-view-file-buttons>
                                        </td>
                                        <td width="15%">{{item.NOMBRE_ARCHIVO_RESP}}</td>
                                        <td width="10%">
                                            <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_RESP" [nombreArchivo]="item.NOMBRE_ARCHIVO_RESP"></app-view-file-buttons>
                                        </td>
                                        <td>
                                            <input #inputFile style="border-color: transparent;" class="form-control" name="archivo" type="file" (change)="detectFiles($event,item.CORRELATIVO)" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xl-12" style="text-align: center;">
                            <button *ngIf="showBtn" type="button" class="btn btn-success" (click)="onGuardarRespuesta()">Enviar respuestas</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end #content -->