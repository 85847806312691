import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LicitacionService } from '../shared/services/licitacion.service';
import { NotificationService } from '../shared/services/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface AyudaEvalTecnica {
  correlativo:    number;
  descripcion:    string;
  vigente:        string;
  fecha_creacion: Date;
}

export interface AyudaEvalTecnicaCriterio {
  correlativo:      number;
  descripcion:      string;
  grupo:            string;
  item:             string;
  cargo:            string;
  ponderador:       string;
  perfil_requerido: string;
  nota:             number;
  correlativo_nota: number;
}

export interface NotasEvalTecnica {
  correlativo:    number;
  nota:           number;
  vigente:        string;
  fecha_creacion: Date;
}

@Component({
  selector: 'app-mantenedor-ayuda-eval-tecnica',
  templateUrl: './mantenedor-ayuda-eval-tecnica.component.html',
  styleUrls: ['./mantenedor-ayuda-eval-tecnica.component.css']
})
export class MantenedorAyudaEvalTecnicaComponent implements OnInit {

  ayudaEvalTecnicaCorrelativo: number;
  ayudaCriterioCorrelativo: number;
  ayudasEvalTecnica: AyudaEvalTecnica[];
  ayudaEvalTecnicaFiltered: AyudaEvalTecnica[];
  ayudasEvalTecnicaCriterios: AyudaEvalTecnicaCriterio[];
  ayudasEvalTecnicaCriteriosFiltered: AyudaEvalTecnicaCriterio[];
  notasEvaluacionTecnica: NotasEvalTecnica[];
  ayudaEvalTecnicaSelected: boolean;
  ayudaSeleccionadaDescripcion: string;
  formUpdateAyudaCriterio: FormGroup;
  formCreateAyudaCriterio: FormGroup;
  formCreateAyudaEval: FormGroup;
  formUpdateAyudaEval: FormGroup;

  @ViewChild('closeModalEliminarAyudaCriterio') closeModalEliminarAyudaCriterio: ElementRef;
  @ViewChild('closeModalCrearAyudaCriterio') closeModalCrearAyudaCriterio: ElementRef;
  @ViewChild('closeModalEditarAyudaCriterio') closeModalEditarAyudaCriterio: ElementRef;
  @ViewChild('closeModalCrearAyudaEvalTecnica') closeModalCrearAyudaEvalTecnica: ElementRef;
  @ViewChild('closeModalUpdateAyudaEvalTecnica') closeModalUpdateAyudaEvalTecnica: ElementRef;

  constructor(
    private licitacionService: LicitacionService, 
    private notifyService: NotificationService,
  ) {
    this.ayudasEvalTecnica = [];
    this.ayudaEvalTecnicaFiltered = [];
    this.ayudasEvalTecnicaCriteriosFiltered = [];
    this.ayudasEvalTecnicaCriterios = [];
    this.notasEvaluacionTecnica = [];
    this.ayudaEvalTecnicaSelected = false;
    this.ayudaSeleccionadaDescripcion = '';
    this.ayudaCriterioCorrelativo = 0;
    this.ayudaEvalTecnicaCorrelativo = 0;

    this.formUpdateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
      grupo: new FormControl(null, [ Validators.required ]),
      item: new FormControl(null, Validators.required),
      cargo: new FormControl(null, Validators.required),
      ponderador: new FormControl(null, Validators.required),
      perfil_requerido: new FormControl(null, Validators.required),
      nota: new FormControl(null, Validators.required),
    });

    this.formCreateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
      grupo: new FormControl(null, [ Validators.required ]),
      item: new FormControl(null, Validators.required),
      cargo: new FormControl(null, Validators.required),
      ponderador: new FormControl(null, Validators.required),
      perfil_requerido: new FormControl(null, Validators.required),
      nota: new FormControl(null, Validators.required),
    });

    this.formCreateAyudaEval = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
    });

    this.formUpdateAyudaEval = new FormGroup({
      descripcion: new FormControl(null, Validators.required),
      vigente: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.getAyudaEvalTecnica();
    this.getNotasEvaluacionTecnica();
  }

  resetFormCreateAyudaEval(){
    this.formUpdateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(''),
    });
  }

  resetFormUpdateAyudaEval(){
    this.formUpdateAyudaEval = new FormGroup({
      descripcion: new FormControl(''),
      vigente: new FormControl(''),
    });
  }

  resetFormUpdateAyudaEvalTecnicaCriterio(){
    this.formUpdateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(''),
      grupo: new FormControl(''),
      item: new FormControl(''),
      cargo: new FormControl(''),
      ponderador: new FormControl(''),
      perfil_requerido: new FormControl(''),
      nota: new FormControl(0),
    });
  }

  resetFormCreateAyudaEvalTecnicaCriterio(){
    this.formCreateAyudaCriterio = new FormGroup({
      descripcion: new FormControl(''),
      grupo: new FormControl(''),
      item: new FormControl(''),
      cargo: new FormControl(''),
      ponderador: new FormControl(''),
      perfil_requerido: new FormControl(''),
      nota: new FormControl(this.notasEvaluacionTecnica[0].correlativo),
    });
  }

  getNotasEvaluacionTecnica(){
    this.licitacionService.getNotasEvalTecnica().subscribe(resp=>{
      const notasEvaluacionTecnica: NotasEvalTecnica[] = JSON.parse(resp);
      console.log(notasEvaluacionTecnica);
      if (notasEvaluacionTecnica.length > 0) {
        this.notasEvaluacionTecnica = notasEvaluacionTecnica;
      }else{
        this.notasEvaluacionTecnica = [];
      }
    });
  }

  getAyudaEvalTecnica(){
    this.licitacionService.getAyudaEvalTecnica().subscribe(resp=>{
        const ayudaEvalTecnica: AyudaEvalTecnica[] = JSON.parse(resp);

        if (ayudaEvalTecnica.length > 0) {
          this.ayudasEvalTecnica = ayudaEvalTecnica;
          this.ayudaEvalTecnicaFiltered = this.ayudasEvalTecnica;
        }else{
          this.ayudasEvalTecnica = [];
          this.ayudaEvalTecnicaFiltered = [];
        }
      },
      error => {
        this.ayudasEvalTecnica = [];
        this.ayudaEvalTecnicaFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener ayuda de evaluación técnica');
      }
    );
  }

  getAyudaEvalTecnicaCriterios(correlativoAyuda: number){
    this.ayudaEvalTecnicaCorrelativo = correlativoAyuda;
    const ayudaEvalTecnica: AyudaEvalTecnica = this.ayudasEvalTecnica.filter(data => data.correlativo === correlativoAyuda)[0];
    this.ayudaSeleccionadaDescripcion = ayudaEvalTecnica.descripcion;

    this.licitacionService.getAyudaEvalTecnicaCriteriosMantenedor(correlativoAyuda).subscribe(resp=>{
        const ayudaEvalTecnicaCritrerios: AyudaEvalTecnicaCriterio[] = JSON.parse(resp);
        console.log(ayudaEvalTecnicaCritrerios);
        if (ayudaEvalTecnicaCritrerios.length > 0) {
          this.ayudasEvalTecnicaCriterios = ayudaEvalTecnicaCritrerios;
          this.ayudasEvalTecnicaCriteriosFiltered = ayudaEvalTecnicaCritrerios;
        }else{
          this.ayudasEvalTecnicaCriterios = [];
          this.ayudasEvalTecnicaCriteriosFiltered = [];
        }
      },
      error => {
        this.ayudasEvalTecnicaCriterios = [];
        this.ayudasEvalTecnicaCriteriosFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener criterios de ayuda de evaluación técnica');
      }
    );
  }

  onUpdateAyudaEvalTecnica(){
    const createAyudaEvalTecnica = {
      in_correlativo: this.ayudaEvalTecnicaCorrelativo,
      in_descripcion: this.formUpdateAyudaEval.controls.descripcion.value,
      in_vigente: this.formUpdateAyudaEval.controls.vigente.value
    }

    this.licitacionService.putUpdateAyudaEvalTecnica(createAyudaEvalTecnica).subscribe(resp=>{
        this.getAyudaEvalTecnica();
        this.closeModalUpdateAyudaEvalTecnica.nativeElement.click();
        this.notifyService.showSuccess('Actualizar', 'Ayuda Evaluación Técnica Actualizada con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al Actualizar ayuda de evaluación técnica');
      }
    );
  }

  editarAyudaEvalTecnica(correlativoAyudaEval: number){
    this.ayudaEvalTecnicaCorrelativo = correlativoAyudaEval;
    const ayudaEvalTecnica: AyudaEvalTecnica = this.ayudasEvalTecnica.filter(data => data.correlativo === correlativoAyudaEval)[0];

    this.resetFormUpdateAyudaEval();
    this.formUpdateAyudaEval.controls.descripcion.setValue(ayudaEvalTecnica?.descripcion);
    this.formUpdateAyudaEval.controls.vigente.setValue(ayudaEvalTecnica?.vigente);
    this.formUpdateAyudaEval.updateValueAndValidity();
  }

  onCrearAyudaEvalTecnica(){
    const createAyudaEvalTecnica = {
      in_descripcion: this.formCreateAyudaEval.controls.descripcion.value,
    }

    this.licitacionService.postCreateAyudaEvalTecnica(createAyudaEvalTecnica).subscribe(resp=>{
        this.getAyudaEvalTecnica();
        this.closeModalCrearAyudaEvalTecnica.nativeElement.click();
        this.notifyService.showSuccess('Crear', 'Ayuda Evaluación Técnica creada con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al crear ayuda de evaluación técnica');
      }
    );
  }

  editarAyudaEvalTecnicaCriterio(correlativoAyudaCriterio: number){
    
    this.ayudaCriterioCorrelativo = correlativoAyudaCriterio;
    const ayudaEvalTecnicaCriterio: AyudaEvalTecnicaCriterio = this.ayudasEvalTecnicaCriterios.filter(data => data.correlativo === correlativoAyudaCriterio)[0];

    this.resetFormUpdateAyudaEvalTecnicaCriterio();
    this.formUpdateAyudaCriterio.controls.descripcion.setValue(ayudaEvalTecnicaCriterio?.descripcion);
    this.formUpdateAyudaCriterio.controls.grupo.setValue(ayudaEvalTecnicaCriterio?.grupo);
    this.formUpdateAyudaCriterio.controls.item.setValue(ayudaEvalTecnicaCriterio?.item);
    this.formUpdateAyudaCriterio.controls.cargo.setValue(ayudaEvalTecnicaCriterio?.cargo);
    this.formUpdateAyudaCriterio.controls.ponderador.setValue(ayudaEvalTecnicaCriterio?.ponderador);
    this.formUpdateAyudaCriterio.controls.perfil_requerido.setValue(ayudaEvalTecnicaCriterio?.perfil_requerido);
    this.formUpdateAyudaCriterio.controls.nota.setValue(ayudaEvalTecnicaCriterio?.correlativo_nota);
  }

  eliminarAyudaEvalTecnicaCriterio(correlativoAyudaCriterio: number){
    this.ayudaCriterioCorrelativo = correlativoAyudaCriterio;
  }

  onEliminarAyudaEvalTecnicaCriterio(){
    this.licitacionService.deleteAyudaEvalTecnicaCriterios({in_correlativo: this.ayudaCriterioCorrelativo}).subscribe(resp=>{
        this.getAyudaEvalTecnicaCriterios(this.ayudaEvalTecnicaCorrelativo);
        this.closeModalEliminarAyudaCriterio.nativeElement.click();
        this.notifyService.showSuccess('Eliminar', 'Criterio de ayuda de evaluación técnica eliminado');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al eliminar criterios de ayuda de evaluación técnica');
      }
    );
  }

  onEditarAyudaEvalTecnicaCriterio(){
    const updateAyudaEvalTecnicaCriterio = {
      in_correlativo: this.ayudaCriterioCorrelativo,
      in_idAyuda: this.ayudaEvalTecnicaCorrelativo,
      in_descripcion: this.formUpdateAyudaCriterio.controls.descripcion.value,
      correlativo_nota: this.formUpdateAyudaCriterio.controls.nota.value,
      in_grupo: this.formUpdateAyudaCriterio.controls.grupo.value,
      in_item: this.formUpdateAyudaCriterio.controls.item.value,
      in_cargo: this.formUpdateAyudaCriterio.controls.cargo.value,
      in_ponderador: this.formUpdateAyudaCriterio.controls.ponderador.value,
      in_perfil_requerido: this.formUpdateAyudaCriterio.controls.perfil_requerido.value,
    }

    this.licitacionService.putAyudaEvalTecnicaCriterios(updateAyudaEvalTecnicaCriterio).subscribe(resp=>{
        this.getAyudaEvalTecnicaCriterios(this.ayudaEvalTecnicaCorrelativo);
        this.closeModalEditarAyudaCriterio.nativeElement.click();
        this.notifyService.showSuccess('Actualizar', 'Criterio de ayuda actualizado con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al actualizar criterio de ayuda de evaluación técnica');
      }
    );
  }

  onCrearAyudaEvalTecnicaCriterio(){
    const ayudaEvalTecnicaCriterio = {
      in_idAyuda: this.ayudaEvalTecnicaCorrelativo,
      in_descripcion: this.formCreateAyudaCriterio.controls.descripcion.value,
      correlativo_nota: this.formCreateAyudaCriterio.controls.nota.value,
      in_grupo: this.formCreateAyudaCriterio.controls.grupo.value,
      in_item: this.formCreateAyudaCriterio.controls.item.value,
      in_cargo: this.formCreateAyudaCriterio.controls.cargo.value,
      in_ponderador: this.formCreateAyudaCriterio.controls.ponderador.value,
      in_perfil_requerido: this.formCreateAyudaCriterio.controls.perfil_requerido.value,
    }

    this.licitacionService.createAyudaEvalTecnicaCriterios(ayudaEvalTecnicaCriterio).subscribe(resp=>{
        this.getAyudaEvalTecnicaCriterios(this.ayudaEvalTecnicaCorrelativo);
        this.closeModalCrearAyudaCriterio.nativeElement.click();
        this.notifyService.showSuccess('Crear', 'Criterio de ayuda creado con exito.');
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error al crear criterio de ayuda de evaluación técnica');
      }
    );
  }

  applyFilterAyudaEvalTecnica(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.ayudaEvalTecnicaFiltered = this.ayudasEvalTecnica.filter(data => {
        return (
          (
            data?.correlativo.toString().toLowerCase().includes(filterValue) || 
            data?.descripcion.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.ayudaEvalTecnicaFiltered = this.ayudasEvalTecnica
    }
  }

  applyFilterAyudaEvalTecnicaCriterios(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.ayudasEvalTecnicaCriteriosFiltered = this.ayudasEvalTecnicaCriterios.filter(data => {
        return (
          (
            data?.descripcion.toLowerCase().includes(filterValue) 
            || 
            data?.cargo.toLowerCase().includes(filterValue)
            ||
            data?.perfil_requerido.toLowerCase().includes(filterValue)
            ||
            data?.ponderador.toLowerCase().includes(filterValue)
            ||
            data?.nota.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.ayudasEvalTecnicaCriteriosFiltered = this.ayudasEvalTecnicaCriterios;
    }
  }

  get fe() { return this.formUpdateAyudaCriterio.controls; }
  get fc() { return this.formCreateAyudaCriterio.controls; }
}
