<div>
    <div *ngIf="correosLicitaciones && correosLicitaciones.length > 0">
        <br>
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-4">
            </div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-4"></div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-4 text-right">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fas fa-search"></i></span>
                    <input class="form-control" type="text" [(ngModel)]="filterCorreo" name="filterCorreo"
                        id="filterCorreo" placeholder="Buscar">
                </div>
            </div>
        </div>
        <table style="margin-top: 10px;" id="table_correos" class="table table-striped table-td-valign-middle">
            <thead class="thead-dark">
                <tr>
                    <th class="text-nowrap">ID Correo</th>
                    <th class="text-nowrap">Asunto</th>
                    <th class="text-nowrap">Destinatario</th>
                    <th class="text-nowrap">Fecha creación</th>
                    <th class="text-nowrap"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="odd gradeX" *ngFor="let item of correosLicitaciones | tableFilter: filterCorreo">
                    <td class="text-break text-muted">
                        <i class="fas fa-envelope text-primary mr-2"></i>
                        <span>
                            {{item.correo_correlativo}}
                        </span>
                    </td>
                    <td class="text-break">
                        <span>
                            {{item.correo_asunto}}
                        </span>
                    </td>
                    <td>
                        <span class="text-muted">
                            {{item.correo_destinatario }}
                        </span>
                    </td>
                    <td>
                        <span class="text-muted">
                            {{item.correo_fecha_creacion}}
                        </span>
                    </td>
                    <td class="text-center">
                        <!-- <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons> -->
                        <ng-container>
                            <a class="btn rounded-icon mr-2"
                                (click)="onVisualizarCorreo(modalCorreos, item.correo_cuerpo)" title="Visualizar">
                                <i class="fas fa-eye"></i>
                            </a>
                            <app-print-correo [correoBody]="item.correo_cuerpo"></app-print-correo>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!correosLicitaciones || correosLicitaciones.length <= 0">
        <div class="p-2">
            <h4 class="text-center text-muted p-5">Aun no existen correos asociados a esta licitación</h4>
        </div>
    </div>
    <!-- Modal para visualizar los correos -->
    <ng-template #modalCorreos>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Vista Previa</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div [innerHTML]="correoBody"></div>
        </div>
    </ng-template>
</div>