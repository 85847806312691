import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';

import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';
import * as moment from 'src/assets/plugins/moment/moment';
import { IParticipante } from 'src/app/shared/services/licitacion.types';
import { CriteriosEvaluacionService } from 'src/app/shared/services/criterios-evaluacion.service';
import { ItemizadoService } from 'src/app/shared/services/itemizado.service';

@Component({
  selector: 'app-create-licitacion',
  templateUrl: './create-licitacion.component.html',
  styleUrls: ['./create-licitacion.component.css'],
  providers: [ItemizadoService, LicitacionService, CriteriosEvaluacionService]
})
export class CreateLicitacionComponent implements OnInit {

  documentos: [];
  etapas: [];
  licForm : FormGroup;
  idLicitacion : number;
  idEtapaLic : number;
  userId: string;
  postData: { in_usuario: string;} = { in_usuario: '' };
  archivos64 : any[] = [];
  //horasTemp:any[] =[];
  //fechaInicio:any[] = [];
  //fechaFin:any[] = [];
  fechaCronogramaLic: any[] = [];
  showInvitacion : boolean = false;
  showGuardar : boolean = false;

  /*dTemp:any[] =[];
  dTempVal:any[] =[];
  hTemp:any[] =[];
  totalHoras: number;*/

  hInicioTemp:any[] =[]
  fInicioTemp: any[] = []
  hTerminoTemp:any[] =[]
  fTerminoTemp: any[] = []
  fInicioText: any[] = []
  fTerminoText: any[] = [];

  fechaCreacionLicitacion: string = '';
  monedas: any[] = [];

  fileName: string;
  type: string;
  archivos: any[] = [];
  archivos64Adicionales : Blob;
  nombreArchivo: string;

  // validacion de datos de la licitacion
  islicitacionValida: boolean = false;
  // componentes licitacion validos
  isDatosBasicosValidos: boolean = false;
  isCronogramaValido: boolean = false;
  isParticipantesValido: boolean = false;
  isComisionValidoTecnica: boolean = false;
  isComisionValidoEconomica: boolean = false;
  isComisionValidoResponsable: boolean = false;
  isComisionValidoCoordinador: boolean = false;
  isCriteriosValido: boolean = false;
  isitemizadoValido: boolean = false;
  isDocumentosValido: boolean = false;

  cronograma: any[];
  participantes: IParticipante[] = [];
  participantesCom: any[];
  contArchivosBase: number;

  @ViewChild('inputFile')
  inputFile: ElementRef;

  @ViewChild('inputFileAdicional')
  inputFileAdicional: ElementRef;

  montoSolpe: number = 0;
  montoItemizado: number = 0;
  diferenciaMontos: number = 0;

  constructor(
    private licitacionService : LicitacionService,
    private router: Router,
    private etapaService : EtapasService,
    private notifyService : NotificationService,
    private criteriosServices: CriteriosEvaluacionService,
    private objItemizadoService: ItemizadoService
  ) { }

  ngOnInit(): void {
    this.licForm = new FormGroup({
      'solpe' : new FormControl(null),
      'nro_contrato': new FormControl(null),
      'nombre_contrato' : new FormControl(null),
      'descripcion' : new FormControl(null),
      'moneda_solpe' : new FormControl(0),
      'monto_solpe' : new FormControl(null),
      'files': new FormArray ([]),
      'nombreArchivo': new FormControl(null),
    });

    this.getMonedas();

    this.userId = JSON.parse(localStorage.getItem('userData')).userId;

    if (this.userId){
      this.postData.in_usuario = this.userId;
      this.licitacionService.postLicitacion(this.postData).subscribe(resp =>{
        this.idLicitacion = JSON.parse(resp)[0].LIC_CORRELATIVO;
        this.idEtapaLic = JSON.parse(resp)[0].ETLIC_CORRELATIVO;
        this.fechaCreacionLicitacion = JSON.parse(resp)[0].FECHA_CREACION;
        this.showGuardar = true;
      }, err =>{
        this.notifyService.showError(err.error.message, 'Crear licitación');
      });

      this.licitacionService.getDocumentosBase().subscribe(resp=>{
        this.documentos = JSON.parse(resp);
        //console.log(this.documentos);
        this.documentos.forEach(element => {
          let valor = JSON.parse(JSON.stringify(element)).VALOR;
          let descripcion = JSON.parse(JSON.stringify(element)).DESCRIPCION;
          let correlativo = JSON.parse(JSON.stringify(element)).CORRELATIVO;
          const group = new FormGroup({
            'nombreArchivo': new FormControl({value: descripcion,disabled: true}, Validators.required),
            'archivo': new FormControl(null, Validators.required),
            'id_archivo': new FormControl(correlativo, Validators.required),
            'valor': new FormControl(valor, Validators.required)
          });
          (<FormArray>this.licForm.get('files')).push(group);
          this.archivos64.push({ correlativo: correlativo, path:null });
        });
      }, err =>{
        this.notifyService.showError(err.error.message, 'Obtener documentos base licitación');
      });

      this.etapaService.getEtapas().subscribe(resp =>{
        this.etapas = JSON.parse(resp);
      }, err =>{
        this.notifyService.showError(err.error.message, 'Obtener cronograma');
      });
    }
  }
  // onChangeProceso(etapa_correlativo: number, activo:any){
  //   const putData: { in_licitacion: number;in_etapa_correl: number; in_usuario:string; in_activo: string; } = { in_licitacion:0, in_etapa_correl: 0 , in_usuario:'', in_activo:''};
  //   if (etapa_correlativo && this.idLicitacion){
  //     putData.in_licitacion = this.idLicitacion;
  //     putData.in_etapa_correl = etapa_correlativo;
  //     putData.in_activo = activo === true ? 'S' : 'N';
  //     this.licitacionService.putCronogramaLic(putData).subscribe(resp=>{
  //       this.notifyService.showSuccess(JSON.parse(resp), 'Cambio de cronograma');
  //     }, err =>{
  //       this.notifyService.showError(err.error.message, 'Cambio de cronograma');
  //     });
  //   }
  // }

  onValidateLicitacionCompletada(){
    if (this.isDatosBasicosValidos === true && this.isCronogramaValido === true && this.isParticipantesValido === true && this.isComisionValidoTecnica === true && this.isComisionValidoEconomica === true && this.isComisionValidoCoordinador === true && this.isComisionValidoResponsable === true && this.isCriteriosValido === true && this.isitemizadoValido && this.isDocumentosValido) {
      this.islicitacionValida = true;
    } else {
      this.islicitacionValida = false;
    }
  }

  onValidarLicitacion(){
    // validacion de formulario
    if (this.idLicitacion !== 0 && this.licForm.value.solpe !== '' && this.licForm.value.nro_contrato !== '' && this.licForm.value.descripcion !== '' && this.licForm.value.moneda_solpe != 0 && this.licForm.value.monto_solpe !== 0){
      this.isDatosBasicosValidos = true;
    }else{
      this.isDatosBasicosValidos = false;
    }

    this.montoSolpe = this.licForm.value.monto_solpe ? this.licForm.value.monto_solpe : 0;

    // validacion cronograma
    this.licitacionService.getCronogramaLicitacionId(this.idLicitacion).subscribe(resp=>{
      this.cronograma = JSON.parse(resp);

      for (let index = 0; index < this.cronograma.length; index++) {
        const element = this.cronograma[index];
        if (element.ACTIVO == 'S') {
          if (element.FECHA_INICIO_ETAPA !== null) {
            this.isCronogramaValido = true;
          }else{
            this.isCronogramaValido = false;
            break;
          }
        }
      }

      // validacion de participantes oferentes
      this.licitacionService.getParticipantesLic(this.idLicitacion, this.idEtapaLic).subscribe(resp =>{
        this.participantes = JSON.parse(resp);

        if (this.participantes.length > 0) {
          this.isParticipantesValido = true;
        } else {
          this.isParticipantesValido = false;
        }

        // validacion de participantes de la comision evaluadora
        this.licitacionService.getParticipantesComLicitacion(this.idLicitacion).subscribe(resp =>{
          this.participantesCom = JSON.parse(resp);
          this.isComisionValidoTecnica = false;
          this.isComisionValidoEconomica = false;
          this.isComisionValidoCoordinador = false;
          this.isComisionValidoResponsable = false;

          for (let index = 0; index < this.participantesCom.length; index++) {
            const element = this.participantesCom[index];
            if (element.TIPO_PARTICIPANTE == 7) {
              this.isComisionValidoTecnica = true;
            }

            if (element.TIPO_PARTICIPANTE == 8) {
              this.isComisionValidoEconomica = true;
            }

            if (element.TIPO_PARTICIPANTE == 36) {
              this.isComisionValidoCoordinador = true;
            }

            if (element.TIPO_PARTICIPANTE == 37) {
              this.isComisionValidoResponsable = true;
            }
          }

          // validacion de los criterios
          this.criteriosServices.getGrupos(this.idLicitacion).subscribe(resp => {
            let response: any = resp;

            if(response.data?.length > 0){
              this.isCriteriosValido = true;
            }else{
              this.isCriteriosValido = false;
            }

            // validacion de itemizado
            this.objItemizadoService.getItemizadoParticipante(this.idLicitacion).subscribe(resp => {
              let response: any = resp;

              let totalitemizado: number = 0;
              //this.objItemizadoLs
              for(let y = 0; y < response.data?.length; y++)
              {
                for(let i = 0; i < response.data[y]?.itemizadoDetalleLs?.length; i++)
                {
                  totalitemizado = totalitemizado + (response.data[y]?.itemizadoDetalleLs[i]?.cantidad * response.data[y]?.itemizadoDetalleLs[i]?.precio_unitario);
                }
              }

              this.montoItemizado = totalitemizado;
              this.diferenciaMontos = (this.montoItemizado - this.montoSolpe);
        
              if(response.data?.length > 0){
                this.isitemizadoValido = true;
              }else{
                this.isitemizadoValido = false;
              }

              // validacion de archivos
              this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,0).subscribe(resp=>{
                let archivosAdicionales: any[] = JSON.parse(resp);
                if (archivosAdicionales.length > 0) {
                  this.isDocumentosValido = true;
                } else {
                  this.isDocumentosValido = false;
                }

                this.contArchivosBase = archivosAdicionales.length;
                this.onValidateLicitacionCompletada();
              });

            }, 
            error => {console.log(error)},
            () => {
              
            });

          }, 
          error => {console.log(error)},
          () => {
            
          });

        }, err =>{
          this.notifyService.showError(err.error.message, 'Obtener Participantes Comisión Licitación');
        });

      });
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener cronograma licitación');
    });

  }

  getMonedas(){
    this.licitacionService.getMonedasSolpeLicitacion().subscribe(resp =>{
      this.monedas = JSON.parse(resp);
      console.log(this.monedas);
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener Monedas para la licitacion');
    });
  }

  detectFiles(event: any, idF: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {

      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
          this.archivos64.map((item, i) => {
            if (item.correlativo == idF){
              this.archivos64[i].fileName = event.target.files[0].name;
              this.archivos64[i].type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
              this.archivos64[i].path = bs64;
             }
           });
        }
      };
    }
  }

  onUpdateLicitacion(){
    if (this.idLicitacion){
      const putData: { 
        in_licitacion: number;
        in_solpe: number;
        in_cont_correlativo: number; 
        in_cont_nombre:string; 
        in_usuario:string; 
        in_descripcion: string; 
        in_moneda_solpe: number;
        in_monto_solpe: number;
        in_fecha_publicacion: string;
      } = { 
        in_licitacion: 0, 
        in_solpe:0, 
        in_cont_correlativo:0, 
        in_cont_nombre: '', 
        in_usuario: '', 
        in_descripcion: '', 
        in_moneda_solpe: 0,
        in_monto_solpe: 0,
        in_fecha_publicacion: null
      };
      
      putData.in_licitacion = this.idLicitacion;
      putData.in_solpe = this.licForm.value.solpe;
      putData.in_cont_correlativo = this.licForm.value.nro_contrato;
      putData.in_cont_nombre = this.licForm.value.nombre_contrato;
      putData.in_descripcion = this.licForm.value.descripcion;
      putData.in_moneda_solpe = this.licForm.value.moneda_solpe;
      putData.in_monto_solpe = this.licForm.value.monto_solpe;

      if (putData.in_licitacion !== 0 && putData.in_solpe && putData.in_cont_correlativo && putData.in_cont_nombre && putData.in_monto_solpe !== 0 && putData.in_moneda_solpe != 0){
        this.licitacionService.putLicitacion(putData).subscribe(resp=>{
          if (resp){
            this.archivos64.map((item, i) => {
              if (item.path){
                  const postData: { 
                    in_det_etapa_lic: number;
                    files: Blob; 
                    fileName: string; 
                    type : string; 
                    in_usuario: string; 
                    in_domi_correlativo_tiar: number;
                    publico: number;
                    descripcion_input_archivo: string;
                    idLicitacion: number;
                    idEtapaLicitacion: number;
                    correlativoGrupoArchivo: number
                  } = 
                  { 
                    in_det_etapa_lic: this.idEtapaLic , 
                    files : item.path, 
                    fileName: item.fileName, 
                    type: item.type, in_usuario:'', 
                    in_domi_correlativo_tiar: item.correlativo, 
                    publico: 1,
                    descripcion_input_archivo: item.fileName,
                    idLicitacion: this.idLicitacion,
                    idEtapaLicitacion: this.idEtapaLic,
                    correlativoGrupoArchivo: 0
                  };

                  this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
                    this.notifyService.showSuccess('Archivo subido correctamente.', 'Subir archivos licitacion');
                  }, err =>{
                    this.notifyService.showError(err.error.message, 'Subir archivos licitacion');
                  });
              }
            });
          }
          this.notifyService.showSuccess(JSON.parse(resp), 'Actualizar licitación');
          this.verificarLic(this.idLicitacion);
        }, err =>{
          this.notifyService.showError(err.error.message, 'Actualizar licitación');
        });
      }else{
        this.notifyService.showError('Favor llene los campos obligatorios.', 'Actualizar licitación');
      }
    }
  }
  private verificarLic(idLic: number){
    if (idLic){
      this.licitacionService.getValidaLic(idLic).subscribe(resp=>{

        switch (JSON.parse(resp)[0].LIC_VALIDA){
          case 0:
            this.showInvitacion = false;
            this.showGuardar = true;
            this.notifyService.showWarning(JSON.parse(resp)[0].MENSAJE, 'Valida licitación');
          break;
          case 1:
            this.showInvitacion = true;
            this.showGuardar = false;
            this.notifyService.showSuccess(JSON.parse(resp)[0].MENSAJE, 'Valida licitación');
          break;
        }
      }, err =>{
        this.notifyService.showError(err.error.message, 'Valida licitación');
      });
    }
  }
  
  onEnviarInvitacion(){
    const putData: { in_licitacion: number; in_etapa: number, in_usuario:string; } = { in_licitacion : this.idLicitacion, in_etapa: 0 , in_usuario: ''}
    this.licitacionService.putEnviarInvitacionLic(putData).subscribe(resp=>{
      this.notifyService.showSuccess(JSON.parse(resp), 'Envio invitación');
      this.router.navigate(['/licitaciones']);
    });
  }

  onCalHrInicio( idEtapa: number,hrs: number){
    if (hrs < 25 && hrs >0){
      this.hInicioTemp[idEtapa]  = Number.isNaN(hrs) ? 0 : hrs;
      this.licitacionService.getFormatearFecha(moment(this.fInicioTemp[idEtapa]).format('YYYY-MM-DD')).subscribe(resp => {
        if(resp) {
          this.fInicioText[idEtapa] = JSON.parse(resp)[0].FECHA_PALABRAS + ', ' + hrs + ':00 hrs.'
        }
      })
    }else{
      this.hInicioTemp[idEtapa]= 0;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }
  }
  
  onCalHrTermino( idEtapa: number,hrs: number){
    if (hrs < 25 && hrs >0){
      this.hTerminoTemp[idEtapa]  = Number.isNaN(hrs) ? 0 : hrs;
      this.hInicioTemp[idEtapa + 1]  = Number.isNaN(hrs) ? 0 : hrs;

      this.licitacionService.getFormatearFecha(moment(this.fTerminoTemp[idEtapa]).format('YYYY-MM-DD')).subscribe(resp => {
        if(resp) {
          this.fInicioText[idEtapa + 1] = JSON.parse(resp)[0].FECHA_PALABRAS + ', ' + ('0'+hrs).slice(-2) + ':00 hrs.'
          this.fTerminoText[idEtapa] = JSON.parse(resp)[0].FECHA_PALABRAS + ', ' + ('0'+hrs).slice(-2) + ':00 hrs.'
        }
      })
    }else{
      this.hTerminoTemp[idEtapa]= 0;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }

    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa] + 'T' + ('0'+this.hInicioTemp[idEtapa]).slice(-2) + ':00' ,
      in_fecha_fin: this.fTerminoTemp[idEtapa] + 'T' + ('0'+this.hTerminoTemp[idEtapa]).slice(-2) + ':00',
      in_hora: 1
    })
  }
}
