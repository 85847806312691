<!-- begin #content -->
<div id="content" class="content no-print">
    <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-primary" routerLink="/licitaciones"><i _ngcontent-ftv-c76=""
                class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
        <button *ngIf="dataLicitacion?.ETAPA_CORRELATIVO == 0" type="button" class="btn btn-primary" data-toggle="modal"
            data-target="#modalEliminarLicitacion"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-trash-alt"
                style="color: white;"></i> Eliminar
            Licitación</button>
    </div>
</div>

<!-- begin #content -->
<div id="content" class="content" id="printPage">
    <!-- begin row -->
    <div class="row">

        <div class="col-12 col-xl-2">
            <div class="panel panel-inverse p3-rem rounded" data-sortable-id="form-stuff-1">
                <p class="cursor-pointer" (click)="currentStep = 1">
                    <i *ngIf="isDatosBasicosValidos" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isDatosBasicosValidos" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 1}">Datos Generales</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 2">
                    <i *ngIf="isParticipantesValido" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isParticipantesValido" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 2}">Oferentes</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 3">
                    <i *ngIf="isComisionValidoTecnica && isComisionValidoEconomica && isComisionValidoResponsable && isComisionValidoCoordinador && isComisionValidLiderComisionTecnica"
                        class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isComisionValidoTecnica || !isComisionValidoEconomica || !isComisionValidoResponsable || !isComisionValidoCoordinador || !isComisionValidLiderComisionTecnica"
                        class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 3}">Comisiones</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 4">
                    <i *ngIf="isCronogramaValido" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isCronogramaValido" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 4}">Cronograma</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 5">
                    <i *ngIf="isCriteriosValido === true" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="isCriteriosValido !== true" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 5}">Ev. Tecnica</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 6">
                    <i *ngIf="isitemizadoValido" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isitemizadoValido" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 6}">Presupuesto</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 7">
                    <i *ngIf="isDocumentosValido" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isDocumentosValido" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 7}">Doc. Base (EMSA)</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 8">
                    <i *ngIf="isDocumentosValidoOferente" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isDocumentosValidoOferente" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 8}">Doc. ADM - ECO - TEC</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 9">
                    <i *ngIf="isMatrizRiesgoValida" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isMatrizRiesgoValida" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 9}">Matriz de Categorización</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 10">
                    <i *ngIf="isActaValida" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isActaValida" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 10}">Acta de Inicio</span>
                </p>
                <p class="cursor-pointer" (click)="currentStep = 11">
                    <i *ngIf="isValidacionLct" class="fas fa-check-circle icon-18 text-success"></i>
                    <i *ngIf="!isValidacionLct" class="fas fa-circle text-muted icon-18"></i>
                    <span class="ml-2" [ngClass]="{'currentStep': currentStep == 11}">Validación LCT</span>
                </p>
                <ng-container *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                    <div *ngIf="montoItemizado > 0" class="row mt-4 mb-0 border">
                        <hr>
                        <div class="col-12 mt-2">
                            <p class="mb-0">Licitacion:</p>
                            <p class="text-right font-weight-bold mb-0">{{dataLicitacion.MONTO_SOLPE | number:'1.2'}}</p>
                        </div>
                        <div class="col-12" class="bg-gray-transparent-2 col-12" style="height: 1px;">
    
                        </div>
                        <div class="col-12">
                            <p class="mb-0">Itemizado:</p>
                            <p class="text-right font-weight-bold mb-2">{{montoItemizado | number:'1.2'}}</p>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="(islicitacionValida === true && validateRoleParticipanteComision(37)) && dataLicitacion?.ETAPA_CORRELATIVO == 0"
                    class="col-12 text-c">
                    <button type="button" class="btn btn-primary mt-2 mb-0" (click)="onEnviarInvitacion()">Enviar
                        invitación</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-10">
            <!-- begin panel -->
            <div class="panel panel-inverse p3-rem rounded" data-sortable-id="form-stuff-1">
                <div class="row">
                    <label class="col-12 col-md-6 text-muted">Número Licitación: {{idLicitacion}}</label>
                    <label class="col-12 col-md-6 text-right text-muted"
                        id="fechaCreacionLicitacion">{{dataLicitacion?.FECHA_CREACION}}</label>
                </div>
                <div class="row mb-4 mt-2" *ngIf="currentStep > 1">
                    <div class="col-12">
                        <h1>
                            {{dataLicitacion.CONT_NOMBRE}}
                        </h1>
                    </div>
                    <div class="col-12">
                        <p class="text-muted">
                            {{dataLicitacion.DESCRIPCION}}
                        </p>
                    </div>
                </div>
                <div class="step1" *ngIf="currentStep == 1">
                    <form [formGroup]="licitacionForm" novalidate (ngSubmit)="onUpdateLicitacion()">
                        <div class="row mt-4 mb-4">
                            <div class="col-12">
                                <h2>Datos generales</h2>
                            </div>
                        </div>
                        <!-- <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">SOLPE</label>
                            <div class="col-md-10">
                                <custom-select [options]="aSolpesSelect" (optionSelected)="onOptionSelected($event)">
                                </custom-select>
                            </div>
                        </div> -->
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Codigo SOLPE</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="" formControlName="solpe" readonly>
                            </div>
                        </div>
                        <div class="form-group row m-b-15" *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                            <label class="col-form-label col-md-2">Monto Original SOLPE</label>
                            <div class="col-md-10">
                                <span>{{montoSolpeOriginal | number:'1.2'}}</span>
                            </div>
                        </div>
                        <div class="form-group row m-b-15" *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                            <label class="col-form-label col-md-2">Moneda Original SOLPE</label>
                            <div class="col-md-10">
                                <span>{{monedaSolpeOriginal}}</span>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Nombre de contrato</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" id="nombre_contrato"
                                    formControlName="nombre_contrato">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Descripcion de la licitacion <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <textarea class="form-control" id="descripcion"
                                    formControlName="descripcion"></textarea>
                            </div>
                        </div>
                        <div class="form-group row m-b-15" *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                            <label class="col-form-label col-md-2">Monto Licitación <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" type="number" id="monto_solpe"
                                    formControlName="monto_solpe">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Moneda Licitación <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <!-- <select class="default-select2 form-control no-print" id="moneda_solpe" formControlName="moneda_solpe" (change)="onUpdateMonedaSolpe()"> -->
                                <select class="form-control no-print" id="moneda_solpe" formControlName="moneda_solpe"
                                    disabled>
                                    <option value="0">Seleccione ...</option>
                                    <option *ngFor="let item of monedas; let i = index" value="{{item.id}}">
                                        {{item.description}}</option>
                                </select>
                                <!-- <input class="form-control print" type="text" [value]="moneda_solpe"> -->
                                <input class="form-control print" type="text">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Número de contrato</label>
                            <div class="col-md-10">
                                <input class="form-control" type="text" placeholder="ej: CO-0XX/2018 ..."
                                    id="nro_contrato" formControlName="nro_contrato">
                            </div>
                        </div>
                        <div *ngIf="this.dataLicitacion?.MONTO_SOLPE_USD >= 500000" class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Fecha estimada de publicación <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input class="form-control" #fecha_publicacion type="date"
                                    formControlName="fecha_publicacion" id="fecha_publicacion">
                            </div>
                        </div>
                        <div class="row" *ngIf="dataLicitacion?.ETAPA_CORRELATIVO == 0">
                            <ng-container *ngIf="validateRoleParticipanteComision(37) || validateRoleParticipanteComision(39)">
                                <div class="col-12 text-right">
                                    <button type="submit" class="btn btn-primary">Guardar</button>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step2" *ngIf="currentStep == 2">
                    <p class="text-muted">IMPORTANTE: Seleccionar un mínimo de 3 participantes para cada Licitación.</p>
                    <app-table-participantes [idLicitacion]="idLicitacion"
                        [idEtapaLic]="dataLicitacion?.CORRELATIVO_ETAPA"
                        (data)="onUpdateOferentes($event)"></app-table-participantes>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step3" *ngIf="currentStep == 3">
                    <div class="row">
                        <div class="col-6"></div>
                        <div *ngIf="isNotificaicioneEnviada == true" class="col-6 text-right">
                            <p class="text-muted h4">Notificaciones ya fueron enviadas <i
                                    class="fa fa-check-circle text-green mr-2"></i></p>
                        </div>
                        <div *ngIf="isNotificaicioneEnviada == false" class="col-6 text-right">
                            <p class="text-muted h4">La notificación no ha sido enviada a todos los integrantes <i
                                    class="fa fa-times text-red mr-2"></i></p>
                        </div>
                    </div>
                    <app-table-participantes-com [idLicitacion]="idLicitacion"
                        (data)="onUpdateParticipante($event)"></app-table-participantes-com>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button *ngIf="isNotificaicioneEnviada == false" type="button" class="btn btn-primary mr-2"
                                (click)="sendNotificacionComision()">Enviar notificación</button>
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step4" *ngIf="currentStep == 4">
                    <ng-container *ngIf="dataLicitacion?.ETAPA_CORRELATIVO == 0">
                        <app-cronograma-fechas [idLicitacion]="idLicitacion" [idEtapa]="0"
                            (data)="onUpdateCronograma($event)"
                            [fecha_inicio]="dataLicitacion?.FECHA_PUBLICACION"></app-cronograma-fechas>
                    </ng-container>
                    <ng-container *ngIf="dataLicitacion?.ETAPA_CORRELATIVO != 0">
                        <app-cronograma-fechas [idLicitacion]="idLicitacion" [idEtapa]="16"></app-cronograma-fechas>
                    </ng-container>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step4" *ngIf="currentStep == 5">
                    <create-criterios [idLicitacionParent]="idLicitacion"
                        [actaInicio]="dataLicitacion?.ETAPA_CORRELATIVO == 0"
                        (data)="updateCriterios($event)"></create-criterios>
                    <ng-container *ngIf="dataLicitacion?.ETAPA_CORRELATIVO != 0">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button type="button" class="btn btn-primary"
                                    (click)="currentStep = currentStep + 1">Siguiente</button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="step4" *ngIf="currentStep == 6">
                    <app-create-itemizado [idLicitacionParent]="idLicitacion"
                        [actaInicio]="dataLicitacion?.ETAPA_CORRELATIVO == 0"
                        (isUpdate)="updateItemizado($event)"></app-create-itemizado>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step4" *ngIf="currentStep == 7">
                    <app-cargar-archivos-licitacion [idLicitacion]="idLicitacion"
                        [idEtapa]="dataLicitacion?.CORRELATIVO_ETAPA" (data)="onUpdateArchivos($event)"
                        [currentStep]="currentStep"
                        [actaInicio]="dataLicitacion?.ETAPA_CORRELATIVO == 0"></app-cargar-archivos-licitacion>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step4" *ngIf="currentStep == 8">
                    <div class="row">
                        <div class="col-12">
                            <h4>Seleccione los documentos ADM, ECO y TEC:</h4>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <ng-container *ngIf="dataLicitacion?.ETAPA_CORRELATIVO == 0">
                            <div class="col-md-6">
                                <form [formGroup]="formDocRequeridosOferenteActa"
                                    (ngSubmit)="onAddDocRequeridosOferenteActa()">
                                    <div class="row form-group">
                                        <label for="docRequeridoOferente"
                                            class="col-md-3 col-form-label">Documentos</label>
                                        <div class="col-md-9">
                                            <select class="form-control"
                                                formControlName="docRequeridoOferenteCorrelativo"
                                                #docRequeridoOferenteCorrelativo>
                                                <ng-container *ngFor="let doc of docRequeridosOferenteActa">
                                                    <option value="{{doc.correlativo}}">{{doc.descripcion}}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button type="submit" class="btn btn-primary"
                                                [disabled]="formDocRequeridosOferenteActa.invalid">Agregar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <div class="col-md-6">
                            <ng-container *ngIf="docRequeridosOferenteToSave.length > 0">
                                <div class="docRequeridoContainer" *ngFor="let doc of docRequeridosOferenteToSave">
                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="text-muted">{{ doc.tipo_doc_descripcion }}</h5>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="doc.tipo_doc_correlativo === 2" class="row">
                                        <div class="col-12">
                                            <h5 class="text-muted">{{ doc.tipo_doc_descripcion }}</h5>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-8">
                                            <span class="text-muted"><i class="far fa-file-alt text-primary mr-2"></i>
                                                {{doc.descripcion}}</span>
                                        </div>
                                        <div class="col-4 text-right">
                                            <ng-container *ngIf="dataLicitacion?.ETAPA_CORRELATIVO == 0">
                                                <div title="Eliminar Documento" class="btn rounded-icon mr-2"
                                                    (click)="deleteDocRequeridoOferente(doc.correlativo)">
                                                    <i class="fa fa-trash-alt"></i>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="docRequeridosOferenteToSave.length <= 0">
                                <p class="text-muted text-center">No hay documentos requeridos seleccionados.</p>
                            </ng-container>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step4" *ngIf="currentStep == 9">
                    <app-cargar-archivos-licitacion [idLicitacion]="idLicitacion"
                        [idEtapa]="dataLicitacion?.CORRELATIVO_ETAPA" (data)="onUpdateArchivos($event)"
                        [currentStep]="currentStep" [actaInicio]="dataLicitacion?.ETAPA_CORRELATIVO == 0"></app-cargar-archivos-licitacion>
                    <br>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div class="step4" *ngIf="currentStep == 10">
                    <button *ngIf="dataLicitacion" type="button" class="btn btn-primary" style="margin-bottom: 3px;"
                        [disabled]="downloadingPdf" (click)="onDescargarPDF()">
                        <i class="fas fa-lg fa-fw m-r-10 fa-file-pdf"></i>
                        Descargar acta de inicio
                    </button>
                    <app-cargar-archivos-licitacion [idLicitacion]="idLicitacion"
                        [idEtapa]="dataLicitacion?.CORRELATIVO_ETAPA" (data)="onUpdateArchivos($event)"
                        [currentStep]="currentStep"
                        [actaInicio]="dataLicitacion?.ETAPA_CORRELATIVO == 0"></app-cargar-archivos-licitacion>
                    <br>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="currentStep = currentStep + 1">Siguiente</button>
                        </div>
                    </div>
                </div>
                <!-- falta gregar el formulario para la observacion en caso de rechazo -->
                <div class="step4" *ngIf="currentStep == 11">
                    <ng-container *ngIf="validateRoleParticipanteComision(39)">
                        <ng-container *ngIf="!isValidacionLct">
                            <p>Es necesaria su aprobación como líder de la comisión técnica designado para esta
                                licitación</p>
                            <div class="row">
                                <ng-container *ngIf="observacionLct !== ''">
                                    <div class="col-12">
                                        <hr>
                                        <h5 class="text-muted">Última observación de rechazo:</h5>
                                        <p class="text-muted">{{ observacionLct }}</p>
                                        <hr>
                                    </div>
                                </ng-container>
                                <div class="col-12">
                                    <button type="button" class="btn btn-primary mr-2" data-toggle="modal"
                                        data-target="#validacionLctModal" (click)="validacionLct = 1">Aprobar</button>
                                    <button type="button" class="btn btn-primary" data-toggle="modal"
                                        data-target="#validacionLctModal" (click)="validacionLct = 0">Rechazar</button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isValidacionLct">
                            <div class="row">
                                <div class="col-12">
                                    <h5 class="text-muted"><i class="fas fa-check-circle text-success"></i> El Acta de
                                        Inicio ha sido Aprobada por el Líder de la Comisión Técnica.</h5>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!validateRoleParticipanteComision(39)">
                        <ng-container *ngIf="!isValidacionLct">
                            <p class="text-muted">La licitación debe ser aprobada por el líder de la comisión técnica
                                designado.</p>
                            <ng-container *ngIf="observacionLct !== ''">
                                <div class="row">
                                    <div class="col-12">
                                        <hr>
                                        <h5 class="text-muted">Observación de rechazo:</h5>
                                        <p class="text-muted">{{ observacionLct }}</p>
                                        <hr>
                                        <button class="btn btn-primary text-right"
                                            (click)="getGenerateNotificacionRevisionLctByResponsable()">Enviar solicitud
                                            de revisión</button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="isValidacionLct">
                            <div class="row">
                                <div class="col-12">
                                    <h5 class="text-muted"> <i class="fas fa-check-circle text-success"></i> El Acta de
                                        Inicio ha sido Aprobada por el Líder de la Comisión Técnica.</h5>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Eliminar licitacion-->
<div class="modal fade" id="modalEliminarLicitacion" tabindex="-1" role="dialog"
    aria-labelledby="modalEliminarLicitacion" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEliminarLicitacion">¿Desea eliminar la Licitación actual?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeEliminarLicitacion>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Una vez eliminada la licitación no podrá recuperar los datos que hayan ingresado en la misma.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="deleteLicitacion()">Eliminar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal validacion LCT-->
<div class="modal fade" id="validacionLctModal" tabindex="-1" role="dialog" aria-labelledby="validacionLctModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="validacionLctModalLabel">
                    <ng-container *ngIf="validacionLct == 1">
                        ¿Desea seleccionar esta opción para aprobar la licitación?
                    </ng-container>
                    <ng-container *ngIf="validacionLct == 0">
                        ¿Desea seleccionar esta opción para rechazar la licitación?
                    </ng-container>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeValidarLicitacion>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="validacionLct == 0">
                    <p>Por favor ingrese una observacion por la cual la licitación será rechazada.</p>
                    <textarea [(ngModel)]="observacionLct" class="form-control" rows="5"></textarea>
                </ng-container>
                <ng-container *ngIf="validacionLct == 1">
                    <p>La licitación será aprobada, por favor confirme que los datos son correctos.</p>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="validarLicitacionLct()">Enviar</button>
            </div>
        </div>
    </div>
</div>
<!-- Cronograma para el PDF -->
<table id="table_cronograma" class="table table-striped table-td-valign-middle mb-4 d-none">
    <thead class="thead-dark">
        <tr>
            <th class="text-nowrap">
                Actividad
            </th>
            <!-- <th width="5%" class="text-nowrap">Nro. Horas</th> -->
            <th class="text-nowrap">
                Fecha Inicio
            </th>
            <th class="text-nowrap">
                Fecha Fin
            </th>
            <th class="text-nowrap">
                Obligatoria
            </th>
        </tr>
    </thead>
    <tbody>
        <tr class="odd gradeX" *ngFor="let item of cronograma">
            <td [ngStyle]="{'background-color':item.ETAPA_ACTUAL === 'S' ? '#fefe88' : 'transparent'}">
                <i *ngIf="item.ETAPA_ACTUAL === 'S'" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-right"
                    style="color: black;"></i>
                <!-- menu -->
                <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL === 'S' && item.ETAPA_CORRELATIVO != 17">
                    <span>
                        {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)':
                            ''}}</strong>
                    </span>
                </a>
                <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL === 'S' && item.ETAPA_CORRELATIVO == 17">
                    <span>
                        {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)':
                            ''}}</strong>
                    </span>
                </a>
                <a *ngIf="item.ETLIC_CORRELATIVO && item.ETAPA_ACTUAL !== 'S'">
                    <span>
                        {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)':
                            ''}}</strong>
                    </span>
                </a>
                <a *ngIf="!item.ETLIC_CORRELATIVO">
                    <span>
                        {{ item.ETAPA_DESCRIPCION}} <strong>{{ item.ETAPA_ACTUAL === 'S' ? '(Etapa actual)':
                            ''}}</strong>
                    </span>
                </a>
            </td>
            <!-- <td width="5%" [ngStyle]="{'background-color':item.ETAPA_ACTUAL === 'S' ? '#fefe88' : 'transparent' }">{{item.CANT_HORAS}}</td> -->
            <td [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                <span>
                    <span [class]="pdfClassElement">{{getDayNameByDate(item.FECHA_INICIO_ETAPA)}}</span>
                    {{item.FECHA_INICIO_ETAPA | date: 'dd/MM/yyyy'}}
                </span>
            </td>
            <td [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                <span>
                    <span [class]="pdfClassElement">{{getDayNameByDate(item.FECHA_TERMINO_ETAPA)}}</span>
                    {{item.FECHA_TERMINO_ETAPA | date: 'dd/MM/yyyy'}}
                </span>
            </td>
            <td [ngStyle]="{'background-color':item.ETAPA_ACTUAL ==='S' ? '#fefe88' : 'transparent' }">
                <span class="tab tab-yellow" *ngIf="item.ETAPA_OBLIGATORIA === 1">
                    Obligatoria
                </span>
            </td>
        </tr>
    </tbody>
</table>
<table style="margin-top: 10px;" id="table_archivos" class="table table-striped table-td-valign-middle d-none">
    <thead class="thead-dark">
        <tr>
            <th class="text-nowrap">Nombre archivo</th>
            <th class="text-nowrap">Descripción</th>
            <th class="text-nowrap">Etapa</th>
            <th class="text-nowrap">Fecha creación</th>
            <th class="text-nowrap">Subido</th>
            <th class="text-nowrap"></th>
        </tr>
    </thead>
    <tbody>
        <tr class="odd gradeX" *ngFor="let item of archivosLicitacion">
            <td class="text-break">
                <i class="fas fa-file text-primary mr-2"></i>
                <span>
                    {{item.DESCRIPCION}}
                </span>
            </td>
            <td class="text-break">
                <span>
                    {{item.DESCRIPCION_ARCHIVO}}
                </span>
            </td>
            <td class="text-break">
                <span class="text-muted">
                    {{item.ETAPA_DESCRIPCION}}
                </span>
            </td>
            <td>
                <span class="text-muted">
                    {{item.FECHA_ARCHIVO }}
                </span>
            </td>
            <td>
                <span class="text-muted">
                    {{item.USUARIO}}
                </span>
                <!-- <ng-template [ngIf]="item.TIPO_ARCHIVO"> - </ng-template>
                {{item.NOMBRE_ARCHIVO.split('/').pop()}} -->
            </td>
            <td class="text-center">
                <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
            </td>
        </tr>
    </tbody>
</table>
<div class="d-none">
    <app-table-participantes-com [idLicitacion]="idLicitacion"
        (data)="onUpdateParticipante($event)"></app-table-participantes-com>
</div>
<div class="d-none">
    <app-table-participantes [idLicitacion]="idLicitacion" [idEtapaLic]="dataLicitacion?.CORRELATIVO_ETAPA"
        (data)="onUpdateOferentes($event)"></app-table-participantes>
</div>