import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LicitacionService } from "../shared/services/licitacion.service";
import { NotificationService } from "../shared/services/notification.service";

interface MotivosRenuncia {
  correlativo: number;
  descripcion: string;
  tipo: string;
  vigente: number;
  fecha_creacion: Date;
}

@Component({
  selector: "app-mantenedor-motivos-renuncia",
  templateUrl: "./mantenedor-motivos-renuncia.component.html",
  styleUrls: ["./mantenedor-motivos-renuncia.component.css"],
})
export class MantenedorMotivosRenunciaComponent implements OnInit {
  motivoSeleccionadoDescripcion: string;
  motivoRenuncia: MotivosRenuncia[];
  motivoRenunciaFiltered: MotivosRenuncia[];
  formCreateMotivoRenuncia: FormGroup;
  formUpdateMotivoRenuncia: FormGroup;
  motivoCorrelativoSelected: number;
  @ViewChild("closeModalCrearMotivoRenuncia")
  closeModalCrearMotivoRenuncia: ElementRef;
  @ViewChild("closeModalEliminarMotivoRenuncia")
  closeModalEliminarMotivoRenuncia: ElementRef;
  @ViewChild("closeModalUpdateMotivoRenuncia")
  closeModalUpdateMotivoRenuncia: ElementRef;

  constructor(
    private licitacionService: LicitacionService,
    private notifyService: NotificationService
  ) {
    this.motivoSeleccionadoDescripcion = "";
    this.motivoRenuncia = [];
    this.motivoRenunciaFiltered = [];
    this.motivoCorrelativoSelected = 0;

    this.formCreateMotivoRenuncia = new FormGroup({
      descripcion: new FormControl("", Validators.required),
      tipo: new FormControl("", Validators.required),
    });

    this.formUpdateMotivoRenuncia = new FormGroup({
      descripcion: new FormControl("", Validators.required),
      tipo: new FormControl("", Validators.required),
      vigente: new FormControl("0", Validators.required),
    });
  }

  ngOnInit(): void {
    this.getMotivosRenuncia();
  }

  getMotivosRenuncia() {
    this.licitacionService.getMotivosRenunciaLicitacion().subscribe(
      (resp) => {
        const motivosRenuncia: MotivosRenuncia[] = JSON.parse(resp);

        if (motivosRenuncia.length > 0) {
          this.motivoRenuncia = motivosRenuncia;
          this.motivoRenunciaFiltered = motivosRenuncia;
        } else {
          this.motivoRenuncia = [];
          this.motivoRenunciaFiltered = [];
        }
      },
      (error) => {
        this.motivoRenuncia = [];
        this.motivoRenunciaFiltered = [];
        this.notifyService.showError(
          error.error.message,
          "Error al obtener los motivos de renuncia"
        );
      }
    );
  }

  onEliminarMotivoRenunciaCriterio() {
    const postDataEliminarMotivoRenuncia = {
      correlativo_renuncia: this.motivoCorrelativoSelected,
    };

    this.licitacionService
      .postDeteleMotivoRenunciaLicitacion(postDataEliminarMotivoRenuncia)
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Se elimino el motivo de renuncia correctamente.",
            "Eliminar motivo de renuncia"
          );
          this.motivoCorrelativoSelected = 0;
          this.closeModalEliminarMotivoRenuncia.nativeElement.click();
          this.getMotivosRenuncia();
        },
        (error) => {
          this.notifyService.showError(
            error.error.message,
            "Error al eliminar motivo de renuncia"
          );
        }
      );
  }

  onCrearMotivoRenuncia() {
    const postDataCrearMotivoRenuncia = {
      motivo_renuncia: this.formCreateMotivoRenuncia.value.descripcion,
      tipo_motivo_renuncia: this.formCreateMotivoRenuncia.value.tipo,
    };

    this.licitacionService
      .postInsertMotivoRenunciaLicitacion(postDataCrearMotivoRenuncia)
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Se ingreso el motivo de renuncia correctamente.",
            "Insertar motivo de renuncia"
          );
          this.resetFormCreateMotivoRenuncia();
          this.closeModalCrearMotivoRenuncia.nativeElement.click();
          this.getMotivosRenuncia();
        },
        (error) => {
          this.notifyService.showError(
            error.error.message,
            "Error al insertar motivo de renuncia"
          );
        }
      );
  }

  onUpdateMotivoRenuncia() {
    const postDataUpdateMotivoRenuncia = {
      motivo_renuncia: this.formUpdateMotivoRenuncia.value.descripcion,
      tipo_motivo_renuncia: this.formUpdateMotivoRenuncia.value.tipo,
      vigente: this.formUpdateMotivoRenuncia.value.vigente,
      correlativo_renuncia: this.motivoCorrelativoSelected,
    };

    this.licitacionService
      .postUpdateMotivoRenunciaLicitacion(postDataUpdateMotivoRenuncia)
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Se actualizo el motivo de renuncia correctamente.",
            "Actualizacion motivo de renuncia"
          );
          this.resetFormUpdateMotivoRenuncia();
          this.closeModalUpdateMotivoRenuncia.nativeElement.click();
          this.getMotivosRenuncia();
        },
        (error) => {
          this.notifyService.showError(
            error.error.message,
            "Error al actualizar motivo de renuncia"
          );
        }
      );
  }

  eliminarMotivoRenuncia(idMotivoRenuncia: number) {
    this.motivoCorrelativoSelected = idMotivoRenuncia;
    const motivoRenuncia: MotivosRenuncia = this.motivoRenuncia.filter(
      (data) => data.correlativo === idMotivoRenuncia
    )[0];
    this.motivoSeleccionadoDescripcion = motivoRenuncia?.descripcion;
  }

  editarMotivoRenuncia(idMotivoRenuncia: number) {
    this.motivoCorrelativoSelected = idMotivoRenuncia;

    const motivoRenuncia: MotivosRenuncia = this.motivoRenuncia.filter(
      (data) => data.correlativo === idMotivoRenuncia
    )[0];

    console.log(motivoRenuncia);

    this.resetFormUpdateMotivoRenuncia();
    this.formUpdateMotivoRenuncia.controls.descripcion.setValue(
      motivoRenuncia?.descripcion
    );
    this.formUpdateMotivoRenuncia.controls.tipo.setValue(motivoRenuncia?.tipo);
    this.formUpdateMotivoRenuncia.controls.vigente.setValue(
      motivoRenuncia?.vigente.toString()
    );
    this.formUpdateMotivoRenuncia.updateValueAndValidity();
  }

  resetFormUpdateMotivoRenuncia() {
    this.formUpdateMotivoRenuncia = new FormGroup({
      descripcion: new FormControl("", Validators.required),
      tipo: new FormControl("", Validators.required),
      vigente: new FormControl("0", Validators.required),
    });
  }

  resetFormCreateMotivoRenuncia() {
    this.formCreateMotivoRenuncia = new FormGroup({
      descripcion: new FormControl("", Validators.required),
      tipo: new FormControl("", Validators.required),
    });
  }

  applyFilterMotivoRenuncia(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if (filterValue != "") {
      this.motivoRenunciaFiltered = this.motivoRenuncia.filter((data) => {
        return (
          data?.correlativo.toString().toLowerCase().includes(filterValue) ||
          data?.descripcion.toString().toLowerCase().includes(filterValue)
        );
      });
    } else {
      this.motivoRenunciaFiltered = this.motivoRenuncia;
    }
  }
}
