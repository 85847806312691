import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { LicitacionService } from "../shared/services/licitacion.service";
import { ActivatedRoute, Params } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-administrar-oferentes-licitacion",
  templateUrl: "./administrar-oferentes-licitacion.component.html",
  styleUrls: ["./administrar-oferentes-licitacion.component.css"],
})
export class AdministrarOferentesLicitacionComponent implements OnInit {
  idLicitacion: number;
  licitacionData: any;
  idEtapa: number;
  idEtapaLic: number;
  participantes: any[];
  oferentesDescalificadosData: any[];

  constructor(
    private notifyService: NotificationService,
    private licitacionService: LicitacionService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.participantes = [];
      this.oferentesDescalificadosData = [];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          const licitacion: any[] = JSON.parse(resp);
          this.licitacionData = licitacion[0];
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener datos de la licitacion"
          );
        }
      );
    });
  }

  ngOnInit(): void {
    this.getOferentesDescalificados();
  }

  setParticipantes(arr: []) {
    this.participantes = arr;
    this.getOferentesDescalificados();
  }

  getOferentesDescalificados() {
    this.licitacionService
      .getOferentesDescalificados(this.idLicitacion)
      .subscribe((resp) => {
        const oferentesDescalificados: any[] = JSON.parse(resp);
        this.oferentesDescalificadosData = oferentesDescalificados;
      });
  }

  back(): void {
    this.location.back();
  }
}
