import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppRoutingModule } from './app-routing-module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LicitacionesComponent } from './licitaciones/licitaciones.component';
import { EditLicitacionComponent } from './licitaciones/edit-licitacion/edit-licitacion.component';
import { CreateLicitacionComponent } from './licitaciones/create-licitacion/create-licitacion.component';
import { ViewLicitacionComponent } from './licitaciones/view-licitacion/view-licitacion.component';
import { EditEtapaLicitacionComponent } from './licitaciones/edit-etapa-licitacion/edit-etapa-licitacion.component';
import { ViewConsultaLicitacionComponent } from './licitaciones/view-consulta-licitacion/view-consulta-licitacion.component';
import { ViewRespuestaLicitacionComponent } from './licitaciones/view-respuesta-licitacion/view-respuesta-licitacion.component';
import { ViewAclaracionTecnicaComponent } from './licitaciones/view-aclaracion-tecnica/view-aclaracion-tecnica.component';
import { ContratistaComponent } from './contratista/contratista.component';
import { CreateConsultaLicitacionComponent } from './contratista/create-consulta-licitacion/create-consulta-licitacion.component';
import { ViewRespuestaAclaracionComponent } from './contratista/view-respuesta-aclaracion/view-respuesta-aclaracion.component';
import { ViewRespuestaEconomicaComponent } from './contratista/view-respuesta-economica/view-respuesta-economica.component';
import { ViewAclaracionEconomicaComponent } from './licitaciones/view-aclaracion-economica/view-aclaracion-economica.component';
import { ViewNegociacionComponent } from './licitaciones/view-negociacion/view-negociacion.component';
import { ViewRespuestaNegociacionComponent } from './contratista/view-respuesta-negociacion/view-respuesta-negociacion.component';
import { CronogramaComponent } from './cronograma/cronograma.component';
import { EditCronogramaComponent } from './cronograma/edit-cronograma/edit-cronograma.component';
import { ViewCronogramaComponent } from './licitaciones/view-cronograma/view-cronograma.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewCronogramaContratistaComponent } from './contratista/view-cronograma-contratista/view-cronograma-contratista.component';
import { ViewEtapaReadonlyComponent } from './shared/view-etapa-readonly/view-etapa-readonly.component';
import { ViewConsultaLicitacionReadonlyComponent } from './shared/view-consulta-licitacion-readonly/view-consulta-licitacion-readonly.component';
import { ViewRespuestaLicitacionReadonlyComponent } from './shared/view-respuesta-licitacion-readonly/view-respuesta-licitacion-readonly.component';
import { ViewAclaTecReadonlyComponent } from './shared/view-acla-tec-readonly/view-acla-tec-readonly.component';
import { ViewAclaEcoReadonlyComponent } from './shared/view-acla-eco-readonly/view-acla-eco-readonly.component';
import { ViewNegoReadonlyComponent } from './shared/view-nego-readonly/view-nego-readonly.component';
import { MantenedoresComponent } from './mantenedores/mantenedores.component';
import { ViewEtapaArchivoComponent } from './licitaciones/view-etapa-archivo/view-etapa-archivo.component';
import { UploadEtapaArchivoComponent } from './contratista/upload-etapa-archivo/upload-etapa-archivo.component';
import { ViewArchivosLicitacionComponent } from './licitaciones/view-archivos-licitacion/view-archivos-licitacion.component';
import { ParticipantesComponent } from './mantenedores/participantes/participantes.component';
import { CreateCriteriosComponent } from './licitaciones/create-criterios/create-criterios.component';
import { EvalucionComponent } from './licitaciones/evalucion/evalucion.component';
import { CalificarComponent } from './licitaciones/calificar/calificar.component';
import { ResultadosComponent } from './licitaciones/resultados/resultados.component';
import { ViewFileButtonsComponent } from 'src/app/shared/view-file-buttons/view-file-buttons.component';
import { ViewEtapaFilesTableComponent } from 'src/app/shared/view-etapa-files-table/view-etapa-files-table.component';
import { SafePipe } from './shared/pipes/pipe.pipe';
import { TableParticipantesComComponent } from './licitaciones/table-participantes-com/table-participantes-com.component';
import { ViewPrintButtonComponent } from './shared/view-print-button/view-print-button.component';
import { CronogramaFechasComponent } from './licitaciones/cronograma-fechas/cronograma-fechas.component';
import { TableParticipantesComponent } from './licitaciones/table-participantes/table-participantes.component';
import { ModalParticipantesComponent } from './licitaciones/modal-participantes/modal-participantes.component';
import { ViewExcelButtonsComponent } from './shared/view-excel-button/view-excel-buttons.component';
import { CorreosLicitacionComponent } from './correos-licitacion/correos-licitacion.component';
import { PrintCorreoComponent } from './print-correo/print-correo.component';
import { TableFilterPipe } from './correos-licitacion/table-filter.pipe';
import { TableFilterLicitacionesPipe } from './licitaciones/table-filter-licitaciones.pipe';
import { TableFilterContratistaLicitacionesPipe } from './contratista/filter-table-contratista.pipe';
import { EnvioCorreoLicitacionesComponent } from './envio-correo-licitaciones/envio-correo-licitaciones.component';
import { AclaracionesLicitacionComponent } from './aclaraciones-licitacion/aclaraciones-licitacion.component';
import { CreateItemizadoComponent } from './itemizado/create-itemizado/create-itemizado.component';
import { CargarPresupuestoComponent } from './itemizado/cargar-presupuesto/cargar-presupuesto.component';
import { ResultadosItemizadoComponent } from './itemizado/resultados-itemizado/resultados-itemizado.component';
import { CargarArchivosLicitacionComponent } from './cargar-archivos-licitacion/cargar-archivos-licitacion.component';
import { VerItemizadoLicitacionComponent } from './ver-itemizado-licitacion/ver-itemizado-licitacion.component';
import { CustomSelectComponent } from './general/custom-select/custom-select.component';
import { ActaInicioComponent } from './licitaciones/acta-inicio/acta-inicio.component';
import { MantenedorAyudaEvalTecnicaComponent } from './mantenedor-ayuda-eval-tecnica/mantenedor-ayuda-eval-tecnica.component';
import { ChatComponent } from './general/chat/chat.component';
import { HistoricoCambiosCronogramaComponent } from './historico-cambios-cronograma/historico-cambios-cronograma.component';
import { MantenedorMotivosRenunciaComponent } from './mantenedor-motivos-renuncia/mantenedor-motivos-renuncia.component';
import { MantenedorEstandaresEvalTecComponent } from './mantenedor-estandares-eval-tec/mantenedor-estandares-eval-tec.component';
import { MantenedorDocRequeridosOferenteComponent } from './mantenedor-doc-requeridos-oferente/mantenedor-doc-requeridos-oferente.component';
import { AdministrarDocReqOferenteLicComponent } from './administrar-doc-req-oferente-lic/administrar-doc-req-oferente-lic.component';
import { DecimalPlacesDirective } from './decimal-places.directive';
import { ObservacionesEtapasLicitacionComponent } from './observaciones-etapas-licitacion/observaciones-etapas-licitacion.component';
import { AdjudicacionComponent } from './adjudicacion/adjudicacion.component';
import { AdjudicacionContratistaComponent } from './adjudicacion-contratista/adjudicacion-contratista.component';
import { ActaInicialComponent } from './acta-inicial/acta-inicial.component';
import { AdministrarOferentesLicitacionComponent } from './administrar-oferentes-licitacion/administrar-oferentes-licitacion.component';
import { AdministrarComisionComponent } from './administrar-comision/administrar-comision.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    SafePipe,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    LicitacionesComponent,
    EditLicitacionComponent,
    CreateLicitacionComponent,
    ViewLicitacionComponent,
    EditEtapaLicitacionComponent,
    ViewConsultaLicitacionComponent,
    ViewRespuestaLicitacionComponent,
    ViewAclaracionTecnicaComponent,
    ContratistaComponent,
    CreateConsultaLicitacionComponent,
    ViewRespuestaAclaracionComponent,
    ViewRespuestaEconomicaComponent,
    ViewAclaracionEconomicaComponent,
    ViewNegociacionComponent,
    ViewRespuestaNegociacionComponent,
    CronogramaComponent,
    EditCronogramaComponent,
    ViewCronogramaComponent,
    DashboardComponent,
    ViewCronogramaContratistaComponent,
    ViewEtapaReadonlyComponent,
    ViewConsultaLicitacionReadonlyComponent,
    ViewRespuestaLicitacionReadonlyComponent,
    ViewAclaTecReadonlyComponent,
    ViewAclaEcoReadonlyComponent,
    ViewNegoReadonlyComponent,
    MantenedoresComponent,
    ViewEtapaArchivoComponent,
    UploadEtapaArchivoComponent,
    ViewArchivosLicitacionComponent,
    ParticipantesComponent,
    CreateCriteriosComponent,
    EvalucionComponent,
    CalificarComponent,
    ResultadosComponent,
    ViewFileButtonsComponent,
    ViewEtapaFilesTableComponent,
    TableParticipantesComComponent,
    ViewPrintButtonComponent,
    CronogramaFechasComponent,
    TableParticipantesComponent,
    ModalParticipantesComponent,
    ViewExcelButtonsComponent,
    CorreosLicitacionComponent,
    PrintCorreoComponent,
    TableFilterPipe,
    TableFilterLicitacionesPipe,
    TableFilterContratistaLicitacionesPipe,
    EnvioCorreoLicitacionesComponent,
    AclaracionesLicitacionComponent,
    CreateItemizadoComponent,
    CargarPresupuestoComponent,
    ResultadosItemizadoComponent,
    CargarArchivosLicitacionComponent,
    VerItemizadoLicitacionComponent,
    CustomSelectComponent,
    ActaInicioComponent,
    MantenedorAyudaEvalTecnicaComponent,
    ChatComponent,
    HistoricoCambiosCronogramaComponent,
    MantenedorMotivosRenunciaComponent,
    MantenedorEstandaresEvalTecComponent,
    MantenedorDocRequeridosOferenteComponent,
    AdministrarDocReqOferenteLicComponent,
    DecimalPlacesDirective,
    ObservacionesEtapasLicitacionComponent,
    AdjudicacionComponent,
    AdjudicacionContratistaComponent,
    ActaInicialComponent,
    AdministrarOferentesLicitacionComponent,
    AdministrarComisionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }