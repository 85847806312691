import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NotificationService } from "../shared/services/notification.service";
import { LicitacionService } from "../shared/services/licitacion.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { IOption } from "../general/custom-select/custom-select.component";
import { SolpeService } from "../shared/services/solpe.service";
import { Subscription } from "rxjs";
import {
  GlobalDataService,
  IActions,
} from "../shared/services/global-data.service";
import { ParticipantesService } from "../shared/services/participantes.service";
import { AuthService } from "../shared/services/auth.service";

@Component({
  selector: "app-licitaciones",
  templateUrl: "./licitaciones.component.html",
  styleUrls: ["./licitaciones.component.css"],
  providers: [
    LicitacionService,
    SolpeService,
    ParticipantesService,
    AuthService,
  ],
})
export class LicitacionesComponent implements OnInit {
  aSolpes: [];
  aSolpesSelect: IOption[];
  licitaciones: any;
  licitacionesFiltered: any;
  idFiltro: number = 0;
  dataLicitacion;
  // variables para el bucador
  filterLicitacion: string;
  // variables de tags
  en_proceso: number = 0;
  en_creacion: number = 0;
  adjudicadas: number = 0;
  excelHeaders: any[] = [
    {
      text: "#",
      value: "LIC_CORRELATIVO",
    },
    {
      text: "Nro. Contrato",
      value: "CONTRATO_CORRELATIVO",
    },
    {
      text: "Nombre",
      value: "CONTRATO_NOMBRE",
    },
    {
      text: "Etapa",
      value: "ETAPA_DESCRIPCION",
    },
    {
      text: "Inicio etapa",
      value: "FECHA_INICIO_ETAPA",
      type: "date",
    },
    {
      text: "Fin etapa",
      value: "FECHA_TERMINO_ETAPA",
      type: "date",
    },
  ];
  solpeSeleccionada: number = 0;
  idCargoActual: number;
  participantes: any[];
  participantesCount: number;
  rolSubcription: Subscription;
  nombreArchivo: string;
  file: { filename; type; archivos64 };
  PuedeParticipar = 0;
  @ViewChild("inputFile")
  inputFile: ElementRef;

  @ViewChild("closeModal") closeModal: ElementRef;
  comisionesParticipacionByUser: any[];

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private routerPath: Router,
    private solpeService: SolpeService,
    public globalData: GlobalDataService,
    private participantesService: ParticipantesService,
    private authServices: AuthService
  ) {
    this.file = { filename: "", type: "", archivos64: "" };
    this.aSolpesSelect = [];
    this.idCargoActual = JSON.parse(localStorage.getItem("userData")).rolId;
    this.participantes = [];
    this.participantesCount = 0;
    this.comisionesParticipacionByUser = [];

    //this.globalData.

    this.authServices.getAccionesPorUsuario().subscribe(
      (reps) => {
        const Actions = reps as IActions[];
        if (Actions && Actions.length > 0) {
          this.globalData.Actions = Actions;
          localStorage.setItem(
            "userActions",
            JSON.stringify(this.globalData.Actions)
          );
        }
      },
      (error) => {}
    );
  }

  ngOnInit(): void {
    //this.globalData.validationRol.subscribe((valor)=>{alert('s')})

    this.rolSubcription = this.globalData.rolObservable.subscribe((valor) => {
      this.getLicitaciones();
    });

    this.route.params.subscribe((params: Params) => {
      this.idFiltro = params["idFiltro"];
      this.getLicitaciones();
    });

    this.getComisionByUser();
  }

  onAgregar() {
    let idSolpe: number = this.solpeSeleccionada;
    this.licitacionService.postRegistrarLicitacion({ idSolpe }).subscribe(
      (resp) => {
        let response: any = resp;
        this.notifyService.showSuccess("", "Datos guardados");
        if (response.data) {
          let solpeCorrelativo = response.data;
          this.routerPath.navigate([`/acta-inicial/${solpeCorrelativo}`]);
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  getLicitaciones() {
    if (
      JSON.parse(localStorage.getItem("userData")).rolId == 12 ||
      JSON.parse(localStorage.getItem("userData")).rolId == 5
    ) {
      if (this.globalData.existeAccion(27)) {
        //ver todas
        if (this.idFiltro) {
          this.licitacionService
            .getLicitacionesTipo(this.idFiltro)
            .subscribe((resp) => {
              this.licitaciones = JSON.parse(resp);
              this.licitacionesFiltered = JSON.parse(resp);
              this.en_proceso = this.licitaciones.filter(
                (t) => t.ORDEN > 0 && t.ORDEN < 20
              ).length;
              this.en_creacion = this.licitaciones.filter(
                (t) => t.ORDEN === 0
              ).length;
              this.adjudicadas = this.licitaciones.filter(
                (t) => t.ORDEN === 20
              ).length;
            });
        } else {
          this.licitacionService.getLicitaciones("N").subscribe((resp) => {
            this.licitaciones = JSON.parse(resp);
            this.licitacionesFiltered = JSON.parse(resp);
            this.en_proceso = this.licitaciones.filter(
              (t) => t.ORDEN > 0 && t.ORDEN < 20
            ).length;
            this.en_creacion = this.licitaciones.filter(
              (t) => t.ORDEN === 0
            ).length;
            this.adjudicadas = this.licitaciones.filter(
              (t) => t.ORDEN === 20
            ).length;
          });
        }
      } else {
        //solo las que creo o las que esta como encargado
        this.licitacionService.getLicitacionesGestor().subscribe((resp) => {
          this.licitaciones = JSON.parse(resp);
          this.licitacionesFiltered = JSON.parse(resp);
          this.en_proceso = this.licitaciones.filter(
            (t) => t.ORDEN > 0 && t.ORDEN < 20
          ).length;
          this.en_creacion = this.licitaciones.filter(
            (t) => t.ORDEN === 0
          ).length;
          this.adjudicadas = this.licitaciones.filter(
            (t) => t.ORDEN === 20
          ).length;
        });
      }
    }

    // en caso de ser evaluador
    if (JSON.parse(localStorage.getItem("userData")).rolId == 26) {
      this.licitacionService
        .getLicitacionesEvaluadorList()
        .subscribe((resp) => {
          this.licitaciones = JSON.parse(resp);
          this.licitacionesFiltered = JSON.parse(resp);

          this.en_proceso = this.licitaciones.filter(
            (t) => t.ORDEN > 0 && t.ORDEN < 20
          ).length;
          this.en_creacion = this.licitaciones.filter(
            (t) => t.ORDEN === 0
          ).length;
          this.adjudicadas = this.licitaciones.filter(
            (t) => t.ORDEN === 20
          ).length;
        });
    }

    // en caso de ser jac (verificar para que lgan solo las del JAC o DAB)
    if (
      JSON.parse(localStorage.getItem("userData")).rolId == 15 ||
      JSON.parse(localStorage.getItem("userData")).rolId == 27
    ) {
      this.licitacionService.getLicitaciones("N").subscribe((resp) => {
        const licitacionesJac: any[] = JSON.parse(resp).filter(
          (t) => t.ETAPA_CORRELATIVO === 18
        );
        this.licitaciones = licitacionesJac;
        this.licitacionesFiltered = licitacionesJac;

        this.en_proceso = this.licitaciones.filter(
          (t) => t.ORDEN > 0 && t.ORDEN < 20
        ).length;
        this.en_creacion = this.licitaciones.filter(
          (t) => t.ORDEN === 0
        ).length;
        this.adjudicadas = this.licitaciones.filter(
          (t) => t.ORDEN === 20
        ).length;
      });
    }

    // en caso de validacion por parte de riesgo control
    if (JSON.parse(localStorage.getItem("userData")).rolId == 29) {
      this.licitacionService.getLicitaciones("N").subscribe((resp) => {
        const licitacionesRC: any[] = JSON.parse(resp).filter(
          (t) => t.ETAPA_CORRELATIVO === 20 
        );
        this.licitaciones = licitacionesRC;
        this.licitacionesFiltered = licitacionesRC;

        this.en_proceso = this.licitaciones.filter(
          (t) => t.ORDEN > 0 && t.ORDEN < 20
        ).length;
        this.en_creacion = this.licitaciones.filter(
          (t) => t.ORDEN === 0
        ).length;
        this.adjudicadas = this.licitaciones.filter(
          (t) => t.ORDEN === 20
        ).length;
      });
    }
  }
  getSolpes() {
    if (JSON.parse(localStorage.getItem("userData")).rolId == 12) {
      if (this.globalData.existeAccion(12)) {
        //ver todas
        this.solpeService.getSolpes().subscribe(
          (resp) => {
            let response: any = resp;
            if (response.data?.length > 0) {
              this.aSolpesSelect = response.data;
            } else {
              this.notifyService.showWarning(
                "Busqueda Solpe",
                "No hay solpes sin Licitación"
              );
            }
          },
          (error) => {
            console.log(error);
          },
          () => {}
        );
      } else {
        this.solpeService.getSolpeFilter().subscribe(
          (resp) => {
            let response: any = resp;
            if (response.data?.length > 0) {
              this.aSolpesSelect = response.data;
            } else {
              this.notifyService.showWarning(
                "Busqueda Solpe",
                "No hay solpes sin Licitación"
              );
            }
          },
          (error) => {
            console.log(error);
          },
          () => {}
        );
      }
    }
  }
  ngOnDestroy() {
    this.rolSubcription.unsubscribe();
  }
  onOptionSelected(event) {
    this.solpeSeleccionada = +event;
  }
  cargarDatosLicitacion(correlativo_Licitacion: number) {
    this.licitacionService
      .getHeaderLicitacionId(correlativo_Licitacion)
      .subscribe(
        (resp) => {
          this.dataLicitacion = JSON.parse(resp)[0];
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
  }

  getParticipantes(correlativo_Licitacion: number, idEtapaLic: number) {
    this.participantes = [];
    this.licitacionService.getParticipantes().subscribe((resp) => {
      this.participantes = JSON.parse(resp);
      if (this.participantes) {
        this.licitacionService
          .getParticipantesLic(correlativo_Licitacion, idEtapaLic)
          .subscribe((resp) => {
            //const part_activos = JSON.parse(resp);
            /* part_activos.map(el =>{
            this.participantes.find((element ,i)  => {
              const elem = JSON.parse(JSON.stringify(element));
              if (elem.CORRELATIVO === el.CORRELATIVO){
                this.participantes.splice(i, 1);
                this.participantes.push({ ...element, ACTIVO: true});
              }
            });
          }); */
            this.participantes = JSON.parse(resp);
            this.participantesCount = this.participantes.length;
            //this.data.emit(this.participantes);
          });
      }
    });
  }

  detectFiles(event: any) {
    if (event.target.validity.valid) {
      //this.isDisable = false;
    }
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.file.filename = event.target.files[0].name;
          this.file.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
          this.file.archivos64 = bs64;
        }
      };
    }
  }

  onGuardarArchivo() {
    if (this.file.archivos64) {
      if (this.PuedeParticipar === 0) {
        this.notifyService.showWarning(
          "Por favor elija una opción para definir su participación.",
          "Licitación"
        );
      } else {
        const postData: {
          in_det_etapa_lic: number;
          files: Blob;
          fileName: string;
          type: string;
          in_usuario: string;
          publico: number;
          descripcion_input_archivo: string;
          idLicitacion: number;
          idEtapaLicitacion: number;
          puedeParticipar: number;
        } = {
          in_det_etapa_lic: this.dataLicitacion.CORRELATIVO_ETAPA,
          files: this.file.archivos64,
          fileName: this.file.filename,
          type: this.file.type,
          in_usuario: "",
          publico: 0,
          descripcion_input_archivo:
            this.nombreArchivo == null || this.nombreArchivo == ""
              ? this.file.filename
              : this.nombreArchivo,
          idLicitacion: this.dataLicitacion.LICITACION_CORRELATIVO,
          idEtapaLicitacion: this.dataLicitacion.ETAPA_CORRELATIVO,
          puedeParticipar: this.PuedeParticipar,
        };

        if (this.file.filename == null || this.file.filename == "") {
          this.notifyService.showWarning(
            "Por favor agregue un achivo con su declaración.",
            "Subir archivos licitación"
          );
        } else {
          this.participantesService
            .postArchivosDeclaracionJurada(postData)
            .subscribe(
              (resp) => {
                this.notifyService.showSuccess(
                  "Archivo subido correctamente",
                  "Subir archivo licitación"
                );
                this.nombreArchivo = "";
                this.PuedeParticipar = 0;
                this.getComisionByUser();
                this.resetModalForm();
                this.closeModal.nativeElement.click();
                //this.getArchivoLicEtapaFull();
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Subir archivos licitación"
                );
              }
            );
        }
      }
    } else {
      this.notifyService.showWarning(
        "Por favor agregue un achivo con su declaración.",
        "Subir archivos licitación"
      );
    }
  }
  resetModalForm() {
    this.file.archivos64 = null;
    this.file.filename = "";
    this.file.type = "";
    this.inputFile.nativeElement.value = "";
  }
  isComision(comisiones: string) {
    if (comisiones !== null && comisiones !== "") {
      const arrayComisiones = comisiones?.split(",");
      const user = JSON.parse(localStorage.getItem("userData")).userId;
      const esComision = arrayComisiones?.includes(user.toString());
      return esComision;
    } else {
      return false;
    }
  }

  FilterTable(idfilter) {
    this.licitacionesFiltered = [];
    switch (idfilter) {
      case 0:
        this.idFiltro = 0;
        this.licitacionesFiltered = this.licitaciones;
        break;
      case 1:
        this.idFiltro = 1;
        this.licitacionesFiltered = this.licitaciones.filter(
          (t) => t.ETAPA_CORRELATIVO === 0
        );
        break;
      case 2:
        this.idFiltro = 2;
        this.licitacionesFiltered = this.licitaciones.filter(
          (t) => t.ETAPA_CORRELATIVO !== 0 && t.ETAPA_CORRELATIVO !== 16
        );
        break;
      case 3:
        this.idFiltro = 3;
        this.licitacionesFiltered = this.licitaciones.filter(
          (t) => t.ETAPA_CORRELATIVO === 16
        );
        break;
      default:
        this.idFiltro = 0;
        this.licitacionesFiltered = this.licitaciones;
        break;
    }

    //this.Search()
  }
  applyFilterSearch() {
    this.Search();
  }
  Search() {
    if (
      this.filterLicitacion?.trim().toLowerCase() === "" ||
      !this.filterLicitacion?.trim().toLowerCase()
    ) {
      this.FilterTable(this.idFiltro);
    } else {
      this.licitacionesFiltered = this.licitacionesFiltered.filter((lic) => {
        return (
          lic.CONTRATO_NOMBRE.toLowerCase().includes(
            this.filterLicitacion?.trim().toLowerCase()
          ) ||
          lic.LIC_CORRELATIVO.toString()
            .toLowerCase()
            .includes(this.filterLicitacion?.trim().toLowerCase()) ||
          lic.CONTRATO_CORRELATIVO.toLowerCase().includes(
            this.filterLicitacion?.trim().toLowerCase()
          )
        );
      });
    }
  }

  getComisionByUser() {
    this.licitacionService.getSelectComisionByLicitacion().subscribe(
      (resp) => {
        const comisionLicitacion: any[] = JSON.parse(resp);
        if (comisionLicitacion.length > 0) {
          this.comisionesParticipacionByUser = comisionLicitacion;
        } else {
          this.comisionesParticipacionByUser = [];
        }
      },
      (error) => {
        this.comisionesParticipacionByUser = [];
      }
    );
  }

  validateBtnDeclaracionJurada(idLicitacion: number): number {
    const comisionParticipacionByLicitacion: any[] =
      this.comisionesParticipacionByUser.filter(
        (t) => t.lic_correlativo === idLicitacion && t.vigente == "S"
      );

    if (comisionParticipacionByLicitacion.length > 0) {
      const conflictoInteresNoValidado: any[] =
        comisionParticipacionByLicitacion.filter(
          (t) => t.conflictoInteres === 0
        );

      if (conflictoInteresNoValidado.length > 0) {
        return 1;
      } else {
        return 2;
      }
    } else {
      return 0;
    }
  }

  validateCargoJac(): boolean {
    if (
      JSON.parse(localStorage.getItem("userData")).rolId === 15 ||
      JSON.parse(localStorage.getItem("userData")).rolId === 27
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateCargoDab(): boolean {
    if (JSON.parse(localStorage.getItem("userData")).rolId === 27) {
      return true;
    } else {
      return false;
    }
  }
}
