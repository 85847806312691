import { jsPDF, jsPDFOptions } from "jspdf";
import autoTable, { Styles } from "jspdf-autotable";

interface ColumnStyles {
  [key: string]: Partial<Styles>;
}

export class PDF extends jsPDF {
  nPageYAxi = 10;
  iMargin = 24;
  iLengthPage = this.internal.pageSize.getWidth() - this.iMargin;
  iFontText = 11;
  iMarginHeader = 14;
  iIdentTextSeparation = 8;
  iSection = 1;
  fontName: 'helvetica';
  sections = [];

  constructor(options: jsPDFOptions, sections: string[]) {
    super(options);
    this.sections = sections;
  }

  addTitle = (title: string) => {
    if(title) {
      this.setFontSize(14);
      this.setFontStyle('bold');
      this.slitText(title, this.iLengthPage - 20, 5, this.iMargin, false);
      this.increasePageY(5);
      this.addLine();
    }

    this.increasePageY(10);
    this.setFontSize(this.iFontText);
    this.setFontStyle('normal');
  }

  //Se emplea para aumentar el contador
  increasePageY = function (iIncreaseValue: number) {
    this.nPageYAxi += iIncreaseValue;
    if (this.nPageYAxi > this.internal.pageSize.height - this.iMargin) {
      this.addPage();
      this.nPageYAxi = this.iMarginHeader;
    }
  };
  //slitText se emplea para dividir el texto de un parrafo en multiples lineas, en funcion del ancho enviado en el parametro iMaxWidth
  //el cual es el ancho maximo permitido para el texto.
  //iIncreaseY = es alto que se aumentara por cada linea
  //de ser necesario, el valor del alto de la ultima linea se guarda en la variable nPageYAxi
  //iXAxis = posicion en eje X para inicio del texto.
  slitText = function (
    sText: string,
    iMaxWidth: number,
    iIncreaseY: number | number[],
    iXAxis: number,
    isCenter: boolean
  ) {
    const aTextLines = this.splitTextToSize(sText, iMaxWidth);
    let iIncreaseY2 = 0;
    if (Array.isArray(iIncreaseY)) {
      iIncreaseY2 = iIncreaseY[1];
      iIncreaseY = iIncreaseY[0];
    } else {
      iIncreaseY2 = iIncreaseY;
    }
    aTextLines.forEach((sLine: string, i: number) => {
      if (i != 0) {
        this.increasePageY(iIncreaseY);
      } else {
        this.increasePageY(iIncreaseY2);
      }
      if (isCenter) {
        this.centeredText(sLine, this.nPageYAxi); //nPageYAxi posee el valor del ultimo alto ingresado
      } else {
        this.text(iXAxis, this.nPageYAxi, sLine);
      }
    });
  };
  identText = (sTitle: string | string[], sValue: string, iMaxWidth: number, iMaxWidthValue = null) => {
    let firstTitle = '';
    let secondTitle = ''
    let iSeparator = this.iIdentTextSeparation;

    if(!Array.isArray(sTitle)) {
      firstTitle = sTitle;
    } else {
      firstTitle = sTitle[0];
      secondTitle = sTitle[1];
      iSeparator = 5;
    }
    if (!iMaxWidthValue) {
      if (iMaxWidth > 50) {
        iMaxWidthValue = 100;
      } else {
        iMaxWidthValue = iMaxWidth;
      }
    } else {
      const iMaxLength = 50;
      sTitle = this.getMaxText(firstTitle, iMaxLength);
    }
    this.setFontStyle('bold');
    this.slitText(firstTitle, iMaxWidth, iSeparator, this.iMargin, false);
    this.setFontStyle('normal');
    if(secondTitle) {
      this.slitText(secondTitle, iMaxWidth, iSeparator, this.iMargin, false);
      this.increasePageY(-iSeparator);
    }
    this.slitText(sValue, iMaxWidthValue, [iSeparator, 0], iMaxWidth, false);
  };
  centeredText = function (sText: string, y: number) {
    const textWidth =
      (this.getStringUnitWidth(sText) * this.internal.getFontSize()) /
      this.internal.scaleFactor;
    const textOffset = (this.internal.pageSize.width - textWidth) / 2;
    this.text(textOffset, y, sText);
  };
  getMaxText = (sText: string, iMaxLength: number) => {
    if (sText.length > iMaxLength) {
      sText = sText.substr(0, iMaxLength) + "...";
    }
    return sText;
  };
  addNewPage = (title?: string) => {
    this.addPage();
    //al iniciar nueva pagina y agregar el header establesco el alto inicial
    this.nPageYAxi = this.iMarginHeader;
    this.addLogo();
    const sTitle = !title ? this.sections[this.iSection - 1] : title;
    this.addTitle(title || sTitle);
  };
  addFooter = () => {
    const pages = (this as any).internal.getNumberOfPages();
    const pageWidth = this.internal.pageSize.width;
    const pageHeight = this.internal.pageSize.height;
    this.setFontSize(10);

    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    const lineYPos = verticalPos - 10;

    for (let j = 1; j < pages + 1; j++) {
      this.setPage(j);
      this.line(this.iMargin, lineYPos, this.iLengthPage, lineYPos);
      this.text("Fecha de emisión", this.iMargin, verticalPos -5);
      this.text(new Date(Date.now()).toLocaleString(), this.iMargin, verticalPos);
      this.text(`Página ${j} de ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
  };
  setFontStyle = (fontStyle: string) => {
    this.setFont(this.fontName, fontStyle);
  }

  addTable = (head: string[][], body: string[][], columnStyles?: ColumnStyles) => {
    autoTable(this, {
      theme: 'plain',
      styles: {
        fontSize: this.iFontText
      },
      columnStyles,
      margin: this.iMargin - 1,
      startY: this.nPageYAxi,
      head,
      body,
    });
  }

  addTableHtml = (id: string) => {
    autoTable(this, {
      html: id,
      theme: 'grid',
      headStyles: {
        fillColor: [52, 58, 64]
      },
      bodyStyles: {
        fillColor: [248, 249, 250],
      },
      styles: {
        fontSize: this.iFontText,
      },
      didParseCell: hookData => {
        //console.log(JSON.stringify(hookData.cell));
        if (hookData.section == 'body') {
          for (let index = 0; index < hookData.cell.text.length; index++) {
            const element = hookData.cell.text[index].trim().replace(/,/g, '');
            const pasarANumero: number = Number(element);

            if (!isNaN(pasarANumero)) {
              hookData.cell.styles.halign = 'right';
            }
          }
        }
      },
  
      margin: this.iMargin - 1,
      startY: this.nPageYAxi,
    });

    this.nPageYAxi = (this as any).lastAutoTable.finalY;
  }

  addLine = () => {
    this.setDrawColor(211, 211, 211);
    this.line(this.iMargin, this.nPageYAxi, this.iLengthPage, this.nPageYAxi);
  }

  addLogo = () => {
    const logo = new Image();
    logo.src = "/assets/img/logo/logo-emsa-completo.png";
    this.addImage(logo, "PNG", this.iMargin, this.nPageYAxi, 85, 8);
    this.increasePageY(15);
  }


  addSign = (name: string) => {
    const signSize = 120;
    this.setDrawColor(0, 0, 0);
    this.line(this.iMargin, this.nPageYAxi, signSize, this.nPageYAxi);
    this.increasePageY(5);
    this.text(name, this.iMargin, this.nPageYAxi);
  }
}