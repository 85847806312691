<div class="custom-select-ssss">
    
    <select [(ngModel)]="selectedOption" 
    (change)="onOptionSelected()"
    #controSelect
    >     
        <option *ngFor="let option of filteredOptions" [value]="option.value">{{ option.text }}</option>
    </select>

    <div class="custom-select-input">
        <span class="text-selected">
            <ng-container *ngIf="!pickedOption?.text">
                {{solpeName}}
            </ng-container>
            <ng-container *ngIf="pickedOption?.text">
                {{pickedOption?.text}}
            </ng-container>
        </span>
        <i class="fas fa-sort-down select-down-arrow" (click)="openDropdown = !openDropdown"></i>
        <div class="dropdown-select" *ngIf="openDropdown">
            <div class="input-container-search">
                <input class="form-control"
                type="text" 
                [(ngModel)]="searchTerm" (input)="filterOptions()" placeholder="Buscar...">
            </div>
            <div class="options">
                <ng-container *ngFor="let option of filteredOptions">
                    <div class='option-custom-select'
                    [ngClass]="{'current-option' : selectedOption == option.value}"
                    (click)="setOpction(option)">
                        {{option.text}}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    

</div>