import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import {
  IItemizado,
  IItemizadoDetalle,
  ItemizadoService,
} from "src/app/shared/services/itemizado.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { Location } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "src/assets/plugins/moment/moment";
import { PDF } from "src/app/shared/functions/pdf";
import { formatCurrency } from "src/app/shared/functions/common";

@Component({
  selector: "app-cargar-presupuesto",
  templateUrl: "./cargar-presupuesto.component.html",
  styleUrls: ["./cargar-presupuesto.component.css"],
  providers: [LicitacionService, ItemizadoService],
})
export class CargarPresupuestoComponent implements OnInit {
  idLicitacion: number;
  objItemizado: IItemizado[];
  objLicitacion: any;
  indexItem: number = 0;
  indexDetalle: number = 0;
  archivos: [];
  idParticipante: number;
  updateDet: IItemizadoDetalle;
  idEtapaLic: number;
  etapaOfertaEco: any;

  archivos64: Blob;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivo: string;
  @ViewChild("inputFile")
  inputFile: ElementRef;
  nombreArchivo: string;
  uploadFileModal: boolean = false;

  stepSelected: number;
  docRequeridos: any[];
  formDocRequeridoOferente: FormGroup;
  docRequeridosValido: boolean;
  idEtapa: number;
  isPermisoEditarPresupuesto: boolean;
  isEnviarCorreccionPresupuesto: boolean;
  presupuestoEnviadoStr: string;
  downloadingPdf: boolean = false;
  userName: string;
  montoTotalPresupuesto: number;

  constructor(
    private objItemizadoService: ItemizadoService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private etapaService: EtapasService,
    private location: Location
  ) {
    this.stepSelected = 0;
    this.docRequeridos = [];
    this.docRequeridosValido = false;
    this.idEtapa = 0;
    this.isPermisoEditarPresupuesto = false;
    this.isEnviarCorreccionPresupuesto = false;
    this.presupuestoEnviadoStr = "";
    this.userName =
      JSON.parse(localStorage.getItem("userData")).userName ??
      "Sin especificar";
    this.montoTotalPresupuesto = 0;

    this.formDocRequeridoOferente = new FormGroup({
      docSelected: new FormControl(null, Validators.required),
    });

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      /* this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        this.verificarLic(this.idLicitacion); */
      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
          this.getItemizado();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );

      this.licitacionService.getDatosPart().subscribe((resp) => {
        this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
        if (this.idParticipante) {
          //this.getArchivoLicEtapaFull();
          this.getCronograma();
        }
      });

      this.licitacionService
        .getEtapaActualLicitacion(this.idLicitacion)
        .subscribe(
          (resp) => {
            const etapa: any[] = JSON.parse(resp);
            //console.log(etapa[0].etapa_correlativo);

            if (etapa.length > 0) {
              this.idEtapa = etapa[0].etapa_correlativo;

              // validacion de si puede editar el presupuesto o no dependiento de la etapa y del permiso que tenga el usuario
              this.licitacionService
                .getSelectPermitirEditarPresupuesto(this.idLicitacion)
                .subscribe(
                  (resp) => {
                    const permisoEditarData: any[] = JSON.parse(resp);

                    if (this.idEtapa === 10) {
                      this.isPermisoEditarPresupuesto = true;
                      this.isEnviarCorreccionPresupuesto = false;
                      this.presupuestoEnviadoStr = "";
                    } else if (this.idEtapa === 11) {
                      if (permisoEditarData[0].corregir_presupuesto === 1) {
                        this.isPermisoEditarPresupuesto = true;
                        this.isEnviarCorreccionPresupuesto = true;
                        this.presupuestoEnviadoStr = "";
                      } else if (
                        permisoEditarData[0].corregir_presupuesto === 2
                      ) {
                        this.isPermisoEditarPresupuesto = false;
                        this.isEnviarCorreccionPresupuesto = false;
                        this.presupuestoEnviadoStr =
                          "Corrección de presupuesto enviada";
                      } else {
                        this.isPermisoEditarPresupuesto = false;
                        this.isEnviarCorreccionPresupuesto = false;
                        this.presupuestoEnviadoStr = "";
                      }
                    } else if (this.idEtapa === 13) {
                      if (permisoEditarData[0].corregir_presupuesto === 1) {
                        this.isPermisoEditarPresupuesto = true;
                        this.isEnviarCorreccionPresupuesto = true;
                        this.presupuestoEnviadoStr = "";
                      } else if (
                        permisoEditarData[0].corregir_presupuesto === 2
                      ) {
                        this.isPermisoEditarPresupuesto = false;
                        this.isEnviarCorreccionPresupuesto = false;
                        this.presupuestoEnviadoStr =
                          "Corrección de presupuesto enviada";
                      } else {
                        this.isPermisoEditarPresupuesto = false;
                        this.isEnviarCorreccionPresupuesto = false;
                        this.presupuestoEnviadoStr = "";
                      }
                    } else {
                      this.isPermisoEditarPresupuesto = false;
                      this.isEnviarCorreccionPresupuesto = false;
                      this.presupuestoEnviadoStr = "";
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            }
          },
          (error) => console.log(error)
        );

      // traer el presupuesto ingresado por el usuario
      this.getSelectPresupuestoOferenteLicitacion();
    });
  }

  getCronograma() {
    if (this.idLicitacion) {
      this.licitacionService
        .getCronogramaLicView(this.idLicitacion)
        .subscribe((resp) => {
          if (resp) {
            this.etapaOfertaEco = JSON.parse(resp);
            this.etapaOfertaEco = this.etapaOfertaEco?.filter(
              (item) => item.ETAPA_CORRELATIVO === 10
            ); //10 estapa de recepcion de oferta economica
            this.idEtapaLic = this.etapaOfertaEco[0]?.ETLIC_CORRELATIVO;
            this.getArchivoPartcipante();
            //this.getArchivoLicEtapaFull();
          }
        });
    }
  }
  ngOnInit(): void {
    this.getDocRequeridos();
  }
  getItemizado() {
    this.objItemizadoService
      .getItemizadoParticipante(this.idLicitacion)
      .subscribe(
        (resp) => {
          let response: any = resp;

          if (response.data?.length > 0) {
            this.objItemizado = response.data;
          }
          /* else
      {
        this.setDefault();
      } */

          //this.calcularPorcentajesFormularios();
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
  }
  registrarMonto(event: any, indexItem, indexDetalle) {
    this.indexItem = indexItem;
    this.indexDetalle = indexDetalle;
    if (
      event.target.value == "" ||
      event.target.value == null ||
      event.target.value == undefined
    ) {
      return;
    }
    if (
      event.target.value ==
      this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle]
        .precio
    ) {
      return;
    }

    this.updateMonto(event.target.value);
  }

  updateMonto(calificacion) {
    this.updateDet =
      this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle];
    this.updateDet.precio = +calificacion;
    //this.objCritero.

    this.objItemizadoService.postRegistraPrespuesto(this.updateDet).subscribe(
      (resp) => {
        let response: any = resp;
        this.notifyService.showSuccess("", "Datos guardados");
        if (response.data) {
          //this.objCritero = response.data;
          this.objItemizado[this.indexItem].itemizadoDetalleLs[
            this.indexDetalle
          ] = this.updateDet;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }
  calcularTotal() {
    let toReturn = 0;
    //this.objItemizadoLs
    for (let y = 0; y < this.objItemizado?.length; y++) {
      for (
        let i = 0;
        i < this.objItemizado[y]?.itemizadoDetalleLs?.length;
        i++
      ) {
        toReturn =
          toReturn +
          this.objItemizado[y]?.itemizadoDetalleLs[i]?.cantidad *
            this.objItemizado[y]?.itemizadoDetalleLs[i]?.precio;
      }
    }
    return toReturn;
  }
  calcularCapitulo(index: number) {
    let toReturn = 0;
    //this.objItemizadoLs
    for (
      let i = 0;
      i < this.objItemizado[index]?.itemizadoDetalleLs?.length;
      i++
    ) {
      toReturn =
        toReturn +
        this.objItemizado[index]?.itemizadoDetalleLs[i]?.cantidad *
          this.objItemizado[index]?.itemizadoDetalleLs[i]?.precio;
    }
    return toReturn;
  }
  getArchivoLicEtapaFull() {
    this.etapaService
      .getArchivoLicEtapaFull(this.idLicitacion, 10)
      .subscribe((resp) => {
        this.archivos = JSON.parse(resp);
      });
  }

  detectFiles(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.archivos64 = bs64;
          this.fileName = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
        }
      };
    }
  }

  onSubirArchivo() {
    const postData: {
      in_det_etapa_lic: number;
      files: Blob;
      fileName: string;
      type: string;
      in_usuario: string;
      in_part_correlativo: number;
      in_domi_correlativo_tiar: number;
      publico: number;
      descripcion_input_archivo: string;
      idLicitacion: number;
      idEtapaLicitacion: number;
      correlativoGrupoArchivo: number;
    } = {
      in_det_etapa_lic: this.idEtapaLic,
      files: this.archivos64,
      fileName: this.fileName,
      type: this.type,
      in_usuario: "",
      in_part_correlativo: this.idParticipante,
      in_domi_correlativo_tiar: 27,
      publico: 1,
      descripcion_input_archivo:
        this.nombreArchivo == null || this.nombreArchivo == ""
          ? this.fileName
          : this.nombreArchivo,
      idLicitacion: this.idLicitacion,
      idEtapaLicitacion: 10,
      correlativoGrupoArchivo: 0,
    };
    if (postData.files) {
      this.licitacionService.postArchivosLicitacion(postData).subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Archivo enviado correctamente.",
            "Archivo enviado correctamente"
          );
          this.getArchivoPartcipante();
          //this.getArchivoLicEtapaFull();
          //this.getArchivoPartcipante();
          //this.getArchivoLicEtapaFull();
          this.uploadFileModal = false;
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Actualizar etapa");
          this.uploadFileModal = true;
        }
      );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Actualizar etapa"
      );
    }

    this.archivos64 = null;
    this.fileName = "";
    this.type = "";
    this.inputFile.nativeElement.value = "";
    this.nombreArchivo = "";
  }

  getArchivoPartcipante() {
    const etapa = this.idEtapaLic;
    const parti = this.idParticipante;
    this.licitacionService
      .getArchivosPartLic(etapa, parti, 27)
      .subscribe((resp) => {
        // if (JSON.parse(resp)){
        //   this.archivos = JSON.parse(resp);
        // }
        this.archivos = JSON.parse(resp);
      });
  }

  enviarPresupuesto() {
    //this.updateDet = this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle];
    //this.updateDet.precio = +calificacion;
    //this.objCritero.

    this.objItemizadoService.postEnviaPrespuesto(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;
        this.notifyService.showSuccess("", "Datos guardados");
        if (response.data) {
          //this.objCritero = response.data;
          //this.objItemizado[this.indexItem].itemizadoDetalleLs[this.indexDetalle] = this.updateDet;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  back(): void {
    this.location.back();
  }

  setMontoDosDecimales(monto: number) {
    return parseFloat(monto.toFixed(2));
  }

  onAddDocRequeridoOferente() {
    if (this.formDocRequeridoOferente.invalid) {
      this.notifyService.showWarning(
        "Todos los campos son obligatorios",
        "Advertencia"
      );
      return;
    }

    const postData: {
      correlativo_doc_requerido: number;
      files: Blob;
      fileName: string;
      type: string;
      idLicitacion: number;
    } = {
      correlativo_doc_requerido:
        this.formDocRequeridoOferente.value.docSelected,
      files: this.archivos64,
      fileName: this.fileName,
      type: this.type,
      idLicitacion: this.idLicitacion,
    };
    if (postData.files) {
      this.licitacionService
        .postCreateDocumentoRequeridoByOferente(postData)
        .subscribe(
          (resp) => {
            this.notifyService.showSuccess(
              "Archivo agregado correctamente.",
              "Archivo agregado correctamente"
            );
            this.getDocRequeridos();
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Actualizar archivos requeridos"
            );
          }
        );
    } else {
      this.notifyService.showError(
        "Debe adjuntar un archivo.",
        "Archivos requeridos"
      );
    }

    this.archivos64 = null;
    this.fileName = "";
    this.type = "";
    this.inputFile.nativeElement.value = "";
    this.nombreArchivo = "";
  }

  resetFormDocRequeridoOferente() {
    this.formDocRequeridoOferente = new FormGroup({
      docSelected: new FormControl(null, Validators.required),
    });
  }

  getDocRequeridos() {
    this.licitacionService
      .getSelectDocRequeridosOferenteSaved(this.idLicitacion)
      .subscribe(
        (resp) => {
          const data: any[] = JSON.parse(resp);
          this.docRequeridos = data.filter(
            (doc) => doc.tipo_doc_correlativo === 2
          );

          for (let index = 0; index < this.docRequeridos.length; index++) {
            const element = this.docRequeridos[index];

            if (element.archivos.length <= 0) {
              this.formDocRequeridoOferente = new FormGroup({
                docSelected: new FormControl(
                  element.correlativo_doc_requerido,
                  Validators.required
                ),
              });

              this.docRequeridosValido = false;

              break;
            } else {
              this.formDocRequeridoOferente = new FormGroup({
                docSelected: new FormControl(null, Validators.required),
              });

              this.docRequeridosValido = true;
            }
          }
        },
        (error) => {
          this.notifyService.showError(error.error.message, "Error");
          this.docRequeridos = [];
          console.log(error);
        }
      );
  }

  deleteDocRequeridoByOferente(correlativo: number) {
    this.licitacionService
      .postDeleteDocRequeridoByOferente({ correlativo: correlativo })
      .subscribe((resp) => {
        this.notifyService.showSuccess(
          "Archivo eliminado correctamente.",
          "Archivo eliminado correctamente"
        );
        this.getDocRequeridos();
      });
  }

  enviarEdicionPresupuesto() {
    this.licitacionService
      .postUpdatePermisoEditarPresupuesto({ idLicitacion: this.idLicitacion })
      .subscribe(
        (resp) => {
          this.licitacionService
            .getSelectPermitirEditarPresupuesto(this.idLicitacion)
            .subscribe(
              (resp) => {
                const permisoEditarData: any[] = JSON.parse(resp);

                if (permisoEditarData[0].corregir_presupuesto === 1) {
                  this.isPermisoEditarPresupuesto = true;
                  this.isEnviarCorreccionPresupuesto = true;
                  this.presupuestoEnviadoStr = "";
                } else if (permisoEditarData[0].corregir_presupuesto === 2) {
                  this.isPermisoEditarPresupuesto = false;
                  this.isEnviarCorreccionPresupuesto = false;
                  this.presupuestoEnviadoStr =
                    "Corrección de presupuesto enviada";
                } else {
                  this.isPermisoEditarPresupuesto = false;
                  this.isEnviarCorreccionPresupuesto = false;
                  this.presupuestoEnviadoStr = "";
                }
              },
              (error) => {
                this.notifyService.showError(
                  error.error.message,
                  "Corrección de presupuesto"
                );
                console.log(error);
              }
            );

          this.notifyService.showSuccess(
            "Se ha enviado la corrección del presupuesto",
            "Corrección de presupuesto"
          );
        },
        (error) => {
          this.notifyService.showError(
            error.error.message,
            "Corrección de presupuesto"
          );
          console.log(error);
        }
      );
  }

  async onDescargarPDF() {
    this.downloadingPdf = true;
    const doc = new PDF({ format: "A2", unit: "mm" }, [
      "",
      "Detalle partida - subpartida",
    ]);

    doc.addLogo();
    doc.addTitle(`Licitacion ${this.objLicitacion.CONT_NOMBRE}`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 300;

    doc.identText(
      "Descripción",
      this.objLicitacion.DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Licitación",
      this.idLicitacion.toString(),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    // doc.identText(
    //   "Solpe",
    //   this.objLicitacion?.SOLPE_CORRELATIVO,
    //   iLengthTitleFirst,
    //   iLengthValueFirst
    // );

    doc.identText(
      "Estado",
      this.objLicitacion?.ETAPA_DESC,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha",
      this.objLicitacion?.FECHA_CREACION
        ? moment(this.objLicitacion?.FECHA_CREACION, "DD-MM-YYYY").format(
            "DD/MM/YYYY"
          )
        : "",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    // doc.identText(
    //   "Moneda",
    //   this.objLicitacion?.DESCRIPCION_MONEDA_SOLPE || "Peso Chileno",
    //   iLengthTitleFirst,
    //   iLengthValueFirst
    // );

    // doc.identText(
    //   "Monto",
    //   formatCurrency(this.objLicitacion?.MONTO_SOLPE || ""),
    //   iLengthTitleFirst,
    //   iLengthValueFirst
    // );

    doc.increasePageY(10);

    doc.addTitle("Resumen Oferta Económica");

    doc.addTableHtml("#table_presupuesto");

    doc.increasePageY(20);

    doc.text(
      "Por favor firme el documento para corroborar su oferta economica para esta licitación:",
      doc.iMargin,
      doc.nPageYAxi
    );
    doc.increasePageY(20);

    doc.addSign(`(${this.userName})`);
    doc.increasePageY(20);

    doc.addFooter();
    doc.save(`oferta_economia_${this.idLicitacion}.pdf`);
    this.downloadingPdf = false;
  }

  getSelectPresupuestoOferenteLicitacion() {
    this.objItemizadoService
      .getSelectPresupuestoOferenteLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          const dataPresupeusto: any[] = JSON.parse(resp);
          if (dataPresupeusto.length > 0) {
            this.montoTotalPresupuesto =
              dataPresupeusto[0].monto_total_oferta_economica_ingresado;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  validateMontoIngresado() {
    if (
      this.montoTotalPresupuesto === 0 ||
      Number.isNaN(this.montoTotalPresupuesto) ||
      this.montoTotalPresupuesto === null
    ) {
      this.notifyService.showWarning("Ingrese un monto valido.", "Presupuesto");
      return;
    } else {

      if (this.calcularTotal() != this.montoTotalPresupuesto) {
        this.notifyService.showWarning("El monto no coincide con el presupuesto.", "Presupuesto");
        return;
      }

      const postData = {
        montoTotalPresupuesto: this.montoTotalPresupuesto,
        idLicitacion: this.idLicitacion,
      };

      this.objItemizadoService
        .postInsertPresupuestoOferenteLicitacion(postData)
        .subscribe(
          (resp) => {
            this.getSelectPresupuestoOferenteLicitacion();
            this.stepSelected = 2;
            this.notifyService.showSuccess(
              "Se ha guardado el presupuesto",
              "Presupuesto"
            );
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
}
