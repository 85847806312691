import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private authService: AuthService,private router: Router,private notifyService : NotificationService) { }

  ngOnInit(): void {
  }
  
  onLogin(postLogin:any){
    this.authService.signin(postLogin).subscribe(resp =>{     
      if(resp){
        const result = JSON.parse(JSON.stringify(resp));
        switch (result.rolId){
          case 0: 
            this.router.navigate(['/contratista']);
          break;
          default: 
            //this.router.navigate(['/licitaciones']);       
            this.router.navigate(['/licitaciones']);          
          break;
        }

      }
    }, err =>{
      this.notifyService.showError('Error al iniciar sesion', 'Inicio sesion');
    });
  }

}
