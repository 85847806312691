import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ParticipantesService } from 'src/app/shared/services/participantes.service';

@Component({
  selector: 'app-modal-participantes',
  templateUrl: './modal-participantes.component.html',
  styleUrls: ['./modal-participantes.component.css'],
  providers: [LicitacionService, ParticipantesService]
})
export class ModalParticipantesComponent implements OnInit {

  @Input() participanteSeleccionado: number = 0;
  @Input() empresaSeleccionada: number = 0;
  @Input() idLicitacion: number;
  @Input() idEstapa: number = 0;
  @Output("getParticipantes") getParticipantes: EventEmitter<any> = new EventEmitter();
  empresas: any[] = []
  formParticipante: FormGroup;
  submittedParticipante = false;
  corrParticipanteSeleccionado: number;

  @ViewChild('openModal') openModal: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;

  constructor(private participanteServices: ParticipantesService, private notifyService: NotificationService, private licitacionService: LicitacionService) { }

  ngOnInit(): void {
    this.resetFormParticipante();
    if(this.empresaSeleccionada == 0){
      this.getEmpresas();
    }
  }

  abrirModal(){
    if(this.participanteSeleccionado){
      this.getParticipante(this.participanteSeleccionado)
    }else{
      this.resetFormParticipante();
      setTimeout(() => {
        this.formParticipante.controls.part_correlativo.setValue(this.empresas[0]?.PART_CORRELATIVO);
        this.openModal.nativeElement.click();
      }, 100);
    }
  }
  
  private getEmpresas() {
    this.participanteServices.getEmpresasParticipantes().subscribe(resp => {
      this.empresas = JSON.parse(resp);
    });
  }
  private getParticipante(corr_participante: number) {
    this.participanteServices.getEmpresa(corr_participante).subscribe(resp => {
      let participante = JSON.parse(resp)[0];      
      this.formParticipante.controls.part_correlativo.setValue(participante?.PART_CORRELATIVO_DEPENDE);
      this.formParticipante.controls.nombres.setValue(participante.NOMBRE);
      this.formParticipante.controls.apellido_paterno.setValue(participante.APATERNO);
      this.formParticipante.controls.apellido_materno.setValue(participante.AMATERNO);
      this.formParticipante.controls.rut.setValue(participante.RUT);
      this.formParticipante.controls.digito.setValue(participante.DIGRUT);
      this.formParticipante.controls.email.setValue(participante.EMAIL);
      this.formParticipante.controls.password.setValue(participante.PASSWORD);
      this.formParticipante.updateValueAndValidity();
      this.submittedParticipante = false;
      this.openModal.nativeElement.click();
    });
  }
  private resetFormParticipante() {
    /* this.formParticipante = new FormGroup({
      part_correlativo: new FormControl("", Validators.required),
      nombres: new FormControl(null, Validators.required),
      apellido_paterno: new FormControl(null, Validators.required),
      apellido_materno: new FormControl(null, Validators.required),
      rut: new FormControl(null, [ Validators.required ]),
      digito: new FormControl(null, [ Validators.required, Validators.minLength(1), Validators.maxLength(1) ]),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      tipo_participante: new FormControl(32, Validators.required)  
    }); */
    this.formParticipante = new FormGroup({
      part_correlativo: new FormControl("", Validators.required) 
    });
    this.submittedParticipante = false;
  }
  onGuardarParticipante() {
    this.submittedParticipante = true;
    if (this.formParticipante.invalid) {
      return;
    }

    /* const putData: { 
      in_part_correlativo: number, 
      iv_nombre: string, 
      iv_apellido_paterno: string, 
      iv_apellido_materno: string, 
      iv_razon_social: string,
      in_rut: number, 
      iv_digrut: string, 
      iv_email: string, 
      iv_password: string
      in_domi_correlativo_tipar: number
    } = {
      in_part_correlativo: this.part_correlativo, 
      iv_nombre: this.formParticipante.value.nombres, 
      iv_apellido_paterno: this.formParticipante.value.apellido_paterno, 
      iv_apellido_materno: this.formParticipante.value.apellido_materno, 
      iv_razon_social: '',
      in_rut: this.formParticipante.value.rut, 
      iv_digrut: this.formParticipante.value.digito, 
      iv_email: this.formParticipante.value.email, 
      iv_password: this.formParticipante.value.password,
      in_domi_correlativo_tipar: this.formParticipante.value.tipo_participante
    } */

    const postData : { 
      in_licitacion: number;
      in_etapa: number; 
      in_part_correlativo: number; 
      in_usuario: string; 
      in_activo: string;
      in_motivo_descalificacion: string;
      in_motivo_reuncia_correlativo: number;
    } = { 
      in_licitacion: this.idLicitacion, 
      in_etapa: this.idEstapa, 
      in_part_correlativo : this.part_correlativo, 
      in_usuario:'', 
      in_activo: 'S',
      in_motivo_descalificacion: '',
      in_motivo_reuncia_correlativo: 0
    };
    this.licitacionService.postPostulanteLic(postData).subscribe(resp =>{
      this.notifyService.showSuccess(JSON.parse(resp), 'Participante');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Participante');
    });
    

    /* if(this.editarParticipante) {
      this.participanteServices.putEmpresaOParticipante(putData).subscribe(resp=>{
        this.notifyService.showSuccess('Participante actualizado con exito.', 'Actualizar Participante');
      }, err =>{
        this.notifyService.showError('Error al actualizar participante', 'Actualizar Participante');
        console.error(putData);
        console.error(err);
      });      
    } else {
      this.participanteServices.putParticipante(putData).subscribe(resp=>{
        this.notifyService.showSuccess('Participante creado con exito.', 'Crear Participante');
      }, err =>{
        this.notifyService.showError('Error al crear participante', 'Crear Participante');
        console.error(putData);
        console.error(err);
      });
    } */
    
    this.getParticipantes.emit();
    this.closeModal.nativeElement.click();
    this.resetFormParticipante();
  }  

  get fp() { return this.formParticipante.controls; }
  get part_correlativo() { 
    return this.participanteSeleccionado !== 0 ? 
    this.participanteSeleccionado :
    this.formParticipante.value.part_correlativo;
  }
  get editarParticipante(){
    return this.participanteSeleccionado !== 0;
  }
}
