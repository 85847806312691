<div id="content" class="content">
    <div class="row">
        <!-- begin col-3 -->
        <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-blue">
                <div class="stats-icon"><i class="far fa-lg fa-fw m-r-10 fa-file-alt"></i></div>
                <div class="stats-info">
                    <h4>En creación</h4>
                    <p>{{en_creacion}}</p>
                </div>
                <div class="stats-link">
                    <a routerLink="/licitaciones/0">Ver detalle<i class="fa fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-info">
                <div class="stats-icon"><i class="fas fa-cog fa-spin"></i></div>
                <div class="stats-info">
                    <h4>En proceso</h4>
                    <p>{{en_proceso}}</p>
                </div>
                <div class="stats-link">
                    <a routerLink="/licitaciones/1">Ver detalle<i class="fa fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-xl-4 col-md-6">
            <div class="widget widget-stats bg-orange">
                <div class="stats-icon"><i class="fas fa-lg fa-fw m-r-10 fa-archive"></i></div>
                <div class="stats-info">
                    <h4>Adjudicadas</h4>
                    <p>{{adjudicadas}}</p>
                </div>
                <div class="stats-link">
                    <a routerLink="/licitaciones/2">Ver detalle<i class="fa fa-arrow-alt-circle-right"></i></a>
                </div>
            </div>
        </div>
        <!-- end col-3 -->
    </div>
</div>