<!-- begin #content -->
<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                    <h4 class="panel-title">Licitaciones</h4>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <div class="col-md-12">
                        <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nro. Contrato</th>
                                    <th>Nombre</th>
                                    <th class="text-nowrap">Etapa</th>
                                    <th class="text-nowrap">Fecha inicio etapa</th>
                                    <th class="text-nowrap">Fecha fin etapa</th>
                                    <th class="text-nowrap">Detalle proceso</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="odd gradeX" *ngFor="let item of licitaciones">
                                    <td>
                                        <label>{{ item.LIC_CORRELATIVO}}</label>
                                    </td>
                                    <td class="text-break">
                                        <label>{{ item.CONTRATO_CORRELATIVO}}</label>
                                    </td>
                                    <td class="text-break">
                                        <label>{{ item.CONTRATO_NOMBRE }}</label>
                                    </td>
                                    <td class="text-break">
                                        <label>{{ item.ETAPA_DESCRIPCION}}</label>
                                    </td>
                                    <td>
                                        <label>{{ item.FECHA_INICIO_ETAPA}}</label>
                                    </td>
                                    <td>
                                        <label>{{ item.FECHA_TERMINO_ETAPA}}</label>
                                    </td>
                                    <td>
                                        <button style="margin-left: 10px;" *ngIf="item.LIC_CORRELATIVO" type="button" class="btn btn-primary" (click)="onAvanzaEtapa(item.LIC_CORRELATIVO)">Avanzar etapa</button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>