<!-- begin #content -->
<div id="content" class="content">
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Ver etapa licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-12 col-md-6">
            <!-- begin panel -->
            <div class="bg-white rounded p3-rem" data-sortable-id="form-stuff-1">
                <!-- <div class="panel-heading">
                    <h4 class="panel-title">Ver etapa licitación</h4>
                </div> -->
                <div class="panel-body">
                    <div class="row mb-4">
                        <div class="col-12">
                            <h4>Ver etapa licitación</h4>
                        </div>
                    </div>

                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-md-3 col-form-label">
                            Participantes <span class="text-danger">*</span>
                        </label>
                        <div class="col-md-9">
                            <ng-container *ngFor="let item of participantes">
                                <ng-container *ngIf="item.VIGENTE == 'S'">
                                    <div class="checkbox checkbox-css pt-0">
                                        <input type="checkbox" value="" id="{{item.PART_CORRELATIVO}}" [checked]="item.ACTIVO ==='S'" disabled/>
                                        <label style="margin-bottom: 10px;" for="{{item.PART_CORRELATIVO}}">{{item.PART_NOMBRE}}</label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="item.VIGENTE == 'N'">
                                    <span [ngClass]="{'text-danger': item.VIGENTE == 'N'}">{{item.PART_NOMBRE}} - <span style="font-weight: bold;">Eliminado</span></span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <app-view-etapa-files-table [archivos]="archivos" [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                </div>
                <div class="panel-footer text-right">
                    <button style="margin-left: 10px;" type="button" class="btn btn-warning" routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Volver Cronograma</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end #content -->