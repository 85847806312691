import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-view-archivos-licitacion',
  templateUrl: './view-archivos-licitacion.component.html',
  styleUrls: ['./view-archivos-licitacion.component.css']
})
export class ViewArchivosLicitacionComponent implements OnInit {
  idLicitacion:number;
  idParticipante:number;
  idEtapa:number;
  idEtapaLic:number;
  nombreEtapa:string;
  archivosLicitacion: any[] = [];
  userId: string = '';

  constructor(private route: ActivatedRoute,
    private etapaService: EtapasService, 
    private licitacionService: LicitacionService, 
    private notifyService : NotificationService,
  ) { }

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (resp){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          });
        }
        this.getArchivoLicitacion(this.idLicitacion);
    });
  }

  private getArchivoLicitacion(idLicitacion: number){
    if(idLicitacion){
      this.licitacionService.getArchivosLicitacionOferente(this.idLicitacion,this.userId).subscribe(
        resp =>{
          this.archivosLicitacion = JSON.parse(resp);

          if (this.idEtapa == 5) {
            this.archivosLicitacion = this.archivosLicitacion.filter(x => x.ETAPA_CORRELATIVO != 5);
          }

          this.notifyService.showSuccess('Archivos obtenidos correctamente', 'Obtener archivos licitación');
        }, 
        err =>{
          this.notifyService.showError(err.error.message, 'Obtener archivos licitación');
        }
      );
    }else{
      this.notifyService.showError('Falta el id de la licitación', 'Obtener archivos licitación');
    }
  }
}
