import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LicitacionService } from '../shared/services/licitacion.service';
import { EtapasService } from '../shared/services/etapas.licitacion.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-mantenedores',
  templateUrl: './mantenedores.component.html',
  styleUrls: ['./mantenedores.component.css']
})
export class MantenedoresComponent implements OnInit {

  licitaciones: [];
  constructor(private route: ActivatedRoute, private licitacionService: LicitacionService, private etapaService : EtapasService, private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.getLicitaciones();
  }

  private getLicitaciones(){
    this.licitacionService.getLicitaciones('N').subscribe(resp=>{
      this.licitaciones = JSON.parse(resp);
    });
  }
  onAvanzaEtapa(idLicitacion: number){
    // const postData : {in_lic_correlativo:number, in_usuario: string} = { in_lic_correlativo: idLicitacion, in_usuario: ''};
    // this.etapaService.postAvanzaEtapaLic(postData).subscribe(resp=>{
    //   this.notifyService.showSuccess('La licitacion avanzó de etapa correctamente', 'Avanzar etapa');
    //   this.getLicitaciones();

    // }, err =>{
    //   this.notifyService.showError(err.error.message, 'Avanzar etapa');
    // });
  }
}
