import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { Location } from '@angular/common'

@Component({
  selector: 'app-edit-cronograma',
  templateUrl: './edit-cronograma.component.html',
  styleUrls: ['./edit-cronograma.component.css']
})
export class EditCronogramaComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  nombreEtapa: string;
  horasTemp:any[] =[];
  dTemp:any[] =[];
  dTempVal:any[] =[];
  hTemp:any[] =[];
  fechaInicio:any[] = [];
  fechaFin:any[] = [];
  cantHora:any[] = [];
  fechaCronogramaLic: any[] = [];
  cronograma:[];

  hInicioTemp:any[] =[]
  fInicioTemp: any[] = []
  hTerminoTemp:any[] =[]
  fTerminoTemp: any[] = []

  totalHoras: number;

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private etapaService : EtapasService,
    private notifyService : NotificationService,
    private location: Location
  ) { }

  ngOnInit(): void {

    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];
        this.licitacionService.getCronogramaLicUpdate(this.idLicitacion).subscribe(resp=>{
          this.cronograma = JSON.parse(resp);
          this.cronograma.forEach(element => {
            const el = JSON.parse(JSON.stringify(element));
            const idEtapa = el.ETAPA_CORRELATIVO;
            this.fechaInicio[idEtapa] = el.FECHA_INICIO_ETAPA;
            this.fechaFin[idEtapa] = el.FECHA_TERMINO_ETAPA;
            this.cantHora[idEtapa] = el.CANT_HORAS;
            this.horasTemp[idEtapa]= el.CANT_HORAS;
            this.fInicioTemp[idEtapa] = moment(el.FECHA_INICIO_ETAPA).format('YYYY-MM-DD');
            this.fTerminoTemp[idEtapa] = moment(el.FECHA_TERMINO_ETAPA).format('YYYY-MM-DD');

            this.fechaCronogramaLic.push({
              in_etapa: idEtapa,
              in_fecha_ini: this.fInicioTemp[idEtapa],
              in_fecha_fin: this.fTerminoTemp[idEtapa],
              in_hora: 0
            })
          });
        }, err =>{
          this.notifyService.showError(err.error.message, 'Obtener cronograma licitación');
        });
        if (this.idEtapa){
          this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
            if (JSON.parse(resp)[0]){
              this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
            }
          }, err =>{
            this.notifyService.showError(err.error.message, 'Obtener etapa');
          });
        }
    });
  }

  back(): void {
    this.location.back();
  }

  onChangeProceso(etapa_correlativo: number, activo:any){

    if (etapa_correlativo == this.idEtapa){
      this.notifyService.showWarning('No se puede desactivar la etapa seleccionada ya que es una etapa en curso.', 'Cambio de cronograma');
      return;
    }

    const putData: { in_licitacion: number;in_etapa_correl: number; in_usuario:string; in_activo: string; } = { in_licitacion:0, in_etapa_correl: 0 , in_usuario:'', in_activo:''};
    if (etapa_correlativo && this.idLicitacion){
      putData.in_licitacion = this.idLicitacion;
      putData.in_etapa_correl = etapa_correlativo;
      putData.in_activo = activo === true ? 'S' : 'N';
      this.licitacionService.putCronogramaLic(putData).subscribe(resp=>{
        this.notifyService.showSuccess(JSON.parse(resp), 'Cambio de cronograma');
      }, err =>{
        this.notifyService.showError(err.error.message, 'Cambio de cronograma');
      });
    }
  }

  /** Cambio en forma de ingreso fechas del formulario de creacion de licitacion */
  onFechaInicio( idEtapa: number, fecha: string){
    this.fInicioTemp[idEtapa] = fecha
    this.fechaInicio[idEtapa] = fecha

    this.fechaInicio.forEach((element, i) => {
      if (i >=  idEtapa + 1 ){
        this.fechaInicio[i] = '';
        this.fechaFin[i] ='';
      }
    });
  }
  onCalHrInicio( idEtapa: number,hrs: number){
    if (hrs < 25 && hrs >0){
      this.hInicioTemp[idEtapa]  = Number.isNaN(hrs) ? 0 : hrs;
    }else{
      this.hInicioTemp[idEtapa]= 0;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }
    this.fechaInicio[idEtapa] = this.fInicioTemp[idEtapa] + ' ' + hrs + ':00'
  }
  onFechaTermino( idEtapa: number, fecha: string){
    this.fTerminoTemp[idEtapa] = fecha
    this.fInicioTemp[idEtapa + 1] = fecha

    this.fechaInicio[idEtapa + 1] = fecha
    this.fechaFin[idEtapa] = fecha
  }
  onCalHrTermino( idEtapa: number,hrs: number){
    if (hrs < 25 && hrs >0){
      this.hTerminoTemp[idEtapa]  = Number.isNaN(hrs) ? 0 : hrs;
      this.hInicioTemp[idEtapa + 1]  = Number.isNaN(hrs) ? 0 : hrs;
    }else{
      this.hTerminoTemp[idEtapa]= 0;
      this.notifyService.showError('Debe ingresar numeros entre el 1 y 24', 'Calculo de horas');
    }

    this.fechaInicio[idEtapa + 1] = this.fTerminoTemp[idEtapa] + ' ' + hrs + ':00'
    this.fechaFin[idEtapa] = this.fTerminoTemp[idEtapa] + ' ' + hrs + ':00'

    this.fechaCronogramaLic.push({
      in_etapa: idEtapa,
      in_fecha_ini: this.fInicioTemp[idEtapa] + ' ' + this.hInicioTemp[idEtapa] + ':00' ,
      in_fecha_fin: this.fTerminoTemp[idEtapa] + ' ' + this.hTerminoTemp[idEtapa] + ':00',
      in_hora: 0
    })
  }

  checkValidDate(date: Date){
    const momentDate = moment(date);
    return momentDate.isValid();
  }
}
