import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SolpeService {
  private header;
  private urlService: string = environment.apiUrl;
  constructor(private http: HttpClient) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }

  getSolpes() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(this.urlService + "solpe/getSolpes", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  getSolpeFilter() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(this.urlService + "solpe/getSolpeFilter", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }

  getSolpeByCorrelativo(solpeCorrelativo: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http.get(
      `${this.urlService}solpe/getSolpeByCorrelativo/${solpeCorrelativo}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
}
