<ng-container>
  <div class="row mb-3 mt-5">
    <div class="col">
      <h4 class="d-flex justify-content-between">
        Oferentes
        <ng-container *ngIf="etapaActualLicitacion == 0 || etapaActualLicitacion == 18">
          <ng-container *ngIf="validateRoleParticipanteComision(37)">
            <a class="btn tag text-orange" (click)="openModalParticipante(0)">Agregar</a>
          </ng-container>
        </ng-container>
      </h4>
    </div>
  </div>
  <div class="form-group row m-b-15">
    <label class="col-form-label col-md-2"></label>
    <ng-container *ngIf="eliminarOfernete">
      <div class="col-12">
        <form [formGroup]="elminarParticipateForm" (ngSubmit)="eliminarParticipante()">
          <div class="form-group">
            <p>Motivo de descalificación: <span class="text-danger">*</span></p>
            <select class="form-control" formControlName="motivoRenunciaCorrelativo" #motivoRenunciaCorrelativo>
              <ng-container *ngFor="let item of motivoRenunciaList">
                <option *ngIf="item.vigente === 1" value="{{item.correlativo}}">{{item.descripcion}}</option>
              </ng-container>
            </select>
          </div>
          <div class="form-group">
            <p>Descripción: <span class="text-danger">*</span></p>
            <textarea class="form-control" formControlName="motivoDescalificacion" name="motivoDescalificacion"
              #motivoDescalificacion></textarea>
          </div>
          <div class="form-group">
            <p>Descripción archivo: <span class="text-danger">*</span></p>
            <input class="form-control" type="text" formControlName="nombreArchivo" name="nombreArchivo" #nombreArchivo>
          </div>
          <div class="form-group row m-b-10 align-items-center">
            <label class="col-md-3 col-form-label">Subir evidencia adicional <span class="text-danger">*</span></label>
            <div class="col-md-9">
              <input #inputFile style="border-color: transparent;" class="form-control" name="archivo" id="archivo"
                formControlName="archivo" type="file" (change)="detectFiles($event)" />
            </div>
          </div>
          <div class="text-right">
            <button class="btn tag text-orange mr-2" (click)="cancelarEliminarOferenete()">Cancelar</button>
            <button class="btn btn-primary" type="submit" [disabled]="elminarParticipateForm.invalid">Descalificar
              oferente</button>
          </div>
        </form>
        <br>
        <br>
      </div>
    </ng-container>
    <div class="col-md-12">
      <table id="table_participantes" class="table table-striped table-td-valign-middle">
        <thead class="thead-darkk">
          <tr>
            <!-- <th class="text-nowrap" style="vertical-align: top; text-align: center; width: 40px;"></th> -->
            <th class="text-nowrap">Empresa</th>
            <th class="text-nowrap">Responsable</th>
            <th class="text-nowrap">Motivo Descalificación</th>
            <th class="text-nowrap">Descripción</th>
            <th style="width: 75px;">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="participantes.length == 0">
            <td colspan="3">
              <h4 class="text-center text-muted p-5">
                Sin participantes asignados
              </h4>
            </td>
          </tr>
          <tr class="odd gradeX" *ngFor="let item of participantes">
            <!-- <td style="text-align: center;">
              <input #part type="checkbox" id="part{{item.CORRELATIVO}}" [checked]="item.ACTIVO" (change)="onUpdateParticipante(item.PART_CORRELATIVO, part.checked)" />
            </td> -->
            <!-- <td>{{ item.EMPRESA }}</td>
            <td>{{ item.NOMBRE }}</td> -->
            <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}">{{ item.RAZON_SOCIAL }}</td>
            <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}">{{ item.ENCARGADO }}</td>
            <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}">{{ item.MOTIVO_RENUNCIA_TABULADA }}</td>
            <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}">{{ item.MOTIVO_DESCALIFICACION }}</td>
            <td class="text-center">
              <!-- eliminarParticipante(item.PART_CORRELATIVO) -->
              <ng-container *ngIf="item.VIGENTE == 'S'">
                <ng-container *ngIf="etapaActualLicitacion == 0 || etapaActualLicitacion == 18">
                  <ng-container *ngIf="(validateRoleParticipanteComision(37) || (idRol == 15))">
                    <a class="btn rounded-icon" href="javascript:;" data-toggle="modal"
                      (click)="onEliminarParticipante(item.PART_CORRELATIVO)" title="Eliminar">
                      <i class="fas fa-trash"></i>
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item.VIGENTE == 'N'">
                <span class="tab tab-red">Eliminado</span>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="row">
        <div class="col-md-12">
          <span class="text-muted mt-2">Nota: Se enviará un correo electrónico a todos los participantes seleccionados previamente.</span>
        </div>
      </div> -->
    </div>
  </div>
</ng-container>

<app-modal-participantes #modalParticipante [empresaSeleccionada]="'0'"
  [participanteSeleccionado]="participanteSeleccionado" [idLicitacion]="idLicitacion" [idEstapa]="idEtapaLic"
  (getParticipantes)="getParticipantes()"></app-modal-participantes>