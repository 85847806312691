import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { EtapasService } from 'src/app/shared/services/etapas.licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FileService } from 'src/app/shared/services/file.service';
import { ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-upload-etapa-archivo',
  templateUrl: './upload-etapa-archivo.component.html',
  styleUrls: ['./upload-etapa-archivo.component.css'],
  providers: [LicitacionService, EtapasService, FileService]
})
export class UploadEtapaArchivoComponent implements OnInit {
  idLicitacion: number;
  idParticipante: number;
  idEtapa: number;
  idEtapaLic: number;
  participantes: [];
  archivos64 : Blob ;
  fileName: string;
  type: string;
  nombreEtapa: string;
  archivo: string ;
  archivos:[]=[];
  @ViewChild('inputFile') inputFile: ElementRef;
  nombreArchivo: string;
  stepSelected: number;
  docRequeridos: any[];
  formDocRequeridoOferente: FormGroup;
  docRequeridosValido: boolean;

  constructor(private route: ActivatedRoute, private router: Router, 
    private licitacionService: LicitacionService, 
    private etapaService : EtapasService, 
    private notifyService : NotificationService,
    private fileService: FileService,
    private location: Location
  ) {
    this.stepSelected = 0;
    this.docRequeridos = [];
    this.docRequeridosValido = false;
    
    this.formDocRequeridoOferente = new FormGroup({
      docSelected: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {

    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idLicitacion = params['idLicitacion'];
        // this.idEtapa = params['idEtapa'];
        this.idEtapaLic = params['idEtapaLic'];

        this.licitacionService.getDatosPart().subscribe(resp=>{      
          this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
          if (this.idParticipante){
            this.getArchivoPartcipante();
          }
        });

        this.licitacionService.getEtapaActualLicitacion(this.idLicitacion).subscribe(
          resp=>{
            const etapa: any[] = JSON.parse(resp);
            console.log(etapa[0].etapa_correlativo);

            if (etapa.length > 0){ 
              this.idEtapa = etapa[0].etapa_correlativo;

              if (this.idEtapa){
                this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
                  if (resp){
                    this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
                  }
                });
              }
      
              if (this.idEtapa){
                this.etapaService.getEtapaId(this.idEtapa).subscribe(resp=>{
                  if (resp){
                    this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC
                  }
                });
              }

              this.getDocRequeridos();
            }
          },
          error=>console.log(error)
        );
      }
    );
  }

  back(): void {
    this.location.back();
  }

  getArchivoLicEtapaFull(){
    this.etapaService.getArchivoLicEtapaFull(this.idLicitacion,this.idEtapa).subscribe(resp=>{
      this.archivos = JSON.parse(resp);
    });
  }

  private getArchivoPartcipante(){
    this.licitacionService.getArchivosPartLic(this.idEtapaLic,this.idParticipante,27).subscribe(resp =>{
      // if (JSON.parse(resp)[0]){
      //   this.archivos = JSON.parse(resp);
      // }
      this.archivos = JSON.parse(resp);
    });
  }

  detectFiles(event: any){
    const reader = new FileReader(); 
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);       
      reader.onload = () => {
        bs64 = reader.result;        
        if (bs64 !== null){                      
              this.archivos64 = bs64; 
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ; 
        }
      };
    }
  }

  onSubirArchivo(){
    const postData: { 
      in_det_etapa_lic: number;
      files: Blob; 
      fileName: string; 
      type : string; 
      in_usuario: string, 
      in_part_correlativo: number, 
      in_domi_correlativo_tiar: number, 
      publico: number; 
      descripcion_input_archivo: string;
      idLicitacion: number;
      idEtapaLicitacion: number;
      correlativoGrupoArchivo: number
    } = { 
      in_det_etapa_lic: this.idEtapaLic, 
      files : this.archivos64, 
      fileName: this.fileName, 
      type: this.type, 
      in_usuario:'' , 
      in_part_correlativo: this.idParticipante, 
      in_domi_correlativo_tiar: 27, 
      publico: 1, 
      descripcion_input_archivo: this.nombreArchivo == null || this.nombreArchivo == '' ? this.fileName : this.nombreArchivo,
      idLicitacion: this.idLicitacion,
      idEtapaLicitacion: this.idEtapa,
      correlativoGrupoArchivo: 0
    };
    if (postData.files){
      this.licitacionService.postArchivosLicitacion(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Archivo enviado correctamente.', 'Archivo enviado correctamente');
        this.getArchivoPartcipante();
        //this.getArchivoLicEtapaFull();
      }, err =>{
        this.notifyService.showError(err.error.message, 'Actualizar etapa');
      });
    }else{
      this.notifyService.showError('Debe adjuntar un archivo.', 'Actualizar etapa');
    }

    this.archivos64 = null; 
    this.fileName = '';
    this.type = '';
    this.inputFile.nativeElement.value = "";
    this.nombreArchivo = '';
  }

  onDescargar(fileName: string){    
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }

  onAddDocRequeridoOferente(){
    if (this.formDocRequeridoOferente.invalid){
      this.notifyService.showWarning("Todos los campos son obligatorios", "Advertencia");
      return;
    }

    const postData: {
      correlativo_doc_requerido: number;
      files: Blob; 
      fileName: string; 
      type : string;
      idLicitacion: number;
    } = {
      correlativo_doc_requerido: this.formDocRequeridoOferente.value.docSelected,
      files : this.archivos64, 
      fileName: this.fileName, 
      type: this.type,
      idLicitacion: this.idLicitacion
    };
    if (postData.files){
      this.licitacionService.postCreateDocumentoRequeridoByOferente(postData).subscribe(resp=>{
        this.notifyService.showSuccess('Archivo agregado correctamente.', 'Archivo agregado correctamente');
        this.getDocRequeridos();
      }, err =>{
        this.notifyService.showError(err.error.message, 'Actualizar archivos requeridos');
      });
    }else{
      this.notifyService.showError('Debe adjuntar un archivo.', 'Archivos requeridos');
    }

    this.archivos64 = null; 
    this.fileName = '';
    this.type = '';
    this.inputFile.nativeElement.value = "";
    this.nombreArchivo = '';
  }

  resetFormDocRequeridoOferente(){
    this.formDocRequeridoOferente = new FormGroup({
      docSelected: new FormControl(null, Validators.required)
    });
  }

  getDocRequeridos(){
    this.licitacionService.getSelectDocRequeridosOferenteSaved(this.idLicitacion).subscribe(
      resp=>{
        const data: any[] = JSON.parse(resp);
        
        if (this.idEtapa == 6){ 
          this.docRequeridos = data.filter((doc)=> doc.tipo_doc_correlativo === 1 || doc.tipo_doc_correlativo === 3);
        } else if(this.idEtapa == 10){
          this.docRequeridos = data.filter((doc)=> doc.tipo_doc_correlativo === 2);
        }

        for (let index = 0; index < this.docRequeridos.length; index++) {
          const element = this.docRequeridos[index];
          
          if (element.archivos.length <= 0){ 
            this.formDocRequeridoOferente = new FormGroup({
              docSelected: new FormControl(element.correlativo_doc_requerido, Validators.required)
            });

            this.docRequeridosValido = false;

            break;
          }else{
            this.formDocRequeridoOferente = new FormGroup({
              docSelected: new FormControl(null, Validators.required)
            });

            this.docRequeridosValido = true;
          }
        }
      },
      error => {
        this.notifyService.showError(error.error.message, 'Error');
        this.docRequeridos = [];
        console.log(error);
      }
    );
  }

  deleteDocRequeridoByOferente(correlativo: number){
    this.licitacionService.postDeleteDocRequeridoByOferente({correlativo: correlativo}).subscribe(
      resp=>{
        this.notifyService.showSuccess('Archivo eliminado correctamente.', 'Archivo eliminado correctamente');
        this.getDocRequeridos();
      }
    );
  }
}
