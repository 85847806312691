<div>
    <form [formGroup]="correosForm" (ngSubmit)="onEnviarCorreo()">
        <div class="row">
            <div style="padding-left: 20px; padding-right: 20px; border-right: solid 1px;" class="col-lg-6 col-md-12">
                <p>Seleccione a los destinatarios de la comisión:</p>
                <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-3">Comisión:</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="destinatarioComision" (change)="onChangeDestinatarioComision($event.target.value)">	
                            <option value="0">Seleccione...</option>										
                            <option *ngFor="let item of comisiones; let i = index" value="{{i}}">{{item.PART_NOMBRE}} - {{item.EMAIL}}</option>
                        </select>
                    </div>
                </div>
                <p>Seleccione a los destinatarios oferentes:</p>
                <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-3">Participantes:</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="destinatarioParticipante" (change)="onChangeDestinatarioOferentes($event.target.value)">	
                            <option value="0">Seleccione...</option>
                            <ng-container *ngFor="let item of participantes; let i = index">
                                <option *ngIf="item.VIGENTE == 'S'" value="{{i}}">{{item.RAZON_SOCIAL}} - {{item.CORREO_ELECTRONICO}}</option>
                            </ng-container>										
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <p *ngIf="destinatariosCorreo.length <= 0" class="text-center">No hay destinatarios seleccionados.</p>
                    <div *ngIf="destinatariosCorreo.length > 0">
                        <h4>Destinatarios:</h4>
                        <table id="data-table-default" class="table table-striped table-td-valign-middle">
                            <thead>
                                <tr>
                                    <th>Destinatario</th>
                                    <th>Correo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="odd gradeX" *ngFor="let item of destinatariosCorreo; let i = index">
                                    <td>
                                        <span>{{item.destinatario}}</span>
                                    </td>
                                    <td class="text-break">
                                        <span>{{item.correo}}</span>
                                    </td>
                                    <td>
                                        <a href="javascript:;" title="Eliminar" class="btn rounded-icon" (click)="onEliminarDestinatario(i)"><i class="fas fa-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div style="padding-left: 20px; padding-right: 20px;" class="col-lg-6 col-md-12">
                <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-3">Asunto:</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" name="asuntoCorreo" id="asuntoCorreo" formControlName="asuntoCorreo">
                    </div>
                </div>
                <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-3">Cuerpo:</label>
                    <div class="col-md-9">
                        <textarea class="form-control" rows="5" name="cuerpoCorreo" id="cuerpoCorreo" formControlName="cuerpoCorreo"></textarea>
                    </div>
                </div>
                <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-3">Descripción archivo:</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" name="nombreArchivo" id="nombreArchivo" formControlName="nombreArchivo">
                    </div>
                </div>
                <div class="form-group row m-b-10 align-items-center">
                    <label class="col-md-3 col-form-label">Subir evidencia adicional</label>
                    <div class="col-md-9">
                        <input #inputFile style="border-color: transparent;" class="form-control" name="archivo" id="archivo" formControlName="archivo" type="file" (change)="detectFiles($event)" />
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <footer style="text-align: center;">
            <button class="btn btn-success" type="submit" [disabled]="correosForm.invalid">Enviar</button>
        </footer>
    </form>
</div>
