import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../shared/services/auth.service";
import { GlobalDataService } from "../shared/services/global-data.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  private userSub: Subscription;
  isAuth = false;
  cargos: any[] = [];
  idCargoActual: number = 0;
  nombreCargoActual: string = null;
  modulos: any[] = [];
  notificaciones: any[];
  stepNotifications: number;
  rutasAutorizadas: string[];

  constructor(
    private authService: AuthService,
    private router: Router,
    private globalData: GlobalDataService
  ) {
    this.notificaciones = [];
    this.stepNotifications = 0;
    this.rutasAutorizadas = [];
  }
  userData: any;
  ngOnInit(): void {
    this.userSub = this.authService.user.subscribe((user) => {
      this.userData = JSON.parse(JSON.stringify(user));
      this.isAuth = !!user;

      if (this.isAuth) {
        this.authService.getCargosPorUsuario(this.userData.userId).subscribe(
          (resp) => {
            this.cargos = JSON.parse(resp);
            this.idCargoActual = JSON.parse(
              localStorage.getItem("userData")
            ).rolId;
            this.nombreCargoActual = JSON.parse(
              localStorage.getItem("userData")
            ).rolName;
            let rolData: string = this.idCargoActual.toString();
            this.globalData.setRol = rolData;

            // rutas autorizadas para el oferente, en caso de no ser alguna de las rutas autorizadas entonces retornara una ruta de no autorizado
            this.rutasAutorizadas = [
              "/upload-etapa-archivo/",
              "/view-respuesta-economica/",
              "/view-respuesta-aclaracion/",
              "/create-consulta-licitacion/",
              "/evaluacion/",
              "/view-respuesta-negociacion/",
              "/cargar-presupuesto/",
              "/adjudicacion-contratista/",
              "/contratista",
              "/auth",
              "/view-cronograma-contratista/",
              "/view-archivo-licitacion/",
            ];

            if (JSON.parse(localStorage.getItem("userData")).rolId === 0) {
              if (this.validarURL(this.rutasAutorizadas)) {
                console.log("Ruta autorizada");
              } else {
                this.router.navigate(["/not-autorization"]);
              }
            }

            setTimeout(() => {
              this.authService.getSelectNotificacionesByUsuario().subscribe(
                (resp) => {
                  this.notificaciones = JSON.parse(resp);
                },
                (error) => {
                  console.log(error);
                }
              );
            }, 500);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  cambiarCargo(rolId: number, rolName: string) {
    this.idCargoActual = rolId;
    this.nombreCargoActual = rolName;

    let usuario: {
      userId: string;
      _token: string;
      _tokenExpiration: string;
      userName: string;
      rolId: number;
      rolName: string;
    } = JSON.parse(localStorage.getItem("userData"));

    usuario.rolId = this.idCargoActual;
    usuario.rolName = this.nombreCargoActual;

    localStorage.setItem("userData", JSON.stringify(usuario));

    this.nombreCargoActual = JSON.parse(
      localStorage.getItem("userData")
    ).rolName;
    let rolData: string = this.idCargoActual.toString();
    this.globalData.setRol = rolData;

    /* this.authService.getModulosPorCargo(this.idCargoActual).subscribe(resp => {
      this.modulos = JSON.parse(resp);
    }, 
    error => console.log(error),
    () => { */
    this.router.navigate(["/licitaciones"]);
    /* }); */
  }

  onLogout() {
    this.authService.logout();
  }

  marcarNotificacionesComoLeida(correlativo: number) {
    const postData = {
      correlativo: correlativo,
    };

    this.authService.postUpdateNotificacionLeida(postData).subscribe(
      (resp) => {
        this.authService.getSelectNotificacionesByUsuario().subscribe(
          (resp) => {
            this.notificaciones = JSON.parse(resp);
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => console.log(error)
    );
  }

  marcarTodoLeido() {
    this.authService.getUpdateMarcarTodoLeido().subscribe(
      (resp) => {
        this.authService.getSelectNotificacionesByUsuario().subscribe(
          (resp) => {
            this.notificaciones = JSON.parse(resp);
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => console.log(error)
    );
  }

  validarURL(urls: string[]): boolean {
    return urls.some((url) => this.router.url.includes(url));
  }
}
