import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from "./auth/auth.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { CreateLicitacionComponent } from "./licitaciones/create-licitacion/create-licitacion.component";
import { EditLicitacionComponent } from "./licitaciones/edit-licitacion/edit-licitacion.component";
import { LicitacionesComponent } from "./licitaciones/licitaciones.component";
import { ViewLicitacionComponent } from "./licitaciones/view-licitacion/view-licitacion.component";
import { EditEtapaLicitacionComponent } from "./licitaciones/edit-etapa-licitacion/edit-etapa-licitacion.component";
import { ViewConsultaLicitacionComponent } from "./licitaciones/view-consulta-licitacion/view-consulta-licitacion.component";
import { ViewRespuestaLicitacionComponent } from "./licitaciones/view-respuesta-licitacion/view-respuesta-licitacion.component";
import { ViewAclaracionTecnicaComponent } from "./licitaciones/view-aclaracion-tecnica/view-aclaracion-tecnica.component";
import { ContratistaComponent } from "./contratista/contratista.component";
import { CreateConsultaLicitacionComponent } from "./contratista/create-consulta-licitacion/create-consulta-licitacion.component";
import { ViewRespuestaAclaracionComponent } from "./contratista/view-respuesta-aclaracion/view-respuesta-aclaracion.component";
import { ViewAclaracionEconomicaComponent } from "./licitaciones/view-aclaracion-economica/view-aclaracion-economica.component";
import { ViewRespuestaEconomicaComponent } from "./contratista/view-respuesta-economica/view-respuesta-economica.component";
import { ViewNegociacionComponent } from "./licitaciones/view-negociacion/view-negociacion.component";
import { ViewRespuestaNegociacionComponent } from "./contratista/view-respuesta-negociacion/view-respuesta-negociacion.component";
import { EditCronogramaComponent } from "./cronograma/edit-cronograma/edit-cronograma.component";
import { CronogramaComponent } from "./cronograma/cronograma.component";
import { ViewCronogramaComponent } from "./licitaciones/view-cronograma/view-cronograma.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ViewCronogramaContratistaComponent } from "./contratista/view-cronograma-contratista/view-cronograma-contratista.component";
import { ViewEtapaReadonlyComponent } from "./shared/view-etapa-readonly/view-etapa-readonly.component";
import { ViewConsultaLicitacionReadonlyComponent } from "./shared/view-consulta-licitacion-readonly/view-consulta-licitacion-readonly.component";
import { ViewRespuestaLicitacionReadonlyComponent } from "./shared/view-respuesta-licitacion-readonly/view-respuesta-licitacion-readonly.component";
import { ViewAclaTecReadonlyComponent } from "./shared/view-acla-tec-readonly/view-acla-tec-readonly.component";
import { ViewAclaEcoReadonlyComponent } from "./shared/view-acla-eco-readonly/view-acla-eco-readonly.component";
import { ViewNegoReadonlyComponent } from "./shared/view-nego-readonly/view-nego-readonly.component";
import { MantenedoresComponent } from "./mantenedores/mantenedores.component";
import { ViewEtapaArchivoComponent } from "./licitaciones/view-etapa-archivo/view-etapa-archivo.component";
import { UploadEtapaArchivoComponent } from "./contratista/upload-etapa-archivo/upload-etapa-archivo.component";
import { ViewArchivosLicitacionComponent } from "./licitaciones/view-archivos-licitacion/view-archivos-licitacion.component";
import { ParticipantesComponent } from "./mantenedores/participantes/participantes.component";
import { CreateCriteriosComponent } from "./licitaciones/create-criterios/create-criterios.component";
import { EvalucionComponent } from "./licitaciones/evalucion/evalucion.component";
import { CalificarComponent } from "./licitaciones/calificar/calificar.component";
import { ResultadosComponent } from "./licitaciones/resultados/resultados.component";
import { CreateItemizadoComponent } from "./itemizado/create-itemizado/create-itemizado.component";
import { CargarPresupuestoComponent } from "./itemizado/cargar-presupuesto/cargar-presupuesto.component";
import { ResultadosItemizadoComponent } from "./itemizado/resultados-itemizado/resultados-itemizado.component";
import { VerItemizadoLicitacionComponent } from "./ver-itemizado-licitacion/ver-itemizado-licitacion.component";
import { ActaInicioComponent } from "./licitaciones/acta-inicio/acta-inicio.component";
import { MantenedorAyudaEvalTecnicaComponent } from "./mantenedor-ayuda-eval-tecnica/mantenedor-ayuda-eval-tecnica.component";
import { HistoricoCambiosCronogramaComponent } from "./historico-cambios-cronograma/historico-cambios-cronograma.component";
import { MantenedorMotivosRenunciaComponent } from "./mantenedor-motivos-renuncia/mantenedor-motivos-renuncia.component";
import { MantenedorEstandaresEvalTecComponent } from "./mantenedor-estandares-eval-tec/mantenedor-estandares-eval-tec.component";
import { MantenedorDocRequeridosOferenteComponent } from "./mantenedor-doc-requeridos-oferente/mantenedor-doc-requeridos-oferente.component";
import { AdministrarDocReqOferenteLicComponent } from "./administrar-doc-req-oferente-lic/administrar-doc-req-oferente-lic.component";
import { ObservacionesEtapasLicitacionComponent } from "./observaciones-etapas-licitacion/observaciones-etapas-licitacion.component";
import { AdjudicacionComponent } from "./adjudicacion/adjudicacion.component";
import { AdjudicacionContratistaComponent } from "./adjudicacion-contratista/adjudicacion-contratista.component";
import { ActaInicialComponent } from "./acta-inicial/acta-inicial.component";
import { AdministrarOferentesLicitacionComponent } from "./administrar-oferentes-licitacion/administrar-oferentes-licitacion.component";
import { AdministrarComisionComponent } from "./administrar-comision/administrar-comision.component";

const appRoutes: Routes = [
  { path: "", redirectTo: "/auth", pathMatch: "full" },
  { path: "auth", component: AuthComponent },
  {
    path: "home",
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    component: DashboardComponent,
  },
  {
    path: "licitaciones",
    canActivate: [AuthGuard],
    component: LicitacionesComponent,
  },
  {
    path: "licitaciones/:idFiltro",
    canActivate: [AuthGuard],
    component: LicitacionesComponent,
  },
  {
    path: "view-licitacion/:id",
    canActivate: [AuthGuard],
    component: ViewLicitacionComponent,
  },
  {
    path: "view-archivo-licitacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewArchivosLicitacionComponent,
  },
  {
    path: "view-etapa-archivo/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewEtapaArchivoComponent,
  },
  {
    path: "upload-etapa-archivo/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: UploadEtapaArchivoComponent,
  },
  {
    path: "view-consulta-licitacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewConsultaLicitacionComponent,
  },
  {
    path: "view-respuesta-licitacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewRespuestaLicitacionComponent,
  },
  {
    path: "view-aclaracion-tecnica/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewAclaracionTecnicaComponent,
  },
  {
    path: "view-aclaracion-economica/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewAclaracionEconomicaComponent,
  },
  {
    path: "view-negociacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewNegociacionComponent,
  },
  {
    path: "edit-etapa-licitacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: EditEtapaLicitacionComponent,
  },
  {
    path: "view-etapa-readonly/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewEtapaReadonlyComponent,
  },
  {
    path: "view-consulta-licitacion-readonly/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewConsultaLicitacionReadonlyComponent,
  },
  {
    path: "view-respuesta-licitacion-readonly/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewRespuestaLicitacionReadonlyComponent,
  },
  {
    path: "view-acla-tec-readonly/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewAclaTecReadonlyComponent,
  },
  {
    path: "view-acla-eco-readonly/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewAclaEcoReadonlyComponent,
  },
  {
    path: "view-nego-readonly/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewNegoReadonlyComponent,
  },
  {
    path: "create-licitacion",
    canActivate: [AuthGuard],
    component: CreateLicitacionComponent,
  },
  {
    path: "edit-licitacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: EditLicitacionComponent,
  },
  {
    path: "cronograma",
    canActivate: [AuthGuard],
    component: CronogramaComponent,
  },
  {
    path: "mantenedores",
    canActivate: [AuthGuard],
    component: MantenedoresComponent,
  },
  {
    path: "view-cronograma/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewCronogramaComponent,
  },
  {
    path: "view-cronograma-contratista/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewCronogramaContratistaComponent,
  },
  {
    path: "edit-cronograma/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: EditCronogramaComponent,
  },
  {
    path: "contratista",
    canActivate: [AuthGuard],
    component: ContratistaComponent,
  },
  {
    path: "create-consulta-licitacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: CreateConsultaLicitacionComponent,
  },
  {
    path: "view-respuesta-aclaracion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewRespuestaAclaracionComponent,
  },
  {
    path: "view-respuesta-economica/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewRespuestaEconomicaComponent,
  },
  {
    path: "view-respuesta-negociacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ViewRespuestaNegociacionComponent,
  },
  {
    path: "participantes",
    canActivate: [AuthGuard],
    component: ParticipantesComponent,
  },
  {
    path: "crear-criterios/:idLicitacion/:idEtapa/:idEtapaLic/:idOrigen",
    canActivate: [AuthGuard],
    component: CreateCriteriosComponent,
  },
  {
    path: "evaluacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: EvalucionComponent,
  },
  {
    path: "calificar/:idLicitacion",
    canActivate: [AuthGuard],
    component: CalificarComponent,
  },
  {
    path: "resultados/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ResultadosComponent,
  },
  {
    path: "itemizado/:idLicitacion/:idEtapa/:idEtapaLic/:idOrigen",
    canActivate: [AuthGuard],
    component: CreateItemizadoComponent,
  },
  {
    path: "cargar-presupuesto/:idLicitacion",
    canActivate: [AuthGuard],
    component: CargarPresupuestoComponent,
  },
  {
    path: "resultados-economicos/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: ResultadosItemizadoComponent,
  },
  {
    path: "ver-itemizado/:idLicitacion/:idEtapa/:idEtapaLic/:idOrigen",
    canActivate: [AuthGuard],
    component: VerItemizadoLicitacionComponent,
  },
  {
    path: "mantenedor-ayuda-eval-tecnica",
    canActivate: [AuthGuard],
    component: MantenedorAyudaEvalTecnicaComponent,
  },
  {
    path: "historico-cronogramas-licitaciones",
    canActivate: [AuthGuard],
    component: HistoricoCambiosCronogramaComponent,
  },
  {
    path: "mantenedor-motivos-renuncia",
    canActivate: [AuthGuard],
    component: MantenedorMotivosRenunciaComponent,
  },
  {
    path: "mantenedor-estadares-eval-tecnica",
    canActivate: [AuthGuard],
    component: MantenedorEstandaresEvalTecComponent,
  },
  {
    path: "mantenedor-doc-requeridos-oferente",
    canActivate: [AuthGuard],
    component: MantenedorDocRequeridosOferenteComponent,
  },
  {
    path: "administrar-doc-req-oferente-lic/:idLicitacion",
    canActivate: [AuthGuard],
    component: AdministrarDocReqOferenteLicComponent,
  },
  {
    path: "observaciones-etapas/:idLicitacion",
    canActivate: [AuthGuard],
    component: ObservacionesEtapasLicitacionComponent,
  },
  {
    path: "adjudicacion/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: AdjudicacionComponent,
  },
  {
    path: "adjudicacion-contratista/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: AdjudicacionContratistaComponent,
  },
  // {
  //   path: "acta-inicio/:idLicitacion",
  //   canActivate: [AuthGuard],
  //   component: ActaInicioComponent,
  // },
  {
    path: "acta-inicial/:idLicitacion",
    canActivate: [AuthGuard],
    component: ActaInicialComponent,
  },
  {
    path: "administrar-oferentes/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: AdministrarOferentesLicitacionComponent,
  },
  {
    path: "administrar-comision/:idLicitacion/:idEtapa/:idEtapaLic",
    canActivate: [AuthGuard],
    component: AdministrarComisionComponent,
  },
  { path: "not-autorization", component: NotFoundComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
