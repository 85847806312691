import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalPlaces]'
})
export class DecimalPlacesDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const regex = /^\d+(\.\d{1,2})?$/;
    //const regex = /^-?\d+(,\d{3})*(\.\d{1,2})?$/;
    if (Number.isInteger(parseFloat(value))) return //cuando es un valor entero no hace nada 
    if (regex.test(value)) {
      this.control.control.setValue(value);
    } else {
      const valorRedondeado = parseFloat(value).toFixed(2); //trunca a 2 si tiene mas decimales
      this.control.control.setValue(valorRedondeado);
    }
  }

}
