<!-- <table id="data-table-default" class="table table-striped table-td-valign-middle mt-5" *ngIf="archivos && archivos.length > 0">
  <thead>
      <tr>
            <th class="text-nowrap">
                <span>
                    Subido por
                </span>
            </th>
            <th class="text-nowrap">
                <span>
                    Etapa
                </span>
            </th>
            <th class="text-nowrap">
                <span>
                    Fecha creación
                </span>
            </th>
            <th class="text-nowrap">
                <span>
                    Nombre archivo
                </span>
            </th>
          <th class="text-nowrap"></th>
      </tr>
  </thead>
  <tbody>
      <tr class="odd gradeX" *ngFor="let item of archivos">
          <td class="text-break">
            <span *ngIf="item.PARTICIPANTE == 0">
                {{item.USUARIO}}
            </span>
            <span *ngIf="item.PARTICIPANTE > 0">
                {{item.USUARIO2}}
            </span>
            </td>
          <td class="text-break">
            <span>
                {{nombreEtapa}}
            </span>
            </td>
          <td>
            <span>
                {{item.FECHA_ARCHIVO}}
            </span>
            </td>
            <td>
                <span>
                    {{item.DESCRIPCION}}
                </span>
            </td>
          <td class="text-center">
              <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
          </td>
      </tr>
  </tbody>
</table> -->
<div id="data-table" class="mt-4" *ngIf="archivosFiltrados && archivosFiltrados?.length > 0">
    <ng-container *ngIf="type == 0">
        <div class="file border rounded mb-2" *ngFor="let item of archivosFiltrados">
            <div *ngIf="idCargoActual === 12">
                <div class="row">
                    <!-- <div class="col-2 content-center">
                        <i class="fas fa-file text-primary mr-2"></i>
                        
                    </div> -->
                    <div class="col-9">
                        <div class="file-detail content-center nowrap">
                            <div class="file-img content-center">
                                <i class="far fa-file-alt text-primary mr-2"></i>
                            </div>
                            <div class="">
                                <p class="mb-1 nombreArchivoCustom" *ngIf="item.DESCRIPCION_ARCHIVO == ''">
                                    {{item.DESCRIPCION}}
                                </p>
                                <p class="mb-1" *ngIf="item.DESCRIPCION_ARCHIVO != ''">
                                    {{item.DESCRIPCION_ARCHIVO}}
                                </p>
                                <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE == 0">
                                    {{item.USUARIO}}
                                </p>
                                <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE > 0">
                                    {{item.USUARIO2}}
                                </p>
                                <p class="text-muted mb-0">
                                    {{item.FECHA_ARCHIVO}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 content-center" style="justify-content: end;">
                        <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                    </div>
                </div>
            </div>
            <div *ngIf="idCargoActual != 12">
                <div *ngIf="item.PUBLICO > 0" >
                    <!-- <div class="col-1">
                        <i class="fas fa-file text-primary mr-2"></i>
                    </div>
                    <div class="col-8">
                        <p class="mb-1 nombreArchivoCustom">
                            {{item.DESCRIPCION}}
                        </p>
                        <p class="mb-1">
                            {{item.DESCRIPCION_ARCHIVO}}
                        </p>
                        <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE == 0">
                            {{item.USUARIO}}
                        </p>
                        <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE > 0">
                            {{item.USUARIO2}}
                        </p>
                        <p class="text-muted mb-0">
                            {{item.FECHA_ARCHIVO}}
                        </p>
                    </div> -->
                    <div class="row">
                        <div class="col-9">
                            <div class="file-detail content-center nowrap">
                                <div class="file-img content-center">
                                    <i class="far fa-file-alt text-primary mr-2"></i>
                                </div>
                                <div class="">
                                    <p class="mb-1 nombreArchivoCustom" *ngIf="item.DESCRIPCION_ARCHIVO == ''">
                                        {{item.DESCRIPCION}}
                                    </p>
                                    <p class="mb-1" *ngIf="item.DESCRIPCION_ARCHIVO != ''">
                                        {{item.DESCRIPCION_ARCHIVO}}
                                    </p>
                                    <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE == 0">
                                        {{item.USUARIO}}
                                    </p>
                                    <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE > 0">
                                        {{item.USUARIO2}}
                                    </p>
                                    <p class="text-muted mb-0">
                                        {{item.FECHA_ARCHIVO}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 content-center" style="justify-content: end;">
                            <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                        </div>
                    </div>
                    <!-- <div class="col-3 text-right">
                        <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                    </div> -->
                </div>
            </div>
            <!-- <td class="text-break">
                <span>
                    {{nombreEtapa}}
                </span>
            </td> -->
            <!-- <td class="text-center">
                <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
            </td> -->
        </div>
    </ng-container>

    <ng-container *ngIf="type == 1">
        <div class="row">
            <div class="col-12 col-md-4 mb-2" *ngFor="let item of archivosFiltrados">
                <div class="file border rounded" *ngIf="idCargoActual === 2">
                    <div class="row">
                        <!-- <div class="col-2 content-center">
                            <i class="fas fa-file text-primary mr-2"></i>
                            
                        </div> -->
                        <div class="col-9">
                            <div class="file-detail content-center nowrap">
                                <div class="file-img content-center">
                                    <i class="far fa-file-alt text-primary mr-2"></i>
                                </div>
                                <div class="">
                                    <p class="mb-1 nombreArchivoCustom" *ngIf="item.DESCRIPCION_ARCHIVO == ''">
                                        {{item.DESCRIPCION}}
                                    </p>
                                    <p class="mb-1" *ngIf="item.DESCRIPCION_ARCHIVO != ''">
                                        {{item.DESCRIPCION_ARCHIVO}}
                                    </p>
                                    <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE == 0">
                                        {{item.USUARIO}}
                                    </p>
                                    <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE > 0">
                                        {{item.USUARIO2}}
                                    </p>
                                    <p class="text-muted mb-0">
                                        {{item.FECHA_ARCHIVO}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 content-center" style="justify-content: end;">
                            <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                        </div>
                    </div>
                </div>
                <div class="file border rounded" *ngIf="idCargoActual != 2">
                    <ng-container *ngIf="item.PUBLICO > 0">
                        <div class="row">
                            <div class="col-9">
                                <div class="file-detail content-center nowrap">
                                    <div class="file-img content-center">
                                        <i class="far fa-file-alt text-primary mr-2"></i>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 nombreArchivoCustom" *ngIf="item.DESCRIPCION_ARCHIVO == ''">
                                            {{item.DESCRIPCION}}
                                        </p>
                                        <p class="mb-1" *ngIf="item.DESCRIPCION_ARCHIVO != ''">
                                            {{item.DESCRIPCION_ARCHIVO}}
                                        </p>
                                        <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE == 0">
                                            {{item.USUARIO}}
                                        </p>
                                        <p class="text-muted mb-0" *ngIf="item.PARTICIPANTE > 0">
                                            {{item.USUARIO2}}
                                        </p>
                                        <p class="text-muted mb-0">
                                            {{item.FECHA_ARCHIVO}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 content-center" style="justify-content: end;">
                                <app-view-file-buttons [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="mt-4" *ngIf="archivosFiltrados?.length == 0">
    <div class="row nofiles">
        <div class="col-12">
            <h4 class="text-center text-muted pb-0">Sin documentos registrados</h4>
        </div>
    </div>
</div>