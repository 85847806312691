import { Component, OnInit } from "@angular/core";
import { Params, ActivatedRoute } from "@angular/router";
import { EtapasService } from "../services/etapas.licitacion.service";
import { LicitacionService } from "../services/licitacion.service";
import { NotificationService } from "../services/notification.service";
import { FileService } from "../services/file.service";
import { jsPDF } from "jspdf";
import { Location } from "@angular/common";

declare var require: any;
const FileSaver = require("file-saver");
@Component({
  selector: "app-view-respuesta-licitacion-readonly",
  templateUrl: "./view-respuesta-licitacion-readonly.component.html",
  styleUrls: ["./view-respuesta-licitacion-readonly.component.css"],
})
export class ViewRespuestaLicitacionReadonlyComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  idParticipante: number;
  consultas: [];
  tipo_consultas: [];
  archivo: string;
  aclaracionesLicitacion: any[];
  objLicitacion: any;
  consultas_licitacion: [
    {
      CORRELATIVO: number;
      CONSULTA: string;
      isCollapsed: boolean;
      NUM_ROW: number;
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private etapaService: EtapasService,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private fileService: FileService,
    private location: Location
  ) {
    this.aclaracionesLicitacion = [];
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = +params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.licitacionService
        .getListConsultasLic(this.idLicitacion, this.idEtapa)
        .subscribe((resp) => {
          this.consultas_licitacion = JSON.parse(resp);
        });
      this.getConsultas(this.idLicitacion);
      this.getAclaracionesLicitacion();
      this.getHeaderLicitacion();
      this.etapaService
        .getArchivoLicEtapaFull(this.idLicitacion, this.idEtapa)
        .subscribe((resp) => {
          if (JSON.parse(resp)[0]) {
            this.archivo = JSON.parse(resp)[0].NOMBRE_ARCHIVO;
          }
        });
    });
  }

  private getConsultas(idLic: number) {
    this.licitacionService.getConsultasLicFull(idLic).subscribe((resp) => {
      this.consultas = JSON.parse(resp);
    });
  }

  getAclaracionesLicitacion() {
    this.licitacionService
      .getAclaracionesLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.aclaracionesLicitacion = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener tipos de aclaracion."
          );
        }
      );
  }

  getHeaderLicitacion() {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        this.objLicitacion = JSON.parse(resp)[0];
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cabecera licitación"
        );
      }
    );
  }

  onDescargar(fileName: string) {
    this.fileService.downloadFile(fileName).subscribe(
      (resp) => {
        FileSaver.saveAs(resp, fileName);
        this.notifyService.showSuccess("Descargando ...", "Descargar archivos");
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Descargar archivos");
      }
    );
  }

  printPdf() {
    const oDocPdf = new jsPDF({ format: "letter", unit: "mm" });
    let nPageYAxi = 10;
    const iMargin = 20;
    const iFontText = 10;
    const iMarginHeader = 35;
    const iMaxPageWidth = 215.9 - iMargin * 2;
    const iLineSeparation = 5;

    const addLogo = () => {
      const logo = new Image();
      logo.src = "/assets/img/logo/logo_em_pdf.png";
      oDocPdf.addImage(logo, "PNG", iMargin, 10, 68, 6.8);
    };

    const addHeader = () => {
      oDocPdf.setFontSize(iFontText);
      oDocPdf.setTextColor(0, 0, 0); // black text
      oDocPdf.setFont("Helvetica", "normal", 700);
      nPageYAxi = 25;

      const consultas_respuestas = new Image();
      consultas_respuestas.src = "/assets/img/misc/consultas_respuestas.png";
      oDocPdf.addImage(
        consultas_respuestas,
        "PNG",
        iMargin,
        nPageYAxi,
        iMaxPageWidth,
        6.8
      );

      nPageYAxi += 20;

      centeredText(`LICITACIÓN ${this.objLicitacion?.CONT_NUMERO}`, nPageYAxi);
      slitText(
        `“${this.objLicitacion?.CONT_NOMBRE}”`,
        iMaxPageWidth,
        6,
        iMargin,
        "center"
      );
      nPageYAxi += 10;
    };

    //slitText se emplea para dividir el texto de un parrafo en multiples lineas, en funcion del ancho enviado en el parametro iMaxWidth
    //el cual es el ancho maximo permitido para el texto.
    //iIncreaseY = es alto que se aumentara por cada linea
    //de ser necesario, el valor del alto de la ultima linea se guarda en la variable nPageYAxi
    //iXAxis = posicion en eje X para inicio del texto.
    const slitText = function (sText, iMaxWidth, iIncreaseY, iXAxis, align) {
      let iIncreaseY2 = 0;
      if (Array.isArray(iIncreaseY)) {
        iIncreaseY2 = iIncreaseY[1];
        iIncreaseY = iIncreaseY[0];
      } else {
        iIncreaseY2 = iIncreaseY;
      }
      const aTextLines = oDocPdf.splitTextToSize(sText, iMaxWidth);
      aTextLines.forEach((sLine, i) => {
        if (i != 0) {
          increasePageY(iIncreaseY);
        } else {
          increasePageY(iIncreaseY2);
        }
        if (!align) {
          oDocPdf.text(iXAxis, nPageYAxi, sLine);
        } else if (align === "center") {
          centeredText(sLine, nPageYAxi); //nPageYAxi posee el valor del ultimo alto ingresado
        } else if (align === "justify") {
          oDocPdf.text(sLine, iXAxis, nPageYAxi, {
            maxWidth: iMaxPageWidth - iMargin,
            align: "justify",
          });
        }
      });
    };

    //funcion se emplea para centrar en texto en funcion del ancho del la hora configurada
    const centeredText = function (sText, y) {
      const textWidth =
        (oDocPdf.getStringUnitWidth(sText) * iFontText) /
        oDocPdf.internal.scaleFactor;
      const textOffset = ((oDocPdf.internal.pageSize.width - textWidth) /
        2) as any;
      oDocPdf.text(textOffset, y, sText);
    };

    //Se emplea para aumentar el contador
    const increasePageY = function (iIncreaseValue) {
      nPageYAxi += iIncreaseValue;
      if (nPageYAxi > oDocPdf.internal.pageSize.height - iMargin) {
        addPage();
      }
    };

    const addPage = () => {
      oDocPdf.addPage("letter", "portrait");
      //al iniciar nueva pagina y agregar el header establesco el alto inicial
      nPageYAxi = iMarginHeader;
      addLogo();
      addHeader();
    };

    const addSubtitle = (sTitle, sValue) => {
      oDocPdf.setTextColor(0, 0, 0); // black text
      identText(sTitle, sValue, 31, 80, 0);
      nPageYAxi += 8;
    };

    const identText = (
      sTitle,
      sValue,
      iMaxWidth,
      iMaxWidthValue = null,
      iAddMargin = 0,
      isQuestion = false,
      align = ""
    ) => {
      if (!iMaxWidthValue) {
        if (iMaxWidth > 50) {
          iMaxWidthValue = 100;
        } else {
          iMaxWidthValue = iMaxWidth;
        }
      } else {
        const iMaxLength = 50;
        sTitle = getMaxText(sTitle, iMaxLength);
      }
      if (isQuestion) oDocPdf.setFont("Helvetica", "normal", 700);
      slitText(sTitle, iMaxWidth, 5, iMargin + iAddMargin, align);
      if (isQuestion) oDocPdf.setFont("Helvetica", "normal", 400);
      slitText(sValue, iMaxWidthValue, [5, 0], iMaxWidth, align);
    };

    const getMaxText = (sText, iMaxLength) => {
      if (sText.length > iMaxLength) {
        sText = sText.substr(0, iMaxLength) + "...";
      }
      return sText;
    };

    // PAGE INIT

    addLogo();
    addHeader();

    addSubtitle("I.", "ACLARACIONES");
    let iMarginText = iMargin;
    const iSeparationText = 11;
    iMarginText += iSeparationText;
    this.aclaracionesLicitacion.map((aclaracion) => {
      let iMarginLeft = iMarginText;
      oDocPdf.setTextColor(0, 0, 0); // black text
      slitText(
        `${aclaracion.descripcion.toUpperCase()}:`,
        iMaxPageWidth,
        iLineSeparation,
        iMarginLeft,
        false
      );
      iMarginLeft += 10;
      oDocPdf.setTextColor(0, 31, 95); //blue job
      slitText(
        `${aclaracion.texto_aclaracion}`,
        iMaxPageWidth - iMargin,
        iLineSeparation,
        iMarginLeft,
        "justify"
      );

      nPageYAxi += 8;
    });

    oDocPdf.setTextColor(0, 0, 0); // black text
    slitText("ANEXOS:", iMaxPageWidth, iLineSeparation, iMarginText, false);

    addPage();

    addSubtitle("II.", "RESPUESTAS A CONSULTAS");

    for (let index = 0; index < this.consultas.length; index++) {
      const marginText = 15;
      const anchoMargenPregunta = iMaxPageWidth - marginText - 10;
      const pregunta: any = this.consultas[index];

      oDocPdf.setTextColor(0, 0, 0); // black text
      identText(
        this.getNumber(pregunta.CORRELATIVO) + `-`,
        pregunta.CONSULTA,
        35,
        anchoMargenPregunta,
        11,
        true,
        "justify"
      );
      //identText(`${index+1}-`, pregunta.CONSULTA, 35, 80, 11, true, 'justify');
      oDocPdf.setTextColor(0, 31, 95); //blue job
      oDocPdf.setFont("Helvetica", "normal", 700);

      slitText(
        `R: ${pregunta.RESPUESTA || ""}`,
        anchoMargenPregunta,
        iLineSeparation,
        iMargin + marginText,
        "justify"
      );

      // 3
      nPageYAxi += 2;
    }

    oDocPdf.save(
      `${this.objLicitacion?.CONT_NUMERO} Consolidado de Consultas.pdf`
    );
  }

  getNumber(correlativo: number) {
    let index = this.consultas_licitacion
      ?.map(function (det) {
        return det.CORRELATIVO;
      })
      .indexOf(correlativo);

    if (index > -1) {
      return this.consultas_licitacion[index].NUM_ROW;
    } else {
      return "";
    }
  }

  back(): void {
    this.location.back();
  }
}
