import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-print-correo',
  templateUrl: './print-correo.component.html',
  styleUrls: ['./print-correo.component.css']
})
export class PrintCorreoComponent implements OnInit {

  @Input() correoBody: string;

  constructor() { }

  ngOnInit(): void {
  }

  onImprimirCorreo(): void{    
    const printComponent = document.getElementById('printComponent');
    const WindowPrt = window.open('Correo');
    WindowPrt.document.open();
    WindowPrt.document.write(`
      <html>
        <head>
          <title>Correo</title>
          <style>
            .content {
              margin-left: 0 !important;
              padding: 0 !important;
            }
            .table-main {
              padding: 0 !important;
            }
            body {
              background-color: white !important;
            }
            .panel-body {
              padding: 0 !important;
            }
          </style>
        </head>
        <body>${this.correoBody}</body>
      </html>
    `);
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(function() {
      WindowPrt.print();
      setTimeout(function() {
        WindowPrt.close();
      }, 10);
    }, 50);
  }

}
