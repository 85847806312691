<!-- begin #content -->
<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <!-- begin panel-heading -->
                <!-- <div class="panel-heading">
                    <h4 class="panel-title">Licitaciones</h4>
                </div> -->
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <div class="row">
                        <div class="col-12">
                            <h4>Mis licitaciones</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4"></div>
                        <div class="col-4 text-right">
                            <div class="input-group">
                                <input class="form-control" type="text" [(ngModel)]="filterLicitacion"
                                    name="filterLicitacion" id="filterLicitacion" placeholder="Buscar">
                                <span class="input-group-addon"><i class="fas fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <table id="data-table-default" class="table table-striped table-td-valign-middle">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nro. Contrato</th>
                                        <th>Nombre</th>
                                        <th class="text-nowrap">Etapa</th>
                                        <th class="text-nowrap">Fecha cierre</th>
                                        <th class="text-nowrap">Estado</th>
                                        <th class="text-nowrap">Oblibatoria</th>
                                        <th class="text-nowrap">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd gradeX"
                                        *ngFor="let item of licitaciones | tableFilterContratistaLicitaciones: filterLicitacion">
                                        <td>
                                            <span>{{ item.LIC_CORRELATIVO}}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.CONTRATO_CORRELATIVO}}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.CONTRATO_NOMBRE }}</span>
                                        </td>
                                        <td class="text-break">
                                            <span *ngIf="item.LICITACION_FINALIZADA != 1" class="tab tab-green">{{
                                                item.ETAPA_DESCRIPCION}}</span>
                                            <span *ngIf="item.LICITACION_FINALIZADA == 1"
                                                class="tab tab-red">Adjudicada</span>
                                        </td>
                                        <td>
                                            <span>{{ item.FECHA_TERMINO_ETAPA}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="item.LICITACION_FINALIZADA != 1">
                                                En Proceso
                                            </span>
                                            <span *ngIf="item.LICITACION_FINALIZADA == 1">
                                                Cerrada
                                            </span>
                                        </td>
                                        <td>
                                            <span class="tab tab-yellow" *ngIf="item.OBLIGATORIO === 1">
                                                Obligatoria
                                            </span>
                                        </td>
                                        <td [ngSwitch]="item.ETAPA_CORRELATIVO">
                                            <!-- Nueva botonera -->
                                            <div class="dropdown d-flex-inline  ml-2">
                                                <button class="btn tag dropdown-toggle" type="button" id="dropdownMenu2"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                    (click)="getArchivoLicitacionValidarConfirmacion(item.LIC_CORRELATIVO, item.ETAPA_CORRELATIVO)">
                                                    Opciones
                                                    <span class="caret ml-1"></span>
                                                </button>
                                                <ul *ngIf="item.LICITACION_FINALIZADA != 1" class="dropdown-menu"
                                                    aria-labelledby="dropdownMenu2">
                                                    <li *ngSwitchCase="1">
                                                        <a *ngIf="!confirmacionEnviada" style="cursor: pointer;"
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>Subir confirmación</span>
                                                        </a>
                                                        <a class="text-success"
                                                            *ngIf="confirmacionEnviada">Confirmado</a>
                                                    </li>
                                                    <li *ngSwitchCase="2">
                                                        <a *ngIf="!confirmacionEnviada" style="cursor: pointer;"
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>Subir confirmación</span>
                                                        </a>
                                                        <a class="text-success"
                                                            *ngIf="confirmacionEnviada">Confirmado</a>
                                                    </li>
                                                    <li *ngSwitchCase="3">
                                                        <a *ngIf="!confirmacionEnviada" style="cursor: pointer;"
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            <span>Subir confirmación</span>
                                                        </a>
                                                        <a class="text-success"
                                                            *ngIf="confirmacionEnviada">Confirmado</a>
                                                    </li>
                                                    <li *ngSwitchCase="11">
                                                        <a *ngIf="validateRespuestasAclaraciones(item.LIC_CORRELATIVO, item.ETAPA_CORRELATIVO)"
                                                            routerLink="/view-respuesta-economica/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            Aclaracion economica
                                                        </a>
                                                        <a class="text-success" href="javascript:void(0);"
                                                            *ngIf="!validateRespuestasAclaraciones(item.LIC_CORRELATIVO, item.ETAPA_CORRELATIVO)">
                                                            Sin aclaraciones
                                                        </a>
                                                    </li>
                                                    <li *ngSwitchCase="8">
                                                        <a *ngIf="validateRespuestasAclaraciones(item.LIC_CORRELATIVO, item.ETAPA_CORRELATIVO)"
                                                            routerLink="/view-respuesta-aclaracion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Aclaracion
                                                            tecnica
                                                        </a>
                                                        <a class="text-success" href="javascript:void(0);"
                                                            *ngIf="!validateRespuestasAclaraciones(item.LIC_CORRELATIVO, item.ETAPA_CORRELATIVO)">
                                                            Sin aclaraciones
                                                        </a>
                                                    </li>
                                                    <li *ngSwitchCase="4"><a
                                                            routerLink="/create-consulta-licitacion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Consultas</a>
                                                    </li>
                                                    <li *ngIf="item.ETAPA_CORRELATIVO > 5"><a
                                                            routerLink="/create-consulta-licitacion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Consultas</a>
                                                    </li>
                                                    <li *ngIf="item.ETAPA_CORRELATIVO == 6"><a
                                                            routerLink="/evaluacion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Oferta
                                                            Técnica</a></li>
                                                    <li *ngSwitchCase="13"><a
                                                            routerLink="/view-respuesta-negociacion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Negociación</a>
                                                    </li>
                                                    <!-- <li *ngSwitchCase="6"
                                                        routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                        <a>Subir Archivos</a>
                                                    </li> -->
                                                    <li *ngSwitchCase="7"><a
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Subir
                                                            Archivos</a></li>
                                                    <li *ngSwitchCase="9"><a
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Subir
                                                            Archivos</a></li>
                                                    <!-- <li *ngSwitchCase="10"><a
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Subir
                                                            Archivos</a></li> -->
                                                    <!-- <li *ngSwitchCase="12"><a
                                                            routerLink="/upload-etapa-archivo/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Subir
                                                            Archivos</a></li> -->
                                                    <li><a
                                                            routerLink="/view-cronograma-contratista/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Ver
                                                            Licitación</a></li>
                                                    <li><a
                                                            routerLink="/view-archivo-licitacion/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">Ver
                                                            Archivos</a></li>
                                                    <li *ngIf="item.ETAPA_CORRELATIVO == 10"><a
                                                            routerLink="/cargar-presupuesto/{{item.LIC_CORRELATIVO}}">Oferta Económica</a></li>
                                                    <li
                                                        *ngIf="(item.ETAPA_CORRELATIVO == 11 && validateCorreccionPresupuesto(item.LIC_CORRELATIVO))">
                                                        <a routerLink="/cargar-presupuesto/{{item.LIC_CORRELATIVO}}">
                                                            Oferta Económica
                                                        </a>
                                                    </li>
                                                    <li
                                                        *ngIf="(item.ETAPA_CORRELATIVO == 13 && validateCorreccionPresupuesto(item.LIC_CORRELATIVO))">
                                                        <a routerLink="/cargar-presupuesto/{{item.LIC_CORRELATIVO}}">
                                                            Oferta Económica
                                                        </a>
                                                    </li>
                                                    <li *ngIf="item.ETAPA_CORRELATIVO != 0"><a style="cursor: pointer;"
                                                            (click)="onSolicitarRenunciaLicitacion(item.LIC_CORRELATIVO, item.ETAPA_CORRELATIVO, item.ETLIC_CORRELATIVO)"
                                                            data-toggle="modal"
                                                            data-target="#modalRenunciasLicitacion">Renunciar a
                                                            licitación</a></li>
                                                </ul>
                                                <ul *ngIf="item.LICITACION_FINALIZADA == 1" class="dropdown-menu"
                                                    aria-labelledby="dropdownMenu2">
                                                    <li>
                                                        <a
                                                            routerLink="/view-cronograma-contratista/{{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}}">
                                                            Ver Licitación
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>
<!-- Modal -->
<div class="modal fade" id="modalRenunciasLicitacion" tabindex="-1" role="dialog"
    aria-labelledby="modalRenunciasLicitacion" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalRenunciasLicitacion">Solicitar renuncia a la licitación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal
                    (click)="cancelarReununciaLicitacion()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="renunciarLicitacionForm" (ngSubmit)="renunciarLicitacion()">
                    <p>Motivo de renuncia: <span class="text-danger">*</span></p>
                    <div class="form-group">
                        <select class="form-control" formControlName="motivoRenunciaCorrelativo"
                            #motivoRenunciaCorrelativo>
                            <ng-container *ngFor="let item of motivoRenunciaList">
                                <option *ngIf="item.vigente === 1" value="{{item.correlativo}}">{{item.descripcion}}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="form-group">
                        <p>Descripción: <span class="text-danger">*</span></p>
                        <textarea rows="5" class="form-control" formControlName="motivoRenuncia" name="motivoRenuncia"
                            #motivoRenuncia></textarea>
                    </div>
                    <div class="form-group">
                        <p>Descripción archivo: <span class="text-danger">*</span></p>
                        <input class="form-control" type="text" formControlName="nombreArchivo" name="nombreArchivo"
                            #nombreArchivo>
                    </div>
                    <div class="form-group row m-b-10 align-items-center">
                        <label class="col-md-3 col-form-label">Subir evidencia <span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input #inputFile style="border-color: transparent;" class="form-control" name="archivo"
                                id="archivo" formControlName="archivo" type="file" (change)="detectFiles($event)" />
                        </div>
                    </div>
                    <br>
                    <div class="text-right">
                        <a class="btn tag text-orange mr-2" (click)="cancelarReununciaLicitacion()"
                            data-dismiss="modal">Cancelar</a>
                        <button class="btn btn-primary" type="submit"
                            [disabled]="renunciarLicitacionForm.invalid">Renunciar a licitación</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>