<!-- begin #content -->
<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <ng-container *ngIf="licitacionSeleccionada === false">
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <!-- begin panel-heading -->
                    <div class="panel-heading">
                        <h4 class="panel-title">Historial cronogramas de Licitaciones</h4>
                    </div>
                    <!-- end panel-heading -->
                    <!-- begin panel-body -->
                    <div class="panel-body">
                        <div class="col-md-12">
                            <table id="data-table-default" class="table table-striped table-td-valign-middle">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nro. Contrato</th>
                                        <th>Nombre</th>
                                        <th class="text-nowrap">Etapa</th>
                                        <th class="text-nowrap">Fecha inicio etapa</th>
                                        <th class="text-nowrap">Fecha fin etapa</th>
                                        <th class="text-nowrap">Historial</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd gradeX" *ngFor="let item of licitaciones">
                                        <td>
                                            <span>{{ item.LIC_CORRELATIVO}}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.CONTRATO_CORRELATIVO}}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.CONTRATO_NOMBRE }}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.ETAPA_DESCRIPCION}}</span>
                                        </td>
                                        <td>
                                            <span>{{ item.FECHA_INICIO_ETAPA | date: 'dd/MM/yyyy'}}</span>
                                        </td>
                                        <td>
                                            <span>{{ item.FECHA_TERMINO_ETAPA | date: 'dd/MM/yyyy'}}</span>
                                        </td>
                                        <td>
                                            <!-- {{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}} -->
                                            <button style="margin-left: 10px;" type="button" class="btn tag mb-1" (click)="selectHistorialCronograma(item.LIC_CORRELATIVO, item.CONTRATO_NOMBRE)">Ver</button>
                                        </td>
                                    </tr>
    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end panel-body -->
                </div>
            </ng-container>
            <ng-container *ngIf="licitacionSeleccionada === true">
                <button type="button" class="btn btn-primary" (click)="licitacionSeleccionada = false;"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
                <br>
                <br>
                <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                    <!-- begin panel-heading -->
                    <div class="panel-heading">
                        <h4 class="panel-title">Licitacion: {{idLicitacion}} - {{nombreLicitacion}}</h4>
                    </div>
                    <!-- end panel-heading -->
                    <!-- begin panel-body -->
                    <div class="panel-body">
                        <div class="col-md-12">
                            <table id="data-table-default" class="table table-striped table-td-valign-middle">
                                <thead>
                                    <tr>
                                        <th>Version cronograma</th>
                                        <th>Usuario creacion</th>
                                        <th>Etapa cambio cronograma</th>
                                        <th>Fecha de cambio</th>
                                        <th>Cronograma</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd gradeX" *ngFor="let item of versionesCronograma">
                                        <td>
                                            <span>{{ item.version}}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.nombre_usuario_creacion}}</span>
                                        </td>
                                        <td class="text-break">
                                            <span>{{ item.descripcion_etapa_historico }}</span>
                                        </td>
                                        <td>
                                            <span>{{ item.fecha_creacion_historico | date: 'dd/MM/yyyy hh:mm'}}</span>
                                        </td>
                                        <td>
                                            <!-- {{item.LIC_CORRELATIVO}}/{{item.ETAPA_CORRELATIVO}}/{{item.ETLIC_CORRELATIVO}} -->
                                            <button style="margin-left: 10px;" type="button" class="btn tag mb-1" data-toggle="modal" data-target="#modalHistorialCronograma" (click)="selectVercionCronograma(item.version)">Ver</button>
                                        </td>
                                    </tr>
    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end panel-body -->
                </div>
            </ng-container>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>
<!-- Modal -->
<div class="modal fade" id="modalHistorialCronograma" tabindex="-1" role="dialog" aria-labelledby="modalHistorialCronograma" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalHistorialCronograma">Version: {{versionSeleccionada}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="versionCronograma?.length > 0">
                <div class="col-md-12">
                    <table id="data-table-default" class="table table-striped table-td-valign-middle">
                        <thead>
                            <tr>
                                <th>Etapa</th>
                                <th>Usuario creacion</th>
                                <th>Fecha de inicio</th>
                                <th>Fecha de termino</th>
                                <th>Activa</th>
                                <th>Obligatoria</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of versionCronograma">
                                <td>
                                    <span>{{ item.descripcion}}</span>
                                </td>
                                <td class="text-break">
                                    <span>{{ item.nombre_usuario_creacion}}</span>
                                </td>
                                <td class="text-break">
                                    <span>{{ item.fecha_inicio_etapa | date: 'dd/MM/yyyy' }}</span>
                                </td>
                                <td>
                                    <span>{{ item.fecha_termino_etapa | date: 'dd/MM/yyyy hh:mm'}}</span>
                                </td>
                                <td>
                                    <span *ngIf="item.activo === 'N'" class="tab tab-red">
                                        Inactiva
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="item.obligatorio === 1" class="tab tab-yellow">
                                        Obligatoria
                                    </span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </ng-container>
            <ng-container *ngIf="versionCronograma?.length <= 0">
                <div class="alert alert-danger" role="alert">
                    No se encontraron registros
                </div>
            </ng-container>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
</div>