import { Component, OnInit, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { LicitacionService } from 'src/app/shared/services/licitacion.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-correos-licitacion',
  templateUrl: './correos-licitacion.component.html',
  styleUrls: ['./correos-licitacion.component.css'],
  styles: ['.table-demo{ border: none !important; }'],
  template: '<div [innerHTML]="correoBody"></div>',
  encapsulation: ViewEncapsulation.None
})
export class CorreosLicitacionComponent implements OnInit {

  @Input() idLicitacion: number;
  correosLicitaciones: any[];
  modalRef: BsModalRef;
  correoBody: string;
  filterCorreo: string;

  constructor(
    private licitacionService : LicitacionService,
    private notifyService : NotificationService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    if (this.idLicitacion) {
      this.licitacionService.getCorreosLicitacion(this.idLicitacion).subscribe(resp =>{
        this.correosLicitaciones = JSON.parse(resp);
      }, err =>{
        this.notifyService.showError(err.error.message, 'Obtener correos de la licitacion.');
      });
    } else {
      this.correosLicitaciones = [];
      this.notifyService.showError('No existen correos asociados', 'Obtener correos de la licitacion.');
    }
  }

  onVisualizarCorreo(template: TemplateRef<any>, correoBody: string){
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.correoBody = correoBody;
  }

}
