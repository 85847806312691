<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
        routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76=""
            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Negociación licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div *ngIf="objLicitacion.TIENE_NEGOCIACION === 1" class="row">
        <!-- begin col-6 -->
        <div class="col-md-6 col-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Enviar negociación </h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="negociacionForm" novalidate (ngSubmit)="onGuardarAclaracion()">
                        <div class="form-group row m-b-15">
                            <!-- begin col-6 -->
                            <div class="col-xl-12">
                                <div class="form-group row m-b-15">
                                    <div class="col col-md-3">
                                        Participante
                                    </div>
                                    <div class="col col-md-9">
                                        <select class="form-control" formControlName="participante">
                                            <option value="0">Seleccione...</option>
                                            <ng-container *ngFor="let item of participantes">
                                                <option *ngIf="item.VIGENTE == 'S'" value="{{item.PART_CORRELATIVO}}">{{
                                                    item.PART_NOMBRE}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row m-b-15">
                                    <div class="col col-md-3">
                                        Descripción de la negociación
                                    </div>
                                    <div class="col col-md-9">
                                        <textarea class="form-control " rows="2"
                                            formControlName="desc_aclaracion"></textarea>
                                    </div>
                                </div>
                                <div class="form-group row m-b-15">
                                    <div class="col col-md-3">
                                        Descripción Archivo
                                    </div>
                                    <div class="col col-md-9">
                                        <input class="form-control" type="text" placeholder="" id="nombreArchivo"
                                            formControlName="nombreArchivo">
                                    </div>
                                </div>
                                <div class="form-group row m-b-15">
                                    <div class="col col-md-3">
                                        Seleccionar archivo
                                    </div>
                                    <div class="col col-md-9">
                                        <input #inputFile class="form-control" style="border-color: transparent;"
                                            name="archivo" type="file" (change)="detectFiles($event)" />
                                    </div>
                                </div>
                            </div>
                            <!-- end col-6 -->
                            <div class="col col-md-12" style="margin-top: 10px; text-align: center;">
                                <button class="btn btn-success" type="submit" [disabled]="isDisable">Enviar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Seleccionar participantes para la corrección de Oferta Económica</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <!-- habilitar en caso de que se requiera la correccion del presupuesto -->
                    <div class="row">
                        <div class="col-12 text-right">
                            <button class="btn btn-primary"
                                routerLink="/resultados-economicos/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}">Ver
                                Ofertas Económicas</button>
                        </div>
                    </div>
                    <br>
                    <table id="data-table-default" class="table table-striped table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">
                                    Participante
                                </th>
                                <th class="text-nowrap">
                                    Estado
                                </th>
                                <th class="text-nowrap text-right">
                                    Permitir corrección
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of participantes">
                                <ng-container *ngIf="item.VIGENTE == 'S'">
                                    <td class="text-break">
                                        {{ item.PART_NOMBRE }}
                                    </td>
                                    <td class="text-break">
                                        <span *ngIf="item.CORREGIR_PRESUPUESTO === 1" class="tab tab-yellow">En
                                            proceso</span>
                                        <span *ngIf="item.CORREGIR_PRESUPUESTO === 2"
                                            class="tab tab-green">Corregido</span>
                                    </td>
                                    <td class="text-break text-right">
                                        <ng-container *ngIf="item.CORREGIR_PRESUPUESTO !== 1">
                                            <!-- ver la forma de ver solamente el presupuesto del participante seleccionado -->
                                            <!-- Mejor colocar el click en un modal para que sea tipo wirning... -->
                                            <a class="btn rounded-icon mr-2" title="Permitir corrección"
                                                (click)="selectUserPatarticipante(item.PART_CORRELATIVO, item.PART_NOMBRE)"
                                                data-toggle="modal" data-target="#mdoalCorregirPresupuesto">
                                                <i class="fas fa-check"></i>
                                            </a>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div class="col-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Negociaciones enviadas</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Negociación</th>
                                <th class="text-nowrap">Enviada a </th>
                                <th class="text-nowrap">Fecha creación</th>
                                <th class="text-nowrap">Archivo</th>
                                <th class="text-nowrap">Descargar archivos</th>
                                <th class="text-nowrap">Respuesta</th>
                                <th class="text-nowrap">Descargar archivos</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of negociacion">
                                <td class="text-break">{{item.NEGOCIACION}}</td>
                                <td class="text-break">{{item.PART_NOMBRE}}</td>
                                <td>{{ item.FECHA_CREACION}}</td>
                                <td>{{ item.DESCRIPCION}}</td>
                                <td class="text-center">
                                    <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_ACL"
                                        [nombreArchivo]="item.NOMBRE_ARCHIVO_ACL"
                                        style="margin-right: 10px;"></app-view-file-buttons>
                                </td>
                                <td>{{ item.NOMBRE_ARCHIVO_RESP}}</td>
                                <td class="text-center">
                                    <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_RESP"
                                        [nombreArchivo]="item.NOMBRE_ARCHIVO_RESP"></app-view-file-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="objLicitacion.TIENE_NEGOCIACION === 0">
        <ng-container *ngIf="objLicitacion.ETAPA_CORRELATIVO == 13">
            <div class="row">
                <div class="col-12 mt-5">
                    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                        <div class="panel-heading">
                            <h4 class="panel-title">Validar Negociaciones</h4>
                            <div class="panel-heading-btn">
                                <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                                    data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                            </div>
                        </div>
                        <div class="panel-body text-center">
                            <h4 class="text-muted">Se debe validar si la licitación tendrá Negociaciones por parte del
                                responsable del proceso.</h4>
                            <ng-container *ngIf="validateRoleParticipanteComision(37)">
                                <br>
                                <button class="btn btn-primary mr-2" data-toggle="modal"
                                    data-target="#rechazarNegociacionModal">Rechazar</button>
                                <button class="btn btn-primary" data-toggle="modal"
                                    data-target="#aceptarNegociacionModal">Aceptar y validar</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="objLicitacion.ETAPA_CORRELATIVO != 13">
            <div class="row">
                <div class="col-12 mt-5">
                    <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                        <div class="panel-heading">
                            <h4 class="panel-title">Negociaciones</h4>
                            <div class="panel-heading-btn">
                                <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                                    data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                            </div>
                        </div>
                        <div class="panel-body text-center">
                            <h4 class="text-muted">No fueron necesarias las Negociaciones para esta licitación.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <chat [idLicitacion]="idLicitacion"></chat>
</div>
<!-- end #content -->
<!-- Modal -->
<div class="modal fade" id="mdoalCorregirPresupuesto" tabindex="-1" role="dialog"
    aria-labelledby="mdoalCorregirPresupuesto" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="mdoalCorregirPresupuestoLabel">Corregir presupuesto</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea permitir corregir el presupuesto del participante <span
                        style="font-weight: bold;">{{nombreParticipanteStr}}</span>?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="permitirCorreccionPresupuesto()">Permitir</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal definir si habra aclaraciones o no-->
<div class="modal fade" id="aceptarNegociacionModal" tabindex="-1" role="dialog"
    aria-labelledby="aceptarAclaracionesTecLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="aceptarAclaracionesTecLabel">Negociaciones</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Desea aceptar la etapa de Negociaciones?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="aceptarNegociacion()">Aceptar Etapa</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal definir si habra aclaraciones o no (caso de rechazo)-->
<div class="modal fade" id="rechazarNegociacionModal" tabindex="-1" role="dialog"
    aria-labelledby="rechazarAclaracionesTecLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="rechazarAclaracionesTecLabel">Negociaciones</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="text-muted">IMPORTANTE: Una vez rechazada esta estapa se avanzara a la siguiente y no se podra
                    volver atras.</p>
                <p>¿Desea rechazar la etapa de Negociaciones?</p>
                <p>Ingrese sus observaciones:</p>
                <textarea [(ngModel)]="observacionAvanceEtapa" class="form-control" rows="5"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="onAvanzaEtapa()">Rechazar y
                    avanzar</button>
            </div>
        </div>
    </div>
</div>