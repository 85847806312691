<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">

                    <div class="row">
                        <div class="col-md-12" *ngIf="empresaSelected == false">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Empresas</h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <a href="#modal-empresa" data-toggle="modal" class="btn btn-primary" (click)="resetFormEmpresa()">Agregar</a>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterEmpresas($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                    </div>
                            </div>
                            
                            <table class="table table-hover">
                                <thead class="thead-darkk">
                                    <th>Razon Social</th>
                                    <th>Rut</th>
                                    <th>Email</th>
                                    <th>Estado</th>
                                    <th class="text-right">Acciones</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of empresasFilter">
                                        <ng-container>
                                            <td>
                                                {{ item.RAZON_SOCIAL }}
                                            </td>
                                            <td>
                                                {{ item.RUT }}-{{ item.DIGITO_RUT }}
                                            </td>
                                            <td>
                                                {{ item.EMAIL }}
                                            </td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente === 'S'">Activo</span>
                                                <span class="tab tab-red" *ngIf="item.vigente === 'N'">Inactivo</span>
                                            </td>
                                            <td class="text-right">
                                                <div title="Ver" class="btn rounded-icon mr-2"
                                                (click)="desplegarParticipantes(item.PART_CORRELATIVO, item.RAZON_SOCIAL); empresaSelected = true"
                                                >
                                                    <i class="fas fa-user-cog"></i>
                                                </div>
                                                <a href="#modal-eliminar" data-toggle="modal" (click)="eliminarEmpresa(item.PART_CORRELATIVO)" 
                                                class="btn rounded-icon f-right mr-2">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>
                                                <a href="#modal-empresa" data-toggle="modal" (click)="onEditarEmpresa(item.PART_CORRELATIVO)" 
                                                class="btn rounded-icon f-right mr-2">
                                                    <i class="fa fa-edit"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12" *ngIf="empresaSelected">
                            <ng-container *ngIf="empresaSeleccionada.length > 0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h5>Responsable de {{ empresaSeleccionada }}</h5>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <button class="btn tag text-bolder text-muted text-primary mr-2" (click)="empresaSelected = false">Regresar</button>
                                        <a href="#modal-participante" data-toggle="modal" class="btn btn-primary" (click)="resetFormParticipante()">Agregar</a>
                                    </div>
                                </div>
                                <br>
                            </ng-container>

                            <div class="row">
                                <div class="col-12">
                                    <button 
                                    (click)="filtroUsuario = 32"
                                    [ngClass]="{'text-primary': filtroUsuario == 32}" class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;">Responsable</button>
                                    <!-- <button 
                                    (click)="filtroUsuario = 33"
                                    [ngClass]="{'text-primary': filtroUsuario == 33}" class="btn tag mb-1 text-bolder text-muted" style="margin-right: 5px;">Notificación</button> -->
                                </div>
                            </div>
                            <div class="row" *ngIf="filtroUsuario == 32">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Nombre</th>
                                        <th>Rut</th>
                                        <th>Email</th>
                                        <th>Estado</th>
                                        <th class="text-right">Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of participantesEmpresa">
                                            <td>{{ item.NOMBRE }}</td>
                                            <td>{{ item.RUT }}-{{ item.DIGRUT }}</td>
                                            <td>{{ item.EMAIL }}</td>
                                            <td>
                                                <span class="tab tab-green" *ngIf="item.vigente === 'S'">Activo</span>
                                                <span class="tab tab-red" *ngIf="item.vigente === 'N'">Inactivo</span>
                                            </td>
                                            <td class="text-right">
                                                <a 
                                                    href="#modal-eliminarPerticipante" 
                                                    data-toggle="modal" 
                                                    (click)="eliminarParticipante(item.PART_CORRELATIVO)" 
                                                    class="btn rounded-icon f-right mr-2" 
                                                >
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>
                                                <a 
                                                    href="#modal-participante" 
                                                    data-toggle="modal" 
                                                    (click)="onEditarParticipante(item.PART_CORRELATIVO)" 
                                                    class="btn rounded-icon f-right mr-2"
                                                >
                                                    <i class="fa fa-edit"></i>
                                                </a>
                                                <ng-container *ngIf="item.vigente === 'N'">
                                                    <a  
                                                        (click)="onActivarParticipante(item.PART_CORRELATIVO, item.correlativo_participante)" 
                                                        class="btn rounded-icon f-right mr-2" 
                                                    >
                                                        <i class="fa fa-check"></i>
                                                    </a>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row" *ngIf="filtroUsuario == 33">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Nombre</th>
                                        <th>Rut</th>
                                        <th>Email</th>
                                        <!-- <th>Password</th> -->
                                        <th class="text-right">Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of participantesEmpresaNotificacion">
                                            <td>{{ item.NOMBRE }}</td>
                                            <td>{{ item.RUT }}-{{ item.DIGRUT }}</td>
                                            <td>{{ item.EMAIL }}</td>
                                            <!-- <td>{{ item.PASSWORD }}</td> -->
                                            <td class="text-right">
                                                <a href="#modal-eliminarPerticipante" data-toggle="modal" (click)="eliminarParticipante(item.PART_CORRELATIVO)" 
                                                class="btn rounded-icon f-right mr-2">
                                                    <i class="fa fa-trash-alt"></i>
                                                </a>

                                                <a href="#modal-participante" data-toggle="modal" (click)="onEditarParticipante(item.PART_CORRELATIVO)" 
                                                class="btn rounded-icon f-right mr-2">
                                                    <i class="fa fa-edit"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<!-- Modal de empresa -->
<div class="modal fade" id="modal-empresa">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" *ngIf="!editarEmpresa">Agregar Empresa</h4>
                <h4 class="modal-title" *ngIf="editarEmpresa">Editar Empresa</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formEmpresa" (ngSubmit)="onAgregarEmpresa()" novalidate>
                    <div class="row form-group">
                        <label for="nombre" class="col-md-3 col-form-label">Nombre</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="nombre" #nombre [ngClass]="{ 'is-invalid': submitted && fe.nombre.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="rut" class="col-md-3 col-form-label">Rut</label>
                        <div class="col-md-7">
                            <input type="number" class="form-control" formControlName="rut" #rut [ngClass]="{ 'is-invalid': submitted && fe.rut.errors}">
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" formControlName="digito" #digito [ngClass]="{ 'is-invalid': submitted && fe.digito.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="email" class="col-md-3 col-form-label">Email</label>
                        <div class="col-md-9">
                            <input type="email" class="form-control" formControlName="email" #email [ngClass]="{ 'is-invalid': submitted && fe.email.errors}">
                        </div>
                    </div>
                    <div *ngIf="editarEmpresa" class="row form-group">
                        <label for="email" class="col-md-3 col-form-label">Vigente</label>
                        <div class="col-md-9">
                            <select formControlName="vigente" class="form-control" #vigente>
                                <option value="S">Si</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2" type="submit" *ngIf="!editarEmpresa">Agregar</button>
                            <button class="btn btn-primary mr-2" type="submit" *ngIf="editarEmpresa">Guardar</button>
                            <a href="javascript:;" #closeModal class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de participante -->
<div class="modal fade" id="modal-participante">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" *ngIf="!editarParticipante">Agregar Responsable</h4>
                <h4 class="modal-title" *ngIf="editarParticipante">Editar Responsable</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formParticipante" (ngSubmit)="onAgregarParticipante()" novalidate>
                    <div class="row form-group">
                        <label for="nombres" class="col-md-3 col-form-label">Nombres</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="nombres" #nombres [ngClass]="{ 'is-invalid': submittedParticipante && fp.nombres.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="apellido_paterno" class="col-md-3 col-form-label">Apellido Paterno</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="apellido_paterno" #apellido_paterno [ngClass]="{ 'is-invalid': submittedParticipante && fp.apellido_paterno.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="apellido_materno" class="col-md-3 col-form-label">Apellido Materno</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="apellido_materno" #apellido_materno [ngClass]="{ 'is-invalid': submittedParticipante && fp.apellido_materno.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="rut" class="col-md-3 col-form-label">Rut</label>
                        <div class="col-7">
                            <input type="number" class="form-control" formControlName="rut" #rut [ngClass]="{ 'is-invalid': submittedParticipante && fp.rut.errors}">
                        </div>
                        <div class="col-2">
                            <input type="text" class="form-control" formControlName="digito" #digito [ngClass]="{ 'is-invalid': submittedParticipante && fp.digito.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="email" class="col-md-3 col-form-label">Email</label>
                        <div class="col-md-9">
                            <input type="email" class="form-control" formControlName="email" #email [ngClass]="{ 'is-invalid': submittedParticipante && fp.email.errors}">
                        </div>
                    </div>
                    <div *ngIf="isVisiblePassword" class="row form-group">
                        <label for="password" class="col-md-3 col-form-label">Password</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="password" #password [ngClass]="{ 'is-invalid': submittedParticipante && fp.password.errors}">
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="tipo_participante" class="col-md-3 col-form-label">Tipo de Participante</label>
                        <div class="col-md-9">
                            <select formControlName="tipo_participante" name="tipo_participante" #tipo_participante class="form-control" (change)="onTipoParticipanteChange($event)">
                                <option value="32" selected>Flujo</option>
                                <!-- <option value="33">Notificación</option> -->
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button *ngIf="!editarParticipante" class="btn btn-primary mr-2" type="submit">
                                Agregar
                            </button>
                            <button *ngIf="editarParticipante" class="btn btn-primary mr-2" type="submit">
                                Guardar
                            </button>
                            <a href="javascript:;" #closeModalParticipante class="btn btn-light" data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de eliminar empresa -->
<div class="modal fade" id="modal-eliminar">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Eliminar Empresa</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <h5 class="text-center">¿Deseas eliminar la empresa seleccionada?</h5>
                <br><br>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-primary mr-2" type="submit" (click)="onEliminarEmpresa()">Confirmar</button>
                        <a href="javascript:;" #closeModalEliminar class="btn btn-light" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de eliminar participantes -->
<div class="modal fade" id="modal-eliminarPerticipante">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Eliminar Participante</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <h5 class="text-center">¿Deseas eliminar el participante seleccionado?</h5>
                <br><br>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-primary mr-2" type="submit" (click)="onEliminarParticipante()">Confirmar</button>
                        <a href="javascript:;" #closeModalEliminarParticipante class="btn btn-light" data-dismiss="modal">Cerrar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>