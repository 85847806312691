import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { NotificationService } from "src/app/shared/services/notification.service";
import {
  ItemizadoService,
  IItemizado,
  IItemizadoDetalle,
} from "src/app/shared/services/itemizado.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-create-itemizado",
  templateUrl: "./create-itemizado.component.html",
  styleUrls: ["./create-itemizado.component.css"],
  providers: [ItemizadoService, LicitacionService],
})
export class CreateItemizadoComponent implements OnInit {
  objItemizado: IItemizado[];
  modalItemizado: boolean = false;
  modalItemizadoDetelle: boolean = false;
  formItemizado: FormGroup;
  formItemizadoDetalle: FormGroup;
  idLicitacion: number = 0;
  objLicitacion: any;
  isUpdateItemizado: boolean = false;

  fileName: string;
  arrayBuffer: any;
  file: File;
  data: any[] = [];

  idEtapa: number = 0;
  idEtapaLic: number = 0;
  idOrigen: number = 0;
  isChildren: boolean = false;
  @Input() idLicitacionParent;
  @Input() actaInicio: boolean;
  @Output() isUpdate: EventEmitter<IItemizado[]> = new EventEmitter<
    IItemizado[]
  >();
  rolesParticipanteComision: any[];

  constructor(
    private objItemizadoService: ItemizadoService,
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder
  ) {
    this.rolesParticipanteComision = [];

    this.formItemizado = new FormGroup({
      descripcion: new FormControl(null),
      licitacion_correlativo: new FormControl(null),
      pos: new FormControl(null),
    });
    this.formItemizadoDetalle = new FormGroup({
      item: new FormControl(null),
      unidad: new FormControl(null),
      pos: new FormControl(null),
      cantidad: new FormControl(null),
      precio: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.isChildren = this.idLicitacionParent !== undefined;

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = !this.isChildren
        ? params["idLicitacion"]
        : this.idLicitacionParent;
      this.idEtapa = !this.isChildren ? params["idEtapa"] : 0;
      this.idEtapaLic = !this.isChildren ? params["idEtapaLic"] : 0;
      this.idOrigen = !this.isChildren ? params["idOrigen"] : 0;
      /* this.verificarLic(this.idLicitacion); */
      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
          this.getItemizado();
          this.getSelectRolesParticipanteComision();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
    });
  }
  /* cleanItemizadoForm()
  {
    this.formItemizado.controls['descripcion'].setValue('');
    //this.formGroup.controls['correlativo'].setValue('');
    //this.formGroup.controls['licitacion_correlativo'].setValue('');
    this.formItemizado.controls['peso'].setValue('');

    this.formItemizado = this.formBuilder.group({
      descripcion: ['', Validators.required],
      peso: ['', Validators.required],
    });
  } */
  getItemizado() {
    this.objItemizadoService.getItemizado(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;

        if (response.data?.length > 0) {
          this.objItemizado = response.data;
          this.isUpdate.emit(this.objItemizado);
        }
        /* else
      {
        this.setDefault();
      } */

        //this.calcularPorcentajesFormularios();
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }
  cargarExcel() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let sheetData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.data = sheetData;
    };
    fileReader.readAsArrayBuffer(this.file);
  }
  incomingfile(event) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
  }

  save() {
    // valdiacion de monto total del itemizado contra el monto de la licitación
    if (this.sumTotal() > this.objLicitacion?.MONTO_LICITACION) {
      this.notifyService.showWarning(
        "El total del itemizado no puede ser mayor al monto de la licitación",
        "Advertencia"
      );
      return;
    }

    if (this.data.length <= 0) {
      this.notifyService.showWarning(
        "Debe cargar un archivo excel",
        "Advertencia"
      );
      this.isUpdate.emit(this.objItemizado);
      return;
    } else {
      this.objItemizado = [];
      let itemizado: IItemizado;
      let primero = true;
      for (let i = 0; i < this.data.length; i++) {
        if (primero) {
          itemizado = {
            correlativo: 0,
            descripcion: this.data[i].SPOS,
            licitacion_correlativo: this.idLicitacion,
            pos: this.data[i].POS,
            itemizadoDetalleLs: [],
          };
        }

        if (Number.isInteger(this.data[i].POS) && !primero) {
          this.objItemizado.push(itemizado);
          itemizado = {
            correlativo: 0,
            descripcion: this.data[i].SPOS,
            licitacion_correlativo: this.idLicitacion,
            pos: this.data[i].POS,
            itemizadoDetalleLs: [],
          };
        }

        if (this.data[i].PRECIO != undefined && !primero) {
          itemizado.itemizadoDetalleLs.push({
            correlativo: 0,
            item: this.data[i].ITEM,
            unidad: this.data[i].UNIDAD,
            pos: this.data[i].SPOS,
            cantidad: this.data[i].CANTIDAD,
            precio: this.data[i].PRECIO,
            presupuesto: this.data[i].PRECIO,
            itemizado_correlativo: 0,
            precio_unitario: 0,
          });
        }
        primero = false;
      }
      this.objItemizado.push(itemizado);
      //console.log(this.objItemizado);
      this.objItemizadoService.postItemizado(this.objItemizado).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data?.length > 0) {
            this.objItemizado = response.data;
            this.isUpdate.emit(this.objItemizado);
            this.data = [];
            this.fileName = "";
            this.file = null;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    }
  }

  sumTotal(): number {
    if (this.data) {
      let sumatoria = 0;
      for (let i = 0; i < this.data?.length; i++) {
        if (this.data[i].PRECIO) {
          sumatoria += this.data[i].PRECIO * this.data[i].CANTIDAD;
        }
      }
      return sumatoria;
    } else {
      return 0;
    }
  }

  sumTotal2() {
    if (this.objItemizado) {
      let sumatoria = 0;
      for (let i = 0; i < this.objItemizado?.length; i++) {
        for (
          let j = 0;
          j < this.objItemizado[i].itemizadoDetalleLs?.length;
          j++
        ) {
          if (this.objItemizado[i].itemizadoDetalleLs[j].precio) {
            sumatoria +=
              this.objItemizado[i].itemizadoDetalleLs[j].precio *
              this.objItemizado[i].itemizadoDetalleLs[j].cantidad;
          }
        }
      }
      return sumatoria;
    } else {
      return 0;
    }
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }
}
