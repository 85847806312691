<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" (click)="back()"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h3 class="">{{licitacionData?.CONT_NOMBRE}}</h3>
                        </div>
                        <div class="col-12">
                            <span class="text-muted">
                                {{licitacionData?.DESCRIPCION}}
                            </span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="mr-3 ml-3">
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Licitación: </span> <strong>{{idLicitacion}}</strong></span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Solpe: </span> {{licitacionData?.SOLPE_CORRELATIVO}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Monto: </span> {{licitacionData?.MONTO_SOLPE | number: '1.2'}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Moneda: </span> {{licitacionData?.DESCRIPCION_MONEDA_SOLPE}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Contrato: </span> {{licitacionData?.CONT_NUMERO}}</span>
                            <span class="d-block d-md-inline align-center mr-5 mt-3"><span class="text-muted">Fecha: </span> {{licitacionData?.FECHA_CREACION}}</span>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <app-table-participantes [idLicitacion]="idLicitacion" [idEtapaLic]="idEtapaLic"
                                (data)="setParticipantes($event)"></app-table-participantes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>