<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary" routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Negociación licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Negociaciones enviadas</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <table id="data-table-default" class="table table-striped table-bordered table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Negociación</th>
                                <th class="text-nowrap">Enviada a </th>
                                <th class="text-nowrap">Fecha creación</th>
                                <th class="text-nowrap">Negociación</th>
                                <th class="text-nowrap">Respuesta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of negociacion">
                                <td class="text-break">{{item.NEGOCIACION}}</td>
                                <td class="text-break">{{item.PART_NOMBRE}}</td>
                                <td class="text-break">{{ item.FECHA_CREACION}}</td>
                                <td>
                                  <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_ACL" [nombreArchivo]="item.NOMBRE_ARCHIVO_ACL" style="margin-right: 10px;"></app-view-file-buttons>
                                </td>
                                <td>
                                    <app-view-file-buttons *ngIf="item.NOMBRE_ARCHIVO_RESP" [nombreArchivo]="item.NOMBRE_ARCHIVO_RESP"></app-view-file-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end #content -->