<!-- begin #content -->
<div id="content" class="content">
    <button style="margin-right: 10px; margin-bottom: 15px;" type="button" class="btn btn-primary"
        routerLink="/view-cronograma/{{idLicitacion}}/{{idEtapa}}/{{idEtapaLic}}"><i _ngcontent-ftv-c76=""
            class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left" style="color: white;"></i> Volver</button>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Licitación</a></li>
        <li class="breadcrumb-item active">Archivos etapa licitación</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Licitación #{{idLicitacion}} <small>en {{nombreEtapa}}</small></h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-12 col-md-8">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-heading">
                    <h4 class="panel-title">Archivos de {{nombreEtapa}}</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning"
                            data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <div class="panel-body">
                    <!-- <div class="alert alert-warning fade show">Recuerde eliminar de la Licitación a quienes no hayan enviado el archivo, En caso de no hacerlo el participante avanzara a la siguiente etapa.</div> -->
                    <table id="data-table-default" class="table table-striped table-td-valign-middle">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Estado</th>
                                <th class="text-nowrap">Participante</th>
                                <th class="text-nowrap">Etapa</th>
                                <th class="text-nowrap">Fecha creación</th>
                                <th class="text-nowrap">Nombre archivo</th>
                                <th class="text-nowrap"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd gradeX" *ngFor="let item of etapaArchivo">
                                <td>
                                    <!-- <ng-container *ngIf="item.VIGENTE == 'S'">
                                        <a class="btn rounded-icon" href="#modalEliminarParticipante" data-toggle="modal"
                                        (click)="removeParticipante(item.PART_CORRELATIVO)" title="Eliminar">
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </ng-container> -->
                                    <ng-container *ngIf="item.VIGENTE == 'N'">
                                        <span [ngClass]="{'text-danger': item.VIGENTE == 'N'}">Eliminado</span>
                                    </ng-container>
                                </td>
                                <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}" class="text-break">
                                    {{item.PART_NOMBRE}}</td>
                                <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}" class="text-break">{{nombreEtapa}}
                                </td>
                                <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}">{{ item.FECHA_CREACION}}</td>
                                <td [ngClass]="{'text-danger': item.VIGENTE == 'N'}" class="tdCustom">
                                    {{item.DESCRIPCION}}
                                </td>
                                <td class="text-center">
                                    <app-view-file-buttons
                                        [nombreArchivo]="item.NOMBRE_ARCHIVO"></app-view-file-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <chat [idLicitacion]="idLicitacion"></chat>
</div>
<!-- end #content -->
<!-- Modal Eliminar participante -->
<div class="modal fade" id="modalEliminarParticipante" tabindex="-1" role="dialog"
    aria-labelledby="modalEliminarParticipante" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalEliminarParticipante">Eliminar Participante</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>¿Está seguro que desea eliminar el participante?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="eliminarParticipante()"
                    data-dismiss="modal">Guardar cambios</button>
            </div>
        </div>
    </div>
</div>