
<a id="btn-modal-participante" class="d-none" href="#modal-participante" data-toggle="modal" #openModal></a>

<!-- Modal de participante -->
<div class="modal fade" id="modal-participante">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="!editarParticipante">Agregar Oferentes</h4>
        <h4 class="modal-title" *ngIf="editarParticipante">Editar Oferentes</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formParticipante" (ngSubmit)="onGuardarParticipante()" novalidate>
          <p class="text-muted">IMPORTANTE: Solo podrá visualizar las empresas que tengan responsables asignados.</p>
          <br>
          <div class="row form-group" *ngIf="empresaSeleccionada == 0">
            <label for="part_correlativo" class="col-md-3 col-form-label">Empresa</label>
            <div class="col-md-9">
              <select formControlName="part_correlativo" name="part_correlativo" #part_correlativo
                class="form-control" [attr.disabled]="participanteSeleccionado !== 0 ? '' : null">
                <option *ngFor="let item of empresas" value="{{item.PART_CORRELATIVO}}">{{ item.RAZON_SOCIAL}}</option>
              </select>
            </div>
          </div>
          <!-- <div class="row form-group">
            <label for="nombres" class="col-md-3 col-form-label">Nombres</label>
            <div class="col-md-9">
              <input type="text" class="form-control" formControlName="nombres" #nombres
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.nombres.errors}">
            </div>
          </div>
          <div class="row form-group">
            <label for="apellido_paterno" class="col-md-3 col-form-label">Apellido Paterno</label>
            <div class="col-md-9">
              <input type="text" class="form-control" formControlName="apellido_paterno" #apellido_paterno
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.apellido_paterno.errors}">
            </div>
          </div>
          <div class="row form-group">
            <label for="apellido_materno" class="col-md-3 col-form-label">Apellido Materno</label>
            <div class="col-md-9">
              <input type="text" class="form-control" formControlName="apellido_materno" #apellido_materno
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.apellido_materno.errors}">
            </div>
          </div>
          <div class="row form-group">
            <label for="rut" class="col-md-3 col-form-label">Rut</label>
            <div class="col-md-7">
              <input type="number" class="form-control" formControlName="rut" #rut
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.rut.errors}">
            </div>
            <div class="col-md-2">
              <input type="text" class="form-control" formControlName="digito" #digito
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.digito.errors}">
            </div>
          </div>
          <div class="row form-group">
            <label for="email" class="col-md-3 col-form-label">Email</label>
            <div class="col-md-9">
              <input type="email" class="form-control" formControlName="email" #email
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.email.errors}">
            </div>
          </div>
          <div class="row form-group">
            <label for="password" class="col-md-3 col-form-label">Password</label>
            <div class="col-md-9">
              <input type="text" class="form-control" formControlName="password" #password
                [ngClass]="{ 'is-invalid': submittedParticipante && fp.password.errors}">
            </div>
          </div>
          <div class="row form-group">
            <label for="tipo_participante" class="col-md-3 col-form-label">Tipo de Participante</label>
            <div class="col-md-9">
              <select formControlName="tipo_participante" name="tipo_participante" #tipo_participante
                class="form-control">
                <option value="32" selected>Flujo</option>
                <option value="33">Notificación</option>
              </select>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12 text-right">
              <button *ngIf="!editarParticipante" class="btn btn-primary mr-2" type="submit">
                Agregar
              </button>
              <button *ngIf="editarParticipante" class="btn btn-primary mr-2" type="submit">
                Guardar
              </button>
              <a href="javascript:;" #closeModal class="btn tag text-orange"
              data-dismiss="modal">Cerrar</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>