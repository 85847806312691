<div>
    <!-- <div class="row">
        <div class="col">
            <h3>Aclaraciones:</h3>
        </div>
    </div> -->
    <div class="row">
        <div class="col-12 col-md-8 border-right">
            <!-- respuestas ya realizadas -->
            <div *ngIf="aclaracionesLicitacion.length > 0">
                <div class="pr-0 pr-sm-0 pr-md-2 no-border-sm" *ngFor="let item of aclaracionesLicitacion; let i = index">
                    <div class="p-20 m-b-20 card">
                        <div class="pregunta mb-20">
                            <div class="row">
                                <div class="text-break col-6 text-muted">
                                </div>
                                <div class="col-6 text-right">
                                    <span class=" text-muted">
                                        {{item.fecha | date: 'dd/MM/yyyy'}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h5 class="mb-1 text-uppercase">{{item.descripcion}}</h5>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="respuesta ml-4 mb-4">
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <div class="card-body">
                                        <p>{{item.texto_aclaracion}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- seccion para responder a aclaraciones -->
            <div class="pr-0 pr-sm-0 pr-md-2 no-border-sm">
                <div class="p-20 m-b-20 card">
                    <form [formGroup]="aclaracionesForm" (ngSubmit)="onGurardarAclaracionLicitacion()">
                        <div class="pregunta mb-20">
                            <div class="row">
                                <div class="col">
                                    <h5 class="mb-1 text-regular">SELECCIONE UN TIPO DE ACLARACIÓN</h5>
                                    <br>
                                    <div class="form-group row m-b-15">
                                        <label class="col-form-label col-md-3">tipos de aclaración:</label>
                                        <div class="col-md-9">
                                            <select class="form-control" formControlName="tipoAclaracion">	
                                                <option value="0">Seleccione...</option>										
                                                <option *ngFor="let item of tiposAclaraciones; let i = index" value="{{item.id_tipo_aclaracion}}">{{item.descripcion}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="respuesta ml-4 mb-4">
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <textarea class="form-control" formControlName="textoAclaracion" rows="2"></textarea>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col text-right">
                                <div class="col col-md-12 text-right" style="margin-top: 10px;">
                                    <button class="btn btn-primary pl-3 pr-3" type="submit" [disabled]="aclaracionesForm.invalid">Agregar aclaración</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="ml-1 pl-md-2" style="position: sticky;top: 6rem;">
                <div class="row">
                    <div class="col">
                        <h3>Subir consolidado</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-12">
                        <div class="row card p-20">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-form-label col-12">Descripción Archivo <span class="text-danger">*</span></label>
                                    <div class="col-12">
                                        <input class="form-control" type="text" placeholder="" id="nombreArchivo" [(ngModel)]="nombreArchivo">
                                    </div>
                                </div>   
                            </div>
                            <div class="col col-md-12 mb-4">
                                <input #inputFile class="" name="archivo" type="file" (change)="detectFiles($event)" #archivo />
                            </div>
                            <div class="col col-md-12 text-right">
                                <button type="button" class="btn tag"(click)="onGuardarArchivo()">Subir</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div *ngIf="archivos?.length > 0" class="row mt-4">
                    <div class="col-12 archivos-list">
                        <app-view-etapa-files-table [archivos]="archivos" [nombreEtapa]="nombreEtapa"></app-view-etapa-files-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
