import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class EtapasService {
  constructor(private http: HttpClient) {}
  private urlService: string = environment.apiUrl;

  getEtapas() {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "etapa/getEtapas", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let etapas = [];
          for (const item in resp["etapas"]) {
            etapas.push({ ...resp["etapas"][item] });
          }
          return JSON.stringify(etapas);
        })
      );
  }

  getEtapaId(in_etapa_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(this.urlService + "etapa/getEtapaId/" + in_etapa_correlativo, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let etapa = [];
          for (const item in resp["etapa"]) {
            etapa.push({ ...resp["etapa"][item] });
          }
          return JSON.stringify(etapa);
        })
      );
  }

  getArchivoLicEtapa(in_licitacion: Number, in_etapa: Number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "etapa/getArchivoLicEtapa/" +
          in_licitacion +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivo_etapa = [];
          for (const item in resp["archivo_etapa"]) {
            archivo_etapa.push({ ...resp["archivo_etapa"][item] });
          }
          return JSON.stringify(archivo_etapa);
        })
      );
  }
  getArchivoLicEtapaFull(in_licitacion: Number, in_etapa: Number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "etapa/getArchivoLicEtapaFull/" +
          in_licitacion +
          "/" +
          in_etapa,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivo_etapa = [];
          for (const item in resp["archivo_etapa"]) {
            archivo_etapa.push({ ...resp["archivo_etapa"][item] });
          }
          return JSON.stringify(archivo_etapa);
        })
      );
  }
  getArchivosLicEtapaPart(in_etli_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "etapa/getArchivosLicEtapaPart/" +
          in_etli_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let archivo_etapa = [];
          for (const item in resp["archivo_etapa"]) {
            archivo_etapa.push({ ...resp["archivo_etapa"][item] });
          }
          return JSON.stringify(archivo_etapa);
        })
      );
  }

  postAvanzaEtapaLic(postData: {
    in_lic_correlativo: number;
    in_etapa_correlativo: number;
    in_observaciones: string;
  }) {
    // postData.in_usuario = "SISTEMA";
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .post(this.urlService + "etapa/postAvanzaEtapaLic", postData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let licitacion = [];
          for (const item in resp["licitacion"]) {
            licitacion.push({ ...resp["licitacion"][item] });
          }
          return JSON.stringify(licitacion);
        })
      );
  }

  getSelectOrdenEtapaActual(etapa_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "etapa/getSelectOrdenEtapaActual/" +
          etapa_correlativo,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let orderEtapa = [];
          for (const item in resp["orderEtapa"]) {
            orderEtapa.push({ ...resp["orderEtapa"][item] });
          }
          return JSON.stringify(orderEtapa);
        })
      );
  }

  getSelectObservacioneEtapaLicitacion(idLicitacion: number) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;
    return this.http
      .get(
        this.urlService +
          "etapa/getSelectObservacioneEtapaLicitacion/" +
          idLicitacion,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .pipe(
        map((resp) => {
          let observacionesEtapa = [];
          for (const item in resp["observacionesEtapa"]) {
            observacionesEtapa.push({ ...resp["observacionesEtapa"][item] });
          }
          return JSON.stringify(observacionesEtapa);
        })
      );
  }

  postiInsertObservacionesLicitacion(postData: {
    in_lic_correlativo: number;
    in_etapa_correlativo: number;
    in_observaciones: string;
  }) {
    const token = JSON.parse(localStorage.getItem("userData"))._token;

    return this.http.post(
      `${this.urlService}etapa/postiInsertObservacionesLicitacion`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }
}
