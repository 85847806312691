import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
  IItemizado,
  ItemizadoService,
} from "src/app/shared/services/itemizado.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { ExportExcelService } from "src/app/shared/services/export-excel.service";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import { formatCurrency } from "src/app/shared/functions/common";
import { PDF } from "src/app/shared/functions/pdf";
import * as moment from "src/assets/plugins/moment/moment";
import { Location } from "@angular/common";
import { CriteriosEvaluacionService, IGrupo } from "src/app/shared/services/criterios-evaluacion.service";

interface ObjetoValidateMontos {
  correlativo: number;
  montoTotal: number;
}

@Component({
  selector: "app-resultados-itemizado",
  templateUrl: "./resultados-itemizado.component.html",
  styleUrls: ["./resultados-itemizado.component.css"],
  providers: [LicitacionService, ItemizadoService],
})
export class ResultadosItemizadoComponent implements OnInit {
  idLicitacion: number;
  objItemizadoLs: IItemizado[];
  objLicitacion: any;
  indexItem: number = 0;
  indexDetalle: number = 0;
  objParticipantes: any[] = [];
  objComisionEconomica: any[];
  numCols: number;
  archivos: any[];

  Total: number;
  TotalPart: any;
  headerResumen: string[];
  headerDetalle: string[];
  dataResumen: (string | number)[][] = [];
  dataDetalle: (string | number)[][] = [];

  idEtapa: number;

  ParticipanteSelected: any;
  isChildren: boolean;
  showInfo: boolean = false;
  downloadingPdf: boolean = false;
  @Input() idLicitacionParent: number = 0;

  archivosLicitacion: any[];
  onVerArchivos: boolean;
  showJacSection: boolean;
  validacionJacEnviar: number;
  validacionJac: number;
  obsevacionJac: string;
  modalTexto: string;
  idRol: number;
  idUser: number;
  idEtapaLic: number;
  objetoValidateMontos: ObjetoValidateMontos | null;
  presupuestosOferentesLicitacion: any[];
  observacionAvanceEtapa: string;
  rolesParticipanteComision: any[];
  // validacion de riesgo control
  validacionRcEnviar: number;
  validacionRc: number;
  obsevacionRc: string;
  // en caso de que no se pueda determinar al oferente con el menor presupuesto
  oferentesSeleccionManual: any[];
  // resultados eval tecnica
  objResultados: any[];
  objGrupos: IGrupo[];

  constructor(
    private objItemizadoService: ItemizadoService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private excelService: ExportExcelService,
    private etapaService: EtapasService,
    private router: Router,
    private location: Location,
    private criteriosServices: CriteriosEvaluacionService,
  ) {
    this.archivosLicitacion = [];
    this.onVerArchivos = false;
    this.showJacSection = false;
    this.validacionJac = 0;
    this.validacionJacEnviar = 0;
    this.obsevacionJac = "";
    this.modalTexto = "";
    this.idRol = 0;
    this.presupuestosOferentesLicitacion = [];
    this.observacionAvanceEtapa = "";
    this.archivos = [];
    this.rolesParticipanteComision = [];
    this.validacionRcEnviar = 0;
    this.validacionRc = 0;
    this.obsevacionRc = "";
    this.oferentesSeleccionManual = [];
    this.objResultados = [];
    this.objGrupos = [];

    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.getArchivoLicitacion(this.idLicitacion);
      this.idRol = JSON.parse(localStorage.getItem("userData")).rolId;
      this.idUser = JSON.parse(localStorage.getItem("userData")).userId;
      this.idEtapaLic = params["idEtapaLic"];
      this.getGrupos();
    });
  }

  ngOnInit(): void {
    this.isChildren = this.idLicitacionParent !== 0;
    if (this.isChildren) this.idLicitacion = this.idLicitacionParent;
    this.getHeaderLicitacionId();
    this.getParticipantesLicitacionID();
    this.getArchivoLicEtapaFull();
    this.getParticipantesComLicitacion();
    this.getPresupuestosOferentesLicitacion();
    this.getSelectRolesParticipanteComision();
    this.getResultadosEvalTec();
  }

  getHeaderLicitacionId() {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        this.objLicitacion = JSON.parse(resp)[0];
        this.showInfo = this.objLicitacion?.ORDEN >= 15 || this.isChildren;
        this.showJacSection = this.objLicitacion?.ORDEN === 12;
        this.obsevacionJac = this.objLicitacion?.OBSERVACION_JAC ?? "";
        this.validacionJac = this.objLicitacion?.VALIDACION_JAC ?? 0;
        this.idEtapa = this.objLicitacion.ETAPA_CORRELATIVO ?? 0;
        // validacion de riesgo control
        this.validacionRc = this.objLicitacion?.VALIDACION_RIESGO_CONTROL ?? 0;
        this.obsevacionRc =
          this.objLicitacion?.OBSERVACION_RIESGO_CONTROL ?? "";
        this.getItemizado();
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cabecera licitación"
        );
      }
    );
  }

  getParticipantesLicitacionID() {
    !this.isChildren &&
      this.licitacionService
        .getParticipantesLicitacionID(this.idLicitacion)
        .subscribe(
          (resp) => {
            this.objParticipantes = JSON.parse(resp);
            console.log(this.objParticipantes);
            this.numCols = this.objParticipantes.length;
          },
          (err) => {
            this.notifyService.showError(
              err.error.message,
              "Obtener cabecera licitación"
            );
          }
        );
  }

  createHeaders() {
    this.headerResumen = ["Descripcion", "Presupuesto"];
    this.headerDetalle = ["Descripcion", "unidad", "cantidad", "Presupuesto"];
  }

  createResumen() {
    let totalesParticipantesCompara: ObjetoValidateMontos[] = [];
    let totalesParticipantes: any[] = [];

    const participantesVigentes: any[] = this.objParticipantes.filter(
      (participante) => participante.vigente === "S"
    );

    this.objItemizadoLs.forEach((item, i) => {
      const dataResumen = [
        `${item.pos} ${item.descripcion}`,
        this.calcularTotales(i),
      ];

      participantesVigentes.forEach((parti) => {
        const totalParti = this.calcularTotalesPart(i, parti.PART_CORRELATIVO);
        const porcentajeParti = (
          (this.calcularTotalesPart(i, parti.PART_CORRELATIVO) * 100) /
          this.calcularTotales(i)
        ).toFixed(0);
        const diferenciaParti =
          this.calcularTotalesPart(i, parti.PART_CORRELATIVO) -
          this.calcularTotales(i);
        dataResumen.push(totalParti);
        dataResumen.push(porcentajeParti);
        dataResumen.push(diferenciaParti);
      });
      this.dataResumen.push(dataResumen);
    });

    const totalRow = ["Total", this.Total];

    participantesVigentes.forEach((parti) => {
      const totalParti = this.calcularPresupuestoPart(parti.PART_CORRELATIVO);
      const porcentajeParti = (
        (this.calcularPresupuestoPart(parti.PART_CORRELATIVO) * 100) /
        this.Total
      ).toFixed(0);
      const diferenciaParti =
        this.calcularPresupuestoPart(parti.PART_CORRELATIVO) - this.Total;
      totalRow.push(totalParti);
      totalRow.push(porcentajeParti);
      totalRow.push(diferenciaParti);

      if (parti.APROBO_EVAL_TEC == 1) {
        const dataTotalesParti: ObjetoValidateMontos = {
          correlativo: parti.PART_CORRELATIVO,
          montoTotal: totalParti,
        };

        totalesParticipantesCompara.push(dataTotalesParti);
        totalesParticipantes.push({
          correlativo: parti.PART_CORRELATIVO,
          razonSocial: parti.RAZON_SOCIAL,
          total: totalParti,
        });
      }
    });

    this.dataResumen.push(totalRow);

    console.log(this.encontrarMenor(totalesParticipantesCompara, this.Total));

    this.oferentesSeleccionManual = totalesParticipantes;

    this.objetoValidateMontos = this.encontrarMenor(
      totalesParticipantesCompara,
      this.Total
    );
  }

  createDetalle() {
    const participantesVigentes: any[] = this.objParticipantes.filter(
      (participante) => participante.vigente === "S"
    );

    this.objItemizadoLs.forEach((item) => {
      const headerDetalle = [
        `${item.pos} ${item.descripcion}`,
        ...Array(this.headerDetalle.length - 1).fill(""),
      ];

      this.dataDetalle.push(headerDetalle);

      item.itemizadoDetalleLs.forEach((detalle) => {
        const dataDetalle = [
          `   ${detalle.pos} ${detalle.item}`,
          detalle.unidad,
          detalle.cantidad,
          detalle.presupuesto * detalle.cantidad,
        ];

        participantesVigentes.forEach((parti) => {
          const total = detalle[parti.RAZON_SOCIAL]
            ? detalle[parti.RAZON_SOCIAL] * detalle.cantidad
            : 0;
          dataDetalle.push(total);
        });

        this.dataDetalle.push(dataDetalle);
      });
    });

    const totalRow = ["Total", "", "", this.Total];

    participantesVigentes.forEach((parti) => {
      const totalParti = this.calcularPresupuestoPart(parti.PART_CORRELATIVO);
      totalRow.push(totalParti);
    });

    this.dataDetalle.push(totalRow);
  }

  getItemizado() {
    this.objItemizadoService.getItemizadoResultado(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;

        if (response.data?.length > 0) {
          this.objItemizadoLs = response.data;
          this.Total = 0;
          for (let i = 0; i < this.objItemizadoLs.length; i++) {
            for (
              let y = 0;
              y < this.objItemizadoLs[i].itemizadoDetalleLs.length;
              y++
            ) {
              this.Total +=
                this.objItemizadoLs[i].itemizadoDetalleLs[y].cantidad *
                this.objItemizadoLs[i].itemizadoDetalleLs[y].presupuesto;
              //this.objParticipantes += (this.objItemizadoLs[i].itemizadoDetalleLs[y].cantidad * this.objItemizadoLs[i].itemizadoDetalleLs[y].presupuesto);
            }
          }
          this.createHeaders();
          this.createResumen();
          this.createDetalle();
        }
        /* else
      {
        this.setDefault();
      } */

        //this.calcularPorcentajesFormularios();
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  calcularTotales(index: number) {
    let toReturn: number = 0;
    //this.objItemizadoLs
    for (
      let i = 0;
      i < this.objItemizadoLs[index].itemizadoDetalleLs.length;
      i++
    ) {
      toReturn =
        toReturn +
        this.objItemizadoLs[index]?.itemizadoDetalleLs[i]?.cantidad *
          this.objItemizadoLs[index]?.itemizadoDetalleLs[i]?.presupuesto;
    }

    return +toReturn.toFixed(2);
  }
  calcularTotalesPart(index: number, razon_social: number) {
    let toReturn = 0;
    //this.objItemizadoLs
    for (
      let i = 0;
      i < this.objItemizadoLs[index].itemizadoDetalleLs.length;
      i++
    ) {
      toReturn =
        toReturn +
        this.objItemizadoLs[index]?.itemizadoDetalleLs[i]?.cantidad *
          this.objItemizadoLs[index]?.itemizadoDetalleLs[i]?.[razon_social];
    }

    return +toReturn.toFixed(2);
  }

  calcularPresupuestoPart(razon_social: number) {
    let toReturn = 0;
    for (let i = 0; i < this.objItemizadoLs.length; i++) {
      for (
        let y = 0;
        y < this.objItemizadoLs[i].itemizadoDetalleLs.length;
        y++
      ) {
        toReturn +=
          this.objItemizadoLs[i].itemizadoDetalleLs[y].cantidad *
          this.objItemizadoLs[i].itemizadoDetalleLs[y]?.[razon_social];
        //this.objParticipantes += (this.objItemizadoLs[i].itemizadoDetalleLs[y].cantidad * this.objItemizadoLs[i].itemizadoDetalleLs[y].presupuesto);
      }
    }
    return toReturn;
  }
  /* getKeys() {
    return (this.objEvaluadoresResultados && this.objEvaluadoresResultados.length > 0) ?  Object.keys(this.objEvaluadoresResultados[0]) : [];
  } */

  getParticipantesComLicitacion() {
    this.licitacionService
      .getParticipantesComLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          if (JSON.parse(resp)) {
            const data = JSON.parse(resp);
            const comisionEconomica = [
              "Comisión Económica",
              "Supervisor Abastecimiento y Contrato",
              "Supervisor de Control de Calidad",
            ];
            this.objComisionEconomica = data.filter((d: any) =>
              comisionEconomica.includes(d.COMISION)
            );
          }
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Obtener comision");
        }
      );
  }

  exportExcelResultados() {
    const headerResumen = [...this.headerResumen];

    const participantesVigentes: any[] = this.objParticipantes.filter(
      (participante) => participante.vigente === "S"
    );

    participantesVigentes.forEach(({ RAZON_SOCIAL }) => {
      headerResumen.push(`${RAZON_SOCIAL} Ofertado`);
      headerResumen.push(`${RAZON_SOCIAL} % Ofertado / Presupuesto`);
      headerResumen.push(`${RAZON_SOCIAL} Diferencia Ofertado - Presupuesto`);
    });

    const dataResumen = {
      title: "Resumen",
      headers: headerResumen,
      data: this.dataResumen,
    };

    const headerDetalle = [...this.headerDetalle];
    headerDetalle[0] = "Partida";

    const dataDetalle = {
      title: "Detalle",
      headers: headerDetalle,
      data: this.dataDetalle,
    };

    const data = [dataResumen, dataDetalle];

    const excelProps = {
      filename: `Resultado-Economicos-Lic-${this.idLicitacion}`,
      data,
    };

    this.excelService.exportExcelWithMultiplesSheets(excelProps);
  }

  getArchivoLicEtapaFull() {
    this.etapaService
      .getArchivoLicEtapaFull(this.idLicitacion, 10)
      .subscribe((resp) => {
        this.archivos = JSON.parse(resp);
      });
  }

  filesPart(partCorrelativo: number) {
    return this.archivos.filter(
      (element) => element.PARTICIPANTE === partCorrelativo
    );
  }

  setGanador() {
    const postdata = {
      id_participante: this.ParticipanteSelected.PART_CORRELATIVO,
      id_licitacion: this.idLicitacion,
    };

    this.licitacionService.postSetGanador(postdata).subscribe(
      (resp) => {
        let response: any = resp;
        this.notifyService.showSuccess("", "Datos guardados");
        if (response.data == "") {
          this.router.navigate(["/licitaciones"]);
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.getHeaderLicitacionId();
        this.getParticipantesLicitacionID();
        this.getArchivoLicEtapaFull();
        this.getParticipantesComLicitacion();
      }
    );
  }

  async onDescargarPDF() {
    this.downloadingPdf = true;
    const doc = new PDF({ format: "A2", unit: "mm" }, [
      "",
      "Detalle partida - subpartida",
    ]);

    doc.addLogo();
    doc.addTitle(`Licitacion ${this.objLicitacion.CONT_NOMBRE}`);

    const iLengthTitleFirst = 80.975;
    const iLengthValueFirst = 300;

    doc.identText(
      "Descripción",
      this.objLicitacion.DESCRIPCION,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Licitación",
      this.idLicitacion.toString(),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Solpe",
      this.objLicitacion?.SOLPE_CORRELATIVO,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Estado",
      this.objLicitacion?.ETAPA_DESC,
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Fecha",
      this.objLicitacion?.FECHA_CREACION
        ? moment(this.objLicitacion?.FECHA_CREACION, "DD-MM-YYYY").format(
            "DD/MM/YYYY"
          )
        : "",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Moneda",
      this.objLicitacion?.DESCRIPCION_MONEDA_SOLPE || "Peso Chileno",
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.identText(
      "Monto",
      formatCurrency(this.objLicitacion?.MONTO_SOLPE || ""),
      iLengthTitleFirst,
      iLengthValueFirst
    );

    doc.increasePageY(10);

    doc.addTitle("Resumen Resultados Económicos");

    doc.addTableHtml("#table_resultado");

    // //SEGUNDA PAGINA

    doc.iSection = 2;
    doc.addNewPage();

    doc.addTableHtml("#table_detalle");
    doc.increasePageY(10);

    doc.text(
      "Los abajo firmantes certifican los resultados de la comisión encargada:",
      doc.iMargin,
      doc.nPageYAxi
    );
    doc.increasePageY(20);

    this.objComisionEconomica.forEach((persona) => {
      doc.addSign(persona.PART_NOMBRE);
      doc.increasePageY(15);
    });

    doc.text("Observaciones:", doc.iMargin, doc.nPageYAxi);
    doc.increasePageY(5);

    doc.addFooter();
    doc.save(`resultado_eval_economica_${this.idLicitacion}.pdf`);
    this.downloadingPdf = false;
  }

  back(): void {
    this.location.back();
  }

  convertir(valor: number) {
    if (valor < this.Total) {
      return valor * 1;
    } else {
      return valor;
    }
  }

  convertirPorcentaje(valor: number) {
    if (valor < 100) {
      return valor * 1;
    } else {
      return valor;
    }
  }

  private getArchivoLicitacion(idLicitacion: number) {
    if (idLicitacion) {
      this.licitacionService.getArchivosLicitacion(this.idLicitacion).subscribe(
        (resp) => {
          this.archivosLicitacion = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener archivos licitación"
          );
        }
      );
    } else {
      this.notifyService.showError(
        "Falta el id de la licitación",
        "Obtener archivos licitación"
      );
    }
  }

  valdiacionJac(validacion: number) {
    this.validacionJacEnviar = validacion;

    if (validacion === 1) {
      this.modalTexto =
        "¿Esta seguro que desea validar la apertura de ofertas económicas para esta licitación? Una vez validada no podrá ser modificada.";
    } else {
      this.modalTexto =
        "Por favor indique una justificación para rechazar la licitación.";
    }
  }

  onValidarLicitacion() {
    if (this.validacionJacEnviar === 0) {
      if (this.obsevacionJac.trim() === "") {
        this.notifyService.showWarning(
          "Por favor indique una justificación",
          "Validación JAC"
        );
        return;
      }
    }

    if (this.validacionJacEnviar === 1) {
      if (this.observacionAvanceEtapa.trim() === "") {
        this.notifyService.showWarning(
          "Para avanzar de etapa, primero debe ingresar al menos una observación",
          "Validación JAC"
        );
        return;
      }
    }

    const postData = {
      validacion: this.validacionJacEnviar,
      observacion: this.obsevacionJac,
      idLicitacion: this.idLicitacion,
    };

    this.licitacionService.postUpdateValidacionJac(postData).subscribe(
      (resp) => {
        if (this.validacionJacEnviar === 1) {
          if (this.observacionAvanceEtapa.trim() === "") {
            this.notifyService.showWarning(
              "Para avanzar de etapa, primero debe ingresar al menos una observación",
              "Avanzar etapa"
            );
            return;
          } else {
            const postData = {
              in_lic_correlativo: this.idLicitacion,
              in_etapa_correlativo: this.idEtapa,
              in_observaciones: this.observacionAvanceEtapa,
            };

            this.etapaService.postAvanzaEtapaLic(postData).subscribe(
              (resp) => {
                this.getHeaderLicitacionId();
                this.getParticipantesLicitacionID();
                this.getArchivoLicEtapaFull();
                this.getParticipantesComLicitacion();

                this.notifyService.showSuccess(
                  "La licitacion avanzó de etapa correctamente",
                  "Avanzar etapa"
                );
              },
              (err) => {
                this.notifyService.showError(
                  err.error.message,
                  "Avanzar etapa"
                );
              }
            );
          }
        } else {
          this.getHeaderLicitacionId();
          this.notifyService.showSuccess(
            "La licitacion fue rechazada correctamente",
            "Validación JAC"
          );
        }
      },
      (err) => {
        this.notifyService.showError(err.error.message, "Validación JAC");
      }
    );
  }

  encontrarMenor(
    arreglo: ObjetoValidateMontos[],
    valor: number
  ): ObjetoValidateMontos | null {
    let resultado: ObjetoValidateMontos | null = null;
    for (let i = 0; i < arreglo.length; i++) {
      if (arreglo[i].montoTotal > 0 && arreglo[i].montoTotal < valor) {
        if (
          resultado === null ||
          arreglo[i].montoTotal < resultado.montoTotal
        ) {
          resultado = arreglo[i];
        }
      }
    }
    return resultado;
  }

  getPresupuestosOferentesLicitacion() {
    this.objItemizadoService
      .getPresupuestosOferentesLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.presupuestosOferentesLicitacion = JSON.parse(resp);
          console.log(this.presupuestosOferentesLicitacion);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener presupuestos oferentes"
          );
        }
      );
  }

  getPresupuestoIngresadoByOferente(partCorrelativo: number) {
    const presupuestoOferenteObj = this.presupuestosOferentesLicitacion.filter(
      (element) => element.correlativo_oferente === partCorrelativo
    );

    if (presupuestoOferenteObj.length > 0) {
      return presupuestoOferenteObj[0]
        .monto_total_oferta_economica_ingresado as number;
    } else {
      return 0;
    }
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  valdiacionRc(validacion: number) {
    this.validacionRcEnviar = validacion;

    if (validacion === 1) {
      this.modalTexto =
        "¿Esta seguro que desea validar la apertura de ofertas económicas para esta licitación? Una vez validada no podrá ser modificada.";
    } else {
      this.modalTexto =
        "Por favor indique una justificación para rechazar la licitación.";
    }
  }

  onValidarLicitacionRc() {
    if (this.validacionRcEnviar === 0) {
      if (this.obsevacionRc.trim() === "") {
        this.notifyService.showWarning(
          "Por favor indique una justificación",
          "Validación Riesgo Control"
        );
        return;
      }
    }

    if (this.validacionRcEnviar === 1) {
      if (this.observacionAvanceEtapa.trim() === "") {
        this.notifyService.showWarning(
          "Para avanzar de etapa, primero debe ingresar al menos una observación",
          "Validación Riesgo Control"
        );
        return;
      }
    }

    const postData = {
      validacion: this.validacionRcEnviar,
      observacion: this.obsevacionRc,
      idLicitacion: this.idLicitacion,
    };

    this.licitacionService
      .postUpdateValidacionRiesgoControl(postData)
      .subscribe(
        (resp) => {
          if (this.validacionRcEnviar === 1) {
            if (this.observacionAvanceEtapa.trim() === "") {
              this.notifyService.showWarning(
                "Para avanzar de etapa, primero debe ingresar al menos una observación",
                "Avanzar etapa"
              );
              return;
            } else {
              const postData = {
                in_lic_correlativo: this.idLicitacion,
                in_etapa_correlativo: this.idEtapa,
                in_observaciones: this.observacionAvanceEtapa,
              };

              this.etapaService.postAvanzaEtapaLic(postData).subscribe(
                (resp) => {
                  this.getHeaderLicitacionId();
                  this.getParticipantesLicitacionID();
                  this.getArchivoLicEtapaFull();
                  this.getParticipantesComLicitacion();

                  this.notifyService.showSuccess(
                    "La licitacion avanzó de etapa correctamente",
                    "Avanzar etapa"
                  );
                },
                (err) => {
                  this.notifyService.showError(
                    err.error.message,
                    "Avanzar etapa"
                  );
                }
              );
            }
          } else {
            this.getHeaderLicitacionId();
            this.notifyService.showSuccess(
              "La apertura fue rechazada correctamente",
              "Validación Riesgo Control"
            );
          }
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Validación JAC");
        }
      );
  }

  validateRol(): boolean {
    if (this.idRol == 15) {
      return true;
    } else if (this.idRol == 29) {
      return true;
    } else {
      return false;
    }
  }

  findOferenteSeleccionadoManual(idOferente: number) {
    const participantesVigentes: any = this.objParticipantes.filter(
      (participante) => participante.PART_CORRELATIVO == idOferente
    );

    return participantesVigentes[0];
  }

  validateEtapaVerBotonArchivos(): boolean {
    if (this.objLicitacion.ETAPA_CORRELATIVO == 10) {
      return false;
    } else if(this.objLicitacion.ETAPA_CORRELATIVO == 18) {
      return false;
    } else if(this.objLicitacion.ETAPA_CORRELATIVO == 19) {
      return false;
    } else if(this.objLicitacion.ETAPA_CORRELATIVO == 20) {
      return false;
    } else {
      return true;
    }
  }

  getResultadosEvalTec() {
    this.criteriosServices.getResultados(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;
        if (response.data?.length > 0) {
          this.objResultados = response.data;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  calTotal(Participante) {
    let sum = 0;

    for (let i = 0; i < this.objResultados?.length; i++) {
      for (const [key, value] of Object.entries(this.objResultados[i])) {
        if (key == Participante) {
          sum +=
            +value *
            (this.objResultados[i].peso / 100) *
            (this.getPesoGrupo(this.objResultados[i].grupo) / 100);
        }
      }
    }

    return sum;
  }

  getPesoGrupo(idGrupo) {
    let Arr = this.objGrupos?.filter((x) => x.correlativo === idGrupo);

    if (Arr?.length > 0) {
      return Arr[0].peso;
    } else {
      return 0;
    }
  }

  getGrupos() {
    this.criteriosServices.getGrupos(this.idLicitacion).subscribe(
      (resp) => {
        let response: any = resp;
        if (response.data?.length > 0) {
          this.objGrupos = response.data;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  getEvalEconomicaTextStatus(): string {
    switch (this.objLicitacion?.ETAPA_CORRELATIVO) {
      case 10:
        return 'Pendiente';
      case 11:
        return 'Pendiente';
      case 18:
        return 'Pendiente';
      case 19:
        return 'Pendiente';
      case 20:
        return 'Pendiente';
      default:
        return 'No seleccionado';
    }
  }
}
