import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import {
  CriteriosEvaluacionService,
  ICriterios,
  IGrupo,
} from "src/app/shared/services/criterios-evaluacion.service";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-evalucion",
  templateUrl: "./evalucion.component.html",
  styleUrls: ["./evalucion.component.css"],
  providers: [CriteriosEvaluacionService, LicitacionService],
})
export class EvalucionComponent implements OnInit {
  modalGrupo: boolean = false;
  modalSubgrupo: boolean = false;
  modalCriterio: boolean = false;
  grupoSelected: IGrupo;
  grupoIndex: number;

  isUpdateGrupo: boolean = false;
  isUpdateSubgrupo: boolean = false;
  isUpdateCriterio: boolean = false;

  indexGrupo: number = 0;
  indexSubgrupo: number = 0;
  indexCriterio: number = 0;
  idParticipante: number = 0;

  subgrupoIndex: number;

  idLicitacion: number;
  objLicitacion: any;
  objGrupos: IGrupo[];
  formGroup: FormGroup;
  formSubgroup: FormGroup;
  formCriterio: FormGroup;

  objCritero: ICriterios;

  idEtapa: number;
  idEtapaLic: number;
  userId: string = "";
  archvosLicitacion: any[];

  constructor(
    private route: ActivatedRoute,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private criteriosServices: CriteriosEvaluacionService,
    formBuilder: FormBuilder,
    private location: Location
  ) {
    this.formCriterio = new FormGroup({
      correlativo: new FormControl(null),
      descripcion: new FormControl(null),
      observacion: new FormControl(null),
      grupo_correlativo: new FormControl(null),
      subgrupo_correlativo: new FormControl(null),
    });
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];

      this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
        (resp) => {
          this.objLicitacion = JSON.parse(resp)[0];
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener cabecera licitación"
          );
        }
      );
    });

    this.objCritero = {} as ICriterios;
  }

  ngOnInit(): void {
    this.licitacionService.getDatosPart().subscribe((resp) => {
      this.idParticipante = JSON.parse(resp)[0].PART_CORRELATIVO;
      this.getGrupos();
    });
  }

  imprimir() {
    window.print();
  }

  LoadUpdateCriterioForm(index, indexSub, indexCriterio, correlativo) {
    this.modalCriterio = true;
    this.isUpdateCriterio = true;
    this.indexGrupo = index;
    this.indexSubgrupo = indexSub;
    this.indexCriterio = indexCriterio;
    if (this.objGrupos[index].subgrupoLs[indexSub].tipo == 1) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        grupo_correlativo: new FormControl(null),
        subgrupo_correlativo: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["file"].setValue("");
    } else if (this.objGrupos[index].subgrupoLs[indexSub].tipo == 2) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        cantidad: new FormControl(null),
        turno: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["cantidad"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .cantidad
      );
      this.formCriterio.controls["turno"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .turno
      );
    } else if (this.objGrupos[index].subgrupoLs[indexSub].tipo == 3) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        nombre_apellido: new FormControl(null),
        perfil: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["nombre_apellido"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .nombre_apellido
      );
      this.formCriterio.controls["perfil"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .perfil
      );
    } else if (this.objGrupos[index].subgrupoLs[indexSub].tipo == 4) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        marca: new FormControl(null),
        modelo: new FormControl(null),
        ano: new FormControl(null),
        propiedad: new FormControl(null),
        cumpleecf: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["marca"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .marca
      );
      this.formCriterio.controls["modelo"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .modelo
      );

      this.formCriterio.controls["ano"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .ano
      );
      this.formCriterio.controls["propiedad"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .tipo_propiedad
      );
      this.formCriterio.controls["cumpleecf"].setValue(
        this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
          .cumple_ECF
      );
    }

    /* this.formCriterio.controls['descripcion'].setValue(this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio].descripcion); */
    //this.formGroup.controls['correlativo'].setValue(this.objGrupos[index].correlativo);
    //this.formGroup.controls['correlativo_licitacion'].setValue(this.objGrupos[index]);
    this.formCriterio.controls["observacion"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
        .observacion
    );
    this.formCriterio.controls["descripcion"].setValue(
      this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCriterio]
        .descripcion
    );
  }
  removeCriterio(index, indexSub, indexCrit, correlativo) {
    if (correlativo > 0) {
      //alert("delete con carga de datos");
      this.criteriosServices
        .postDeleteCriterio(
          this.objGrupos[index].subgrupoLs[indexSub].criteriosLs[indexCrit]
        )
        .subscribe(
          (resp) => {
            let response: any = resp;
            this.objGrupos[index].subgrupoLs[indexSub].criteriosLs.splice(
              indexCrit,
              1
            );
            this.isRespuestasCompletadas();
          },
          (error) => {
            console.log(error);
          },
          () => {
            console.log(this.objGrupos);
          }
        );
    }
  }
  cleanCriterioForm() {
    const tipo =
      this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].tipo;
    if (tipo == 1) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        grupo_correlativo: new FormControl(null),
        subgrupo_correlativo: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["descripcion"].setValue("");
      this.formCriterio.controls["observacion"].setValue("");
      this.formCriterio.controls["file"].setValue("");
    } else if (tipo == 2) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        cantidad: new FormControl(null),
        turno: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["descripcion"].setValue("");
      this.formCriterio.controls["observacion"].setValue("");
      this.formCriterio.controls["cantidad"].setValue("");
      this.formCriterio.controls["turno"].setValue("1");
    } else if (tipo == 3) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        nombre_apellido: new FormControl(null),
        perfil: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["descripcion"].setValue("");
      this.formCriterio.controls["observacion"].setValue("");
      this.formCriterio.controls["nombre_apellido"].setValue("");
      this.formCriterio.controls["perfil"].setValue("");
    } else if (tipo == 4) {
      this.formCriterio = new FormGroup({
        descripcion: new FormControl(null),
        observacion: new FormControl(null),
        marca: new FormControl(null),
        modelo: new FormControl(null),
        ano: new FormControl(null),
        propiedad: new FormControl(null),
        cumpleecf: new FormControl(null),
        file: new FormControl(null),
        fileSource: new FormControl(null),
      });
      this.formCriterio.controls["descripcion"].setValue("");
      this.formCriterio.controls["observacion"].setValue("");
      this.formCriterio.controls["marca"].setValue("");
      this.formCriterio.controls["modelo"].setValue("");
      this.formCriterio.controls["ano"].setValue("");
      this.formCriterio.controls["propiedad"].setValue("");
      this.formCriterio.controls["cumpleecf"].setValue("0");
    }
  }
  postCriterio() {
    //obsData: ISubGrupo, indexGrupo, indexSubGrupo
    //this.objGrupos.push({})
    const tipo =
      this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].tipo;
    if (tipo == 1) {
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      //var file = this.formCriterio.get("file").value;
      var file = this.formCriterio.controls.fileSource.value;

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      var fileData = JSON.parse(file);
      var nameFile = fileData ? fileData.filename : "";

      this.objCritero = {
        correlativo: 0,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion: 0,
        documento: file,
        correlativo_participante: this.idParticipante,
        cantidad: 0,
        turno: 0,
        nombre_apellido: "",
        perfil: "",
        marca: "",
        modelo: "",
        ano: 0,
        tipo_propiedad: 0,
        cumple_ECF: false,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioForm(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (
              this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
                .criteriosLs == undefined
            ) {
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs = [];
            }
            this.objGrupos[this.indexGrupo].subgrupoLs[
              this.indexSubgrupo
            ].criteriosLs.push(this.objCritero);

            this.isRespuestasCompletadas();
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else if (tipo == 2) {
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      var cantidad = this.formCriterio.get("cantidad").value;
      var turno = this.formCriterio.get("turno").value;
      var file = this.formCriterio.controls.fileSource.value;

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      var fileData = JSON.parse(file);
      var nameFile = fileData ? fileData.filename : "";

      this.objCritero = {
        correlativo: 0,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion: 0,
        documento: file,
        correlativo_participante: this.idParticipante,
        cantidad: cantidad,
        turno: turno,
        nombre_apellido: "",
        perfil: "",
        marca: "",
        modelo: "",
        ano: 0,
        tipo_propiedad: 0,
        cumple_ECF: false,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioCargo(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (
              this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
                .criteriosLs == undefined
            ) {
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs = [];
            }
            this.objGrupos[this.indexGrupo].subgrupoLs[
              this.indexSubgrupo
            ].criteriosLs.push(this.objCritero);
            this.isRespuestasCompletadas();
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else if (tipo == 3) {
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      var nombre = this.formCriterio.get("nombre_apellido").value;
      var perfil = this.formCriterio.get("perfil").value;
      var file = this.formCriterio.controls.fileSource.value;

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      var fileData = JSON.parse(file);
      var nameFile = fileData ? fileData.filename : "";

      this.objCritero = {
        correlativo: 0,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion: 0,
        documento: file,
        correlativo_participante: this.idParticipante,
        cantidad: 0,
        turno: 0,
        nombre_apellido: nombre,
        perfil: perfil,
        marca: "",
        modelo: "",
        ano: 0,
        tipo_propiedad: 0,
        cumple_ECF: false,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioPersonal(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (
              this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
                .criteriosLs == undefined
            ) {
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs = [];
            }
            this.objGrupos[this.indexGrupo].subgrupoLs[
              this.indexSubgrupo
            ].criteriosLs.push(this.objCritero);
            this.isRespuestasCompletadas();
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else if (tipo == 4) {
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      var marca = this.formCriterio.get("marca").value;
      var modelo = this.formCriterio.get("modelo").value;
      var ano = this.formCriterio.get("ano").value;
      var propiedad = this.formCriterio.get("propiedad").value;
      var cumpleecf = this.formCriterio.get("cumpleecf").value;
      var file = this.formCriterio.controls.fileSource.value;

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      var fileData = JSON.parse(file);
      var nameFile = fileData ? fileData.filename : "";

      this.objCritero = {
        correlativo: 0,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion: 0,
        documento: file,
        correlativo_participante: this.idParticipante,
        cantidad: 0,
        turno: 0,
        nombre_apellido: "",
        perfil: "",
        marca: marca,
        modelo: modelo,
        ano: ano,
        tipo_propiedad: propiedad,
        cumple_ECF: cumpleecf,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioEquipo(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (
              this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
                .criteriosLs == undefined
            ) {
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs = [];
            }
            this.objGrupos[this.indexGrupo].subgrupoLs[
              this.indexSubgrupo
            ].criteriosLs.push(this.objCritero);
            this.isRespuestasCompletadas();
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else {
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
    }

    //this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: '', correlativo_participante: this.idParticipante});
    this.modalCriterio = false;
  }

  getGrupos() {
    this.criteriosServices
      .getGruposParticipante(this.idLicitacion, this.idParticipante)
      .subscribe(
        (resp) => {
          let response: any = resp;
          if (response.data?.length > 0) {
            this.objGrupos = response.data;
            this.isRespuestasCompletadas();
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
  }
  updateCriterios() {
    //this.indexCriterio
    /* var descr = this.formCriterio.get("descripcion").value;
    var obj = this.formCriterio.get("observacion").value; */
    const tipo =
      this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo].tipo;
    if (tipo == 1) {
      //archivo
      this.objGrupos[this.indexGrupo].subgrupoLs[
        this.indexSubgrupo
      ].criteriosLs[this.indexCriterio].descripcion =
        this.formCriterio.get("descripcion").value;
      this.objGrupos[this.indexGrupo].subgrupoLs[
        this.indexSubgrupo
      ].criteriosLs[this.indexCriterio].observacion =
        this.formCriterio.get("observacion").value;
      var file = this.formCriterio.controls.fileSource.value;
      var nameFile = "";
      if (file !== null) {
        this.objGrupos[this.indexGrupo].subgrupoLs[
          this.indexSubgrupo
        ].criteriosLs[this.indexCriterio].documento = file;
        var fileData = JSON.parse(file);
        nameFile = fileData.filename;
      }

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      this.objCritero = {
        correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].correlativo,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].calificacion,
        documento:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].documento,
        correlativo_participante: this.idParticipante,
        cantidad: 0,
        turno: 0,
        nombre_apellido: "",
        perfil: "",
        marca: "",
        modelo: "",
        ano: 0,
        tipo_propiedad: 0,
        cumple_ECF: false,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterio(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;
            this.objGrupos[this.indexGrupo].subgrupoLs[
              this.indexSubgrupo
            ].criteriosLs[this.indexCriterio] = this.objCritero;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else if (tipo == 2) {
      //cargo
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      var cantidad = this.formCriterio.get("cantidad").value;
      var turno = this.formCriterio.get("turno").value;
      var file = this.formCriterio.controls.fileSource.value;
      var nameFile = "";

      if (file !== null) {
        this.objGrupos[this.indexGrupo].subgrupoLs[
          this.indexSubgrupo
        ].criteriosLs[this.indexCriterio].documento = file;
        var fileData = JSON.parse(file);
        nameFile = fileData.filename;
      }

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      this.objCritero = {
        correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].correlativo,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].calificacion,
        documento:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].documento,
        correlativo_participante: this.idParticipante,
        cantidad: cantidad,
        turno: turno,
        nombre_apellido: "",
        perfil: "",
        marca: "",
        modelo: "",
        ano: 0,
        tipo_propiedad: 0,
        cumple_ECF: false,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioCargo(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (response.data) {
              this.objCritero = response.data;
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs[this.indexCriterio] = this.objCritero;
            }
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else if (tipo == 3) {
      //personal
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      var nombre = this.formCriterio.get("nombre_apellido").value;
      var perfil = this.formCriterio.get("perfil").value;
      var file = this.formCriterio.controls.fileSource.value;
      var nameFile = "";

      if (file !== null) {
        this.objGrupos[this.indexGrupo].subgrupoLs[
          this.indexSubgrupo
        ].criteriosLs[this.indexCriterio].documento = file;
        var fileData = JSON.parse(file);
        nameFile = fileData.filename;
      }

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      this.objCritero = {
        correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].correlativo,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].calificacion,
        documento:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].documento,
        correlativo_participante: this.idParticipante,
        cantidad: 0,
        turno: 0,
        nombre_apellido: nombre,
        perfil: perfil,
        marca: "",
        modelo: "",
        ano: 0,
        tipo_propiedad: 0,
        cumple_ECF: false,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioPersonal(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (response.data) {
              this.objCritero = response.data;
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs[this.indexCriterio] = this.objCritero;
            }
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else if (tipo == 4) {
      //equipos
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;
      var marca = this.formCriterio.get("marca").value;
      var modelo = this.formCriterio.get("modelo").value;
      var ano = this.formCriterio.get("ano").value;
      var propiedad = this.formCriterio.get("propiedad").value;
      var cumpleecf = this.formCriterio.get("cumpleecf").value;
      var file = this.formCriterio.controls.fileSource.value;
      var nameFile = "";

      if (file !== null) {
        this.objGrupos[this.indexGrupo].subgrupoLs[
          this.indexSubgrupo
        ].criteriosLs[this.indexCriterio].documento = file;
        var fileData = JSON.parse(file);
        nameFile = fileData.filename;
      }

      if (!file) {
        this.notifyService.showWarning(
          "Por favor seleccione un archivo",
          "Advertencia"
        );
        return;
      }

      this.objCritero = {
        correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].correlativo,
        descripcion: descr,
        observacion: obj,
        grupo_correlativo: this.objGrupos[this.indexGrupo].correlativo,
        subgrupo_correlativo:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .correlativo,
        calificacion:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].calificacion,
        documento:
          this.objGrupos[this.indexGrupo].subgrupoLs[this.indexSubgrupo]
            .criteriosLs[this.indexCriterio].documento,
        correlativo_participante: this.idParticipante,
        cantidad: 0,
        turno: 0,
        nombre_apellido: "",
        perfil: "",
        marca: marca,
        modelo: modelo,
        ano: ano,
        tipo_propiedad: propiedad,
        cumple_ECF: cumpleecf,
        descripcion_archivo: nameFile,
      };
      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
      this.criteriosServices.postCriterioEquipo(this.objCritero).subscribe(
        (resp) => {
          let response: any = resp;
          this.notifyService.showSuccess("", "Datos guardados");
          if (response.data) {
            this.objCritero = response.data;

            if (response.data) {
              this.objCritero = response.data;
              this.objGrupos[this.indexGrupo].subgrupoLs[
                this.indexSubgrupo
              ].criteriosLs[this.indexCriterio] = this.objCritero;
            }
          }
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else {
      var descr = this.formCriterio.get("descripcion").value;
      var obj = this.formCriterio.get("observacion").value;

      //this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].criteriosLs.push({correlativo: 0,descripcion: descr,observacion: obj,grupo_correlativo: this.objGrupos[this.grupoIndex].correlativo,subgrupo_correlativo: this.objGrupos[this.grupoIndex].subgrupoLs[this.subgrupoIndex].correlativo,calificacion: 0,documento: ''});
    }
  }

  onFileChange(event) {
    //V1 base 64
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      const fileName = event.target.files[0].name;
      const type = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      reader.onload = () => {
        const File = { filename: fileName, type: type, base: reader.result };
        this.formCriterio.patchValue({
          fileSource: JSON.stringify(File),
        });
      };
    }
  }

  validateArchivoLicitacion() {
    this.licitacionService
      .getArchivosLicitacionOferente(this.idLicitacion, this.userId)
      .subscribe(
        (resp) => {
          const archivos = JSON.parse(resp);

          if (archivos.length > 0) {
            this.archvosLicitacion = archivos;
          } else {
            this.archvosLicitacion = [];
          }
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener archivos licitación"
          );
          this.archvosLicitacion = [];
        }
      );

    console.log(this.archvosLicitacion);
  }

  back(): void {
    this.location.back();
  }

  isRespuestasCompletadas(): boolean {
    let completadas: boolean = true;

    for (let index = 0; index < this.objGrupos.length; index++) {
      const grupos = this.objGrupos[index];

      for (let index = 0; index < grupos.subgrupoLs.length; index++) {
        const subgrupos = grupos.subgrupoLs[index];
        const criterios: any[] = subgrupos.criteriosLs ?? [];

        if (criterios.length < subgrupos.limiteRespuestasOferente) {
          completadas = false;
          break;
        }
      }
    }

    console.log(completadas);
    return completadas;
  }
}
