<div>
    <div class="form-group row m-b-15">
        <label class="col-form-label col-md-2"></label>
        <div class="col-md-12">
            <table *ngIf="etapas?.length > 0" id="data-table-default" class="table table-td-valign-middle">
                <thead class="thead-dark">
                    <tr>
                        <th width="5%" class="no-print"></th>
                        <th class="text-nowrap">Obligatoria</th>
                        <th class="text-nowrap">Actividad</th>
                        <th class="text-nowrap">Dia</th>
                        <th class="text-nowrap">Fecha Inicio</th>
                        <th class="text-nowrap">Dia</th>
                        <th class="text-nowrap">Fecha Termino</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd gradeX" *ngFor="let item of etapas; let i = index">
                        <td width="2%" class="no-print">
                            <input #proceso type="checkbox" id="proceso" name="proceso"
                                (click)="onChangeProceso(item.CORRELATIVO, proceso.checked)"
                                [disabled]="item.OBLIGATORIO === 1 ? true: false" checked>
                        </td>
                        <td width="2%" class="no-print text-center">
                            <!-- (click)="onChangeProceso(item.CORRELATIVO, proceso.checked)" -->
                            <input #etapaObligatoria type="checkbox" id="etapaObligatoria" name="etapaObligatoria"
                                [disabled]="item.OBLIGATORIO === 1 ? true: false" checked>
                        </td>
                        <td>
                            {{item.DESCRIPCION}}
                        </td>
                        <td>
                            {{fecha_inicio.value | date: 'EEEE'}}
                        </td>
                        <td>
                            <input class="form-control" #fecha_inicio type="date" id="fecha_inicio" name="fecha_inicio"
                                min="{{minDate}}" (change)="onFechaInicio(item.CORRELATIVO,fecha_inicio.value,i)"
                                [(ngModel)]="fInicioTemp[item.CORRELATIVO]" [ngModelOptions]="{standalone: true}">
                        </td>
                        <td>
                            {{fecha_termino.value | date: 'EEEE'}}
                        </td>
                        <td>
                            <input class="form-control" #fecha_termino type="date" id="fecha_termino"
                                name="fecha_termino" (change)="onFechaTermino(item.CORRELATIVO,fecha_termino.value,i)"
                                [(ngModel)]="fTerminoTemp[item.CORRELATIVO]" [ngModelOptions]="{standalone: true}">
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="cronograma?.length > 0" id="data-table-default"
                class="table table-striped table-td-valign-middle">
                <thead>
                    <tr>
                        <th width="10%" class="no-print">Etapa Activa</th>
                        <th class="text-nowrap">Obligatoria</th>
                        <th class="text-nowrap">Actividad</th>
                        <th class="text-nowrap">Dia</th>
                        <th class="text-nowrap">Fecha Inicio</th>
                        <th class="text-nowrap">Dia</th>
                        <th class="text-nowrap">Fecha Termino</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd gradeX" *ngFor="let item of cronograma; let i = index">
                        <td width="5%" class="no-print">
                            <input #proceso type="checkbox" id="proceso" name="proceso"
                                (click)="onChangeProcesoEdit(item.ETAPA_CORRELATIVO, proceso.checked)"
                                [disabled]="((item.OBLIGATORIO === 1 || item.ORDEN <= ordenEtapaActual) || idEtapa == 16) ? true: false"
                                [checked]="item.ACTIVO === 'S'">
                        </td>
                        <td width="5%" class="no-print text-center">
                            <!-- (click)="onChangeProcesoEdit(item.ETAPA_CORRELATIVO, etapaObligatoria.checked)" -->
                            <input #etapaObligatoria type="checkbox" id="etapaObligatoria" name="etapaObligatoria"
                                [disabled]="((item.OBLIGATORIO === 1 || item.ORDEN < ordenEtapaActual) || idEtapa == 16) ? true: false"
                                [checked]="item.ETAPA_OBLIGATORIA === 1"
                                (click)="onChengeObligatorio(item.CORRELATIVO, etapaObligatoria.checked)">
                        </td>
                        <td>{{ item.ETAPA_DESC }}</td>
                        <td>
                            {{fecha_inicio.value | date: 'EEEE'}}
                        </td>
                        <td>
                            <input class="form-control" #fecha_inicio type="date" id="fecha_inicio" name="fecha_inicio"
                                min="{{minDate}}"
                                (change)="onFechaInicioEdit(item.ETAPA_CORRELATIVO,fecha_inicio.value,i)"
                                [(ngModel)]="fInicioTemp[item.ETAPA_CORRELATIVO]" [ngModelOptions]="{standalone: true}"
                                [disabled]="((item.ACTIVO === 'N' || item.ORDEN < ordenEtapaActual) || idEtapa == 16) ? true: false">
                        </td>
                        <td>
                            {{fecha_termino.value | date: 'EEEE'}}
                        </td>
                        <td>
                            <input class="form-control" #fecha_termino type="date" id="fecha_termino"
                                name="fecha_termino"
                                (change)="onFechaTerminoEdit(item.ETAPA_CORRELATIVO,fecha_termino.value,i)"
                                [(ngModel)]="fTerminoTemp[item.ETAPA_CORRELATIVO]" [ngModelOptions]="{standalone: true}"
                                [disabled]="((item.ACTIVO === 'N' || item.ORDEN < ordenEtapaActual) || idEtapa == 16) ? true: false">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>