<div id="content" class="content">
    <!-- begin error -->
    <div class="error">
        <div class="error-code">404</div>
        <div class="error-content">
            <div class="error-message">Página no encontrada</div>
            <div class="error-desc mb-3 mb-sm-4 mb-md-5">
                La página que esta tratando de accder no esta disponible o no tiene autorización para verla.
            </div>
        </div>
    </div>
    <!-- end error -->
</div>