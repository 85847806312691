import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LicitacionService } from "src/app/shared/services/licitacion.service";
import { ActivatedRoute, Params } from "@angular/router";
import { NotificationService } from "src/app/shared/services/notification.service";
import { EtapasService } from "src/app/shared/services/etapas.licitacion.service";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { jsPDF } from "jspdf";

@Component({
  selector: "app-view-respuesta-licitacion",
  templateUrl: "./view-respuesta-licitacion.component.html",
  styleUrls: ["./view-respuesta-licitacion.component.css"],
})
export class ViewRespuestaLicitacionComponent implements OnInit {
  idLicitacion: number;
  idEtapa: number;
  idEtapaLic: number;
  consultas_licitacion: [
    {
      CORRELATIVO: number;
      CONSULTA: string;
      isCollapsed: boolean;
      NUM_ROW: number;
    }
  ];
  consultas_result: any[] = [];
  consolidado: any[] = [
    { name: "Si", value: 1, check: false },
    { name: "No", value: 0, check: true },
  ];
  showFile: boolean = false;
  archivos64: Blob;
  filename: string;
  type: string;
  isDisable: boolean = true;
  nombreEtapa: string;
  archivos: [] = [];
  consultas: any[];
  // tabs
  tabSelected: number = 1;
  // form
  formRespuestas: FormGroup;
  nombreArchivo: string;

  @ViewChild("inputFile")
  inputFile: ElementRef;

  respuestas: any[];
  aclaracionesLicitacion: any[] = [];
  objLicitacion: any;
  rolesParticipanteComision: any[];

  @ViewChild("closeUpdateValidacionRespuestas")
  closeUpdateValidacionRespuestas: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private etapaService: EtapasService,
    private licitacionService: LicitacionService,
    private notifyService: NotificationService,
    private fb: FormBuilder
  ) {
    this.route.params.subscribe((params: Params) => {
      this.idLicitacion = params["idLicitacion"];
      this.idEtapa = params["idEtapa"];
      this.idEtapaLic = params["idEtapaLic"];
      this.rolesParticipanteComision = [];
    });

    this.formRespuestas = this.fb.group({
      respuestas: new FormArray([]),
    });
  }

  ngOnInit(): void {
    this.getHeaderLicitacion();
    this.getConsultasFull();
    this.getAclaracionesLicitacion();
    if (this.idEtapa) {
      this.etapaService.getEtapaId(this.idEtapa).subscribe((resp) => {
        if (resp) {
          this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
        }
      });
      this.getArchivoLicEtapaFull();
      this.getSelectRolesParticipanteComision();
    }
  }

  validateUsuarioEncargado() {
    if (
      this.objLicitacion.RESP_PROPCESO ==
        JSON.parse(localStorage.getItem("userData")).userId ||
      this.objLicitacion.LIDER_COMISION_TEC ==
        JSON.parse(localStorage.getItem("userData")).userId
    ) {
      return true;
    } else {
      return false;
    }
  }

  onValidateBtnRespuestasConsultas() {
    for (let index = 0; index < this.consultas.length; index++) {
      const element = this.consultas[index];

      if (element[0].RESPUESTA == null || element[0].RESPUESTA == "") {
        return false;
      }
    }

    return true;
  }

  onValidarRespuestasConsultas() {
    if (this.onValidateBtnRespuestasConsultas()) {
      const postData = {
        pin_lic_correlativo: this.idLicitacion,
        in_validacion: this.objLicitacion.CONSULTAS_APROBADAS === 0 ? 1 : 0,
      };

      this.licitacionService
        .putUpdateValidateConsultasRespuestas(postData)
        .subscribe((resp) => {
          this.getHeaderLicitacion();
          this.closeUpdateValidacionRespuestas.nativeElement.click();
          this.notifyService.showSuccess(
            "Se ha actualizado la validación de las respuestas.",
            "Validar consultas"
          );
        });
    } else {
      this.notifyService.showWarning(
        "Hay preguntas pendendientes por contestar.",
        "Validar consultas"
      );
    }
  }

  printArray() {
    console.log(this.formRespuestas);
  }

  getArchivoLicEtapaFull() {
    this.etapaService
      .getArchivoLicEtapaFull(this.idLicitacion, this.idEtapa)
      .subscribe((resp) => {
        this.archivos = JSON.parse(resp);
      });
  }

  detectFiles(event: any) {
    if (event.target.validity.valid) {
      this.isDisable = false;
    }
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null) {
          this.filename = event.target.files[0].name;
          this.type = event.target.files[0].name.slice(
            ((event.target.files[0].name.lastIndexOf(".") - 1) >>> 0) + 2
          );
          this.archivos64 = bs64;
        }
      };
    }
  }
  onConsolidado(id: number) {
    switch (id) {
      case 0:
        this.showFile = false;
        this.isDisable = true;
        this.archivos64 = null;
        this.filename = null;
        this.type = null;
        break;
      case 1:
        this.showFile = true;
        break;
    }
  }

  onGuardarArchivo() {
    if (this.archivos64) {
      const postData: {
        in_det_etapa_lic: number;
        files: Blob;
        fileName: string;
        type: string;
        in_usuario: string;
        publico: number;
        descripcion_input_archivo: string;
        idLicitacion: number;
        idEtapaLicitacion: number;
        correlativoGrupoArchivo: number;
      } = {
        in_det_etapa_lic: this.idEtapaLic,
        files: this.archivos64,
        fileName: this.filename,
        type: this.type,
        in_usuario: "",
        publico: 1,
        descripcion_input_archivo:
          this.nombreArchivo == null || this.nombreArchivo == ""
            ? this.filename
            : this.nombreArchivo,
        idLicitacion: this.idLicitacion,
        idEtapaLicitacion: this.idEtapa,
        correlativoGrupoArchivo: 0,
      };
      this.licitacionService.postArchivosLicitacion(postData).subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Archivo subido correctamente",
            "Subir archivos licitacion"
          );
          this.getArchivoLicEtapaFull();

          this.archivos64 = null;
          this.filename = "";
          this.type = "";
          this.inputFile.nativeElement.value = "";
          this.nombreArchivo = "";
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Subir archivos licitacion"
          );
        }
      );
    }
  }

  getConsultas() {
    this.licitacionService
      .getListConsultasLic(this.idLicitacion, this.idEtapa)
      .subscribe((resp) => {
        this.consultas_licitacion = JSON.parse(resp);
      });
    if (this.idEtapa) {
      this.etapaService.getEtapaId(this.idEtapa).subscribe((resp) => {
        if (resp) {
          this.nombreEtapa = JSON.parse(resp)[0].ETAPA_DESC;
        }
      });
      //this.getArchivoLicEtapaFull();
    }
  }

  onPreGuardaResp(idConsulta: number, respuesta: string) {
    const data = {
      in_condetlic_correlativo: idConsulta,
      in_respuesta: respuesta,
      in_usuario: "",
    };
    if (this.consultas_result) {
      this.consultas_result.map((todo, i) => {
        if (todo.in_condetlic_correlativo === idConsulta) {
          this.consultas_result.splice(i, 1);
        }
      });
    }
    this.consultas_result.push(data);
  }

  onGuardarResp() {
    this.consultas_result.map((putData, i) => {
      this.licitacionService.putRespuestaConsultaLicitacion(putData).subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Las respuestas se han guardado correctamente.",
            "Guardar respuestas de licitación"
          );
          this.getConsultas();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Guardar respuestas de licitación"
          );
        }
      );
    });
  }

  onChangeRespuesta(idConsulta: number, respuesta: string, index: number) {
    // this.respuestas[index].Respuesta = respuesta;
    // this.respuestas[index].in_respuesta = respuesta;
    // this.respuestas[index].in_condetlic_correlativo = idConsulta;
    // this.respuestas[index].in_usuario = '';
    //{in_condetlic_correlativo : idConsulta , in_respuesta: respuesta ,  in_usuario : ''}
    //this.respuestas[index].Respuesta = respuesta;
  }

  // { in_condetlic_correlativo: number;in_respuesta: string; in_usuario : string  }
  guardarRespuesta(index: number, condetlicCorrelativo: number) {
    const respuesta =
      this.formRespuestas.get("respuestas")["controls"][index]["controls"][
        "respuesta"
      ].value;

    if (respuesta.trim() === "") {
      this.notifyService.showWarning(
        "La respuesta no puede estar vacía",
        "Guardar respuestas de licitación"
      );
      return;
    } else {
      this.respuestas[index].Respuesta =
        this.formRespuestas.get("respuestas")["controls"][index]["controls"][
          "respuesta"
        ].value;
      this.respuestas[index].in_respuesta =
        this.formRespuestas.get("respuestas")["controls"][index]["controls"][
          "respuesta"
        ].value;
      // this.formRespuestas.get('respuestas')['controls'][index]['controls']['correlativoConsulta'].value;
      this.respuestas[index].in_condetlic_correlativo = condetlicCorrelativo;
      this.respuestas[index].in_usuario = "";
    }

    //alert(`${index} - ${this.formRespuestas.get('respuestas')['controls'][index]['controls']['correlativoConsulta'].value} - ${condetlicCorrelativo}`);

    this.licitacionService
      .putRespuestaConsultaLicitacion(this.respuestas[index])
      .subscribe(
        (resp) => {
          this.notifyService.showSuccess(
            "Las respuestas se han guardado correctamente.",
            "Guardar respuestas de licitación"
          );
          this.respuestas[index].Respuesta = "";
          this.respuestas[index].in_respuesta = "";
          this.respuestas[index].in_condetlic_correlativo = 0;
          this.respuestas[index].in_usuario = "";
          this.formRespuestas.get("respuestas")["controls"][index]["controls"][
            "respuesta"
          ].value = "";
          this.getConsultasFull();
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Guardar respuestas de licitación"
          );
        }
      );
  }

  private getConsultasFull() {
    this.licitacionService
      .getListConsultasLic(this.idLicitacion, this.idEtapa)
      .subscribe((resp) => {
        this.consultas_licitacion = JSON.parse(resp);

        this.licitacionService
          .getConsultasLicFull(this.idLicitacion)
          .subscribe((resp) => {
            let consultasWithNumber: any[] = JSON.parse(resp);

            for (let index = 0; index < consultasWithNumber.length; index++) {
              const element = consultasWithNumber[index];
              consultasWithNumber[index] = {
                ...element,
                numRow: this.getNumber(element.CORRELATIVO),
              };
            }

            //console.log(this.consultas_licitacion);

            const respuestas = this.groupByKey(consultasWithNumber, "numRow"); //
            this.consultas = Object.values(respuestas);

            this.respuestas = new Array(this.consultas.length);

            this.respuestas = this.respuestas.fill({ Respuesta: "" });

            for (let index = 0; index < this.consultas.length; index++) {
              const element = this.consultas[index];

              this.formRespuestas.get("respuestas")["controls"].push(
                this.fb.group({
                  respuesta: "",
                  correlativoConsulta: element[0].CORRELATIVO,
                })
              );
            }

            // console.log(this.consultas);
            // console.log(this.respuestas);
          });
      });
  }
  groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
    /* return array.groupByToMap(pregunta =>
        {
          return pregunta[key];
        }) */
  }

  getHeaderLicitacion() {
    this.licitacionService.getHeaderLicitacionId(this.idLicitacion).subscribe(
      (resp) => {
        this.objLicitacion = JSON.parse(resp)[0];
      },
      (err) => {
        this.notifyService.showError(
          err.error.message,
          "Obtener cabecera licitación"
        );
      }
    );
  }

  getAclaracionesLicitacion() {
    this.licitacionService
      .getAclaracionesLicitacion(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.aclaracionesLicitacion = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(
            err.error.message,
            "Obtener tipos de aclaracion."
          );
        }
      );
  }

  printPdf() {
    const oDocPdf = new jsPDF({ format: "letter", unit: "mm" });
    let nPageYAxi = 10;
    const iMargin = 20;
    const iFontText = 10;
    const iMarginHeader = 35;
    const iMaxPageWidth = 215.9 - iMargin * 2;
    const iLineSeparation = 5;

    const addLogo = () => {
      const logo = new Image();
      logo.src = "/assets/img/logo/logo_em_pdf.png";
      oDocPdf.addImage(logo, "PNG", iMargin, 10, 68, 6.8);
    };

    const addHeader = () => {
      oDocPdf.setFontSize(iFontText);
      oDocPdf.setTextColor(0, 0, 0); // black text
      oDocPdf.setFont("Helvetica", "normal", 700);
      nPageYAxi = 25;

      const consultas_respuestas = new Image();
      consultas_respuestas.src = "/assets/img/misc/consultas_respuestas.png";
      oDocPdf.addImage(
        consultas_respuestas,
        "PNG",
        iMargin,
        nPageYAxi,
        iMaxPageWidth,
        6.8
      );

      nPageYAxi += 20;

      centeredText(`CONTRATO: ${this.objLicitacion?.CONT_NUMERO}`, nPageYAxi);

      slitText(
        `“${this.objLicitacion?.CONT_NOMBRE}”`,
        iMaxPageWidth,
        6,
        iMargin,
        "center"
      );

      nPageYAxi += 10;

      centeredText(
        `LICITACIÓN: ${this.objLicitacion?.LICITACION_CORRELATIVO}`,
        nPageYAxi
      );

      nPageYAxi += 10;
    };

    //slitText se emplea para dividir el texto de un parrafo en multiples lineas, en funcion del ancho enviado en el parametro iMaxWidth
    //el cual es el ancho maximo permitido para el texto.
    //iIncreaseY = es alto que se aumentara por cada linea
    //de ser necesario, el valor del alto de la ultima linea se guarda en la variable nPageYAxi
    //iXAxis = posicion en eje X para inicio del texto.
    const slitText = function (sText, iMaxWidth, iIncreaseY, iXAxis, align) {
      let iIncreaseY2 = 0;
      if (Array.isArray(iIncreaseY)) {
        iIncreaseY2 = iIncreaseY[1];
        iIncreaseY = iIncreaseY[0];
      } else {
        iIncreaseY2 = iIncreaseY;
      }
      const aTextLines = oDocPdf.splitTextToSize(sText, iMaxWidth);
      aTextLines.forEach((sLine, i) => {
        if (i != 0) {
          increasePageY(iIncreaseY);
        } else {
          increasePageY(iIncreaseY2);
        }
        if (!align) {
          oDocPdf.text(iXAxis, nPageYAxi, sLine);
        } else if (align === "center") {
          centeredText(sLine, nPageYAxi); //nPageYAxi posee el valor del ultimo alto ingresado
        } else if (align === "justify") {
          oDocPdf.text(sLine, iXAxis, nPageYAxi, {
            maxWidth: iMaxPageWidth - iMargin,
            align: "justify",
          });
        }
      });
    };

    //funcion se emplea para centrar en texto en funcion del ancho del la hora configurada
    const centeredText = function (sText, y) {
      const textWidth =
        (oDocPdf.getStringUnitWidth(sText) * iFontText) /
        oDocPdf.internal.scaleFactor;
      const textOffset = ((oDocPdf.internal.pageSize.width - textWidth) /
        2) as any;
      oDocPdf.text(textOffset, y, sText);
    };

    //Se emplea para aumentar el contador
    const increasePageY = function (iIncreaseValue) {
      nPageYAxi += iIncreaseValue;
      if (nPageYAxi > oDocPdf.internal.pageSize.height - iMargin) {
        addPage();
      }
    };

    const addPage = () => {
      oDocPdf.addPage("letter", "portrait");
      //al iniciar nueva pagina y agregar el header establesco el alto inicial
      nPageYAxi = iMarginHeader;
      addLogo();
      addHeader();
    };

    const addSubtitle = (sTitle, sValue) => {
      oDocPdf.setTextColor(0, 0, 0); // black text
      identText(sTitle, sValue, 31, 80, 0);
      nPageYAxi += 8;
    };

    const identText = (
      sTitle,
      sValue,
      iMaxWidth,
      iMaxWidthValue = null,
      iAddMargin = 0,
      isQuestion = false,
      align = ""
    ) => {
      if (!iMaxWidthValue) {
        if (iMaxWidth > 50) {
          iMaxWidthValue = 100;
        } else {
          iMaxWidthValue = iMaxWidth;
        }
      } else {
        const iMaxLength = 50;
        sTitle = getMaxText(sTitle, iMaxLength);
      }
      if (isQuestion) oDocPdf.setFont("Helvetica", "normal", 700);
      slitText(sTitle, iMaxWidth, 5, iMargin + iAddMargin, align);
      if (isQuestion) oDocPdf.setFont("Helvetica", "normal", 400);
      slitText(sValue, iMaxWidthValue, [5, 0], iMaxWidth, align);
    };

    const getMaxText = (sText, iMaxLength) => {
      if (sText.length > iMaxLength) {
        sText = sText.substr(0, iMaxLength) + "...";
      }
      return sText;
    };

    // PAGE INIT

    addLogo();
    addHeader();

    addSubtitle("I.", "ACLARACIONES");
    let iMarginText = iMargin;
    const iSeparationText = 11;
    iMarginText += iSeparationText;
    this.aclaracionesLicitacion.map((aclaracion) => {
      let iMarginLeft = iMarginText;
      oDocPdf.setTextColor(0, 0, 0); // black text
      slitText(
        `${aclaracion.descripcion.toUpperCase()}:`,
        iMaxPageWidth,
        iLineSeparation,
        iMarginLeft,
        false
      );
      iMarginLeft += 10;
      oDocPdf.setTextColor(0, 31, 95); //blue job
      slitText(
        `${aclaracion.texto_aclaracion}`,
        iMaxPageWidth - iMargin,
        iLineSeparation,
        iMarginLeft,
        "justify"
      );

      nPageYAxi += 8;
    });

    oDocPdf.setTextColor(0, 0, 0); // black text
    slitText("ANEXOS:", iMaxPageWidth, iLineSeparation, iMarginText, false);

    addPage();

    addSubtitle("II.", "RESPUESTAS A CONSULTAS");
    const pdfConsultas = [...this.consultas];

    pdfConsultas.map((d, i) => {
      const marginText = 15;
      const anchoMargenPregunta = iMaxPageWidth - marginText - 10;
      const pregunta = d[0];
      oDocPdf.setTextColor(0, 0, 0); // black text
      //getNumber(pregunta[0].CORRELATIVO)
      //identText(`${i+1}-`, pregunta.CONSULTA, 35, 80, 11, true, 'justify');
      identText(
        this.getNumber(pregunta.CORRELATIVO) + `-`,
        pregunta.CONSULTA,
        35,
        anchoMargenPregunta,
        11,
        true,
        "justify"
      );

      //this.consultas
      for (var y = 0; y < d.length; y++) {
        if (d[y].RESPUESTA_PRINCIPAL == "S") {
          oDocPdf.setTextColor(0, 31, 95); //blue job
          oDocPdf.setFont("Helvetica", "normal", 700);
          slitText(
            `R: ${d[y].RESPUESTA || ""}`,
            anchoMargenPregunta,
            iLineSeparation,
            iMargin + marginText,
            "justify"
          );
        }
      }

      nPageYAxi += 3;
    });

    oDocPdf.save(
      `Licitación ${this.objLicitacion?.LICITACION_CORRELATIVO} Consolidado de Consultas.pdf`
    );
  }

  getNumber(correlativo: number) {
    let index = this.consultas_licitacion
      ?.map(function (det) {
        return det.CORRELATIVO;
      })
      .indexOf(correlativo);

    if (index > -1) {
      return this.consultas_licitacion[index].NUM_ROW;
    } else {
      return "";
    }
  }
  setRespuestaPrincipal(correlativo) {
    if (!correlativo) {
      this.notifyService.showError("Error", "asdasd.");
      return;
    }
    this.licitacionService.postRespuestaPrincipal({ correlativo }).subscribe(
      (resp) => {
        //const mensajes: any[] = JSON.parse(resp?.toString());
        //this.inputMenssage = new FormControl('');
        //this.getMessages()
        this.getConsultasFull();
      },
      (error) => {
        this.notifyService.showError(
          "Error",
          "Ocurrio un error al comprobar las notificaciones enviadas."
        );
        console.log(error);
      },
      () => {
        // nada
      }
    );
  }

  validateConsultasCompletas() {
    let result = true;

    for (let index = 0; index < this.consultas.length; index++) {
      const element = this.consultas[index];

      if (element[0].RESPUESTA === null) {
        result = false;
        return result;
      }
    }

    return result;
  }

  getSelectRolesParticipanteComision() {
    this.licitacionService
      .getSelectRolesParticipanteComision(this.idLicitacion)
      .subscribe(
        (resp) => {
          this.rolesParticipanteComision = JSON.parse(resp);
        },
        (err) => {
          this.notifyService.showError(err.error.message, "Roles participante");
        }
      );
  }

  validateRoleParticipanteComision(idRol: number): boolean {
    return this.rolesParticipanteComision.some(
      (item: { domi_correlativo_tipar: number }) =>
        item.domi_correlativo_tipar === idRol
    );
  }

  validateRespuestasLct() {
    this.licitacionService
      .postUpdateValidacionRespuestasAConsultasLct({
        idLicitacion: this.idLicitacion,
      })
      .subscribe(
        (resp) => {
          this.getHeaderLicitacion();
          this.notifyService.showSuccess(
            "Las respuestas se han validado correctamente y se ha enviado la notificación al responsable de la licitación.",
            "Validar respuestas de licitación"
          );
        },
        (error) => {
          this.notifyService.showError(
            "Error",
            "Ocurrio un error al validar las respuestas."
          );
          console.log(error);
        },
        () => {
          // nada
        }
      );
  }
}
