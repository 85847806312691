import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../models/user.model";
import { tap, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { IActions } from "./global-data.service";

interface AuthResponseData {
  token: string;
  userId: number;
  userName: string;
  rolId: number;
  rolName: string;
  expiresIn: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;
  private urlService: string = environment.apiUrl;
  private header;

  constructor(private http: HttpClient, private router: Router) {
    const token = JSON.parse(localStorage.getItem("userData"))?._token;
    this.header = {
      Authorization: "Bearer " + token,
    };
  }

  signin(postLogin: { email: string; password: string }) {
    return this.http
      .post<AuthResponseData>(this.urlService + "auth/signin", postLogin)
      .pipe(
        /*map( resData =>{
                //this.handleAuth(resData.userId , resData.rolId, resData.rolName, resData.userName, resData.token, +resData.expiresIn);  
                return  JSON.stringify(resData);
              })*/
        tap((resData) => {
          this.handleAuth(
            resData.userId,
            resData.rolId,
            resData.rolName,
            resData.userName,
            resData.token,
            +resData.expiresIn
          );
          return JSON.stringify(resData);
        })
      );
  }
  getCargosPorUsuario(in_pers_correlativo: number) {
    return this.http
      .get(this.urlService + "auth/getCargosPorUsuario/" + in_pers_correlativo)
      .pipe(
        map((resp) => {
          let cargos = [];
          for (const item in resp["cargos"]) {
            cargos.push({ ...resp["cargos"][item] });
          }
          return JSON.stringify(cargos);
        })
      );
  }

  getAccionesPorUsuario() {
    const token = JSON.parse(localStorage.getItem("userData"))?._token;
    return this.http.get(this.urlService + "auth/getAccionesPorUsuario", {
      headers: { Authorization: "Bearer " + token },
    });
  }

  autoLogin() {
    const userData: {
      userId: number;
      rolId: number;
      rolName: string;
      userName: string;
      _token: string;
      _tokenExpiration: string;
    } = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      return;
    }

    const loadedUser = new User(
      userData.userId,
      userData.rolId,
      userData.rolName,
      userData.userName,
      userData._token,
      new Date(userData._tokenExpiration)
    );

    if (loadedUser.token) {
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(userData._tokenExpiration).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  logout() {
    this.user.next(null);
    this.router.navigate(["/auth"]);
    localStorage.removeItem("userData");
    localStorage.removeItem("userActions");
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  getSelectNotificacionesByUsuario() {
    const token = JSON.parse(localStorage.getItem("userData"))?._token;
    return this.http
      .get(`${this.urlService}licitacion/getSelectNotificacionesByUsuario`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .pipe(
        map((resp) => {
          let returnNotificacionesByUsuario = [];
          for (const item in resp["returnNotificacionesByUsuario"]) {
            returnNotificacionesByUsuario.push({
              ...resp["returnNotificacionesByUsuario"][item],
            });
          }
          return JSON.stringify(returnNotificacionesByUsuario);
        })
      );
  }

  postUpdateNotificacionLeida(postData: { correlativo: number }) {
    const token = JSON.parse(localStorage.getItem("userData"))?._token;
    return this.http.post(
      `${this.urlService}licitacion/postUpdateNotificacionLeida`,
      postData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  getUpdateMarcarTodoLeido() {
    const token = JSON.parse(localStorage.getItem("userData"))?._token;
    return this.http.get(
      `${this.urlService}licitacion/getUpdateMarcarTodoLeido`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  }

  private handleAuth(
    userId: number,
    rolId: number,
    rolName: string,
    userName: string,
    token: string,
    expiresIn: number
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(
      userId,
      rolId,
      rolName,
      userName,
      token,
      expirationDate
    );
    this.user.next(user);
    this.autoLogout(expiresIn * 1000);
    localStorage.setItem("userData", JSON.stringify(user));
  }
}
