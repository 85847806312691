import { Component, OnInit } from '@angular/core';
import { LicitacionService } from '../shared/services/licitacion.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  en_proceso: number = 0 ;
  en_creacion: number = 0 ;
  adjudicadas: number = 0 ;
  constructor(private licitacionService : LicitacionService, private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.licitacionService.getDashBoard().subscribe(resp =>{
      if (JSON.parse(resp)){
        this.en_proceso = JSON.parse(resp)[0].EN_PROCESO ;
        this.en_creacion = JSON.parse(resp)[0].EN_CREACION ;
        this.adjudicadas = JSON.parse(resp)[0].ADJUDICADAS ;
      }
    }, err =>{
      this.notifyService.showError(err.error.message, 'Obtener dashboard');
    });
  }

}
